import type { FC } from 'react';

import { useState } from 'react';

import { Input } from '@shared/ui/atoms/input';

import styles from './currency-input.module.scss';

import type { CurrencyInputProps } from './currency-input.props';

const CurrencyInput: FC<CurrencyInputProps> = ({
  onChange,
  value,
  ...props
}) => {
  const [typedValue, setTypedValue] = useState(value ?? '');

  const currencyReplacer = (value: string) => {
    let shouldReplace = false;

    return value
      .replace(/[^0-9\.]+/gi, '')
      .replace(/^0/, '')
      .replace(/\./gi, match => {
        if (!shouldReplace) {
          shouldReplace = true;

          return match;
        }

        return '';
      });
  };

  const onInputChange = (value: string) => {
    setTypedValue(currencyReplacer(value));
    onChange(currencyReplacer(value));
  };

  return (
    <Input
      {...props}
      className={styles.currencyInput}
      type='text'
      prepend={<div className={styles.currency}>£</div>}
      clearable={false}
      value={typedValue}
      onChange={onInputChange}
    />
  );
};

export { CurrencyInput };
