import { redirect } from 'atomic-router';

import { combine, createEvent, createStore, sample } from 'effector';

import { splitMap } from 'patronum';

import { SignUpType } from '@features/care-recipient/client-type-selection';

import { SignUpForNewsLetterModelFactory } from '@features/newsletter/sign-up';

import { PostcodeCheckFactory } from '@features/postcode/check';

import { routes } from '@shared/routes';

const reset = routes.signUp.any.closed;

const signUpTypeClicked = createEvent<SignUpType>();

const hasAnAccountBackClicked = createEvent();

const hasAnAccountNextClicked = createEvent();

const $$signUpForNewsLetter = SignUpForNewsLetterModelFactory.createModel();

const $$postCodeCheck = PostcodeCheckFactory.createModel();

const $isCarer = routes.signUp.carer.any.$isOpened;

const $firstName = createStore('');

const $hasAnAccount = createStore(false);

const $postcodeNotSupported = createStore(false);

const $openedSignUpType = combine(
  {
    carer: routes.signUp.carer.any.$isOpened,

    lovedOne: routes.signUp.lovedOne.any.$isOpened,

    myself: routes.signUp.myself.any.$isOpened
  },
  ({ carer, lovedOne, myself }) => {
    switch (true) {
      case carer:
        return SignUpType.Carer;
      case lovedOne:
        return SignUpType.Loved;
      case myself:
        return SignUpType.Myself;
    }
  }
);

const postcodeSubmittedFor = splitMap({
  source: sample({
    clock: $$postCodeCheck.checkPostcodeFx.done,

    source: $openedSignUpType,

    fn: (signUpType, { params }) => ({
      signUpType,

      postcode: params.postcode
    })
  }),

  cases: {
    lovedOne: ({ signUpType, postcode }) => {
      if (signUpType == SignUpType.Loved) return postcode;
    },

    carer: ({ signUpType, postcode }) => {
      if (signUpType == SignUpType.Carer) return postcode;
    },

    myself: ({ signUpType, postcode }) => {
      if (signUpType == SignUpType.Myself) return postcode;
    }
  }
});

const signUpTypeClickedWith = splitMap({
  source: sample({
    clock: signUpTypeClicked,

    source: routes.signUp.type.$query,

    fn: (query, type) => ({
      type,

      postcode: query.postcode
    })
  }),

  cases: {
    lovedOne: ({ type, postcode }) => {
      if (type == SignUpType.Loved && !postcode) return { type };
    },

    lovedOneWithPostcode: ({ type, postcode }) => {
      if (type == SignUpType.Loved && postcode) return { type, postcode };
    },

    myself: ({ type, postcode }) => {
      if (type == SignUpType.Myself && !postcode) return { type };
    },

    myselfWithPostcode: ({ type, postcode }) => {
      if (type == SignUpType.Myself && postcode) return { type, postcode };
    }
  }
});

redirect({
  clock: signUpTypeClickedWith.lovedOne,

  route: routes.signUp.lovedOne.postcode
});

redirect({
  clock: signUpTypeClickedWith.myself,

  route: routes.signUp.myself.postcode
});

sample({
  clock: signUpTypeClickedWith.lovedOneWithPostcode,

  fn: ({ postcode }) => ({
    postcode
  }),

  target: routes.signUp.lovedOne.careNeeds.open
});

sample({
  clock: signUpTypeClickedWith.myselfWithPostcode,

  fn: ({ postcode }) => ({
    postcode
  }),

  target: routes.signUp.myself.careNeeds.open
});

sample({
  clock: postcodeSubmittedFor.carer,

  fn: postcode => ({
    postcode
  }),

  target: routes.signUp.carer.skills.open
});

sample({
  clock: postcodeSubmittedFor.lovedOne,

  fn: postcode => ({
    postcode
  }),

  target: routes.signUp.lovedOne.careNeeds.open
});

sample({
  clock: postcodeSubmittedFor.myself,

  fn: postcode => ({
    postcode
  }),

  target: routes.signUp.myself.careNeeds.open
});

sample({
  clock: $$postCodeCheck.checkPostcodeFx.fail,

  fn: ({ params }) => ({
    name: '',
    email: '',
    postCode: params.postcode
  }),

  target: $$signUpForNewsLetter.form.reset
});

sample({
  clock: reset,

  target: [$$postCodeCheck.reset, $$signUpForNewsLetter.reset]
});

redirect({
  clock: hasAnAccountNextClicked,

  route: routes.auth.signIn
});

$hasAnAccount.reset(reset, hasAnAccountBackClicked);

$postcodeNotSupported
  .on($$postCodeCheck.checkPostcodeFx.fail, () => true)

  .reset(reset);

const $$signUpPage = {
  $isCarer,
  $firstName,
  $hasAnAccount,
  $openedSignUpType,
  $postcodeNotSupported,

  $$postCodeCheck,
  $$signUpForNewsLetter,

  signUpTypeClicked,
  hasAnAccountBackClicked,
  hasAnAccountNextClicked
};

export { $$signUpPage };
