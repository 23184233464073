import classNames from 'classnames';

import type {
  CSSProperties,
  FC,
  HTMLAttributes,
  PropsWithChildren
} from 'react';

import { $windowSize } from '@shared/lib/global-events';

import { createView } from '@shared/lib/view';

import styles from './menu.module.scss';

type MenuProps = PropsWithChildren<{}>;

const Menu = createView<MenuProps>()
  .units({
    height: $windowSize.map(state => state.height)
  })

  .view(({ children, height }) => (
    <div
      className={styles.menu}
      style={
        {
          '--full-screen-height': `${height}px`
        } as CSSProperties
      }
    >
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {children}
    </div>
  ));

const MenuContentContainer: FC<HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => <div className={classNames(styles.container)} {...props} />;

export { Menu, MenuContentContainer };
