import { ReactComponent as ArrowBack } from '@icon/arrow-back.svg';

import classNames from 'classnames';

import type { FC, PropsWithChildren, ReactNode } from 'react';

import { Button, ButtonGroup } from '@shared/ui/atoms/button';

import { useMedia } from '@shared/ui/atoms/media';

import styles from './wizard-form-step.module.scss';

type WizardFormStepProps = PropsWithChildren<{
  title?: ReactNode;

  total?: number;

  className?: string;

  current?: number;

  footer?: ReactNode;

  onGoNext?: ((...args: any) => any) | (() => void);

  onGoBack?: () => void;

  captions?: {
    next?: string;
  };
}>;

/**
 * <Step />
 */
const WizardFormStep: FC<PropsWithChildren<WizardFormStepProps>> = ({
  title,
  total,
  footer,
  current,
  children,
  captions,
  className,
  onGoBack: onBackClick,
  onGoNext: onNextClick
}) => {
  const sm = useMedia('>=xs');

  const content = (
    <>
      <div className={styles.header}>
        <h3 className={styles.title}>{title}</h3>

        {total != 0 && current != undefined && (
          <div className={styles.step}>
            <span className={styles.stepCurrent}>Step {current} </span>

            <span className={styles.stepTotal}>of {total}</span>
          </div>
        )}
      </div>

      <div className={styles.content}>{children}</div>

      {footer && <div className={styles.contentFooter}>{footer}</div>}

      <div className={styles.footer}>
        <ButtonGroup>
          {onBackClick && (
            <Button
              className={styles.back}
              variant='text'
              palette='text'
              onClick={onBackClick}
            >
              <ArrowBack className={styles.back__icon} />

              <span>{sm ? 'Back to previous step' : 'Back to previous'}</span>
            </Button>
          )}
        </ButtonGroup>

        <ButtonGroup>
          {onNextClick && (
            <Button className={styles.submit} onClick={onNextClick}>
              {captions?.next ?? 'Next step'}
            </Button>
          )}
        </ButtonGroup>
      </div>
    </>
  );

  return (
    <div className={classNames(styles.container, className)}>{content}</div>
  );
};

export { WizardFormStep };
