import { ReactComponent as Search } from '@icon/search.svg';

import circleAvatar from '@img/circle-avatar.png';

import classNames from 'classnames';

import type { InvoiceStatus } from '@shared/api';

import { invoiceStatusColor, invoiceStatusNames } from '@shared/api';

import { createView } from '@shared/lib/view';

import { Card } from '@shared/ui/atoms/card';

import { Chip } from '@shared/ui/atoms/chip';

import type { VariantsPalette } from '@shared/ui/atoms/chip/chip.props';

import { Input } from '@shared/ui/atoms/input';

import { useMedia } from '@shared/ui/atoms/media';

import { Cell, Row, Table, TBody, THead } from '@shared/ui/atoms/table';

import { InvoicesPlaceholder } from '../../../ui/invoices-placeholder';

import { NoSearchResult } from '../../../ui/no-search-result';

import styles from './desktop-invoices.module.scss';

import type { DesktopInvoicesProps } from './desktop-invoices.props';

const DesktopInvoices = createView<DesktopInvoicesProps>()
  .map(() => {
    const lg = useMedia('>=lg');

    return {
      lg
    };
  })

  .view(
    ({
      noInvoices,
      filteredInvoices,
      onRowClick,
      noResultsForFilters,
      lg,
      statusTabs,
      tab,
      onStatusChange,
      searchText,
      onQueryChange,
      onTableContainerScroll,
      tableContainerRef
    }) => (
      <Card className={styles.fullView}>
        {noInvoices ? (
          <InvoicesPlaceholder />
        ) : (
          <>
            <div className={styles.fullViewHeader}>
              {lg && (
                <ul className={styles.statusTabs}>
                  {statusTabs.map(item => (
                    <li
                      className={classNames(styles.statusTabsTab, {
                        [styles.statusTabsTabActive]: tab == item.id
                      })}
                      key={item.id}
                      onClick={() => onStatusChange(item.id as InvoiceStatus)}
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              )}

              <div className={styles.search}>
                <Input
                  className={styles.search}
                  value={searchText}
                  placeholder='Search'
                  prepend={<Search />}
                  onChange={value => onQueryChange(value)}
                />
              </div>
            </div>

            <div
              className={styles.tableContainer}
              onScroll={onTableContainerScroll}
              ref={tableContainerRef}
            >
              <Table className={styles.table}>
                <THead>
                  <Row className={classNames(styles.row, styles.headRow)}>
                    <Cell className={styles.columnName}>Carer name</Cell>

                    <Cell className={styles.columnDate}>Invoice date</Cell>

                    <Cell className={styles.columnStatus}>Status</Cell>

                    <Cell className={styles.columnHours}>Hours worked</Cell>

                    <Cell className={styles.columnEarnings}>
                      Earnings to date
                    </Cell>
                  </Row>
                </THead>

                {noResultsForFilters ? (
                  <TBody>
                    <Cell className={styles.noSearchResults} colSpan={5}>
                      <NoSearchResult />
                    </Cell>
                  </TBody>
                ) : (
                  <TBody className={styles.tableBody}>
                    {filteredInvoices.map(
                      ({ invoice, displayValues: { period, fullName } }) => (
                        <Row
                          className={classNames(styles.row, styles.bodyRow)}
                          onClick={() => {
                            if (lg) return;

                            onRowClick('123');
                          }}
                          key={invoice.id}
                          hoverable
                        >
                          <Cell>
                            <div className={styles.carer}>
                              <img
                                className={styles.photo}
                                src={invoice?.carer?.imageUrl || circleAvatar}
                              />

                              <span>{fullName}</span>
                            </div>
                          </Cell>

                          <Cell className={styles.period}>{period}</Cell>

                          <Cell>
                            <Chip
                              palette={
                                invoiceStatusColor[
                                  invoice.status as keyof typeof invoiceStatusColor
                                ] as VariantsPalette
                              }
                            >
                              {
                                invoiceStatusNames[
                                  invoice.status as keyof typeof invoiceStatusNames
                                ]
                              }
                            </Chip>
                          </Cell>

                          <Cell>{invoice.totalHours} hr</Cell>

                          <Cell className={styles.earnings} bold>
                            £{invoice.earnings}
                            <div
                              className={styles.goToDetails}
                              onClick={() => onRowClick('123')}
                            >
                              Details
                            </div>
                          </Cell>
                        </Row>
                      )
                    )}
                  </TBody>
                )}
              </Table>
            </div>
          </>
        )}
      </Card>
    )
  );

export { DesktopInvoices };
