import { Link } from 'atomic-router-react';

import { $$restoreCareSpace } from '@features/care-space/restore';

import { $$user } from '@entities/user';

import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { Button } from '@shared/ui/atoms/button';

import { Modal } from '@shared/ui/atoms/modal/modal.component';

import { $$addPayments } from '../model';

import styles from './add-payments.module.scss';

/**
 * <AddPayments />
 */
const AddPayments = createView()
  .units({
    account: $$user.$account,

    isRestored: $$restoreCareSpace.$isRestored,

    onClose: $$addPayments.closed,

    onAddPaymentDetailsClick: $$addPayments.addPaymentDetailsClicked
  })
  .view(({ account, isRestored, onClose, onAddPaymentDetailsClick }) => (
    <Modal.Container onClose={onClose} size='small'>
      <Modal.Header>
        <div className={styles.title}>
          <span className={styles.name}>{account?.firstName}</span>

          <span>{`, welcome ${isRestored ? 'back ' : ''}to Carers HQ!`}</span>
        </div>
      </Modal.Header>

      <Modal.Body className={styles.content}>
        <p className={styles.description}>
          Thank you for confirmation. Now all that's left is to add payment
          details.
        </p>

        <p className={styles.notice}>
          Notice: We want to protect our clients and carers from fraud. It's
          safe and free.
        </p>
      </Modal.Body>

      <Modal.Footer className={styles.footer}>
        <Button
          palette='text'
          variant='text'
          className={styles.cancel}
          onClick={() => onClose()}
        >
          Cancel
        </Button>

        <Link
          to={routes.settings.billing}
          onClick={() => onAddPaymentDetailsClick()}
        >
          <Button>Add payment details</Button>
        </Link>
      </Modal.Footer>
    </Modal.Container>
  ));

export { AddPayments };
