import { createStore, sample } from 'effector';

import { array, object, string } from 'yup';

import { CareType, VisitCareType } from '@shared/api';

import { createForm } from '@shared/lib/form';

import { hourRegEx } from '@shared/lib/utils';

const $schema = createStore<any>(null);

const form = createForm({
  initialValues: {
    visitCareType: 0,

    careTypes: [],

    nightCareTypes: [],

    hoursPerWeek: ''
  },

  $schema
});

sample({
  clock: [form.$values, form.$values.updates],

  source: form.$values,

  fn: values => {
    const fields = {
      nightCareTypes: array().nullable(),

      careTypes: array().nullable().required(),

      hoursPerWeek: string().nullable()
    };

    const shouldValidateNightCareType =
      values.visitCareType == VisitCareType.VisitingCare &&
      values?.careTypes.includes?.(CareType.NightCare);

    if (values.hoursPerWeek) {
      fields.hoursPerWeek = fields.hoursPerWeek
        .matches(hourRegEx, 'Should contain only latin characters')

        .max(100, 'Max length is 100 characters')

        .trim('Should have no spaces at start/end')

        .strict(true);
    }

    if (shouldValidateNightCareType) {
      fields.nightCareTypes = fields.nightCareTypes
        .required()

        .min(1);
    }

    if (shouldValidateNightCareType) {
      fields.nightCareTypes = fields.nightCareTypes
        .required()

        .min(1);
    }

    if (values.visitCareType == VisitCareType.VisitingCare) {
      fields.careTypes = fields.careTypes
        .required('Required')

        .min(1, 'Required');
    }

    return object(fields);
  },

  target: $schema
});

const $$profileCareBasics = {
  form
};

export { $$profileCareBasics, form };
