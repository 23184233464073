import classNames from 'classnames';

import { modelView } from 'effector-factorio';

import { useField } from '@shared/lib/form';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { Form } from '@shared/ui/organisms/form';

import { PostcodeCheckFactory } from '../../model';

import styles from './postcode-check.module.scss';

/**
 * <PostcodeCheck />
 */
const PostcodeCheck = modelView(
  PostcodeCheckFactory,

  createView()
    .map(() => {
      const { form } = PostcodeCheckFactory.useModel();

      const { value, onChange, error, hasError, shouldShowValidation } =
        useField(form.fields.postcode);

      return {
        form,

        value,

        error,

        onChange,

        hasError: shouldShowValidation && hasError
      };
    })

    .view(({ form, value, hasError, onChange }) => (
      <Form
        className={classNames(styles.form, {
          [styles.error]: hasError
        })}
        use={form}
      >
        <input
          className={styles.formInput}
          placeholder='Enter postcode'
          value={value ?? ''}
          spellCheck={false}
          autoCorrect='off'
          onChange={event => onChange(event.target.value)}
        />

        <Button className={styles.submit} type='submit'>
          Continue
        </Button>

        {hasError && (
          <div className={styles.errorMessage}>
            Oops, please enter a valid postcode
          </div>
        )}
      </Form>
    ))
);

export { PostcodeCheck };
