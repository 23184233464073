import type { Carer } from './carer';

import type { Client } from './client';

enum SupportTypeId {
  DayCare = 1,
  SleepingNight = 2,
  WakingNight = 3,
  LiveInCare = 4
}

const supportTypeColor = {
  [SupportTypeId.DayCare]: 'filledPeach',
  [SupportTypeId.SleepingNight]: 'filledBlue',
  [SupportTypeId.WakingNight]: 'filledBlue',
  [SupportTypeId.LiveInCare]: 'filledGreen'
};

const supportTypeNames = {
  [SupportTypeId.DayCare]: 'Day care',
  [SupportTypeId.SleepingNight]: 'Sleeping night',
  [SupportTypeId.WakingNight]: 'Waking night',
  [SupportTypeId.LiveInCare]: 'Live-in care'
};

type SupportType = {
  id?: number;

  name: string;

  carers?: Carer[];

  clients?: Client[];
};

export { SupportTypeId, supportTypeColor, supportTypeNames };

export type { SupportType };
