import medication from '@img/medication_assistance.png';

import classNames from 'classnames';

import { $isInOwnWorkspace } from '@entities/_computed/care-space';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import styles from './no-care-recipients.module.scss';

import type { NoCareRecipientsProps } from './no-care-recipients.props';

const NoCareRecipients = createView<NoCareRecipientsProps>()
  .units({
    isOwnWorkspace: $isInOwnWorkspace
  })

  .view(({ minified, isOwnWorkspace }) => (
    <div
      className={classNames(styles.noClients, {
        [styles.noClientsMinified]: minified
      })}
    >
      <div
        className={classNames(styles.wrapper, {
          [styles.wrapperMinified]: minified
        })}
      >
        {!minified && (
          <img
            className={styles.image}
            src={medication}
            alt='Medication assistance'
          />
        )}

        <h1
          className={classNames(styles.title, {
            [styles.titleMinified]: minified
          })}
        >
          {isOwnWorkspace
            ? 'You don’t have any care recipients yet'
            : 'Care recipients is not there yet '}
        </h1>

        <p
          className={classNames(styles.text, {
            [styles.textMinified]: minified
          })}
        >
          {isOwnWorkspace ? (
            <>
              To fully use Carers HQ you need to add
              <br className={styles.brOne} /> care recipient.
            </>
          ) : (
            <>
              Care recipients will appear here after{' '}
              <br className={styles.brOne} /> they have been added by the owner
              of the care space.
            </>
          )}
        </p>

        {!minified && isOwnWorkspace && (
          <Button className={styles.add} onClick={() => {}}>
            Add new care recipient
          </Button>
        )}
      </div>
    </div>
  ));

export { NoCareRecipients };
