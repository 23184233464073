import { attach, createEvent, createStore, sample } from 'effector';

import { $$careRecipient } from '@entities/care-recipient';

import { $$members } from '@entities/members';

import type { Client, WorkspaceMember } from '@shared/api';

import { carersHQ } from '@shared/api';

import { createForm } from '@shared/lib/form';

type Values = {
  relations: Record<Client['id'], number>;
};

const createEditMemberDetails = () => {
  const updateRelationFx = attach({
    effect: carersHQ.workspaces.updateMemberRelations
  });

  const closed = createEvent();

  const $visible = createStore(false);

  const $member = createStore<WorkspaceMember>(null);

  const $recipients = $$careRecipient.$careRecipients;

  const form = createForm<Values>({
    initialValues: {
      relations: {}
    }
  });

  sample({
    clock: $member.updates,

    fn: member => {
      if (!member) return { relations: {} };

      const relations: Values['relations'] = {};

      member.account.relations.forEach(item => {
        relations[item.client.id as keyof typeof relations] = item
          .clientRelation.id as number;
      });

      return { relations };
    },

    target: form.reset
  });

  sample({
    clock: form.submitted,

    source: $member,

    fn: (member, { relations }) => ({
      relations: Object.entries(relations).map(([clientId, relation]) => ({
        email: member.account.email,
        clientId,
        relation: { id: relation }
      }))
    }),

    target: updateRelationFx
  });

  sample({
    clock: updateRelationFx.doneData,

    target: $$members.getMembersFx
  });

  $visible.reset(closed, updateRelationFx.done);

  $member.reset(closed);

  return {
    form,
    closed,
    $member,
    $visible,
    $recipients
  };
};

export { createEditMemberDetails };
