import medications from '@img/medication_assistance.png';

import medicationsPreview from '@img/medications-preview.png';

import { $$careRecipientsPage } from '@pages/care-recipients/care-recipients.model';

import { CareBasics } from '@pages/care-recipients/pages/profile/ui/care-basics';

import { ConditionExperience } from '@pages/care-recipients/pages/profile/ui/condition-experience';

import { Personal } from '@pages/care-recipients/pages/profile/ui/personal';

import { Preferences } from '@pages/care-recipients/pages/profile/ui/preferences';

import { RequiredSupport } from '@pages/care-recipients/pages/profile/ui/required-support';

import { NoCareRecipients } from '@pages/care-recipients/ui/no-care-recipients';

import { $$deleteAccount } from '@features/care-recipient/delete-account';

import { $$careRecipient } from '@entities/care-recipient';

import { $$careSpace } from '@entities/care-space';

import { $$drafts } from '@entities/drafts';

import { MembersRoleType } from '@shared/api';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { EditableSection } from '@shared/ui/atoms/editable-section';

import { Tabs } from '@shared/ui/atoms/tabs';

import { EmptyPlaceholder } from '../../../ui/empty-placeholder';

import styles from './profile.module.scss';

const Profile = createView<any>()
  .units({
    id: $$careRecipientsPage.$id,

    careRecipients: $$careSpace.$careSpace,

    careSpace: $$careSpace.$careSpace,

    profile: $$careRecipient.$careRecipientsProfile,

    drafts: $$drafts.$drafts,

    onEditDraftClicked: $$drafts.editDraftClicked,

    onDeleteClicked: $$deleteAccount.openModalClicked,

    onRestoreClick: $$deleteAccount.restoreClicked
  })

  .map(({ drafts, id }) => {
    const isDraft = drafts.some(one => one.id == id);

    return {
      isDraft
    };
  })

  .view(
    ({
      id,
      tabChanged,
      tab,
      careRecipients,
      profile,
      isDraft,
      onRestoreClick,
      careSpace: { role },
      onDeleteClicked,
      onEditDraftClicked
    }) => {
      if (!careRecipients.clients.length) {
        return <NoCareRecipients />;
      }

      if (isDraft) {
        return (
          <EmptyPlaceholder
            classNames={{
              text: styles.draftPlaceholderText,
              action: styles.draftPlaceholderAction
            }}
            image={medicationsPreview}
            title='The person almost added'
            text='There are just a few steps left to add a new person to your account.'
            action='Continue adding details'
            onAction={() => onEditDraftClicked(id)}
            styles={{
              ...styles,
              action: styles.continueAdding
            }}
          />
        );
      }

      if (profile?.isDeleted) {
        return (
          <EmptyPlaceholder
            active={false}
            image={medications}
            title='The account has been deleted'
            text='You can restore your account within 2 years of deletion. To do this, click on the button below.'
            action='Restore care recipient'
            onAction={() => onRestoreClick(profile?.id)}
            styles={styles}
          />
        );
      }

      if (!profile) {
        return (
          <div className={styles.loading}>
            <EmptyPlaceholder
              styles={styles}
              title='Loading data...'
              image=''
              text=''
              action=''
              onAction={() => {}}
            />
          </div>
        );
      }

      return (
        <>
          <div className={styles.tabList}>
            <Tabs
              tabs={[
                { id: 'profile', name: 'Profile' },
                { id: 'medication', name: 'Medication' },
                { id: 'wellness', name: 'Wellness' },
                { id: 'contacts', name: 'Contacts' }
              ]}
              className={styles.tab}
              onChange={value => tabChanged(value)}
              selected={tab}
              size='lg'
              variant='inline'
            />
          </div>

          <div className={styles.general}>
            <div className={styles.sections}>
              <Personal />

              <CareBasics />

              <RequiredSupport />

              <ConditionExperience />

              <Preferences />

              {role === MembersRoleType.Owner && (
                <EditableSection title='Delete account' editable={false}>
                  <p className={styles.deletionDisclaimer}>
                    If you want to delete {profile?.firstName}{' '}
                    {profile?.lastName}
                    ’s account you can click button below.
                  </p>

                  <p className={styles.deletionWarning}>
                    Please note, that all data, medications, scheduled meetings,
                    etc., will be permanently deleted.
                  </p>

                  <Button
                    className={styles.deletionSubmit}
                    palette='error'
                    onClick={() => onDeleteClicked()}
                  >
                    Yes, I want to delete account
                  </Button>
                </EditableSection>
              )}
            </div>
          </div>
        </>
      );
    }
  );

export { Profile };
