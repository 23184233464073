type BillingDetails = {
  sortCode: string;
  accountNumber: string;
  IBAN: string;
  password?: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  region: string;
  postalCode: string;
  country: string;
  firstName: string;
  lastName: string;
};

enum AccountType {
  UK = 'UK account',
  EU = 'EU account'
}

type PublicBillingDetails = {
  ending: number;
  type: AccountType;
};

export { AccountType };

export type { BillingDetails, PublicBillingDetails };
