import classNames from 'classnames';

import { $$accountInfo } from '@entities/account';

import { createView } from '@shared/lib/view';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { form } from '../model';

import styles from './personal-details.module.scss';

const PersonalDetailsForm = createView()
  .units({
    account: $$accountInfo.$account
  })

  .view(({ account }) => (
    <Form use={form}>
      <div className={styles.formFieldsContainer}>
        <section className={classNames(styles.formContainer, styles.row)}>
          <Field.Input
            use={form.fields.firstName}
            name='firstName'
            placeholder='Enter'
            label='First name'
            errorIn='label'
          />

          <Field.Input
            use={form.fields.lastName}
            className={styles.lastName}
            placeholder='Enter'
            name='lastName'
            label='Last name'
            errorIn='label'
          />
        </section>

        <section className={classNames(styles.emailWrapper)}>
          <p className={styles.emailInfo}>
            Your current email{' '}
            <span className={styles.accent}>{account?.email}</span>. <br /> If
            you want to change it, please enter new email and your current
            password.
          </p>

          <div className={classNames(styles.formContainer, styles.row)}>
            <Field.Input
              use={form.fields.email}
              type='email'
              disableAutocomplete
              label='New email'
              placeholder='Enter'
              errorIn='label'
            />

            <Field.Input
              use={form.fields.password}
              placeholder='Enter'
              type='password'
              disableAutocomplete
              label='Current password'
              errorIn='label'
            />
          </div>
        </section>

        <section className={classNames(styles.formContainer, styles.row)}>
          <Field.PhoneInput
            use={form.fields.phone}
            name='phone'
            placeholder='Enter'
            label='Phone'
            errorIn='label'
          />
        </section>
      </div>
    </Form>
  ));

export { PersonalDetailsForm };
