import { createStore } from 'effector';

import { modelFactory } from 'effector-factorio';

import { array, boolean, object } from 'yup';

import type { Certificate, Qualification } from '@shared/api';

import { createForm } from '@shared/lib/form';

type QualificationsValues = {
  dbs: number;

  publicInsuranse: boolean;

  qualificationConfirm: boolean;

  publicInsuranseConfirmed: boolean;

  certificates: Certificate['id'][];

  qualifications: Qualification['id'][];
};

const ManageQualificationsModelFactory = modelFactory(() => {
  const form = createForm<QualificationsValues>({
    initialValues: {
      dbs: null,

      publicInsuranse: false,

      qualificationConfirm: true,

      certificates: [] as number[],

      qualifications: [] as number[],

      publicInsuranseConfirmed: true
    } as QualificationsValues,

    $schema: createStore(
      object({
        qualifications: array()
          .min(1, 'Required')
          .required()
          .nullable()
          .label('Qualifications'),

        qualificationConfirm: boolean()
          .required()
          .nullable()
          .label('Confirmation')
          .oneOf([true]),

        publicInsuranseConfirmed: boolean()
          .required()
          .nullable()
          .oneOf([true])
          .label('Confirmation'),

        certificates: array()
          .nullable()
          .required()
          .min(1, 'Required')
          .label('Certificates'),

        publicInsuranse: boolean()
          .required()
          .nullable()
          .label('Public insurance')
      })
    )
  });

  return {
    form
  };
});

export type { QualificationsValues };

export { ManageQualificationsModelFactory };
