import classNames from 'classnames';

import { $$restoreCareSpace } from '@features/care-space/restore';

import { $$billing } from '@entities/billing';

import { $$user } from '@entities/user';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { useMedia } from '@shared/ui/atoms/media';

import { Modal } from '@shared/ui/atoms/modal';

import { $$workspaceCreated } from './workspace-created.model';

import styles from './workspace-created.module.scss';

const WorkspaceCreated = createView()
  .units({
    account: $$user.$account,

    isRestored: $$restoreCareSpace.$isRestored,

    onClose: $$workspaceCreated.closeModal,

    onUpdatePaymentDetailsClicked: $$workspaceCreated.updatePaymentDetails
  })

  .open($$billing.getBillingDetailsFx)

  .map(() => {
    const onUpdatePaymentDetails = () => {
      // $$settings.updatePaymentDetailsClicked(
      //   isRestored
      //     ? BillingModalType.WorkspaceCreatedRestored
      //     : BillingModalType.WorkspaceCreatedNew
      // );
      //
      // setTimeout(() => {
      //   $$settings.workspaceCreatedClosed();
      // }, 0);
    };

    const md = useMedia('>=md');

    return {
      md
    };
  })

  .view(
    ({ md, isRestored, account, onClose, onUpdatePaymentDetailsClicked }) => (
      <Modal.Container
        size={isRestored ? 'medium' : 'large'}
        onClose={() => onClose()}
        fullHeight={md && !isRestored}
      >
        <Modal.Header className={styles.header}>
          <h2 className={styles.title}>
            {account?.firstName}, your Care Space has been{' '}
            {isRestored ? 'restored' : 'created'}
          </h2>
        </Modal.Header>

        <Modal.Body className={styles.content}>
          <p className={styles.contentTitle}>
            We are happy to have you as part of our service Carers HQ.
          </p>

          <p className={styles.infoText}>
            To continue using the Carers HQ you need to make{' '}
            {isRestored ? '1 simple step' : '2 simple steps'}:
          </p>

          <ul className={styles.actions}>
            <li className={styles.actionItem}>
              - Update payment details{' '}
              <span>
                (It's free. You will not be charged any money until you receive
                services from the carer)
              </span>
            </li>

            {!isRestored && (
              <li className={styles.actionItem}>
                - Add a carer recipient{' '}
                <span>
                  (To fully use Carers HQ you need to add care recipient).
                </span>
              </li>
            )}
          </ul>
        </Modal.Body>

        <Modal.Footer
          className={classNames(styles.footer, {
            [styles.newType]: !isRestored
          })}
        >
          <Button
            className={styles.cancel}
            onClick={() => onClose()}
            palette='text'
            variant='text'
          >
            Cancel
          </Button>

          <Button
            className={styles.submit}
            onClick={() => onUpdatePaymentDetailsClicked()}
            palette='primary'
          >
            Update payment details
          </Button>
        </Modal.Footer>
      </Modal.Container>
    )
  );

export { WorkspaceCreated };
