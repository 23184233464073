import { createStore, sample } from 'effector';

import { modelFactory } from 'effector-factorio';

import { boolean, object, string } from 'yup';

import { createForm } from '@shared/lib/form';

import { passwordRegEx } from '@shared/lib/utils';

import { routes } from '@shared/routes';

type CreateAccountValues = {
  email: string;
  password: string;
  confirmPassword: string;
  terms: boolean;
  policy: boolean;
};

const CreateAccountModelFactory = modelFactory(() => {
  const $schema = createStore<any>(null);

  const form = createForm<CreateAccountValues>({
    initialValues: {
      email: '',

      password: '',

      confirmPassword: '',

      terms: false,

      policy: false
    },

    $schema
  });

  sample({
    clock: [
      form.$values.updates,
      form.$values,
      routes.signUp.carer.createAccount.$isOpened,
      routes.signUp.lovedOne.createAccount.$isOpened,
      routes.signUp.myself.createAccount.$isOpened
    ],

    source: form.$values,

    fn: values => {
      const fields = {
        email: string()
          .nullable()

          .required('Required')

          .email()

          .label('Email')

          .trim('Required'),

        password: string()
          .nullable()

          .required('Required')

          .trim('Required')

          .label('Password')

          .matches(
            passwordRegEx,

            'Least 6 digits long and contain numbers and upper case'
          )

          .min(6, 'Least 6 digits long and contain numbers and upper case'),

        confirmPassword: string()
          .nullable()

          .required('Required')

          .trim('Required')

          .label('Confirm password'),

        terms: boolean()
          .oneOf([true], 'Required')

          .nullable()

          .required('Required')

          .label('Terms'),

        policy: boolean()
          .oneOf([true], 'Required')

          .nullable()

          .required('Required')

          .label('Privacy policy')
      };

      if (values.confirmPassword) {
        fields.password = fields.password
          .required()
          .equals(
            [values.confirmPassword],
            `Passwords don't match. Check it and try again`
          ) as any;
      }

      if (values.password) {
        fields.confirmPassword = fields.confirmPassword
          .required()
          .equals(
            [values.password],
            `Passwords don't match. Check it and try again`
          ) as any;
      }

      return object(fields);
    },

    target: $schema
  });

  return {
    form
  };
});

export type { CreateAccountValues };

export { CreateAccountModelFactory };
