import pencilEdit from '@icon/pencil-edit.svg';

import { $$shared } from '@features/billing/billing-details/model';

import { $$billing } from '@entities/billing';

import { AccountType } from '@shared/api';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { $$billingPage } from './billing.model';

import styles from './billing.module.scss';

/**
 * <Billing />
 */
const Billing = createView()
  .units({
    publicDetails: $$billing.$publicDetails,

    ready: $$billingPage.$ready,

    showModalCLick: $$shared.billingDetailsOpened
  })

  .view(({ ready, publicDetails, showModalCLick }) => (
    <div className={styles.billing}>
      <h2 className={styles.title}>Billing details</h2>

      {ready &&
        (publicDetails ? (
          <>
            <p className={styles.warning}>
              For security reasons, we do not show full payment information. If
              you want to change your information, click on the edit icon.
            </p>

            <div className={styles.details}>
              <div className={styles.ending}>
                Ending in <span>{publicDetails.ending}</span>
              </div>

              <div className={styles.type}>
                {publicDetails.type == AccountType.UK
                  ? 'Account number'
                  : 'IBAN'}
              </div>

              <div className={styles.edit}>
                <img src={pencilEdit} onClick={() => showModalCLick()} />
              </div>
            </div>
          </>
        ) : (
          <>
            <p className={styles.noDataWarning}>
              To fully use Carers HQ, you need to add your billing details. It's
              completely secure and free of charge.
            </p>

            <Button className={styles.add} onClick={() => showModalCLick()}>
              Add billing details
            </Button>
          </>
        ))}
    </div>
  ));

export { Billing };
