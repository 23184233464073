import { createRoutesView } from 'atomic-router-react';

import { modelView } from 'effector-factorio';

import { CreateAccount } from '@pages/sign-up/ui/create-account';

import { StepWizard } from '@pages/sign-up/ui/sign-up-wizard-step';

import { AdditionalInformationForm } from '@features/carer/manage-additional-information';

import { EmploymentDetailsForm } from '@features/carer/manage-employment-details';

import { QualificationsForm } from '@features/carer/manage-qualifications';

import { ServicesForm } from '@features/carer/manage-services';

import { ContactDetailsForm } from '@features/user/manage-contact-details';

import { PersonalInformationForm } from '@features/user/manage-personal-information';

import { routes } from '@shared/routes';

import { CtaBanner } from '@shared/ui/atoms/cta-banner';

import { useMedia } from '@shared/ui/atoms/media';

import { styled } from '@shared/ui/stitches';

import { CarerSkills } from './carer-skills';

import { $$signUpCarer, SignUpCarerModelFactory } from './carer.model';

const Container = styled('div', {
  flex: 1,

  background: '#fff'
});

const Wrap = styled('br', {
  display: 'none',

  '@sm': {
    display: 'initial'
  },

  '@md': {
    display: 'none'
  }
});

const WrapMd = styled('br', {
  display: 'none',

  '@md': {
    display: 'initial'
  }
});

const PersonalInformation = () => {
  const mobile = useMedia('<sm');

  return (
    <>
      <CtaBanner letterSpacing>
        <p>
          Great! <br />
          First, tell me a little bit {mobile && <br />} about yourself.
        </p>
      </CtaBanner>

      <Container>
        <StepWizard
          model={$$signUpCarer.wizards.personalInformation}
          key='personal-information'
          title='Personal information'
        >
          <PersonalInformationForm
            model={$$signUpCarer.steps.personalInformation}
          />
        </StepWizard>
      </Container>
    </>
  );
};

const ContactDetails = () => {
  const mobile = useMedia('<sm');

  return (
    <>
      <CtaBanner letterSpacing>
        <p>
          Thanks ! <br />
          And where can I {mobile && <br />} reach you?
        </p>
      </CtaBanner>

      <Container>
        <StepWizard
          model={$$signUpCarer.wizards.contactDetails}
          key='contact-details'
          title='Contact Details'
        >
          <ContactDetailsForm model={$$signUpCarer.steps.contactDetails} />
        </StepWizard>
      </Container>
    </>
  );
};

const Services = () => {
  const mobile = useMedia('<sm');

  return (
    <>
      <CtaBanner letterSpacing>
        <p>
          Now, what type of care do {mobile && <br />} you provide{' '}
          {!mobile && <br />} & what are {mobile && <br />} your rates?
        </p>
      </CtaBanner>

      <Container>
        <StepWizard
          model={$$signUpCarer.wizards.services}
          key='services'
          title='Services'
        >
          <ServicesForm model={$$signUpCarer.steps.services} />
        </StepWizard>
      </Container>
    </>
  );
};

const Qualifications = () => {
  const mobile = useMedia('<sm');

  return (
    <>
      <CtaBanner letterSpacing>
        <p>
          Next, please tell me {mobile && <br />} about your {!mobile && <br />}{' '}
          care {mobile && <br />} qualifications.
        </p>
      </CtaBanner>

      <Container>
        <StepWizard
          model={$$signUpCarer.wizards.qualifications}
          key='qualifications'
          title='Qualifications'
        >
          <QualificationsForm model={$$signUpCarer.steps.qualifications} />
        </StepWizard>
      </Container>
    </>
  );
};

const AdditionalInformation = () => (
  <>
    <CtaBanner letterSpacing>
      <p>
        Great! <br />
        And now, tell me a little more about yourself.
      </p>
    </CtaBanner>

    <Container>
      <StepWizard
        model={$$signUpCarer.wizards.additionalInformation}
        key='additional-information'
        title='Additional Information'
      >
        <AdditionalInformationForm
          model={$$signUpCarer.steps.additionalInformation}
        />
      </StepWizard>
    </Container>
  </>
);

const EmploymentDetails = () => {
  const mobile = useMedia('<sm');

  return (
    <>
      <CtaBanner letterSpacing>
        <p>
          Lastly, tell me a little more {mobile && <br />} about{' '}
          {!mobile && <br />}
          your work {mobile && <br />} as a carer.
        </p>
      </CtaBanner>

      <Container>
        <StepWizard
          model={$$signUpCarer.wizards.employmentDetails}
          key='employment-details'
          title='Employment Details'
        >
          <EmploymentDetailsForm
            model={$$signUpCarer.steps.employmentDetails}
          />
        </StepWizard>
      </Container>
    </>
  );
};

const carerViews = [
  {
    route: routes.signUp.carer.personalInformation,

    view: PersonalInformation
  },

  {
    route: routes.signUp.carer.contactDetails,

    view: ContactDetails
  },

  {
    route: routes.signUp.carer.services,

    view: Services
  },

  {
    route: routes.signUp.carer.qualifications,

    view: Qualifications
  },

  {
    route: routes.signUp.carer.additionalInformation,

    view: AdditionalInformation
  },

  {
    route: routes.signUp.carer.employmentDetails,

    view: EmploymentDetails
  },

  {
    route: routes.signUp.carer.skills,

    view: CarerSkills
  },

  {
    route: routes.signUp.carer.createAccount,

    view: () => (
      <CreateAccount
        asCarer
        stepWizardModel={$$signUpCarer.$$createAccountWizard}
        createAccountModel={$$signUpCarer.$$createAccount}
      />
    )
  }
];

const Steps = createRoutesView({
  routes: carerViews
});

const SignUpCarerPage = modelView(SignUpCarerModelFactory, () => <Steps />);

export { SignUpCarerPage, carerViews };
