import type { CareNeedsValues } from '@features/care-recipient/manage-care-needs';

import type { ConditionExperienceValues } from '@features/care-recipient/manage-condition-experience';

import type { ManageCareRecipientDetailsValues } from '@features/care-recipient/manage-details';

import type { PreferencesValues } from '@features/care-recipient/manage-preferences';

enum ClientType {
  Carer = 'carer',
  Myself = 'myself',
  Loved = 'loved'
}

type ClientTypeSelectionValues = {
  clientType: ClientType;
};

type PostcodeCheckValues = {
  postCode: string;
};

export enum ModalSteps {
  PostcodeCheck = 0,
  ClientTypeSelection = 1,
  CareNeeds = 2,
  ConditionExperience = 3,
  Preferences = 4,
  CareRecipientInfo = 5,
  Cancel = 6
}

type AddCareRecipientValues = PostcodeCheckValues &
  ClientTypeSelectionValues &
  CareNeedsValues &
  ConditionExperienceValues &
  PreferencesValues &
  ManageCareRecipientDetailsValues;

export type {
  AddCareRecipientValues,
  ClientTypeSelectionValues,
  PostcodeCheckValues
};
