import { $$emailConfirmation, form } from '@widgets/email-confirmation/model';

import { $$user } from '@entities/user';

import { createView } from '@shared/lib/view';

import { Button, ButtonGroup } from '@shared/ui/atoms/button';

import { useMedia } from '@shared/ui/atoms/media';

import { Modal } from '@shared/ui/atoms/modal';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import styles from './change-email.module.scss';

/**
 * <ChangeEmail />
 */
const ChangeEmail = createView()
  .units({
    account: $$user.$account,

    onSubmit: $$emailConfirmation.form.submit,

    onCancelClick: $$emailConfirmation.closeClicked,

    onPreviousClick: $$emailConfirmation.backFromEmailChangeClicked
  })

  .map(() => {
    const md = useMedia('>md');

    return { md };
  })

  .view(
    ({
      account,
      onCancelClick,
      onPreviousClick,
      onSubmit: onSubmitClick,
      md
    }) => (
      <Modal.Container onClose={onCancelClick} size='small'>
        <Modal.Header>Changing email</Modal.Header>

        <Modal.Body className={styles.content}>
          <p className={styles.description}>
            Your current email <span>{account?.email}.</span> <br /> If you want
            to change it, please enter new email and your current password.
          </p>

          <Form className={styles.fields} use={$$emailConfirmation.form}>
            <Field.Input
              use={form.fields.email}
              errorIn='helperText'
              label='New email'
              placeholder='Enter'
            />

            <Field.Input
              use={form.fields.password}
              type='password'
              errorIn='helperText'
              placeholder='Enter'
              label='Current password'
            />
          </Form>
        </Modal.Body>

        <Modal.Footer space className={styles.footer}>
          <Button
            variant='text'
            palette='text'
            className={styles.cancel}
            onClick={() => onPreviousClick()}
          >
            Previous {md && 'step'}
          </Button>

          <ButtonGroup>
            <Button
              onClick={() => onCancelClick()}
              variant='text'
              palette='text'
            >
              Cancel
            </Button>

            <Button onClick={() => onSubmitClick()}>Change email</Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal.Container>
    )
  );

export { ChangeEmail };
