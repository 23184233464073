import { createEvent, createStore, sample } from 'effector';

import { $$password } from '@features/account/password';

import { $$personalDetails } from '@features/account/personal-details';

import { ToastText } from '@features/account/personal-details/ui/toast';

import { createSetAccountRelations } from '@features/account/set-relations';

import { $$createCareSpace } from '@features/care-space/create';

import { $$confirmUserEmail } from '@features/user/email/confirm';

import { $$logoutUser } from '@features/user/logout';

import { $$accountInfo } from '@entities/account';

import { $$careSpace } from '@entities/care-space';

import { $$members } from '@entities/members';

import { ToastID } from '@shared/config';

import { routes } from '@shared/routes';

import { createEditableSection } from '@shared/ui/atoms/editable-section';

import type { NotifyOptions } from '@shared/ui/organisms/toasts';

import { $$toast } from '@shared/ui/organisms/toasts';

const verifyClicked = createEvent();

const resetPasswordClicked = createEvent();

const createCareSpace = createEvent();

const personalDetails = createEditableSection();

const password = createEditableSection();

const editCareRecipietnsClicked = createEvent();

const careRecipients = createEditableSection();

const saveCareRecipientsClicked = createEvent();

const $verifyEmailSent = createStore(false);

const $$setAccountRelations = createSetAccountRelations();

const emailSent = sample({
  clock: $$confirmUserEmail.sendEmailConfirmationFx.done,

  filter: routes.settings.account.$isOpened
});

const $$accountPage = {
  $$setAccountRelations,

  $verifyEmailSent,

  personalDetails,

  password,

  saveCareRecipientsClicked,

  createCareSpace,

  editCareRecipietnsClicked,

  resetPasswordClicked,

  careRecipients,

  verifyClicked
};

sample({
  clock: routes.settings.account.opened,

  target: [$$accountInfo.getAccountFx]
});

sample({
  clock: routes.settings.account.opened,

  filter: $$careSpace.$careSpaceID.map(Boolean),

  target: $$members.getMembersFx
});

sample({
  clock: createCareSpace,

  target: $$createCareSpace.createCareSpaceFx
});

sample({
  clock: verifyClicked,

  target: $$confirmUserEmail.sendEmailConfirmationFx
});

sample({
  clock: $$confirmUserEmail.sendEmailConfirmationFx.doneData,

  source: $$accountInfo.$account,

  filter: routes.settings.account.$isOpened,

  fn: account =>
    ({
      id: ToastID.ConfirmationLinkHasBeenSent,
      type: 'success',
      title: `Confirmation link has been sent to <b style="word-break: break-all">${account?.email}</b>`,
      content: ToastText
    } as NotifyOptions),

  target: $$toast.notifyFx
});

sample({
  clock: $$accountInfo.updatePersonalDetailsFx.doneData,

  target: personalDetails.close
});

sample({
  clock: $$password.updatePasswordFx.doneData,

  target: [password.close]
});

sample({
  clock: password.close,

  target: $$password.form.reset
});

sample({
  clock: editCareRecipietnsClicked,

  target: careRecipients.open
});

sample({
  clock: personalDetails.close,

  source: $$accountInfo.$account,

  fn: ({ firstName = '', lastName = '', phone = '' }) => ({
    firstName,
    lastName,
    phone,
    email: '',
    password: ''
  }),

  target: $$personalDetails.form.reset
});

sample({
  clock: editCareRecipietnsClicked,

  target: $$setAccountRelations.resetRelations
});

sample({
  clock: saveCareRecipientsClicked,

  target: $$setAccountRelations.form.submit
});

sample({
  clock: $$setAccountRelations.updateRelationFx.finally,

  target: careRecipients.close
});

sample({
  clock: resetPasswordClicked,

  target: $$logoutUser.logout.prepend(() => 'recovery')
});

$verifyEmailSent
  .on(emailSent, () => true)

  .reset(routes.settings.account.closed);

export { $$accountPage };
