import classNames from 'classnames';

import moment from 'moment';

import { useEffect, useRef, useState } from 'react';

import { $$profilePage } from '@pages/profile/profile.model';

import { form } from '@features/carer/profile/media';

import { $$carerProfile } from '@entities/carer/model';

import { ToastID } from '@shared/config';

import { useUpdate } from '@shared/lib/hooks';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { Card } from '@shared/ui/atoms/card';

import { Form } from '@shared/ui/organisms/form';

import { $$toast } from '@shared/ui/organisms/toasts';

import styles from './media.module.scss';

/**
 * <CarerMedia />
 */
const Media = createView()
  .units({
    carer: $$carerProfile.$carerProfile,

    error: $$profilePage.$mediaError,

    submitMedia: $$profilePage.submitMedia,

    resetError: $$profilePage.resetError,

    onDownloadLargeFile: $$profilePage.downloadLargeFile,

    imageUrlValue: form.fields.image.url.$value,

    videoUrlValue: form.fields.video.url.$value
  })

  .map(
    ({
      carer,
      submitMedia,
      resetError,
      onDownloadLargeFile,
      error,
      imageUrlValue,
      videoUrlValue
    }) => {
      const photoRef = useRef<HTMLInputElement>();

      const promoRef = useRef<HTMLInputElement>();

      const videoRef = useRef<HTMLVideoElement>();

      const [playing, setPlaying] = useState(false);

      const isInitial = !carer?.imageUrl || !carer?.videoUrl;

      const onFileChange =
        (max: number, field: string) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          const file = event.target.files.item(0);

          event.target.value = '';

          if (!file) {
            return;
          }

          const mb = file.size / (1024 * 1024);

          resetError();

          if (mb > max) {
            onDownloadLargeFile(max);

            return;
          }

          if (field === 'video') {
            form.fields.video.change({ file, url: URL.createObjectURL(file) });
          }

          if (field === 'image') {
            form.fields.image.change({ file, url: URL.createObjectURL(file) });
          }

          if (isInitial) {
            return;
          }

          submitMedia();
        };

      const onPlayClick = () => {
        setPlaying(true);

        videoRef.current.play();
      };

      const onVideoClick = () => {
        videoRef.current.pause();

        setPlaying(false);
      };

      useEffect(() => {
        if (carer && (!carer?.imageUrl || !carer?.videoUrl)) {
          $$toast.notifyFx({
            type: 'warning',
            title: `${carer?.firstName}, welcome to Carers HQ`,
            id: ToastID.LegitCarer,
            content: () => (
              <div className={styles.add}>
                There is one last step before using the service.{' '}
                <span onClick={() => photoRef.current.click()}>
                  Add your photo
                </span>{' '}
                and{' '}
                <span onClick={() => promoRef.current.click()}>
                  promo video
                </span>
                .
              </div>
            ),
            maxWidth: 760,
            duration: 30000
          });
        }

        return () => {
          // TODO: add close toast on leave
          // dispatch(toast.close('legit-carer-toast'));
        };
      }, [carer]);

      useUpdate(() => {
        // TODO: implement
        // dispatch(
        //   toast.patch('legit-carer-toast', {
        //     type: 'error'
        //   })
        // );

        if (error) {
          $$toast.notifyFx({
            type: 'error',
            title: `${carer?.firstName}, welcome to Carers HQ`,
            id: ToastID.LegitCarer,
            content: () => (
              <div className={styles.add}>
                There is one last step before using the service.{' '}
                {!videoUrlValue && !imageUrlValue ? (
                  <>
                    <span onClick={() => photoRef.current.click()}>
                      Add your photo
                    </span>{' '}
                    and{' '}
                    <span onClick={() => promoRef.current.click()}>
                      promo video
                    </span>
                    .
                  </>
                ) : !imageUrlValue ? (
                  <span onClick={() => photoRef.current.click()}>
                    Add your photo
                  </span>
                ) : (
                  <span onClick={() => promoRef.current.click()}>
                    Add your promo video
                  </span>
                )}
              </div>
            ),
            maxWidth: 760,
            duration: 30000
          });
        }
      }, [error]);

      return {
        form,
        playing,
        photoRef,
        promoRef,
        videoRef,
        isInitial,
        onPlayClick,
        onVideoClick,
        onFileChange
      };
    }
  )

  .view(
    ({
      photoRef,
      promoRef,
      error,
      form,
      carer,
      isInitial,
      playing,
      onVideoClick,
      onPlayClick,
      onFileChange,
      videoRef,
      imageUrlValue,
      submitMedia,
      videoUrlValue
    }) => (
      <Card
        className={classNames(styles.media, {
          [styles.hasError]: error
        })}
      >
        <Form use={form} className={styles.mediaForm}>
          <div className={styles.mediaCarer}>
            <div
              className={classNames(styles.avatar, {
                [styles.hasImage]: imageUrlValue
              })}
              onClick={() => photoRef.current.click()}
            >
              <img src={imageUrlValue} />

              <div className={styles.overlay} />
            </div>

            <div className={styles.mediaCarerContent}>
              <div className={styles.mediaCarerName}>
                {carer?.firstName} {carer?.lastName}
              </div>

              <div className={styles.mediaCarerStats}>
                {carer?.gender?.name},{' '}
                {Math.abs(moment(carer?.dateOfBirth).diff(moment(), 'years'))}{' '}
                years
              </div>
            </div>
          </div>

          <div className={styles.vidioWrapper}>
            {videoUrlValue ? (
              <div
                className={classNames(styles.video, {
                  [styles.playing]: playing
                })}
              >
                <video
                  ref={videoRef}
                  controls={false}
                  onClick={onVideoClick}
                  key={videoUrlValue}
                >
                  <source type='video/mp4' src={`${videoUrlValue}#t=0.001`} />
                </video>

                <div className={styles.overlay}>
                  <div className={styles.play} onClick={onPlayClick} />

                  <div
                    className={styles.upload}
                    onClick={() => promoRef.current.click()}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div
                  className={styles.uploadVideo}
                  onClick={() => promoRef.current.click()}
                >
                  <div className={styles.uploadVideoIcon} />

                  <div className={styles.uploadVideoText}>
                    Upload your promo-video
                  </div>
                </div>
              </div>
            )}

            {isInitial && (
              <Button className={styles.save} onClick={() => submitMedia()}>
                Save files
              </Button>
            )}
          </div>

          <input
            className={styles.photoInput}
            ref={photoRef}
            type='file'
            accept='image/*'
            onChange={onFileChange(5, 'image')}
          />

          <input
            className={styles.promoInput}
            ref={promoRef}
            accept='video/*'
            type='file'
            onChange={onFileChange(100, 'video')}
          />
        </Form>
      </Card>
    )
  );

export { Media };
