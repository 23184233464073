enum FieldKey {
  $value = '$value',
  $touched = '$touched',
  $dirty = '$dirty',
  $focused = '$focused',
  $errors = '$errors',
  changed = 'changed',
  path = 'path',
  change = 'change',
  blured = 'blured',
  focused = 'focused',
  meta = '__meta__',
  formMeta = '__formMeta__'
}

export { FieldKey };
