import { chainRoute, createRoute } from 'atomic-router';

import axios from 'axios';

import type { Event } from 'effector';

import { sample } from 'effector';

import { splitMap } from 'patronum';

import { $$logoutUser } from '@features/user/logout';

import { $$session } from '@entities/session';

import { carersHQ } from '@shared/api';

import { routes } from '@shared/routes';

import { $$toast } from '@shared/ui/organisms/toasts';

const signUpAfterInviteRoute = createRoute();

const requestFaieldWith401 = carersHQ.http.baseRequestFx.failData.filter({
  fn: error => axios.isAxiosError(error) && error?.response?.status == 401
});

const loggedOut = sample({
  clock: [$$logoutUser.logout, requestFaieldWith401 as any as Event<void>]
}) as Event<'recovery' | 'invite' | void>;

const loggedOutTo = splitMap({
  source: loggedOut,

  cases: {
    invite: value => value == 'invite',

    recovery: value => value == 'recovery'
  }
});

const $$logout = {
  loggedOut
};

chainRoute({
  route: routes.auth.signUpInvite,

  beforeOpen: $$logoutUser.logout.prepend(() => 'invite'),

  openOn: loggedOutTo.invite,

  chainedRoute: signUpAfterInviteRoute
});

sample({
  clock: loggedOut,

  target: $$session.ended
});

sample({
  clock: loggedOutTo.recovery,

  fn: () => ({
    params: {},
    query: {},
    replace: true
  }),

  target: routes.auth.recovery.navigate
});

sample({
  clock: loggedOut,

  target: $$toast.closeNotify
});

sample({
  clock: loggedOutTo.__,

  fn: () => ({
    params: {},
    query: {},
    replace: true
  }),

  target: routes.auth.signIn.navigate
});

export { $$logout, signUpAfterInviteRoute };
