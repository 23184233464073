import { sample } from 'effector';

import { $$signUpCarer } from '@pages/sign-up/pages/carer';

import { routes } from '@shared/routes';

import { $carerValues } from './shared';

const { submitted } = $$signUpCarer.steps.personalInformation.form;

$carerValues.on(submitted, (state, values) => ({
  ...state,

  personalInformation: values
}));

sample({
  clock: submitted,

  source: routes.signUp.carer.personalInformation.$params,

  target: routes.signUp.carer.contactDetails.open
});

sample({
  clock: submitted,

  fn: values => values.dateOfBirth,

  target: $$signUpCarer.steps.employmentDetails.$dateOfBirth
});

sample({
  clock: routes.signUp.carer.personalInformation.opened,

  source: $carerValues,

  fn: values => values.personalInformation ?? null,

  target: $$signUpCarer.steps.personalInformation.form.reset
});
