import { CareMatch } from '@entities/carer/ui/carer-match';

import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { ReactComponent as Tip } from '@shared/ui/assets/icons/tip.svg';

import { Breadcrumbs } from '@shared/ui/atoms/breadcrumbs';

import { Switch } from '@shared/ui/atoms/switch';

import styles from './carer-matches.module.scss';

import { useCarerMatchesProps } from './carer-matches.props';

const CarerMatches = createView()
  .map(useCarerMatchesProps)
  .view(({ hasMore, shortNotice, matches, loadMore, onShortNoticeChange }) => (
    <div className={styles.carerMatches}>
      <div className={styles.head}>
        <div className={styles.breadcrumbs}>
          <Breadcrumbs
            steps={[
              {
                label: 'Care team',

                route: routes.team.my
              },

              {
                label: 'Carer matches',

                route: routes.matches
              }
            ]}
          />

          <div>
            <Tip className={styles.tip} />
          </div>
        </div>

        <div className={styles.switch}>
          <Switch
            className={styles.shortNotice}
            value={shortNotice}
            onChange={onShortNoticeChange}
            label='Show only carers available on short notice'
          />
        </div>
      </div>

      {matches.length > 0 ? (
        <div className={styles.matches}>
          {matches.map(item => (
            <CareMatch carer={item} key={item.id} />
          ))}
        </div>
      ) : (
        <div>No results...</div>
      )}

      {hasMore && (
        <button className={styles.loadMore} onClick={() => loadMore()}>
          Load more carers
        </button>
      )}
    </div>
  ));

export { CarerMatches };
