import { styled } from '@shared/ui/stitches';

const AlertBox = styled('div', {
  padding: '12px 16px 14px 12px',

  border: '1px solid transparent',

  borderRadius: '6px',

  '@md': {
    padding: '$2',
    paddingTop: '12px'
  },

  variants: {
    variant: {
      warning: {
        borderColor: '$peach30',
        color: '$textGrey',
        background: '$yellow5'
      },

      error: {
        borderColor: '$red',
        background: '#FFF5F5'
      },

      info: {
        borderColor: '$divider',
        background: 'transparent'
      }
    }
  },

  defaultVariants: {
    variant: 'info'
  }
});

export { AlertBox };
