import { MembersRoleType } from '@shared/api';

const accessLevelsForSelect = Object.values(MembersRoleType)
  .filter(accessLevel => accessLevel !== MembersRoleType.Owner)

  .map(accessLevel => ({
    id: accessLevel,
    name: accessLevel
  }));

export { accessLevelsForSelect };
