import classNames from 'classnames';

import {
  $$personalDetails,
  PersonalDetailsForm
} from '@features/account/personal-details';

import { $$accountInfo } from '@entities/account';

import { $$user } from '@entities/user';

import { connect, createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { EditableSection } from '@shared/ui/atoms/editable-section';

import { TextPair } from '@shared/ui/atoms/text-pair';

import { $$accountPage } from '../../account.model';

import styles from './personal-details.module.scss';

const EmailInfo = createView()
  .units({
    email: $$accountInfo.$account.map(state => state?.email ?? ''),

    verified: $$user.$account.map(state => Boolean(state?.emailConfirmed)),

    verifySent: $$accountPage.$verifyEmailSent,

    onVerifyClick: $$accountPage.verifyClicked.prepend(() => {})
  })

  .view(({ email, verified, verifySent, onVerifyClick }) => (
    <div className={styles.wrapper}>
      <p className={styles.email}>{email}</p>

      {!verified && (
        <div className={styles.emailStatus}>
          <span className={styles.badge}>Unverified</span>

          {!verifySent && (
            <Button
              variant='text'
              palette='text'
              className={styles.verify}
              onClick={onVerifyClick}
            >
              Verify
            </Button>
          )}
        </div>
      )}
    </div>
  ));

const Section = connect(EditableSection)
  .static({
    title: 'Personal details'
  })

  .units({
    editing: $$accountPage.personalDetails.$opened,

    onCancel: $$accountPage.personalDetails.close.prepend(() => {}),

    onEdit: $$accountPage.personalDetails.open.prepend(() => {}),

    onSave: $$personalDetails.form.submit.prepend(() => {})
  })

  .view();

const PersonalDetails = createView()
  .units({
    account: $$accountInfo.$account
  })

  .view(({ account }) => (
    <Section>
      {({ editing }) => {
        if (editing) {
          return <PersonalDetailsForm />;
        }

        return (
          <div className={styles.formFieldsContainer}>
            <TextPair
              className={classNames(styles.emailTextPair)}
              label='Full name:'
              value={`${account?.firstName} ${account?.lastName}`}
              wrap
            />

            <TextPair
              className={classNames(styles.emailTextPair)}
              label='Email:'
              value={<EmailInfo />}
              nowrapOverflow
            />

            <TextPair label='Phone:' value={account?.phone} />
          </div>
        );
      }}
    </Section>
  ));

export { PersonalDetails };
