import type { FC } from 'react';

import { memo } from 'react';

import type { IconProps } from '../icon.props';

import styles from './check-mark.module.scss';

const CheckMarkIcon: FC<IconProps> = memo(() => (
  <span className={styles.icon}>
    <div className={styles.circle} />
    <div className={styles.stem} />
    <div className={styles.kick} />
  </span>
));

export { CheckMarkIcon };
