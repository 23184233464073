import { combine, createEvent, createStore, restore, sample } from 'effector';

import { object, string } from 'yup';

import { $$restoreCareSpace } from '@features/care-space/restore';

import type { EmailChangeErrors } from '@features/user/email/change';

import { $$changeUserEmail } from '@features/user/email/change';

import { $$confirmUserEmail } from '@features/user/email/confirm';

import { $$careSpace } from '@entities/care-space';

import { $$user } from '@entities/user';

import { ErrorCode } from '@shared/api';

import { createForm } from '@shared/lib/form';

enum EmailConfirmationStep {
  Welcome = 0,
  Confirmation = 1,
  ChangeEmail = 2,
  Sent = 3,
  Changed = 4
}

const $emailChangeErrors = createStore<EmailChangeErrors>({
  wrongPassword: false,

  alreadyInUse: false,

  sameAsCurrent: false
});

const $errors = createStore({});

const form = createForm({
  initialValues: {
    email: '',

    password: ''
  },

  $errors,

  $schema: createStore(
    object({
      email: string()
        .required()

        .trim()

        .nullable()

        .email('Email is incorrect. Please, check it and try again.')

        .label('Email'),

      password: string()
        .required()

        .trim()

        .nullable()

        .label('Password')
    })
  )
});

const opened = createEvent();

const closeClicked = createEvent();

const stepChanged = createEvent<EmailConfirmationStep>();

const contactUsClicked = createEvent();

const confirmEmailClicked = createEvent();

const goToEmailConfirmationClicked = createEvent();

const backFromEmailChangeClicked = createEvent();

const changeEmailClicked = createEvent();

const $visible = createStore(false);

const $step = restore(stepChanged, EmailConfirmationStep.Welcome);

const $previousStep = createStore<EmailConfirmationStep>(
  EmailConfirmationStep.Welcome
);

const $inOwnCareSpace = combine(
  $$careSpace.$careSpace,
  $$user.$account,

  (careSpace, account) =>
    careSpace && account && careSpace?.owner?.id == account?.id
);

const closed = sample({
  clock: [closeClicked, contactUsClicked]
});

const wentToEmailChange = sample({
  clock: [changeEmailClicked],

  source: $step
});

const $$emailConfirmation = {
  $step,
  $visible,
  $previousStep,
  $inOwnCareSpace,
  $emailChangeErrors,

  form,
  opened,
  closed,
  stepChanged,
  closeClicked,
  contactUsClicked,
  changeEmailClicked,
  confirmEmailClicked,
  backFromEmailChangeClicked,
  goToEmailConfirmationClicked
};

sample({
  clock: confirmEmailClicked,

  filter: $$confirmUserEmail.$pendingEmailConfirmation.map(pending => !pending),

  target: $$confirmUserEmail.sendEmailConfirmationFx
});

sample({
  clock: $$confirmUserEmail.sendEmailConfirmationFx.doneData,

  fn: () => EmailConfirmationStep.Sent,

  target: stepChanged
});

sample({
  clock: wentToEmailChange,

  fn: () => EmailConfirmationStep.ChangeEmail,

  target: stepChanged
});

sample({
  clock: goToEmailConfirmationClicked,

  fn: () => EmailConfirmationStep.Confirmation,

  target: stepChanged
});

sample({
  clock: backFromEmailChangeClicked,

  source: $previousStep,

  target: stepChanged
});

sample({
  clock: closeClicked,

  target: $$restoreCareSpace.resetRestoredStatus
});

sample({
  clock: form.submitted,

  target: $$changeUserEmail.changeEmailFx
});

sample({
  clock: $$changeUserEmail.changeEmailFx.doneData,

  fn: () => EmailConfirmationStep.Changed,

  target: stepChanged
});

sample({
  source: $emailChangeErrors,

  fn: errors => {
    if (errors.alreadyInUse) {
      return { email: 'Already in use' };
    }

    if (errors.sameAsCurrent) {
      return { email: 'Is your current email' };
    }

    if (errors.wrongPassword) {
      return {
        password: 'Password is incorrect. Please, check it and try again.'
      };
    }

    return {};
  },

  target: $errors
});

// spec({
//   on: form.fields.email,
//
//   errors: $emailChangeErrors.map(state => {
//     if (state.alreadyInUse) return ['Already in use'];
//
//     if (state.sameAsCurrent) return ['Is your current email'];
//
//     return [];
//   })
// });
//
// spec({
//   errors: $emailChangeErrors.map(state => {
//     if (state.wrongPassword) {
//       return ['Password is incorrect. Please, check it and try again.'];
//     }
//
//     return [];
//   }),
//
//   on: form.fields.password
// });

$visible.on(opened, () => true).reset(closed);

$previousStep.on(wentToEmailChange, (_, payload) => payload);

$emailChangeErrors
  .on(
    $$changeUserEmail.changeEmailFx.failData,

    (_, payload) => {
      const { code } = payload.response.data;

      return {
        wrongPassword: code == ErrorCode.WrongPassword,

        alreadyInUse: code == ErrorCode.EmailIsAlreadyInUse,

        sameAsCurrent: code == ErrorCode.EmailIsCurrentEmail
      };
    }
  )

  .reset(form.submitted, $visible.updates);

export { $$emailConfirmation, EmailConfirmationStep, form };
