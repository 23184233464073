import {
  $$profileCareBasics,
  CareBasicsForm
} from '@features/care-recipient/profile/care-basics';

import { $$careRecipient } from '@entities/care-recipient';

import {
  careTypeNames,
  nightCareTypeNames,
  VisitCareType,
  visitCareTypeNames
} from '@shared/api';

import { createView } from '@shared/lib/view';

import { EditableSection } from '@shared/ui/atoms/editable-section';

import { useMedia } from '@shared/ui/atoms/media';

import { TextPair } from '@shared/ui/atoms/text-pair';

import { $$profilePage } from '../../model';

import styles from './care-basics.module.scss';

/**
 * <CareBasics />
 */

const CareBasics = createView()
  .units({
    client: $$careRecipient.$careRecipientsProfile,

    editing: $$profilePage.careBasics.$opened,

    onSave: $$profileCareBasics.form.submit,

    onCancel: $$profilePage.careBasics.close,

    onEdit: $$profilePage.careBasics.open
  })
  .view(
    ({
      client,

      editing,

      onSave,

      onCancel,

      onEdit
    }) => (
      <EditableSection
        title='Care basics'
        editing={editing}
        onSave={() => onSave()}
        onCancel={() => onCancel()}
        onEdit={() => onEdit()}
      >
        {({ editing }) => {
          const md = useMedia('>=md');

          return editing ? (
            <CareBasicsForm />
          ) : (
            <div className={styles.fields}>
              <TextPair
                label={`Req${md ? 'uired' : '.'} types of care:`}
                value={
                  client?.visitCareType == VisitCareType.LiveInCare
                    ? visitCareTypeNames[VisitCareType.LiveInCare]
                    : client?.careTypes
                        ?.map(item => careTypeNames[item])

                        .concat(
                          client?.nightCareTypes?.map(
                            item => nightCareTypeNames[item]
                          )
                        )

                        .join(', ') || 'None'
                }
              />

              <TextPair
                label='Hours per week:'
                value={client?.hoursPerWeek || 'None'}
                wrap
              />
            </div>
          );
        }}
      </EditableSection>
    )
  );

export { CareBasics };
