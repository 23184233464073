import type { FC } from 'react';

import type { Entry } from '@shared/api';

import type { ControlProps } from '@shared/lib/types';

import { createView } from '@shared/lib/view';

import { Checkbox } from '@shared/ui/atoms/checkbox';

type Value = number | string;

type CheckboxListProps = ControlProps<Value[]> & {
  options: Entry[];
};

const CheckboxList: FC<CheckboxListProps> = createView<CheckboxListProps>()
  .map(({ onChange, value: values, options }) => {
    const selectedOptions = options.filter(item =>
      values?.some(one => one == item.id)
    );

    const onItemChange =
      (index: number, selected: boolean, option: Entry) => () => {
        onChange(
          selected
            ? values?.filter(item => item != option.id)
            : [...values, options.find((_, _index) => _index == index).id]
        );
      };

    return {
      onItemChange,
      selectedOptions
    };
  })

  .view(({ options, hasError, selectedOptions, onItemChange }) => (
    <>
      {options.map((item, index) => {
        const selected = selectedOptions.includes(item);

        return (
          <Checkbox
            key={index}
            value={selected}
            hasError={hasError}
            label={item.name}
            onChange={onItemChange(index, selected, item)}
          />
        );
      })}
    </>
  ));

export { CheckboxList };
