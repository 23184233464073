import { $$headerDropdown } from '@layouts/main-page';

import { sample } from 'effector';

import { $$addUserRole } from '@features/user/add-role';

import { $$careRecipient } from '@entities/care-recipient';

import { $$session, RoleType } from '@entities/session';

import { routes } from '@shared/routes';

sample({
  clock: [
    $$addUserRole.addMyselfRoleFx.doneData.map(payload => payload.id),

    $$addUserRole.addLovedOneRoleFx.doneData.map(payload => payload.id)
  ],

  target: $$careRecipient.$careRecipientID
});

sample({
  clock: [
    $$headerDropdown.roleSwitched,
    $$addUserRole.addCarerRoleFx.doneData,
    $$addUserRole.addLovedOneRoleFx.doneData,
    $$addUserRole.addMyselfRoleFx.doneData
  ],

  source: $$session.$role,

  fn: role =>
    role == RoleType.CareRecipient ? RoleType.Carer : RoleType.CareRecipient,

  target: [$$session.roleChanged, routes.schedule.open.prepend(() => ({}))]
});
