import { createStore } from 'effector';

import { persist } from 'effector-storage/query';

import { TOKEN_QUERY_KEY } from '@shared/config';

const $token = createStore<string>(null!);

const $$setPasswordPage = {
  $token
};

persist({
  store: $token,

  key: TOKEN_QUERY_KEY
});

export { $$setPasswordPage };
