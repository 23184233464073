import { $$carerProfile } from '@entities/carer';

import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { Breadcrumbs } from '@shared/ui/atoms/breadcrumbs';

import { Card } from '@shared/ui/atoms/card';

import { styled } from '@shared/ui/stitches';

import styles from './profile.module.scss';

import { General } from './ui/general/ui';

import { Media } from './ui/media';

import './profile.model';

const ProfilePage = createView()
  .units({
    ready: $$carerProfile.$carerProfile.map(Boolean)
  })
  .view(({ ready }) => {
    if (!ready) return null;

    return (
      <div>
        <Breadcrumbs
          className={styles.text}
          steps={[{ label: 'Profile', route: routes.profile }]}
        />

        <Container>
          <Media />

          <Card className={styles.profileCard}>
            <General />
          </Card>
        </Container>
      </div>
    );
  });

const Container = styled('div', {
  display: 'grid',
  rowGap: '12px',

  '@md': {
    rowGap: '20px'
  },

  '@lg': {
    gridTemplateColumns: '350px 1fr',
    columnGap: '30px'
  }
});

export { ProfilePage };
