import { Route } from 'atomic-router-react';

import { combine } from 'effector';

import { useLayoutEffect } from 'react';

import { $$teamCarers } from '@entities/carer';

import { CareMatch } from '@entities/carer/ui/carer-match';

import type { Carer, CarerRelationType } from '@shared/api';

import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { Breadcrumbs } from '@shared/ui/atoms/breadcrumbs';

import { useMedia } from '@shared/ui/atoms/media';

import { Tabs } from '@shared/ui/atoms/tabs';

import { $$teamPage } from './team.model';

import styles from './team.module.scss';

import { AddCarer } from './ui/add-carer';

import { CarerCard } from './ui/carer-card';

const syncHeight = (items: HTMLElement[]) => {
  const headers = items.filter(item => item);

  const max = Math.max(
    ...headers.map(item => item.getBoundingClientRect().height)
  );

  headers.forEach(item => (item.style.height = `${max}px`));
};

/**
 * <Team />
 */
const Team = createView()
  .units({
    team: $$teamCarers.$team,

    shortlist: $$teamCarers.$shortlist,

    connections: $$teamCarers.$connections,

    tab: combine(
      routes.team.my.$isOpened,

      routes.team.connections.$isOpened,

      routes.team.shortlist.$isOpened,

      (my, connections, shortlist) => {
        if (my) return 'my';

        if (connections) return 'connections';

        if (shortlist) return 'shortlist';

        return 'my';
      }
    ),

    onTabChange: $$teamPage.tabChanged
  })

  .map(({ team, connections, shortlist }) => {
    const md = useMedia('>=md');

    const lg = useMedia('>=lg');

    useLayoutEffect(() => {
      const container = document.getElementById('carer-cards');

      if (!container) return;

      const elements = container.getElementsByTagName('header');

      if (!md && !lg) {
        Array.from(elements).forEach(item => {
          item.style.removeProperty('height');
        });

        return;
      }

      for (let i = 0; i < elements.length; i += lg ? 3 : 2) {
        const first = elements[i];

        switch (true) {
          case lg: {
            syncHeight([first, elements[i + 1], elements[i + 2]]);

            break;
          }

          case md: {
            syncHeight([first, elements[i + 1]]);

            break;
          }
        }
      }
    }, [md, lg, team, connections, shortlist]);

    return {
      lg
    };
  })

  .view(({ tab, team, connections, shortlist, onTabChange }) => {
    const renderCards = (type: CarerRelationType, list: Carer[]) => () =>
      (
        <div className={styles.carers} id='carer-cards'>
          {list.map(carer => (
            <CarerCard carer={carer} key={carer.id} type={type} />
          ))}

          <AddCarer />
        </div>
      );

    return (
      <div className={styles.team}>
        <div className={styles.header}>
          <Breadcrumbs
            className={styles.crumbs}
            steps={[{ label: 'Care team', route: routes.team.my }]}
          />

          <Tabs
            size='md'
            variant='rounded'
            className={styles.tabs}
            tabs={[
              {
                id: 'my',
                name: 'My team'
              },
              {
                id: 'connections',
                name: 'Connected'
              },
              {
                id: 'shortlist',
                name: 'Shortlist'
              }
            ]}
            onChange={onTabChange}
            selected={tab}
          />
        </div>

        <Route route={routes.team.my} view={renderCards('team', team)} />

        <Route
          route={routes.team.connections}
          view={renderCards('connections', connections)}
        />

        <Route
          route={routes.team.shortlist}
          view={() =>
            shortlist?.length > 0 ? (
              <div className={styles.shortList}>
                {shortlist?.map((item, index) => (
                  <CareMatch
                    carer={{
                      ...item,
                      inShortList: true
                    }}
                    key={index}
                  />
                ))}
              </div>
            ) : (
              <div className={styles.carers} id='carer-cards'>
                <AddCarer />
              </div>
            )
          }
        />
      </div>
    );
  });

export { Team };
