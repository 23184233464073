import { createView } from '@shared/lib/view';

import styles from './no-search-result.module.scss';

const NoSearchResult = createView().view(() => (
  <>
    <div className={styles.noSearchResultsPhoto} />

    <div className={styles.noSearchResultsTitle}>No invoices found</div>

    <div className={styles.noSearchResultsText}>
      Try changing your search query and try again
    </div>
  </>
));

export { NoSearchResult };
