import { useUnit } from 'effector-react';

import type { FC, HTMLAttributes } from 'react';

import type { FormModel } from '@shared/lib/form';

type FormProps = HTMLAttributes<HTMLFormElement> & {
  use: Pick<FormModel<any>, 'submit' | '$disabled'>;
};

const Form: FC<FormProps> = ({ use, ...props }) => {
  const onSubmit = useUnit(use.submit);

  return (
    <form
      {...props}
      autoComplete='new-password'
      onSubmit={event => {
        event.preventDefault();

        onSubmit();
      }}
    />
  );
};

export { Form };
