import { ReactComponent as Search } from '@icon/search.svg';

import avatar from '@img/avatar-placeholder.png';

import type { InvoiceStatus } from '@shared/api';

import { invoiceStatusColor, invoiceStatusNames } from '@shared/api';

import { createView } from '@shared/lib/view';

import { Card } from '@shared/ui/atoms/card';

import { Chip } from '@shared/ui/atoms/chip';

import type { VariantsPalette } from '@shared/ui/atoms/chip/chip.props';

import { Input } from '@shared/ui/atoms/input';

import { Select } from '@shared/ui/atoms/select';

import { TextPair } from '@shared/ui/atoms/text-pair';

import { InvoicesPlaceholder } from '../../../ui/invoices-placeholder';

import { NoSearchResult } from '../../../ui/no-search-result';

import styles from './mobile-invoices.module.scss';

import type { MobileInvoicesProps } from './mobile-invoices.props';

const MobileInvoices = createView<MobileInvoicesProps>().view(
  ({
    statusOptions,
    noInvoices,
    filteredInvoices,
    onRowClick,
    noResultsForFilters,
    tab,
    onStatusChange,
    searchText,
    onQueryChange
  }) => (
    <>
      {noInvoices ? (
        <Card className={styles.noInvoicesPlaceholderCard}>
          <InvoicesPlaceholder />
        </Card>
      ) : (
        <>
          <Input
            className={styles.search}
            value={searchText}
            placeholder='Search'
            prepend={<Search className={styles.searchIcon} />}
            onChange={value => onQueryChange(value)}
          />

          <Select
            className={styles.status}
            options={statusOptions}
            value={tab}
            onChange={status => onStatusChange(status as InvoiceStatus)}
          />

          {noResultsForFilters ? (
            <Card className={styles.noSearchResultCard}>
              <NoSearchResult />
            </Card>
          ) : (
            <div className={styles.invoiceCards}>
              {filteredInvoices.map(
                ({ invoice, displayValues: { period, fullName } }) => (
                  <Card
                    className={styles.invoiceCard}
                    key={invoice.id}
                    onClick={() => onRowClick(invoice.id)}
                  >
                    <div className={styles.invoiceCardHeader}>
                      <img
                        className={styles.invoiceCardPhoto}
                        src={invoice?.carer?.imageUrl || avatar}
                      />

                      <h4 className={styles.invoiceCardName}>{fullName}</h4>

                      <Chip
                        className={styles.invoiceCardStatus}
                        palette={
                          invoiceStatusColor[
                            invoice.status as keyof typeof invoiceStatusColor
                          ] as VariantsPalette
                        }
                      >
                        {
                          invoiceStatusNames[
                            invoice.status as keyof typeof invoiceStatusNames
                          ]
                        }
                      </Chip>
                    </div>

                    <div className={styles.invoiceCardContent}>
                      <TextPair
                        spacing='spread'
                        label='Period:'
                        value={period}
                      />

                      <TextPair
                        spacing='spread'
                        label='Hours worked:'
                        value={`${invoice.totalHours} hr`}
                      />

                      <TextPair
                        spacing='spread'
                        label='Amount:'
                        value={
                          <span className={styles.bold}>
                            £{invoice.earnings}
                          </span>
                        }
                      />
                    </div>
                  </Card>
                )
              )}
            </div>
          )}
        </>
      )}
    </>
  )
);

export { MobileInvoices };
