import { Route } from 'atomic-router-react';

import classNames from 'classnames';

import { combine } from 'effector';

import { Account } from '@pages/settings/pages/account';

import { Member } from '@pages/settings/pages/members';

import { $isInOwnWorkspace } from '@entities/_computed/care-space';

import { $$careSpace } from '@entities/care-space';

import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { Breadcrumbs } from '@shared/ui/atoms/breadcrumbs';

import { Card } from '@shared/ui/atoms/card';

import { Tabs } from '@shared/ui/atoms/tabs';

import { $$settings } from './model';

import { Billing } from './pages/billing';

import styles from './settings.module.scss';

const SettingsPage = createView()
  .units({
    careSpace: $$careSpace.$careSpace,

    isUserOwner: $isInOwnWorkspace,

    tab: combine(
      routes.settings.account.$isOpened,

      routes.settings.members.$isOpened,

      routes.settings.billing.$isOpened,

      (account, members, billing) => {
        if (account) return 'account';

        if (members) return 'members';

        if (billing) return 'billing';

        return 'account';
      }
    ),

    tabChanged: $$settings.tabChanged
  })

  .map(({ careSpace, isUserOwner }) => {
    const tabs = [
      {
        id: 'account',
        name: 'Account',
        active: true
      },
      {
        id: 'members',
        name: 'Members',
        active: !!careSpace && careSpace.status !== 'deleted'
      },
      {
        id: 'billing',
        name: 'Billing',
        active: !!careSpace && isUserOwner && careSpace.status !== 'deleted'
      }
    ]
      .filter(({ active }) => active)

      .map(({ id, name }) => ({ id, name }));

    return {
      tabs
    };
  })

  .view(({ tab, tabs, tabChanged }) => (
    <div
      className={classNames(styles.settings, {
        [styles.membersRoute]: tab == 'members'
      })}
    >
      <div className={styles.header}>
        <Breadcrumbs
          className={styles.crumbs}
          steps={[{ label: 'Settings', route: routes.settings.account }]}
        />

        {tabs.length > 1 && (
          <Tabs
            className={classNames(styles.tabs, {
              [styles.tabsTwo]: tabs.length == 2,
              [styles.tabsOne]: tabs.length == 1
            })}
            tabs={tabs}
            onChange={value => tabChanged(value)}
            selected={tab}
            variant='rounded'
            size='md'
          />
        )}
      </div>

      <Card
        className={classNames(styles.card, {
          [styles.memberCard]: tab === 'members'
        })}
      >
        <Route route={routes.settings.account} view={Account} />

        <Route route={routes.settings.members} view={Member} />

        <Route route={routes.settings.billing} view={Billing} />
      </Card>
    </div>
  ));

export { SettingsPage };
