import moment from 'moment';

import type { FC } from 'react';

import { useMemo } from 'react';

import DatePicker from 'react-datepicker';

import { months } from '@shared/lib/utils';

import type { CalendarProps } from './calendar.props';

/**
 * <Calendar />
 */
const Calendar: FC<CalendarProps> = ({ value, onChange, minDate }) => {
  const _minDate = useMemo(
    () => (minDate ? moment(minDate).toDate() : undefined),
    [minDate]
  );

  const selected = useMemo(() => {
    const _value = value ? moment(value) : moment();

    if (_minDate && _value.isBefore(_minDate)) {
      return _minDate;
    }

    return _value.toDate();
  }, [value, _minDate]);

  return (
    <DatePicker
      inline
      minDate={_minDate}
      selected={selected}
      onChange={value => {
        onChange(moment(value as Date).format());
      }}
      renderCustomHeader={({ increaseMonth, decreaseMonth, date }) => (
        <div className='react-datepicker__current-month'>
          <div
            className='react-datepicker__navigation-icon react-datepicker__navigation-icon--previous'
            onClick={() => decreaseMonth()}
          />

          <div className='react-datepicker__current-month-name'>
            {months[date.getMonth()]}, {date.getFullYear()}
          </div>

          <div
            className='react-datepicker__navigation-icon react-datepicker__navigation-icon--next'
            onClick={() => increaseMonth()}
          />
        </div>
      )}
    />
  );
};

export { Calendar };
