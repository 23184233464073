import placeholder from '@img/avatar-placeholder.png';

import classNames from 'classnames';

import type { FC, HTMLAttributes } from 'react';

import styles from './user-box.module.scss';

type UserBoxProps = Omit<HTMLAttributes<HTMLDivElement>, 'title'> & {
  src: string;
  title: string;
  subTitle: string;
};

const UserBox: FC<UserBoxProps> = ({ src, title, subTitle }) => (
  <div className={styles.box}>
    <div
      className={classNames(styles.frame, {
        [styles.placeholder]: !src
      })}
    >
      <img className={styles.photo} src={src ?? placeholder} />
    </div>

    <div className={styles.text}>
      <div className={styles.title} title={title}>
        {title}
      </div>

      <div className={styles.subTitle} title={subTitle}>
        {subTitle}
      </div>
    </div>
  </div>
);

export { UserBox };
