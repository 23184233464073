import { modelView } from 'effector-factorio';

import { useUnit } from 'effector-react';

import { $$dictionary } from '@entities/dictionary';

import { createView } from '@shared/lib/view';

import { FormSectionGroup } from '@shared/ui/atoms/form-section';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { ManageEmploymentDetailsModelFactory } from '../model';

import styles from './employment-details.module.scss';

/**
 * <EmploymentDetails />
 */
const EmploymentDetailsForm = modelView(
  ManageEmploymentDetailsModelFactory,

  createView()
    .units({
      workingDocuments: $$dictionary.$workingDocuments
    })

    .map(() => {
      const { form, $dateRange } =
        ManageEmploymentDetailsModelFactory.useModel();

      const [dateRange] = useUnit([$dateRange]);

      return {
        form,

        dateRange
      };
    })

    .view(({ form, dateRange, workingDocuments }) => (
      <Form use={form}>
        <FormSectionGroup>
          <Field.FormSection
            use={form.fields.carerStart}
            title='Since when have you been a professional carer?'
          >
            <div className={styles.carerStart}>
              <Field.DateSelectGroup
                use={form.fields.carerStart}
                min={dateRange.min}
                max={dateRange.max}
                date={false}
              />
            </div>
          </Field.FormSection>

          <Field.FormSection
            use={form.fields.workDocument}
            title='What document shows your Right to Work in the UK?'
          >
            <Field.Select
              use={form.fields.workDocument}
              name='workDocument'
              errorIn='label'
              className={styles.docs}
              options={workingDocuments}
              // labelGap
            />
          </Field.FormSection>
        </FormSectionGroup>

        <div className={styles.footer}>
          <Field.CheckboxAlertBox
            hideMore
            use={form.fields.preferences}
            title='I confirm I will be able to provide two professional references upon request'
            text='We require at least two professional references. Your references must prove that you have at least 1 year professional experience in the care sector, with at least 3 months in the UK in the past 24 months. These can be either from your clients or supervisors.'
          />
        </div>
      </Form>
    ))
);

export { EmploymentDetailsForm };
