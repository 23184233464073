import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample
} from 'effector';

import { $$careSpace } from '@entities/care-space';

import { $$user } from '@entities/user';

import type { Workspace } from '@shared/api';

import { carersHQ, WorkspaceStatus } from '@shared/api';

import { routes } from '@shared/routes';

const restoreCareSpace = createEvent();

const resetRestoredStatus = createEvent();

const setRestoredStatus = createEvent();

const restoreCareSpaceFx = createEffect<Workspace['id'], Workspace['id']>();

const getAccountAfterRestoreFx = attach({
  effect: $$user.getAccountFx
});

const careSpaceRestored = getAccountAfterRestoreFx.doneData.map(
  () => void null
);

const $isRestored = createStore(false)
  .on(restoreCareSpaceFx.doneData, () => true)

  .on(setRestoredStatus, () => true)

  .reset(resetRestoredStatus);

const $$restoreCareSpace = {
  $isRestored,

  careSpaceRestored,

  restoreCareSpace,

  setRestoredStatus,

  resetRestoredStatus,

  restoreCareSpaceFx
};

sample({
  clock: restoreCareSpaceFx.doneData,

  target: [$$careSpace.$careSpaceID, getAccountAfterRestoreFx]
});

sample({
  clock: getAccountAfterRestoreFx.doneData,

  target: routes.matches.open.prepend(() => ({}))
});

sample({
  clock: restoreCareSpace,

  source: $$careSpace.$accountCareSpaces,

  fn: spaces =>
    spaces.find(one => one?.workspace?.status == WorkspaceStatus.Deleted)
      ?.workspace?.id,

  target: restoreCareSpaceFx
});

restoreCareSpaceFx.use(async workspaceId => {
  await carersHQ.settings.restoreWorkspace({
    workspaceId
  });

  return workspaceId;
});

export { $$restoreCareSpace };
