import classNames from 'classnames';

import { modelView } from 'effector-factorio';

import { PostcodeCheckFactory } from '@features/postcode/check';

import { $$members } from '@entities/members';

import { RelationId } from '@shared/api';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { useMedia } from '@shared/ui/atoms/media';

import { Modal } from '@shared/ui/atoms/modal';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { $$addCareRecipient } from '../../model';

import styles from './postcode-check.module.scss';

const PostcodeCheck = modelView(
  PostcodeCheckFactory,

  createView()
    .units({
      onCancel: $$addCareRecipient.closeModal,

      members: $$members.$members
    })
    .map(({ members }) => {
      const { form } = PostcodeCheckFactory.useModel();

      const skipClientTypeSelection = members.some(
        ({ account: { relations } }) =>
          relations.some(
            ({ clientRelation: { id } }) => id === RelationId.Myself
          )
      );

      const lg = useMedia('>=lg');

      return {
        lg,
        form,
        skipClientTypeSelection
      };
    })

    .view(({ form, onCancel, lg }) => (
      <Form use={form} className={styles.form}>
        <Modal.Container
          onClose={() => onCancel()}
          size='large'
          fullHeight={lg}
          overlayStyles={classNames({ [styles.overlay]: !lg })}
          className={styles.modal}
        >
          <Modal.Header>Add a new person</Modal.Header>

          <Modal.Body>
            <h1 className={styles.title}>
              First, let’s check if we’re in
              <br /> your area.
            </h1>

            <p className={styles.info}>
              Please enter the postcode of the new care recipient so
              <br className={styles.brOne} /> we can check if we operate in
              their area.
            </p>

            <div className={styles.wrapper}>
              <h2 className={styles.wrapperTitle}>Enter postcode</h2>

              <Field.Input
                use={form.fields.postcode}
                className={styles.input}
                placeholder='Enter'
                name='postCode'
                errorIn='label'
                autoFocus
              />
            </div>
          </Modal.Body>

          <Modal.Footer fill className={styles.footer}>
            <Button
              palette='text'
              variant='text'
              onClick={() => onCancel()}
              className={styles.cancel}
            >
              Cancel
            </Button>

            <Button onClick={() => form.submit()}>Continue</Button>
          </Modal.Footer>
        </Modal.Container>
      </Form>
    ))
);

export { PostcodeCheck };
