import { $$careRecipient } from '@entities/care-recipient';

import { createView } from '@shared/lib/view';

import { AlertBox } from '@shared/ui/atoms/alert-box';

import { Button } from '@shared/ui/atoms/button';

import { Modal } from '@shared/ui/atoms/modal';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { $$deleteAccount } from '../../model';

import styles from './password.module.scss';

const Password = createView()
  .units({
    onCancel: $$deleteAccount.closeModalClicked,

    reason: $$deleteAccount.$reason,

    client: $$careRecipient.$careRecipientsProfile
  })

  .view(({ onCancel, client }) => (
    <Modal.Container size='small' onClose={() => onCancel()}>
      <Modal.Header>
        Are you sure you want to delete {client?.firstName} {client?.lastName}’s
        account?
      </Modal.Header>

      <Modal.Body className={styles.content}>
        <AlertBox className={styles.alert} variant='error'>
          <h4>Pay attention!</h4>

          <p>After you delete your account, you won’t have access to:</p>

          <ul>
            <li>- any carer information;</li>

            <li>- reports and notes;</li>

            <li>- anything else in the Carers HQ.</li>
          </ul>
        </AlertBox>

        <Form use={$$deleteAccount.passwordForm}>
          <Field.Input
            use={$$deleteAccount.passwordForm.fields.password}
            className={styles.password}
            type='password'
            placeholder='Enter'
            label='Enter your password to confirm '
            name='password'
            errorIn='label'
          />
        </Form>
      </Modal.Body>

      <Modal.Footer className={styles.footer}>
        <Button
          palette='text'
          onClick={() => onCancel()}
          variant='text'
          className={styles.cancel}
        >
          Cancel
        </Button>

        <Button
          palette='error'
          onClick={() => $$deleteAccount.passwordForm.submit()}
          className={styles.submit}
        >
          Yes, I want to delete
        </Button>
      </Modal.Footer>
    </Modal.Container>
  ));

export { Password };
