import { createEffect, createEvent, restore, createStore } from 'effector';

import type { Dashboard } from '@shared/api';

import type { DashboardParams } from '@shared/api';

import { carersHQ, DashboardMarkTypes } from '@shared/api';

const getDashboardFx = createEffect<
  { id: string } & DashboardParams,
  Dashboard[]
>();

const activeWellnessCharsChanged = createEvent<DashboardMarkTypes[]>();

const editWellnessSettingsClicked = createEvent();

const wellnessSettingsCloseClicked = createEvent();

const wellnessDaysCountChanged = createEvent<number>();

const $dashboard = createStore<Dashboard[]>([]);

const $editingWellnessSettings = createStore(false);

const $wellnessActiveCharts = createStore<DashboardMarkTypes[]>([
  DashboardMarkTypes.Mood,
  DashboardMarkTypes.Sleep,
  DashboardMarkTypes.Pain,
  DashboardMarkTypes.Meals,
  DashboardMarkTypes.BowelMovements
]);

const $wellnessShowDays = restore(wellnessDaysCountChanged, 6);

const $$wellness = {
  $dashboard,

  $editingWellnessSettings,

  $wellnessActiveCharts,

  $wellnessShowDays,

  wellnessDaysCountChanged,

  activeWellnessCharsChanged,

  editWellnessSettingsClicked,

  wellnessSettingsCloseClicked,

  getDashboardFx
};

$dashboard.on(getDashboardFx.doneData, (_state, payload) => payload);

$wellnessActiveCharts.on(
  activeWellnessCharsChanged,
  (_state, payload) => payload
);

$editingWellnessSettings
  .on(editWellnessSettingsClicked, () => true)

  .reset([wellnessSettingsCloseClicked, activeWellnessCharsChanged]);

getDashboardFx.use(async payload => {
  const { data } = await carersHQ.carer.dashboard(payload);

  return data;
});

export { $$wellness };
