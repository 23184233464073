import { modelView } from 'effector-factorio';

import { useUnit } from 'effector-react';

import type { FC } from 'react';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { CtaBanner } from '@shared/ui/atoms/cta-banner';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { SignUpForNewsLetterModelFactory } from '../model';

import styles from './sign-up-newsletter.module.scss';

const SignUpNewsletterSuccessBanner = () => (
  <CtaBanner letterSpacing>
    <p>
      Thank you for subscribing to <br className={styles.visibleSm} /> our
      updates.
    </p>

    <p>
      We promise we won't send you spam, only <br /> important news.
    </p>
  </CtaBanner>
);

const SignUpNewsLetterNotSupportedBanner = () => (
  <CtaBanner letterSpacing>
    <p>
      We’re not in your area just yet, but we are{' '}
      <br className={styles.visibleMd} />
      expanding rapidly.
    </p>

    <p>
      Sign up to our newsletter, and we’ll let you know when{' '}
      <br className={styles.visibleMd} />
      we become available in your area.
    </p>
  </CtaBanner>
);

const SignUpNewsLetterSuccess: FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button className={styles.back} palette='primary' onClick={onClick}>
    Back to website
  </Button>
);

const SignUpNewsLetterAgreement = () => (
  <div className={styles.agreement}>
    <p>
      By providing us with your name and email address, you consent to our{' '}
      <a href='https://google.com'> Privacy Policy</a> and{' '}
      <a href='https://google.com'>Terms of Service</a>.
    </p>

    <p>
      You also consent to us contacting you by email to inform you of our
      services and other information we think will be of interest to you. You
      can unsubscribe at any time.
    </p>
  </div>
);

const SignUpNewsLetterForm = modelView(
  SignUpForNewsLetterModelFactory,

  createView()
    .map(() => {
      const { $error, form } = SignUpForNewsLetterModelFactory.useModel();

      return {
        form,
        error: useUnit($error)
      };
    })

    .view(({ form, error }) => (
      <Form use={form}>
        <div className={styles.fieldsContainer}>
          <div className={styles.fields}>
            <Field.Input
              use={form.fields.name}
              labelGap
              bold
              name='name'
              label='Your name'
              errorIn='label'
            />

            <Field.Input
              use={form.fields.email}
              labelGap
              bold
              label='Email address'
              errorIn='label'
            />
          </div>

          {error && <div className={styles.error}>Something went wrong</div>}
        </div>
      </Form>
    ))
);

export {
  SignUpNewsLetterForm,
  SignUpNewsLetterSuccess,
  SignUpNewsLetterAgreement,
  SignUpNewsletterSuccessBanner,
  SignUpNewsLetterNotSupportedBanner
};
