import classNames from 'classnames';

import { modelView } from 'effector-factorio';

import moment from 'moment';

import { $$dictionary } from '@entities/dictionary';

import {
  CareType,
  NightCareType,
  StartingFromType,
  VisitCareType,
  visitCareTypeNames
} from '@shared/api';

import { createView } from '@shared/lib/view';

import { BoxRow } from '@shared/ui/atoms/box-row';

import { CheckboxGroup } from '@shared/ui/atoms/checkbox';

import { FormSection, FormSectionGroup } from '@shared/ui/atoms/form-section';

import { RadioGroup } from '@shared/ui/atoms/radio';

import { Field, useField } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { ManageCareNeedsModelFactory } from '../../model';

import styles from './care-needs.module.scss';

const minStartDate = moment().add(7, 'days');

const CareNeedsForm = modelView(
  ManageCareNeedsModelFactory,

  createView()
    .units({
      services: $$dictionary.$services
    })

    .map(() => {
      const { form } = ManageCareNeedsModelFactory.useModel();

      const { value: visitCareType } = useField(form.fields.visitCareType);

      return {
        form,
        visitCareType
      };
    })

    .view(({ form, services, visitCareType }) => (
      <Form use={form}>
        <FormSectionGroup>
          <FormSection
            className={styles.fieldGroup}
            title='What type of care is required?'
          >
            <RadioGroup layout='horizontalAlways'>
              <Field.RadioList
                use={form.fields.visitCareType}
                options={[
                  {
                    id: VisitCareType.VisitingCare,
                    name: visitCareTypeNames[VisitCareType.VisitingCare]
                  },

                  {
                    id: VisitCareType.LiveInCare,
                    name: visitCareTypeNames[VisitCareType.LiveInCare]
                  }
                ]}
              />
            </RadioGroup>
          </FormSection>

          {visitCareType == VisitCareType.VisitingCare && (
            <>
              <Field.FormSection
                use={form.fields.careTypes}
                className={classNames(styles.fieldGroup)}
                title='What type of care is required?'
                tip='You can select multiple types'
              >
                <BoxRow layout='horizontalAlways'>
                  <Field.CheckboxList
                    use={form.fields.careTypes}
                    options={[
                      {
                        id: CareType.DayCare,
                        name: 'Day care'
                      },

                      {
                        id: CareType.NightCare,
                        name: 'Night care'
                      }
                    ]}
                  />
                </BoxRow>
              </Field.FormSection>

              <Field.Container use={form.fields.careTypes}>
                {({ value: careTypes }: any) => {
                  if (!careTypes?.includes?.(CareType.NightCare)) return null;

                  return (
                    <Field.FormSection
                      className={styles.fieldGroup}
                      use={form.fields.nightCareTypes}
                      title='What type of night care is required?'
                      tip='You can select multiple types'
                    >
                      <BoxRow layout='horizontalAlways'>
                        <Field.CheckboxList
                          use={form.fields.nightCareTypes}
                          options={[
                            {
                              id: NightCareType.Sleeping,
                              name: 'Sleeping night care'
                            },

                            {
                              id: NightCareType.Waking,
                              name: 'Waking night care'
                            }
                          ]}
                        />
                      </BoxRow>
                    </Field.FormSection>
                  );
                }}
              </Field.Container>
            </>
          )}

          {visitCareType == VisitCareType.LiveInCare && (
            <div className={styles.startDateSection}>
              <FormSection className={styles.boxRowV} title='Starting from?'>
                <RadioGroup layout='vertical'>
                  <Field.RadioList
                    use={form.fields.startingFromType}
                    options={[
                      {
                        id: StartingFromType.SpecificDate,
                        name: 'Specific date'
                      },

                      {
                        id: StartingFromType.Immediately,
                        name: 'Immediately (within one week)'
                      },

                      {
                        id: StartingFromType.NotSure,
                        name: 'Not sure'
                      }
                    ]}
                  />
                </RadioGroup>
              </FormSection>

              <Field.Container use={form.fields.startingFromType}>
                {({ value: startingFromType }) => {
                  if (startingFromType != StartingFromType.SpecificDate)
                    return null;

                  return (
                    <Field.DatePicker
                      bold
                      wrapperClassName={styles.startDate}
                      className={styles.calendar}
                      use={form.fields.startingFromDate}
                      placeholder='Select'
                      label='Start date'
                      minDate={minStartDate}
                    />
                  );
                }}
              </Field.Container>
            </div>
          )}

          <Field.FormSection
            use={form.fields.services}
            title='What type of care support is required?'
            tip='You can select multiple services'
          >
            <CheckboxGroup>
              <Field.CheckboxList
                use={form.fields.services}
                options={services}
              />
            </CheckboxGroup>
          </Field.FormSection>
        </FormSectionGroup>
      </Form>
    ))
);

export { CareNeedsForm };
