import { modelView } from 'effector-factorio';

import { useStoreMap, useUnit } from 'effector-react';

import { RateFields, SupportTypesList } from '@entities/carer';

import { $$dictionary } from '@entities/dictionary';

import type { SupportTypeId } from '@shared/api';

import { supportTypeNames } from '@shared/api';

import { createView } from '@shared/lib/view';

import { BoxRow } from '@shared/ui/atoms/box-row';

import { CheckboxGroup } from '@shared/ui/atoms/checkbox';

import { FormSection, FormSectionGroup } from '@shared/ui/atoms/form-section';

import { Field } from '@shared/ui/organisms/field';

import { ManageServicesModelFactory } from '../model';

import styles from './services.module.scss';

/**
 * <Services />
 */

const ServicesForm = modelView(
  ManageServicesModelFactory,

  createView()
    .units({
      conditionTypes: $$dictionary.$conditionTypes,

      services: $$dictionary.$services,

      supportTypes: $$dictionary.$supportTypes
    })

    .map(() => {
      const { form } = ManageServicesModelFactory.useModel();

      const shouldShowRate = useStoreMap({
        store: form.fields.supportTypes.$value,

        keys: [],

        fn: state => (state?.length ?? 0) > 0
      });

      const hasRatesErrors = useStoreMap({
        store: form.$errors,

        keys: [],

        defaultValue: false,

        fn: state => Boolean(state.supportTypes)
      });

      const shouldShowRateTitleError = useUnit(form.$submitted);

      return {
        form,

        shouldShowRate,

        hasRatesErrors: hasRatesErrors && shouldShowRateTitleError
      };
    })

    .view(
      ({
        form,
        shouldShowRate,
        supportTypes,
        conditionTypes,
        services,
        hasRatesErrors
      }) => (
        <FormSectionGroup>
          <Field.FormSection
            use={form.fields.supportTypes}
            title='What type of care do you provide?'
            tip='You can select multiple care types'
          >
            <BoxRow layout='horizontal'>
              <SupportTypesList
                supportTypes={supportTypes}
                use={form.fields.supportTypes}
              />
            </BoxRow>
          </Field.FormSection>

          {shouldShowRate && (
            <FormSection
              hasError={hasRatesErrors}
              title='Rate details'
              tip='Clients will see it and be guided by it when offering a job'
            >
              <div className={styles.rates}>
                <Field.Container use={form.fields.supportTypes}>
                  {({ value }: any) =>
                    value?.map((item, index) => (
                      <RateFields
                        key={index}
                        name={
                          supportTypeNames[
                            item?.supportType?.id as SupportTypeId
                          ]
                        }
                        use={[
                          form.fields.supportTypes[index].rates[0].rate,

                          form.fields.supportTypes[index].rates[1].rate
                        ]}
                      />
                    ))
                  }
                </Field.Container>
              </div>
            </FormSection>
          )}
          <Field.FormSection
            use={form.fields.services}
            title='And what care services?'
            tip='You can select multiple services'
          >
            <CheckboxGroup>
              <Field.CheckboxList
                use={form.fields.services}
                options={services}
              />
            </CheckboxGroup>
          </Field.FormSection>

          <Field.FormSection
            use={form.fields.conditionsTypes}
            title='Do you have experience with any of these conditions?'
            tip='You can select multiple conditions'
          >
            <CheckboxGroup>
              <Field.CheckboxList
                use={form.fields.conditionsTypes}
                options={conditionTypes}
              />
            </CheckboxGroup>
          </Field.FormSection>
        </FormSectionGroup>
      )
    )
);

export { ServicesForm };
