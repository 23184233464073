import { createRoute } from 'atomic-router';

type ProfileParams = {
  id: string;
};

type SignUpStepParams = {
  postcode: string;
};

const routes = {
  schedule: createRoute(),

  matches: createRoute(),

  team: {
    base: createRoute(),

    any: createRoute<{ page: string[] }>(),

    my: createRoute(),

    connections: createRoute(),

    shortlist: createRoute()
  },

  clients: createRoute(),

  invoices: createRoute(),

  help: createRoute(),

  settings: {
    account: createRoute(),

    members: createRoute(),

    billing: createRoute()
  },

  profile: createRoute(),

  careRecipients: {
    base: createRoute<{ id: string[] }>(),

    general: createRoute<ProfileParams>(),

    medication: createRoute<ProfileParams>(),

    wellness: createRoute<ProfileParams>(),

    contacts: createRoute<ProfileParams>()
  },

  deletedAccount: {
    home: createRoute(),

    restore: createRoute(),

    invoices: createRoute<{ status: string; search: string }>(),

    personInvoice: createRoute<ProfileParams>()
  },

  signUp: {
    any: createRoute<{ page: string[] }>(),

    type: createRoute(),

    carer: {
      any: createRoute<SignUpStepParams & { page: string[] }>(),

      postcode: createRoute(),

      anyWithPostcode: createRoute<SignUpStepParams>(),

      skills: createRoute<SignUpStepParams>(),

      personalInformation: createRoute<SignUpStepParams>(),

      contactDetails: createRoute<SignUpStepParams>(),

      services: createRoute<SignUpStepParams>(),

      qualifications: createRoute<SignUpStepParams>(),

      additionalInformation: createRoute<SignUpStepParams>(),

      employmentDetails: createRoute<SignUpStepParams>(),

      createAccount: createRoute<SignUpStepParams>()
    },

    myself: {
      any: createRoute<SignUpStepParams & { page: string[] }>(),

      anyWithPostcode: createRoute<SignUpStepParams>(),

      postcode: createRoute(),

      careNeeds: createRoute<SignUpStepParams>(),

      conditionExperience: createRoute<SignUpStepParams>(),

      preferences: createRoute<SignUpStepParams>(),

      personalInformation: createRoute<SignUpStepParams>(),

      contactDetails: createRoute<SignUpStepParams>(),

      createAccount: createRoute<SignUpStepParams>()
    },

    lovedOne: {
      any: createRoute<SignUpStepParams & { page: string[] }>(),

      anyWithPostcode: createRoute<SignUpStepParams>(),

      postcode: createRoute(),

      careNeeds: createRoute<SignUpStepParams>(),

      conditionExperience: createRoute<SignUpStepParams>(),

      preferences: createRoute<SignUpStepParams>(),

      careRecipient: createRoute<SignUpStepParams>(),

      accountHolder: createRoute<SignUpStepParams>(),

      createAccount: createRoute<SignUpStepParams>()
    }
  },

  auth: {
    signIn: createRoute(),

    signUpInvite: createRoute(),

    recovery: createRoute(),

    password: createRoute()
  },

  careSpaceDeletion: {
    confirmed: createRoute<{ id: string }>()
  },

  leaveCareSpace: {
    confirmed: createRoute<{ name: string }>()
  }
};

export type { SignUpStepParams };

export { routes };
