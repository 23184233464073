import { useUnit } from 'effector-react';

import { $$carerMatchesPage } from '@pages/carer-matches/carer-matches.model';

import { $$carerMatches } from '@entities/carer';

/**
 * <CarerMatches /> props
 */
const useCarerMatchesProps = () => {
  const [{ page, shortNotice }, matches, total, loadMore, shortNoticeChange] =
    useUnit([
      $$carerMatchesPage.$params,
      $$carerMatches.$matches,
      $$carerMatches.$total,
      $$carerMatchesPage.loadMore,
      $$carerMatchesPage.shortNoticeChanged
    ]);

  const onShortNoticeChange = (value: boolean) => {
    shortNoticeChange(value);
  };

  const hasMore = page < total - 1;

  return {
    matches,
    hasMore,
    shortNotice,
    loadMore,
    onShortNoticeChange
  };
};

export { useCarerMatchesProps };
