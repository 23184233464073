import { createStore } from 'effector';

import { persist } from 'effector-storage/session';

import type { CareNeedsValues } from '@features/care-recipient/manage-care-needs';

import type { ConditionExperienceValues } from '@features/care-recipient/manage-condition-experience';

import type { ManageCareRecipientDetailsValues } from '@features/care-recipient/manage-details';

import type { PreferencesValues } from '@features/care-recipient/manage-preferences';

import type { AccountHolderValues } from '@features/user/manage-account-holder-details';

import type { SignUpClientParams } from '@shared/api';

import { routes } from '@shared/routes';

import { createSignUpStepModelFactory } from '../model';

type Values = {
  preferences: PreferencesValues;

  conditionExperience: ConditionExperienceValues;

  careNeeds: CareNeedsValues;

  accountHolder: AccountHolderValues;

  careRecipient: ManageCareRecipientDetailsValues;
};

const $lovedOneValues = createStore<Values>({
  preferences: null,

  conditionExperience: null,

  careNeeds: null,

  accountHolder: null,

  careRecipient: null
});

const createLovedOneStep = createSignUpStepModelFactory({
  $values: $lovedOneValues,

  fallbackRoute: routes.signUp.lovedOne.careNeeds
});

persist({
  key: 'SIGN_UP_LOVED_ONE_VALUES',

  store: $lovedOneValues
});

$lovedOneValues.reset(
  routes.signUp.lovedOne.anyWithPostcode.closed,
  routes.signUp.lovedOne.postcode.opened
);

function lovedOneValuesToDto(
  {
    careNeeds,
    preferences,
    careRecipient,
    accountHolder,
    conditionExperience
  }: Values,
  isNew?: boolean
) {
  return {
    ...(isNew
      ? {
          firstName: accountHolder.firstName,
          lastName: accountHolder.lastName,
          phone: accountHolder.phone
        }
      : {}),

    client: {
      postCode: careRecipient.postCode,
      city: careRecipient.city,
      addressLine1: careRecipient.addressLine1,
      addressLine2: careRecipient.addressLine2,
      phone: careRecipient.phone,
      firstName: careRecipient.firstName,
      lastName: careRecipient.lastName,
      dateOfBirth: careRecipient.dateOfBirth,
      gender: careRecipient.gender ? { id: careRecipient.gender } : null,
      conditionsTypes: conditionExperience.conditionsTypes.map(id => ({
        id
      })),
      careStart: '2021-07-14T15:47:30',
      careStartDate: '2021-07-14T15:47:30',
      careEndDate: '2021-07-14T15:47:30',
      carerGender: preferences.carerGender
        ? { id: preferences.carerGender }
        : null,
      languages: preferences.languages,
      smoking: preferences.smokingOk,
      pets: preferences.pets.map(id => ({ id })),
      transportationHelp: preferences.transportationHelp
        ? { id: preferences.transportationHelp }
        : null,

      services: careNeeds.services.map(id => ({ id })),
      visitCareType: careNeeds.visitCareType,
      careTypes: careNeeds.careTypes,
      startingFromType: careNeeds.startingFromType,
      startingFromDate: careNeeds.startingFromDate,
      nightCareTypes: careNeeds.nightCareTypes
    }
  } as SignUpClientParams;
}

export { $lovedOneValues, createLovedOneStep, lovedOneValuesToDto };
