import { $$profilePage } from '@pages/profile/profile.model';

import { $$openAddressOnMap } from '@features/address/open-on-map';

import { $$carerContactDetails } from '@features/carer/profile/contact-details';

import { $$carerProfile } from '@entities/carer/model';

import { ContactDetailsFields } from '@entities/user';

import type { Carer } from '@shared/api';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { EditableSection } from '@shared/ui/atoms/editable-section';

import { useMedia } from '@shared/ui/atoms/media';

import { TextPair } from '@shared/ui/atoms/text-pair';

import styles from './contact-details.module.scss';

/**
 * <ContactDetails />
 */
const ContactDetails = createView()
  .units({
    carer: $$carerProfile.$carerProfile,

    editing: $$profilePage.contactDetails.$opened,

    onSave: $$carerContactDetails.form.submit,

    onCancel: $$profilePage.contactDetails.close,

    onEdit: $$profilePage.contactDetails.open
  })

  .map(({ carer }) => {
    const md = useMedia('>=md');

    const onShowClick = () => {
      $$openAddressOnMap.openFullAddressFx(carer as Required<Carer>);
    };

    return {
      md,
      onShowClick
    };
  })

  .view(({ md, onShowClick, carer, onEdit, editing, onSave, onCancel }) => (
    <EditableSection
      title='Contact details'
      editing={editing}
      onEdit={onEdit}
      onCancel={onCancel}
      onSave={onSave}
    >
      {({ editing }) =>
        editing ? (
          <ContactDetailsFields
            form={$$carerContactDetails.form}
            classNames={{
              location: styles.location,
              formSection: styles.formSection,
              phoneRow: styles.phone,
              addressLine2: styles.addressLine,
              addressLine1: styles.addressLine
            }}
          />
        ) : (
          <div className={styles.fields}>
            <TextPair
              label='Address:'
              wrap
              spacing={md ? 'normal' : 'multiline'}
              value={
                <div>
                  <div>
                    {[
                      carer?.addressLine1,
                      carer?.addressLine2,
                      carer?.city,
                      carer?.postCode.replace(/^(.*)(.{3})$/, '$1 $2')
                    ]
                      .filter(item => item)
                      .join(', ')}
                  </div>

                  <Button
                    palette='text'
                    variant='text'
                    className={styles.showOnAMap}
                    onClick={onShowClick}
                  >
                    Show on a map
                  </Button>
                </div>
              }
            />

            <TextPair label='Phone:' value={carer?.phone} wrap />
          </div>
        )
      }
    </EditableSection>
  ));

export { ContactDetails };
