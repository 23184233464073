import type { ComponentType, FC } from 'react';

import { useField } from '@shared/lib/form';

import type { FieldModel, MappedField } from '@shared/lib/form';

import { Checkbox } from '@shared/ui/atoms/checkbox';

import { FormSection } from '@shared/ui/atoms/form-section';

import type { FormSectionProps } from '@shared/ui/atoms/form-section/form-section.props';

import { Input } from '@shared/ui/atoms/input';

import { Radio } from '@shared/ui/atoms/radio';

import { Select } from '@shared/ui/atoms/select';

import { Textarea } from '@shared/ui/atoms/textarea';

import { CheckboxAlertBox } from '@shared/ui/molecules/checkbox-alert-box';

import { CheckboxList } from '@shared/ui/molecules/checkbox-list';

import { CurrencyInput } from '@shared/ui/molecules/currency-input';

import { DatePicker } from '@shared/ui/molecules/date-picker';

import { DateSelectGroup } from '@shared/ui/molecules/date-select-group';

import { PhoneInput } from '@shared/ui/molecules/phone-input';

import { RadioList } from '@shared/ui/molecules/radio-list';

import { SuggestionInput } from '@shared/ui/molecules/suggestion-input';

// default controls keys
type ControlKeys =
  | 'value'
  | 'touched'
  | 'dirty'
  | 'focused'
  | 'error'
  | 'hasError'
  | 'onChange'
  | 'onFocus'
  | 'onBlur';

function createField<P, Keys extends string = ControlKeys>(
  Component: ComponentType<P>
) {
  return ({ use, ...props }: Omit<P, Keys> & { use: FieldModel<any> }) => {
    const { hasError, path, shouldShowValidation, ..._field } = useField(use);

    return (
      <Component
        hasError={hasError && shouldShowValidation}
        {..._field}
        {...(props as any)}
      />
    );
  };
}

function createFieldWithErrorPlacement<P, Keys extends string = ControlKeys>(
  Component: ComponentType<P>
) {
  return ({
    use,
    errorIn = 'label',
    ...props
  }: Omit<P, Keys> & {
    use: FieldModel<any>;
    errorIn?: 'label' | 'helperText';
  }) => {
    const { error, hasError, path, shouldShowValidation, ..._field } =
      useField(use);

    const _props = {
      [errorIn == 'label' ? 'error' : 'helperText']: error
    };

    return (
      <Component
        hasError={hasError && shouldShowValidation}
        {..._field}
        {..._props}
        {...(props as any)}
      />
    );
  };
}

function Container<V>({
  use,
  children
}: {
  use: FieldModel<V>;
  children: (props: MappedField<V>) => any;
}) {
  return children(useField(use));
}

const _FormSection: FC<FormSectionProps & { use: FieldModel<any> }> = ({
  use,
  ...props
}) => {
  const { shouldShowValidation, hasError, error } = useField(use);

  return (
    <FormSection
      {...props}
      error={error}
      hasError={hasError && shouldShowValidation}
    />
  );
};

const Field = {
  Container,
  FormSection: _FormSection,
  Radio: createField(Radio),
  Checkbox: createField(Checkbox),
  RadioList: createField(RadioList),
  DatePicker: createField(DatePicker),
  CheckboxList: createField(CheckboxList),
  Input: createFieldWithErrorPlacement(Input),
  Textarea: createFieldWithErrorPlacement(Textarea),
  Select: createFieldWithErrorPlacement(Select),
  DateSelectGroup: createField(DateSelectGroup),
  CheckboxAlertBox: createField(CheckboxAlertBox),
  PhoneInput: createFieldWithErrorPlacement(PhoneInput),
  CurrencyInput: createFieldWithErrorPlacement(CurrencyInput),
  SuggestionInput: createFieldWithErrorPlacement(SuggestionInput)
};

export { Field, useField };
