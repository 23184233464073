import { Auth } from '@layouts/auth';

import {
  $$resetUserPasswordByEmail,
  ResetPassword
} from '@features/user/password/reset-by-email';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { Card } from '@shared/ui/atoms/card';

import { styled } from '@shared/ui/stitches';

import { $$resetPasswordPage } from './reset-password.model';

const ResetPasswordPage = createView()
  .units({
    sent: $$resetUserPasswordByEmail.$resetPasswordEmailSent,

    title: $$resetUserPasswordByEmail.$resetPasswordEmailSent.map(sent =>
      sent ? 'Instructions sent' : 'Forgot your password?'
    ),

    onBackToLoginClick: $$resetPasswordPage.backToLoginClicked.prepend(() => {})
  })

  .view(({ sent, title, onBackToLoginClick }) => (
    <Auth.FormPage title={title}>
      <Card>
        <ResetPassword />

        {!sent && (
          <Back variant='text' palette='text' onClick={onBackToLoginClick}>
            Back to login
          </Back>
        )}
      </Card>
    </Auth.FormPage>
  ));

const Back = styled(Button, {
  display: 'block',
  marginTop: '12px',
  mx: 'auto'
});

export { ResetPasswordPage };
