import {
  $$profileConditionExperience,
  ConditionExperienceForm
} from '@features/care-recipient/profile/condition-experience';

import { $$careRecipient } from '@entities/care-recipient';

import { createView } from '@shared/lib/view';

import { EditableSection } from '@shared/ui/atoms/editable-section';

import { useMedia } from '@shared/ui/atoms/media';

import { TextPair } from '@shared/ui/atoms/text-pair';

import { $$profilePage } from '../../model';

import styles from './condition-experience.module.scss';

/**
 * <ConditionExperience />
 */
const ConditionExperience = createView()
  .units({
    client: $$careRecipient.$careRecipientsProfile,

    editing: $$profilePage.conditionExperience.$opened,

    onSave: $$profileConditionExperience.form.submit,

    onCancel: $$profilePage.conditionExperience.close,

    onEdit: $$profilePage.conditionExperience.open
  })

  .map(() => ({
    md: useMedia('>=md')
  }))

  .view(
    ({
      md,

      client,

      editing,

      onSave,

      onCancel,

      onEdit
    }) => (
      <EditableSection
        title='Condition experience'
        editing={editing}
        onSave={() => onSave()}
        onCancel={() => onCancel()}
        onEdit={() => onEdit()}
      >
        {({ editing }) =>
          editing ? (
            <ConditionExperienceForm />
          ) : (
            <div className={styles.fields}>
              <TextPair
                label={`Req${md ? 'uired' : '.'} condition experience:`}
                spacing={md ? 'normal' : 'multiline'}
                value={
                  client?.conditionsTypes?.map(item => item.name).join(', ') ||
                  'None'
                }
              />

              <TextPair
                label='Additional details:'
                spacing={md ? 'normal' : 'multiline'}
                value={client?.additionalDetails || 'None'}
                wrap
              />
            </div>
          )
        }
      </EditableSection>
    )
  );

export { ConditionExperience };
