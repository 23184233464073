import { combine, createEvent, createStore, sample } from 'effector';

import { $$shared } from '@features/billing/billing-details/model';

import { $$careSpace } from '@entities/care-space';

import { $$user } from '@entities/user';

const $visible = createStore(false);

const closed = createEvent();

const opened = createEvent();

const addPaymentDetailsClicked = createEvent();

const $inOwnCareSpace = combine(
  $$careSpace.$careSpace,

  $$user.$account,

  (careSpace, account) =>
    careSpace && account && careSpace?.owner?.id == account?.id
);

const $$emailConfirmed = {
  $visible,
  $inOwnCareSpace,

  closed,
  opened,
  addPaymentDetailsClicked
};

// condition({
//   source: sample({
//     clock: closed,
//
//     source: {
//       role: $$session.$role,
//
//       logged: $$session.$active
//     }
//   }),
//
//   if: ({ role, logged }) => role == RoleType.CareRecipient && logged,
//
//   then: routes.matches.open.prepend(() => ({})),
//
//   else: routes.schedule.open.prepend(() => ({}))
// });

sample({
  clock: addPaymentDetailsClicked,

  target: $$shared.billingDetailsOpened
});

$visible.on(opened, () => true).reset(closed, addPaymentDetailsClicked);

export { $$emailConfirmed };
