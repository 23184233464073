import logo from '@icon/auth-logo.svg';

import burgerClose from '@icon/burger-close.svg';

import burger from '@icon/burger.svg';

import plus from '@icon/icn-plus.svg';

import logout from '@icon/logout.svg';

import message from '@icon/message.svg';

import notification from '@icon/notification.svg';

import profile from '@icon/profile.svg';

import settings from '@icon/settings.svg';

import switchAccount from '@icon/switch.svg';

import { Link } from 'atomic-router-react';

import classNames from 'classnames';

import type { Event } from 'effector';

import { combine } from 'effector';

import { useRef } from 'react';

import { $avatarUrl, $user } from '@entities/_computed/user';

import { $$careRecipient } from '@entities/care-recipient';

import { $$session, RoleType } from '@entities/session';

import { $$user } from '@entities/user';

import { $windowSize } from '@shared/lib/global-events';

import { useClickOutside } from '@shared/lib/hooks';

import { connect, createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { useMedia } from '@shared/ui/atoms/media';

import { UserBox } from '@shared/ui/atoms/user-box';

import { $$dropdown } from '../../model';

import { AddRoleConfirm } from './add-role-confirm';

import { CareRecipients } from './care-recipients';

import { CareSpace } from './care-space';

import { DropdownBox, DropdownBoxContainer } from './dropdown-box';

import styles from './header.module.scss';

import { Line } from './line';

import { LinkItem } from './link-item';

import { Menu, MenuContentContainer } from './menu';

import { Navigation } from './navigation';

const Header = createView()
  .units({
    opened: $$dropdown.$opened,

    careRecipientsVisible: $$dropdown.$careRecipientsVisible,

    onMenuClick: $$dropdown.hamburgerClicked,

    addRoleVisible: $$dropdown.$shouldConfirmAddRole,

    careSpaceVisible: $$dropdown.$careSpaceVisible
  })

  .map(({ opened }) => {
    const lg = useMedia('>=lg');

    return {
      navigationVisible: lg,

      menuVisible: !lg && opened
    };
  })

  .view(
    ({
      opened,
      menuVisible,
      addRoleVisible,
      careSpaceVisible,
      navigationVisible,
      careRecipientsVisible
    }) => (
      <header
        className={classNames(styles.header, {
          [styles.opened]: opened
        })}
      >
        <div className={styles.container}>
          <Link className={styles.logo} to={routes.schedule}>
            <img src={logo} />
          </Link>

          <div className={styles.navigation}>
            {navigationVisible && <Navigation />}
          </div>

          <Controls />
        </div>

        {menuVisible && (
          <Menu>
            {careSpaceVisible && <CareSpace.Mobile />}

            {careRecipientsVisible && careSpaceVisible && <CareRecipients />}

            <MenuContentContainer>
              <Links />

              <div className={styles.user}>
                <CurrentUserBox />
              </div>
            </MenuContentContainer>

            <Navigation />
          </Menu>
        )}

        {addRoleVisible && <AddRoleConfirm />}
      </header>
    )
  );

const Links = createView()
  .units({
    role: $$session.$role,

    hasCareRecipients: $$careRecipient.$hasCareRecipients,

    careSpaceVisible: $$dropdown.$careSpaceVisible,

    height: $windowSize.map(state => state.height),

    careRecipientID: $$careRecipient.$careRecipientID,

    onLogoutClick: $$dropdown.logoutClicked as Event<void>,

    onSwitchAccountClick: $$dropdown.accountSwitchClicked as Event<void>,

    onCreateCareSpaceClick: $$dropdown.createCareSpaceClicked,

    onAddCareRecipientsClick: $$dropdown.addCareRecipientsClicked
  })

  .view(
    ({
      role,
      onLogoutClick,
      careRecipientID,
      careSpaceVisible,
      hasCareRecipients,
      onSwitchAccountClick,
      onCreateCareSpaceClick,
      onAddCareRecipientsClick
    }) => {
      const createOwnCareSpace = (
        <LinkItem src={plus} onClick={onCreateCareSpaceClick}>
          Create your own Care Space
        </LinkItem>
      );

      return (
        <>
          {!careSpaceVisible &&
            !hasCareRecipients &&
            role == RoleType.CareRecipient && (
              <>
                <div className={styles.linkSection}>{createOwnCareSpace}</div>

                <Line />
              </>
            )}

          {hasCareRecipients && <Line />}

          <div className={styles.linkSection}>
            {!careSpaceVisible &&
              hasCareRecipients &&
              role == RoleType.CareRecipient &&
              createOwnCareSpace}

            {role == RoleType.CareRecipient ? (
              <>
                {hasCareRecipients && careSpaceVisible ? (
                  <LinkItem
                    src={profile}
                    to={
                      careRecipientID
                        ? routes.careRecipients.general
                        : routes.careRecipients.base
                    }
                    params={careRecipientID ? { id: careRecipientID } : {}}
                  >
                    Care recipients
                  </LinkItem>
                ) : (
                  careSpaceVisible && (
                    <LinkItem src={profile} onClick={onAddCareRecipientsClick}>
                      Add care recipients
                    </LinkItem>
                  )
                )}
              </>
            ) : (
              <LinkItem src={profile} to={routes.profile}>
                Profile
              </LinkItem>
            )}

            {role == RoleType.CareRecipient && (
              <LinkItem src={settings} to={routes.settings.account}>
                Settings
              </LinkItem>
            )}
          </div>

          <Line />

          <div className={styles.linkSection}>
            <LinkItem src={switchAccount} onClick={onSwitchAccountClick}>
              Switch to {role == RoleType.Carer ? 'client' : 'carer'} account
            </LinkItem>
          </div>

          <Line />

          <div className={styles.linkSection}>
            <LinkItem src={logout} onClick={onLogoutClick}>
              Logout
            </LinkItem>
          </div>

          <Line />
        </>
      );
    }
  );

const CurrentUserBox = connect(UserBox)
  .static({
    className: styles.user
  })

  .units({
    src: $avatarUrl,

    title: combine($user, $$user.$account, (user, account) => {
      const source = user ?? account;

      if (!source) return '';

      const { firstName, lastName } = source;

      return `${firstName} ${lastName}`;
    }),

    subTitle: $$user.$account.map(state => state?.email ?? '')
  })

  .view();

const Controls = createView()
  .units({
    avatarUrl: $avatarUrl,

    opened: $$dropdown.$opened,

    careRecipientsVisible: $$dropdown.$careRecipientsVisible,

    onAvatarClick: $$dropdown.avatarClicked as Event<any>,

    onOutsideDropdownClick: $$dropdown.outsideClicked,

    careSpaceVisible: $$dropdown.$careSpaceVisible,

    onHamburgerClick: $$dropdown.hamburgerClicked.prepend(() => {})
  })

  .map(({ opened, onOutsideDropdownClick }) => {
    const lg = useMedia('>=lg');

    const avatarRef = useRef<HTMLDivElement>();

    useClickOutside(() => lg && onOutsideDropdownClick(), avatarRef, [lg]);

    return {
      avatarRef,

      dropdownVisible: opened && lg,

      otherControlsVisible: lg || !opened
    };
  })

  .view(
    ({
      opened,
      avatarUrl,
      avatarRef,
      dropdownVisible,
      careSpaceVisible,
      otherControlsVisible,
      careRecipientsVisible,
      onAvatarClick,
      onHamburgerClick
    }) => (
      <div className={styles.controls}>
        {otherControlsVisible && (
          <>
            <div className={styles.message}>
              <img src={message} />
            </div>

            <div
              className={classNames(
                styles.notification,
                styles.notificationHasSome
              )}
            >
              <img src={notification} />
            </div>
          </>
        )}

        <div className={styles.avatar} ref={avatarRef}>
          <img src={avatarUrl} onClick={onAvatarClick} />

          {dropdownVisible && (
            <DropdownBox>
              {careSpaceVisible && <CareSpace.Desktop />}

              {careRecipientsVisible && careSpaceVisible && <CareRecipients />}

              <DropdownBoxContainer>
                <Links />

                <div className={styles.user}>
                  <CurrentUserBox />
                </div>
              </DropdownBoxContainer>
            </DropdownBox>
          )}
        </div>

        <img
          className={classNames(styles.burger, {
            [styles.burgerActive]: opened
          })}
          src={opened ? burgerClose : burger}
          onClick={onHamburgerClick}
        />
      </div>
    )
  );

export { Header };
