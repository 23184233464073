import { createEffect } from 'effector';

import type { FullAdressOptions, ShortAddressOptions } from './types';

const BASE_MAP_URL = 'https://maps.google.com/?q=';

const openFullAddressFx = createEffect<FullAdressOptions, void>();

const openShortAddressFx = createEffect<ShortAddressOptions, void>();

const $$openAddressOnMap = {
  openFullAddressFx,
  openShortAddressFx
};

openFullAddressFx.use(({ addressLine1, addressLine2, city, postCode }) => {
  window.open(
    BASE_MAP_URL + [addressLine1, addressLine2, city, postCode].join(',')
  );
});

openShortAddressFx.use(({ district, city, postCode }) => {
  window.open(BASE_MAP_URL + [district, city, postCode].join(','));
});

export { $$openAddressOnMap };
