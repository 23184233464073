import { Auth } from '@layouts/auth';

import { WorkspaceAction } from '@widgets/workspace-action';

import { Type } from '@widgets/workspace-action/workspace-action.props';

import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

const LeftCareSpace = createView()
  .units({
    name: routes.leaveCareSpace.confirmed.$params.map(params =>
      decodeURIComponent(params.name)
    )
  })

  .view(({ name }) => (
    <Auth.Container>
      <Auth.Header centered logoTo={routes.schedule} />

      <Auth.Body>
        <WorkspaceAction type={Type.Left} leftWorkspaceName={name} />
      </Auth.Body>
    </Auth.Container>
  ));

export { LeftCareSpace };
