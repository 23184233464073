/**
 * Build enviroment
 */
const enviroment = {
  development: import.meta.env.DEV,

  carersHQApiUrl: import.meta.env.VITE_CARERS_HQ_API_URL
};

export { enviroment };
