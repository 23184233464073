import { $$dictionary } from '@entities/dictionary';

import { createView } from '@shared/lib/view';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { form } from '../model';

import styles from './condition-experience.module.scss';

const ConditionExperienceForm = createView()
  .units({
    conditionTypes: $$dictionary.$conditionTypes
  })

  .view(({ conditionTypes }) => (
    <Form use={form} className={styles.formFields}>
      <Field.FormSection
        use={form.fields.conditionsTypes}
        title='What conditions should the carer have experience with?'
        tip='You can select multiple conditions'
      >
        <div className={styles.checkboxGroup}>
          <Field.CheckboxList
            use={form.fields.conditionsTypes}
            options={conditionTypes}
          />
        </div>
      </Field.FormSection>

      <div className={styles.formContainer}>
        <Field.Input
          use={form.fields.additionalDetails}
          label='Additional details'
          placeholder='Enter'
        />
      </div>
    </Form>
  ));

export { ConditionExperienceForm };
