import classNames from 'classnames';

import {
  CareType,
  NightCareType,
  VisitCareType,
  visitCareTypeNames
} from '@shared/api';

import { createView } from '@shared/lib/view';

import { BoxRow } from '@shared/ui/atoms/box-row';

import { FormSection } from '@shared/ui/atoms/form-section';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { form } from '../model';

import styles from './care-basics.module.scss';

const CareBasicsForm = createView()
  .units({
    visitCareTypeValue: form.fields.visitCareType.$value,

    careTypesValue: form.fields.careTypes.$value
  })

  .view(({ visitCareTypeValue, careTypesValue }) => (
    <Form use={form} className={classNames(styles.formFields)}>
      <FormSection title='What type of care is required?'>
        <BoxRow layout='horizontalAlways'>
          <Field.Radio
            use={form.fields.visitCareType}
            label={visitCareTypeNames[VisitCareType.VisitingCare]}
            payload={VisitCareType.VisitingCare}
          />

          <Field.Radio
            use={form.fields.visitCareType}
            label={visitCareTypeNames[VisitCareType.LiveInCare]}
            payload={VisitCareType.LiveInCare}
          />
        </BoxRow>
      </FormSection>

      {visitCareTypeValue == VisitCareType.VisitingCare && (
        <>
          <Field.FormSection
            use={form.fields.careTypes}
            title='What type of care is required?'
          >
            <BoxRow layout='horizontalAlways'>
              <Field.CheckboxList
                use={form.fields.careTypes}
                options={[
                  {
                    id: CareType.DayCare,
                    name: 'Day care'
                  },

                  {
                    id: CareType.NightCare,
                    name: 'Night care'
                  }
                ]}
              />
            </BoxRow>
          </Field.FormSection>

          {careTypesValue?.includes(CareType.NightCare) && (
            <Field.FormSection
              use={form.fields.nightCareTypes}
              title='What type of care is required?'
              tip='You can select multiple types'
            >
              <BoxRow layout='horizontalAlways'>
                <Field.CheckboxList
                  use={form.fields.nightCareTypes}
                  options={[
                    {
                      id: NightCareType.Sleeping,
                      name: 'Sleeping night care'
                    },

                    {
                      id: NightCareType.Waking,
                      name: 'Waking night care'
                    }
                  ]}
                />
              </BoxRow>
            </Field.FormSection>
          )}
        </>
      )}

      <div className={styles.formContainer}>
        <Field.Input
          nowrap
          use={form.fields.hoursPerWeek}
          name='hoursPerWeek'
          placeholder='Enter'
          label='Hours per week'
        />
      </div>
    </Form>
  ));

export { CareBasicsForm };
