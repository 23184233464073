import classNames from 'classnames';

import type { FC, HTMLAttributes } from 'react';

import styles from './form-section.module.scss';

import type { FormSectionProps } from './form-section.props';

/**
 * <FormSection />
 */
const FormSection: FC<FormSectionProps> = ({
  children,
  hasError,
  error,
  touched,
  title,
  subTitle,
  onTouch,
  tip,
  classNames: _classNames,
  className,
  ...props
}) => (
  <div
    className={classNames(styles.formSection, className, {
      [styles.hasError]: hasError
    })}
    data-has-error={hasError}
    {...props}
  >
    <div className={classNames(styles.header, _classNames?.header)}>
      {title && (
        <h3 className={styles.title}>
          <span className={styles.titleText}>{title}</span>

          {hasError && error ? (
            <span className={classNames(styles.required, _classNames?.error)}>
              {error}
            </span>
          ) : (
            tip && (
              <span
                className={
                  typeof tip === 'string' ? styles.tip : styles.customTip
                }
              >
                {tip}
              </span>
            )
          )}
        </h3>
      )}

      {subTitle && <h4 className={styles.subTitle}>{subTitle}</h4>}
    </div>

    <div>{children}</div>
  </div>
);

const FormSectionGroup: FC<HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => <div className={classNames(className, styles.group)} {...props} />;

export { FormSection, FormSectionGroup };
