import { createStore } from 'effector';

import { array, boolean, object } from 'yup';

import { createForm } from '@shared/lib/form';

const form = createForm({
  initialValues: {
    languages: [
      {
        id: 1,
        name: 'English'
      }
    ],

    pets: [],

    transportationHelp: null,

    smoking: false,

    carerGender: null
  },

  $schema: createStore(
    object({
      languages: array(object().nullable()).nullable(),

      transportationHelp: object()
        .nullable()

        .label('Transportation help'),

      smoking: boolean()
        .nullable()

        .label('Smoking'),

      pets: array()
        .nullable()

        .label('Pets'),

      carerGender: object()
        .nullable()

        .label('Gender')
    })
  )
});

const $$profilePreferences = {
  form
};

export { $$profilePreferences, form };
