import { useStoreMap, useUnit } from 'effector-react';

import { RateFields, SupportTypesList } from '@entities/carer';

import { $$dictionary } from '@entities/dictionary';

import type { SupportTypeId } from '@shared/api';

import { supportTypeNames } from '@shared/api';

import { createView } from '@shared/lib/view';

import { BoxRow } from '@shared/ui/atoms/box-row';

import { FormSection, FormSectionGroup } from '@shared/ui/atoms/form-section';

import { useMedia } from '@shared/ui/atoms/media';

import { Field } from '@shared/ui/organisms/field';

import { form } from '../model';

import styles from './rates.module.scss';

/**
 * <Services />
 */
const RatesForm = createView()
  .units({
    supportTypes: $$dictionary.$supportTypes,

    shouldShowRate: form.fields.supportTypes.$value.map(
      state => (state?.length ?? 0) > 0
    ),

    supportType: form.fields.supportTypes.$value
  })

  .map(({ supportType }) => {
    const ids = supportType.map(item => item.id);

    const hasRatesErrors = useStoreMap({
      store: form.$errors,

      keys: [],

      defaultValue: false,

      fn: state => Boolean(state.supportTypes)
    });

    const mobile = useMedia('<=md');

    const shouldShowRateTitleError = useUnit(form.$submitted);

    return {
      ids,

      mobile,

      hasRatesErrors: hasRatesErrors && shouldShowRateTitleError
    };
  })

  .view(({ shouldShowRate, supportTypes, ids, hasRatesErrors, mobile }) => (
    <FormSectionGroup>
      <Field.FormSection
        use={form.fields.supportTypes}
        title='What type of care do you provide?'
        tip='You can select multiple care types'
      >
        <BoxRow layout='horizontal'>
          <SupportTypesList
            supportTypes={supportTypes}
            use={form.fields.supportTypes}
          />
        </BoxRow>
      </Field.FormSection>

      {shouldShowRate && (
        <FormSection
          hasError={hasRatesErrors}
          title='Rate details'
          tip='Clients will see it and be guided by it when offering a job'
        >
          <div className={styles.rates}>
            <Field.Container use={form.fields.supportTypes}>
              {({ value }) =>
                value
                  ?.filter(({ id }, index) => !ids.includes(id, index + 1))
                  .map((item, index) => (
                    <RateFields
                      mobile={mobile}
                      key={item.id}
                      name={
                        supportTypeNames[item?.supportType?.id as SupportTypeId]
                      }
                      use={[
                        form.fields.supportTypes[index].rates[0].rate,

                        form.fields.supportTypes[index].rates[1].rate
                      ]}
                    />
                  ))
              }
            </Field.Container>
          </div>
        </FormSection>
      )}
    </FormSectionGroup>
  ));

export { RatesForm };
