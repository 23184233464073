import { Link } from 'atomic-router-react';

import { styled } from '@shared/ui/stitches';

const Anchor = styled(Link, {
  color: '$blue',
  transition: '0.25s',
  fontWeight: 500,
  cursor: 'pointer',

  '&:hover': {
    color: '$yellowHovered'
  },

  '&:active': {
    color: '$yellowPrimary'
  }
});

export { Anchor };
