import { createEffect, createStore } from 'effector';

import type { PostCodeAddressMatchResult } from '@shared/api';

import { carersHQ } from '@shared/api';

const findAddressesByPostcodeFx = createEffect<
  string,
  PostCodeAddressMatchResult[]
>();

const $postcode = createStore('');

const $results = createStore<PostCodeAddressMatchResult[]>([]);

const $$address = {
  $results,
  $postcode,

  findAddressesByPostcodeFx
};

$postcode.on(findAddressesByPostcodeFx, (_, payload) => payload);

$results
  .on(findAddressesByPostcodeFx.doneData, (_, payload) => payload)

  .on(findAddressesByPostcodeFx.failData, () => []);

findAddressesByPostcodeFx.use(async postcode => {
  const { data } = await carersHQ.postcode.addresses({ postcode });

  return data;
});

export { $$address };
