// const $completeProfile = createStore(false);

// const triedToLeave = createEvent();

// const confirmed = createEvent();

// const promptFx = createEffect(() => confirm('Confirm leave'));

import { attach, createEvent, createStore, sample } from 'effector';

import { $$carerAdditionalInformation } from '@features/carer/profile/additional-information';

import { $$carerConditionExperience } from '@features/carer/profile/condition-experience';

import { $$carerContactDetails } from '@features/carer/profile/contact-details';

import { $$carerMedia } from '@features/carer/profile/media';

import { $$carerQualifications } from '@features/carer/profile/qualifications';

import { $$carerRates } from '@features/carer/profile/rates/model';

import { $$carerServices } from '@features/carer/profile/services/model';

import { $$carerProfile } from '@entities/carer/model';

import { $$user } from '@entities/user';

import type { Carer } from '@shared/api';

import { ToastID } from '@shared/config';

import { routes } from '@shared/routes';

import { createEditableSection } from '@shared/ui/atoms/editable-section';

import type { NotifyOptions } from '@shared/ui/organisms/toasts';

import { $$toast } from '@shared/ui/organisms/toasts';

const saveRatingsFx = attach({
  effect: $$carerProfile.saveCarerFx
});

const saveServicesFx = attach({
  effect: $$carerProfile.saveCarerFx
});

const saveExperienceFx = attach({
  effect: $$carerProfile.saveCarerFx
});

const saveQualificationFx = attach({
  effect: $$carerProfile.saveCarerFx
});

const saveContactDetailsFx = attach({
  effect: $$carerProfile.saveCarerFx
});

const saveAdditionalInfoFx = attach({
  effect: $$carerProfile.saveCarerFx
});

const saveProfileClicked = createEvent();

const submitMedia = createEvent();

const resetError = createEvent();

const downloadLargeFile = createEvent<number>();

const rating = createEditableSection();

const services = createEditableSection();

const conditionExperience = createEditableSection();

const qualifications = createEditableSection();

const contactDetails = createEditableSection();

const additionalInformation = createEditableSection();

const saveRatingClicked = createEvent();

const saveServicesClicked = createEvent();

const saveConditionExperienceClicked = createEvent();

const saveQualificationsClicked = createEvent();

const saveContactDetailsClicked = createEvent();

const saveAdditionalInformationClicked = createEvent();

const $mediaError = createStore(false).reset(resetError);

const $$profilePage = {
  $mediaError,

  rating,

  services,

  conditionExperience,

  qualifications,

  contactDetails,

  additionalInformation,

  saveProfileClicked,

  submitMedia,

  resetError,

  downloadLargeFile,

  saveRatingClicked,

  saveServicesClicked,

  saveQualificationsClicked,

  saveContactDetailsClicked,

  saveAdditionalInformationClicked,

  saveConditionExperienceClicked
};

sample({
  clock: routes.profile.opened,

  source: $$user.$account,

  fn: user => user?.carer?.id,

  target: $$carerProfile.getCarerPublicProfileFx
});

sample({
  clock: [
    $$carerServices.form.submitted,
    $$carerRates.form.submitted,
    $$carerConditionExperience.form.submitted,
    $$carerQualifications.form.submitted,
    $$carerContactDetails.form.submitted,
    $$carerAdditionalInformation.form.submitted
  ],

  target: saveProfileClicked
});

sample({
  clock: $$carerRates.form.submitted,

  target: saveRatingClicked
});

sample({
  clock: $$carerServices.form.submitted,

  target: saveServicesClicked
});

sample({
  clock: $$carerConditionExperience.form.submitted,

  target: saveConditionExperienceClicked
});

sample({
  clock: $$carerQualifications.form.submitted,

  target: saveQualificationsClicked
});

sample({
  clock: $$carerContactDetails.form.submitted,

  target: saveContactDetailsClicked
});

sample({
  clock: $$carerAdditionalInformation.form.submitted,

  target: saveAdditionalInformationClicked
});

const saveRating = sample({
  clock: saveRatingClicked,

  source: {
    carer: $$carerProfile.$carerProfile,
    rating: $$carerRates.form.$values,
    services: $$carerServices.form.$values,
    conditionExperience: $$carerConditionExperience.form.$values,
    qualifications: $$carerQualifications.form.$values,
    contactDetails: $$carerContactDetails.form.$values,
    additionalInformation: $$carerAdditionalInformation.form.$values
  },

  fn: ({
    carer,
    rating,
    services,
    conditionExperience,
    qualifications,
    contactDetails,
    additionalInformation
  }) => {
    const ids = rating.supportTypes.map(item => item.id);

    const supportTypes = rating.supportTypes
      .map(item => ({
        id: item.id,
        rates: item.rates,
        supportType: { id: item.id, name: '' }
      }))
      .filter(({ id }, index) => !ids.includes(id, index + 1));

    return {
      id: carer?.id,

      ...rating,
      ...services,
      ...conditionExperience,
      ...qualifications,
      ...contactDetails,
      ...additionalInformation,

      services: services.services.map(item => ({
        id: item
      })),

      conditionsTypes: conditionExperience.conditionsTypes.map(item => ({
        id: item
      })),

      qualifications: qualifications.qualifications.map(item => ({
        id: item
      })),

      certificates: qualifications.certificates.map(item => ({
        id: item
      })),

      petsDisabled: additionalInformation.petsDisabled.map(item => ({
        id: item
      })),

      supportTypes: supportTypes,

      transportationHelp: additionalInformation.transportationHelp && {
        id: additionalInformation.transportationHelp
      }
    } as Carer;
  }
});

const saveServices = sample({
  clock: saveServicesClicked,

  source: {
    carer: $$carerProfile.$carerProfile,
    rating: $$carerRates.form.$values,
    services: $$carerServices.form.$values,
    conditionExperience: $$carerConditionExperience.form.$values,
    qualifications: $$carerQualifications.form.$values,
    contactDetails: $$carerContactDetails.form.$values,
    additionalInformation: $$carerAdditionalInformation.form.$values
  },

  fn: ({
    carer,
    rating,
    services,
    conditionExperience,
    qualifications,
    contactDetails,
    additionalInformation
  }) => {
    const ids = rating.supportTypes.map(item => item.id);

    const supportTypes = rating.supportTypes
      .map(item => ({
        id: item.id,
        rates: item.rates,
        supportType: { id: item.id, name: '' }
      }))
      .filter(({ id }, index) => !ids.includes(id, index + 1));

    return {
      id: carer?.id,

      ...rating,
      ...services,
      ...conditionExperience,
      ...qualifications,
      ...contactDetails,
      ...additionalInformation,

      services: services.services.map(item => ({
        id: item
      })),

      conditionsTypes: conditionExperience.conditionsTypes.map(item => ({
        id: item
      })),

      qualifications: qualifications.qualifications.map(item => ({
        id: item
      })),

      certificates: qualifications.certificates.map(item => ({
        id: item
      })),

      petsDisabled: additionalInformation.petsDisabled.map(item => ({
        id: item
      })),

      supportTypes: supportTypes,

      transportationHelp: additionalInformation.transportationHelp && {
        id: additionalInformation.transportationHelp
      }
    } as Carer;
  }
});

const saveConditionExperience = sample({
  clock: saveConditionExperienceClicked,

  source: {
    carer: $$carerProfile.$carerProfile,
    rating: $$carerRates.form.$values,
    services: $$carerServices.form.$values,
    conditionExperience: $$carerConditionExperience.form.$values,
    qualifications: $$carerQualifications.form.$values,
    contactDetails: $$carerContactDetails.form.$values,
    additionalInformation: $$carerAdditionalInformation.form.$values
  },

  fn: ({
    carer,
    rating,
    services,
    conditionExperience,
    qualifications,
    contactDetails,
    additionalInformation
  }) => {
    const ids = rating.supportTypes.map(item => item.id);

    const supportTypes = rating.supportTypes
      .map(item => ({
        id: item.id,
        rates: item.rates,
        supportType: { id: item.id, name: '' }
      }))
      .filter(({ id }, index) => !ids.includes(id, index + 1));

    return {
      id: carer?.id,

      ...rating,
      ...services,
      ...conditionExperience,
      ...qualifications,
      ...contactDetails,
      ...additionalInformation,

      services: services.services.map(item => ({
        id: item
      })),

      conditionsTypes: conditionExperience.conditionsTypes.map(item => ({
        id: item
      })),

      qualifications: qualifications.qualifications.map(item => ({
        id: item
      })),

      certificates: qualifications.certificates.map(item => ({
        id: item
      })),

      petsDisabled: additionalInformation.petsDisabled.map(item => ({
        id: item
      })),

      supportTypes: supportTypes,

      transportationHelp: additionalInformation.transportationHelp && {
        id: additionalInformation.transportationHelp
      }
    } as Carer;
  }
});

const saveQualifications = sample({
  clock: saveQualificationsClicked,

  source: {
    carer: $$carerProfile.$carerProfile,
    rating: $$carerRates.form.$values,
    services: $$carerServices.form.$values,
    conditionExperience: $$carerConditionExperience.form.$values,
    qualifications: $$carerQualifications.form.$values,
    contactDetails: $$carerContactDetails.form.$values,
    additionalInformation: $$carerAdditionalInformation.form.$values
  },

  fn: ({
    carer,
    rating,
    services,
    conditionExperience,
    qualifications,
    contactDetails,
    additionalInformation
  }) => {
    const ids = rating.supportTypes.map(item => item.id);

    const supportTypes = rating.supportTypes
      .map(item => ({
        id: item.id,
        rates: item.rates,
        supportType: { id: item.id, name: '' }
      }))
      .filter(({ id }, index) => !ids.includes(id, index + 1));

    return {
      id: carer?.id,

      ...rating,
      ...services,
      ...conditionExperience,
      ...qualifications,
      ...contactDetails,
      ...additionalInformation,

      services: services.services.map(item => ({
        id: item
      })),

      conditionsTypes: conditionExperience.conditionsTypes.map(item => ({
        id: item
      })),

      qualifications: qualifications.qualifications.map(item => ({
        id: item
      })),

      certificates: qualifications.certificates.map(item => ({
        id: item
      })),

      petsDisabled: additionalInformation.petsDisabled.map(item => ({
        id: item
      })),

      supportTypes: supportTypes,

      transportationHelp: additionalInformation.transportationHelp && {
        id: additionalInformation.transportationHelp
      }
    } as Carer;
  }
});

const saveContactDetails = sample({
  clock: saveContactDetailsClicked,

  source: {
    carer: $$carerProfile.$carerProfile,
    rating: $$carerRates.form.$values,
    services: $$carerServices.form.$values,
    conditionExperience: $$carerConditionExperience.form.$values,
    qualifications: $$carerQualifications.form.$values,
    contactDetails: $$carerContactDetails.form.$values,
    additionalInformation: $$carerAdditionalInformation.form.$values
  },

  fn: ({
    carer,
    rating,
    services,
    conditionExperience,
    qualifications,
    contactDetails,
    additionalInformation
  }) => {
    const ids = rating.supportTypes.map(item => item.id);

    const supportTypes = rating.supportTypes
      .map(item => ({
        id: item.id,
        rates: item.rates,
        supportType: { id: item.id, name: '' }
      }))
      .filter(({ id }, index) => !ids.includes(id, index + 1));

    return {
      id: carer?.id,

      ...rating,
      ...services,
      ...conditionExperience,
      ...qualifications,
      ...contactDetails,
      ...additionalInformation,

      services: services.services.map(item => ({
        id: item
      })),

      conditionsTypes: conditionExperience.conditionsTypes.map(item => ({
        id: item
      })),

      qualifications: qualifications.qualifications.map(item => ({
        id: item
      })),

      certificates: qualifications.certificates.map(item => ({
        id: item
      })),

      petsDisabled: additionalInformation.petsDisabled.map(item => ({
        id: item
      })),

      supportTypes: supportTypes,

      transportationHelp: additionalInformation.transportationHelp && {
        id: additionalInformation.transportationHelp
      }
    } as Carer;
  }
});

const saveAdditionalInformation = sample({
  clock: saveAdditionalInformationClicked,

  source: {
    carer: $$carerProfile.$carerProfile,
    rating: $$carerRates.form.$values,
    services: $$carerServices.form.$values,
    conditionExperience: $$carerConditionExperience.form.$values,
    qualifications: $$carerQualifications.form.$values,
    contactDetails: $$carerContactDetails.form.$values,
    additionalInformation: $$carerAdditionalInformation.form.$values
  },

  fn: ({
    carer,
    rating,
    services,
    conditionExperience,
    qualifications,
    contactDetails,
    additionalInformation
  }) => {
    const ids = rating.supportTypes.map(item => item.id);

    const supportTypes = rating.supportTypes
      .map(item => ({
        id: item.id,
        rates: item.rates,
        supportType: { id: item.id, name: '' }
      }))
      .filter(({ id }, index) => !ids.includes(id, index + 1));

    return {
      id: carer?.id,

      ...rating,
      ...services,
      ...conditionExperience,
      ...qualifications,
      ...contactDetails,
      ...additionalInformation,

      services: services.services.map(item => ({
        id: item
      })),

      conditionsTypes: conditionExperience.conditionsTypes.map(item => ({
        id: item
      })),

      qualifications: qualifications.qualifications.map(item => ({
        id: item
      })),

      certificates: qualifications.certificates.map(item => ({
        id: item
      })),

      petsDisabled: additionalInformation.petsDisabled.map(item => ({
        id: item
      })),

      supportTypes: supportTypes,

      transportationHelp: additionalInformation.transportationHelp && {
        id: additionalInformation.transportationHelp
      }
    } as Carer;
  }
});

sample({
  clock: saveRating,

  target: saveRatingsFx
});

sample({
  clock: saveServices,

  target: saveServicesFx
});

sample({
  clock: saveConditionExperience,

  target: saveExperienceFx
});

sample({
  clock: saveQualifications,

  target: saveQualificationFx
});

sample({
  clock: saveContactDetails,

  target: saveContactDetailsFx
});

sample({
  clock: saveAdditionalInformation,

  target: saveAdditionalInfoFx
});

sample({
  clock: [
    saveRatingsFx.doneData,
    saveServicesFx.doneData,
    saveExperienceFx.doneData,
    saveQualificationFx.doneData,
    saveContactDetailsFx.doneData,
    saveAdditionalInfoFx.doneData
  ],

  source: $$user.$account,

  fn: (account, carer) => ({ ...account, carer }),

  target: $$user.$account
});

sample({
  clock: [
    saveRatingsFx.doneData,
    saveServicesFx.doneData,
    saveExperienceFx.doneData,
    saveQualificationFx.doneData,
    saveContactDetailsFx.doneData,
    saveAdditionalInfoFx.doneData
  ],

  fn: carer => carer?.id,

  target: $$carerProfile.getCarerPublicProfileFx
});

sample({
  clock: saveRatingsFx.doneData,

  target: rating.close
});

sample({
  clock: saveServicesFx.doneData,

  target: services.close
});

sample({
  clock: saveExperienceFx.doneData,

  target: conditionExperience.close
});

sample({
  clock: saveQualificationFx.doneData,

  target: qualifications.close
});

sample({
  clock: saveContactDetailsFx.doneData,

  target: contactDetails.close
});

sample({
  clock: saveAdditionalInfoFx.doneData,

  target: additionalInformation.close
});

sample({
  clock: [
    rating.open,
    rating.close,
    $$carerProfile.getCarerPublicProfileFx.doneData
  ],

  source: $$carerProfile.$carerProfile,

  fn: profile => ({
    supportTypes: profile?.supportTypes.map(item => ({
      ...item,
      id: item.supportType.id
    }))
  }),

  target: $$carerRates.form.patch
});

sample({
  clock: [
    conditionExperience.open,
    conditionExperience.close,
    $$carerProfile.getCarerPublicProfileFx.doneData
  ],

  source: $$carerProfile.$carerProfile,

  fn: profile => ({
    conditionsTypes: profile?.conditionsTypes.map(item => item?.id)
  }),

  target: $$carerConditionExperience.form.patch
});

sample({
  clock: [
    services.open,
    services.close,
    $$carerProfile.getCarerPublicProfileFx.doneData
  ],

  source: $$carerProfile.$carerProfile,

  fn: profile => ({
    services: profile?.services.map(item => item?.id)
  }),

  target: $$carerServices.form.patch
});

sample({
  clock: [
    qualifications.open,
    qualifications.close,
    $$carerProfile.getCarerPublicProfileFx.doneData
  ],

  source: $$carerProfile.$carerProfile,

  fn: profile => ({
    qualificationConfirm: profile?.qualificationConfirm,
    carerStart: profile?.carerStart,
    certificates: profile?.certificates.map(item => item.id),
    qualifications: profile?.qualifications.map(item => item.id)
  }),

  target: $$carerQualifications.form.reset
});

sample({
  clock: [
    contactDetails.open,
    contactDetails.close,
    $$carerProfile.getCarerPublicProfileFx.doneData
  ],

  source: $$carerProfile.$carerProfile,

  fn: profile => ({
    city: profile?.city,
    phone: profile?.phone,
    postCode: profile?.postCode,
    addressLine1: profile?.addressLine1,
    addressLine2: profile?.addressLine2
  }),

  target: $$carerContactDetails.form.patch
});

sample({
  clock: [
    additionalInformation.open,
    additionalInformation.close,
    $$carerProfile.getCarerPublicProfileFx.doneData
  ],

  source: $$carerProfile.$carerProfile,

  fn: profile => ({
    languages: profile?.languages,
    transportationHelp: profile?.transportationHelp?.id,
    petsOk: profile?.petsOk,
    smokingOk: profile?.smokingOk,
    petsDisabled: profile?.petsDisabled.map(item => item.id)
  }),

  target: $$carerAdditionalInformation.form.patch
});

sample({
  clock: $$carerProfile.getCarerPublicProfileFx.doneData,

  source: $$carerProfile.$carerProfile,

  fn: profile => ({
    video: {
      url: profile?.videoUrl
    },

    image: {
      url: profile?.imageUrl
    }
  }),

  target: $$carerMedia.form.patch
});

sample({
  clock: submitMedia,

  source: $$carerMedia.form.$values,

  filter: ({ video, image }) => Boolean(video.url && image.url),

  fn: ({ video, image }) => ({
    video: video.file,
    image: image.file
  }),

  target: $$carerProfile.saveCarerMediaFx
});

sample({
  clock: submitMedia,

  source: $$carerMedia.form.$values,

  filter: ({ video, image }) => Boolean(!video?.url || !image?.url),

  fn: () => true,

  target: $mediaError
});

sample({
  clock: $$carerProfile.saveCarerMediaFx.doneData,

  source: {
    carer: $$carerProfile.$carerProfile
  },

  fn: ({ carer }, { imageUrl, videoUrl }) => ({
    ...carer,
    videoUrl,
    imageUrl
  }),

  target: saveRatingsFx
});

sample({
  clock: $$carerProfile.saveCarerMediaFx.doneData,

  target: $$user.getAccountFx
});

sample({
  clock: downloadLargeFile,

  fn: max =>
    ({
      id: ToastID.InvalidFileFormat,
      type: 'error',
      title: `File size is too big`,
      content: `<div style="word-break: break-word">Maximum allowed file size is ${max}MB</div>`,
      duration: 5000
    } as NotifyOptions),

  target: $$toast.notifyFx
});

export { $$profilePage };
