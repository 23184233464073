import { createEvent, createStore, sample } from 'effector';

import { $$createCareSpace } from '@features/care-space/create';

import { $$restoreCareSpace } from '@features/care-space/restore';

import { $hasOwnDeletedCareSpace } from '@entities/_computed/care-space';

import { createForm } from '@shared/lib/form';

enum CreateWorkspaceType {
  Restore = 0,
  New = 1
}

const form = createForm({
  initialValues: {
    createType: CreateWorkspaceType.Restore
  }
});

const workspaceVisibilityOpened = createEvent();

const workspaceVisibilityClosed = createEvent();

const $visible = createStore(false);

const $$createWorkspace = {
  form,

  $visible,

  workspaceVisibilityOpened,

  workspaceVisibilityClosed
};

sample({
  clock: form.submitted,

  filter: ({ createType }) => createType === CreateWorkspaceType.New,

  target: $$createCareSpace.createCareSpaceFx
});

sample({
  clock: form.submitted,

  filter: ({ createType }) => createType === CreateWorkspaceType.Restore,

  target: $$restoreCareSpace.restoreCareSpace
});

sample({
  clock: [
    $$createCareSpace.createCareSpaceFx.doneData,

    $$restoreCareSpace.restoreCareSpaceFx.doneData
  ],

  target: workspaceVisibilityClosed
});

sample({
  clock: workspaceVisibilityOpened,

  source: $hasOwnDeletedCareSpace,

  fn: canRestore => ({
    createType: canRestore
      ? CreateWorkspaceType.Restore
      : CreateWorkspaceType.New
  }),

  target: form.patch
});

$visible
  .on(workspaceVisibilityOpened, () => true)

  .reset(workspaceVisibilityClosed);

export { $$createWorkspace, form, CreateWorkspaceType };
