import { modelView } from 'effector-factorio';

import { useUnit } from 'effector-react';

import { $$dictionary } from '@entities/dictionary';

import { createView } from '@shared/lib/view';

import { Divider } from '@shared/ui/atoms/divider';

import { FormSection, FormSectionGroup } from '@shared/ui/atoms/form-section';

import { useMedia } from '@shared/ui/atoms/media';

import { RadioGroup } from '@shared/ui/atoms/radio';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { ManageQualificationsModelFactory } from '../../model';

import styles from './qualifications-form.module.scss';

/**
 * <Qualifications />
 */
const QualificationsForm = modelView(
  ManageQualificationsModelFactory,

  createView()
    .units({
      dbs: $$dictionary.$dbs,

      certificates: $$dictionary.$certificates,

      qualifications: $$dictionary.$qualifications
    })

    .map(({ qualifications, certificates }) => {
      const { form } = ManageQualificationsModelFactory.useModel();

      const mobile = useMedia('<md');

      const qualificationsOptions = mobile
        ? qualifications
        : [
            { id: 1, name: 'NVQ/QCF Level 2 in Health and Social Care' },

            { id: 5, name: 'Agency Induction Training (pre-2015)' },

            { id: 2, name: 'NVQ/QCF Level 3 in Health and Social Care' },

            { id: 6, name: 'Care Certificate' },

            { id: 3, name: 'NVQ/QCF Level 4 in Health and Social Care' },

            {},

            { id: 4, name: 'NVQ/QCF Level 5 in Health and Social Care' }
          ];

      const certificatesOptions = mobile
        ? certificates
        : [
            { id: 1, name: 'Physiotherapy certification: HCPC registered' },

            { id: 5, name: 'Univercity degree (other descipline)' },

            { id: 2, name: 'Occupational certification: HCPC registered' },

            { id: 6, name: 'Nursing (UK)' },

            { id: 3, name: 'Univercity degree in Health and Social Care' },

            { id: 7, name: 'Nursing (abroad)' },

            { id: 4, name: 'NVQ/QCF Level 5 in Health and Social Care' },

            { id: 8, name: 'Other' }
          ];

      return {
        form,

        qualificationsOptions,

        certificatesOptions,

        publicInsurance: useUnit(form.fields.publicInsuranse.$value),

        onSubmit: form.submit
      };
    })

    .view(
      ({
        form,
        dbs,
        qualificationsOptions,
        certificatesOptions,
        publicInsurance
      }) => (
        <Form use={form}>
          <FormSectionGroup>
            <FormSection
              title='What care qualifications do you have?'
              subTitle='In order to collaborate with CarersHQ, you must have one of the following qualifications:'
            >
              <div className={styles.qualifications}>
                <Field.CheckboxList
                  use={form.fields.qualifications}
                  options={qualificationsOptions}
                />
              </div>
            </FormSection>

            <FormSection
              title='What other certificates do you have?'
              className={styles.certificatesBlock}
            >
              <div className={styles.certificates}>
                <Field.CheckboxList
                  use={form.fields.certificates}
                  options={certificatesOptions}
                />
              </div>
            </FormSection>

            <Divider className={styles.divider} />

            <Field.CheckboxAlertBox
              use={form.fields.qualificationConfirm}
              hideMore
              classNames={{ alertBox: styles.alertBox }}
              title='I confirm I will be able to provide proof of my qualifications upon request'
              text='We will require proof of your qualifications at a later stage. This can be a copy of your certificate, a copy of your diploma, or a confirmation letter from the agency. '
            />

            <Divider className={styles.divider} />

            <FormSection
              className={styles.fieldGroup}
              title='Do you have a clean DBS?'
              subTitle='We require all our carers to have a clean DBS which is either registered online or dated in the last 6 months.'
            >
              <RadioGroup layout='vertical'>
                <Field.RadioList
                  use={form.fields.dbs}
                  options={[
                    {
                      id: null,
                      name: 'I do not currently have a DBS, but I am willing to pay for one'
                    },

                    ...dbs
                  ]}
                />
              </RadioGroup>
            </FormSection>

            <FormSection
              title='Do you have Public Liability insurance?'
              subTitle='We require all our carers to hold a valid Public Liability insurance policy.'
              className={styles.publicInsuranceBlock}
            >
              <RadioGroup className={styles.publicInsurance} layout='vertical'>
                <Field.RadioList
                  use={form.fields.publicInsuranse}
                  options={[
                    {
                      id: false,
                      name: 'I do not have Public Liability insurance and would like CarersHQ to arrange one for me for £1.52 per week, charged only on the weeks I work'
                    },

                    {
                      id: true,
                      name: 'I have Public Liability insurance and would like to keep my own insurance provider'
                    }
                  ]}
                />
              </RadioGroup>
            </FormSection>

            {publicInsurance && (
              <>
                <Divider className={styles.dividerInsurance} />

                <Field.CheckboxAlertBox
                  classNames={{ alertBox: styles.alertBoxInsurance }}
                  hideMore
                  use={form.fields.publicInsuranseConfirmed}
                  title='I confirm I will be able to provide proof of my Public Liability insurance coverage upon request'
                  text={
                    <p className={styles.insuranceDescription}>
                      If you prefer to keep your own insurance provider, we will
                      require proof of your insurance coverage.
                    </p>
                  }
                />
              </>
            )}
          </FormSectionGroup>
        </Form>
      )
    )
);

export { QualificationsForm };
