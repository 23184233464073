import { createEffect, createEvent, createStore } from 'effector';

import type {
  Credentials,
  GetInvoicesParams,
  GetInvoicesResponse,
  Invoice,
  Account,
  Carer
} from '@shared/api';

import { carersHQ } from '@shared/api';

import { InvoiceStatus } from '@shared/api';

const statuses = [
  InvoiceStatus.InProgress,
  InvoiceStatus.Paid,
  InvoiceStatus.Refunded,
  InvoiceStatus.Unpaid
];

const restoreAccountFx = createEffect<Credentials, void>();

const loginFx = createEffect<Credentials, string>();

const getInvoicesFx = createEffect<
  Pick<GetInvoicesParams, 'search' | 'status' | 'from'>,
  GetInvoicesResponse & Pick<GetInvoicesParams, 'search' | 'status' | 'from'>
>();

const getInvoiceFx = createEffect<Invoice['id'], Invoice>();

const restoreReset = createEvent();

const $restoreAccountSent = createStore(false).reset(restoreReset);

const $invoices = createStore<Invoice[]>([]);

const $invoice = createStore<Invoice>(null!);

const $total = createStore(0);

const $from = createStore(0);

const $search = createStore('');

const $status = createStore<InvoiceStatus>(null!);

const $$deletedAccount = {
  $restoreAccountSent,

  $invoices,

  $invoice,

  $total,

  $from,

  $status,

  $search,

  restoreAccountFx,

  loginFx,

  getInvoicesFx,

  getInvoiceFx
};

$restoreAccountSent
  .on(restoreAccountFx.doneData, () => true)
  .reset(restoreReset);

$invoice.on(getInvoiceFx.doneData, (_, payload) => payload);

$invoices.on(getInvoicesFx.doneData, (state, { from, invoices }) =>
  from == 0 ? invoices : [...state, ...invoices]
);

$total.on(getInvoicesFx.doneData, (_, payload) => payload.total);

$from.on(getInvoicesFx, (_, { from }) => (from == 0 ? 30 : from + 10));

$status.on(getInvoicesFx.doneData, (_, payload) => payload.status);

$search.on(getInvoicesFx.doneData, (_, payload) => payload.status);

restoreAccountFx.use(async payload => {
  await carersHQ.settings.restoreAccount(payload);
});

loginFx.use(async payload => {
  const {
    data: { invoiceAccessToken }
  } = await carersHQ.auth.loginAsDeleted(payload);

  return invoiceAccessToken;
});

getInvoiceFx.use(() => ({
  id: 'eb45e784-25f7-47cc-81d0-a0f2f314533f',
  startDate: '2022-02-01T09:24:15.559Z',
  endDate: '2022-02-08T09:24:15.559Z',
  status: statuses[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
  totalHours: 18,
  earnings: 447,
  carer: {
    id: '05b2fa45-8ae1-4f87-9f62-aebe642a7e60',
    firstName: 'Nazar',
    lastName: 'Porshe',
    imageUrl: 'https://i.pravatar.cc/300'
  },
  visits: [
    {
      id: 4,
      careType: 1,
      startDate: '2022-02-01T09:24:15.559Z',
      endDate: '2022-02-01T16:24:15.559Z',
      duration: 10,
      price: 5,
      holiday: false,
      totalPrice: 50
    } as any,
    {
      id: 5,
      careType: 1,
      startDate: '2022-02-02T08:30:15.559Z',
      endDate: '2022-02-02T17:30:15.559Z',
      duration: 10,
      price: 5,
      holiday: false,
      totalPrice: 50
    } as any,
    {
      id: 6,
      careType: 1,
      startDate: '2022-02-03T07:30:15.559Z',
      endDate: '2022-02-03T14:30:15.559Z',
      duration: 10,
      price: 5,
      holiday: false,
      totalPrice: 50
    } as any,
    {
      id: 7,
      careType: 1,
      startDate: '2022-02-03T07:30:15.559Z',
      endDate: '2022-02-03T14:30:15.559Z',
      duration: 10,
      price: 5,
      holiday: false,
      totalPrice: 50
    } as any,
    {
      id: 8,
      careType: 1,
      startDate: '2022-02-03T07:30:15.559Z',
      endDate: '2022-02-03T14:30:15.559Z',
      duration: 10,
      price: 5,
      holiday: false,
      totalPrice: 50
    } as any,

    {
      id: 61,
      careType: 1,
      startDate: '2022-02-03T07:30:15.559Z',
      endDate: '2022-02-03T14:30:15.559Z',
      duration: 10,
      price: 5,
      holiday: false,
      totalPrice: 50
    } as any,
    {
      id: 62,
      careType: 1,
      startDate: '2022-02-03T07:30:15.559Z',
      endDate: '2022-02-03T14:30:15.559Z',
      duration: 10,
      price: 5,
      holiday: false,
      totalPrice: 50
    } as any,
    {
      id: 63,
      careType: 1,
      startDate: '2022-02-03T07:30:15.559Z',
      endDate: '2022-02-03T14:30:15.559Z',
      duration: 10,
      price: 5,
      holiday: false,
      totalPrice: 50
    } as any,
    {
      id: 64,
      careType: 1,
      startDate: '2022-02-03T07:30:15.559Z',
      endDate: '2022-02-03T14:30:15.559Z',
      duration: 10,
      price: 5,
      holiday: false,
      totalPrice: 50
    } as any,
    {
      id: 65,
      careType: 1,
      startDate: '2022-02-03T07:30:15.559Z',
      endDate: '2022-02-03T14:30:15.559Z',
      duration: 10,
      price: 5,
      holiday: false,
      totalPrice: 50
    } as any,
    {
      id: 66,
      careType: 1,
      startDate: '2022-02-03T07:30:15.559Z',
      endDate: '2022-02-03T14:30:15.559Z',
      duration: 10,
      price: 5,
      holiday: false,
      totalPrice: 50
    } as any
  ],
  dayCare: 30,
  nightCare: 0,
  subTotal: 150,
  vat: 45,
  grandTotal: 195
}));

getInvoicesFx.use(({ from, search, status }) => ({
  total: 5000,
  invoices: new Array(from == 0 ? 30 : 10)
    .fill({} as Invoice)
    .map((_, index) => ({
      id: `630c7dc7-3479-4aaa-8713-f3227a1af595${Math.random()
        .toString()
        .replace('.', '')}${index}`,
      startDate: '2021-10-01T06:30:15.559Z',
      endDate: '2021-10-17T06:30:15.559Z',
      totalHours: 12,
      earnings: (Math.floor(Math.random() * (3 - 0 + 1)) + 1) * 100,
      account: {
        id: '5e24cbbe-24ef-4806-a0b6-018cf6f58dbb'
      } as Account,
      carer: {
        id: '05b2fa45-8ae1-4f87-9f62-aebe642a7e60',
        firstName: 'Nazar',
        lastName: 'Porshe',
        imageUrl: null
      } as Carer,

      status: statuses[Math.floor(Math.random() * (3 - 0 + 1)) + 0]
    })) as Invoice[],
  from,
  search,
  status
}));

export { $$deletedAccount };
