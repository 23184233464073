import type { ThemeOptions } from './types';

const colors: ThemeOptions = {
  yellowHovered: '#FFD266',
  yellowPrimary: '#FDDF98',
  yellow10: '#FFEFCA',
  yellow5: '#FFFAF0',
  peach: '#E5936B',
  peach30: '#FEE5C7',
  peach10: '#FEF0E1',
  peach5: '#FEF8F1',
  blue: '#154C95',
  blue30: '#C8D4F3',
  blue10: '#EEF2FB',
  blue5: '#F5F7FD',
  green: '#15955E',
  green30: '#B8DFCE',
  green10: '#EBF9F0',
  green5: '#F3F9F7',
  red: '#E90003',
  red30: '#FCC1C2',
  red10: '#FEE6E7',
  red5: '#FFF5F5',
  textBlack: '#0E1B44',
  textGrey: '#667992',
  textDisabled: '#BCC3CE',
  disabled: '#F6F6F7',
  divider: '#E5E6EA',
  spacing: 8
};

const theme = {
  colors
};

export { theme };
