import styles from './delete-success.module.scss';

const DeleteSuccess = () => (
  <p className={styles.text}>
    We sent an email notification to the user that he or she had been deleted
    from the account.
  </p>
);

export { DeleteSuccess };
