import type { Account } from './account';

import type { Client } from './client';

import type { ClientRelation } from './client-relation';

enum RelationId {
  Myself = 9
}

type Relation = {
  clientRelation: ClientRelation;

  client: Client;

  account: Account;
};

export { RelationId };

export type { Relation };
