import logo from '@icon/auth-logo.svg';

import type { RouteInstance } from 'atomic-router';

import { Link } from 'atomic-router-react';

import classNames from 'classnames';

import type { FC, PropsWithChildren, ReactNode } from 'react';

import styles from './auth.module.scss';

type HeaderProps = PropsWithChildren<{
  centered: boolean;
  logoTo: RouteInstance<any>;
}>;

type AuthFormPageProps = PropsWithChildren<{
  title: ReactNode;
}>;

const Header: FC<HeaderProps> = ({ children, centered, logoTo }) => (
  <div className={styles.header}>
    <div
      className={classNames(styles.headerContainer, {
        [styles.centered]: centered
      })}
    >
      <Link className={styles.logo} to={logoTo}>
        <img src={logo} />
      </Link>

      {children}
    </div>
  </div>
);

const Body: FC<PropsWithChildren<{}>> = ({ children }) => (
  <div className={styles.body}>{children}</div>
);

const Container: FC<PropsWithChildren<{}>> = ({ children }) => (
  <div className={styles.container}>{children}</div>
);

const FormPage: FC<AuthFormPageProps> = ({ children, title }) => (
  <div className={styles.formPageContainer}>
    <div className={styles.formPageBody}>
      <h1 className={styles.formPageTitle}>{title}</h1>

      <div className={styles.formPageFormContainer}>{children}</div>
    </div>
  </div>
);

const Auth = {
  Body,
  Header,
  FormPage,
  Container
};

export { Auth };
