import type { PropsWithChildren } from 'react';

import type { FC } from 'react';

import { Header } from './header/header';

import styles from './main-page.module.scss';

const Container: FC<PropsWithChildren<{}>> = ({ children }) => (
  <div className={styles.container}>{children}</div>
);

const Content: FC<PropsWithChildren<{}>> = ({ children }) => (
  <div className={styles.content}>
    <div className={styles.contentContainer}>{children}</div>
  </div>
);

const MainPage = {
  Header,
  Content,
  Container
};

export { MainPage };
