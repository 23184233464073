import classNames from 'classnames';

import React from 'react';

import { createView } from '@shared/lib/view';

import styles from './member-card-tile.module.scss';

import type { MemberCardTileProps } from './member-card-tile.props';

const MemberCardTile = createView<MemberCardTileProps>().view(
  ({ title, describe }) => (
    <div className={styles.tile}>
      <span className={styles.title}>{title}</span>

      <span
        className={classNames(styles.describe, {
          [styles.textOverflow]: typeof describe !== 'object'
        })}
      >
        {describe}
      </span>
    </div>
  )
);

export { MemberCardTile };
