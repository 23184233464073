class ScrollLock {
  public static body = document.querySelector('body');

  public static scrollPosition = 0;

  public static enable() {
    this.scrollPosition = window.pageYOffset;

    document.body.classList.add('overflow-hidden');

    this.body.style.position = 'fixed';

    this.body.style.top = `-${this.scrollPosition}px`;

    this.body.style.width = '100%';
  }

  public static disable() {
    document.body.classList.remove('overflow-hidden');

    this.body.style.removeProperty('position');

    this.body.style.removeProperty('top');

    this.body.style.removeProperty('width');

    window.scrollTo(0, this.scrollPosition);
  }
}

const ensureElementCreatedByID = (id: string, container = document.body) => {
  let element = container.querySelector(`#${id}`);

  if (!element) {
    element = document.createElement('div');

    element.id = id;

    container.appendChild(element);
  }

  return element;
};

export { ScrollLock, ensureElementCreatedByID };
