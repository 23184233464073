import axios from 'axios';

import {
  createEffect,
  createEvent,
  createStore,
  restore,
  sample
} from 'effector';

import { object, string } from 'yup';

import type { ForgotPasswordDto, Response } from '@shared/api';

import { carersHQ } from '@shared/api';

import { ToastID } from '@shared/config';

import { createForm } from '@shared/lib/form';

import { notify } from '@shared/ui/organisms/toasts';

type ResetPasswordOptions = {
  email: string;
};

const form = createForm<ForgotPasswordDto>({
  initialValues: {
    email: ''
  },

  $schema: createStore(
    object({
      email: string().required().nullable().email().trim().label('Email')
    })
  )
});

const reset = createEvent();

const resetPasswordFormSubmitted = createEvent<ForgotPasswordDto>();

const resetPasswordByEmailFx = createEffect<ResetPasswordOptions, string>();

const $resetPasswordError = createStore<string | null>('');

const $resetPasswordEmailSent = createStore(false);

const $email = restore(resetPasswordByEmailFx.doneData, '');

const $$resetUserPasswordByEmail = {
  $email,
  $resetPasswordError,
  $resetPasswordEmailSent,

  form,
  reset,

  resetPasswordByEmailFx
};

sample({
  clock: form.submitted,

  target: resetPasswordByEmailFx
});

sample({
  clock: reset,

  target: form.reset
});

notify({
  clock: resetPasswordByEmailFx.failData,

  options: {
    id: ToastID.InvalidForgotPasswordCredentials,
    type: 'error',
    title: 'Sorry, we couldn’t find an account',
    content: 'Please check it and try again.',
    maxWidth: 414
  }
});

$resetPasswordError
  .on(resetPasswordByEmailFx.failData, (_, error) => {
    if (axios.isAxiosError(error)) {
      const response = error.response.data as Response;

      return response.message;
    }

    return 'Something went wrong';
  })

  .reset(reset);

$resetPasswordEmailSent
  .on(resetPasswordByEmailFx.doneData, () => true)

  .reset(reset);

$email.reset(reset);

resetPasswordByEmailFx.use(async payload => {
  await carersHQ.auth.forgotPassword(payload);

  return payload.email;
});

export { $$resetUserPasswordByEmail, resetPasswordFormSubmitted, form };
