import { Auth } from '@layouts/auth';

import { Route } from 'atomic-router-react';

import { combine } from 'effector';

import { some } from 'patronum';

import type { PropsWithChildren } from 'react';

import { connect, createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { Anchor } from '@shared/ui/atoms/anchor';

import styles from './auth.module.scss';

import { InvitePage } from './pages/invite';

import { ResetPasswordPage } from './pages/reset-password';

import { PasswordPage } from './pages/set-password';

import { SignInPage } from './pages/sign-in';

const SignInProposal = () => (
  <div className={styles.registered}>
    <span className={styles.registeredMessage}>Do you have an account? </span>

    <Anchor className={styles.registeredLink} to={routes.auth.signIn}>
      Sign In
    </Anchor>
  </div>
);

const Header = connect(Auth.Header)
  .static({
    logoTo: routes.auth.signIn
  })

  .units({
    centered: some({
      predicate: Boolean,

      stores: [routes.auth.signIn.$isOpened, routes.auth.recovery.$isOpened]
    })
  })

  .view();

/**
 * <Auth />
 */
const AuthPage = createView<PropsWithChildren>()
  .units({
    // rework
    signInProposalVisible: combine(
      routes.auth.signIn.$isOpened.map(state => !state),

      routes.auth.recovery.$isOpened.map(state => !state),

      (...args) => args.every(Boolean)
    )
  })

  .view(({ signInProposalVisible, children }) => (
    <Auth.Container>
      <Header>{signInProposalVisible && <SignInProposal />}</Header>

      <Auth.Body>
        <Route route={routes.auth.signIn} view={SignInPage} />

        <Route route={routes.auth.password} view={PasswordPage} />

        <Route route={routes.auth.recovery} view={ResetPasswordPage} />

        <Route route={routes.auth.signUpInvite} view={InvitePage} />

        {children}
      </Auth.Body>
    </Auth.Container>
  ));

export { AuthPage };
