import styles from '@pages/deleted-account/pages/invoice-login/invoice-login.module.scss';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { DeletedStateInfo } from '@shared/ui/atoms/deleted-state-info';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { $$invoiceLogin } from '../model';

const InvoiceLoginForm = createView().view(() => (
  <DeletedStateInfo
    className={styles.card}
    classNames={{
      subTitle: styles.subTitle
    }}
    photo=''
    title='Access to invoices'
    subTitle={
      <p className={styles.loginSubTitle}>
        To access your invoices, enter email and account password. If you are
        having difficulties, please{' '}
        <a
          target='_blank'
          href='https://google.com'
          rel='noreferrer'
          className={styles.link}
        >
          contact us
        </a>
        .
      </p>
    }
  >
    <Form use={$$invoiceLogin.form}>
      <div className={styles.email}>
        <Field.Input
          use={$$invoiceLogin.form.fields.email}
          name='email'
          label='Email'
          errorIn='label'
          placeholder='Enter your email'
        />
      </div>

      <div className={styles.password}>
        <Field.Input
          use={$$invoiceLogin.form.fields.password}
          name='password'
          label='Password'
          type='password'
          errorIn='label'
          placeholder='Enter your password'
        />
      </div>

      <Button
        className={styles.continue}
        type='submit'
        onClick={() => $$invoiceLogin.form.submit()}
      >
        Continue
      </Button>
    </Form>
  </DeletedStateInfo>
));

export { InvoiceLoginForm };
