import { createEvent, sample } from 'effector';

import { $$resetUserPasswordByEmail } from '@features/user/password/reset-by-email';

import { routes } from '@shared/routes';

const backToLoginClicked = createEvent();

const $$resetPasswordPage = {
  backToLoginClicked
};

sample({
  clock: backToLoginClicked,

  target: routes.auth.signIn.open.prepend(() => ({}))
});

sample({
  clock: routes.auth.recovery.closed,

  target: $$resetUserPasswordByEmail.reset
});

export { $$resetPasswordPage };
