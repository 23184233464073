import type { AccountWorkspace } from './account-workspace';

import type { Carer } from './carer';

import type { Client } from './client';

import type { RefreshToken } from './refresh-token';

import type { Relation } from './relation';

enum AccountStatus {
  Active = 'active',
  Deleted = 'deleted',
  Blocked = 'blocked'
}

type Account = {
  id?: string;

  firstName?: string;

  lastName?: string;

  email?: string;

  emailConfirmed?: boolean;

  password?: string;

  phone?: string;

  billingId?: string;

  billingActive?: boolean;

  deleteReason?: string;

  carer?: Carer;

  refreshTokens?: RefreshToken[];

  accountWorkspaces?: AccountWorkspace[];

  relations?: Relation[];

  // mostlikely dynamic field
  myself?: Client;

  status: AccountStatus;
};

type Credentials = {
  email: string;
  password: string;
};

export type { Account, Credentials };

export { AccountStatus };
