import moment from 'moment';

import { useMemo } from 'react';

import { $$profilePage } from '@pages/profile/profile.model';

import {
  $$carerQualifications,
  QualificationsForm
} from '@features/carer/profile/qualifications';

import { $$carerProfile } from '@entities/carer/model';

import { months } from '@shared/lib/utils';

import { createView } from '@shared/lib/view';

import { EditableSection } from '@shared/ui/atoms/editable-section';

import { useMedia } from '@shared/ui/atoms/media';

import { TextPair } from '@shared/ui/atoms/text-pair';

import styles from './qualifications.module.scss';

/**
 * <Qualifications />
 */
const Qualifications = createView()
  .units({
    carer: $$carerProfile.$carerProfile,

    editing: $$profilePage.qualifications.$opened,

    onSave: $$carerQualifications.form.submit,

    onCancel: $$profilePage.qualifications.close,

    onEdit: $$profilePage.qualifications.open
  })

  .map(({ carer }) => {
    const md = useMedia('>=md');

    const startDate = moment(carer?.carerStart);

    const experience = useMemo(() => {
      const formatForPlural = (num: number, str: string) =>
        num > 1 ? `(${num} ${str}s)` : `(${num} ${str})`;

      const months = Math.abs(startDate.diff(moment(), 'months'));
      const years = Math.abs(startDate.diff(moment(), 'years'));

      if (years > 0) {
        return formatForPlural(years, 'year');
      }

      return formatForPlural(months, 'month');
    }, [startDate]);

    return {
      md,
      startDate,
      experience
    };
  })

  .view(
    ({
      carer,
      md,
      experience,
      startDate,
      onEdit,
      onCancel,
      onSave,
      editing
    }) => (
      <EditableSection
        title='Qualifications'
        editing={editing}
        onEdit={onEdit}
        onCancel={onCancel}
        onSave={onSave}
      >
        {({ editing }) =>
          editing ? (
            <QualificationsForm />
          ) : (
            <div className={styles.view}>
              <TextPair
                className={styles.start}
                label='Since when have you been a professional carer:'
                spacing={md ? 'normal' : 'multiline'}
                value={
                  <>
                    {months[startDate.month()]}, {startDate.year()}{' '}
                    <span className={styles.years}>{experience}</span>
                  </>
                }
              />

              {carer?.qualifications?.length > 0 && (
                <TextPair
                  className={styles.qualifications}
                  spacing='multiline'
                  label='Care qualifications:'
                  value={
                    <ul className={styles.qualificationsList}>
                      {carer?.qualifications?.map(item => (
                        <li key={item.id}>- {item.name}</li>
                      ))}
                    </ul>
                  }
                />
              )}

              {carer?.certificates?.length > 0 && (
                <TextPair
                  className={styles.certificates}
                  spacing='multiline'
                  label='Other certificates:'
                  value={
                    <ul className={styles.certificatesList}>
                      {carer?.certificates?.map(item => (
                        <li key={item.id}>- {item.name}</li>
                      ))}
                    </ul>
                  }
                />
              )}
            </div>
          )
        }
      </EditableSection>
    )
  );

export { Qualifications };
