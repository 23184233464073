import type { CarerGender } from './carer-gender';

import type { ConditionsType } from './conditions-type';

import type { ContactPerson } from './contact-person';

import type { Gender } from './gender';

import type { Language } from './language';

import type { Pet } from './pet';

import type { Service } from './service';

import type { SupportType } from './support-type';

import type { TransportationHelp } from './transportation-help';

type CarerRelationType = 'team' | 'connections' | 'shortlist';

enum VisitCareType {
  VisitingCare = 0,
  LiveInCare = 1
}

enum CareType {
  DayCare = 1,
  NightCare = 2
}

enum StartingFromType {
  SpecificDate = 0,
  Immediately = 1,
  NotSure = 2
}

enum NightCareType {
  Sleeping = 0,
  Waking = 1
}

const visitCareTypeNames = {
  [VisitCareType.LiveInCare]: 'Live-in care',
  [VisitCareType.VisitingCare]: 'Visiting care'
};

const nightCareTypeNames = {
  [NightCareType.Sleeping]: 'Sleeping night care',
  [NightCareType.Waking]: 'Waking night care'
};

const careTypeNames = {
  [CareType.DayCare]: 'Day care',
  [CareType.NightCare]: 'Night care'
};

type Client = {
  id?: string;

  postCode?: string;

  city?: string;

  addressLine1?: string;

  addressLine2?: string;

  phone?: string;

  careStartDate?: Date;

  careEndDate?: Date;

  carerGender?: CarerGender;

  transportationHelp?: TransportationHelp;

  activityPerWeek?: string;

  smoking?: boolean;

  pets?: Pet[];

  firstName?: string;

  lastName?: string;

  gender?: Gender;

  // changed
  dateOfBirth?: string;

  createdAt?: Date;

  // TODO: check after BE
  visitCareType?: VisitCareType;

  careTypes?: CareType[];

  startingFromType?: StartingFromType;

  nightCareTypes?: NightCareType[];

  startingFromDate?: string;

  supportTypes?: SupportType[];

  conditionsTypes?: ConditionsType[];

  languages?: Language[];

  district?: string;

  services?: Service[];

  imageUrl?: string;

  additionalDetails?: string;

  contactPerson: ContactPerson[];

  isDeleted?: boolean;

  hoursPerWeek?: string;

  // TODO: fix
  lastStep?: any;
};

type ClientProfileView = Pick<
  Client,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'gender'
  | 'dateOfBirth'
  | 'addressLine1'
  | 'addressLine2'
  | 'postCode'
  | 'district'
  | 'conditionsTypes'
  | 'supportTypes'
  | 'visitCareType'
  | 'nightCareTypes'
  | 'additionalDetails'
  | 'imageUrl'
  | 'languages'
  | 'carerGender'
  | 'careTypes'
  | 'transportationHelp'
  | 'smoking'
  | 'pets'
  | 'contactPerson'
  | 'city'
  | 'services'
> & {
  age?: number;
  isFullDataView?: boolean;
};

export {
  CareType,
  VisitCareType,
  StartingFromType,
  NightCareType,
  careTypeNames,
  visitCareTypeNames,
  nightCareTypeNames
};

export type { Client, ClientProfileView, CarerRelationType };
