import { sample } from 'effector';

import { $$signUpLovedOne } from '@pages/sign-up/pages/loved-one';

import { routes } from '@shared/routes';

import { $lovedOneValues } from './shared';

const { submitted } = $$signUpLovedOne.steps.careNeeds.form;

$lovedOneValues.on(submitted, (state, values) => ({
  ...state,

  careNeeds: values
}));

sample({
  clock: submitted,

  source: routes.signUp.lovedOne.careNeeds.$params,

  target: routes.signUp.lovedOne.conditionExperience.open
});

sample({
  clock: routes.signUp.lovedOne.careNeeds.opened,

  source: $lovedOneValues,

  fn: values => values.careNeeds ?? null,

  target: $$signUpLovedOne.steps.careNeeds.form.reset
});
