import { attach, createEvent, createStore, createEffect } from 'effector';

import type { Account, Client } from '@shared/api';

import { carersHQ } from '@shared/api';

const fetchDraftsFx = createEffect<Account['id'], Client[]>();

const getDraftFx = createEffect<Client['id'], Client>();

const cleanupDraftFx = attach({
  effect: carersHQ.auth.getCareRecipientDraft,

  mapParams: (draftId: Client['id']) => ({ draftId })
});

const saveDraftFx = attach({
  effect: carersHQ.auth.saveCareRecipientAsDraft
});

const deleteDraftFx = createEffect<Client['id'], void>();

const saveAsDraftClicked = createEvent();

const editDraftClicked = createEvent<Client['id']>();

const $drafts = createStore<Client[]>([]);

const $draft = createStore<Client>(null!);

const $$drafts = {
  $drafts,

  $draft,

  editDraftClicked,

  saveAsDraftClicked,

  fetchDraftsFx,

  cleanupDraftFx,

  getDraftFx,

  deleteDraftFx,

  saveDraftFx
};

$drafts.on(fetchDraftsFx.doneData, (_, payload) => payload);

$draft.on(getDraftFx.doneData, (_, draft) => draft).reset(deleteDraftFx.done);

fetchDraftsFx.use(async id => {
  const { data } = await carersHQ.auth.getCareRecipientDrafts({ id });

  return data;
});

getDraftFx.use(async draftId => {
  const { data } = await carersHQ.auth.getCareRecipientDraft({ draftId });

  return data;
});

deleteDraftFx.use(async draftId => {
  await carersHQ.auth.deleteCareRecipientDraft({ draftId });
});
export { $$drafts };
