import { createView } from '@shared/lib/view';

import { Anchor } from '@shared/ui/atoms/anchor';

import { Button } from '@shared/ui/atoms/button';

import { Card } from '@shared/ui/atoms/card';

import { Fieldset } from '@shared/ui/atoms/fieldset';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { $$signInByEmail } from '../model';

import styles from './sign-in.module.scss';

import type { SignInProps } from './sign-in.props';

const SignInForm = createView<SignInProps>()
  .close($$signInByEmail.reset.prepend(() => {}))

  .view(({ onBecomeCarerClick, onFindCarerClick, recoveryRoute }) => (
    <Card className={styles.card}>
      <Form className={styles.form} use={$$signInByEmail.signInForm}>
        <Fieldset>
          <Field.Input
            use={$$signInByEmail.signInForm.fields.email}
            type='email'
            disableAutocomplete
            label='Email'
            errorIn='label'
            placeholder='Enter your email'
          />

          <Field.Input
            use={$$signInByEmail.signInForm.fields.password}
            errorIn='label'
            type='password'
            disableAutocomplete
            label='Password'
            placeholder='Enter your password'
            helper={
              <Anchor className={styles.forgot} to={recoveryRoute}>
                Forgot password?
              </Anchor>
            }
          />
        </Fieldset>

        <Button className={styles.submit} type='submit'>
          Login
        </Button>

        <Field.Checkbox
          use={$$signInByEmail.signInForm.fields.rememberMe}
          className={styles.rememberMe}
          label='Keep me logged in'
        />
      </Form>

      <div className={styles.footer}>
        <h4 className={styles.footerTitle}>Don’t have an acсount?</h4>

        <div className={styles.footerActions}>
          <Button onClick={() => onFindCarerClick()} palette='secondary'>
            Find care today
          </Button>

          <Button onClick={() => onBecomeCarerClick()} palette='secondary'>
            Become a carer
          </Button>
        </div>
      </div>
    </Card>
  ));

export { SignInForm };
