import { InvoiceLoginForm } from '@features/deleted-account/invoice-login-form/ui';

import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { Breadcrumbs } from '@shared/ui/atoms/breadcrumbs';

import styles from './invoice-login.module.scss';

const InvoiceLogin = createView().view(() => (
  <div>
    <Breadcrumbs
      className={styles.breadcrumbs}
      steps={[
        { label: 'Account', route: routes.deletedAccount.home },
        { label: 'Invoices', route: routes.deletedAccount.invoices }
      ]}
    />

    <InvoiceLoginForm />
  </div>
));

export { InvoiceLogin };
