import { createEvent, createStore, createEffect, sample } from 'effector';

import { object, string } from 'yup';

import { $$checkUserCredentials } from '@features/user/credentials/check';

import { $$careSpace } from '@entities/care-space';

import { $$members } from '@entities/members';

import type {
  TransferWorkspaceOwnershipDTO,
  TransferWorkspaceOwnershipInviteDTO
} from '@shared/api';

import { carersHQ } from '@shared/api';

import { createForm } from '@shared/lib/form';

enum ModalSteps {
  SelectMember = 'select-member',
  Credentials = 'credential',
  Attention = 'attention',
  Done = 'done'
}

enum MemberType {
  Select = 0,
  New = 1
}

const $errors = createStore({});

const credentialForm = createForm({
  initialValues: {
    email: '',

    password: ''
  },

  $errors,

  $schema: createStore(
    object({
      email: string()
        .required('required')

        .trim()

        .nullable()

        .email('is invalid')

        .label('Email'),

      password: string()
        .required('required')

        .trim()

        .nullable()

        .label('Password')
    })
  )
});

const selectMemberForm = createForm({
  initialValues: {
    email: ''
  },

  $schema: createStore(
    object({
      email: string()
        .trim()

        .nullable()

        .required('required')

        .email('is invalid')
    })
  )
});

const transferWorkspaceOwnershipFx = createEffect<
  TransferWorkspaceOwnershipDTO,
  void
>();

const transferWorkspaceOwnershipInviteFx = createEffect<
  TransferWorkspaceOwnershipInviteDTO,
  void
>();

const modalOpened = createEvent();

const modalClosed = createEvent();

const submit = createEvent();

const stepChanged = createEvent<ModalSteps>();

const changeMemberType = createEvent<MemberType>();

const $step = createStore<ModalSteps>(ModalSteps.SelectMember);

const $visible = createStore(false);

const $memberType = createStore<MemberType>(MemberType.Select);

const $selectedMember = createStore<string>(null);

const $credentialInfo = createStore<{ email: string; password: string }>(null);

const $error = createStore(false);

const $$transferOwnerShip = {
  credentialForm,

  selectMemberForm,

  $credentialInfo,

  $memberType,

  $selectedMember,

  $step,

  $visible,

  modalOpened,

  submit,

  modalClosed,

  stepChanged,

  changeMemberType
};

sample({
  clock: selectMemberForm.submitted,

  fn: () => ModalSteps.Credentials,

  target: $$transferOwnerShip.stepChanged
});

sample({
  clock: $$checkUserCredentials.checkCredentialsFx.doneData,

  source: $visible,

  filter: visible => visible,

  fn: () => ModalSteps.Attention,

  target: $$transferOwnerShip.stepChanged
});

sample({
  clock: submit,

  source: {
    info: $credentialInfo,
    newOwnerEmail: $selectedMember,
    members: $$members.$members,
    workspaceId: $$careSpace.$careSpaceID
  },

  filter: ({ newOwnerEmail, members }) => {
    const userId = members.find(
      ({ account: { email } }) => email === newOwnerEmail
    )?.account?.id;

    return Boolean(!userId);
  },

  fn: ({ info: { email, password }, newOwnerEmail, workspaceId }) => ({
    email,
    password,
    newUser: newOwnerEmail,
    workspaceId
  }),

  target: transferWorkspaceOwnershipInviteFx
});

sample({
  clock: submit,

  source: {
    info: $credentialInfo,
    newOwnerEmail: $selectedMember,
    members: $$members.$members,
    workspaceId: $$careSpace.$careSpaceID
  },

  filter: ({ newOwnerEmail, members }) => {
    const userId = members.find(
      ({ account: { email } }) => email === newOwnerEmail
    )?.account?.id;

    return Boolean(userId);
  },

  fn: ({ info: { email, password }, newOwnerEmail, workspaceId, members }) => {
    const userId = members.find(
      ({ account: { email } }) => email === newOwnerEmail
    )?.account?.id;

    return {
      email,
      password,
      userId,
      workspaceId
    };
  },

  target: transferWorkspaceOwnershipFx
});

sample({
  clock: [
    transferWorkspaceOwnershipFx.doneData,
    transferWorkspaceOwnershipInviteFx.doneData
  ],

  fn: () => ModalSteps.Done,

  target: stepChanged
});

sample({
  clock: credentialForm.submitted,

  fn: ({ password, email }) => ({ password, email }),

  target: [$$checkUserCredentials.checkCredentialsFx, $credentialInfo]
});

sample({
  clock: $$checkUserCredentials.checkCredentialsFx.doneData,

  source: $visible,

  filter: visible => visible,

  fn: () => ({
    email: '',

    password: ''
  }),

  target: credentialForm.patch
});

sample({
  clock: selectMemberForm.submitted,

  fn: ({ email }) => email,

  target: $selectedMember
});

sample({
  clock: modalClosed,

  target: [selectMemberForm.reset, credentialForm.reset]
});

sample({
  source: $error,

  fn: error =>
    error
      ? {
          email: 'is incorrect',
          password: 'is incorrect'
        }
      : {},

  target: $errors
});

$error
  .on($$checkUserCredentials.checkCredentialsFx.failData, () => true)

  .reset(
    $$checkUserCredentials.checkCredentialsFx.doneData,

    modalClosed
  );

$memberType.on(changeMemberType, (_state, payload) => payload);

$step.on(stepChanged, (_state, payload) => payload).reset(modalClosed);

$visible.on(modalOpened, () => true).reset(modalClosed);

transferWorkspaceOwnershipInviteFx.use(
  async ({ email, newUser, password, workspaceId }) => {
    await carersHQ.settings.transferWorkspaceOwnershipInvite({
      email,
      password,
      workspaceId,
      newUser
    });
  }
);

transferWorkspaceOwnershipFx.use(
  async ({ email, password, userId, workspaceId }) => {
    await carersHQ.settings.transferWorkspaceOwnership({
      email,
      password,
      workspaceId,
      userId
    });
  }
);

export { $$transferOwnerShip, ModalSteps, MemberType };
