import logo from '@icon/auth-logo.svg';

import { Link, Route } from 'atomic-router-react';

import { Home } from '@pages/deleted-account/pages/home';

import { InvoiceLogin } from '@pages/deleted-account/pages/invoice-login';

import { Invoices } from '@pages/deleted-account/pages/invoices';

import { PersonInvoice } from '@pages/deleted-account/pages/person-invoice';

import { Restore } from '@pages/deleted-account/pages/restore';

import { $$logoutUser } from '@features/user/logout';

import { $$session } from '@entities/session';

import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { Anchor } from '@shared/ui/atoms/anchor';

import styles from './deleted-account.module.scss';

import './deleted-account.model';

/**
 * <DeletedAccount />
 */
const DeletedAccount = createView()
  .units({
    logged: $$session.$active,

    onLogoutClick: $$logoutUser.logout
  })
  .view(({ onLogoutClick, logged }) => (
    <div className={styles.deletedAccount}>
      <header className={styles.header}>
        <div className={styles.headerContainer}>
          <Link className={styles.logo} to={routes.deletedAccount.home}>
            <img src={logo} />
          </Link>

          <Anchor
            as='div'
            className={styles.signOut}
            onClick={() => onLogoutClick()}
          >
            Log out
          </Anchor>
        </div>
      </header>

      <div className={styles.container}>
        <Route route={routes.deletedAccount.restore} view={Restore} />

        <Route
          route={routes.deletedAccount.personInvoice}
          view={PersonInvoice}
        />

        <Route
          route={routes.deletedAccount.invoices}
          view={logged ? Invoices : InvoiceLogin}
        />

        <Route route={routes.deletedAccount.home} view={Home} />
      </div>
    </div>
  ));

export { DeletedAccount };
