import camelcase from 'camelcase';

import classNames from 'classnames';

import type { FC } from 'react';

import { createElement } from 'react';

import styles from './text.module.scss';

import type { TextProps } from './text.props';

/**
 * <Text />
 */
const Text: FC<TextProps> = ({ variant, tag, ...props }) =>
  createElement(tag as string, {
    ...props,
    className: classNames(props.className, styles[camelcase(variant)])
  });

Text.defaultProps = {
  tag: 'div'
};

export { Text };
