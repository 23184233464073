import { Route } from 'atomic-router-react';

import { combine } from 'effector';

import { Contacts } from '@pages/care-recipients/pages/contacts';

import { MedicationPage } from '@pages/care-recipients/pages/medication';

import { Profile } from '@pages/care-recipients/pages/profile/ui';

import { WellnessPage } from '@pages/care-recipients/pages/wellness';

import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { Breadcrumbs } from '@shared/ui/atoms/breadcrumbs';

import { $$careRecipientsPage } from './care-recipients.model';

import styles from './care-recipients.module.scss';

import { Persons } from './ui/persons';

const CareRecipients = createView()
  .units({
    id: $$careRecipientsPage.$id,

    tab: combine(
      routes.careRecipients.general.$isOpened,

      routes.careRecipients.medication.$isOpened,

      routes.careRecipients.wellness.$isOpened,

      routes.careRecipients.contacts.$isOpened,

      (profile, medication, wellness, contacts) => {
        if (profile) return 'profile';

        if (medication) return 'medication';

        if (wellness) return 'wellness';

        if (contacts) return 'contacts';

        return 'profile';
      }
    ),

    tabChanged: $$careRecipientsPage.tabChanged
  })

  .view(({ id, tabChanged, tab }) => (
    <div className={styles.profile}>
      <Breadcrumbs
        className={styles.crumbs}
        steps={[
          {
            label: 'Care Recipients',
            route: routes.careRecipients.general,
            params: { id }
          }
        ]}
      />

      <div className={styles.content}>
        <Persons />

        <div className={styles.card}>
          <Route
            route={routes.careRecipients.general}
            view={() => <Profile tab={tab} tabChanged={tabChanged} />}
          />

          <Route
            route={routes.careRecipients.medication}
            view={() => <MedicationPage tab={tab} tabChanged={tabChanged} />}
          />

          <Route
            route={routes.careRecipients.wellness}
            view={() => <WellnessPage tab={tab} tabChanged={tabChanged} />}
          />

          <Route
            route={routes.careRecipients.contacts}
            view={() => <Contacts tab={tab} tabChanged={tabChanged} />}
          />
        </div>
      </div>
    </div>
  ));

export { CareRecipients };
