import { $$deleteAccount } from '@features/care-recipient/delete-account/model';

import { createView } from '@shared/lib/view';

import { Confirm } from './confirm';

import { Password } from './password';

import { Reason } from './reason';

const DeleteAccount = createView()
  .units({
    step: $$deleteAccount.$step
  })

  .view(({ step }) => (
    <>
      {step == 'confirm' && <Confirm />}

      {step == 'password' && <Password />}

      {step == 'reason' && <Reason />}
    </>
  ));

export { DeleteAccount };
