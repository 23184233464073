import { createRoute, chainRoute } from 'atomic-router';

import { attach, sample } from 'effector';

import { not } from 'patronum';

import { $$signUpLovedOne } from '@pages/sign-up/pages/loved-one';

import { $$session } from '@entities/session';

import type { SignUpStepParams } from '@shared/routes/routes';

import { routes } from '@shared/routes/routes';

import { $lovedOneValues } from './shared';

// createLovedOneStep({
//   key: 'careRecipient',

//   form: $$signUpLovedOne.steps.careRecipient.form,

//   route: routes.signUp.lovedOne.careRecipient,

//   nextRoute: routes.signUp.lovedOne.accountHolder,

//   mapValues: (values, { postcode }) =>
//     values.careRecipient ?? {
//       ...$$signUpLovedOne.steps.careRecipient.form.initialValues,

//       postCode: decodeURIComponent(postcode)
//     },

//   processValues: values => values,

//   checkValues: values => Boolean(values.preferences)
// });

const chainedRoute = createRoute<SignUpStepParams>();

const { submitted, reset } = $$signUpLovedOne.steps.careRecipient.form;

const beforeOpenFx = attach({
  source: $lovedOneValues,

  effect: (values, { postcode }: SignUpStepParams) => {
    if (!values.preferences) throw 'Not Allowed';

    return (
      values.careRecipient ?? {
        ...$$signUpLovedOne.steps.careRecipient.form.initialValues,

        postCode: decodeURIComponent(postcode)
      }
    );
  }
});

$lovedOneValues.on(submitted, (state, values) => ({
  ...state,

  careRecipient: values
}));

chainRoute({
  route: routes.signUp.lovedOne.careRecipient,

  beforeOpen: {
    effect: beforeOpenFx,

    mapParams: ({ params }) => params
  },

  cancelOn: beforeOpenFx.fail,

  openOn: beforeOpenFx.done,

  chainedRoute
});

sample({
  clock: beforeOpenFx.doneData,

  fn: values => values ?? null,

  target: reset
});

sample({
  clock: beforeOpenFx.failData,

  source: routes.signUp.lovedOne.careRecipient.$params,

  target: routes.signUp.lovedOne.careNeeds.open
});

sample({
  clock: submitted,

  source: routes.signUp.lovedOne.careRecipient.$params,

  filter: not($$session.$active),

  target: routes.signUp.lovedOne.accountHolder.open
});
