import { $theme } from '@shared/ui/theme';

import type { ButtonPalette } from './button.props';

const $palettes = $theme.map(theme => ({
  primary: {
    fill: {
      normal: theme.yellowPrimary,
      hovered: theme.yellowHovered,
      pressed: theme.yellow10
    },

    text: {
      normal: theme.blue,
      hovered: theme.blue,
      pressed: theme.blue
    },

    border: {
      normal: theme.yellowPrimary,
      hovered: theme.yellowHovered,
      pressed: theme.yellow10
    }
  } as ButtonPalette,

  secondary: {
    fill: {
      normal: 'transparent',
      hovered: theme.blue10,
      pressed: theme.blue5
    },

    text: {
      normal: theme.blue,
      hovered: theme.blue,
      pressed: theme.blue
    },

    border: {
      normal: theme.blue30,
      hovered: theme.blue30,
      pressed: theme.blue10
    }
  } as ButtonPalette,

  teritary: {
    shadow: '0 4px 8px 0 rgba(6,13,39,0.04)',

    fill: {
      normal: '#ffffff',
      hovered: theme.blue10,
      pressed: theme.blue5
    },

    text: {
      normal: theme.blue,
      hovered: theme.blue,
      pressed: theme.blue
    },

    border: {
      normal: theme.blue30,
      hovered: theme.blue30,
      pressed: theme.blue10
    }
  } as ButtonPalette,

  text: {
    fill: {
      normal: 'transparent',
      hovered: 'transparent',
      pressed: 'transparent'
    },

    text: {
      normal: theme.blue,
      hovered: theme.yellowHovered,
      pressed: theme.yellowPrimary
    },

    border: {
      normal: 'transparent',
      hovered: 'transparent',
      pressed: 'transparent'
    }
  } as ButtonPalette,

  error: {
    fill: {
      normal: 'transparent',
      hovered: theme.red10,
      pressed: theme.red5
    },

    text: {
      normal: theme.red,
      hovered: theme.red,
      pressed: theme.red
    },

    border: {
      normal: theme.red30,
      hovered: theme.red30,
      pressed: theme.red10
    }
  } as ButtonPalette
}));

export { $palettes };
