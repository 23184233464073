import { chainRoute, createRoute } from 'atomic-router';

import { attach, sample } from 'effector';

import { not } from 'patronum';

import { $$signUpMyself } from '@pages/sign-up/pages/myself';

import { $$session } from '@entities/session';

import type { SignUpStepParams } from '@shared/routes/routes';

import { routes } from '@shared/routes/routes';

import { $myselfValues } from './shared';

const chainedRoute = createRoute<SignUpStepParams>();

const { submitted } = $$signUpMyself.steps.contactDetails.form;

const beforeOpenFx = attach({
  source: $myselfValues,

  effect: (values, params: SignUpStepParams) => {
    if (!values.personalInformation) throw 'Not Allowed';

    return (
      values.contactDetails ?? {
        ...$$signUpMyself.steps.contactDetails.form.initialValues,

        postCode: decodeURIComponent(params.postcode)
      }
    );
  }
});

$myselfValues.on(submitted, (state, values) => ({
  ...state,

  contactDetails: values
}));

chainRoute({
  route: routes.signUp.myself.contactDetails,

  beforeOpen: {
    effect: beforeOpenFx,

    mapParams: ({ params }) => params
  },

  cancelOn: beforeOpenFx.fail,

  openOn: beforeOpenFx.done,

  chainedRoute
});

sample({
  clock: beforeOpenFx.doneData,

  fn: values => values ?? null,

  target: $$signUpMyself.steps.contactDetails.form.reset
});

sample({
  clock: beforeOpenFx.failData,

  source: routes.signUp.myself.contactDetails.$params,

  target: routes.signUp.myself.careNeeds.open
});

sample({
  clock: submitted,

  source: routes.signUp.myself.contactDetails.$params,

  filter: not($$session.$active),

  target: routes.signUp.myself.createAccount.open
});
