import type { RouteInstance } from 'atomic-router';

import { redirect } from 'atomic-router';

import { sample } from 'effector';

import type { Model } from 'effector-factorio';

import { $$signUpCarer } from '@pages/sign-up/pages/carer';

import type { StepWizardModelFactory } from '@pages/sign-up/ui/sign-up-wizard-step';

import { routes } from '@shared/routes';

const steps: [RouteInstance<any>, Model<typeof StepWizardModelFactory>][] = [
  [
    routes.signUp.carer.personalInformation,

    $$signUpCarer.wizards.personalInformation
  ],

  [routes.signUp.carer.contactDetails, $$signUpCarer.wizards.contactDetails],

  [routes.signUp.carer.services, $$signUpCarer.wizards.services],

  [routes.signUp.carer.qualifications, $$signUpCarer.wizards.qualifications],

  [
    routes.signUp.carer.additionalInformation,
    $$signUpCarer.wizards.additionalInformation
  ],

  [
    routes.signUp.carer.employmentDetails,
    $$signUpCarer.wizards.employmentDetails
  ]
];

steps.forEach(([route, wizard], index, list) => {
  const previous = list[index - 1];

  if (!previous) return;

  sample({
    clock: wizard.backClicked,

    source: route.$params,

    target: previous[0].open
  });
});

redirect({
  clock: $$signUpCarer.$$createAccountWizard.backClicked,

  params: routes.signUp.carer.createAccount.$params,

  route: routes.signUp.carer.employmentDetails
});
