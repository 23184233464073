import { createEffect, restore } from 'effector';

import type {
  CarerGender,
  CareType,
  Certificate,
  ConditionsType,
  Dbs,
  Entry,
  Gender,
  Language,
  Pet,
  Qualification,
  Role,
  SupportType,
  TransportationHelp,
  WorkingDocument
} from '@shared/api';

import { carersHQ } from '@shared/api';

import type { Service } from '@shared/api/models/service';

const getRelationshipsFx = createEffect<void, Entry[]>();

const getLanguagesFx = createEffect<void, Language[]>();

const getTransportationHelpTypesFx = createEffect<void, TransportationHelp[]>();

const getPetsFx = createEffect<void, Pet[]>();

const getGendersFx = createEffect<void, Gender[]>();

const getCareTypesFx = createEffect<void, CareType[]>();

const getCertificatesFx = createEffect<void, Certificate[]>();

const getConditionTypesFx = createEffect<void, ConditionsType[]>();

const getDbsFx = createEffect<void, Dbs[]>();

const getCarerGendersFx = createEffect<void, CarerGender[]>();

const getQualificationsFx = createEffect<void, Qualification[]>();

const getRolesFx = createEffect<void, Role[]>();

const getSupportTypesFx = createEffect<void, SupportType[]>();

const getWorkingDocumentsFx = createEffect<void, WorkingDocument[]>();

const getServicesFx = createEffect<void, Service[]>();

const getMemberLeaveCausesFx = createEffect<void, Entry[]>();

const getHolderLeaveCausesFx = createEffect<void, Entry[]>();

const getAccountDeleteCausesFx = createEffect<void, Entry[]>();

const $relationships = restore<Entry[]>(getRelationshipsFx.doneData, []);

const $languages = restore<Language[]>(getLanguagesFx.doneData, []);

const $transportationHelpTypes = restore<TransportationHelp[]>(
  getTransportationHelpTypesFx,
  []
);

const $pets = restore<Pet[]>(getPetsFx, []);

const $genders = restore<Gender[]>(getGendersFx, []);

const $careTypes = restore<CareType[]>(getCareTypesFx, []);

const $certificates = restore<Certificate[]>(getCertificatesFx, []);

const $conditionTypes = restore<ConditionsType[]>(getConditionTypesFx, []);

const $dbs = restore<Dbs[]>(getDbsFx, []);

const $carerGenders = restore<CarerGender[]>(getCarerGendersFx, []);

const $qualifications = restore<Qualification[]>(getQualificationsFx, []);

const $supportTypes = restore<SupportType[]>(getSupportTypesFx, []);

const $workingDocuments = restore<WorkingDocument[]>(getWorkingDocumentsFx, []);

const $services = restore<Service[]>(getServicesFx, []);

const $memberLeaveCauses = restore<Entry[]>(getMemberLeaveCausesFx, []);

const $holderLeaveCauses = restore<Entry[]>(getHolderLeaveCausesFx, []);

const $accountDeleteCauses = restore<Entry[]>(getAccountDeleteCausesFx, []);

const $$dictionary = {
  $relationships,
  $languages,
  $transportationHelpTypes,
  $pets,
  $genders,
  $careTypes,
  $certificates,
  $conditionTypes,
  $dbs,
  $carerGenders,
  $qualifications,
  $supportTypes,
  $workingDocuments,
  $services,
  $memberLeaveCauses,
  $holderLeaveCauses,
  $accountDeleteCauses,

  getRelationshipsFx,
  getLanguagesFx,
  getTransportationHelpTypesFx,
  getPetsFx,
  getGendersFx,
  getCareTypesFx,
  getCertificatesFx,
  getConditionTypesFx,
  getDbsFx,
  getCarerGendersFx,
  getQualificationsFx,
  getSupportTypesFx,
  getWorkingDocumentsFx,
  getServicesFx,
  getMemberLeaveCausesFx,
  getHolderLeaveCausesFx,
  getAccountDeleteCausesFx
};

getRelationshipsFx.use(() =>
  carersHQ.dictionary.relationships().then(res => res.data)
);

getLanguagesFx.use(() => carersHQ.dictionary.languages().then(res => res.data));

getTransportationHelpTypesFx.use(() =>
  carersHQ.dictionary.transportationHelpTypes().then(res => res.data)
);

getPetsFx.use(() => carersHQ.dictionary.pets().then(res => res.data));

getGendersFx.use(() => carersHQ.dictionary.genders().then(res => res.data));

getCareTypesFx.use(() => carersHQ.dictionary.careTypes().then(res => res.data));

getCertificatesFx.use(() =>
  carersHQ.dictionary.certificates().then(res => res.data)
);

getConditionTypesFx.use(() =>
  carersHQ.dictionary.conditionTypes().then(res => res.data)
);

getDbsFx.use(() => carersHQ.dictionary.dbs().then(res => res.data));

getCarerGendersFx.use(() =>
  carersHQ.dictionary.carerGenders().then(res => res.data)
);

getQualificationsFx.use(() =>
  carersHQ.dictionary.qualifications().then(res => res.data)
);

getSupportTypesFx.use(() =>
  carersHQ.dictionary.supportTypes().then(res => res.data)
);

getWorkingDocumentsFx.use(() =>
  carersHQ.dictionary.workingDocuments().then(res => res.data)
);

getServicesFx.use(() => carersHQ.dictionary.services().then(res => res.data));

getMemberLeaveCausesFx.use(() =>
  carersHQ.dictionary.memberLeaveCauses().then(res => res.data)
);

getHolderLeaveCausesFx.use(() =>
  carersHQ.dictionary.holderLeaveCauses().then(res => res.data)
);

getAccountDeleteCausesFx.use(() =>
  carersHQ.dictionary.accountDeleteCauses().then(res => res.data)
);

export { $$dictionary };
