import iconDelete from '@icon/delete-black.svg';

import classNames from 'classnames';

import type { FC } from 'react';

import { memo } from 'react';

import { MembersRoleType } from '@shared/api';

import { createView } from '@shared/lib/view';

import { AlertBox } from '@shared/ui/atoms/alert-box';

import { Button } from '@shared/ui/atoms/button';

import { Input } from '@shared/ui/atoms/input';

import { useMedia } from '@shared/ui/atoms/media';

import { Modal } from '@shared/ui/atoms/modal';

import { Select } from '@shared/ui/atoms/select';

import { Text } from '@shared/ui/atoms/text';

import { Field, useField } from '@shared/ui/organisms/field';

import {
  $memberEmailRows,
  cancelClicked,
  emailsForm,
  deleteRowClicked,
  $emailsError
} from '../../model';

import styles from './emails.module.scss';

const DeleteFieldCross: FC<{
  index: number;
  deleteRowClick: any;
  rows: any;
}> = memo(({ index, rows, deleteRowClick }) => (
  <div
    className={classNames(styles.inviteRemove, {
      [styles.inviteRemovePositionAdjusted]: index === 0
    })}
  >
    {rows.length > 3 && index !== rows.length - 1 && (
      <img
        onClick={() => {
          deleteRowClick(index);
        }}
        src={iconDelete}
      />
    )}
  </div>
));

const Emails = createView()
  .static({
    levels: Object.values(MembersRoleType)
      .filter(name => name !== MembersRoleType.Owner)

      .map(name => ({
        id: name,

        name: name
      }))
  })

  .units({
    rows: $memberEmailRows,

    formSubmitted: emailsForm.$submitted,

    emailsErrors: $emailsError,

    onCancelClick: cancelClicked,

    onContinueClick: emailsForm.submit.prepend(() => {}),

    deleteRowClick: deleteRowClicked
  })

  .map(() => {
    const md = useMedia('>=md');

    const { hasError, value: members } = useField(emailsForm.fields.members);

    const filteredMembers =
      members.filter(item => item.email !== '').length > 0;

    return {
      md,
      members,
      hasError,
      filteredMembers
    };
  })

  .view(
    ({
      rows,
      levels,
      onCancelClick,
      filteredMembers,
      onContinueClick,
      md,
      hasError,
      emailsErrors,
      deleteRowClick
    }) => (
      <Modal.Container
        size='small'
        onClose={onCancelClick}
        className={styles.modal}
      >
        <Modal.Header>Invite new members to your Care Space </Modal.Header>

        <Modal.Body
          className={classNames({
            [styles.hasError]: hasError
          })}
        >
          <Text variant='body-1' className={styles.description}>
            To invite new members, add their emails and select access levels.
          </Text>

          <div className={styles.invites}>
            {rows.map((_, index) => (
              <div key={index} className={styles.invite}>
                <div className={styles.container}>
                  <Field.Container use={emailsForm.fields.members[index]}>
                    {({ value, onChange, error, onBlur, onFocus }) => (
                      <Input
                        value={value?.email ?? ''}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onChange={email => {
                          onChange(
                            value
                              ? {
                                  id: (index + 1) * 50,
                                  email,
                                  role: value.role
                                }
                              : {
                                  id: (index + 1) * 50,
                                  email,
                                  role: MembersRoleType.ViewOnly
                                }
                          );
                        }}
                        error={error}
                        placeholder='name@example.com'
                        label={(!md || !index) && 'Email'}
                      />
                    )}
                  </Field.Container>
                </div>

                <div className={styles.selectWrapper}>
                  <Field.Container use={emailsForm.fields.members[index].role}>
                    {({ onChange, value }) => (
                      <Select
                        value={value ?? MembersRoleType.ViewOnly}
                        onChange={onChange}
                        searchable={false}
                        className={styles.select}
                        menuPosition='fixed'
                        placeholder='Select'
                        options={levels}
                        label={(!md || !index) && 'Access Level'}
                      />
                    )}
                  </Field.Container>

                  <DeleteFieldCross
                    deleteRowClick={deleteRowClick}
                    index={index}
                    rows={rows}
                  />
                </div>
              </div>
            ))}
          </div>

          {emailsErrors ? (
            <AlertBox variant='error' className={styles.alertBox}>
              <p>
                {filteredMembers
                  ? 'Some emails are invalid. Please check and try again.'
                  : 'To continue, you should add at least one email address.'}
              </p>
            </AlertBox>
          ) : null}
        </Modal.Body>

        <Modal.Footer className={styles.footer}>
          <div>
            <Button
              className={styles.previous}
              variant='text'
              palette='text'
              onClick={() => onCancelClick()}
            >
              Cancel
            </Button>

            {/* eslint-disable-next-line react/jsx-handler-names */}
            <Button onClick={filteredMembers ? onContinueClick : () => {}}>
              Continue
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Container>
    )
  );
export { Emails };
