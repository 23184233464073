import { createStore } from 'effector';

import type { Carer } from '@shared/api';

import { atom } from '@shared/lib/factory';

const $$carer = atom('Carer Entity', () => {
  const $carer = createStore<Carer>(null!);

  return {
    $carer
  };
});

export { $$carer };
