import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { $$resetUserPasswordByEmail, form } from '../model';

import styles from './reset-password.module.scss';

type ResetPasswordProps = {};

const ResetPasswordForm = createView<ResetPasswordProps>().view(() => (
  <Form className={styles.form} use={form}>
    <div className={styles.fields}>
      <Field.Input
        use={form.fields.email}
        autoFocus
        type='email'
        disableAutocomplete
        label='Email'
        errorIn='label'
        placeholder='Enter your email'
        tip='We’ll send a instruction to your email'
      />
    </div>

    <Button className={styles.submit} type='submit'>
      Send instructions
    </Button>
  </Form>
));

const EmailSent = createView()
  .units({
    email: $$resetUserPasswordByEmail.$email
  })

  .view(({ email }) => (
    <div className={styles.instructions}>
      <p>
        Instruction for reseting your password have been sent to
        <span className={styles.email}> {email}</span>
      </p>

      <p>
        You’ll receive this email within 5 minutes. Be sure to check your spam
        folder, too.
      </p>
    </div>
  ));

const ResetPassword = createView<ResetPasswordProps>()
  .units({
    sent: $$resetUserPasswordByEmail.$resetPasswordEmailSent
  })

  .view(({ sent }) => <>{sent ? <EmailSent /> : <ResetPasswordForm />}</>);

export { ResetPassword };
