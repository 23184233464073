import moment from 'moment';

import { useMemo } from 'react';

import { $$carerProfile } from '@entities/carer';

import { $$dictionary } from '@entities/dictionary';

import { jsonEqual } from '@shared/lib/utils';

import { createView } from '@shared/lib/view';

import { FormSection, FormSectionGroup } from '@shared/ui/atoms/form-section';

import { useMedia } from '@shared/ui/atoms/media';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { form } from '../../model';

import styles from './qualifications-form.module.scss';

/**
 * <Qualifications />
 */
const QualificationsForm = createView()
  .units({
    carer: $$carerProfile.$carerProfile,

    certificates: $$dictionary.$certificates,

    qualifications: $$dictionary.$qualifications,

    formValues: form.$values
  })

  .map(({ carer, formValues, qualifications, certificates }) => {
    const { minStartDate, maxStartDate } = useMemo(
      () => ({
        minStartDate: moment(carer?.dateOfBirth).add(18, 'years').format(),
        maxStartDate: moment().format()
      }),
      [carer?.dateOfBirth]
    );

    const mobile = useMedia('<md');

    const qualificationsOptions = mobile
      ? qualifications
      : [
          { id: 1, name: 'NVQ/QCF Level 2 in Health and Social Care' },

          { id: 5, name: 'Agency Induction Training (pre-2015)' },

          { id: 2, name: 'NVQ/QCF Level 3 in Health and Social Care' },

          { id: 6, name: 'Care Certificate' },

          { id: 3, name: 'NVQ/QCF Level 4 in Health and Social Care' },

          {},

          { id: 4, name: 'NVQ/QCF Level 5 in Health and Social Care' }
        ];

    const certificatesOptions = mobile
      ? certificates
      : [
          { id: 1, name: 'Physiotherapy certification: HCPC registered' },

          { id: 5, name: 'Univercity degree (other descipline)' },

          { id: 2, name: 'Occupational certification: HCPC registered' },

          { id: 6, name: 'Nursing (UK)' },

          { id: 3, name: 'Univercity degree in Health and Social Care' },

          { id: 7, name: 'Nursing (abroad)' },

          { id: 4, name: 'NVQ/QCF Level 5 in Health and Social Care' },

          { id: 8, name: 'Other' }
        ];

    const differentQualifications = useMemo(
      () =>
        formValues &&
        !jsonEqual(
          {
            qualifications: carer?.qualifications.map(item => item.id),
            certificates: carer?.certificates.map(item => item.id)
          },
          {
            qualifications: formValues?.qualifications,
            certificates: formValues?.certificates
          }
        ),

      [carer, formValues?.qualifications, formValues?.certificates]
    );

    return {
      maxStartDate,

      minStartDate,

      qualificationsOptions,

      certificatesOptions,

      differentQualifications
    };
  })

  .view(
    ({
      certificatesOptions,
      qualificationsOptions,
      differentQualifications,
      maxStartDate,
      minStartDate
    }) => (
      <Form use={form}>
        <Field.FormSection
          use={form.fields.carerStart}
          className={styles.startDate}
          title='Since when have you been a professional carer?'
        >
          <div className={styles.fields}>
            <Field.DateSelectGroup
              labelGap
              use={form.fields.carerStart}
              yaer
              month
              date={false}
              min={minStartDate}
              max={maxStartDate}
            />
          </div>
        </Field.FormSection>
        <FormSectionGroup>
          <FormSection
            title='What care qualifications do you have?'
            subTitle='In order to collaborate with CarersHQ, you must have one of the following qualifications:'
          >
            <div className={styles.qualificationsList}>
              <Field.CheckboxList
                use={form.fields.qualifications}
                options={qualificationsOptions}
              />
            </div>
          </FormSection>

          <FormSection title='What other certificates do you have?'>
            <div className={styles.certificatesList}>
              <Field.CheckboxList
                use={form.fields.certificates}
                options={certificatesOptions}
              />
            </div>
          </FormSection>

          {differentQualifications && (
            <Field.CheckboxAlertBox
              hideMore
              use={form.fields.qualificationConfirm}
              title='I confirm I will be able to provide proof of my qualifications upon request'
              text='We will require proof of your qualifications at a later stage. This can be a copy of your certificate, a copy of your diploma, or a confirmation letter from the agency. '
            />
          )}
        </FormSectionGroup>
      </Form>
    )
  );

export { QualificationsForm };
