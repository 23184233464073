import { $$dictionary } from '@entities/dictionary';

import { createView } from '@shared/lib/view';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { form } from '../../model';

import styles from './services-form.module.scss';

const ServicesForm = createView()
  .units({
    services: $$dictionary.$services
  })

  .view(({ services }) => (
    <Form use={form}>
      <div className={styles.servicesGroup}>
        <Field.CheckboxList use={form.fields.services} options={services} />
      </div>
    </Form>
  ));

export { ServicesForm };
