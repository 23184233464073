import type { HTMLAttributes } from 'react';

import { useRef, useState } from 'react';

type VideoBoxProps = HTMLAttributes<HTMLDivElement>;

const useVideoBoxProps = () => {
  const videoRef = useRef<HTMLVideoElement>();
  const [playing, setPlaying] = useState(false);

  const onBoxClick = () => {
    if (playing) return;

    videoRef.current.play();
  };

  const onPlay = () => {
    setPlaying(true);
  };

  const onPause = () => {
    setPlaying(false);
  };

  return {
    playing,
    onPause,
    videoRef,
    onPlay,
    onBoxClick
  };
};

export type { VideoBoxProps };

export { useVideoBoxProps };
