import { Attention } from '@entities/account/ui/delete-account/attention';

import { Confirm } from '@entities/account/ui/delete-account/confirm';

import { Credentials } from '@entities/account/ui/delete-account/credentials';

import { Done } from '@entities/account/ui/delete-account/done';

import { Reason } from '@entities/account/ui/delete-account/reason';

import { $$dictionary } from '@entities/dictionary';

import { $$user } from '@entities/user';

import { createView } from '@shared/lib/view';

import { $$settingsDeleteAccount } from '../model';

import { WorkspaceDecision } from '../ui/workspace-decision';

const SettingsDeleteAccount = createView()
  .units({
    step: $$settingsDeleteAccount.$step,

    options: $$dictionary.$accountDeleteCauses,

    account: $$user.$account,

    stepChanged: $$settingsDeleteAccount.stepChanged,

    onTransferClicked: $$settingsDeleteAccount.transferClicked,

    onClose: $$settingsDeleteAccount.modalClosed,

    submit: $$settingsDeleteAccount.submit
  })

  .view(({ step, stepChanged, onClose, options, submit, account }) => (
    <>
      {step === 'confirm' && (
        <Confirm onCancel={onClose} stepChanged={stepChanged} isAccount />
      )}

      {step === 'workspace-decision' && <WorkspaceDecision />}

      {step === 'reason' && (
        <Reason
          isAccount
          options={options}
          step='workspace-decision'
          stepChanged={stepChanged}
          onCancel={onClose}
        />
      )}

      {step === 'attention-access' && (
        <Attention
          key='delete-account'
          step={step}
          stepChanged={stepChanged}
          onCancel={onClose}
          isAccount
        />
      )}

      {step === 'credentials' && (
        <Credentials stepChanged={stepChanged} onCancel={onClose} isAccount />
      )}

      {step === 'attention-appointments' && (
        <Attention
          submit={submit}
          step={step}
          stepChanged={stepChanged}
          onCancel={onClose}
          isAccount
        />
      )}

      {step === 'done' && <Done email={account?.email} onCancel={onClose} />}
    </>
  ));

export { SettingsDeleteAccount };
