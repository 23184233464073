import { createStore } from 'effector';

import { modelFactory } from 'effector-factorio';

import { array, boolean, object } from 'yup';

import type { Language, Pet, TransportationHelp } from '@shared/api';

import { createForm } from '@shared/lib/form';

type CarerAdditionalInformationValues = {
  languages: Language[];

  transportationHelp: TransportationHelp['id'];

  petsOk: boolean;

  smokingOk: boolean;

  petsDisabled: Pet['id'][];
};

const ManageAdditionalInformation = modelFactory(() => {
  const form = createForm<CarerAdditionalInformationValues>({
    initialValues: {
      languages: [
        {
          id: 1,

          name: 'English'
        }
      ],

      transportationHelp: null,

      smokingOk: false,

      petsOk: false,

      petsDisabled: []
    },

    $schema: createStore(
      object({
        languages: array().nullable().min(1),

        transportationHelp: object().nullable().label('Transportation help'),

        smokingOk: boolean().nullable().label('Smoking'),

        petsOk: boolean().nullable().label('Pets'),

        petsDisabled: array().nullable().label('Pets')
      })
    )
  });

  return {
    form
  };
});

export type { CarerAdditionalInformationValues };

export { ManageAdditionalInformation };
