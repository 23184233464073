import { $$signUpLovedOne } from '@pages/sign-up/pages/loved-one';

import { routes } from '@shared/routes';

import { createLovedOneStep } from './shared';

createLovedOneStep({
  key: 'preferences',

  form: $$signUpLovedOne.steps.preferences.form,

  route: routes.signUp.lovedOne.preferences,

  nextRoute: routes.signUp.lovedOne.careRecipient,

  mapValues: values => values.preferences,

  processValues: values => ({
    ...values,

    languages: values.languages.filter(Boolean)
  }),

  checkValues: values => Boolean(values.conditionExperience)
});
