import classNames from 'classnames';

import { createView } from '@shared/lib/view';

import styles from './invoices-placeholder.module.scss';

const InvoicesPlaceholder = createView().view(() => (
  <div className={styles.empty}>
    <div className={styles.emptyIllustration} />

    <h5 className={styles.emptyTitle}>You don't have invoices.</h5>

    <p className={classNames(styles.emptyText, styles.emptyTextMain)}>
      It looks like you haven't had any invoices in the entire time you've been
      using <br /> our service.
    </p>

    <p className={styles.emptyText}>
      If not,{' '}
      <a href='@pages/deleted-account/ui/invoices-placeholder/invoices-placeholder.component#'>
        contact us
      </a>
      , and we will <br /> try to help you.
    </p>
  </div>
));

export { InvoicesPlaceholder };
