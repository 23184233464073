import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample
} from 'effector';

import { $$accountInfo } from '@entities/account';

import { $$careSpace } from '@entities/care-space';

import type {
  UpdateMemberNotificationsDTO,
  MembersRoleType,
  UpdateMemberRoleDTO,
  InviteMember,
  UpdateMemberRelation,
  WorkspaceMember,
  KickFromWorkspaceDTO
} from '@shared/api';

import { carersHQ } from '@shared/api';

import { ToastID } from '@shared/config';

import { sortByRole, updateAtCondition } from '@shared/lib/utils';

import { notify } from '@shared/ui/organisms/toasts';

const getMembersFx = attach({
  effect: carersHQ.workspaces.getMembers,

  source: $$careSpace.$careSpaceID,

  mapParams: (_: void, workspaceId: string) => ({ workspaceId })
});

const updateMemberNotificationsFx = createEffect<
  UpdateMemberNotificationsDTO & {
    memberId: number;
  },
  Pick<
    UpdateMemberNotificationsDTO,
    'emailNotifications' | 'smsNotifications'
  > & {
    memberId: number;
  }
>();

const updateMemberRoleFx = createEffect<
  UpdateMemberRoleDTO & {
    memberId: number;
  },
  {
    memberId: number;
    role: MembersRoleType;
  }
>();

const resendInvitationFx = createEffect<
  { email: string; workspaceId: string },
  void
>();

const inviteMembersFx = createEffect<InviteMember[], void>();

const updateMemberRelationsFx = createEffect<UpdateMemberRelation[], void>();

const kickFromWorkspaceFx = createEffect<KickFromWorkspaceDTO, void>();

const updateMemberNotifications = createEvent<
  Omit<UpdateMemberNotificationsDTO, 'workspaceId'> & {
    memberId: number;
  }
>();

const updateMemberRole = createEvent<
  Omit<UpdateMemberRoleDTO, 'workspaceId'> & {
    memberId: number;
  }
>();

const inviteMembers = createEvent<Omit<InviteMember, 'workspaceId'>[]>();

const kickFromWorkspace =
  createEvent<Omit<KickFromWorkspaceDTO, 'workspaceId'>>();

const resendInvitation = createEvent<string>();

const $members = createStore<WorkspaceMember[]>([]);

const $updateNotificationsReady = updateMemberNotificationsFx.pending.map(
  pending => !pending
);

const $$members = {
  $members,

  $updateNotificationsReady,

  updateMemberNotifications,

  updateMemberRole,

  inviteMembers,

  kickFromWorkspace,

  resendInvitation,

  getMembersFx,

  updateMemberRoleFx,

  updateMemberNotificationsFx,

  inviteMembersFx,

  updateMemberRelationsFx,

  kickFromWorkspaceFx
};

sample({
  clock: updateMemberRole,

  source: $$careSpace.$careSpaceID,

  fn: (workspaceId, payload) => ({
    ...payload,
    workspaceId
  }),

  target: updateMemberRoleFx
});

sample({
  clock: [updateMemberRelationsFx.done, updateMemberRoleFx.done],

  target: [getMembersFx, $$accountInfo.getAccountFx]
});

notify({
  clock: updateMemberRoleFx.failData,

  options: () => ({
    id: ToastID.UpdateRole,
    title: 'Update access level',
    content: `<div style="word-break: break-word">Member does not exist in account</div>`,
    type: 'error',
    isTextMinimized: true
  })
});

notify({
  clock: updateMemberRelationsFx.done,

  options: ({ params: relations }) => ({
    id: ToastID.RelationsWereUpdated,
    title: 'Relations updated',
    content: `<div style="word-break: break-all"><b>${relations[0].email}'s</b> relations were updated</div>`,
    type: 'success',
    isTextMinimized: true
  })
});

sample({
  clock: updateMemberNotifications,

  source: $$careSpace.$careSpaceID,

  fn: (workspaceId, value) => ({
    ...value,
    workspaceId
  }),

  target: updateMemberNotificationsFx
});

sample({
  clock: updateMemberNotificationsFx.doneData,

  target: getMembersFx
});

sample({
  clock: inviteMembers,

  source: $$careSpace.$careSpaceID,

  fn: (workspaceId, members) =>
    members.map(item => ({
      ...item,
      workspaceId
    })),

  target: inviteMembersFx
});

sample({
  clock: inviteMembersFx.done,

  target: getMembersFx
});

sample({
  clock: resendInvitation,

  source: $$careSpace.$careSpaceID,

  fn: (workspaceId, email) => ({
    email,
    workspaceId
  }),

  target: resendInvitationFx
});

notify({
  clock: resendInvitationFx.done,

  options: ({ params: { email } }) => ({
    id: ToastID.InvitationResent,
    type: 'success',
    title: 'Invitation has been sent',
    content: `<div style="word-break: break-all">We sent email notification to the <b>${email}.</b></div>`,
    isTextMinimized: true
  })
});

notify({
  clock: inviteMembersFx.done,

  options: {
    id: ToastID.InvitationsSent,
    type: 'success',
    title: 'Invitations have been sent',
    content:
      'We sent email notifications to the invited users. You can resend the invitation via the menu near their accounts.',
    isTextMinimized: true
  }
});

notify({
  clock: inviteMembersFx.fail,

  options: {
    id: ToastID.InvitationsSentError,
    type: 'error',
    title: 'Some invitations have not been sent',
    content: 'There must be an error with one of the invitations',
    isTextMinimized: true
  }
});

$members
  .on(
    getMembersFx.doneData.map(({ data: { members } }) =>
      members.sort(sortByRole)
    ),

    (_, payload) => payload
  )
  .on(
    updateMemberNotificationsFx.doneData,

    (state, { memberId, emailNotifications, smsNotifications }) =>
      updateAtCondition(
        state,

        ({ id }) => id == memberId,

        item => ({
          ...item,

          smsNotifications,
          emailNotifications
        })
      )
  );

$members.on(updateMemberRoleFx, (state, { memberId, role }) =>
  updateAtCondition(
    state,

    item => item.id == memberId,

    item => ({ ...item, role })
  )
);

updateMemberRoleFx.use(async ({ email, role, memberId, workspaceId }) => {
  await carersHQ.workspaces.updateMemberRole({
    email,
    role,
    workspaceId
  });

  return {
    role,
    memberId
  };
});

updateMemberNotificationsFx.use(
  async ({
    workspaceId,
    accountId,
    emailNotifications,
    memberId,
    smsNotifications
  }) => {
    await carersHQ.settings.updateMemberNotifications({
      accountId,
      emailNotifications,
      smsNotifications,
      workspaceId
    });

    return {
      memberId,
      emailNotifications,
      smsNotifications
    };
  }
);

updateMemberRelationsFx.use(async relations => {
  await carersHQ.workspaces.updateMemberRelations({
    relations
  });
});

inviteMembersFx.use(async payload => {
  await carersHQ.settings.inviteMembers({
    invites: payload
  });
});

kickFromWorkspaceFx.use(async payload => {
  await carersHQ.settings.kickFromWorkspace(payload);
});

resendInvitationFx.use(async payload => {
  await carersHQ.settings.resendMemberInvitation(payload);
});

export { $$members };
