import { createStore } from 'effector';

import { modelFactory } from 'effector-factorio';

import { number, object, string } from 'yup';

import { createForm } from '@shared/lib/form';

import { nameRegEx } from '@shared/lib/utils';

type AccountHolderValues = {
  phone: string;

  relation: number;

  lastName: string;

  firstName: string;
};

const ManageAccountHolderModelFactory = modelFactory(() => {
  const form = createForm<AccountHolderValues>({
    initialValues: {
      phone: '',

      lastName: '',

      firstName: '',

      relation: null
    },

    $schema: createStore(
      object({
        firstName: string()
          .required()

          .nullable()

          .trim('Required')

          .matches(nameRegEx, 'Invalid')

          .label('First Name'),

        lastName: string()
          .required()

          .nullable()

          .trim('Required')

          .matches(nameRegEx, 'Invalid')

          .label('Last Name'),

        relation: number().required().nullable().label('Relationship'),

        phone: string()
          .required()

          .nullable()

          .trim('Required')

          .min(2, 'Required')

          .matches(/^\+?\d+$/, 'Invalid')

          .label('Phone')
      })
    )
  });

  return {
    form
  };
});

export type { AccountHolderValues };

export { ManageAccountHolderModelFactory };
