import { createEvent, createStore } from 'effector';

const modalOpened = createEvent<number>();

const modalClosed = createEvent();

const $modalCount = createStore(0)
  .on([modalOpened], (_state, payload) => payload)

  .reset(modalClosed);

const $$modal = {
  $modalCount,

  modalOpened,

  modalClosed
};

export { $$modal };
