import { attach, createEvent, sample } from 'effector';

import { $$profileCareBasics } from '@features/care-recipient/profile/care-basics';

import { $$profileConditionExperience } from '@features/care-recipient/profile/condition-experience';

import { $$profilePersonal } from '@features/care-recipient/profile/personal';

import { $$profilePreferences } from '@features/care-recipient/profile/preferences';

import { $$profileRequiredSupport } from '@features/care-recipient/profile/required-support';

import { $$uploadFile } from '@features/file/upload';

import { $$careRecipient } from '@entities/care-recipient';

import { $$careSpace } from '@entities/care-space';

import type { Client } from '@shared/api';

import { ToastID } from '@shared/config';

import { routes } from '@shared/routes';

import { createEditableSection } from '@shared/ui/atoms/editable-section';

import type { NotifyOptions } from '@shared/ui/organisms/toasts';

import { $$toast } from '@shared/ui/organisms/toasts';

const $id = routes.careRecipients.base.$params.map(
  state => state?.id?.[0] ?? 'temp'
);

const savePersonalDetailsFx = attach({
  effect: $$careRecipient.saveClientFx
});

const saveCareBaseFx = attach({
  effect: $$careRecipient.saveClientFx
});

const saveSupportFx = attach({
  effect: $$careRecipient.saveClientFx
});

const saveExperienceFx = attach({
  effect: $$careRecipient.saveClientFx
});

const savePreferencesFx = attach({
  effect: $$careRecipient.saveClientFx
});

const savePersonalDetailsClicked = createEvent<boolean>();

const saveCareBaseClicked = createEvent<boolean>();

const saveSupportClicked = createEvent<boolean>();

const saveExperienceClicked = createEvent<boolean>();

const savePreferencesClicked = createEvent<boolean>();

const uploadImageClicked = createEvent<File>();

const personalDetails = createEditableSection();

const careBasics = createEditableSection();

const requiredSupport = createEditableSection();

const conditionExperience = createEditableSection();

const preferences = createEditableSection();

const $$profilePage = {
  personalDetails,

  careBasics,

  requiredSupport,

  conditionExperience,

  preferences,

  savePersonalDetailsClicked,

  saveCareBaseClicked,

  saveSupportClicked,

  saveExperienceClicked,

  savePreferencesClicked,

  uploadImageClicked
};

sample({
  clock: $$profilePersonal.form.submitted,

  fn: () => true,

  target: savePersonalDetailsClicked
});

const savePersonalDetails = sample({
  clock: savePersonalDetailsClicked,

  source: {
    id: $id,

    personalDetails: $$profilePersonal.form.$values,

    careBasics: $$profileCareBasics.form.$values,

    conditionExperience: $$profileConditionExperience.form.$values,

    requiredSupport: $$profileRequiredSupport.form.$values,

    preferences: $$profilePreferences.form.$values
  },

  fn: ({
    conditionExperience,
    id,
    careBasics,
    requiredSupport,
    personalDetails,
    preferences
  }) =>
    ({
      ...conditionExperience,
      ...careBasics,
      ...requiredSupport,
      ...preferences,
      ...personalDetails,

      id: id,

      carerGender: preferences.carerGender && {
        id: preferences.carerGender
      },

      gender: personalDetails.gender && {
        id: personalDetails.gender
      },

      careTypes: [...new Set(careBasics.careTypes)],

      nightCareTypes: [...new Set(careBasics.nightCareTypes)],

      conditionsTypes: conditionExperience.conditionsTypes.map(item => ({
        id: item
      })),

      services: requiredSupport.services.map(item => ({ id: item })),

      transportationHelp: preferences.transportationHelp && {
        id: preferences.transportationHelp
      },

      pets: preferences.pets.map(item => ({ id: item }))
    } as Client)
});

sample({
  clock: $$profileCareBasics.form.submitted,

  fn: () => true,

  target: saveCareBaseClicked
});

const saveCareBase = sample({
  clock: saveCareBaseClicked,

  source: {
    id: $id,

    personalDetails: $$profilePersonal.form.$values,

    careBasics: $$profileCareBasics.form.$values,

    conditionExperience: $$profileConditionExperience.form.$values,

    requiredSupport: $$profileRequiredSupport.form.$values,

    preferences: $$profilePreferences.form.$values
  },

  fn: ({
    conditionExperience,
    id,
    careBasics,
    requiredSupport,
    personalDetails,
    preferences
  }) =>
    ({
      ...conditionExperience,
      ...careBasics,
      ...requiredSupport,
      ...preferences,
      ...personalDetails,

      id: id,

      carerGender: preferences.carerGender && {
        id: preferences.carerGender
      },

      gender: personalDetails.gender && {
        id: personalDetails.gender
      },

      careTypes: [...new Set(careBasics.careTypes)],

      nightCareTypes: [...new Set(careBasics.nightCareTypes)],

      conditionsTypes: conditionExperience.conditionsTypes.map(item => ({
        id: item
      })),

      services: requiredSupport.services.map(item => ({ id: item })),

      transportationHelp: preferences.transportationHelp && {
        id: preferences.transportationHelp
      },

      pets: preferences.pets.map(item => ({ id: item }))
    } as Client)
});

sample({
  clock: $$profileRequiredSupport.form.submitted,

  fn: () => true,

  target: saveSupportClicked
});

const saveSupport = sample({
  clock: saveSupportClicked,

  source: {
    id: $id,

    personalDetails: $$profilePersonal.form.$values,

    careBasics: $$profileCareBasics.form.$values,

    conditionExperience: $$profileConditionExperience.form.$values,

    requiredSupport: $$profileRequiredSupport.form.$values,

    preferences: $$profilePreferences.form.$values
  },

  fn: ({
    conditionExperience,
    id,
    careBasics,
    requiredSupport,
    personalDetails,
    preferences
  }) =>
    ({
      ...conditionExperience,
      ...careBasics,
      ...requiredSupport,
      ...preferences,
      ...personalDetails,

      id: id,

      carerGender: preferences.carerGender && {
        id: preferences.carerGender
      },

      gender: personalDetails.gender && {
        id: personalDetails.gender
      },

      careTypes: [...new Set(careBasics.careTypes)],

      nightCareTypes: [...new Set(careBasics.nightCareTypes)],

      conditionsTypes: conditionExperience.conditionsTypes.map(item => ({
        id: item
      })),

      services: requiredSupport.services.map(item => ({ id: item })),

      transportationHelp: preferences.transportationHelp && {
        id: preferences.transportationHelp
      },

      pets: preferences.pets.map(item => ({ id: item }))
    } as Client)
});

sample({
  clock: $$profileConditionExperience.form.submitted,

  fn: () => true,

  target: saveExperienceClicked
});

const saveExperience = sample({
  clock: saveExperienceClicked,

  source: {
    id: $id,

    personalDetails: $$profilePersonal.form.$values,

    careBasics: $$profileCareBasics.form.$values,

    conditionExperience: $$profileConditionExperience.form.$values,

    requiredSupport: $$profileRequiredSupport.form.$values,

    preferences: $$profilePreferences.form.$values
  },

  fn: ({
    conditionExperience,
    id,
    careBasics,
    requiredSupport,
    personalDetails,
    preferences
  }) =>
    ({
      ...conditionExperience,
      ...careBasics,
      ...requiredSupport,
      ...preferences,
      ...personalDetails,

      id: id,

      carerGender: preferences.carerGender && {
        id: preferences.carerGender
      },

      gender: personalDetails.gender && {
        id: personalDetails.gender
      },

      careTypes: [...new Set(careBasics.careTypes)],

      nightCareTypes: [...new Set(careBasics.nightCareTypes)],

      conditionsTypes: conditionExperience.conditionsTypes.map(item => ({
        id: item
      })),

      services: requiredSupport.services.map(item => ({ id: item })),

      transportationHelp: preferences.transportationHelp && {
        id: preferences.transportationHelp
      },

      pets: preferences.pets.map(item => ({ id: item }))
    } as Client)
});

sample({
  clock: $$profilePreferences.form.submitted,

  fn: () => true,

  target: savePreferencesClicked
});

const savePreferences = sample({
  clock: savePreferencesClicked,

  source: {
    id: $id,

    personalDetails: $$profilePersonal.form.$values,

    careBasics: $$profileCareBasics.form.$values,

    conditionExperience: $$profileConditionExperience.form.$values,

    requiredSupport: $$profileRequiredSupport.form.$values,

    preferences: $$profilePreferences.form.$values
  },

  fn: ({
    conditionExperience,
    id,
    careBasics,
    requiredSupport,
    personalDetails,
    preferences
  }) =>
    ({
      ...conditionExperience,
      ...careBasics,
      ...requiredSupport,
      ...preferences,
      ...personalDetails,

      id: id,

      carerGender: preferences.carerGender && {
        id: preferences.carerGender
      },

      gender: personalDetails.gender && {
        id: personalDetails.gender
      },

      careTypes: [...new Set(careBasics.careTypes)],

      nightCareTypes: [...new Set(careBasics.nightCareTypes)],

      conditionsTypes: conditionExperience.conditionsTypes.map(item => ({
        id: item
      })),

      services: requiredSupport.services.map(item => ({ id: item })),

      transportationHelp: preferences.transportationHelp && {
        id: preferences.transportationHelp
      },

      pets: preferences.pets.map(item => ({ id: item }))
    } as Client)
});

sample({
  clock: savePersonalDetails,

  target: savePersonalDetailsFx
});

sample({
  clock: saveCareBase,

  target: saveCareBaseFx
});

sample({
  clock: saveSupport,

  target: saveSupportFx
});

sample({
  clock: saveExperience,

  target: saveExperienceFx
});

sample({
  clock: savePreferences,

  target: savePreferencesFx
});

sample({
  clock: [
    savePersonalDetailsFx.doneData,
    saveCareBaseFx.doneData,
    saveSupportFx.doneData,
    saveExperienceFx.doneData,
    savePreferencesFx.doneData
  ],

  fn: client => client?.id,

  target: $$careRecipient.getCareRecipientFx
});

sample({
  clock: savePersonalDetailsFx.doneData,

  target: personalDetails.close
});

sample({
  clock: saveCareBaseFx.doneData,

  target: careBasics.close
});

sample({
  clock: saveSupportFx.doneData,

  target: requiredSupport.close
});

sample({
  clock: saveExperienceFx.doneData,

  target: conditionExperience.close
});

sample({
  clock: savePreferencesFx.doneData,

  target: preferences.close
});

sample({
  clock: [
    savePersonalDetailsFx.doneData,
    saveCareBaseFx.doneData,
    saveSupportFx.doneData,
    saveExperienceFx.doneData,
    savePreferencesFx.doneData
  ],

  source: $$careSpace.$careSpaceID,

  fn: id => id,

  target: $$careSpace.loadCareSpaceFx
});

sample({
  clock: $$careSpace.loadCareSpaceFx.doneData,

  fn: workspace => workspace?.clients,

  target: $$careRecipient.$careRecipients
});

sample({
  clock: [
    savePersonalDetailsFx.doneData,
    saveCareBaseFx.doneData,
    saveSupportFx.doneData,
    saveExperienceFx.doneData,
    savePreferencesFx.doneData
  ],

  fn: client => client?.id,

  target: $$careRecipient.$careRecipientID
});

sample({
  clock: [
    personalDetails.open,
    personalDetails.close,
    $$careRecipient.getCareRecipientFx.doneData
  ],

  source: $$careRecipient.$careRecipientsProfile,

  fn: ({
    firstName,
    lastName,
    gender,
    imageUrl,
    postCode,
    city,
    addressLine1,
    addressLine2,
    phone,
    dateOfBirth
  }) => ({
    firstName,
    lastName,
    gender: gender?.id,
    postCode,
    imageUrl,
    city,
    addressLine1,
    addressLine2,
    phone,
    dateOfBirth
  }),

  target: $$profilePersonal.form.patch
});

sample({
  clock: [
    careBasics.open,
    careBasics.close,
    saveCareBaseFx.doneData,
    $$careRecipient.getCareRecipientFx.doneData
  ],

  source: $$careRecipient.$careRecipientsProfile,

  fn: ({ nightCareTypes, careTypes, hoursPerWeek, visitCareType }) => ({
    hoursPerWeek,
    nightCareTypes,
    careTypes,
    visitCareType
  }),

  target: $$profileCareBasics.form.patch
});

sample({
  clock: [
    conditionExperience.open,
    conditionExperience.close,
    saveExperienceFx.doneData,
    $$careRecipient.getCareRecipientFx.doneData
  ],

  source: $$careRecipient.$careRecipientsProfile,

  fn: ({ conditionsTypes, additionalDetails }) => ({
    conditionsTypes: conditionsTypes.map(item => item?.id),

    additionalDetails
  }),

  target: $$profileConditionExperience.form.patch
});

sample({
  clock: [
    preferences.open,
    preferences.close,
    savePreferencesFx.doneData,
    $$careRecipient.getCareRecipientFx.doneData
  ],

  source: $$careRecipient.$careRecipientsProfile,

  fn: ({ pets, languages, transportationHelp, carerGender, smoking }) => ({
    pets: pets.map(item => item?.id),

    languages,

    carerGender: carerGender?.id,

    transportationHelp: transportationHelp?.id,

    smoking
  }),

  target: $$profilePreferences.form.patch
});

sample({
  clock: [
    requiredSupport.open,
    requiredSupport.close,
    saveSupportFx.doneData,
    $$careRecipient.getCareRecipientFx.doneData
  ],

  source: $$careRecipient.$careRecipientsProfile,

  fn: ({ services, activityPerWeek }) => ({
    services: services.map(item => item?.id),

    activityPerWeek
  }),

  target: $$profileRequiredSupport.form.patch
});

sample({
  clock: uploadImageClicked,

  filter: file => file.size / (1024 * 1024) < 5,

  target: $$uploadFile.uploadFileFx
});

sample({
  clock: uploadImageClicked,

  filter: file => file.size / (1024 * 1024) > 5,

  fn: () =>
    ({
      id: ToastID.InvalidFileFormat,

      type: 'error',

      title: `File size is too big`,

      content: `<div style="word-break: break-word">Maximum allowed file size is ${5}MB</div>`,

      duration: 5000
    } as NotifyOptions),

  target: $$toast.notifyFx
});

sample({
  clock: $$uploadFile.uploadFileFx.doneData,

  fn: url => ({
    imageUrl: url
  }),

  target: $$profilePersonal.form.patch
});

export { $$profilePage };
