import { $$deleteAccount } from '@features/care-recipient/delete-account/model';

import { $$careRecipient } from '@entities/care-recipient';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { Modal } from '@shared/ui/atoms/modal';

import styles from './confirm.module.scss';

const Confirm = createView()
  .units({
    client: $$careRecipient.$careRecipientsProfile,

    onCancel: $$deleteAccount.closeModalClicked,

    onConfirm: $$deleteAccount.stepChanged
  })

  .view(({ client, onCancel, onConfirm }) => (
    <Modal.Container size='small' onClose={() => onCancel()}>
      <Modal.Header>
        Are you sure you want to delete {client?.firstName} {client?.lastName}’s
        account?
      </Modal.Header>

      <Modal.Body className={styles.content}>
        <p>
          We are sorry that you have decided to delete {client?.firstName}{' '}
          {client?.lastName}’s account.
        </p>

        <p>
          Before you go, please, review these{' '}
          <a href='https://google.com'>
            steps to take before deleting your account
          </a>{' '}
          to make sure your don’t lose any important details.
        </p>

        <p>
          If you need help, <a href='https://google.com'>contact us.</a>
        </p>
      </Modal.Body>

      <Modal.Footer className={styles.footer}>
        <Button
          palette='text'
          onClick={() => onCancel()}
          variant='text'
          className={styles.cancel}
        >
          Cancel
        </Button>

        <Button
          palette='error'
          className={styles.submit}
          onClick={() => onConfirm('reason')}
        >
          Yes, I want to delete
        </Button>
      </Modal.Footer>
    </Modal.Container>
  ));

export { Confirm };
