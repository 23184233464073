import { $$signUpPage } from '@pages/sign-up/sign-up.model';

import {
  SignUpNewsLetterAgreement,
  SignUpNewsLetterForm,
  SignUpNewsLetterNotSupportedBanner,
  SignUpNewsLetterSuccess,
  SignUpNewsletterSuccessBanner
} from '@features/newsletter/sign-up';

import { createView } from '@shared/lib/view';

import { WizardFormStep } from '@shared/ui/molecules/wizard-form-step';

import { styled } from '@shared/ui/stitches';

import { backToWebsiteClicked } from './not-supported-postcode.model';

const NotSupportedPostcode = createView()
  .units({
    onBackClick: backToWebsiteClicked,

    subscribed: $$signUpPage.$$signUpForNewsLetter.$subscribed,

    onSubmit: $$signUpPage.$$signUpForNewsLetter.form.submit
  })

  .view(({ onSubmit, subscribed, onBackClick }) => {
    if (subscribed) {
      return (
        <>
          <SignUpNewsletterSuccessBanner />

          <SignUpNewsLetterSuccess onClick={onBackClick} />
        </>
      );
    }

    return (
      <Container>
        <SignUpNewsLetterNotSupportedBanner />

        <WizardFormStep
          title='Sign up to our newsletter'
          captions={{ next: 'Stay updated' }}
          footer={<SignUpNewsLetterAgreement />}
          onGoNext={onSubmit}
        >
          <SignUpNewsLetterForm model={$$signUpPage.$$signUpForNewsLetter} />
        </WizardFormStep>
      </Container>
    );
  });

const Container = styled('div', {
  flex: 1,
  background: '#ffffff'
});

export { NotSupportedPostcode };
