import { InviteFooter } from '@pages/auth/pages/invite/ui/invite-footer/invite-footer.component';

import { $$invite } from '@features/auth/invite';

import { createView } from '@shared/lib/view';

import { CtaBanner } from '@shared/ui/atoms/cta-banner';

import { FormSection, FormSectionGroup } from '@shared/ui/atoms/form-section';

import { Input } from '@shared/ui/atoms/input';

import { useMedia } from '@shared/ui/atoms/media';

import { WizardFormStep } from '@shared/ui/molecules/wizard-form-step';

import { Field, useField } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { $$invitePage } from '../invite.model';

import styles from './invite.module.scss';

const InvitePage = createView()
  .units({
    userInfo: $$invitePage.$userInfo
  })

  .map(() => {
    const sm = useMedia('<sm');

    const { shouldShowValidation, hasError, error } = useField(
      $$invite.form.fields.password
    );

    const passwordInValid = error && hasError && shouldShowValidation;

    return {
      sm,

      passwordInValid
    };
  })

  .view(({ userInfo, sm, passwordInValid }) => (
    <Form use={$$invite.form}>
      <CtaBanner>
        <p>
          Welcome to Carers HQ!
          <br /> Let’s create your account in simple way.
        </p>
      </CtaBanner>

      <div className={styles.container}>
        <WizardFormStep
          title='Create Account'
          captions={{
            next: sm ? 'Sign Up' : 'Sign Up and Continue'
          }}
          className={styles.box}
          footer={<InviteFooter />}
          onGoNext={() => $$invite.form.submit()}
        >
          <FormSectionGroup className={styles.group}>
            <FormSection
              title='Email'
              tip='We use the address to which the invitation was sent.'
            >
              <Input value={userInfo?.email} onChange={() => {}} disabled />
            </FormSection>

            <div className={styles.flexContainer}>
              <div className={styles.item}>
                <Input
                  bold
                  label='Contact for'
                  value={userInfo?.relations[0]?.client}
                  onChange={() => {}}
                  disabled
                />
              </div>

              <div className={styles.item}>
                <Input
                  bold
                  label='Relationship to care recipient'
                  value={userInfo?.relations[0]?.relation}
                  onChange={() => {}}
                  disabled
                />
              </div>
            </div>

            <Field.Input
              bold
              label='Password'
              nowrap
              wrapperClassName={styles.input}
              subTitle={
                !passwordInValid &&
                'Should be at least 6 digits long and contain numbers and uppercase.'
              }
              type='password'
              use={$$invite.form.fields.password}
            />

            <Field.Input
              bold
              nowrap
              wrapperClassName={styles.input}
              label='Confirm password'
              type='password'
              use={$$invite.form.fields.confirmPassword}
            />

            <div className={styles.flexContainer}>
              <div className={styles.item}>
                <Field.Input
                  bold
                  label='First name'
                  use={$$invite.form.fields.firstName}
                />
              </div>

              <div className={styles.item}>
                <Field.Input
                  bold
                  label='Last name'
                  use={$$invite.form.fields.lastName}
                />
              </div>
            </div>

            <div className={styles.phone}>
              <Field.PhoneInput
                bold
                label='Your contact phone'
                use={$$invite.form.fields.phone}
              />
            </div>
          </FormSectionGroup>
        </WizardFormStep>
      </div>
    </Form>
  ));

export { InvitePage };
