import { attach, createEvent, sample, split } from 'effector';

import { not, some } from 'patronum';

import { $$carerShortlist } from '@features/carer/shortlist';

import { $$careRecipient } from '@entities/care-recipient';

import { $$teamCarers } from '@entities/carer';

import { carersHQ } from '@shared/api';

import { ToastID } from '@shared/config';

import { routes } from '@shared/routes';

import type { NotifyOptions } from '@shared/ui/organisms/toasts';

import { $$toast } from '@shared/ui/organisms/toasts';

const tabChanged = createEvent<string>();

const getCarersFx = attach({
  effect: carersHQ.clients.getClientCarers,

  source: {
    id: $$careRecipient.$careRecipientID
  },

  mapParams: (
    type: Parameters<typeof carersHQ.clients.getClientCarers>[0]['type'],
    { id }
  ) => ({
    id,
    type
  })
});

const $$teamPage = {
  tabChanged
};

split({
  source: tabChanged,

  match: (tab: string) => tab,

  cases: {
    my: routes.team.my.open,
    connections: routes.team.connections.open,
    shortlist: routes.team.shortlist.open
  }
});

sample({
  clock: routes.team.base.opened,

  source: not(
    some({
      predicate: true,

      stores: [
        routes.team.connections.$isOpened,
        routes.team.my.$isOpened,
        routes.team.shortlist.$isOpened
      ]
    })
  ),

  target: routes.team.my.open.prepend(() => ({}))
});

sample({
  clock: routes.team.my.opened,

  target: getCarersFx.prepend(() => 'team')
});

sample({
  clock: routes.team.connections.opened,

  target: getCarersFx.prepend(() => 'connections')
});

sample({
  clock: routes.team.shortlist.opened,

  target: getCarersFx.prepend(() => 'shortlist')
});

sample({
  clock: getCarersFx.done,

  filter: ({ params }) => params == 'team',

  fn: ({ result }) => result.data,

  target: $$teamCarers.$team
});

sample({
  clock: getCarersFx.done,

  filter: ({ params }) => params == 'shortlist',

  fn: ({ result }) => result.data,

  target: $$teamCarers.$shortlist
});

sample({
  clock: getCarersFx.done,

  filter: ({ params }) => params == 'connections',

  fn: ({ result }) => result.data,

  target: $$teamCarers.$connections
});

sample({
  clock: $$teamCarers.removeConnectionFx.doneData,

  fn: ({ firstName, lastName }) =>
    ({
      id: ToastID.RemovedFromConnectedList,

      title: `<b style="word-break: break-all">${firstName} ${lastName}</b> was removed from your connected list.`,

      content: `You can add <b style="word-break: break-all">${firstName}</b> again later`
    } as NotifyOptions),

  target: $$toast.notifyFx
});

$$teamCarers.$shortlist.on(
  $$carerShortlist.removeFromFx.done,
  (state, { params: { carerId } }) => state.filter(item => item.id != carerId)
);

export { $$teamPage };
