import { createEffect, createEvent, createStore, sample } from 'effector';

import { $$openAddressOnMap } from '@features/address/open-on-map';

import type { Carer } from '@shared/api';

import { carersHQ } from '@shared/api';

const getCarerPublicProfileFx = createEffect<Carer['id'], Carer>();

const viewProfileOpened = createEvent<Carer['id']>();

const viewProfileClosed = createEvent();

const locationClicked = createEvent();

const $visible = createStore(false);

const $carerProfile = createStore<Carer | null>(null);

const $$carerProfile = {
  $visible,
  $carerProfile,

  locationClicked,
  viewProfileClosed,
  viewProfileOpened,

  getCarerPublicProfileFx
};

sample({
  clock: viewProfileOpened,

  target: getCarerPublicProfileFx
});

sample({
  clock: locationClicked,

  source: $carerProfile,

  fn: carer => carer as Required<Carer>,

  target: $$openAddressOnMap.openFullAddressFx
});

$visible.on(viewProfileOpened, () => true).reset(viewProfileClosed);

$carerProfile
  .on(getCarerPublicProfileFx.doneData, (_, payload) => payload)

  .reset(viewProfileClosed);

getCarerPublicProfileFx.use(async id => {
  const { data } = await carersHQ.carer.get({ id });

  return data;
});

export { $$carerProfile };
