import { ReactComponent as Check } from '@icon/check.svg';

import { ReactComponent as Arrow } from '@icon/select-arrow-down-light-blue.svg';

import { $$dropdown } from '@layouts/main-page/model';

import classNames from 'classnames';

import { combine } from 'effector';

import type { FC, ReactNode } from 'react';

import { $$selectCareSpace } from '@features/care-space/select';

import { $$careSpace } from '@entities/care-space';

import { $$user } from '@entities/user';

import type { AccountWorkspace } from '@shared/api';

import { createView } from '@shared/lib/view';

import { Chip } from '@shared/ui/atoms/chip';

import { Line } from '../line';

import { $$selectedCareSpace } from './care-space.model';

import styles from './care-space.module.scss';

type CareSpaceItemProps = {
  title: string;

  className?: string;

  subTitle: string;

  isOwner?: boolean;

  indicators?: ReactNode;

  highlighted?: ReactNode;

  onClick: () => void;
};

type ListProps = {
  spaces: AccountWorkspace[];

  className?: string;

  indicators?: (item: AccountWorkspace, selected: boolean) => ReactNode;
};

const List = createView<ListProps>()
  .units({
    account: $$user.$account,

    selected: $$careSpace.$careSpaceID,

    onClick: $$selectCareSpace.careSpaceClicked
  })

  .view(({ account, className, spaces, indicators, selected, onClick }) => (
    <>
      {spaces.map(item => (
        <CareSpaceItem
          className={className}
          key={item?.workspace?.id}
          title={`${item?.workspace?.owner?.firstName ?? ''}'s ${
            item?.workspace?.owner?.lastName.charAt(0).toUpperCase() ?? ''
          }. care space`}
          subTitle={item?.workspace?.owner?.email}
          indicators={indicators?.(item, selected == item?.workspace?.id)}
          onClick={() => onClick(item?.workspace?.id)}
          isOwner={account?.id == item?.workspace?.owner?.id}
        />
      ))}
    </>
  ));

const Selected = createView<Pick<CareSpaceItemProps, 'indicators'>>()
  .units({
    account: $$user.$account,

    space: $$careSpace.$careSpace,

    expanded: $$dropdown.$careSpacesOpened,

    onClick: $$selectedCareSpace.clicked
  })

  .view(({ account, space, expanded, onClick, indicators }) => (
    <CareSpaceItem
      className={styles.selected}
      highlighted={expanded}
      title={`${space?.owner?.firstName ?? ''}'s ${
        space?.owner?.lastName.charAt(0).toUpperCase() ?? ''
      }. care space`}
      subTitle={space?.owner?.email}
      onClick={onClick}
      indicators={indicators}
      isOwner={account?.id == space?.owner?.id}
    />
  ));

const Mobile = createView()
  .units({
    expanded: $$dropdown.$careSpacesOpened,

    spaces: combine(
      $$careSpace.$careSpaceID,
      $$careSpace.$activeAccountCareSpaces,
      (id, spaces) => spaces.filter(item => item.workspace.id != id)
    )
  })

  .view(({ spaces, expanded }) => (
    <div
      className={classNames(styles.careSpaces, {
        [styles.expandedList]: expanded
      })}
    >
      <Selected
        indicators={
          <>
            {expanded && <Check className={styles.check} />}

            <Arrow
              className={classNames(styles.arrow, {
                [styles.inverted]: expanded
              })}
            />
          </>
        }
      />

      {expanded && (
        <List className={styles.mobileNotSelected} spaces={spaces} />
      )}

      <div
        className={classNames(styles.spacing, {
          [styles.expandedLine]: expanded
        })}
      >
        <Line />
      </div>
    </div>
  ));

const Desktop = createView()
  .units({
    spaces: combine(
      $$careSpace.$careSpaceID,

      $$careSpace.$activeAccountCareSpaces,

      (selected, spaces) =>
        [...(spaces ?? [])].sort((a, b) => {
          if (a.workspace.id === selected) return -1;

          if (b.workspace.id === selected) return 1;

          return 0;
        })
    ),

    opened: $$dropdown.$careSpacesOpened
  })

  .view(({ spaces, opened }) => (
    <>
      <div className={styles.desktop}>
        <Selected indicators={<Arrow className={classNames(styles.arrow)} />} />

        {opened && (
          <ul className={styles.listBox}>
            <List
              className={styles.listBoxItem}
              spaces={spaces}
              indicators={(_, selected) =>
                selected ? <Check className={styles.check} /> : null
              }
            />
          </ul>
        )}
      </div>

      <div className={styles.spacing}>
        <Line />
      </div>
    </>
  ));

const CareSpaceItem: FC<CareSpaceItemProps> = ({
  title,
  isOwner,
  onClick,
  subTitle,
  className,
  indicators,
  highlighted
}) => (
  <div
    className={classNames(
      styles.careSpaceItem,
      {
        [styles.highlighted]: highlighted
      },
      className
    )}
    onClick={onClick}
  >
    <div className={styles.careSpaceItemInfo}>
      <div className={styles.careSpaceItemTitle}>
        <div>{title}</div>

        {isOwner && (
          <Chip size='md' palette='filledPeach'>
            Owner
          </Chip>
        )}
      </div>

      <div className={styles.careSpaceItemSubTitle}>{subTitle}</div>
    </div>

    {indicators && (
      <div className={styles.careSpaceItemIndicators}>{indicators}</div>
    )}
  </div>
);

const CareSpace = {
  Mobile,
  Desktop
};

export { CareSpace };
