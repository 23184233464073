import { createEvent, createStore, sample } from 'effector';

import { object, string } from 'yup';

import { $$billing } from '@entities/billing';

import { createForm } from '@shared/lib/form';

import { $$shared } from './shared';

const setPassword = createEvent<string>();

const $password = createStore('');

const $incorrect = createStore(false);

const passwordDetailsForm = createForm({
  initialValues: {
    password: ''
  },

  $errors: $incorrect.map(incorrect =>
    incorrect
      ? { password: `Password isn't correct. Check it and try again` }
      : ({} as any)
  ),

  $schema: createStore(
    object({
      password: string().required('Required').label('Password').nullable()
    })
  )
});

const $$passwordDetails = {
  $password,

  setPassword,

  passwordDetailsForm
};

sample({
  clock: passwordDetailsForm.submitted,

  fn: ({ password }) => password,

  target: [$$billing.getFullBillingDetailsFx, setPassword]
});

sample({
  clock: $$shared.billingDetailsClosed,

  target: passwordDetailsForm.reset
});

$incorrect
  .on($$billing.getFullBillingDetailsFx.failData, () => true)

  .reset($$billing.getFullBillingDetailsFx.done, $$shared.$visible.updates);

$password
  .on(setPassword, (_, payload) => payload)

  .reset($$billing.updateBillingDetailsFx.done);

export { $$passwordDetails, passwordDetailsForm };
