import avatarPlaceholder from '@img/avatar-placeholder.png';

import classNames from 'classnames';

import moment from 'moment';

import { $$careRecipientProfile } from '@features/care-recipient/view-care-recipient-profile/model';

import {
  CareType,
  nightCareTypeNames,
  VisitCareType,
  visitCareTypeNames
} from '@shared/api';

import { months } from '@shared/lib/utils';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { Modal } from '@shared/ui/atoms/modal';

import { TextPair } from '@shared/ui/atoms/text-pair';

import styles from './view-care-recipient-profile.module.scss';

const ViewCareRecipientProfile = createView()
  .units({
    client: $$careRecipientProfile.$careRecipientProfile,

    visible: $$careRecipientProfile.$visible,

    onClose: $$careRecipientProfile.viewProfileClosed,

    onShowMapClick: $$careRecipientProfile.showMapClicked
  })

  .map(({ client }) => {
    const address = (
      client?.isFullDataView
        ? [
            client.addressLine1,
            client.addressLine2,
            client.city,
            client.postCode
          ]
        : [`Near ${client?.district}`, client?.city]
    )
      .map(item => item)
      .join(', ');

    const dateOfBirth = client ? moment(client.dateOfBirth) : null;

    return {
      address,
      dateOfBirth
    };
  })

  .view(
    ({ client, onClose, onShowMapClick, address, dateOfBirth, visible }) => {
      if (!client && !visible) return null;

      const careIsFor = (
        <section className={classNames(styles.section, styles.careIsFor)}>
          <h4 className={styles.sectionTitle}>Care is for</h4>

          <div className={styles.photo}>
            <img src={avatarPlaceholder} />
          </div>

          <div className={styles.sectionFields}>
            <TextPair
              className={styles.fullName}
              label='Full name:'
              wrap
              value={`${client?.firstName} ${client?.lastName}${
                client?.isFullDataView ? '' : '.'
              }`}
            />

            <TextPair
              className={styles.gender}
              label='Gender:'
              value={client?.gender?.name}
            />

            <TextPair
              className={styles.age}
              label={client?.isFullDataView ? 'Date of birth:' : 'Age:'}
              value={
                client?.isFullDataView ? (
                  <>
                    <span>
                      {dateOfBirth.format(
                        `DD [${months[dateOfBirth.month()]}] YYYY`
                      )}
                    </span>

                    <span className={styles.year}> ({client?.age} years)</span>
                  </>
                ) : (
                  `${client?.age} years old`
                )
              }
            />

            <TextPair
              className={styles.address}
              label='Address:'
              value={address}
              wrap
            >
              <div
                className={styles.showOnAMap}
                onClick={() => onShowMapClick()}
              >
                Show on a map
              </div>
            </TextPair>
          </div>
        </section>
      );

      const careNeeds = (
        <section className={styles.section}>
          <h4 className={styles.sectionTitle}>Care needs</h4>

          <div className={styles.sectionFields}>
            <TextPair
              label='Required care support:'
              value={
                client?.services?.map(item => item.name).join(', ') ?? 'None'
              }
            />

            <TextPair
              label='Required care:'
              value={
                [
                  client?.visitCareType == VisitCareType.LiveInCare
                    ? visitCareTypeNames[VisitCareType.LiveInCare]
                    : null,

                  client?.careTypes?.some(one => one == CareType.DayCare)
                    ? 'Day care'
                    : null,

                  ...(client?.careTypes?.some(one => one == CareType.NightCare)
                    ? (client?.nightCareTypes ?? []).map(
                        item => nightCareTypeNames[item]
                      )
                    : [])
                ]
                  .filter(item => item)
                  ?.join(', ') || '-'
              }
            />
          </div>
        </section>
      );

      const specialisedCareNeeds = (
        <section className={styles.section}>
          <h4 className={styles.sectionTitle}>Specialised care needs</h4>

          <div className={styles.sectionFields}>
            <TextPair
              label='Required condition experience:'
              value={client?.conditionsTypes?.map(item => item.name).join(', ')}
              spacing={
                client?.conditionsTypes ?? [].length > 1
                  ? 'multiline'
                  : 'normal'
              }
            />

            {client?.additionalDetails && (
              <TextPair
                label='Additional details:'
                wrap
                value={client?.additionalDetails}
                spacing='multiline'
              />
            )}
          </div>
        </section>
      );

      const preferences = (
        <section className={styles.section}>
          <h4 className={styles.sectionTitle}>Preferences</h4>

          <div className={styles.sectionFields}>
            <TextPair
              label='Language:'
              value={client?.languages?.map(item => item.name).join(', ')}
            />

            <TextPair
              label='Gender preferences:'
              value={client?.carerGender?.name ?? 'None'}
            />

            <TextPair
              label='Transportations needs:'
              value={client?.transportationHelp?.name ?? 'None'}
            />

            <TextPair
              label='Smoking in the house:'
              value={client?.smoking ? 'Yes' : 'No'}
            />

            <TextPair
              label='Pets in the house:'
              value={client?.pets?.map(item => item.name).join(', ') ?? 'None'}
            />
          </div>
        </section>
      );

      const contactPersonDetails = (
        <div className={styles.contactPersonDetailsContainer}>
          <section
            className={classNames(styles.section, styles.contactPersonDetails)}
          >
            <h4 className={styles.sectionTitle}>Contact person details</h4>

            <div className={styles.sectionFields}>
              <TextPair
                className={styles.contactPerson}
                label='Full name:'
                wrap
                value={`${client?.contactPerson[0]?.firstName} ${
                  client?.contactPerson[0]?.lastName
                }${client?.isFullDataView ? '' : '.'}`}
              >
                <div className={styles.holder}>Account holder</div>
              </TextPair>

              {client?.contactPerson[0] && (
                <>
                  {client?.contactPerson[0]?.relation?.clientRelation?.name && (
                    <TextPair
                      className={styles.relationship}
                      label='Relationship to care recipient:'
                      value={
                        client?.contactPerson[0]?.relation?.clientRelation?.name
                      }
                    />
                  )}

                  <TextPair
                    label='Full name:'
                    wrap
                    value={[
                      client?.contactPerson[0]?.firstName,
                      client?.contactPerson[0]?.lastName
                    ]
                      .filter(item => item)
                      .join(' ')}
                  />
                </>
              )}
            </div>
          </section>
        </div>
      );

      return (
        <Modal.Container onClose={onClose} drawer>
          <Modal.Body className={styles.content} drawer>
            <div className={styles.sections}>
              {careIsFor}

              {careNeeds}

              {specialisedCareNeeds}

              {preferences}
            </div>

            {contactPersonDetails}
          </Modal.Body>

          <Modal.Footer drawer className={styles.footer}>
            <Button className={styles.message}>
              Message {client?.firstName}
            </Button>
            <Button palette='text' onClick={() => onClose()}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal.Container>
      );
    }
  );

export { ViewCareRecipientProfile };
