import { createEvent, createStore, sample } from 'effector';

import { object, string } from 'yup';

import { $$loadClientData } from '@processes/boot';

import { $$careRecipient } from '@entities/care-recipient';

import { $$user } from '@entities/user';

import { ToastID } from '@shared/config';

import { createForm } from '@shared/lib/form';

import type { NotifyOptions } from '@shared/ui/organisms/toasts';

import { $$toast } from '@shared/ui/organisms/toasts';

import { ToastText } from './ui/toast';

const $error = createStore(false);

const reasonForm = createForm({
  initialValues: {
    reason: ''
  },

  $schema: createStore(
    object({
      reason: string().required('').nullable()
    })
  )
});

const passwordForm = createForm({
  initialValues: {
    password: ''
  },

  $errors: $error.map(incorrect =>
    incorrect ? { password: `is incorrect` } : ({} as any)
  ),

  $schema: createStore(
    object({
      password: string().required().nullable()
    })
  )
});

const openModalClicked = createEvent();

const closeModalClicked = createEvent();

const restoreClicked = createEvent<string>();

const stepChanged = createEvent<'confirm' | 'reason' | 'password'>();

const $step = createStore<'confirm' | 'reason' | 'password'>('confirm');

const $reason = createStore<string>(null);

const $visible = createStore(false);

const $$deleteAccount = {
  reasonForm,

  passwordForm,

  $step,

  $reason,

  $visible,

  openModalClicked,

  restoreClicked,

  closeModalClicked,

  stepChanged
};

sample({
  clock: passwordForm.submitted,

  source: {
    client: $$careRecipient.$careRecipientsProfile,
    reason: $reason
  },

  fn: ({ reason, client }, { password }) => ({
    id: client.id,
    reason: reason,
    password: password
  }),

  target: $$careRecipient.deleteClientFx
});

sample({
  clock: restoreClicked,

  fn: id => id,

  target: $$careRecipient.restoreClientFx
});

sample({
  clock: reasonForm.submitted,

  fn: ({ reason }) => reason,

  target: $reason
});

sample({
  clock: reasonForm.submitted,

  fn: () => 'password' as any,

  target: stepChanged
});

sample({
  clock: closeModalClicked,

  fn: () => {},

  target: [reasonForm.reset, passwordForm.reset]
});

sample({
  clock: $$careRecipient.restoreClientFx.doneData,

  source: {
    recipientProfile: $$careRecipient.$careRecipientsProfile
  },

  fn: ({ recipientProfile }) =>
    ({
      id: ToastID.CareRecipientRestored,

      title: `<div style="word-break: break-all">${recipientProfile?.firstName} ${recipientProfile?.lastName} account has been restored.</div>`,

      content: ToastText,

      type: 'success'
    } as NotifyOptions),

  target: $$toast.notifyFx
});

sample({
  clock: $$careRecipient.deleteClientFx.doneData,

  target: closeModalClicked
});

sample({
  clock: [
    $$careRecipient.deleteClientFx.doneData,
    $$careRecipient.restoreClientFx.doneData
  ],

  source: $$user.$account,

  fn: account => account,

  target: $$loadClientData.load
});

sample({
  clock: $$careRecipient.deleteClientFx.done,

  fn: ({ params: { id } }) => id,

  target: $$careRecipient.getCareRecipientFx
});

sample({
  clock: $$careRecipient.restoreClientFx.done,

  fn: ({ params }) => params,

  target: $$careRecipient.getCareRecipientFx
});

$error
  .on($$careRecipient.deleteClientFx.failData, () => true)

  .reset(
    closeModalClicked,
    $$careRecipient.deleteClientFx.done,
    passwordForm.$touched
  );

$step.on(stepChanged, (_state, payload) => payload).reset(closeModalClicked);

$visible.on(openModalClicked, () => true).reset(closeModalClicked);

export { $$deleteAccount };
