import { createStore } from 'effector';

import { modelFactory } from 'effector-factorio';

import { number, object, string } from 'yup';

import type { Gender } from '@shared/api';

import { createForm } from '@shared/lib/form';

import { nameRegEx, testMaxDate, testMinDate } from '@shared/lib/utils';

import { maxBirthDate, minBirthDate } from './lib';

type PersonalInformationValues = {
  lastName: string;
  firstName: string;
  dateOfBirth: string;
  gender: Gender['id'];
};

const ManagePersonalInformationModelFactory = modelFactory(() => {
  const form = createForm<PersonalInformationValues>({
    initialValues: {
      firstName: '',

      lastName: '',

      dateOfBirth: null,

      gender: 1
    },

    $schema: createStore(
      object({
        firstName: string()
          .required()

          .nullable()

          .matches(nameRegEx, 'Invalid')

          .trim('Required'),

        lastName: string()
          .required()

          .nullable()

          .matches(nameRegEx, 'Invalid')

          .trim('Required'),

        dateOfBirth: string()
          .required()

          .nullable()

          .test('min-date', 'Maximum age is 120', testMinDate(minBirthDate))

          .test('max-date', 'Minimal age is 18', testMaxDate(maxBirthDate)),

        gender: number().required().nullable()
      })
    )
  });

  return {
    form
  };
});

export type { PersonalInformationValues };

export { ManagePersonalInformationModelFactory };
