import { createEffect, createEvent, createStore, sample } from 'effector';

import { condition } from 'patronum';

import { $$carerShortlist } from '@features/carer/shortlist';

import { $$user } from '@entities/user';

import { carersHQ } from '@shared/api';

import type { CarerMatch, Client, PaginatedData } from '@shared/api';

import { routes } from '@shared/routes';

type GetCarerMatchesOptions = {
  id: Client['id'];
  page: number;
  shortNotice: boolean;
};

const getCarerMatchesFx = createEffect<
  GetCarerMatchesOptions,
  PaginatedData<CarerMatch> & { page: number }
>();

const loaded = createEvent<PaginatedData<CarerMatch> & { page: number }>();

const $matches = createStore<CarerMatch[]>([]);

const $total = createStore<number>(0);

const likeClicked = createEvent<CarerMatch>();

const $$carerMatches = {
  $matches,
  $total,

  loaded,
  likeClicked,

  getCarerMatchesFx
};

sample({
  clock: routes.matches.opened,

  source: $$user.$account,

  filter: account => account?.status === 'deleted',

  target: routes.schedule.open
});

condition({
  source: likeClicked,

  if: (carer: CarerMatch) => carer.inShortList,

  then: $$carerShortlist.removeFromShortList,

  else: $$carerShortlist.addToShortList
});

getCarerMatchesFx.use(async payload => {
  const response = await carersHQ.carer.matches({
    ...payload,

    size: 10
  });

  return {
    ...response.data,
    page: payload.page
  };
});

$matches
  .on(loaded, (state, payload) =>
    payload.page == 0 ? payload.data : [...state, ...payload.data]
  )

  .on($$carerShortlist.addToFx.done, (state, { params: { carerId } }) =>
    state.map(item =>
      item.id == carerId
        ? {
            ...item,

            inShortList: true
          }
        : item
    )
  )

  .on($$carerShortlist.removeFromFx.done, (state, { params: { carerId } }) =>
    state.map(item =>
      item.id == carerId
        ? {
            ...item,

            inShortList: false
          }
        : item
    )
  );

$total.on(loaded, (_, payload) => payload.total);

export { $$carerMatches };
