import { $$logout } from '@processes/logout';

import {
  $$transferOwnerShip,
  ModalSteps
} from '@features/account/transfer-ownership';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { useMedia } from '@shared/ui/atoms/media';

import { Modal } from '@shared/ui/atoms/modal';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import styles from './credentials.module.scss';

type CredentialsValues = {
  email: string;
  password: string;
};

const Credentials = createView()
  .units({
    onCancel: $$transferOwnerShip.modalClosed,

    onLogout: $$logout.loggedOut,

    stepChanged: $$transferOwnerShip.stepChanged
  })
  .map(() => {
    const md = useMedia('>=md');

    return {
      md
    };
  })

  .view(({ md, onCancel, stepChanged, onLogout }) => (
    <Modal.Container onClose={() => onCancel()} size='small'>
      <Modal.Header>Transfer ownership</Modal.Header>

      <Modal.Body>
        <div className={styles.content}>
          <p className={styles.info}>
            To transfer ownership you need to enter your email and account
            password. <br className={styles.brTwo} />
            <a
              className={styles.link}
              onClick={() => {
                onCancel();
                onLogout('recovery');
              }}
            >
              Forgot your password? Click here.
            </a>
          </p>

          <Form
            className={styles.form}
            use={$$transferOwnerShip.credentialForm}
          >
            <Field.Input
              use={$$transferOwnerShip.credentialForm.fields.email}
              wrapperClassName={styles.email}
              type='email'
              label='Email'
              name='email'
              disableAutocomplete
              placeholder='Enter'
              errorIn='label'
            />

            <Field.Input
              use={$$transferOwnerShip.credentialForm.fields.password}
              wrapperClassName={styles.password}
              type='password'
              label='Password'
              name='password'
              disableAutocomplete
              placeholder='Enter'
              errorIn='label'
            />
          </Form>
        </div>
      </Modal.Body>

      <Modal.Footer space className={styles.footer}>
        <Button
          palette='text'
          variant='text'
          onClick={() => stepChanged(ModalSteps.SelectMember)}
        >
          {!md ? 'Previous' : 'Previous step'}
        </Button>

        <div className={styles.group}>
          <Button
            palette='text'
            variant='text'
            onClick={() => onCancel()}
            className={styles.cancel}
          >
            Cancel
          </Button>

          <Button onClick={() => $$transferOwnerShip.credentialForm.submit()}>
            Continue
          </Button>
        </div>
      </Modal.Footer>
    </Modal.Container>
  ));

export { Credentials };
