import { DashboardMarkTypes } from '@shared/api';

type ChartData = {
  title: string;
  description: string;
  labelColor: string;
  barColor: string;
  data: { name: string; day: string; value: number }[];
  active?: boolean;
  type?: DashboardMarkTypes;
  maxLength?: number;
  interval?: number;
};

const initialCharts: ChartData[] = [
  {
    title: 'Mood',
    description: 'how are you feeling today on a scale from 0 to 10',
    barColor: '#FDDF98',
    labelColor: '#FEF7E7',
    data: null,
    active: false,
    type: DashboardMarkTypes.Mood
  },
  {
    title: 'Sleep',
    description: 'how well did you sleep last night on a scale from 0 to 10',
    barColor: '#8BAEDC',
    labelColor: '#F1F7FF',
    data: null,
    active: false,
    type: DashboardMarkTypes.Sleep
  },
  {
    title: 'Pain',
    description: 'how is your pain today on a scale from 0 to 10',
    barColor: '#F08888',
    labelColor: '#FFEAEA',
    data: null,
    active: false,
    type: DashboardMarkTypes.Pain
  },
  {
    title: 'Meals',
    description: 'how many meals did you eat yesterday?',
    barColor: '#A7E7C5',
    labelColor: '#E5FFF4',
    data: null,
    active: false,
    type: DashboardMarkTypes.Meals,
    maxLength: 5,
    interval: 0
  },
  {
    title: 'Bowel movements',
    description: 'did you have a BM in the last 24 hours?',
    barColor: '',
    labelColor: '#F6F6F7',
    data: null,
    active: false,
    type: DashboardMarkTypes.BowelMovements
  }
];

export { initialCharts };

export type { ChartData };
