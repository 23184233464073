import avatar from '@img/avatar-placeholder.png';

import classNames from 'classnames';

import moment from 'moment';

import { $$deletedAccount } from '@entities/deleted-account';

import {
  InvoiceStatus,
  invoiceStatusColor,
  invoiceStatusNames,
  supportTypeColor,
  SupportTypeId
} from '@shared/api';

import { monthsShort } from '@shared/lib/utils';

import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { Breadcrumbs } from '@shared/ui/atoms/breadcrumbs';

import { Button } from '@shared/ui/atoms/button';

import { Card } from '@shared/ui/atoms/card';

import { Chip } from '@shared/ui/atoms/chip';

import type { VariantsPalette } from '@shared/ui/atoms/chip/chip.props';

import { useMedia } from '@shared/ui/atoms/media';

import { Cell, Row, Table, TBody, THead } from '@shared/ui/atoms/table';

import { TextPair } from '@shared/ui/atoms/text-pair';

import type { TextPairProps } from '@shared/ui/atoms/text-pair';

import { $$personInvoice } from './person-invoice.model';

import styles from './person-invoice.module.scss';

const mocks = [
  {
    status: InvoiceStatus.Unpaid
  },

  {
    status: InvoiceStatus.InProgress
  },

  {
    status: InvoiceStatus.Refunded
  },

  {
    status: InvoiceStatus.Paid
  }
];

const visits = [1, 2, 3, 4, 5, 6, 1231, 21, 31, 312, 31, 231, 23, 1, 3];

/**
 * <PersonInvoice />
 */
const PersonInvoice = createView()
  .units({
    invoice: $$deletedAccount.$invoice,

    id: $$personInvoice.$id
  })

  .map(({ invoice }) => {
    const md = useMedia('>=md');

    const lg = useMedia('>=lg');

    const startDate = moment(invoice?.startDate);

    const endDate = moment(invoice?.endDate);

    const period = `${startDate.format(
      `DD [${monthsShort[startDate.month()]}] yyyy`
    )} - ${endDate.format(`DD [${monthsShort[endDate.month()]}] yyyy`)}`;

    const spacing: TextPairProps['spacing'] = !md || lg ? 'spread' : 'normal';

    return {
      md,

      spacing,

      period
    };
  })

  .view(({ md, spacing, invoice, period, id }) => (
    <div className={styles.personInvoices}>
      <div className={styles.header}>
        <Breadcrumbs
          className={styles.breadcrumbs}
          steps={[
            { label: 'Account', route: routes.deletedAccount.home },
            { label: 'Invoices', route: routes.deletedAccount.invoices },
            {
              label: `${invoice?.carer?.firstName} ${invoice?.carer?.lastName}`,
              route: routes.deletedAccount.personInvoice,
              params: { id }
            }
          ]}
        />

        <Button className={styles.export} palette='secondary'>
          Export PDF file
        </Button>
      </div>

      <Card className={styles.invoiceCard}>
        <div className={styles.invoiceCardHeader}>
          <img
            className={styles.invoiceCardPhoto}
            src={invoice?.carer?.imageUrl || avatar}
          />

          <h4 className={styles.invoiceCardName}>
            {invoice?.carer?.firstName} {invoice.carer.lastName}
          </h4>

          <Chip
            className={styles.invoiceCardStatus}
            palette={
              invoiceStatusColor[InvoiceStatus.Unpaid] as VariantsPalette
            }
          >
            {invoiceStatusNames[InvoiceStatus.Unpaid]}
          </Chip>
        </div>

        <div className={styles.invoiceCardContent}>
          <section
            className={classNames(
              styles.invoiceCardSection,
              styles.invoiceCardSectionMain
            )}
          >
            <h5 className={styles.invoiceCardSectionTitle}>Main details</h5>

            <TextPair spacing={spacing} label='Period:' value={period} />

            <TextPair
              spacing={spacing}
              label='Day care:'
              value={`${invoice?.totalHours} hr`}
            />

            <TextPair spacing={spacing} label='Night care:' value='1 night' />
          </section>

          <section
            className={classNames(
              styles.invoiceCardSection,
              styles.invoiceCardSectionBill
            )}
          >
            <h5 className={styles.invoiceCardSectionTitle}>Bill details</h5>

            <TextPair
              spacing={spacing}
              label='Subtotal:'
              value={<span className={styles.bold}>£{invoice?.earnings}</span>}
            />

            <TextPair
              spacing={spacing}
              label='VAT:'
              value={<span className={styles.bold}>£{invoice?.vat}</span>}
            />
          </section>

          <section
            className={classNames(
              styles.invoiceCardSection,
              styles.invoiceCardSectionTotal
            )}
          >
            <TextPair
              className={styles.grandTotal}
              spacing={spacing}
              label='Grand total:'
              value={
                <div className={styles.grandTotalText}>
                  £{invoice?.grandTotal}
                </div>
              }
            />
          </section>
        </div>

        {md && (
          <div className={styles.invoiceCardTableContainer}>
            <Table className={styles.visitTable}>
              <THead>
                <Row className={classNames(styles.row, styles.tHead)}>
                  <Cell className={styles.visitTableDate}>Date</Cell>

                  <Cell className={styles.visitTableCareType}>Care type</Cell>

                  <Cell className={styles.visitTableDuration}>Duration</Cell>

                  <Cell className={styles.visitTablePrice}>Price</Cell>

                  <Cell className={styles.visitTableAmount} align='right'>
                    Amount
                  </Cell>
                </Row>
              </THead>

              <TBody>
                {invoice?.visits.map((_, index) => (
                  <Row className={styles.row} key={index}>
                    <Cell subText='9:00 - 18:30'>Wed, 29 Aug 2020</Cell>

                    <Cell>
                      <Chip
                        className={styles.visitTableCareTypeChip}
                        palette={
                          supportTypeColor[
                            SupportTypeId.DayCare
                          ] as VariantsPalette
                        }
                      >
                        Day care
                      </Chip>
                    </Cell>

                    <Cell>7 hours</Cell>

                    <Cell subText='Holiday'>£24 / hr</Cell>

                    <Cell bold align='right'>
                      £256
                    </Cell>
                  </Row>
                ))}
              </TBody>
            </Table>
          </div>
        )}
      </Card>

      {!md && (
        <div className={styles.visitCards}>
          {mocks.map(() => (
            <Card
              className={styles.visitCard}
              key={Math.random() + Math.random()}
            >
              <div className={styles.visitCardHeader}>
                <TextPair
                  className={styles.visitCardHeaderContent}
                  label='Care type:'
                  spacing='spread'
                  value={
                    <Chip
                      palette={
                        supportTypeColor[
                          SupportTypeId.DayCare
                        ] as VariantsPalette
                      }
                    >
                      Day care
                    </Chip>
                  }
                />
              </div>

              <div className={styles.visitCardContent}>
                <TextPair
                  spacing='spread'
                  label='Date:'
                  value='Wed, 29 Aug 2020'
                />

                <TextPair spacing='spread' label='Time:' value='9:00 - 18:30' />

                <TextPair
                  spacing='spread'
                  label='Duration:'
                  value='9.5 hours'
                />

                <TextPair
                  spacing='spread'
                  label='Price:'
                  value='£24 / hr / holiday'
                />

                <TextPair
                  spacing='spread'
                  label='Amount:'
                  value={<span className={styles.visitCardAmount}>£171</span>}
                />
              </div>
            </Card>
          ))}
        </div>
      )}
    </div>
  ));

export { PersonInvoice };
