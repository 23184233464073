import { $$signUpPage } from '@pages/sign-up/sign-up.model';

import { SignUpType } from '@features/care-recipient/client-type-selection';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { CtaBanner } from '@shared/ui/atoms/cta-banner';

import styles from './care-recipient-sign-up-type.module.scss';

const CareRecipientSignUpType = createView()
  .units({
    onTypeClick: $$signUpPage.signUpTypeClicked
  })

  .view(({ onTypeClick }) => (
    <div className={styles.container}>
      <CtaBanner>
        <p>
          Great!
          <br />
          Who are you arranging care for?
        </p>
      </CtaBanner>

      <div className={styles.options}>
        <Button
          onClick={() => onTypeClick(SignUpType.Myself)}
          palette='teritary'
          size='md'
        >
          Myself
        </Button>

        <Button
          onClick={() => onTypeClick(SignUpType.Loved)}
          palette='teritary'
          size='md'
        >
          A loved one
        </Button>
      </div>
    </div>
  ));

export { CareRecipientSignUpType };
