import { $$profilePage } from '@pages/profile/profile.model';

import {
  $$carerAdditionalInformation,
  AdditionalInformationForm
} from '@features/carer/profile/additional-information';

import { $$carerProfile } from '@entities/carer/model';

import { createView } from '@shared/lib/view';

import { EditableSection } from '@shared/ui/atoms/editable-section';

import { useMedia } from '@shared/ui/atoms/media';

import { TextPair } from '@shared/ui/atoms/text-pair';

import styles from './additional-information.module.scss';

/**
 * <AdditionalInformation />
 */
const AdditionalInformation = createView()
  .units({
    carer: $$carerProfile.$carerProfile,

    editing: $$profilePage.additionalInformation.$opened,

    onSave: $$carerAdditionalInformation.form.submit,

    onCancel: $$profilePage.additionalInformation.close,

    onEdit: $$profilePage.additionalInformation.open
  })

  .map(() => ({
    md: useMedia('>=md')
  }))

  .view(({ md, carer, editing, onSave, onCancel, onEdit }) => {
    const spacing = md ? 'normal' : 'multiline';

    return (
      <EditableSection
        title='Additional information'
        editing={editing}
        onEdit={onEdit}
        onCancel={onCancel}
        onSave={onSave}
      >
        {({ editing }) =>
          editing ? (
            <AdditionalInformationForm />
          ) : (
            <div className={styles.fields}>
              <TextPair
                label='Language:'
                value={carer?.languages?.map(item => item.name).join(', ')}
              />

              <TextPair
                label='Driving license:'
                value={carer?.transportationHelp?.carerText || 'No'}
                spacing={spacing}
              />

              <TextPair
                label='Are you ok with smokers in your client’s home:'
                value={carer?.smokingOk ? 'Yes' : 'No'}
                spacing={spacing}
              />

              <TextPair
                label='Are you ok with pets in your client’s home:'
                value={carer?.petsOk ? 'Yes' : 'No'}
                spacing={spacing}
              />

              {!carer?.petsOk && (
                <TextPair
                  label='What pets are you not ok with:'
                  value={carer?.petsDisabled?.map(item => item.name).join(', ')}
                  spacing={spacing}
                />
              )}
            </div>
          )
        }
      </EditableSection>
    );
  });

export { AdditionalInformation };
