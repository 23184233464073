import { createEvent, sample } from 'effector';

import { $$careRecipient } from '@entities/care-recipient';

import { $$session, RoleType } from '@entities/session';

import type { Client } from '@shared/api';

import { routes } from '@shared/routes';

const careRecipientClicked = createEvent<Client['id']>();

sample({
  clock: careRecipientClicked,

  target: $$careRecipient.$careRecipientID
});

sample({
  clock: careRecipientClicked,

  fn: () => RoleType.CareRecipient,

  target: $$session.roleChanged
});

sample({
  clock: careRecipientClicked,

  target: routes.schedule.open
});

const $$selectCareRecipient = {
  careRecipientClicked
};

export { $$selectCareRecipient };
