import arrow from '@icon/arrow.svg';

import { Link } from 'atomic-router-react';

import classNames from 'classnames';

import type { FC } from 'react';

import { useMedia } from '@shared/ui/atoms/media';

import { Text } from '@shared/ui/atoms/text';

import styles from './breadcrumbs.module.scss';

import type { BreadcrumbsProps, Step } from './breadcrumbs.props';

const Breadcrumbs: FC<BreadcrumbsProps> = ({ steps, disabled, className }) => {
  const mobile = useMedia('<=md');

  const checkIsMobile = (steps: Step[]) => (mobile ? steps.slice(0, 2) : steps);

  return (
    <div className={classNames(styles.breadcrumbs, className)}>
      {checkIsMobile(steps).map((step, index) => (
        <Link
          className={classNames(styles.step, { [styles.disabled]: disabled })}
          key={index}
          params={step.params ?? {}}
          to={step.route}
        >
          <Text
            title={typeof step.label == 'string' ? step.label : undefined}
            className={styles.text}
            variant='h2'
            tag='span'
          >
            {step.label}
          </Text>

          <img src={arrow} className={styles.arrow} alt='arrow' />
        </Link>
      ))}
    </div>
  );
};

export { Breadcrumbs };
