import cancel from '@icon/cancel.svg';

import dotsPale from '@icon/dots-pale.svg';

import dotsPressed from '@icon/dots.svg';

import edit from '@icon/pencil-edit.svg';

import resend from '@icon/resend.svg';

import avatarPlaceholder from '@img/avatar-placeholder.png';

import classNames from 'classnames';

import React, { useMemo, useRef, useState } from 'react';

import type { FC } from 'react';

import type { MemberNotification, MembersRoleType } from '@shared/api';

import { MembersNotificationType } from '@shared/api';

import { RelationId } from '@shared/api';

import { useClickOutside } from '@shared/lib/hooks';

import { shortener } from '@shared/lib/utils';

import { createView } from '@shared/lib/view';

import { Dropdown } from '@shared/ui/atoms/dropdown';

import { useMedia } from '@shared/ui/atoms/media';

import { Select } from '@shared/ui/atoms/select';

import { $$members } from '../../model';

import { MemberCardTile } from './member-card-tile';

import { accessLevelsForSelect } from './member-card.constant';

import styles from './member-card.module.scss';

import type {
  MemberCardProps,
  OptionBoxProps,
  AccessLevelProps
} from './member-card.props';

import { NotificationAccess } from './notification-access';

const MemberCard = createView<MemberCardProps>()
  .map(({ member, isUserOwner, owner, currentUser, deleteMemberOpened }) => {
    const xs = useMedia('<md');

    const moreRef = useRef<HTMLDivElement>();

    const [more, setMore] = useState(false);

    const isOwner = member.account.id === owner?.id;
    const isMemberCurrentUser = member.account.id === currentUser.id;

    const notifications = useMemo(() => {
      const notifications = Object.values(MembersNotificationType).map(
        type => ({
          label: type,
          isEmail: !!member.emailNotifications?.find(data => data === type),
          isSMS: !!member.smsNotifications?.find(data => data === type)
        })
      );

      return notifications;
    }, [member]);

    const onMoreClick = () => {
      setMore(!more);
    };

    const onResendInvitationClick = () => {
      $$members.resendInvitation(member.account.email);
    };

    const onDeleteClick = () => {
      deleteMemberOpened(member?.id || member?.account?.email);
    };

    const onNotificationChange = (
      type: MembersNotificationType,
      field: 'smsNotifications' | 'emailNotifications'
    ) => {
      const prevNotifications: MemberNotification = {
        emailNotifications: member.emailNotifications
          ? [...member.emailNotifications]
          : [],
        smsNotifications: member.smsNotifications
          ? [...member.smsNotifications]
          : []
      };

      if (prevNotifications[field].find(prevType => type === prevType)) {
        prevNotifications[field] = prevNotifications[field].filter(
          prevType => type !== prevType
        );
      } else {
        prevNotifications[field].push(type);
      }

      $$members.updateMemberNotifications({
        memberId: member.id,
        accountId: member.account.id,
        ...prevNotifications
      });
    };

    const onAccessLevelChange = (role: MembersRoleType) => {
      $$members.updateMemberRole({
        email: member.account.email,
        memberId: member.id,
        role
      });
    };

    useClickOutside(() => {
      setMore(false);
    }, moreRef);

    return {
      xs,
      isOwner,
      isUserOwner,
      isMemberCurrentUser,
      more,
      moreRef,
      notifications,
      onDeleteClick,
      onMoreClick,
      onResendInvitationClick,
      onNotificationChange,
      onAccessLevelChange
    };
  })
  .view(
    ({
      xs,
      isOwner,
      isUserOwner,
      isMemberCurrentUser,
      more,
      moreRef,
      member: {
        account: {
          email,
          firstName,
          lastName,
          phone,
          relations: initRelations,
          imageUrl
        },
        role,
        isVerified
      },
      notifications,
      onNotificationChange,
      onMoreClick,
      onDeleteClick,
      onAccessLevelChange,
      onResendInvitationClick,
      onEditClick
    }) => {
      const actionOptions = [
        {
          text: isVerified ? 'Edit details' : 'Resend invitation',

          icon: isVerified ? edit : resend,

          onClick: isVerified ? onEditClick : onResendInvitationClick
        },
        {
          text: 'Delete account',

          isDanger: true,

          icon: cancel,

          onClick: onDeleteClick
        }
      ];

      const relations = useMemo(
        () =>
          initRelations.map(relation => {
            if (isMemberCurrentUser) return relation;

            if (!relation.clientRelation) {
              return {
                client: relation.client,
                clientRelation: {
                  id: -1,
                  name: '-'
                }
              };
            }

            if (relation.clientRelation.id === RelationId.Myself) {
              const {
                client,
                clientRelation: { id }
              } = relation;

              return {
                client,
                clientRelation: {
                  id,
                  name: 'Herself/Himself'
                }
              };
            }

            return relation;
          }),
        [initRelations]
      );

      const memberView = shortener(
        firstName && lastName ? `${firstName} ${lastName}` : email,
        xs ? 30 : 20
      );

      return (
        <div className={styles.card}>
          <div className={styles.infoWrapper}>
            <div className={styles.infoHeaderWrapper}>
              <div
                className={classNames(styles.infoHeader, {
                  [styles.infoHeaderNoBadges]: isVerified && !isOwner
                })}
              >
                <div className={styles.avatar}>
                  <img src={imageUrl || avatarPlaceholder} />
                </div>

                <div className={styles.infoHeaderContent}>
                  <span
                    className={classNames(styles.memberName, {
                      [styles.memberNameNoBadges]: isVerified && !isOwner
                    })}
                  >
                    {memberView}
                  </span>

                  <div className={styles.badges}>
                    {isOwner && (
                      <span className={styles.memberStatus}>
                        Care Space owner
                      </span>
                    )}

                    {!isVerified && (
                      <span
                        className={classNames(
                          styles.memberStatus,
                          styles.unverified,
                          styles.grayBg
                        )}
                      >
                        Unverified
                      </span>
                    )}
                  </div>
                </div>
              </div>

              {isUserOwner && !isOwner && (
                <OptionsBox
                  optionsRef={moreRef}
                  open={more}
                  setOpen={() => onMoreClick()}
                  options={actionOptions as any}
                />
              )}
            </div>

            <div className={styles.infoContent}>
              <div
                className={classNames(styles.infoContentTile, {
                  [styles.tileExpanded]: isOwner || !isUserOwner
                })}
              >
                <MemberCardTile
                  title='Access level:'
                  describe={
                    <AccessLevel
                      isOptionOpen={more}
                      role={role}
                      isUserOwner={isUserOwner}
                      isOwner={isOwner}
                      onAccessLevelChange={onAccessLevelChange}
                    />
                  }
                />
              </div>

              <div className={styles.infoContentTile}>
                <MemberCardTile title='Email:' describe={email} />

                <MemberCardTile title='Phone:' describe={phone || '-'} />
              </div>

              {relations.map(
                (
                  {
                    client: { firstName, lastName },
                    clientRelation: { name: relation, id: relationId }
                  },
                  index
                ) => (
                  <div key={index} className={styles.infoContentTile}>
                    <MemberCardTile
                      title={
                        relationId !== RelationId.Myself
                          ? 'Contact for:'
                          : 'Care recipient:'
                      }
                      describe={`${firstName} ${lastName}`}
                    />

                    <MemberCardTile title='Relationship:' describe={relation} />
                  </div>
                )
              )}
            </div>
          </div>

          <div className={styles.notificationsWrapper}>
            <div className={styles.notificationsHeader}>
              <span>Notifications</span>

              <div className={styles.notifications}>
                <span>Email</span>

                <span>SMS</span>
              </div>
            </div>

            <div className={styles.notificationsContainer}>
              {notifications.map(({ isEmail, isSMS, label }) => (
                <NotificationAccess
                  key={label}
                  title={label}
                  disabled={!isMemberCurrentUser}
                  email={isEmail}
                  sms={isSMS}
                  onClick={option => onNotificationChange(label, option)}
                />
              ))}
            </div>
          </div>
        </div>
      );
    }
  );

const AccessLevel: FC<AccessLevelProps> = ({
  isUserOwner,
  isOptionOpen,
  isOwner,
  role,
  onAccessLevelChange
}) => {
  if (!isUserOwner) return <p>{role}</p>;

  if (isOwner) return <p>Full Access</p>;

  return (
    <Select
      className={classNames(styles.select, {
        [styles.selectDisabled]: isOptionOpen
      })}
      options={accessLevelsForSelect}
      value={role}
      getOptionValue={option => option?.name}
      onChange={name => {
        onAccessLevelChange(name);
      }}
    />
  );
};

const OptionsBox: FC<OptionBoxProps> = ({
  optionsRef,
  open,
  setOpen,
  options
}) => (
  <div
    ref={optionsRef}
    onClick={() => setOpen()}
    className={styles.headerOptions}
  >
    <div className={styles.iconContainer}>
      <img src={open ? dotsPressed : dotsPale} />
    </div>

    {open && (
      <Dropdown className={styles.dropdown}>
        {options.map(({ text, isDanger, icon, onClick }) => (
          <Dropdown.Item
            key={text}
            className={!isDanger && styles.dropdownItem}
            type={isDanger ? 'danger' : 'default'}
            onClick={onClick}
          >
            <span>{text}</span>

            <img src={icon} />
          </Dropdown.Item>
        ))}
      </Dropdown>
    )}
  </div>
);

export { MemberCard };
