import { useCallback } from 'react';

import {
  $$transferOwnerShip,
  MemberType
} from '@features/account/transfer-ownership/model';

import { $$accountInfo } from '@entities/account';

import { $$members } from '@entities/members';

import type { WorkspaceMember } from '@shared/api';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { Modal } from '@shared/ui/atoms/modal';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import styles from './select-member.module.scss';

import type { SelectMemberProps } from './select-member.props';

const SelectMember = createView<SelectMemberProps>()
  .units({
    members: $$members.$members,

    memberType: $$transferOwnerShip.$memberType,

    account: $$accountInfo.$account,

    onChangeMemberType: $$transferOwnerShip.changeMemberType,

    onCancel: $$transferOwnerShip.modalClosed
  })

  .map(({ memberType, account, members }) => {
    const getSelectOptionLabel = useCallback((member: WorkspaceMember) => {
      const {
        account: { email, firstName, lastName }
      } = member;

      return firstName && lastName
        ? `${firstName} ${lastName} (${email})`
        : email;
    }, []);

    const filteredMembers = members.filter(
      ({ account: { id } }) => id !== account.id
    );

    const isTypeSelect =
      memberType === MemberType.Select && filteredMembers.length;

    return {
      isTypeSelect,
      filteredMembers,
      getSelectOptionLabel
    };
  })

  .view(
    ({
      isEmbedded,
      memberType,
      onCancel,
      onChangeMemberType,
      onGoPrevious,
      isTypeSelect,
      filteredMembers,
      getSelectOptionLabel
    }) => (
      <Modal.Container onClose={() => onCancel()} size='small'>
        <Modal.Header>Transfer ownership</Modal.Header>

        <Modal.Body>
          <div className={styles.content}>
            <p>
              {isTypeSelect ? (
                <>
                  Select a new Care Space owner from the list of users. If you
                  want to transfer the ownership to a new user,{' '}
                  <span
                    className={styles.link}
                    onClick={() => onChangeMemberType(MemberType.New)}
                  >
                    click here.
                  </span>
                </>
              ) : (
                <>
                  Enter an email address of the user you wish to invite to
                  Carers HQ and transfer the rights to manage the Care Space.
                </>
              )}
            </p>

            <Form
              className={styles.form}
              use={$$transferOwnerShip.selectMemberForm}
            >
              {isTypeSelect ? (
                <Field.Select
                  use={$$transferOwnerShip.selectMemberForm.fields.email}
                  className={styles.reason}
                  placeholder='Select'
                  name='email'
                  searchable={false}
                  options={filteredMembers}
                  getOptionValue={(member: WorkspaceMember) =>
                    member?.account.email
                  }
                  getOptionLabel={getSelectOptionLabel}
                  menuPosition='fixed'
                />
              ) : (
                <Field.Input
                  use={$$transferOwnerShip.selectMemberForm.fields.email}
                  name='email'
                  label='Email'
                  placeholder='name@example.com'
                  errorIn='label'
                />
              )}
            </Form>

            <p className={styles.changedMind}>
              Changed your mind? <br />
              No problem. Just press cancel.
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer
          space={memberType === MemberType.New || isEmbedded}
          className={styles.footer}
        >
          {memberType === MemberType.New && (
            <Button
              className={styles.previous}
              variant='text'
              palette='text'
              onClick={() => onChangeMemberType(MemberType.Select)}
            >
              Previous step
            </Button>
          )}

          {isEmbedded && memberType !== MemberType.New && (
            <Button
              variant='text'
              palette='text'
              onClick={() => onGoPrevious()}
            >
              Previous step
            </Button>
          )}

          <div className={styles.group}>
            <Button
              palette='text'
              variant='text'
              onClick={() => onCancel()}
              className={styles.cancel}
            >
              Cancel
            </Button>

            <Button
              onClick={() => $$transferOwnerShip.selectMemberForm.submit()}
            >
              Continue
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Container>
    )
  );

export { SelectMember };
