import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { Modal } from '@shared/ui/atoms/modal';

import styles from './confirm.module.scss';

import type { ConfirmProps } from './confirm.props';

/**
 * <Confirm />
 */
const Confirm = createView<ConfirmProps>().view(
  ({ onCancel, stepChanged, isAccount }) => (
    <Modal.Container onClose={() => onCancel()} size='small'>
      <Modal.Header>
        Are you sure you want to delete your{' '}
        {isAccount ? 'account?' : 'Care Space?'}
      </Modal.Header>

      <Modal.Body>
        <div className={styles.content}>
          <p>
            We are sorry that you have decided to delete your{' '}
            {isAccount ? 'account' : 'Care Space'}.
          </p>

          <p>
            Before you go, please, review these{' '}
            <a href='https://google.com' className={styles.link}>
              steps to take before deleting your{' '}
              {isAccount ? 'account' : 'workspace'}
            </a>{' '}
            to make sure your don’t lose any important details.
          </p>

          <p>
            If you need help,{' '}
            <a href='https://google.com' className={styles.link}>
              contact us.
            </a>
          </p>
        </div>
      </Modal.Body>

      <Modal.Footer className={styles.footer}>
        <Button
          palette='text'
          variant='text'
          className={styles.cancel}
          onClick={() => onCancel()}
        >
          Cancel
        </Button>

        <Button
          palette='error'
          onClick={() =>
            isAccount
              ? stepChanged('workspace-decision')
              : stepChanged('reason')
          }
        >
          Yes, I want to delete
        </Button>
      </Modal.Footer>
    </Modal.Container>
  )
);

export { Confirm };
