import classnames from 'classnames';

import classNames from 'classnames';

import { useStore } from 'effector-react';

import type { CSSProperties, FC } from 'react';

import { $palettes } from './chip.config';

import styles from './chip.module.scss';

import type { ChipGroupProps, ChipProps } from './chip.props';

const Chip: FC<ChipProps> = ({
  size,
  icon,
  className,
  disabled,
  palette,
  children,
  ...props
}) => {
  const colors = useStore($palettes);

  const { fill, text, border } =
    typeof palette == 'string' ? colors[palette] : palette;

  return (
    <div
      style={
        {
          '--chip-text-normal': text.normal,
          '--chip-text-hovered': text.hovered,
          '--chip-text-pressed': text.pressed,
          '--chip-fill-normal': fill.normal,
          '--chip-fill-hovered': fill.hovered,
          '--chip-fill-pressed': fill.pressed,
          '--chip-border-normal': border.normal,
          '--chip-border-hovered': border.hovered,
          '--chip-border-pressed': border.pressed
        } as CSSProperties
      }
      className={classnames(styles.chip, className, {
        [styles[size]]: size,
        [styles.disabled]: disabled
      })}
      {...props}
    >
      {icon && <img src={icon} className={styles.icon} />}

      {children}
    </div>
  );
};

const ChipGroup: FC<ChipGroupProps> = ({ chips, className }) => (
  <div className={classNames(styles.chips, className)}>
    {chips?.map((chip, index) => (
      <Chip
        size={chip.size}
        palette={chip.palette}
        key={index}
        disabled={chip.disabled}
        icon={chip.icon}
        className={chip.className}
        {...chip}
      >
        {chip.children}
      </Chip>
    ))}
  </div>
);

Chip.defaultProps = {
  palette: 'primary',
  size: 'lg'
};

export { Chip, ChipGroup };
