import { createEffect } from 'effector';

import { carersHQ } from '@shared/api';

const sendEmailConfirmationFx = createEffect<void, void>();

const emailConfirmationSent = sendEmailConfirmationFx.doneData;

const $pendingEmailConfirmation = sendEmailConfirmationFx.pending;

const $$confirmUserEmail = {
  $pendingEmailConfirmation,

  emailConfirmationSent,

  sendEmailConfirmationFx
};

sendEmailConfirmationFx.use(async () => {
  await carersHQ.email.sendEmailConfirmation();
});

export { $$confirmUserEmail };
