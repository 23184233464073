import { createView } from '@shared/lib/view';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { $$clientTypeSelection, SignUpType } from '../model';

const ClientTypeSelectionForm = createView().view(() => (
  <Form use={$$clientTypeSelection.form}>
    <Field.Radio
      use={$$clientTypeSelection.form.fields.clientType}
      payload={SignUpType.Loved}
      label='A loved one'
    />

    <Field.Radio
      use={$$clientTypeSelection.form.fields.clientType}
      payload={SignUpType.Myself}
      label='Myself'
    />
  </Form>
));

export { ClientTypeSelectionForm };
