import type { Event } from 'effector';

import { sample } from 'effector';

import { $$signUpMyself } from '@pages/sign-up/pages/myself';

import { $$addUserRole } from '@features/user/add-role';

import { $$session } from '@entities/session';

import type { SignUpClientParams } from '@shared/api';

import { $myselfValues, myselfValuesToDto } from './shared';

const submitted = sample({
  clock: $$signUpMyself.steps.contactDetails.form.submitted,

  source: {
    values: $myselfValues
  },

  filter: $$session.$active,

  fn: ({ values }, contactDetails) =>
    myselfValuesToDto({
      ...values,

      contactDetails
    })
});

sample({
  clock: submitted as unknown as Event<SignUpClientParams>,

  target: $$addUserRole.addMyselfRoleFx
});
