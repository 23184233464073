import { $$signUpMyself } from '@pages/sign-up/pages/myself';

import { routes } from '@shared/routes';

import { createMyselfStep } from './shared';

createMyselfStep({
  key: 'conditionExperience',

  form: $$signUpMyself.steps.conditionExperience.form,

  route: routes.signUp.myself.conditionExperience,

  nextRoute: routes.signUp.myself.preferences,

  mapValues: values => values.conditionExperience,

  processValues: values => values,

  checkValues: values => Boolean(values.careNeeds)
});
