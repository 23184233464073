import { createStore } from 'effector';

import { object, string } from 'yup';

import { createForm } from '@shared/lib/form';

import { withoutCyrilic } from '@shared/lib/utils';

type ContactDetailsValues = {
  postCode: string;

  city: string;

  addressLine1: string;

  addressLine2: string;

  phone: string;
};

const form = createForm<ContactDetailsValues>({
  initialValues: {
    postCode: '',

    city: '',

    addressLine1: '',

    addressLine2: '',

    phone: ''
  },

  $schema: createStore(
    object({
      postCode: string()
        .nullable()

        .required()

        .trim('Required')

        .matches(/^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i, 'Invalid')

        .label('Postcode'),

      city: string()
        .nullable()

        .required()

        .matches(withoutCyrilic, {
          excludeEmptyString: true,
          message: 'Invalid format'
        })

        .label('City')

        .trim('Required'),

      addressLine1: string()
        .nullable()

        .required()

        .matches(withoutCyrilic, {
          excludeEmptyString: true,
          message: 'Invalid format'
        })

        .label('Address line 1')

        .trim('Required'),

      addressLine2: string()
        .nullable()

        .matches(withoutCyrilic, {
          excludeEmptyString: true,
          message: 'Invalid format'
        })

        .label('Address line 2'),

      phone: string()
        .nullable()

        .required()

        .label('Phone')

        .trim('Required')

        .min(2, 'Required')

        .matches(/^\+?\d+$/, 'Invalid')
    })
  )
});

const $$carerContactDetails = {
  form
};

export type { ContactDetailsValues };

export { $$carerContactDetails, form };
