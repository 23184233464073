import { Wellness } from '@features/wellness';

import { createView } from '@shared/lib/view';

import { Tabs } from '@shared/ui/atoms/tabs';

import styles from './wellness.module.scss';

import type { WellnessProps } from './wellness.props';

const WellnessPage = createView<WellnessProps>().view(({ tabChanged, tab }) => (
  <>
    <div className={styles.tabList}>
      <Tabs
        tabs={[
          { id: 'profile', name: 'Profile' },
          { id: 'medication', name: 'Medication' },
          { id: 'wellness', name: 'Wellness' },
          { id: 'contacts', name: 'Contacts' }
        ]}
        className={styles.tab}
        onChange={value => tabChanged(value)}
        selected={tab}
        size='lg'
        variant='inline'
      />
    </div>

    <Wellness />
  </>
));

export { WellnessPage };
