import check from '@icon/check.svg';

import { ReactComponent as ArrowDown } from '@icon/select-arrow-down.svg';

import classNames from 'classnames';

import type { FC } from 'react';

import { useMemo } from 'react';

import ReactSelect from 'react-select';

import type { components as _components, OptionProps } from 'react-select';

import { Label } from '@shared/ui/atoms/label';

import { Text } from '@shared/ui/atoms/text';

import styles from './select.module.scss';

import type { DefaultOption, SelectProps } from './select.props';

import { customStyles } from './select.props';

const Option: FC<OptionProps<DefaultOption>> = ({
  isDisabled,
  label,
  data,
  isSelected,
  innerProps
}) => (
  <div
    className={classNames(styles.option, {
      [styles.selectedOption]: isSelected,
      [styles.disabled]: isDisabled
    })}
    {...innerProps}
  >
    <div className={styles.content}>
      <Text variant='body-1' className={styles.optionText}>
        {label}
      </Text>

      {(isSelected || data?.icon) && (
        <div className={styles.customIcon}>
          {isSelected ? <img src={check} /> : data?.icon}
        </div>
      )}
    </div>
  </div>
);

const components: Partial<typeof _components> = {
  ClearIndicator: () => null!,

  IndicatorSeparator: () => null!,

  DropdownIndicator: ({ selectProps }) => (
    <ArrowDown
      className={classNames(styles.arrow, {
        [styles.arrowUp]: selectProps.menuIsOpen
      })}
    />
  ),

  Option: ({ isSelected, innerProps, isDisabled, data, label, ...props }) => (
    // @ts-expect-error temporary
    <Option
      {...props}
      isDisabled={isDisabled}
      label={label}
      data={data as any}
      isSelected={isSelected}
      innerProps={innerProps}
    />
  )
};

const Select: FC<SelectProps<any>> = ({
  options,
  mode = 'select',
  menuPosition,
  helper,
  label,
  value,
  defaultValue,
  labelGap,
  getOptionValue,
  getOptionLabel,
  placeholder,
  name,
  bold,
  wrapperClassName,
  disabled,
  menuPlacement,
  hasError,
  className,
  error,
  plainTextLabel,
  onChange,
  searchable
}) => {
  const onSelectChange = (value: any) => {
    onChange(value);
  };

  const option = useMemo(
    () => options.find(one => getOptionValue(one) == value),

    [options, value, defaultValue]
  );

  return (
    <div className={classNames(styles.wrapper, wrapperClassName)}>
      {(label || helper) && (
        <Label
          labelGap={labelGap}
          error={error}
          bold={bold}
          label={label}
          isError={hasError}
          disabled={disabled}
          helper={helper}
          plainTextLabel={plainTextLabel}
        />
      )}

      <div
        className={classNames(styles.box, className, {
          [styles.error]: hasError
        })}
      >
        {mode === 'select' ? (
          <ReactSelect
            className='select'
            classNamePrefix='select'
            name={name}
            styles={customStyles}
            options={options}
            isMulti={false}
            menuPlacement={menuPlacement}
            components={components}
            getOptionValue={getOptionValue}
            defaultValue={defaultValue ? defaultValue : false}
            menuPosition={menuPosition}
            getOptionLabel={getOptionLabel}
            isOptionDisabled={(option: any) => option.disabled}
            onChange={value => onSelectChange(getOptionValue(value))}
            value={option}
            placeholder={placeholder}
            isSearchable={searchable}
            isClearable={false}
          />
        ) : (
          <div
            className={classNames(styles.viewMode, {
              [styles.viewModeBordered]: mode === 'viewBordered'
            })}
          >
            {(value as any)?.name}
          </div>
        )}
      </div>
    </div>
  );
};

Select.defaultProps = {
  menuPosition: 'absolute',

  searchable: false,

  menuPlacement: 'bottom',

  placeholder: 'Select',

  getOptionValue: (option: any) => option.id,

  getOptionLabel: (option: any) => option.name
};
export { Select };
