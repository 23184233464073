import { createStore, sample } from 'effector';

import { modelFactory } from 'effector-factorio';

import moment from 'moment';

import { array, object, string } from 'yup';

import type { NightCareType, Service } from '@shared/api';

import { CareType } from '@shared/api';

import { StartingFromType, VisitCareType } from '@shared/api';

import { createForm } from '@shared/lib/form';

import { routes } from '@shared/routes';

type CareNeedsValues = {
  startingFromDate: string;

  startingFromType: StartingFromType;

  visitCareType: VisitCareType;

  services: Service['id'][];

  careTypes: CareType[];

  nightCareTypes: NightCareType[];
};

const ManageCareNeedsModelFactory = modelFactory(() => {
  const $schema = createStore<any>(null);

  const form = createForm<CareNeedsValues>({
    initialValues: {
      startingFromDate: null,

      startingFromType: StartingFromType.SpecificDate,

      visitCareType: VisitCareType.VisitingCare,

      services: [],

      careTypes: [],

      nightCareTypes: []
    },

    $schema
  });

  sample({
    clock: [
      form.$values,
      routes.signUp.myself.careNeeds.$isOpened,
      routes.signUp.lovedOne.careNeeds.$isOpened,
      form.$values.updates,
      routes.careRecipients.base.$isOpened
    ],

    source: form.$values,

    fn: values => {
      const fields = {
        services: array()
          .required('Please select at least one option')

          .nullable()

          .min(1, 'Required'),

        startingFromDate: string().nullable(),

        nightCareTypes: array().nullable(),

        careTypes: array().nullable().required()
      };

      const shouldValidateDate =
        values.startingFromType == StartingFromType.SpecificDate &&
        values.visitCareType == VisitCareType.LiveInCare;

      const shouldValidateNightCareType =
        values.visitCareType == VisitCareType.VisitingCare &&
        values?.careTypes.includes?.(CareType.NightCare);

      if (shouldValidateDate) {
        fields.startingFromDate = fields.startingFromDate
          .required()

          .test(value => moment(value).isSameOrAfter(moment(), 'date'));
      }

      if (shouldValidateNightCareType) {
        fields.nightCareTypes = fields.nightCareTypes
          .required()

          .min(1);
      }

      if (values.visitCareType == VisitCareType.VisitingCare) {
        fields.careTypes = fields.careTypes
          .required('Required')

          .min(1, 'Required');
      }

      return object(fields);
    },

    target: $schema
  });

  return {
    form
  };
});

export type { CareNeedsValues };

export { ManageCareNeedsModelFactory };
