import { modelView } from 'effector-factorio';

import { $$dictionary } from '@entities/dictionary';

import { createView } from '@shared/lib/view';

import { Checkbox, CheckboxGroup } from '@shared/ui/atoms/checkbox';

import { Field, useField } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { ManageConditionExperienceModelFactory } from '../../model';

import type { ConditionExperienceFormProps } from './condition-experience-form.props';

const NoneOfTheAbove = createView()
  .units({
    conditionTypes: $$dictionary.$conditionTypes
  })

  .map(({ conditionTypes }) => {
    const { form } = ManageConditionExperienceModelFactory.useModel();

    const { value, onChange } = useField(form.fields.conditionsTypes);

    const indeterminate = value?.length == 0;

    return {
      indeterminate,

      onChange: () =>
        onChange(indeterminate ? conditionTypes.map(item => item.id) : [])
    };
  })

  .view(props => (
    <Checkbox label='None of the above' {...(props as any)} value={false} />
  ));

const ConditionExperienceForm = modelView(
  ManageConditionExperienceModelFactory,

  createView<ConditionExperienceFormProps>()
    .defaultProps({
      tip: 'You can select multiple conditions'
    })

    .units({
      conditionTypes: $$dictionary.$conditionTypes
    })

    .map(() => {
      const { form } = ManageConditionExperienceModelFactory.useModel();

      return {
        form
      };
    })

    .view(({ form, tip, conditionTypes }) => (
      <Form use={form}>
        <Field.FormSection
          use={form.fields.conditionsTypes}
          title='What conditions should the carer have experience with?'
          tip={tip}
        >
          <CheckboxGroup>
            <Field.CheckboxList
              use={form.fields.conditionsTypes}
              options={conditionTypes}
            />
          </CheckboxGroup>
        </Field.FormSection>
      </Form>
    ))
);

export { ConditionExperienceForm };
