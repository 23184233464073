import { createEffect } from 'effector';

import type { SignUpCarerParams, SignUpClientParams } from '@shared/api';

import { carersHQ } from '@shared/api';

const addCarerRoleFx = createEffect<SignUpCarerParams, { id: string }>();

const addMyselfRoleFx = createEffect<SignUpClientParams, { id: string }>();

const addLovedOneRoleFx = createEffect<SignUpClientParams, { id: string }>();

const $$addUserRole = {
  addCarerRoleFx,
  addMyselfRoleFx,
  addLovedOneRoleFx
};

addCarerRoleFx.use(async params => {
  const response = await carersHQ.auth.addCarer(params);

  return response.data;
});

addLovedOneRoleFx.use(async params => {
  const response = await carersHQ.auth.addLovedOne(params);

  return response.data;
});

addMyselfRoleFx.use(async params => {
  const response = await carersHQ.auth.addMyself(params);

  return response.data;
});

export { $$addUserRole };
