import classNames from 'classnames';

import moment from 'moment';

import type { FC } from 'react';

import type { SupportTypeId } from '@shared/api';

import { supportTypeColor } from '@shared/api';

import { roundedPrice } from '@shared/lib/utils';

import { createView } from '@shared/lib/view';

import iconLocation from '@shared/ui/assets/icons/location.svg';

import { Button } from '@shared/ui/atoms/button';

import { Chip, ChipGroup } from '@shared/ui/atoms/chip';

import type { ChipProps } from '@shared/ui/atoms/chip/chip.props';

import { Divider } from '@shared/ui/atoms/divider';

import { useMedia } from '@shared/ui/atoms/media';

import { Modal } from '@shared/ui/atoms/modal/modal.component';

import { VideoBox } from '@shared/ui/atoms/video-box';

import { $$carerProfile } from '../model';

import { Rating } from './rating';

import styles from './view-carer-profile.module.scss';

import type { StatProps } from './view-carer-profile.props';

const themes = ['day', 'night', 'night', 'live'];

const Stat: FC<StatProps> = ({ title, children, big = false }) => (
  <div
    className={classNames(styles.stat, {
      [styles.statBig]: big
    })}
  >
    <div className={styles.statHeader}>{title}</div>

    <div className={styles.statContent}>{children}</div>
  </div>
);

const ViewCarerProfile = createView()
  .units({
    carer: $$carerProfile.$carerProfile,

    visible: $$carerProfile.$visible,

    supportTypes: $$carerProfile.$carerProfile.map(carer =>
      carer
        ? [...carer.supportTypes!]
            .sort((a, b) => a.supportType!.id! - b.supportType!.id!)
            .map(type => ({
              palette:
                supportTypeColor[type!.supportType!.id! as SupportTypeId]!,

              children: type.supportType!.name
            }))
        : []
    ),

    onLocationClick: $$carerProfile.locationClicked,

    onClose: $$carerProfile.viewProfileClosed
  })

  .map(({ carer }) => {
    const md = useMedia('>=md');

    const age = carer
      ? Math.abs(moment(carer.dateOfBirth).diff(moment(), 'years'))
      : null;

    const experience = carer
      ? Math.abs(moment(carer.carerStart).diff(moment(), 'years'))
      : null;

    const index = Math.max(
      Math.floor(carer?.conditionsTypes!.length / 2),
      carer?.services!.length - 1
    );

    const start =
      carer?.conditionsTypes!.length % 2 == 0 &&
      carer?.services!.length < Math.ceil(carer?.conditionsTypes!.length / 2)
        ? index
        : index + 1;

    let columns = [
      carer?.conditionsTypes!.slice(0, start),
      carer?.conditionsTypes!.slice(start)
    ];

    return { md, age, experience, columns };
  })

  .view(
    ({
      onClose,
      carer,
      visible,
      onLocationClick,
      md,
      supportTypes,
      age,
      columns,
      experience
    }) => {
      if (!visible || !carer) return null;

      const personalInfo = (
        <>
          <div className={styles.greeting}>
            Hi, I’m{' '}
            <span className={styles.name}>
              {carer?.firstName} {carer?.lastName}
            </span>
          </div>

          <Rating />

          <div className={styles.rating}>The rating has not formed yet</div>

          <Chip
            className={styles.locationChip}
            palette='primary'
            onClick={() => onLocationClick()}
          >
            <img className={styles.location} src={iconLocation} />

            <span>{carer.addressLine1}</span>
          </Chip>

          <ChipGroup
            className={styles.cheaps}
            chips={supportTypes as ChipProps[]}
          />

          <div className={styles.stats}>
            <Stat title='Gender'>
              <span className={styles.highlight}>{carer.gender!.name}</span>
            </Stat>

            <Stat title='Age'>
              <span className={styles.highlight}>{age}</span>{' '}
              <span className={styles.highlightAddition}>
                year{age > 1 ? 's' : ''}
              </span>
            </Stat>

            <Stat title='Experience'>
              <span className={styles.highlight}>{experience}</span>{' '}
              <span className={styles.highlightAddition}>
                year{experience > 1 ? 's' : ''}
              </span>
            </Stat>
          </div>
        </>
      );

      const professionalInfo = (
        <>
          <div className={styles.careTypes}>
            <div className={styles.categoryTitle}>
              I provide such types of care
            </div>

            <div className={styles.rates}>
              {carer.supportTypes!.map((type, index) => (
                <div
                  className={classNames(styles.careType, styles[themes[index]])}
                  key={index}
                >
                  <div className={styles.careTypeHeader}>
                    <div className={styles.careTypeHeaderTitle}>
                      {type.supportType!.name}
                    </div>

                    <div className={styles.careTypeHeaderIcon} />
                  </div>

                  <div className={styles.careTypeRates}>
                    <div className={styles.careTypeRate}>
                      <span>£{roundedPrice(type.rates![0]?.rate)}</span>{' '}
                      <span>/ hr</span>
                    </div>

                    <div className={styles.careTypeRate}>
                      <span>£{roundedPrice(type.rates![1]?.rate)}</span>{' '}
                      <span>/ hr in holidays</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <Divider className={styles.line} />

          <div className={styles.experience}>
            <Stat title='Services' big>
              <ul className={styles.services}>
                {carer?.services!.map(({ id, name }) => (
                  <li key={id}>- {name}</li>
                ))}
              </ul>
            </Stat>

            <Stat title='Condition experience' big>
              <div className={styles.conditionColumns}>
                {columns.map((conditionsTypes, index) => (
                  <ul
                    className={styles.conditions}
                    data-min-rows={carer.services!.length}
                    key={index}
                  >
                    {conditionsTypes!.map(item => (
                      <li key={item.id}>- {item.name}</li>
                    ))}
                  </ul>
                ))}
              </div>
            </Stat>
          </div>

          <Divider className={styles.line} />

          <div className={styles.qualification}>
            <div className={styles.categoryTitle}>My qualification</div>

            <div className={styles.qualificationLevel}>
              <span>Certificates</span>

              <ul>
                {carer.qualifications?.map(({ id, name }) => (
                  <li key={id}>{name}</li>
                ))}
              </ul>
            </div>
          </div>
        </>
      );

      const promo = (
        <VideoBox className={styles.promo}>
          <source type='video/mp4' src={`${carer.videoUrl}#t=0.001`} />
        </VideoBox>
      );

      return (
        <Modal.Container drawer className={styles.modal} onClose={onClose}>
          <Modal.Body drawer>
            <div className={styles.header}>
              {md && <div className={styles.personalInfo}>{personalInfo}</div>}

              {promo}
            </div>

            {!md && (
              <>
                {personalInfo}

                <Divider />
              </>
            )}

            {professionalInfo}
          </Modal.Body>

          <Modal.Footer drawer className={styles.footer}>
            <Button className={styles.message}>
              Message {carer?.firstName}
            </Button>

            <Button
              className={styles.cancel}
              palette='text'
              onClick={() => onClose()}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal.Container>
      );
    }
  );

export { ViewCarerProfile };
