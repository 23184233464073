import alarmIcon from '@icon/alarm.svg';

import deleteIcon from '@icon/delete.svg';

import classNames from 'classnames';

import type { FC } from 'react';

import { AlertBox } from '@shared/ui/atoms/alert-box';

import styles from './info-alert-box.module.scss';

import type { InfoAlertBoxProps } from './info-alert-box.props';

/**
 * <InfoAlertBox />
 */
const InfoAlertBox: FC<InfoAlertBoxProps> = ({
  title,
  description,
  onClose,
  className,
  ...props
}) => (
  <AlertBox className={classNames(styles.infoAlertBox, className)} {...props}>
    <img className={styles.alertIcon} src={alarmIcon} />

    <div className={styles.content}>
      <p className={styles.title}>{title}</p>

      <p className={styles.description}>{description}</p>
    </div>

    <img className={styles.close} src={deleteIcon} onClick={onClose} />
  </AlertBox>
);

export { InfoAlertBox };
