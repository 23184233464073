import classNames from 'classnames';

import { useUnit } from 'effector-react';

import { usePostCodeAddresses } from '@features/address/search-by-postcode';

import type { ContactDetailsValues } from '@features/user/manage-contact-details';

import type { FormModel } from '@shared/lib/form';

import { createView } from '@shared/lib/view';

import { FormSectionGroup } from '@shared/ui/atoms/form-section';

import { Field } from '@shared/ui/organisms/field';

import styles from './contact-details-fields.module.scss';

type ContactDetailsFieldsProps = {
  form: FormModel<ContactDetailsValues>;

  classNames?: {
    location?: string;

    phoneRow?: string;

    addressLine1?: string;

    addressLine2?: string;

    formSection?: string;
  };
};

/**
 * <ContactDetailsFields />
 */
const ContactDetailsFields = createView<ContactDetailsFieldsProps>()
  .map(({ form }) => {
    const [{ postCode }] = useUnit([form.$values]);

    const { cities, addressLines1, addressLines2, onSuggestionSelect } =
      usePostCodeAddresses(postCode, '', form as any);

    return {
      cities,
      postCode,
      addressLines1,
      addressLines2,
      onSuggestionSelect
    };
  })

  .view(
    ({
      form,
      cities,
      addressLines1,
      addressLines2,
      onSuggestionSelect,
      classNames: _classNames
    }) => {
      const placeholder = (
        <div>
          We didn't find that address. <br /> Just keep typing it in, and we'll
          add it.
        </div>
      );

      return (
        <FormSectionGroup className={classNames(_classNames?.formSection)}>
          <div className={classNames(styles.location, _classNames?.location)}>
            <Field.Input
              labelGap
              use={form.fields.postCode}
              errorIn='label'
              className={styles.postcode}
              bold
              placeholder='Enter'
              label='Postcode'
            />

            <Field.SuggestionInput
              labelGap
              use={form.fields.city}
              errorIn='label'
              bold
              placeholder='Enter'
              label='City'
              suggestions={cities}
              suggestionsPlaceholder={placeholder}
              onSuggestionSelect={onSuggestionSelect('city')}
            />
          </div>

          <Field.FormSection
            className={classNames(
              styles.addressLine1,
              _classNames?.addressLine1
            )}
            use={form.fields.addressLine1}
            title='Address line 1'
          >
            <Field.SuggestionInput
              use={form.fields.addressLine1}
              errorIn='label'
              placeholder='Enter'
              suggestions={addressLines1}
              suggestionsPlaceholder={placeholder}
              onSuggestionSelect={onSuggestionSelect('addressLine1')}
            />
          </Field.FormSection>

          <Field.FormSection
            className={classNames(
              styles.addressLine2,
              _classNames?.addressLine2
            )}
            use={form.fields.addressLine2}
            title='Address line 2'
            tip='Optional'
          >
            <Field.SuggestionInput
              use={form.fields.addressLine2}
              errorIn='label'
              placeholder='Enter'
              suggestions={addressLines2}
              suggestionsPlaceholder={placeholder}
              onSuggestionSelect={onSuggestionSelect('addressLine2')}
            />
          </Field.FormSection>

          <Field.FormSection
            title='Phone number'
            use={form.fields.phone}
            className={classNames(_classNames?.phoneRow)}
          >
            <div className={classNames(styles.phone, _classNames?.phoneRow)}>
              <Field.PhoneInput
                use={form.fields.phone}
                errorIn='label'
                placeholder='Enter'
              />
            </div>
          </Field.FormSection>
        </FormSectionGroup>
      );
    }
  );

export { ContactDetailsFields };
