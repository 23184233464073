import { createStore } from 'effector';

import { array, object, string } from 'yup';

import { createForm } from '@shared/lib/form';

import { hourRegEx } from '@shared/lib/utils';

const form = createForm({
  initialValues: {
    services: [],

    activityPerWeek: null
  },

  $schema: createStore(
    object({
      services: array()
        .required()

        .nullable()

        .min(1),

      activityPerWeek: string()
        .nullable()

        .strict(true)

        .trim('Should have no spaces at start/end')

        .matches(hourRegEx, 'Should contain only latin characters')

        .max(100, 'Max length is 100 characters')
    })
  )
});

const $$profileRequiredSupport = {
  form
};

export { $$profileRequiredSupport, form };
