import classNames from 'classnames';

import { $$drafts } from '@entities/drafts';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { useMedia } from '@shared/ui/atoms/media';

import { Modal } from '@shared/ui/atoms/modal';

import { $$addCareRecipient } from '../../model';

import styles from './cancel-adding.module.scss';

const CancelAdding = createView()
  .units({
    onCancel: $$addCareRecipient.closeModal,

    onStepChanged: $$addCareRecipient.stepChanged,

    lastStep: $$addCareRecipient.$lastStep,

    saveDraft: $$drafts.saveAsDraftClicked
  })
  .map(() => {
    const lg = useMedia('>=lg');

    return {
      lg
    };
  })

  .view(({ onCancel, onStepChanged, lastStep, saveDraft, lg }) => (
    <Modal.Container
      onClose={() => onCancel()}
      size='large'
      fullHeight={lg}
      overlayStyles={classNames({ [styles.overlay]: !lg })}
      className={styles.modal}
    >
      <Modal.Header>Add a new person</Modal.Header>

      <Modal.Body className={styles.content}>
        <p className={classNames(styles.bold, styles.title)}>
          You can always add more information about a person by pressing the
          save {useMedia('>=md') && 'and exit'} button.
        </p>

        <p className={styles.description}>
          If you want to cancel the addition and delete all the data you have
          entered, press {useMedia('>=md') ? '' : 'the '}
          <span className={styles.bold}>
            {useMedia('>=md') ? 'cancel without saving' : 'delete data'}
          </span>{' '}
          button.
        </p>

        <p className={styles.description}>
          To return to filling in the information, press{' '}
          <span className={styles.bold}>
            back {useMedia('>=md') && 'to adding'}
          </span>{' '}
          button.
        </p>
      </Modal.Body>

      <Modal.Footer space className={styles.footer} fill>
        <Button
          palette='text'
          variant='text'
          onClick={() => onStepChanged(lastStep)}
          className={styles.cancel}
        >
          {useMedia('>=md') ? 'Back to adding' : 'Back'}
        </Button>

        <div>
          <Button
            palette='text'
            variant='text'
            className={styles.cancel}
            onClick={() => onCancel()}
          >
            {useMedia('>=md') ? 'Cancel without saving' : 'Delete data'}
          </Button>

          <Button onClick={() => saveDraft()}>
            {useMedia('>=md') ? 'Save and exit' : 'Save'}
          </Button>
        </div>
      </Modal.Footer>
    </Modal.Container>
  ));

export { CancelAdding };
