import pencilEdit from '@icon/pencil-edit.svg';

import classNames from 'classnames';

import { useState } from 'react';

import { shortener } from '@shared/lib/utils';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import styles from './editable-section.module.scss';

import type { EditableSectionProps } from './editable-section.props';

const EditableSection = createView<EditableSectionProps>()
  .defaultProps({
    editable: true,
    onSave: () => {},
    onCancel: () => {}
  })

  .map(({ onEdit, onCancel, editing: propsEditing }: EditableSectionProps) => {
    const [editing, setEditing] = useState(false);

    const onEditClick = () => {
      setEditing(true);

      onEdit?.();
    };

    const onCancelEdit = () => {
      setEditing(false);

      onCancel?.();
    };

    return {
      onEditClick,
      onCancelEdit,
      isEditing: propsEditing ?? editing
    };
  })

  .view(
    ({
      title,
      editable,
      isEditing,
      onSave,
      onCancelEdit,
      onEditClick,
      controls,
      children
    }) => (
      <section className={styles.section}>
        <h4 className={styles.title}>
          <span>
            {typeof title === 'string' ? shortener(title, 30) : title}
          </span>

          {editable && !isEditing && (
            <img
              className={styles.edit}
              src={pencilEdit}
              onClick={onEditClick}
            />
          )}
        </h4>

        <div className={styles.content}>
          {typeof children == 'function'
            ? children({
                editing: isEditing,
                onCancelEdit
              })
            : children}
        </div>

        {isEditing && (
          <div className={classNames(styles.controls, controls?.className)}>
            <Button onClick={onSave}>
              {controls?.save?.text ?? 'Save changes'}
            </Button>

            <Button
              onClick={onCancelEdit}
              palette='text'
              variant='text'
              className={styles.cancel}
            >
              {controls?.close?.text ?? 'Cancel'}
            </Button>
          </div>
        )}
      </section>
    )
  );

export { EditableSection };
