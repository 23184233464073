import iconPlayer from '@icon/play.svg';

import classNames from 'classnames';

import type { FC } from 'react';

import type { LegacyRef } from 'react';

import styles from './video-box.module.scss';

import { useVideoBoxProps } from './video-box.props';

import type { VideoBoxProps } from './video-box.props';

const VideoBox: FC<VideoBoxProps> = ({ className, children }) => {
  const { playing, videoRef, onPause, onBoxClick, onPlay } = useVideoBoxProps();

  return (
    <div className={classNames(styles.promo, className)} onClick={onBoxClick}>
      {!playing && (
        <div className={styles.overlay}>
          <div className={styles.play}>
            <img src={iconPlayer} />
          </div>
        </div>
      )}

      <video
        controls={playing}
        ref={videoRef as LegacyRef<HTMLVideoElement>}
        onPause={onPause}
        onPlay={onPlay}
      >
        {children}
      </video>
    </div>
  );
};

export { VideoBox };
