import { $$profilePage } from '@pages/profile/profile.model';

import {
  $$carerConditionExperience,
  ConditionExperienceForm
} from '@features/carer/profile/condition-experience';

import { $$carerProfile } from '@entities/carer/model';

import { createView } from '@shared/lib/view';

import { EditableSection } from '@shared/ui/atoms/editable-section';

import styles from './condition-experience.module.scss';

/**
 * <ConditionExperience />
 */
const ConditionExperience = createView()
  .units({
    carer: $$carerProfile.$carerProfile,

    editing: $$profilePage.conditionExperience.$opened,

    onSave: $$carerConditionExperience.form.submit,

    onCancel: $$profilePage.conditionExperience.close,

    onEdit: $$profilePage.conditionExperience.open
  })

  .view(({ carer, editing, onSave, onCancel, onEdit }) => (
    <EditableSection
      title='Condition experience'
      editing={editing}
      onEdit={onEdit}
      onCancel={onCancel}
      onSave={onSave}
    >
      {({ editing }) =>
        editing ? (
          <ConditionExperienceForm />
        ) : (
          <ul className={styles.types}>
            {carer?.conditionsTypes.length
              ? carer?.conditionsTypes.map(item => (
                  <li key={item.id}>- {item.name}</li>
                ))
              : '- None of the above'}
          </ul>
        )
      }
    </EditableSection>
  ));

export { ConditionExperience };
