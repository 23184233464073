import { createEffect, createEvent, sample } from 'effector';

import { $$careRecipient } from '@entities/care-recipient';

import type { Carer, ClientBindings } from '@shared/api';

import { carersHQ } from '@shared/api';

import { ToastID } from '@shared/config';

import type { NotifyOptions } from '@shared/ui/organisms/toasts';

import { $$toast } from '@shared/ui/organisms/toasts';

const addToFx = createEffect<ClientBindings & { carer: Carer }, Carer>();

const removeFromFx = createEffect<ClientBindings & { carer: Carer }, Carer>();

const addToShortList = createEvent<Carer>();

const removeFromShortList = createEvent<Carer>();

const $$carerShortlist = {
  added: addToFx.doneData,
  removed: addToFx.doneData,

  addToFx,
  removeFromFx,
  addToShortList,
  removeFromShortList
};

sample({
  clock: addToShortList,

  source: $$careRecipient.$careRecipientID,

  fn: (id, carer) => ({
    carer,
    clientId: id,
    carerId: carer.id
  }),

  target: addToFx
});

sample({
  clock: removeFromShortList,

  source: $$careRecipient.$careRecipientID,

  fn: (id, carer) => ({
    carer,
    clientId: id,
    carerId: carer.id
  }),

  target: removeFromFx
});

sample({
  clock: addToFx.doneData,

  fn: ({ firstName, lastName }) =>
    ({
      id: ToastID.AddedToAShortlist,
      html: true,
      title: `<b>${firstName} ${lastName}</b> was added to your shortlist.`,
      content: `<div style="word-break: break-word">You can find <b>${firstName}</b> in the Carer team, tab Shortlist carers.</div>`
    } as NotifyOptions),

  target: $$toast.notifyFx
});

sample({
  clock: removeFromFx.doneData,

  fn: ({ firstName, lastName }) =>
    ({
      id: ToastID.RemovedFromAShortlist,
      html: true,
      title: `<b style="word-break: break-all">${firstName} ${lastName}</b> was removed from your shortlist.`,
      content: `<div style="word-break: break-word">You can add <b>${firstName}</b> again later</div>`
    } as NotifyOptions),

  target: $$toast.notifyFx
});

addToFx.use(async ({ carer, ...payload }) => {
  await carersHQ.clients.addToShort(payload);

  return carer;
});

removeFromFx.use(async ({ carer, ...payload }) => {
  await carersHQ.clients.removeFromShort(payload);

  return carer;
});

export { $$carerShortlist };
