import { useMemo } from 'react';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { useMedia } from '@shared/ui/atoms/media';

import { Modal } from '@shared/ui/atoms/modal';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { reasonForm } from './reason.model';

import styles from './reason.module.scss';

import type { ReasonProps } from './reason.props';

/**
 * <Reason />
 */
const Reason = createView<ReasonProps>()
  .units({
    reasonValue: reasonForm.fields.reason.$value
  })

  .map(({ reasonValue }) => {
    const isTextareaActive = useMemo(
      () => reasonValue === 'Other',
      [reasonValue]
    );

    return {
      isTextareaActive
    };
  })

  .view(
    ({ options, isTextareaActive, onCancel, stepChanged, step, isAccount }) => (
      <Modal.Container onClose={() => onCancel()} size='small'>
        <Modal.Header>
          {isAccount ? 'Deleting your Account' : 'Deleting your Care Space'}
        </Modal.Header>

        <Modal.Body>
          <div className={styles.content}>
            <p>
              Before you delete your{' '}
              {isAccount ? 'Account and Care Space' : 'Care Space'}, please help
              us make the CarersHQ service better. Please state the reason for
              deleting the Care Space.
            </p>

            <Form use={reasonForm}>
              <Field.Select
                use={reasonForm.fields.reason}
                className={styles.reason}
                label={`What is the reason why you are deleting the ${
                  isAccount ? 'Account' : 'Care Space?'
                }`}
                placeholder='Select a reason'
                name='reason'
                plainTextLabel
                menuPosition='fixed'
                searchable={false}
                getOptionValue={value => value?.name}
                options={options}
              />

              {isTextareaActive && (
                <Field.Textarea
                  placeholder='Enter'
                  use={reasonForm.fields.reasonOther}
                  className={styles.textarea}
                  textareaClassName={styles.textareaInput}
                />
              )}
            </Form>

            <p className={styles.bold}>
              Changed your mind? <br />
              No problem. Just press cancel.
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer space className={styles.footer}>
          <Button
            palette='text'
            onClick={() => stepChanged(step)}
            variant='text'
          >
            {!useMedia('>=md') ? 'Previous' : 'Previous step'}
          </Button>

          <div>
            <Button
              variant='text'
              palette='text'
              onClick={() => onCancel()}
              className={styles.cancel}
            >
              Cancel
            </Button>

            <Button palette='error' onClick={() => reasonForm.submit()}>
              Continue deleting
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Container>
    )
  );

export { Reason };
