import { sample } from 'effector';

import { $$invoiceLogin } from '@features/deleted-account/invoice-login-form';

import { $$restore } from '@features/deleted-account/restore-form';

import { $$deletedAccount } from '@entities/deleted-account';

import { routes } from '@shared/routes';

sample({
  clock: routes.deletedAccount.restore.closed,

  target: $$restore.form.reset
});

sample({
  clock: routes.deletedAccount.invoices.closed,

  target: $$invoiceLogin.form.reset
});

$$restore.$error
  .on($$deletedAccount.restoreAccountFx.failData, () => true)

  .reset(
    $$deletedAccount.restoreAccountFx.doneData,

    routes.deletedAccount.restore.closed
  );

$$invoiceLogin.$error
  .on($$deletedAccount.loginFx.failData, () => true)

  .reset(
    $$deletedAccount.loginFx.doneData,

    routes.deletedAccount.invoices.closed
  );
