import { createEvent, createStore, sample } from 'effector';

import { $$shared } from '@features/billing/billing-details/model';

import { $$createCareSpace } from '@features/care-space/create';

import { $$restoreCareSpace } from '@features/care-space/restore';

const $visible = createStore(false);

const closeModal = createEvent();

const updatePaymentDetails = createEvent();

const $$workspaceCreated = {
  $visible,

  closeModal,

  updatePaymentDetails
};

sample({
  clock: updatePaymentDetails,

  target: [$$shared.billingDetailsOpened, closeModal]
});

sample({
  clock: closeModal,

  fn: () => false,

  target: $$restoreCareSpace.$isRestored
});

$visible
  .on(
    [$$createCareSpace.careSpaceCreated, $$restoreCareSpace.careSpaceRestored],
    () => true
  )
  .reset(closeModal);

export { $$workspaceCreated };
