import type { FC } from 'react';

import { lightenColor } from '@shared/lib/utils';

import styles from './title.module.scss';

type TitleProps = {
  title: string;
  description?: string;
  barColor: string;
  labelColor: string;
};

const Title: FC<TitleProps> = ({
  barColor,
  labelColor,
  title,
  description
}) => (
  <div
    className={styles.titleContainer}
    style={{
      backgroundColor: labelColor ? labelColor : lightenColor(barColor, 80)
    }}
  >
    <h3 className={styles.title}>
      {title}{' '}
      {!!description && (
        <span className={styles.description}>({description})</span>
      )}
    </h3>
  </div>
);

export { Title };
