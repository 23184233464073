import classNames from 'classnames';

import type { Event } from 'effector';

import { $$careRecipient } from '@entities/care-recipient';

import { $$dictionary } from '@entities/dictionary';

import { MembersRoleType } from '@shared/api';

import { createView } from '@shared/lib/view';

import { Accordeon } from '@shared/ui/atoms/accordeon';

import { AlertBox } from '@shared/ui/atoms/alert-box';

import { ButtonGroup, Button } from '@shared/ui/atoms/button';

import { useMedia } from '@shared/ui/atoms/media';

import { Modal } from '@shared/ui/atoms/modal';

import { Select } from '@shared/ui/atoms/select';

import { Text } from '@shared/ui/atoms/text';

import { Field, useField } from '@shared/ui/organisms/field';

import {
  $relationError,
  addCareRecipientClicked,
  cancelClicked,
  emailsForm,
  goBackFromRelationsClicked,
  relationsForm
} from '../../model';

import styles from './relations.module.scss';

type RelationProps = {
  index: number;

  email: string;
};

const Relation = createView<RelationProps>()
  .units({
    values: relationsForm.$values,

    errors: relationsForm.$errors,

    careRecipients: $$careRecipient.$careRecipients,

    relations: $$dictionary.$relationships
  })

  .static({
    levels: Object.values(MembersRoleType)
      .filter(name => name !== MembersRoleType.Owner)

      .map(name => ({
        id: name,

        name: name
      }))
  })

  .map(({ careRecipients, email, index, values, relations }) => {
    const allSelected = (values[email] ?? []).map(state => state.clientId);

    const { value: clientId, onChange: onContactChange } = useField(
      relationsForm.fields[index][index].clientId
    );

    const { onChange: onEmailChange } = useField(
      relationsForm.fields[index][index].email
    );

    const recipients = careRecipients
      .filter(
        ({ id, isDeleted }) =>
          id == clientId || (!allSelected.includes(clientId) && !isDeleted)
      )

      .map(({ id, lastName, firstName }) => ({
        id,

        name: `${firstName} ${lastName}`.trim()
      }));

    const relationsValue = relations.map(name => ({
      id: name,

      name: name
    }));

    return {
      clientId,
      recipients,
      onEmailChange,
      onContactChange,
      relationsValue
    };
  })

  .view(
    ({
      clientId,
      index,
      relations,
      recipients,
      onContactChange,
      email,
      onEmailChange
    }) => (
      <div className={styles.relation}>
        <Select
          label='Contact For'
          placeholder='Select'
          menuPosition='fixed'
          value={clientId}
          options={recipients}
          onChange={value => {
            onContactChange(value);
            onEmailChange(email);
          }}
        />

        <Field.Select
          searchable={false}
          placeholder='Select'
          menuPosition='fixed'
          use={relationsForm.fields[index][index].relation}
          label='Relationship'
          options={relations
            .filter(({ name }) => name !== 'Myself')

            .map(({ name }) => ({ id: name, name: name }))}
        />
      </div>
    )
  );

const RelationPlaceholder = createView<{ onCancelClick: () => void }>()
  .units({
    onAddCareRecipientClick: addCareRecipientClicked as Event<any>
  })

  .view(({ onCancelClick, onAddCareRecipientClick }) => (
    <Modal.Container size='small' onClose={onCancelClick}>
      <Modal.Header>Invite new member</Modal.Header>

      <Modal.Body>
        <div className={styles.relationText}>
          <Text variant='body-1'>
            Please note that there are no Care Recipients in your Care Space
            yet.
          </Text>

          <Text variant='body-2'>
            To add a new member you must first add a Care Recipient.
          </Text>
        </div>

        <div>
          <Text variant='body-1'>
            According to{' '}
            <span className={styles.link}>service terms and conditions ,</span>{' '}
            any member must have at least one connection to the care recipient.
          </Text>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <ButtonGroup>
          <Button variant='text' onClick={() => onCancelClick()}>
            Cancel
          </Button>

          <Button onClick={onAddCareRecipientClick}>Add care recipient</Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal.Container>
  ));

const Relations = createView()
  .units({
    members: emailsForm.$values.map(state =>
      state.members.filter(item => item.email !== '')
    ),

    onCancelClick: cancelClicked,

    relationError: $relationError,

    careRecipients: $$careRecipient.$careRecipients.map(recipients =>
      recipients.filter(item => !item.isDeleted)
    ),

    onSendClick: relationsForm.submit.prepend(() => {}),

    onPreviousClick: goBackFromRelationsClicked.prepend(() => {})
  })

  .view(
    ({
      careRecipients,
      members,
      onCancelClick,
      relationError,
      onSendClick,
      onPreviousClick
    }) => {
      const md = useMedia('>=md');

      if (!careRecipients.length) {
        return <RelationPlaceholder onCancelClick={onCancelClick} />;
      }

      return (
        <Modal.Container
          size='small'
          onClose={onCancelClick}
          className={styles.modal}
        >
          <Modal.Header>
            Specify the relationship to care recipients
          </Modal.Header>

          <Modal.Body
            className={classNames(styles.body, {
              [styles.hasError]: relationError
            })}
          >
            {members.map((member, index) => (
              <Accordeon title={member.email} key={member.email + index} opened>
                <div className={styles.content}>
                  {careRecipients.map((item, _index) => (
                    <Relation
                      key={item.id}
                      email={member.email}
                      index={member?.id + _index}
                    />
                  ))}
                </div>
              </Accordeon>
            ))}

            {relationError ? (
              <AlertBox variant='error' className={styles.alertBox}>
                <p>Specify at least one relationship for each invited user.</p>
              </AlertBox>
            ) : null}
          </Modal.Body>

          <Modal.Footer space className={styles.footer}>
            <ButtonGroup>
              <Button variant='text' onClick={onPreviousClick} palette='text'>
                {md ? 'Previous step' : 'Previous'}
              </Button>
            </ButtonGroup>

            <div>
              <Button
                variant='text'
                onClick={() => onCancelClick()}
                palette='text'
                className={styles.cancel}
              >
                Cancel
              </Button>

              <Button onClick={onSendClick}>Continue</Button>
            </div>
          </Modal.Footer>
        </Modal.Container>
      );
    }
  );

export { Relations };
