import { useGate } from 'effector-react';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { Card } from '@shared/ui/atoms/card';

import { InfoAlertBox } from '@shared/ui/molecules/info-alert-box';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import {
  $passwordsDoNotMatch,
  closeErrorBoxClicked,
  form,
  TokenGate
} from '../model';

import styles from './set-password.module.scss';

type SetPasswordProps = {
  token: string;
};

const SetPassword = createView<SetPasswordProps>()
  .units({
    doNotMatch: $passwordsDoNotMatch,

    onCloseErrorBoxClick: closeErrorBoxClicked,

    repeatPassword: form.fields.repeatPassword.$value,

    password: form.fields.password.$value
  })

  .map(({ doNotMatch, token, password, repeatPassword }) => {
    const hasRepeatError = repeatPassword && password && doNotMatch;

    useGate(TokenGate, { token });

    return {
      hasRepeatError
    };
  })

  .view(({ doNotMatch, onCloseErrorBoxClick }) => (
    <Card>
      {doNotMatch && (
        <InfoAlertBox
          className={styles.errorBox}
          title='Passwords did not match'
          description='Please check it and try again.'
          onClose={onCloseErrorBoxClick}
        />
      )}

      <Form className={styles.form} use={form}>
        <div className={styles.fields}>
          <Field.Input
            use={form.fields.password}
            type='password'
            errorIn='label'
            placeholder='Enter your password'
            label='New password'
          />

          <Field.Input
            use={form.fields.repeatPassword}
            errorIn='label'
            type='password'
            placeholder='Enter your password'
            label='Re-enter your new password'
          />
        </div>

        <p className={styles.requirements}>
          Password should be 6 digits long and contain numbers and upper case
        </p>

        <Button className={styles.submit} type='submit'>
          Change password
        </Button>
      </Form>
    </Card>
  ));

export { SetPassword };
