import { combine, createEvent, createStore } from 'effector';

import { createEffect } from 'effector';

import { persist } from 'effector-storage/local';

import type { Client, UpdateMemberRelation } from '@shared/api';

import { carersHQ } from '@shared/api';

import { CARE_RECIPIENT_ID_KEY } from '@shared/config';

enum SignUpType {
  Carer = 'carer',
  Myself = 'myself',
  Loved = 'loved'
}

const getCareRecipientFx = createEffect<Client['id'], Client>();

const restoreClientFx = createEffect<Client['id'], void>();

const saveClientFx = createEffect<Client, Client>();

const updateMemberRelationsFx = createEffect<
  UpdateMemberRelation[],
  UpdateMemberRelation[]
>();

const deleteClientFx = createEffect<
  { id: string; password: string; reason: string },
  void
>();

const addCareRecipientFx = createEffect<{ values: any }, Client['id']>();

const resetCareRecipientProfile = createEvent();

const $careRecipientID = createStore<Client['id'] | null>(null);

const $careRecipients = createStore<Client[]>([]);

const $myself = $careRecipients.map(state => state[0] ?? null);

const $selectedCareRecipient = combine(
  $careRecipients,

  $careRecipientID,

  (careRecipients, id) => careRecipients.find(one => one.id == id)
);

const $hasCareRecipients = $careRecipients.map(
  state => state.filter(item => !item.isDeleted).length > 0
);

const $careRecipientsProfile = createStore<Client>(null).reset(
  resetCareRecipientProfile
);

const $$careRecipient = {
  $myself,
  $careRecipients,
  $careRecipientsProfile,
  $careRecipientID,
  $hasCareRecipients,
  $selectedCareRecipient,

  resetCareRecipientProfile,

  getCareRecipientFx,

  updateMemberRelationsFx,

  deleteClientFx,

  restoreClientFx,

  saveClientFx,

  addCareRecipientFx
};

persist({
  store: $careRecipientID,

  key: CARE_RECIPIENT_ID_KEY
});

getCareRecipientFx.use(async id => {
  const { data } = await carersHQ.clients.get({ id });

  return data;
});

updateMemberRelationsFx.use(async relations => {
  await carersHQ.workspaces.updateMemberRelations({
    relations
  });

  return relations;
});

deleteClientFx.use(async ({ reason, password, id }) => {
  await carersHQ.auth.deleteUser({
    id,
    reason,
    password
  });
});

restoreClientFx.use(async id => {
  await carersHQ.auth.restoreUser({ id });
});

addCareRecipientFx.use(async ({ values }) => {
  const client = valuesToClient(values);

  const response = await (values.clientType == SignUpType.Myself
    ? carersHQ.auth.addMyselfLoggedIn
    : carersHQ.auth.addLovedOneLoggedIn)({ client });

  return response.data.id;
});

saveClientFx.use(async client => {
  const { data } = await carersHQ.clients.update(client);

  return data;
});

$careRecipientsProfile
  .on(getCareRecipientFx.doneData, (_state, payload) => payload)

  .on(saveClientFx.doneData, (_state, payload) => payload);

function valuesToClient(values: any) {
  return {
    postCode: values.postCode,
    city: values.city,
    addressLine1: values.addressLine1,
    addressLine2: values.addressLine2,
    phone: values.phone,
    firstName: values.firstName,
    lastName: values.lastName,
    dateOfBirth: values.dateOfBirth,
    careStart: '2021-07-14T15:47:30',
    careStartDate: '2021-07-14T15:47:30',
    careEndDate: '2021-07-14T15:47:30',
    conditionsTypes: values.conditionsTypes,
    gender: values.gender,
    carerGender: values.carerGender,
    languages: values.languages,
    smoking: values.smokingOk,
    services: values.services,
    transportationHelp: values.transportationHelp
      ? values.transportationHelp
      : null,
    pets: values.pets,

    visitCareType: values.visitCareType,

    careTypes: values.careTypes,

    startingFromType: values.startingFromType,

    startingFromDate: values.startingFromDate,

    nightCareTypes: values.nightCareTypes
    // TODO: Fix
  } as any as Client;
}

export { $$careRecipient, valuesToClient };
