import classNames from 'classnames';

import moment from 'moment';

import type { FC, HTMLAttributes } from 'react';

import { useMemo, useState } from 'react';

import type { Entry } from '@shared/api';

import { useUpdate } from '@shared/lib/hooks';

import type { ControlProps } from '@shared/lib/types';

import { monthsOptions, wholeMonthsDaysOptions } from '@shared/lib/utils';

import { createView } from '@shared/lib/view';

import { useMedia } from '@shared/ui/atoms/media';

import { Select } from '@shared/ui/atoms/select';

import styles from './date-select-group.module.scss';

type DateSelectGroupProps = ControlProps<string> & {
  min?: string;

  max?: string;

  date?: boolean;

  yaer?: boolean;

  month?: boolean;

  searchable?: boolean;

  labelGap?: boolean;
};

enum DatePartName {
  Date = 'date',
  Month = 'month',
  Year = 'year'
}

type DateControl = {
  label: string;

  value: number;

  options: Entry[];

  name: DatePartName;

  placeholder: string;
};

const parseValue = (value: string): [number, number, number] => {
  if (!value) return [null, null, null];

  const date = moment(value);

  return [date.date() - 1, date.month(), date.year()];
};

const getMonthData = (value: [number, number, number]) => {
  const hasValues = [value[1], value[2]].every(
    one => one !== undefined && one !== null
  );

  const monthAndYearValue = hasValues
    ? moment({
        date: 1,
        month: value[1],

        year: value[2]
      })
    : null;

  const daysInMonth = monthAndYearValue?.daysInMonth();

  return {
    daysInMonth,
    monthAndYearValue
  };
};

const DateSelectGroup = createView<DateSelectGroupProps>()
  .defaultProps({
    year: true,

    month: true,

    date: true,

    min: moment()
      .year(1900)

      .month(0)

      .date(0)

      .format(),

    max: moment()
      .year(2100)

      .month(12)

      .date(31)

      .format()
  })

  .map(({ onChange, value, ...props }) => {
    const md = useMedia('>=md');

    const [_value, setValue] = useState(parseValue(value));

    const range = useMemo(() => {
      const min = moment(props.min);

      const max = moment(props.max);

      return {
        min,
        max
      };
    }, []);

    const years = useMemo(() => {
      const min = range.min.year();

      return new Array(range.max.year() - min)
        .fill(0)

        .map((_, index) => ({
          id: min + index,

          name: (min + index).toString()
        }))

        .reverse();
    }, [range]);

    const controls = useMemo(() => {
      const result: DateControl[] = [];
      const { monthAndYearValue, daysInMonth } = getMonthData(_value);

      if (props.date) {
        result.push({
          label: 'Date',

          value: _value[0],

          options: monthAndYearValue
            ? wholeMonthsDaysOptions.filter(item => item.id < daysInMonth)
            : wholeMonthsDaysOptions,

          name: DatePartName.Date,

          placeholder: 'Day'
        });
      }

      if (props.month) {
        result.push({
          label: 'Month',

          value: _value[1],

          options: monthsOptions,

          name: DatePartName.Month,

          placeholder: 'Month'
        });
      }

      if (props.year) {
        result.push({
          label: 'Year',

          value: _value[2],

          options: years,

          name: DatePartName.Year,

          placeholder: 'Year'
        });
      }

      return result;
    }, [props.year, range, years, _value, value]);

    const onControlChange = (value: number, name: DatePartName) => {
      const changed: [number, number, number] = [..._value];

      switch (name) {
        case DatePartName.Date:
          changed[0] = value;

          break;

        case DatePartName.Month:
          changed[1] = value;

          break;

        case DatePartName.Year:
          changed[2] = value;

          break;
      }

      const { daysInMonth } = getMonthData(changed);

      if (!props.date) {
        changed[0] = daysInMonth - 1;
      }

      const full = changed.every(one => one !== null);

      if (full) {
        onChange(
          moment()
            .year(changed[2])

            .month(changed[1])

            .date(changed[0] + 1)

            .format()
        );

        return;
      }

      setValue(changed);
    };

    return {
      setValue,
      controls,
      searchable: md,
      onControlChange
    };
  })

  .effect(({ value, setValue }) => {
    useUpdate(() => {
      setValue(parseValue(value));
    }, [value]);
  })

  .view(
    ({ hasError, controls, onControlChange, onBlur, onFocus, labelGap }) => (
      <>
        {controls.map(({ name, placeholder, label, value, options }, index) => (
          <Select
            key={index}
            value={value}
            hasError={hasError}
            searchable={false}
            label={label}
            labelGap={labelGap}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder}
            options={options}
            onChange={value => onControlChange(value, name)}
          />
        ))}
      </>
    )
  );

const DateSelectGroupContainer: FC<HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => <div className={classNames(className, styles.container)} {...props} />;

export { DateSelectGroup, DateSelectGroupContainer };
