import classNames from 'classnames';

import type { FC, HTMLAttributes } from 'react';

import styles from './box-row.module.scss';

const BoxRow: FC<
  HTMLAttributes<HTMLDivElement> & {
    layout: 'horizontal' | 'vertical' | 'horizontalAlways';
  }
> = ({ className, layout, ...props }) => (
  <div
    className={classNames(className, styles.boxRow, styles[layout])}
    {...props}
  />
);

export { BoxRow };
