import { createStore } from 'effector';

import { array, boolean, object, string } from 'yup';

import type { Certificate, Qualification } from '@shared/api';

import { createForm } from '@shared/lib/form';

type QualificationsValues = {
  qualificationConfirm: boolean;

  carerStart: string;

  certificates: Certificate['id'][];

  qualifications: Qualification['id'][];
};

const form = createForm<QualificationsValues>({
  initialValues: {
    qualificationConfirm: true,

    certificates: [] as number[],

    qualifications: [] as number[],

    carerStart: ''
  } as QualificationsValues,

  $schema: createStore(
    object({
      carerStart: string()
        .required()

        .nullable()

        // .test('min-date', 'Should be greater than birth date', testMinDate(''))
        //
        // .test('max-date', 'Cant be in future', testMaxDate(''))
        //
        // .test('min-3-months', 'Should be at least 3 months', testMinExperience(3))

        .label('Start date'),

      qualifications: array()
        .min(1, 'Required')

        .required()

        .nullable()

        .label('Qualifications'),

      qualificationConfirm: boolean()
        .required()

        .nullable()

        .label('Confirmation')

        .oneOf([true]),

      certificates: array()
        .required()
        .min(1, 'Required')
        .nullable()
        .label('Certificates')
    })
  )
});

const $$carerQualifications = {
  form
};

export type { QualificationsValues };

export { $$carerQualifications, form };
