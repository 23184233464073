import { useUnit } from 'effector-react';

import { $$dictionary } from '@entities/dictionary';

import { RelationId } from '@shared/api';

import { createView } from '@shared/lib/view';

import { Input } from '@shared/ui/atoms/input';

import { Select } from '@shared/ui/atoms/select';

import { Field } from '@shared/ui/organisms/field';

import type { createSetAccountRelations } from './model';

type SetAccountRelationsFormProps = {
  className?: string;
  use: ReturnType<typeof createSetAccountRelations>;
};

const SetAccountRelationsForm = createView<SetAccountRelationsFormProps>()
  .units({
    relations: $$dictionary.$relationships
  })

  .map(({ use }) => {
    const [relationShips] = useUnit([use.$relationShips]);

    return {
      careRecipients: relationShips.map(item => item.client)
    };
  })

  .view(({ className, relations, careRecipients, use }) => (
    <>
      {careRecipients.map((item, index) => (
        <div className={className} key={item.id}>
          <Input
            value={`${item.firstName} ${item.lastName}`}
            mode='view'
            disabled
            label='Contact For'
            onChange={() => {}}
          />

          <Field.Container use={use.form.fields.relations[index]}>
            {({ onChange, value }) => (
              <>
                {value.relation === RelationId.Myself ? (
                  <Input
                    value='Myself'
                    mode='view'
                    disabled
                    label='Relationship to care recipient'
                    onChange={() => {}}
                  />
                ) : (
                  <Select
                    value={value.relation}
                    onChange={relation =>
                      onChange({
                        ...value,

                        relation
                      })
                    }
                    options={relations}
                    label='Relationship to care recipient'
                  />
                )}
              </>
            )}
          </Field.Container>
        </div>
      ))}
    </>
  ));

export { SetAccountRelationsForm };
