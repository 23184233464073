import { combine, createEvent, createStore, sample } from 'effector';

import { modelFactory } from 'effector-factorio';

import { StepWizardModelFactory } from '@pages/sign-up/ui/sign-up-wizard-step';

import { CreateAccountModelFactory } from '@features/auth/sign-up';

import { ManageCareNeedsModelFactory } from '@features/care-recipient/manage-care-needs';

import { ManageConditionExperienceModelFactory } from '@features/care-recipient/manage-condition-experience';

import { ManageCareRecipientDetailsModelFactory } from '@features/care-recipient/manage-details';

import { ManagePreferencesModelFactory } from '@features/care-recipient/manage-preferences';

import { ManageAccountHolderModelFactory } from '@features/user/manage-account-holder-details';

import { $$session } from '@entities/session';

import { routes } from '@shared/routes';

const SignUpLovedOneModelFactory = modelFactory(() => {
  const createAccountBackClicked = createEvent();

  const $$createAccount = CreateAccountModelFactory.createModel();

  const $$createAccountWizard = StepWizardModelFactory.createModel({
    $total: createStore(0),

    $current: createStore(0),

    backClicked: createAccountBackClicked
  });

  const $total = $$session.$active.map(active => (active ? 4 : 5) as number);

  const $current = combine(
    routes.signUp.lovedOne.careNeeds.$isOpened,

    routes.signUp.lovedOne.conditionExperience.$isOpened,

    routes.signUp.lovedOne.preferences.$isOpened,

    routes.signUp.lovedOne.careRecipient.$isOpened,

    routes.signUp.lovedOne.accountHolder.$isOpened,

    (...opened) => opened.indexOf(true)
  );

  const steps = {
    preferences: ManagePreferencesModelFactory.createModel(),

    conditionExperience: ManageConditionExperienceModelFactory.createModel(),

    careNeeds: ManageCareNeedsModelFactory.createModel(),

    accountHolder: ManageAccountHolderModelFactory.createModel(),

    careRecipient: ManageCareRecipientDetailsModelFactory.createModel()
  };

  const wizards = {
    preferences: StepWizardModelFactory.createModel({
      $total,
      $current,
      nextClicked: steps.preferences.form.submit
    }),

    conditionExperience: StepWizardModelFactory.createModel({
      $total,
      $current,
      nextClicked: steps.conditionExperience.form.submit
    }),

    careNeeds: StepWizardModelFactory.createModel({
      $total,
      $current,
      nextClicked: steps.careNeeds.form.submit
    }),

    accountHolder: StepWizardModelFactory.createModel({
      $total,
      $current,
      nextClicked: steps.accountHolder.form.submit
    }),

    careRecipient: StepWizardModelFactory.createModel({
      $total,
      $current,
      nextClicked: steps.careRecipient.form.submit
    })
  };

  sample({
    clock: $$createAccountWizard.nextClicked,

    target: $$createAccount.form.submit
  });

  sample({
    clock: $$createAccount.form.submitted,

    target: $$createAccount.form.reset
  });

  return {
    $$createAccount,
    $$createAccountWizard,
    $total,
    $current,
    steps,
    wizards
  };
});

const $$signUpLovedOne = SignUpLovedOneModelFactory.createModel();

export { $$signUpLovedOne, SignUpLovedOneModelFactory };
