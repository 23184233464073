import { useMemo } from 'react';

import { shortDays } from '@features/wellness/wellness.constants';

import { $$careSpace } from '@entities/care-space';

import { $$wellness } from '@entities/wellness';

import { DashboardMarkTypeNames, DashboardMarkTypes } from '@shared/api';

import { createView } from '@shared/lib/view';

import { Chart } from './chart/chart.component';

import { Heading } from './heading/heading.component';

import { OptionsModal } from './options-modal/options-modal.component';

import styles from './wellness.module.scss';

import type { ChartData } from './wellness.props';

import { initialCharts } from './wellness.props';

import './wellness.model';

const Wellness = createView()
  .units({
    dashboards: $$wellness.$dashboard,

    wellnessActiveCharts: $$wellness.$wellnessActiveCharts,

    daysCount: $$wellness.$wellnessShowDays,

    isWellnessEditActive: $$wellness.$editingWellnessSettings,

    profileId: $$careSpace.$careSpaceID
  })
  .map(({ dashboards, wellnessActiveCharts }) => {
    const charts = useMemo<ChartData[]>(
      () =>
        initialCharts
          .filter(
            item =>
              item.type === wellnessActiveCharts.find(one => one === item.type)
          )

          .map(initialChart => {
            const data = dashboards.map(dashboard => {
              const date = new Date(dashboard.date);

              return {
                name: `${date.getDate()}/${date.getMonth() + 1}`,

                day: shortDays[date.getDay()],

                value: dashboard[
                  DashboardMarkTypeNames[
                    initialChart.type as keyof typeof DashboardMarkTypeNames
                  ] as keyof typeof dashboard
                ] as number
              };
            });

            return {
              ...initialChart,

              active: wellnessActiveCharts.includes(initialChart.type),

              data: data.every(({ value }) => value === null) ? [] : data
            };
          }),
      [wellnessActiveCharts, dashboards]
    );

    return {
      charts
    };
  })

  .view(({ charts, isWellnessEditActive }) => (
    <div className={styles.wellness} id='wellness'>
      <Heading />

      <div className={styles.barsContainer}>
        {charts.map(
          ({
            barColor,
            data,
            description,
            labelColor,
            title,
            active,
            type,
            maxLength,
            interval
          }) => (
            // if (!active) return;

            <Chart
              key={title}
              type={
                type === DashboardMarkTypes.BowelMovements ? 'Boolean' : 'Bar'
              }
              emptyVariant={DashboardMarkTypeNames[type]}
              barField='value'
              barLabelField='name'
              active={active}
              dataSource={data}
              barColor={barColor}
              title={title}
              description={description}
              labelColor={labelColor}
              maxLength={maxLength}
              interval={interval}
            />
          )
        )}
      </div>

      {isWellnessEditActive && <OptionsModal />}
    </div>
  ));

export { Wellness };
