import type { FC } from 'react';

import { memo, useEffect, useMemo, useState } from 'react';

import type { MediaProps } from './media.props';

const breakpoints: Record<string, number> = {
  xxs: 320,
  xs: 375,
  sm: 480,
  md: 768,
  lg: 1172,
  xl: 1200,
  xxl: 1366
};

let timeout: any = null;
let subscribers: Record<string, ((value: boolean) => void)[]> = {};

window.addEventListener('resize', () => {
  clearTimeout(timeout);

  timeout = setTimeout(() => {
    Object.entries(subscribers).forEach(([query, handlers]) => {
      const { matches } = matchMedia(query);

      handlers.map(handler => handler(matches));
    });
  }, 100);
});

const toRawQuery = (source: string[]) => {
  const result = source.map(text => {
    let value = breakpoints[text.replace(/\>|\=|\</gi, '')];

    switch (true) {
      case text.includes('>='):
        return `(min-width: ${value}px)`;

      case text.includes('>'):
        return `(min-width: ${value + 1}px)`;

      case text.includes('<='):
        return `(max-width: ${value + 1}px)`;

      case text.includes('<'):
        return `(max-width: ${value}px)`;

      default:
        return;
    }
  });

  return result.join(' and ');
};

const useMedia = (query: string | string[]) => {
  const [matches, setMatches] = useState(
    useMemo(
      () =>
        matchMedia(toRawQuery(Array.isArray(query) ? query : [query]))?.matches,
      []
    )
  );

  useEffect(() => {
    const raw = toRawQuery(Array.isArray(query) ? query : [query]);

    setMatches(matchMedia(raw)?.matches);

    if (!subscribers[raw]) subscribers[raw] = [];

    subscribers[raw].push(setMatches);

    return () => {
      subscribers[raw] = subscribers[raw].filter(item => item != setMatches);
    };
  }, [query]);

  return matches;
};

/**
 * <Media />
 */
const Media: FC<MediaProps> = memo(
  ({ query, children }) => (useMedia(query) ? children : null) as JSX.Element
);

export { Media, useMedia };
