import avatarPlaceholder from '@img/avatar-placeholder.png';

import type { FC } from 'react';

import { useMemo, useRef, useState } from 'react';

import { $$carerProfile } from '@features/carer/view-carer-profile';

import { $$teamCarers } from '@entities/carer/model';

import {
  SupportTypeId,
  supportTypeColor,
  visitCareTypeNames,
  VisitCareType
} from '@shared/api';

import type { CarerSupportType } from '@shared/api/models/carer-support-type';

import { useClickOutside } from '@shared/lib/hooks';

import { roundedPrice } from '@shared/lib/utils';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { ChipGroup } from '@shared/ui/atoms/chip';

import type { ChipProps } from '@shared/ui/atoms/chip/chip.props';

import { Dropdown } from '@shared/ui/atoms/dropdown';

import styles from './carer-card.module.scss';

import type { CarerCardProps, StatProps } from './carer-card.props';

const schedule = [
  {
    name: 'Mon',
    tip: 'biweekly',
    value: '9:00am - 12:00pm'
  },
  {
    name: 'Mon',
    tip: 'biweekly',
    value: '9:00am - 12:00pm'
  },
  {
    name: 'Mon',
    tip: 'biweekly',
    value: '9:00am - 12:00pm'
  },
  {
    name: 'Mon',
    tip: 'biweekly',
    value: '9:00am - 12:00pm'
  },
  {
    name: 'Mon',
    tip: 'biweekly',
    value: '9:00am - 12:00pm'
  },
  {
    name: 'Mon',
    tip: 'biweekly',
    value: '9:00am - 12:00pm'
  },
  {
    name: 'Mon',
    tip: 'biweekly',
    value: '9:00am - 12:00pm'
  },
  {
    name: 'Mon',
    tip: 'biweekly',
    value: '9:00am - 12:00pm'
  }
];

const Stat: FC<StatProps> = ({ name, value }) => (
  <div className={styles.stat}>
    <span className={styles.statName}>{name}: </span>

    <span className={styles.statRate}>{value}</span>
  </div>
);

const CarerCard = createView<CarerCardProps>()
  .map(({ carer }) => {
    const [moreVisible, setMoreVisible] = useState(false);
    const moreRef = useRef<HTMLDivElement>(null!);

    const careTypes = useMemo(() => {
      if (
        carer.supportTypes!.some(
          one => one.supportType!.id == SupportTypeId.LiveInCare
        )
      ) {
        return [
          {
            palette: supportTypeColor[SupportTypeId.LiveInCare],
            children: visitCareTypeNames[VisitCareType.LiveInCare]
          }
        ];
      }

      return carer
        .supportTypes!.filter(
          item =>
            item.supportType!.id == SupportTypeId.DayCare ||
            item.supportType!.id == SupportTypeId.WakingNight ||
            item.supportType!.id == SupportTypeId.SleepingNight
        )
        .map(item => ({
          palette: supportTypeColor[item.supportType!.id as SupportTypeId],

          children: item.supportType!.name
        }));
    }, [carer]);

    const lowestRate = useMemo(
      () =>
        carer.supportTypes!.reduce(
          (result, value) =>
            // @ts-expect-error TODO: fix
            Math.min(
              // @ts-expect-error TODO: fix
              result! as number,
              // @ts-expect-error TODO: fix
              value.rates![0]!.rate as number,
              // @ts-expect-error TODO: fix
              value.rates![1]?.rate as number
            ),

          carer.supportTypes?.[0]?.rates?.[0]?.rate
        ),
      [carer]
    );

    const onViewClick = () => {
      alert('Nowhere to go yet');
    };

    const onMessageClick = () => {
      alert('Nowhere to go yet');
    };

    const onHireClick = () => {
      alert('Nowhere to go yet');
    };

    const onMoreClick = () => {
      setMoreVisible(true);
    };

    const onViewProfileClick = () => {
      $$carerProfile.viewProfileOpened(carer.id);
    };

    const onRemoveConnectionClick = () => {
      $$teamCarers.removeConnectionClicked(carer);
    };

    useClickOutside(() => {
      setMoreVisible(false);
    }, moreRef);

    return {
      moreRef,
      careTypes,
      lowestRate,
      moreVisible,
      onMoreClick,
      onViewClick,
      onHireClick,
      onMessageClick,
      onViewProfileClick,
      onRemoveConnectionClick
    };
  })
  .view(
    ({
      type,
      carer,
      moreRef,
      careTypes,
      lowestRate,
      onViewClick,
      onMoreClick,
      onHireClick,
      moreVisible,
      onMessageClick,
      onViewProfileClick,
      onRemoveConnectionClick
    }) => (
      <div className={styles.carerCard}>
        <header className={styles.header}>
          <div className={styles.info}>
            <div className={styles.photo}>
              <img src={carer.imageUrl ?? avatarPlaceholder} />
            </div>

            <div className={styles.infoContent}>
              <div
                className={styles.name}
                title={`${carer?.firstName} ${carer?.lastName}`}
              >
                {carer?.firstName} {carer?.lastName}
              </div>

              <div className={styles.infoCheaps}>
                <ChipGroup chips={careTypes as ChipProps[]} />
              </div>
            </div>
          </div>

          <div className={styles.headerCheaps}>
            <ChipGroup chips={careTypes as ChipProps[]} />
          </div>

          <div className={styles.stats}>
            {type == 'connections' && (
              <>
                <Stat
                  name='Rate'
                  value={`from £${roundedPrice(
                    lowestRate! as number
                  )} per hour`}
                />

                <Stat
                  name='Carer experience'
                  value={`${carer.experience} years`}
                />

                <Stat
                  name='Services'
                  value={
                    carer.services
                      ?.map(item => item.name)
                      .filter((_, index) => index < 3)
                      .join(', ') +
                    (carer.services!.length! > 3
                      ? ` & ${carer.services!.length - 3} more`
                      : '')
                  }
                />
              </>
            )}

            {type == 'team' &&
              carer.supportTypes!.map((type, index) => (
                <Stat
                  name={(type as CarerSupportType)?.supportType?.name}
                  value={`£${roundedPrice(
                    (type as CarerSupportType).rates![0]?.rate?.toString()
                  )} / hour`}
                  key={index}
                />
              ))}
          </div>
        </header>

        <div className={styles.body}>
          {type == 'connections' && (
            <Stat name='Status' value='Scheduled a video interview(mock)' />
          )}

          {type == 'team' && (
            <ul className={styles.slots}>
              {schedule.map((item, index) => (
                <li className={styles.slot} key={index}>
                  <span className={styles.slotName}>
                    {item.name}{' '}
                    <span className={styles.slotTip}>({item.tip})</span>
                  </span>

                  <span className={styles.slotTime}>{item.value}</span>
                </li>
              ))}
            </ul>
          )}
        </div>

        <footer className={styles.footer}>
          {type == 'team' && (
            <Button className={styles.footerAction} onClick={onViewClick}>
              View agreement
            </Button>
          )}

          {type == 'connections' && (
            <Button className={styles.footerAction} onClick={onHireClick}>
              Hire {carer?.firstName}
            </Button>
          )}

          {type == 'shortlist' && (
            <Button className={styles.footerAction} onClick={onMessageClick}>
              Message {carer?.firstName}
            </Button>
          )}

          <div className={styles.moreContainer} ref={moreRef}>
            <Button
              className={styles.more}
              palette='secondary'
              onClick={onMoreClick}
            >
              <div className={styles.moreIcon} />
            </Button>

            {moreVisible && (
              <Dropdown className={styles.moreDropdown}>
                <Dropdown.Item onClick={onViewProfileClick}>
                  View carer profile
                </Dropdown.Item>

                {type == 'team' && (
                  <>
                    <Dropdown.Item>Pause care agreement</Dropdown.Item>

                    <Dropdown.Item type='danger'>
                      Cancel care agreement
                    </Dropdown.Item>
                  </>
                )}

                {type == 'connections' && (
                  <Dropdown.Item
                    type='danger'
                    onClick={onRemoveConnectionClick}
                  >
                    Remove connection
                  </Dropdown.Item>
                )}
              </Dropdown>
            )}
          </div>
        </footer>
      </div>
    )
  );

export { CarerCard };
