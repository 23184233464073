import { $$signUpPage } from '@pages/sign-up/sign-up.model';

import { PostcodeCheck } from '@features/postcode/check';

import { CtaBanner } from '@shared/ui/atoms/cta-banner';

import { Media } from '@shared/ui/atoms/media';

import styles from './postcode.module.scss';

type PostcodeProps = {
  asCarer?: boolean;

  asClient?: boolean;
};

const Postcode = ({ asCarer, asClient }: PostcodeProps) => (
  <div className={styles.postcodeCheck}>
    <CtaBanner className={styles.banner}>
      {asCarer && (
        <p className={styles.carer}>
          Hi, I’m Alice.
          <br />
          I’ll help you with your application to become{' '}
          <Media query='>=md'>
            <br />
          </Media>{' '}
          a CarersHQ carer -
          <br className={styles.visibleSm} /> in just a few steps.
        </p>
      )}

      {asClient && (
        <p>
          Hi, I’m Alice. I’ll help you <br className={styles.visibleSm} />{' '}
          arrange exceptional <br />
          care in just a few steps.
        </p>
      )}

      <p>First, let’s check if we’re in your area.</p>
    </CtaBanner>

    <PostcodeCheck model={$$signUpPage.$$postCodeCheck} />
  </div>
);

export { Postcode };
