const capitalize = (source: string) =>
  source ? source.charAt(0).toUpperCase() + source.substring(1) : source;

const shortener = (source: string, maxLenght = 35) =>
  source.length > maxLenght ? `${source.slice(0, maxLenght)}...` : source;

const roundedPrice = (value: string | number) => {
  if (!value && value != 0) return '';

  let _value = parseFloat(value?.toString()).toString();

  if (!_value.includes('.')) return _value;

  let [left, right] = _value.split('.').map(item => Number(item));

  let rest = right % 10;

  if (rest != 0) {
    right = rest >= 5 ? right + (10 - rest) : right - rest;
  }

  return Number(`${left}.${right}`);
};

const displayRate = (rate: number) =>
  rate ? Math.round(rate * 1.25 * 2) / 2 : 0;

export { capitalize, shortener, roundedPrice, displayRate };
