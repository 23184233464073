import type { FC } from 'react';

import type { Entry } from '@shared/api';

import { Radio } from '@shared/ui/atoms/radio';

import type { RadioProps } from '@shared/ui/atoms/radio/radio.props';

type RadioListProps = Omit<RadioProps, 'payload'> & {
  options: Entry<any>[];
};

const RadioList: FC<RadioListProps> = ({ options, ...props }) => (
  <>
    {options.map((item, index) => (
      <Radio key={index} {...props} payload={item.id} label={item.name} />
    ))}
  </>
);

export { RadioList };
