// import type { RouteInstance } from 'atomic-router';

import { createEvent } from 'effector';

// import type { Event, Store, StoreValue } from 'effector';

// import { createEvent } from 'effector';

// import { createStore } from 'effector';

// import { sample } from 'effector';

// import { createEffect } from 'effector';

// import type { BrowserHistory, Transition } from 'history';

// import type { History } from 'history';

// import { not, empty } from 'patronum';

// import { router } from '@shared/routes';

// type Options = {
//   from: RouteInstance<any>;

//   when: Store<boolean>;

//   onLeave?: Event<void>;

//   allowOn?: Event<void>;
// };

// const blockTransition = ({
//   from: route,
//   when: $when,
//   onLeave,
//   allowOn
// }: Options) => {
//   const { $history } = router;

//   const transitionAttempted = createEvent<Transition>();

//   const $transition = createStore<Transition>(null!);

//   const $subscription = createStore<ReturnType<BrowserHistory['block']>>(null!);

//   const blockFx = createEffect<History, ReturnType<BrowserHistory['block']>>();

//   const unblockFx = createEffect<ReturnType<BrowserHistory['block']>, void>();

//   const applyTransitionFx = createEffect<
//     { tsx: Transition; unblock: StoreValue<typeof $subscription> },
//     void
//   >(({ tsx, unblock }) => {
//     unblock();

//     tsx.retry();
//   });

//   sample({
//     clock: [
//       sample({ clock: $when.updates, filter: when => when }),

//       sample({
//         clock: route.$isOpened.updates.filterMap(Boolean),

//         source: $when,

//         filter: when => when
//       })
//     ],

//     source: {
//       blocked: not(empty($subscription)),

//       history: $history
//     },

//     filter: ({ blocked }) => !blocked,

//     fn: ({ history }) => history,

//     target: blockFx
//   });

//   sample({
//     clock: [
//       route.closed,

//       sample({
//         clock: $when.updates,

//         filter: when => !when
//       })
//     ],

//     source: $subscription,

//     target: unblockFx
//   });

//   if (allowOn) {
//     sample({
//       clock: allowOn,

//       source: {
//         tsx: $transition,

//         unblock: $subscription
//       },

//       target: applyTransitionFx
//     });
//   }

//   blockFx.use(history =>
//     history.block(tsx => {
//       onLeave?.();

//       transitionAttempted(tsx);

//       return false;
//     })
//   );

//   unblockFx.use(unblock => {
//     unblock?.();
//   });

//   $subscription
//     .on(blockFx.doneData, (_, unblock) => unblock)

//     .reset(route.closed);

//   $transition
//     .on(transitionAttempted, (_, tsx) => tsx)

//     .reset(route.closed);

//   return {};
// };

// export { blockTransition };

const pathChanged = createEvent();

export { pathChanged };
