import {
  $$profilePreferences,
  PreferencesForm
} from '@features/care-recipient/profile/preferences';

import { $$careRecipient } from '@entities/care-recipient';

import { createView } from '@shared/lib/view';

import { EditableSection } from '@shared/ui/atoms/editable-section';

import { useMedia } from '@shared/ui/atoms/media';

import { TextPair } from '@shared/ui/atoms/text-pair';

import { $$profilePage } from '../../model';

import styles from './preferences.module.scss';

/**
 * <Preferences />
 */
const Preferences = createView()
  .units({
    client: $$careRecipient.$careRecipientsProfile,

    editing: $$profilePage.preferences.$opened,

    onSave: $$profilePreferences.form.submit,

    onCancel: $$profilePage.preferences.close,

    onEdit: $$profilePage.preferences.open
  })

  .map(() => ({
    md: useMedia('>=md')
  }))

  .view(
    ({
      md,

      client,

      editing,

      onSave,

      onCancel,

      onEdit
    }) => (
      <EditableSection
        title='Preferences'
        editing={editing}
        onSave={() => onSave()}
        onCancel={() => onCancel()}
        onEdit={() => onEdit()}
      >
        {({ editing }) =>
          editing ? (
            <PreferencesForm />
          ) : (
            <div className={styles.fields}>
              <TextPair
                label='Language:'
                value={client?.languages.map(item => item.name).join(', ')}
              />

              <TextPair
                label='Gender preferences:'
                value={client?.carerGender?.name || 'No preference'}
              />

              <TextPair
                label='Transportations needs:'
                spacing={md ? 'normal' : 'multiline'}
                value={client?.transportationHelp?.name || 'None'}
              />

              <TextPair
                label='Smoking in the house:'
                value={client?.smoking ? 'Yes' : 'No'}
              />

              <TextPair
                label='Pets in the house:'
                value={client?.pets.map(item => item.name).join(', ') || 'None'}
              />
            </div>
          )
        }
      </EditableSection>
    )
  );

export { Preferences };
