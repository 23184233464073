import { createEffect } from 'effector';

import type { Credentials } from '@shared/api';

import { carersHQ } from '@shared/api';

const checkCredentialsFx = createEffect<Credentials, Credentials>();

const $$checkUserCredentials = {
  checkCredentialsFx
};

checkCredentialsFx.use(async credentials => {
  const {
    data: { success }
  } = await carersHQ.auth.checkCredentials(credentials);

  if (!success) {
    throw new Error('Invalid credentials');
  }

  return credentials;
});

export { $$checkUserCredentials };
