import { createEffect, createEvent, restore } from 'effector';

import type { Workspace, WorkspaceMember } from '@shared/api';

import { carersHQ } from '@shared/api';

const membersLoaded = createEvent<WorkspaceMember[]>();

const getCareSpaceMembersFx = createEffect<
  Workspace['id'],
  WorkspaceMember[]
>();

const $members = restore(membersLoaded, []);

const $$member = {
  $members,

  membersLoaded,
  getCareSpaceMembersFx
};

getCareSpaceMembersFx.use(async workspaceId => {
  const { data } = await carersHQ.workspaces.getMembers({ workspaceId });

  return data.members;
});

export { $$member };
