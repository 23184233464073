import { object, string } from 'yup';

import { withoutCyrilic } from '@shared/lib/utils';

const contactDetailsSchema = object({
  postCode: string()
    .nullable()

    .required('Required')

    .trim('Required')

    .matches(/^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i, 'Invalid')

    .label('Postcode'),

  city: string()
    .nullable()

    .required('Required')

    .matches(withoutCyrilic, {
      excludeEmptyString: true,
      message: 'Invalid format'
    })

    .trim('Required')

    .label('City'),

  addressLine1: string()
    .nullable()

    .required('Required')

    .matches(withoutCyrilic, {
      excludeEmptyString: true,
      message: 'Invalid format'
    })

    .trim('Required')

    .label('Address line 1'),

  addressLine2: string()
    .nullable()

    .matches(withoutCyrilic, {
      excludeEmptyString: true,
      message: 'Invalid format'
    })

    .label('Address line 2'),

  phone: string()
    .nullable()

    .required('Required')

    .min(2, 'Required')

    .matches(/^\+?\d+$/, 'Invalid')

    .label('Phone')
});

export { contactDetailsSchema };
