import { attach, combine, createEvent, sample } from 'effector';

import { $$accountInfo } from '@entities/account';

import { $$member } from '@entities/care-space';

import { carersHQ } from '@shared/api';

import { createForm } from '@shared/lib/form';

type Values = {
  relations: { clientId: string; relation: number; email: string }[];
};

const createSetAccountRelations = () => {
  const updateRelationFx = attach({
    effect: carersHQ.workspaces.updateMemberRelations
  });

  const form = createForm<Values>({
    initialValues: {
      relations: []
    }
  });

  const $relationShips = combine(
    $$accountInfo.$account,

    $$member.$members,

    (account, members) =>
      (
        members.find(one => one?.account?.id == account?.id)?.account
          ?.relations ?? []
      ).map(item => ({
        ...item,
        email: account?.email ?? ''
      }))
  );

  const resetRelations = createEvent();

  sample({
    clock: resetRelations,

    source: $relationShips,

    fn: relations => ({
      relations: relations.map(item => ({
        clientId: item.client.id,

        email: item.email,

        relation: item.clientRelation.id as number
      }))
    }),

    target: form.reset
  });

  sample({
    clock: form.submitted,

    fn: ({ relations }) => ({
      relations: relations.map(item => ({
        email: item.email,
        clientId: item.clientId,
        relation: { id: item.relation, name: '' }
      }))
    }),

    target: updateRelationFx
  });

  return {
    form,
    resetRelations,
    $relationShips,
    updateRelationFx
  };
};

export { createSetAccountRelations };
