import type { Store } from 'effector';

import { createEffect, sample } from 'effector';

import { combineEvents, not, some } from 'patronum';

import { condition } from 'patronum';

import { $$authorize } from '@processes/authorize';

import { $$boot } from '@processes/boot';

import { $$logout } from '@processes/logout';

import { $$addPayments } from '@widgets/add-payments/model';

import { $$emailConfirmation } from '@widgets/email-confirmation';

import { $$emailConfirmed } from '@widgets/email-confirmed';

import { $$restoreCareSpace } from '@features/care-space/restore';

import { $$selectCareSpace } from '@features/care-space/select';

import { $$session, RoleType } from '@entities/session';

import { $$user } from '@entities/user';

import { pathChanged } from '@shared/lib/router';

import { routes } from '@shared/routes';

import { history, router } from './router';

import '@processes/sign-up';

import '@processes/switch-role';

import '@processes/add-care-recipient';

const resetScrollFx = createEffect();

const loadedAfterAuth = combineEvents({
  events: [$$authorize.logged, $$boot.loaded],

  reset: sample({
    clock: $$logout.loggedOut
  })
});

sample({
  clock: [$$session.roleChanged, $$authorize.logged.map(({ role }) => role)],

  target: $$boot.load
});

sample({
  clock: [$$boot.load, loadedAfterAuth],

  source: $$session.$role,

  filter: $$boot.$ready,

  target: $$authorize.startRouteRedirectTriggered
});

sample({
  clock: $$selectCareSpace.careSpaceChanged,

  target: $$boot.load
});

sample({
  clock: $$logout.loggedOut,

  fn: () => false,

  target: [$$boot.$emailConfirmationOpenedOnce, $$boot.$addPaymentOpenedOnce]
});

sample({
  clock: router.$path.updates,

  target: resetScrollFx
});

sample({
  clock: routes.schedule.opened,

  source: $$session.$active,

  filter: session => !session,

  target: routes.auth.signIn.open
});

sample({
  clock: router.$path.updates,

  target: pathChanged
});

condition({
  source: router.routeNotFound,

  if: $$session.$active,

  then: routes.schedule.open.prepend(() => ({})),

  else: routes.auth.signIn.open.prepend(() => ({}))
});

sample({
  clock: [router.initialized, $$boot.loaded],

  source: {
    account: $$user.$account,

    role: $$session.$role,

    notOnMainPage: some({
      predicate: Boolean,

      stores: [
        routes.careSpaceDeletion.confirmed.$isOpened,
        routes.leaveCareSpace.confirmed.$isOpened,
        routes.signUp.any.$isOpened
      ]
    }),

    emailConfirmationOpenedOnce: $$boot.$emailConfirmationOpenedOnce
  },

  filter: ({ notOnMainPage, account, emailConfirmationOpenedOnce, role }) =>
    !account?.emailConfirmed &&
    role !== RoleType.Carer &&
    !emailConfirmationOpenedOnce &&
    !notOnMainPage,

  fn: () => true,

  target: [$$emailConfirmation.opened, $$boot.$emailConfirmationOpenedOnce]
});

sample({
  clock: $$boot.loaded,

  source: {
    account: $$user.$account,

    addPaymentOpenedOnce: $$boot.$addPaymentOpenedOnce
  },

  filter: ({ account, addPaymentOpenedOnce }) =>
    !addPaymentOpenedOnce && account?.emailConfirmed && !account.billingActive,

  fn: () => true,

  target: [$$addPayments.opened, $$boot.$addPaymentOpenedOnce]
});

sample({
  clock: router.initialized,

  source: {
    query: router.$query as Store<any> as Store<{
      emailConfirmed: string;
    }>
  },

  filter: ({ query: { emailConfirmed } }) => emailConfirmed == 'true',

  target: [$$emailConfirmed.opened, routes.matches.open]
});

sample({
  clock: routes.careSpaceDeletion.confirmed.$isOpened,

  source: $$session.$active,

  filter: active => !active,

  fn: () => ({}),

  target: routes.auth.signIn.open
});

sample({
  clock: router.initialized,

  source: {
    query: router.$query as Store<any> as Store<{
      accountRestored: string;
    }>
  },

  filter: ({ query: { accountRestored } }) => accountRestored == 'true',

  target: [$$restoreCareSpace.setRestoredStatus, routes.matches.open]
});

// sample({
//   clock: router.initialized,
//
//   source: {
//     query: router.$query as Store<any> as Store<{
//       inviteAccepted: string;
//     }>
//   },
//
//   filter: ({ query: { inviteAccepted } }) => inviteAccepted == 'true',
//
//   target: routes.auth.signUpInvite.open
// });

sample({
  clock: $$boot.booted,

  fn: () => history,

  target: router.setHistory
});

sample({
  clock: routes.matches.opened,

  filter: not($$session.$active),

  target: routes.auth.signIn.open
});

resetScrollFx.use(() => {
  window.scrollTo(0, 0);
});
