import classNames from 'classnames';

import { AccountType } from '@shared/api';

import { createView } from '@shared/lib/view';

import { AlertBox } from '@shared/ui/atoms/alert-box';

import { Button } from '@shared/ui/atoms/button';

import { FormSection } from '@shared/ui/atoms/form-section';

import { Modal } from '@shared/ui/atoms/modal/modal.component';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { $$billingDetails, billingDetailsForm } from '../../model/details';

import styles from './details.module.scss';

import { useDetailsProps } from './details.props';

const placeholder = (
  <div>
    We didn't find that address. <br /> Just keep typing it in, and we'll add
    it.
  </div>
);

/**
 * <Details />
 */
const Details = createView()
  .units({
    accountTypeValue: billingDetailsForm.fields.accountType?.$value,

    isError: $$billingDetails.$isError
  })
  .map(useDetailsProps)
  .view(
    ({
      isError,
      cities,
      closed,
      details,
      publicDetails,
      addressLines1,
      addressLines2,
      accountTypeValue,
      onSuggestionSelect
    }) => (
      <Modal.Container className={styles.modal} onClose={() => closed()}>
        <Modal.Header>
          {details ? 'Change billing details' : 'Add billing details'}
        </Modal.Header>

        <Modal.Body
          className={classNames({
            [styles.bottomMargin]: isError
          })}
        >
          <Form use={billingDetailsForm} className={styles.form}>
            {!publicDetails && (
              <AlertBox className={styles.alert} variant='warning'>
                <p className={styles.bold}>
                  We take care of our clients and carers by providing a safe
                  service for each party.
                </p>

                <p className={styles.description}>
                  We ask that you provide your billing details so that you can
                  start working with carers.{' '}
                  <span>
                    We will not make any payments until you have signed your
                    first care agreement and have received services.
                  </span>
                </p>

                <p>
                  Any questions?{' '}
                  <a target='_blank' href='https://google.com' rel='noreferrer'>
                    {' '}
                    Contact our support center.
                  </a>
                </p>
              </AlertBox>
            )}

            <FormSection title='Select account type'>
              <div className={styles.types}>
                <Field.Radio
                  use={billingDetailsForm.fields.accountType}
                  payload={AccountType.UK}
                  label='UK account'
                />

                <Field.Radio
                  use={billingDetailsForm.fields.accountType}
                  payload={AccountType.EU}
                  label='EU account'
                />
              </div>
            </FormSection>

            <FormSection
              className={styles.typeRelatedSection}
              title='Enter billing details'
            >
              <div className={styles.typeRelatedSectionFields}>
                {accountTypeValue == AccountType.UK ? (
                  <>
                    <div className={styles.sortCode}>
                      <Field.Input
                        use={billingDetailsForm.fields.sortCode}
                        key='sortCode'
                        className={styles.sortCode}
                        name='sortCode'
                        label='Sort code'
                        errorIn='label'
                        placeholder='Enter'
                      />
                    </div>

                    <div className={styles.container}>
                      <Field.Input
                        use={billingDetailsForm.fields.accountNumber}
                        key='accountNumber'
                        name='accountNumber'
                        label='Account number'
                        errorIn='label'
                        placeholder='Enter'
                      />
                    </div>
                  </>
                ) : (
                  <div className={styles.container}>
                    <Field.Input
                      use={billingDetailsForm.fields.IBAN}
                      key='IBAN'
                      name='IBAN'
                      label='IBAN'
                      errorIn='label'
                      placeholder='Enter'
                    />
                  </div>
                )}
              </div>
            </FormSection>

            <div className={styles.contactDetails}>
              <Field.Input
                use={billingDetailsForm.fields.firstName}
                className={styles.firstName}
                name='firstName'
                errorIn='label'
                label='First name'
                placeholder='Enter'
              />

              <Field.Input
                use={billingDetailsForm.fields.lastName}
                className={styles.lastName}
                name='lastName'
                errorIn='label'
                label='Last name'
                placeholder='Enter'
              />

              <Field.Input
                use={billingDetailsForm.fields.country}
                className={styles.country}
                name='country'
                errorIn='label'
                label='Country'
                placeholder='Enter'
              />

              <Field.Input
                use={billingDetailsForm.fields.region}
                className={styles.region}
                name='region'
                errorIn='label'
                placeholder='Enter'
                label='Region'
              />

              <Field.Input
                use={billingDetailsForm.fields.postalCode}
                className={styles.postCode}
                name='postalCode'
                errorIn='label'
                label='Postcode'
                placeholder='Enter'
              />

              <Field.SuggestionInput
                use={billingDetailsForm.fields.city}
                className={styles.city}
                name='city'
                errorIn='label'
                placeholder='Enter'
                label='City'
                suggestions={cities}
                suggestionsPlaceholder={placeholder}
                onSuggestionSelect={onSuggestionSelect('city')}
              />

              <div
                className={classNames(styles.container, styles.addressLine1)}
              >
                <Field.SuggestionInput
                  use={billingDetailsForm.fields.addressLine1}
                  className={styles.addressLine1}
                  name='addressLine1'
                  placeholder='Enter'
                  errorIn='label'
                  label='Address line 1'
                  suggestions={addressLines1}
                  suggestionsPlaceholder={placeholder}
                  onSuggestionSelect={onSuggestionSelect('addressLine1')}
                />
              </div>

              <div
                className={classNames(styles.container, styles.addressLine2)}
              >
                <Field.SuggestionInput
                  use={billingDetailsForm.fields.addressLine2}
                  className={styles.addressLine2}
                  name='addressLine2'
                  placeholder='Enter'
                  errorIn='label'
                  label={
                    <div>
                      Address line 2
                      <span className={styles.optional}>(Optional)</span>
                    </div>
                  }
                  suggestions={addressLines2}
                  suggestionsPlaceholder={placeholder}
                  onSuggestionSelect={onSuggestionSelect('addressLine2')}
                />
              </div>
            </div>

            {isError && (
              <AlertBox variant='error' className={styles.alertError}>
                <p>Some billing details are invalid</p>
              </AlertBox>
            )}
          </Form>
        </Modal.Body>

        <Modal.Footer className={styles.footer}>
          <Button
            palette='text'
            variant='text'
            className={styles.cancel}
            onClick={() => closed()}
          >
            I'll do it later
          </Button>

          <Button onClick={() => billingDetailsForm.submit()}>
            {details ? 'Save and continue' : 'Add billing details'}
          </Button>
        </Modal.Footer>
      </Modal.Container>
    )
  );

export { Details };
