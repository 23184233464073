import { createEffect, createEvent, createStore } from 'effector';

import type { Carer, Client } from '@shared/api';

import { carersHQ } from '@shared/api';

const removeConnectionFx = createEffect<
  { carer: Carer; careRecipientID: Client['id'] },
  Carer
>();

const removeConnectionClicked = createEvent<Carer>();

const $team = createStore<Carer[]>([]);

const $connections = createStore<Carer[]>([]);

const $shortlist = createStore<Carer[]>([]);

const $$teamCarers = {
  $team,
  $shortlist,
  $connections,

  removeConnectionClicked,

  removeConnectionFx
};

removeConnectionFx.use(async ({ carer, careRecipientID }) => {
  await carersHQ.clients.removeConnection({
    carerId: carer.id,
    clientId: careRecipientID
  });

  return carer;
});

export { $$teamCarers };
