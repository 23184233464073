import photo from '@img/banner-avatar.png';

import { Auth } from '@layouts/auth';

import { $$logoutUser } from '@features/user/logout';

import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { Button } from '@shared/ui/atoms/button';

import { Card } from '@shared/ui/atoms/card';

import { Text } from '@shared/ui/atoms/text';

import { styled } from '@shared/ui/stitches';

const AccountBlocked = createView()
  .units({
    onLogoutClick: $$logoutUser.logout.prepend(() => {})
  })

  .view(({ onLogoutClick }) => (
    <Auth.Container>
      <Auth.Header centered={false} logoTo={routes.schedule}>
        <Logout variant='body-2' onClick={onLogoutClick}>
          Log out
        </Logout>
      </Auth.Header>

      <Auth.Body>
        <AlertCard>
          <Avatar>
            <Photo src={photo} alt='Alice' />
          </Avatar>

          <CardTitle>Don't worry we’ll help you</CardTitle>

          <CardText>
            You may have just forgotten your account,
            <br /> we will help you restore it.
          </CardText>

          <Divider />

          <ContactUs>
            <a href='mailto:blablabla@carers.com'>Contact us</a>
          </ContactUs>
        </AlertCard>
      </Auth.Body>
    </Auth.Container>
  ));

const ContactUs = styled(Button, {
  width: '100%',
  marginTop: '$4'
});

const Divider = styled('div', {
  height: '1px',
  background: '$textBlack',
  width: '100%',
  opacity: '0.1'
});

const CardTitle = styled('h3', {
  fontSize: '15px',
  fontWeight: 500,
  lineHeight: '23px',
  marginBottom: '4px',

  '@md': {
    marginBottom: '6px'
  }
});

const CardText = styled('p', {
  maxWidth: '298px',
  marginBottom: '20px',
  textAlign: 'center',
  lineHeight: '25px',
  fontWeight: 300,
  fontSize: '15px',

  '& br': {
    display: 'none'
  },

  '@md': {
    maxWidth: 'unset',
    marginBottom: '32px',
    lineHeight: '23px',

    '& br': {
      display: 'initial'
    }
  }
});

const Avatar = styled('div', {
  marginBottom: '20px',

  '@md': {
    marginBottom: '40px'
  }
});

const Photo = styled('img', {
  size: '72px',

  '@md': {
    size: '100px'
  }
});

const AlertCard = styled(Card, {
  flexCol: true,
  flexCenter: true,
  mx: 'auto',
  mt: '48px',
  padding: '24px 16px 16px',

  '@md': {
    mt: '120px',
    padding: '40px 56px 32px',
    width: '540px'
  }
});

const Logout = styled(Text, {
  color: '$blue',
  cursor: 'pointer',
  transition: '0.25s',
  fontWeight: 500,

  '&:hover': {
    color: '$yellowHovered'
  },

  '&:active': {
    color: '$yellowPrimary'
  }
});

export { AccountBlocked };
