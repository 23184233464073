import { $$careRecipient } from '@entities/care-recipient';

import { $$dictionary } from '@entities/dictionary';

import { $$members } from '@entities/members';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { Input } from '@shared/ui/atoms/input';

import { Modal } from '@shared/ui/atoms/modal';

import { Select } from '@shared/ui/atoms/select';

import { $$contactEditRelationship } from '../model';

import styles from './edit-relationship.module.scss';

import type { EditRelationshipProps } from './edit-relationship.props';

const EditRelationship = createView<EditRelationshipProps>()
  .units({
    members: $$members.$members,
    client: $$careRecipient.$careRecipientsProfile,

    onClose: $$contactEditRelationship.modalClosed,

    relations: $$dictionary.$relationships,

    selectedRelation: $$contactEditRelationship.$selectedRelation,

    selectedMember: $$contactEditRelationship.$selectedMember,

    relationChanged: $$contactEditRelationship.changeRelation,

    submitChanges: $$contactEditRelationship.submitChanges
  })

  .map(({ relations, members }) => {
    const membersHasMyself = members.some(el =>
      el.account.relations.some(item => item.clientRelation.name === 'Myself')
    );

    const filteredRelations = membersHasMyself
      ? relations.filter(item => item.name !== 'Myself')
      : relations;

    return {
      filteredRelations
    };
  })

  .view(
    ({
      client,
      onClose,
      filteredRelations,
      selectedMember,
      selectedRelation,
      relationChanged,
      submitChanges
    }) => (
      <Modal.Container onClose={() => onClose()} size='small'>
        <Modal.Header>{`Edit details for ${client?.firstName} ${client?.lastName}`}</Modal.Header>

        <Modal.Body>
          <div className={styles.row}>
            <Input
              mode='view'
              label='Contact'
              value={`${selectedMember?.firstName} ${selectedMember?.lastName}`}
              onChange={() => {}}
            />

            <Select
              mode={selectedRelation?.name === 'Myself' ? 'view' : 'select'}
              onChange={value => relationChanged(value)}
              searchable={false}
              defaultValue={selectedRelation}
              value={selectedRelation}
              options={filteredRelations}
              menuPosition='fixed'
              label='Relationship'
            />
          </div>
        </Modal.Body>

        <Modal.Footer className={styles.footer}>
          <Button
            onClick={() => onClose()}
            palette='text'
            variant='text'
            className={styles.cancel}
          >
            Cancel
          </Button>
          <Button onClick={() => submitChanges()}>Save and continue</Button>
        </Modal.Footer>
      </Modal.Container>
    )
  );

export { EditRelationship };
