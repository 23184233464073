import { sample } from 'effector';

import { not } from 'patronum';

import { $$signUpLovedOne } from '@pages/sign-up/pages/loved-one';

import { $$signUpPage } from '@pages/sign-up/sign-up.model';

import { $$signUp } from '@features/auth/sign-up';

import { $$session } from '@entities/session';

import { ErrorCode } from '@shared/api';

import { routes } from '@shared/routes';

import { $lovedOneValues, lovedOneValuesToDto } from './shared';

const submitted = sample({
  clock: $$signUpLovedOne.$$createAccount.form.submitted,

  source: {
    values: $lovedOneValues
  },

  filter: not($$session.$active),

  fn: ({ values }, { email, password }) => ({
    email,
    password,
    ...lovedOneValuesToDto(values, true)
  })
});

sample({
  clock: submitted,

  target: $$signUp.signUpLovedFx
});

sample({
  clock: $$signUp.signUpLovedFx.failData,

  fn: () => true,

  target: $$signUpPage.$hasAnAccount
});

sample({
  clock: $$signUp.signUpLovedFx.failData,

  source: $lovedOneValues,

  filter: (_, code) => code == ErrorCode.EmailAlreadyInUse,

  fn: values => values.accountHolder.firstName,

  target: $$signUpPage.$firstName
});

sample({
  clock: routes.signUp.lovedOne.createAccount.closed,

  target: $$signUpLovedOne.$$createAccount.form.reset.prepend(() => {})
});
