import { createStore } from 'effector';

import moment from 'moment';

import { number, object, string } from 'yup';

import { createForm } from '@shared/lib/form';

import {
  nameRegEx,
  testMaxDate,
  testMinDate,
  withoutCyrilic
} from '@shared/lib/utils';

const minBirth = moment().subtract(120, 'years').format('YYYY-MM-DD');
const maxBirth = moment().subtract(18, 'years').format('YYYY-MM-DD');

const form = createForm({
  initialValues: {
    firstName: '',

    lastName: '',

    imageUrl: '',

    dateOfBirth: '',

    gender: 1,

    postCode: '',

    city: '',

    addressLine1: '',

    addressLine2: '',

    phone: ''
  },

  $schema: createStore(
    object({
      firstName: string()
        .required()

        .nullable()

        .matches(nameRegEx, 'Invalid')

        .trim('Required'),

      lastName: string()
        .required()

        .nullable()

        .matches(nameRegEx, 'Invalid')

        .trim('Required'),

      dateOfBirth: string()
        .required()

        .nullable()

        .test('min-date', 'Maximum age is 120', testMinDate(minBirth))

        .test('max-date', 'Minimal age is 18', testMaxDate(maxBirth)),

      gender: number()
        .nullable()

        .required(),

      postCode: string()
        .nullable()

        .required()

        .trim('Required')

        .matches(/^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i, 'Invalid')

        .label('Postcode'),

      city: string()
        .nullable()

        .required()

        .matches(withoutCyrilic, {
          excludeEmptyString: true,
          message: 'Invalid format'
        })

        .label('City')

        .trim('Required'),

      addressLine1: string()
        .nullable()

        .required()

        .matches(withoutCyrilic, {
          excludeEmptyString: true,
          message: 'Invalid format'
        })

        .label('Address line 1')

        .trim('Required'),

      addressLine2: string()
        .nullable()

        .matches(withoutCyrilic, {
          excludeEmptyString: true,
          message: 'Invalid format'
        })

        .label('Address line 2'),

      phone: string()
        .nullable()

        .required()

        .label('Phone')

        .trim('Required')

        .min(2, 'Required')

        .matches(/^\+?\d+$/, 'Invalid')
    })
  )
});

const $$profilePersonal = {
  form
};

export { $$profilePersonal, form, minBirth, maxBirth };
