import { Auth } from '@layouts/auth';

import { SetPassword } from '@features/user/password/change-by-reset';

import { createView } from '@shared/lib/view';

import { $$setPasswordPage } from './set-password.model';

/**
 * <ChangePassword />
 */
const PasswordPage = createView()
  .units({
    token: $$setPasswordPage.$token
  })

  .view(({ token }) => (
    <Auth.FormPage title='Change password'>
      <SetPassword token={token} />
    </Auth.FormPage>
  ));

export { PasswordPage };
