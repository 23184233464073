import { $$dictionary } from '@entities/dictionary';

import { createView } from '@shared/lib/view';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { form } from '../model';

import styles from './required-support.module.scss';

const RequiredSupportForm = createView()
  .units({
    services: $$dictionary.$services
  })

  .view(({ services }) => (
    <Form use={form} className={styles.formFields}>
      <Field.FormSection
        use={form.fields.services}
        title='What type of care support is required?'
        tip='You can select multiple services'
      >
        <div className={styles.checkboxGroup}>
          <Field.CheckboxList use={form.fields.services} options={services} />
        </div>
      </Field.FormSection>

      <div className={styles.formContainer}>
        <Field.Input
          use={form.fields.activityPerWeek}
          name='activityPerWeek'
          label='Hours per week'
          placeholder='Enter'
        />
      </div>
    </Form>
  ));

export { RequiredSupportForm };
