import type { Event } from 'effector';

import { createEvent } from 'effector';

import { createEffect, createStore, sample } from 'effector';

import { delay } from 'patronum';

import type { ToastID } from '@shared/config';

import { generateSimpleID } from '@shared/lib/utils';

import type { NotifyOptions, ToastSettings, ToastStack } from './types';

const closeByID = createEvent<ToastID>();

const closeByKey = createEvent<string>();

const closeNotify = createEvent();

const notifyFx = createEffect<NotifyOptions, ToastSettings>();

const $toasts = createStore<ToastStack[]>([]);

const popByTimeout = delay({
  source: notifyFx.doneData,

  timeout: payload => payload.duration
});

const $$toast = {
  notifyFx,

  closeNotify
};

$toasts
  .on(notifyFx.doneData, (state, payload) => {
    const [first, ...rest] = state;

    if (!first) {
      return [
        {
          id: payload.id,

          key: generateSimpleID(),

          toasts: [payload]
        },

        ...rest
      ];
    }

    if (first.id == payload.id) {
      return [
        {
          ...first,

          toasts: [payload, ...first.toasts]
        },
        ...rest
      ];
    }

    return [
      {
        id: payload.id,

        key: generateSimpleID(),

        toasts: [payload]
      },

      ...state
    ];
  })

  .on(
    [popByTimeout.map(payload => payload.key), closeByKey],

    (state, key) =>
      state
        .map(stack => {
          const matched = stack.toasts.some(one => one.key == key);

          if (!matched) return stack;

          const toasts = stack.toasts.filter(item => item.key != key);

          if (!toasts.length) return null;

          return {
            ...stack,

            toasts
          };
        })

        .filter(Boolean)
  )

  .reset(closeNotify);

notifyFx.use(payload => ({
  ...payload,

  duration: payload.duration ?? 4000,

  key: `${new Date().getTime()} ${Math.random()}`
}));

function notify<P>({
  clock,

  options
}: {
  clock: Event<P>;

  options: NotifyOptions | ((payload: P) => NotifyOptions);
}) {
  sample({
    clock,

    fn: typeof options == 'function' ? options : () => options,

    target: notifyFx
  });
}

export { $$toast, $toasts, notify, closeByID, closeByKey };
