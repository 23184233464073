import styles from './toast-text.module.scss';

const ToastText = () => (
  <p className={styles.toastText}>
    You have successfully restored the account. Please, add contact persons and
    create the care agreement.
  </p>
);

export { ToastText };
