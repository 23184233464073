import classNames from 'classnames';

import { $$settingsDeleteAccount } from '@widgets/settings-delete-account';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { useMedia } from '@shared/ui/atoms/media';

import { Modal } from '@shared/ui/atoms/modal';

import { Radio } from '@shared/ui/atoms/radio';

import styles from './workspace-decision.module.scss';

const options = [
  {
    title: 'Transfer ownership',
    text: 'You can choose a new holder for your Care Space. Your care recipients will continue to receive care without any difficulty.',
    value: true
  },

  {
    title: 'Delete the Care Space',
    text: 'All your contacts, messages, scheduled meetings, members, care recipients, etc., will be permanently deleted. Your care recipients will stop receiving care.',
    value: false
  }
];

/**
 * <WorkspaceDecision />
 */
const WorkspaceDecision = createView()
  .units({
    transferType: $$settingsDeleteAccount.$transferType,

    onClose: $$settingsDeleteAccount.modalClosed,

    transferTypeChanged: $$settingsDeleteAccount.transferTypeChanged,

    stepChanged: $$settingsDeleteAccount.stepChanged
  })
  .map(() => {
    const md = useMedia('>=md');

    return {
      md
    };
  })
  .view(({ md, onClose, transferTypeChanged, transferType, stepChanged }) => (
    <Modal.Container onClose={() => onClose()} size='small'>
      <Modal.Header className={styles.header}>
        What to do with your Care Space?
      </Modal.Header>

      <Modal.Body className={styles.content}>
        <p className={styles.textOne}>
          We're sorry you decided to delete your account.
        </p>

        <p className={styles.textTwo}>
          Please note, you have care recipients, and you need to choose what to
          do with them and your Care Space.{' '}
        </p>

        {options.map(({ title, text, value }, index) => (
          <div
            className={classNames(styles.workspaceOption, {
              [styles.workspaceOptionActive]: value == transferType
            })}
            key={index}
            onClick={() => transferTypeChanged(value)}
          >
            <div className={styles.workspaceOptionBody}>
              <h4 className={styles.workspaceOptionTitle}>{title}</h4>

              <p className={styles.workspaceOptionText}>{text}</p>
            </div>

            <Radio
              className={styles.radio}
              value={transferType}
              payload={value}
              onChange={() => {}}
            />
          </div>
        ))}

        <p className={styles.cancelNote}>
          Changed your mind?
          <br /> No problem. Just press cancel.
        </p>
      </Modal.Body>

      <Modal.Footer className={styles.footer}>
        <Button
          palette='text'
          variant='text'
          onClick={() => onClose()}
          className={styles.cancel}
        >
          Cancel
        </Button>

        {transferType ? (
          <Button onClick={() => stepChanged('transfer-ownership')}>
            {md
              ? 'Yes, I want to transfer ownership'
              : 'Yes, I want to transfer'}
          </Button>
        ) : (
          <Button onClick={() => stepChanged('reason')} palette='error'>
            {md ? 'Yes, I want to delete Care Space' : 'Yes, I want to delete'}
          </Button>
        )}
      </Modal.Footer>
    </Modal.Container>
  ));

export { WorkspaceDecision };
