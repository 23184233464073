import type { Account } from './account';

import type { Client } from './client';

import type { Entry } from './common';

import type { MembersNotificationType } from './settings-members';

enum WorkspaceStatus {
  Deleted = 'deleted',
  Active = 'active'
}

type Workspace = {
  id: string;

  role: MembersRoleType;

  clients: Client[];

  owner: Account;

  status: WorkspaceStatus;
};

type DeleteWorkspaceDTO = {
  email: string;

  password: string;

  reason: string;

  workspaceId: string;
};

type GetWorkspaceMembersDTO = {
  id: string;

  members: WorkspaceMember[];
};

type GetUserWorkspaceRelation = {
  id: string;

  owner: {
    id: string;

    firstName: string;

    lastName: string;

    email: string;
  };

  relations: {
    client: {
      id: string;
      firstName: string;

      lastName: string;
    };

    clientRelation: {
      name: string;
    };
  }[];
};

type WorkspaceMember = {
  id: number;

  role: MembersRoleType;

  account: WorkspaceMemberAccount;

  isVerified: boolean;
} & MemberNotification;

type MemberNotification = {
  smsNotifications: MembersNotificationType[];

  emailNotifications: MembersNotificationType[];
};

enum MembersRoleType {
  Owner = 'Owner',
  FullAccess = 'Full access',
  ViewOnly = 'View only'
}

type WorkspaceMemberAccount = {
  id: string;

  firstName: string;

  lastName: string;

  email: string;

  emailConfirmed: boolean;

  phone: string;

  relations: MembersRelation[];

  imageUrl?: string;
};

type MembersRelation = {
  clientRelation: Entry;

  client: {
    id: string;

    firstName: string;

    lastName: string;
  };
};

type TransferWorkspaceOwnershipDTO = {
  email: string;

  password: string;

  userId: string;

  workspaceId: string;
};

type TransferWorkspaceOwnershipInviteDTO = {
  email: string;

  password: string;

  newUser: string;

  workspaceId: string;
};

type RestoreWorkspaceDTO = {
  workspaceId: string;
};

export type {
  Workspace,
  GetUserWorkspaceRelation,
  GetWorkspaceMembersDTO,
  DeleteWorkspaceDTO,
  WorkspaceMember,
  MemberNotification,
  MembersRelation,
  TransferWorkspaceOwnershipDTO,
  TransferWorkspaceOwnershipInviteDTO,
  RestoreWorkspaceDTO,
  WorkspaceMemberAccount
};

export { MembersRoleType, WorkspaceStatus };
