import classNames from 'classnames';

import type { MutableRefObject } from 'react';

import { useState, useRef, type ChangeEvent, useEffect } from 'react';

import { createView } from '@shared/lib/view';

import { Label } from '@shared/ui/atoms/label';

import styles from './textarea.module.scss';

import type { TextareaProps } from './textarea.props';

const Textarea = createView<TextareaProps>()
  .defaultProps({
    type: 'text',
    mode: 'input' as 'view' | 'input',
    validationType: 'bottom' as 'bottom' | 'top',
    cols: 0,
    rows: 0,
    onBlur: () => {},
    onFocus: () => {}
  })
  .map(
    ({
      type,
      value,
      onBlur,
      onFocus,
      hasError,
      onChange,
      inputRef,
      maxLength
    }) => {
      const [text, setText] = useState((value ?? '') || '');
      const [focused, setFocused] = useState(false);
      const _inputRef = useRef<HTMLTextAreaElement | HTMLDivElement>();

      const showError = !focused && hasError;

      const onInputFocus = () => {
        setFocused(true);

        onFocus?.();
      };

      const onInputBlur = () => {
        setFocused(false);

        setText(value!);

        onBlur?.();
      };

      const onInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        let _value = event.target.value;
        let _result: string = _value;

        switch (true) {
          case type == 'without-cyrilic':
            _result = _value
              .replace(/[^0-9a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/]+/g, '')
              .substring(0, maxLength);

            break;
        }

        [setText, onChange].forEach(set => set?.(_result));
      };

      useEffect(() => {
        if (value == text) return;

        setText(value!);
      }, [value]);

      useEffect(() => {
        if (!inputRef) return;

        inputRef.current = _inputRef.current!;
      });

      return {
        text,
        focused,
        onInputBlur,
        onInputFocus,
        _inputRef,
        showError,
        onInputChange
      };
    }
  )
  .view(
    ({
      bold,
      mode,
      text,
      label,
      error,
      value,
      helper,
      hasError,
      focused,
      tip,
      cols,
      rows,
      maxLength,
      readonly,
      autoFocus,
      _inputRef,
      onInputBlur,
      onInputFocus,
      onInputChange,
      showError,
      className,
      textareaClassName,
      placeholder,
      validationType
    }) => {
      const inputClassName = classNames(styles.textarea, textareaClassName, {
        [styles.danger]: showError,
        [styles.view]: mode == 'view'
      });

      return (
        <div
          className={classNames(styles.container, className, {
            [styles.bold]: bold,
            [styles.focused]: focused,
            [styles.hasValue]: !!text,
            [styles.viewMode]: mode == 'view'
          })}
          data-has-error={hasError}
        >
          <div className={styles.header}>
            {label && (
              <Label
                className={styles.label}
                error={validationType == 'top' && error}
                isError={showError && hasError}
                tip={tip}
                label={label}
              />
            )}

            {helper && <div className={styles.helper}>{helper}</div>}
          </div>

          <div className={styles.box}>
            {mode == 'input' ? (
              <textarea
                className={inputClassName}
                ref={_inputRef as React.MutableRefObject<HTMLTextAreaElement>}
                value={text ?? ''}
                rows={rows}
                cols={cols}
                readOnly={readonly}
                maxLength={maxLength}
                autoFocus={autoFocus}
                spellCheck={false}
                autoCorrect='off'
                onChange={event => onInputChange(event)}
                placeholder={placeholder as string}
                onBlur={() => onInputBlur()}
                onFocus={() => onInputFocus()}
              />
            ) : (
              <div
                className={inputClassName}
                ref={_inputRef as MutableRefObject<HTMLDivElement>}
                onFocus={() => onInputFocus()}
                onBlur={() => onInputBlur()}
                tabIndex={-1}
              >
                {value ?? placeholder}
              </div>
            )}
          </div>

          {hasError &&
            error &&
            validationType == 'bottom' &&
            error != 'Required' && (
              <div className={styles.bottomError}>{error}</div>
            )}
        </div>
      );
    }
  );

export { Textarea };
