import { $$signUpLovedOne } from '@pages/sign-up/pages/loved-one';

import { routes } from '@shared/routes';

import { createLovedOneStep } from './shared';

createLovedOneStep({
  key: 'accountHolder',

  form: $$signUpLovedOne.steps.accountHolder.form,

  route: routes.signUp.lovedOne.accountHolder,

  nextRoute: routes.signUp.lovedOne.createAccount,

  mapValues: values => values.accountHolder,

  processValues: values => values,

  checkValues: values => Boolean(values.preferences)
});
