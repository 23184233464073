import classNames from 'classnames';

import type { FC, HTMLAttributes } from 'react';

import { Text } from '@shared/ui/atoms/text';

import styles from './checkbox.module.scss';

import type { CheckboxProps } from './checkbox.props';

const Checkbox: FC<CheckboxProps> = ({
  value,
  label,
  disabled,
  hasError,
  onChange,
  className,
  indeterminate
}) => (
  <label
    className={classNames(styles.checkbox, className, {
      [styles.disabled]: disabled,
      [styles.error]: hasError
    })}
  >
    <div
      className={classNames(styles.checkmark, {
        [styles.checked]: value,
        [styles.indeterminate]: indeterminate
      })}
    />

    <input
      className={styles.input}
      type='checkbox'
      value={value as unknown as string}
      onChange={() => {
        onChange(!value);
      }}
    />

    {label && <Text variant='body-1'>{label}</Text>}
  </label>
);

const CheckboxGroup: FC<
  HTMLAttributes<HTMLDivElement> & {
    layout?: 'horizontal';
  }
> = ({ className, layout, ...props }) => (
  <div
    className={classNames(className, styles.group, styles[layout])}
    {...props}
  />
);

Checkbox.defaultProps = {
  disabled: false,

  hasError: false
};

CheckboxGroup.defaultProps = {
  layout: 'horizontal'
};

export { Checkbox, CheckboxGroup };
