import type { RouteInstance } from 'atomic-router';

import { redirect } from 'atomic-router';

import { sample } from 'effector';

import type { Model } from 'effector-factorio';

import { $$signUpLovedOne } from '@pages/sign-up/pages/loved-one';

import type { StepWizardModelFactory } from '@pages/sign-up/ui/sign-up-wizard-step';

import { $$session } from '@entities/session';

import { routes } from '@shared/routes';

const steps: [RouteInstance<any>, Model<typeof StepWizardModelFactory>][] = [
  [routes.signUp.lovedOne.careNeeds, $$signUpLovedOne.wizards.careNeeds],

  [
    routes.signUp.lovedOne.conditionExperience,
    $$signUpLovedOne.wizards.conditionExperience
  ],

  [routes.signUp.lovedOne.preferences, $$signUpLovedOne.wizards.preferences],

  [
    routes.signUp.lovedOne.careRecipient,
    $$signUpLovedOne.wizards.careRecipient
  ],

  [routes.signUp.lovedOne.accountHolder, $$signUpLovedOne.wizards.accountHolder]
];

steps.forEach(([route, wizard], index, list) => {
  const previous = list[index - 1];

  if (!previous) return;

  sample({
    clock: wizard.backClicked,

    source: route.$params,

    target: previous[0].open
  });
});

const switchRoleRedirection = redirect({
  clock: $$signUpLovedOne.wizards.careNeeds.backClicked,

  query: routes.signUp.lovedOne.careNeeds.$params,

  route: routes.signUp.type
});

const postCodeRedirection = redirect({
  clock: $$signUpLovedOne.wizards.careNeeds.backClicked,

  route: routes.signUp.lovedOne.postcode
});

sample({
  clock: $$signUpLovedOne.wizards.careNeeds.backClicked,

  source: $$session.$active,

  filter: active => active,

  target: switchRoleRedirection
});

sample({
  clock: $$signUpLovedOne.wizards.careNeeds.backClicked,

  source: $$session.$active,

  filter: active => !active,

  target: postCodeRedirection
});

redirect({
  clock: $$signUpLovedOne.$$createAccountWizard.backClicked,

  params: routes.signUp.lovedOne.createAccount.$params,

  route: routes.signUp.lovedOne.accountHolder
});
