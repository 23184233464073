import { createStore } from 'effector';

import { array, object } from 'yup';

import type { Service } from '@shared/api';

import { createForm } from '@shared/lib/form';

type ServicesValues = {
  services: Service['id'][];
};

const form = createForm<ServicesValues>({
  initialValues: {
    services: []
  },

  $schema: createStore(
    object({
      services: array().min(1, 'Required').required().nullable()
    })
  )
});

const $$carerServices = {
  form
};

export type { ServicesValues };

export { $$carerServices, form };
