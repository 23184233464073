import { createStore, sample } from 'effector';

import { mixed, object, string } from 'yup';

import { $$shared } from '@features/billing/billing-details/model/shared';

import { $$billing } from '@entities/billing';

import type { BillingDetails } from '@shared/api';

import { AccountType } from '@shared/api';

import { ToastID } from '@shared/config';

import { createForm } from '@shared/lib/form';

import { ibanRegEx, postalcodeRegEx, withoutCyrilic } from '@shared/lib/utils';

import type { NotifyOptions } from '@shared/ui/organisms/toasts';

import { $$toast } from '@shared/ui/organisms/toasts/model';

import { $$passwordDetails } from './password-details';

const $schema = createStore<any>(null);

const billingDetailsForm = createForm({
  initialValues: {
    accountType: $$billing.$details ? AccountType.EU : AccountType.UK,

    sortCode: '',

    accountNumber: '',

    IBAN: '',

    city: '',

    addressLine1: '',

    addressLine2: '',

    region: '',

    postalCode: '',

    country: '',

    firstName: '',

    lastName: ''
  },

  $schema
});

sample({
  source: billingDetailsForm.$values,

  fn: values => {
    const fields = {
      accountType: mixed(),

      IBAN: string(),

      accountNumber: string(),

      sortCode: string(),

      city: string()
        .nullable()

        .required('Required')

        .matches(withoutCyrilic, {
          excludeEmptyString: true,
          message: 'Invalid format'
        })

        .label('City')

        .trim('Required'),

      addressLine1: string()
        .nullable()

        .required('Required')

        .matches(withoutCyrilic, {
          excludeEmptyString: true,
          message: 'Invalid format'
        })

        .label('Address line 1')

        .trim('Required'),

      region: string().required('Required'),

      postalCode: string()
        .trim('Required')

        .required('Required')

        .matches(postalcodeRegEx, 'is incorrect'),

      country: string().required('Required'),

      firstName: string().required('Required'),

      lastName: string().required('Required')
    };

    if (values.accountType == AccountType.EU && values.IBAN == '') {
      fields.IBAN = fields.IBAN.trim('Required')
        .nullable()
        .required('Required')
        .matches(ibanRegEx, 'Invalid');
    }

    if (values.accountType == AccountType.EU && values.IBAN != '') {
      fields.IBAN = fields.IBAN.required('Required')
        .nullable()
        .matches(ibanRegEx, 'Invalid');
    }

    if (values.accountType == AccountType.UK && values.accountNumber == '') {
      fields.accountNumber = fields.accountNumber
        .nullable()
        .trim('Required')
        .required('Required');
    }

    if (values.accountType == AccountType.UK && values.accountNumber != '') {
      fields.accountNumber = fields.accountNumber
        .nullable()
        .required('Required')
        .length(6, 'Invalid');
    }

    if (values.accountType == AccountType.UK && values.sortCode == '') {
      fields.sortCode = fields.sortCode
        .nullable()
        .trim('Required')
        .required('Required');
    }

    if (values.accountType == AccountType.UK && values.sortCode != '') {
      fields.sortCode = fields.sortCode
        .nullable()
        .required('Required')
        .length(6, 'Invalid');
    }

    return object(fields);
  },

  target: $schema
});

const $isError = createStore(false);

const $$billingDetails = {
  $isError,

  billingDetailsForm
};

sample({
  clock: $$billing.getFullBillingDetailsFx.doneData.map(data => data.details),

  fn: ({
    IBAN,
    sortCode,
    accountNumber,
    postalCode,
    city,
    country,
    addressLine1,
    addressLine2,
    region,
    lastName,
    firstName
  }) => ({
    accountType: IBAN ? AccountType.EU : AccountType.UK,

    sortCode,

    accountNumber,

    IBAN,

    city,

    addressLine1,

    addressLine2,

    region,

    postalCode,

    country,

    firstName,

    lastName
  }),

  target: billingDetailsForm.patch
});

sample({
  clock: billingDetailsForm.submitted,

  source: $$passwordDetails.$password,

  filter: $$billing.$details.map(state => state !== null),

  fn: (password, { accountType, IBAN, accountNumber, sortCode, ...values }) =>
    ({
      ...values,
      ...(accountType === AccountType.UK
        ? { sortCode, accountNumber }
        : { IBAN }),
      password
    } as BillingDetails),

  target: [$$billing.updateBillingDetailsFx, billingDetailsForm.reset]
});

sample({
  clock: billingDetailsForm.submitted,

  filter: $$billing.$details.map(state => !state),

  fn: ({ accountType, IBAN, accountNumber, sortCode, ...values }) =>
    ({
      ...values,
      ...(accountType === AccountType.UK
        ? { sortCode, accountNumber }
        : { IBAN })
    } as BillingDetails),

  target: [$$billing.createBillingDetailsFx, billingDetailsForm.reset]
});

sample({
  clock: [
    $$billing.createBillingDetailsFx.doneData,
    $$billing.updateBillingDetailsFx.doneData
  ],

  fn: () =>
    ({
      id: ToastID.BillingDetailsSaved,

      type: 'success',

      title: 'Your billing details have been saved',

      content:
        '<div style="word-break: break-word">You can change your billing details in a billing tab if you want</div>'
    } as NotifyOptions),

  target: $$toast.notifyFx
});

sample({
  clock: $$shared.billingDetailsClosed,

  source: $$billing.$publicDetails,

  filter: publicDetails => !Boolean(publicDetails),

  fn: () => {},

  target: billingDetailsForm.reset
});

$isError
  .on(
    [
      $$billing.createBillingDetailsFx.failData,
      $$billing.updateBillingDetailsFx.failData
    ],
    () => true
  )
  .reset([
    $$billing.createBillingDetailsFx.done,
    $$billing.updateBillingDetailsFx.done,
    $$shared.billingDetailsClosed
  ]);

export { $$billingDetails, billingDetailsForm };
