import { $$dropdown } from '@layouts/main-page/model';

import classNames from 'classnames';

import { useMemo } from 'react';

import { $$settingsDeleteAccount } from '@widgets/settings-delete-account';

import { $$createWorkspace } from '@features/account/create-workspace/model';

import { $$deleteWorkspace } from '@features/account/delete-workspace';

import { $$leaveWorkspace } from '@features/account/leave-workspace/model';

import { $$transferOwnerShip } from '@features/account/transfer-ownership';

import { $$selectCareSpace } from '@features/care-space/select';

import {
  $hasOwnCareSpace,
  $hasOwnDeletedCareSpace,
  $isInOwnWorkspace
} from '@entities/_computed/care-space';

import { $$careRecipient } from '@entities/care-recipient';

import { $$careSpace } from '@entities/care-space';

import { WorkspaceStatus } from '@shared/api';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { EditableSection } from '@shared/ui/atoms/editable-section';

import { $$accountPage } from './account.model';

import styles from './account.module.scss';

import { CareRecipients, Password, PersonalDetails } from './ui';

const Account = createView()
  .units({
    hasCareRecipients: $$careRecipient.$hasCareRecipients,

    isUserOwner: $isInOwnWorkspace,

    careSpace: $$careSpace.$careSpace,

    hasCareSpaces: $$careSpace.$activeAccountCareSpaces.map(
      state => state.length > 0
    ),

    careSpaceVisible: $$dropdown.$careSpaceVisible,

    createCareSpace: $$accountPage.createCareSpace,

    hasOwnCareSpace: $hasOwnCareSpace,

    hasOwnDeletedCareSpace: $hasOwnDeletedCareSpace,

    deleteAccountOpened: $$settingsDeleteAccount.modalOpened
  })

  .map(({ careSpace }) => {
    const onWorkspaceClick = () => {
      $$selectCareSpace.careSpaceClicked(careSpace?.id);
    };

    return {
      onWorkspaceClick
    };
  })

  .view(
    ({
      hasCareRecipients,
      isUserOwner,
      hasOwnDeletedCareSpace,
      hasOwnCareSpace,
      onWorkspaceClick,
      careSpace,
      hasCareSpaces,
      createCareSpace,
      deleteAccountOpened
    }) => {
      const careSpaceActions = useMemo(
        () => [
          {
            active: isUserOwner && careSpace?.status !== 'deleted',
            text: 'Delete the Care Space',
            className: styles.careSpaceActionDelete,
            theme: 'error',
            onClick: () => $$deleteWorkspace.modalOpened()
          },

          {
            active:
              !isUserOwner && careSpace?.status !== 'deleted' && hasCareSpaces,
            text: 'Leave this Care Space',
            theme: 'error',
            onClick: () => $$leaveWorkspace.leaveWorkspaceOpened()
          },

          {
            active: isUserOwner && careSpace?.status !== 'deleted',
            text: 'Transfer ownership',
            theme: 'secondary',
            className: styles.careSpaceActionTransfer,
            onClick: () => $$transferOwnerShip.modalOpened()
          },

          {
            active: !isUserOwner && hasOwnCareSpace && hasCareSpaces,
            text: 'Go to my Care Space',
            theme: 'secondary',
            onClick: onWorkspaceClick,
            className: ''
          },

          {
            active:
              (!isUserOwner && !hasOwnCareSpace) ||
              hasOwnDeletedCareSpace ||
              !careSpace,
            text: 'Create own Care Space',
            theme: 'secondary',
            onClick: () =>
              hasOwnDeletedCareSpace
                ? $$createWorkspace.workspaceVisibilityOpened()
                : createCareSpace()
          }
        ],

        [isUserOwner, hasOwnCareSpace, $hasOwnDeletedCareSpace]
      );

      return (
        <div className={styles.sections}>
          <PersonalDetails />

          {careSpace?.status !== WorkspaceStatus.Deleted &&
            hasCareRecipients && <CareRecipients />}

          <Password />

          <EditableSection title='Manage your Care Space' editable={false}>
            {isUserOwner ? (
              <p className={styles.description}>
                If you want to transfer ownership of your Care Space to another
                user, or <br /> delete the Care Space, select action below and
                follow the instructions.
              </p>
            ) : (
              <p className={styles.description}>
                {hasOwnCareSpace ? (
                  'If you want to manage your Care Space, you need to go to it.'
                ) : (
                  <>
                    If you want to receive care or add care recipients, you need
                    to create your <br />
                    own Care Space. Click button below and follow the
                    instruction.
                  </>
                )}
              </p>
            )}

            <div className={styles.actions}>
              {careSpaceActions.map(
                ({ text, theme, active, onClick, className }) =>
                  active && (
                    <Button
                      className={classNames(styles.button, className)}
                      key={text}
                      palette={theme as any}
                      onClick={onClick}
                    >
                      {text}
                    </Button>
                  )
              )}
            </div>
          </EditableSection>

          <EditableSection title='Delete my account' editable={false}>
            <p className={styles.description}>
              If you want to stop collaborating with CarersHQ you can click
              button below.
            </p>

            <div className={styles.actions}>
              <Button
                className={styles.button}
                palette='error'
                onClick={() => deleteAccountOpened()}
              >
                Delete my account
              </Button>
            </div>
          </EditableSection>
        </div>
      );
    }
  );

export { Account };
