import { MainPage } from '@layouts/main-page';

import type { RouteInstance } from 'atomic-router';

import { createRouteView, Route } from 'atomic-router-react';

import type { FC } from 'react';

import { CareRecipients } from '@pages/care-recipients';

import { SettingsPage } from '@pages/settings';

import { Team } from '@pages/team';

import { AccountBlocked } from '@widgets/account-blocked';

import {
  $$addCareRecipient,
  AddCareRecipient
} from '@widgets/add-care-recipient';

import { $$addPayments } from '@widgets/add-payments/model';

import { AddPayments } from '@widgets/add-payments/ui';

import {
  $$emailConfirmation,
  EmailConfirmation
} from '@widgets/email-confirmation';

import { $$emailConfirmed, EmailConfirmed } from '@widgets/email-confirmed';

import {
  $$settingsDeleteAccount,
  SettingsDeleteAccount
} from '@widgets/settings-delete-account';

import {
  $$workspaceCreated,
  WorkspaceCreated
} from '@widgets/workspace-created';

import { $$createWorkspace } from '@features/account/create-workspace/model';

import { CreateWorkspace } from '@features/account/create-workspace/ui';

import {
  $$deleteWorkspace,
  DeleteWorkspace
} from '@features/account/delete-workspace';

import { $$leaveWorkspace } from '@features/account/leave-workspace/model';

import { LeaveWorkspace } from '@features/account/leave-workspace/ui';

import {
  $$transferOwnerShip,
  TransferOwnership
} from '@features/account/transfer-ownership';

import { BillingDetails } from '@features/billing/billing-details';

import { $$shared } from '@features/billing/billing-details/model';

import {
  $$deleteAccount,
  DeleteAccount
} from '@features/care-recipient/delete-account';

import {
  $$careRecipientProfile,
  ViewCareRecipientProfile
} from '@features/care-recipient/view-care-recipient-profile';

import {
  $$carerProfile,
  ViewCarerProfile
} from '@features/carer/view-carer-profile';

import { $$memberDelete } from '@features/member/delete/model';

import { MemberDelete } from '@features/member/delete/ui';

import { $$careSpace } from '@entities/care-space';

import { $$session, RoleType } from '@entities/session';

import { $$user } from '@entities/user';

import { AccountStatus } from '@shared/api';

import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { AddRolePage } from './add-role';

import { AuthPage } from './auth';

import { CareSpaceDeletionConfirmed } from './care-space-deletion-confirmed';

import { CarerMatches } from './carer-matches';

import { DeletedAccount } from './deleted-account';

import { LeftCareSpace } from './left-care-space';

import { ProfilePage } from './profile';

import { SignUpPage } from './sign-up';

const Modals = createView()
  .units({
    careSpace: $$careSpace.$careSpace,

    emailConfirmation: $$emailConfirmation.$visible,

    emailConfirmed: $$emailConfirmed.$visible,

    carerProfile: $$carerProfile.$visible,

    careRecipientProfile: $$careRecipientProfile.$visible,

    billingDetails: $$shared.$visible,

    createWorkspace: $$createWorkspace.$visible,

    leaveWorkspace: $$leaveWorkspace.$visible,

    deleteWorkspace: $$deleteWorkspace.$visible,

    transferOwnerShip: $$transferOwnerShip.$visible,

    isEmbedded: $$settingsDeleteAccount.$isEmbedded,

    onTransferGoPrevious: $$settingsDeleteAccount.transferClicked,

    memberDelete: $$memberDelete.$visible,

    deleteAccount: $$deleteAccount.$visible,

    settingsDeleteAccount: $$settingsDeleteAccount.$visible,

    addCareRecipient: $$addCareRecipient.$visible,

    workspaceCreated: $$workspaceCreated.$visible,

    addPayments: $$addPayments.$visible
  })

  .view(
    ({
      emailConfirmation,
      emailConfirmed,
      carerProfile,
      careRecipientProfile,
      billingDetails,
      createWorkspace,
      leaveWorkspace,
      deleteWorkspace,
      memberDelete,
      deleteAccount,
      settingsDeleteAccount,
      transferOwnerShip,
      isEmbedded,
      onTransferGoPrevious,
      addCareRecipient,
      careSpace,
      workspaceCreated,
      addPayments
    }) => (
      <>
        {careSpace?.status === 'active' &&
          emailConfirmation &&
          !workspaceCreated && <EmailConfirmation />}

        {careSpace?.status === 'active' &&
          emailConfirmed &&
          !workspaceCreated && <EmailConfirmed />}

        {carerProfile && <ViewCarerProfile />}

        {careRecipientProfile && <ViewCareRecipientProfile />}

        {billingDetails && <BillingDetails />}

        {createWorkspace && <CreateWorkspace />}

        {leaveWorkspace && <LeaveWorkspace />}

        {deleteWorkspace && <DeleteWorkspace />}

        {memberDelete && <MemberDelete />}

        {deleteAccount && <DeleteAccount />}

        {settingsDeleteAccount && <SettingsDeleteAccount />}

        {workspaceCreated && <WorkspaceCreated />}

        {addPayments && <AddPayments />}

        {transferOwnerShip && (
          <TransferOwnership
            isEmbedded={isEmbedded}
            onGoPrevious={onTransferGoPrevious}
          />
        )}

        {addCareRecipient && <AddCareRecipient />}
      </>
    )
  );

const mainPageCareRecipientRoutes = [
  routes.schedule,
  routes.invoices,
  routes.help,
  routes.matches,
  routes.team.my,
  routes.team.connections,
  routes.team.shortlist,
  routes.settings.account,
  routes.settings.members,
  routes.settings.billing,
  routes.careRecipients.base
] as RouteInstance<any>[];

const mainPageCarerRoutes = [
  routes.profile,
  routes.schedule,
  routes.clients,
  routes.invoices,
  routes.help
];

const SignUpRoute = createRouteView({
  route: routes.signUp.any as any,

  view: SignUpPage
});

const Schedule = () => <div>Schedule</div>;

const Invoices = () => <div>Invoices</div>;

const Help = () => <div>Help</div>;

const CareRecipientMainPage = () => (
  <MainPage.Container>
    <MainPage.Header />

    <MainPage.Content>
      <Route route={[routes.matches]} view={CarerMatches} />

      <Route route={[routes.schedule]} view={Schedule} />

      <Route route={[routes.invoices]} view={Invoices} />

      <Route route={[routes.help]} view={Help} />

      <Route
        route={[routes.team.my, routes.team.connections, routes.team.shortlist]}
        view={Team}
      />

      <Route
        route={[
          routes.settings.account,
          routes.settings.members,
          routes.settings.billing
        ]}
        view={SettingsPage}
      />

      <Route route={routes.careRecipients.base} view={CareRecipients} />
    </MainPage.Content>
  </MainPage.Container>
);

const CareRecipientMainPageRoute = createRouteView({
  route: mainPageCareRecipientRoutes,

  view: CareRecipientMainPage
});

const Pages: FC = createView()
  .units({
    role: $$session.$role,

    hasSession: $$session.$active,

    account: $$user.$account
  })

  .view(({ role, hasSession, account }) => {
    if (!hasSession) {
      return (
        <AuthPage>
          <SignUpRoute />
        </AuthPage>
      );
    }

    if (account?.status === AccountStatus.Deleted) {
      return <DeletedAccount />;
    }

    if (account?.status == AccountStatus.Blocked) {
      return <AccountBlocked />;
    }

    if (role == RoleType.CareRecipient) {
      return (
        <>
          <CareRecipientMainPageRoute />

          <Route
            route={routes.signUp.any}
            view={() => (
              <AddRolePage>
                <SignUpRoute />
              </AddRolePage>
            )}
          />

          <Route
            view={CareSpaceDeletionConfirmed}
            route={routes.careSpaceDeletion.confirmed}
          />

          <Route view={LeftCareSpace} route={routes.leaveCareSpace.confirmed} />

          <Modals />
        </>
      );
    }

    if (role == RoleType.Carer) {
      return (
        <>
          <Route
            route={mainPageCarerRoutes}
            view={() => (
              <MainPage.Container>
                <MainPage.Header />

                <MainPage.Content>
                  <Route route={routes.profile} view={ProfilePage} />

                  <Route
                    route={[routes.schedule]}
                    view={() => <div>Schedule</div>}
                  />

                  <Route
                    route={[routes.clients]}
                    view={() => <div>My clients</div>}
                  />

                  <Route
                    route={[routes.invoices]}
                    view={() => <div>Invoices</div>}
                  />

                  <Route route={[routes.help]} view={() => <div>Help</div>} />
                </MainPage.Content>

                <Modals />
              </MainPage.Container>
            )}
          />

          <Route
            route={routes.signUp.any}
            view={() => (
              <AddRolePage>
                <SignUpRoute />
              </AddRolePage>
            )}
          />
        </>
      );
    }

    return null;
  });

// .view(({ role, hasSession, account }) => {
//   if (!hasSession) {
//     return (
//       <AuthPage>
//         <SignUpRoute />
//       </AuthPage>
//     );
//   }

//   if (account?.status === AccountStatus.Deleted) {
//     return <DeletedAccount />;
//   }

//   if (account?.status == AccountStatus.Blocked) {
//     return <AccountBlocked />;
//   }

//   if (role == RoleType.CareRecipient) {
//     return (
//       <>
//         <Route
//           view={() => (
//             <MainPage.Container>
//               <MainPage.Header />

//               <MainPage.Content>
//                 <Route route={[routes.matches]} view={CarerMatches} />

//                 <Route
//                   route={[routes.schedule]}
//                   view={() => <div>Schedule</div>}
//                 />

//                 <Route
//                   route={[routes.invoices]}
//                   view={() => <div>Invoices</div>}
//                 />

//                 <Route route={[routes.help]} view={() => <div>Help</div>} />

//                 <Route
//                   route={[
//                     routes.team.my,
//                     routes.team.connections,
//                     routes.team.shortlist
//                   ]}
//                   view={Team}
//                 />

//                 <Route
//                   route={[
//                     routes.settings.account,
//                     routes.settings.members,
//                     routes.settings.billing
//                   ]}
//                   view={SettingsPage}
//                 />

//                 <Route
//                   route={routes.careRecipients.base}
//                   view={CareRecipients}
//                 />
//               </MainPage.Content>
//             </MainPage.Container>
//           )}
//           route={mainPageCareRecipientRoutes}
//         />

//         <Route
//           route={routes.signUp.any}
//           view={() => (
//             <AddRolePage>
//               <SignUpRoute />
//             </AddRolePage>
//           )}
//         />

//         <Route
//           view={CareSpaceDeletionConfirmed}
//           route={routes.careSpaceDeletion.confirmed}
//         />

//         <Route view={LeftCareSpace} route={routes.leaveCareSpace.confirmed} />

//         <Modals />
//       </>
//     );
//   }

//   if (role == RoleType.Carer) {
//     return (
//       <>
//         <Route
//           route={mainPageCarerRoutes}
//           view={() => (
//             <MainPage.Container>
//               <MainPage.Header />

//               <MainPage.Content>
//                 <Route route={routes.profile} view={ProfilePage} />

//                 <Route
//                   route={[routes.schedule]}
//                   view={() => <div>Schedule</div>}
//                 />

//                 <Route
//                   route={[routes.clients]}
//                   view={() => <div>My clients</div>}
//                 />

//                 <Route
//                   route={[routes.invoices]}
//                   view={() => <div>Invoices</div>}
//                 />

//                 <Route route={[routes.help]} view={() => <div>Help</div>} />
//               </MainPage.Content>

//               <Modals />
//             </MainPage.Container>
//           )}
//         />

//         <Route
//           route={routes.signUp.any}
//           view={() => (
//             <AddRolePage>
//               <SignUpRoute />
//             </AddRolePage>
//           )}
//         />
//       </>
//     );
//   }

//   return null;
// });

export { Pages, mainPageCareRecipientRoutes };
