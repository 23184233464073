import classNames from 'classnames';

import type { FC, HTMLAttributes } from 'react';

import styles from './divider.module.scss';

/**
 * <Divider />
 */
const Divider: FC<HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => <div className={classNames(styles.divider, className)} {...props} />;

export { Divider };
