import { createForm } from '@shared/lib/form';

const form = createForm({
  initialValues: {
    video: {
      file: null,
      url: ''
    },

    image: {
      file: null,
      url: ''
    }
  }
});

const $$carerMedia = {
  form
};

export { $$carerMedia, form };
