import { Link } from 'atomic-router-react';

import { $$signUpPage } from '@pages/sign-up/sign-up.model';

import { createView } from '@shared/lib/view';

import { CtaBanner } from '@shared/ui/atoms/cta-banner';

import { WizardFormStep } from '@shared/ui/molecules/wizard-form-step';

import styles from './has-an-account.module.scss';

/**
 * <HasAnAccount />
 */
const HasAnAccount = createView()
  .units({
    firstName: $$signUpPage.$firstName,

    onGoNext: $$signUpPage.hasAnAccountNextClicked,

    onGoBack: $$signUpPage.hasAnAccountBackClicked
  })

  .view(({ firstName, onGoBack, onGoNext }) => (
    <div className={styles.container}>
      <CtaBanner>
        <p>
          Unexpectedly, it seems <br className={styles.visibleSm} /> that you
          have already <br />
          registered at Carers HQ!
        </p>
      </CtaBanner>

      <WizardFormStep
        onGoBack={onGoBack}
        total={0}
        onGoNext={onGoNext}
        title={`Good to see you, ${firstName}!`}
        captions={{
          next: 'Go to Sign In'
        }}
      >
        <p className={styles.description}>
          It looks like you recently tried to create a new CarersHQ client
          account, but already have one set up. Click the button below to sign
          in.
        </p>

        <Link className={styles.link} to='/contacts'>
          Any questions? Fell free to contact us.
        </Link>
      </WizardFormStep>
    </div>
  ));

export { HasAnAccount };
