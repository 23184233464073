import classNames from 'classnames';

import { createView } from '@shared/lib/view';

import { AlertBox } from '@shared/ui/atoms/alert-box';

import { Button } from '@shared/ui/atoms/button';

import { useMedia } from '@shared/ui/atoms/media';

import { Modal } from '@shared/ui/atoms/modal';

import styles from './attention.module.scss';

import type { AttentionProps } from './attention.props';

const Attention = createView<AttentionProps>().view(
  ({ step, onCancel, stepChanged, submit, isAccount }) => {
    const isAccessType = step === 'attention-access';

    const md = useMedia('>=md');

    return (
      <Modal.Container onClose={() => onCancel()} size='small'>
        <Modal.Header>
          <>
            {isAccessType
              ? `Are you sure you want to delete your ${
                  isAccount ? 'account?' : 'Care Space?'
                }`
              : `Confirm the ${isAccount ? 'account' : 'Care Space'} deletion`}
          </>
        </Modal.Header>

        <Modal.Body>
          <div>
            <AlertBox
              className={classNames(styles.alert, {
                [styles.appointmentsAlert]: !isAccessType
              })}
              variant={isAccessType ? 'error' : 'warning'}
            >
              <h4
                className={classNames(styles.title, {
                  [styles.dark]: !isAccessType
                })}
              >
                Pay attention!
              </h4>

              {isAccessType ? (
                <div className={styles.accessTypeInfo}>
                  <p>
                    After you delete your {isAccount ? 'account' : 'Care Space'}
                    , you won’t have access to:
                  </p>

                  <ul>
                    <li>- any carer information;</li>

                    <li>- reports and notes;</li>

                    <li>- care recipients will stop receiving care;</li>

                    <li>- anything else in the Carers HQ.</li>
                  </ul>
                </div>
              ) : (
                <div className={styles.attentionText}>
                  <p className={styles.dark}>
                    If you have scheduled appointments within the next 2 weeks,
                    we must
                    <br /> charge your account for them.
                  </p>

                  <p
                    className={classNames(
                      styles.bold,
                      styles.dark,
                      styles.questions
                    )}
                  >
                    If you have any questions, please{' '}
                    <a href='https://google.com' className={styles.link}>
                      check in on our terms and conditions page
                    </a>{' '}
                    or you can{' '}
                    <a href='https://google.com' className={styles.link}>
                      contact us
                    </a>
                    .
                  </p>
                </div>
              )}
            </AlertBox>
          </div>
        </Modal.Body>

        <Modal.Footer space className={styles.footer}>
          <Button
            className={styles.previous}
            variant='text'
            palette='text'
            onClick={() => stepChanged(isAccessType ? 'reason' : 'credentials')}
          >
            {!md ? 'Previous' : 'Previous step'}
          </Button>

          <div>
            <Button
              variant='text'
              palette='text'
              onClick={() => onCancel()}
              className={styles.cancel}
            >
              Cancel
            </Button>

            <Button
              palette='error'
              onClick={() =>
                isAccessType ? stepChanged('credentials') : submit()
              }
            >
              {!md ? 'Yes, delete' : 'Yes, I want to delete'}
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Container>
    );
  }
);

export { Attention };
