import { $$emailConfirmation } from '@widgets/email-confirmation';

import { $$restoreCareSpace } from '@features/care-space/restore';

import { $$user } from '@entities/user';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { Modal } from '@shared/ui/atoms/modal';

import { Text } from '@shared/ui/atoms/text';

import styles from './welcome.module.scss';

const Welcome = createView()
  .units({
    isInOwnCareSpace: $$emailConfirmation.$inOwnCareSpace,

    isRestored: $$restoreCareSpace.$isRestored,

    account: $$user.$account,

    onCloseClick: $$emailConfirmation.closeClicked,

    onGoToEmailConfirmationClick:
      $$emailConfirmation.goToEmailConfirmationClicked
  })

  .view(
    ({ account, isRestored, onCloseClick, onGoToEmailConfirmationClick }) => (
      <Modal.Container
        className={styles.welcome}
        onClose={onCloseClick}
        size='small'
      >
        <Modal.Header>
          <div className={styles.title}>
            <span className={styles.name}>{account?.firstName}</span>

            <span>{`, welcome ${isRestored ? 'back ' : ''}to Carers HQ!`}</span>
          </div>
        </Modal.Header>

        <Modal.Body className={styles.welcomeContent}>
          <Text variant='body-1' className={styles.welcomeText}>
            We are happy to have you as part of our service Carers HQ.
          </Text>

          <p className={styles.welcomeTitle}>
            To continue using the Carers HQ you need to make{' '}
            {!account?.billingActive ? 2 : 1} simple step
            {!account?.billingActive ? 's' : ''}:
          </p>

          <ul className={styles.welcomeActions}>
            <li>
              - Confirm your email{' '}
              <span>(This is to keep your account safe)</span>
            </li>

            {!account?.billingActive && (
              <li className={styles.addPaymentDetails}>
                - Add payment details{' '}
                <span>
                  (It's free. You will not be charged any money until you
                  receive services from the carer)
                </span>
              </li>
            )}
          </ul>
        </Modal.Body>

        <Modal.Footer className={styles.footer}>
          <Button
            palette='text'
            variant='text'
            onClick={() => onCloseClick()}
            className={styles.cancel}
          >
            Cancel
          </Button>

          <Button onClick={() => onGoToEmailConfirmationClick()}>
            Go to email confirmation
          </Button>
        </Modal.Footer>
      </Modal.Container>
    )
  );

export { Welcome };
