import type React from 'react';

import { useEffect } from 'react';

/**
 */
const useSimpleClickOutside = (
  ref: React.MutableRefObject<any>,
  onClickOutside: () => void
) => {
  const onClick = (event: MouseEvent) => {
    if (ref.current?.contains(event.target)) {
      return;
    }

    onClickOutside();
  };

  useEffect(() => {
    document.addEventListener('mousedown', onClick);

    return () => {
      document.removeEventListener('mousedown', onClick);
    };
  });
};

export { useSimpleClickOutside };
