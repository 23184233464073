import photo from '@img/banner-avatar.png';

import { Link } from 'atomic-router-react';

import classNames from 'classnames';

import { WorkspaceStatus } from '@shared/api';

import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { ReactComponent as ArrowBack } from '@shared/ui/assets/icons/arrow-back.svg';

import { Button } from '@shared/ui/atoms/button';

import { Card } from '@shared/ui/atoms/card';

import styles from './workspace-action.module.scss';

import type { WorkspaceActionProps } from './workspace-action.props';

import { useWorkspaceActionProps, Type } from './workspace-action.props';

const WorkspaceAction = createView<WorkspaceActionProps>()
  .map(useWorkspaceActionProps)

  .view(
    ({
      type,
      ownWorkspace,
      leftWorkspaceName,
      accountWorkspaces,
      onWorkspaceActionClick,
      onWorkspaceClick
    }) => {
      const hasWorkspaces = !!accountWorkspaces.length;

      return (
        <div className={styles.workspaceDeleted}>
          <Card
            className={classNames(styles.card, {
              [styles.cardNoWorkspaces]: !hasWorkspaces
            })}
          >
            <div className={styles.details}>
              <div className={styles.imageWrapper}>
                <img src={photo} alt='Alice' />
              </div>

              <div className={styles.titleWrapper}>
                <h1 className={styles.title}>
                  {type === Type.Deleted && 'Your Care Space has been deleted'}

                  {type === Type.Left &&
                    `You left ${leftWorkspaceName}. Care Space`}
                </h1>

                {hasWorkspaces && (
                  <p className={styles.link} onClick={onWorkspaceActionClick}>
                    {ownWorkspace &&
                    ownWorkspace.workspace.status != WorkspaceStatus.Deleted
                      ? 'Go to my Care Space'
                      : 'Create your own Care Space'}
                  </p>
                )}

                {!hasWorkspaces && (
                  <>
                    <p
                      className={classNames(
                        styles.infoText,
                        styles.infoTextNoWorkspace
                      )}
                    >
                      You are no longer a member of any Care Space.
                    </p>

                    <Link className={styles.link} to={routes.settings.account}>
                      Go to my account
                    </Link>
                  </>
                )}
              </div>

              {hasWorkspaces ? (
                <p className={classNames(styles.infoText, styles.memberNotice)}>
                  You are a member of several Care Space. <br />
                  Select the one where you want to continue using Carers HQ.
                </p>
              ) : (
                <Button onClick={onWorkspaceActionClick}>
                  {ownWorkspace &&
                  ownWorkspace.workspace.status != WorkspaceStatus.Deleted
                    ? 'Go to my Care Space'
                    : 'Create own Care Space'}
                </Button>
              )}
            </div>

            <ul className={styles.spaces}>
              {accountWorkspaces.map(
                ({ id, owner: { firstName, lastName }, relations }) => (
                  <li key={id} className={styles.space}>
                    <article className={styles.spaceInfo}>
                      <h2>
                        {firstName} {lastName} Care Space
                      </h2>

                      <p>
                        You are a{' '}
                        {relations
                          .map(
                            ({
                              clientRelation: { name },
                              client: { firstName, lastName }
                            }) => `${name} to ${firstName} ${lastName}`
                          )
                          .join(', ')}
                      </p>
                    </article>

                    <Button
                      palette='secondary'
                      className={styles.action}
                      onClick={() => onWorkspaceClick(id)}
                    >
                      <ArrowBack />
                    </Button>
                  </li>
                )
              )}
            </ul>
          </Card>
        </div>
      );
    }
  );

export { WorkspaceAction };
