import classNames from 'classnames';

import type { FC } from 'react';

import styles from './tabs.module.scss';

import type { TabsProps } from './tabs.props';

const Tabs: FC<TabsProps> = ({
  tabs,
  selected,
  className,
  onChange,
  variant,
  size,
  disabled
}) => (
  <div
    className={classNames(className, {
      [styles[variant]]: variant
    })}
  >
    {tabs.map(({ id, name }) => (
      <div
        className={classNames(styles.tab, {
          [styles.active]: id == selected,
          [styles[size]]: size,
          [styles.disabled]: id == disabled
        })}
        key={id}
        onClick={() => {
          onChange(id);
        }}
      >
        {name}
      </div>
    ))}
  </div>
);

export { Tabs };
