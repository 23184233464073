import { createEvent, sample } from 'effector';

import { $$carer } from '@entities/carer';

import { $$user } from '@entities/user';

import type { Account, Carer } from '@shared/api';

import { atom } from '@shared/lib/factory';

const $$loadCarerData = atom('Load carer general data', () => {
  const load = createEvent<Account>();

  const loadCarerData = createEvent<Carer>();

  const loaded = createEvent();

  sample({
    clock: load.map(state => state.carer),

    target: $$carer.$carer
  });

  sample({
    clock: load,

    target: $$user.$account
  });

  sample({
    clock: [load, loadCarerData],

    target: loaded
  });

  return {
    load,
    loadCarerData,
    loaded
  };
});

export { $$loadCarerData };
