import { useUnit } from 'effector-react';

import { useEffect } from 'react';

import { $$createWorkspace } from '@features/account/create-workspace/model';

import { $$selectCareSpace } from '@features/care-space/select';

import {
  $hasOwnCareSpace,
  $ownCareSpace
} from '@entities/_computed/care-space';

import { $$careRecipient } from '@entities/care-recipient';

import { $$careSpaceRelations } from '@entities/care-space';

import { WorkspaceStatus } from '@shared/api';

enum Type {
  Left = 1,
  Deleted = 2
}

type WorkspaceActionProps = {
  type: Type;
  leftWorkspaceName: string;
};

const useWorkspaceActionProps = (_: WorkspaceActionProps) => {
  const [
    clientId,
    onWorkspaceClick,
    onCreateCareSpaceClick,
    getUserWorkspaceRelations,
    userWorkspaceRelations,
    ownWorkspace
  ] = useUnit([
    $$careRecipient.$careRecipientID,
    $$selectCareSpace.careSpaceClicked,
    $$createWorkspace.workspaceVisibilityOpened,
    $$careSpaceRelations.getUserWorkspaceRelationsFx,
    $$careSpaceRelations.$userWorkspaceRelations,

    $ownCareSpace,
    $hasOwnCareSpace
  ]);

  const onWorkspaceActionClick = () => {
    if (
      ownWorkspace &&
      ownWorkspace.workspace.status != WorkspaceStatus.Deleted
    ) {
      onWorkspaceClick(ownWorkspace.workspace.id);

      return;
    }

    onCreateCareSpaceClick();
  };

  useEffect(() => {
    getUserWorkspaceRelations();
  }, []);

  return {
    clientId,
    ownWorkspace,
    accountWorkspaces: userWorkspaceRelations,
    onWorkspaceClick,
    onWorkspaceActionClick
  };
};

export type { WorkspaceActionProps };

export { useWorkspaceActionProps, Type };
