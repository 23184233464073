import { useUnit } from 'effector-react';

import { useMemo } from 'react';

import { $$careSpace } from '@entities/care-space';

import { $$dictionary } from '@entities/dictionary';

import { useUpdate } from '@shared/lib/hooks';

import { $$leaveWorkspace, form } from '../model/leave-workspace';

const $name = $$careSpace.$careSpace.map(state =>
  state ? `${state.owner.firstName} ${state.owner.lastName}` : null
);

const useLeaveWorkspaceProps = () => {
  const [options, ownerName, reasonValue, reasonOther, onClose] = useUnit([
    $$dictionary.$memberLeaveCauses,
    $name,
    form.fields.reason.$value,
    form.fields.reasonOther.$value,
    $$leaveWorkspace.leaveWorkspaceClosed
  ]);

  const isTextareaActive = useMemo(
    () => reasonValue === 'Other',
    [reasonValue]
  );

  useUpdate(() => {
    if (!reasonOther) return;

    form.fields.reasonOther.changed('');
  }, [reasonValue]);

  return {
    form,
    ownerName,
    options,
    isTextareaActive,
    onClose
  };
};

export { useLeaveWorkspaceProps };
