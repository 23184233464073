import moment from 'moment';

const minCareRecipientBirth = moment()
  .subtract(120, 'years')

  .format('YYYY-MM-DD');

const maxCareRecipientBirth = moment()
  .subtract(17, 'years')

  .format('YYYY-MM-DD');

export { minCareRecipientBirth, maxCareRecipientBirth };
