import { createEffect, createEvent, createStore } from 'effector';

import type { Account } from '@shared/api';

import { AccountStatus, carersHQ } from '@shared/api';

const accountBlocked = createEvent();

const accountStatusChanged = createEvent<AccountStatus>();

const getAccountFx = createEffect<void, Account>();

const $account = createStore<Account>(null);

const $$user = {
  $account,

  accountBlocked,
  accountStatusChanged,

  getAccountFx
};

$account.on(accountBlocked, state => ({
  ...state,

  status: AccountStatus.Blocked
}));

getAccountFx.use(async () => {
  const { data } = await carersHQ.accounts.current();

  return data;
});

export { $$user };
