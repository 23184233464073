import { $$dictionary, LanguagesPicker } from '@entities/dictionary';

import { createView } from '@shared/lib/view';

import { BoxRow } from '@shared/ui/atoms/box-row';

import { FormSection } from '@shared/ui/atoms/form-section';

import { RadioGroup } from '@shared/ui/atoms/radio';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { form } from '../model';

import styles from './preferences.module.scss';

/**
 * <Preferences />
 */
const PreferencesForm = createView()
  .units({
    pets: $$dictionary.$pets,

    transportationHelpTypes: $$dictionary.$transportationHelpTypes,

    genders: $$dictionary.$genders,

    languages: form.fields.languages.$value,

    onLanguageChange: form.fields.languages.change
  })

  .view(
    ({
      pets,
      genders,
      transportationHelpTypes,
      languages,
      onLanguageChange
    }) => (
      <Form className={styles.formFields} use={form}>
        <LanguagesPicker
          classNames={{ list: styles.languages }}
          label='Languages'
          value={languages}
          onChange={value => onLanguageChange(value as any)}
        />

        <FormSection title='Gender preferences'>
          <div className={styles.genders}>
            <Field.RadioList
              use={form.fields.carerGender}
              options={[
                {
                  id: null,

                  name: 'No preference'
                },

                ...genders
              ]}
            />
          </div>
        </FormSection>

        <FormSection title='Is help with transportation required?'>
          <RadioGroup layout='vertical'>
            <Field.RadioList
              use={form.fields.transportationHelp}
              options={[
                {
                  id: null,

                  name: 'No'
                },

                ...transportationHelpTypes
              ]}
            />
          </RadioGroup>
        </FormSection>

        <FormSection title='Does anyone smoke in the home?'>
          <BoxRow layout='horizontalAlways'>
            <Field.RadioList
              use={form.fields.smoking}
              options={[
                { id: false, name: 'No' },
                { id: true, name: 'Yes' }
              ]}
            />
          </BoxRow>
        </FormSection>

        <Field.FormSection
          key='pets'
          use={form.fields.pets}
          title='Are there any pets in the home?'
        >
          <div className={styles.pets}>
            <Field.CheckboxList
              key='pets'
              use={form.fields.pets}
              options={pets}
            />
          </div>
        </Field.FormSection>
      </Form>
    )
  );

export { PreferencesForm };
