import classNames from 'classnames';

import moment from 'moment';

import { useRef, useState, useMemo, useEffect } from 'react';

import { useSimpleClickOutside } from '@shared/lib/hooks';

import { eurDateRegex, months } from '@shared/lib/utils';

import { createView } from '@shared/lib/view';

import { Calendar } from '@shared/ui/atoms/calendar';

import { CalendarIcon } from '@shared/ui/atoms/icons';

import { Input } from '@shared/ui/atoms/input';

import type { DatePickerProps } from '@shared/ui/molecules/date-picker/data-picker.props';

import { $theme } from '@shared/ui/theme';

import styles from './date-picker.module.scss';

const DatePicker = createView<DatePickerProps>()
  .units({
    theme: $theme
  })

  .map(
    ({
      onChange,
      focused,
      minDate,
      value,
      placeholder,
      inputRef: propsInputRef
    }) => {
      const container = useRef(null!);

      const [visible, setVisible] = useState(false);

      const [text, setText] = useState('');

      const inputRef = useRef<HTMLInputElement>(null!);

      if (propsInputRef) {
        propsInputRef.current = inputRef.current;
      }

      const dateCaption = useMemo(() => {
        if (!value) return (placeholder as string) || '';

        const _date = moment(value);

        return [_date.date(), months[_date.month()], _date.year()].join(' ');
      }, [value]);

      const onIconClick = () => {
        inputRef.current!.focus();
      };

      const onCalendarChange = (value: string) => {
        const result = moment(value);

        onChange?.(result.format('YYYY-MM-DD'));

        setText(moment(value).format('DD/MM/YYYY'));

        setVisible(false);

        // to unfocus from field bc we need to close calendar
        const input: HTMLInputElement = document?.activeElement as any;

        input?.blur();
      };

      const onInputChange = (value: string) => {
        setText(value);

        const result = moment(value, 'DD/MM/YYYY');

        if (
          !result.isValid() ||
          !eurDateRegex.test(value) ||
          (minDate && !result.isSameOrAfter(minDate, 'date'))
        )
          return;

        onChange?.(result.format());
      };

      useSimpleClickOutside(container, () => {
        setVisible(false);
      });

      useEffect(() => {
        const date = moment(value);

        if (!value || !date.isValid() || focused) return;

        setText(date.format('DD/MM/YYYY'));
      }, [value]);

      return {
        text,
        visible,
        focused,
        inputRef,
        container,
        onIconClick,
        dateCaption,
        onInputChange,
        onCalendarChange
      };
    }
  )
  .view(
    ({
      text,
      container,
      visible,
      value,
      focused,
      theme,
      minDate,
      error,
      hasError,
      inputRef,
      onCalendarChange,
      onChange,
      onInputChange,
      onIconClick,
      dateCaption,
      className,
      ...props
    }) => (
      <Input
        value={dateCaption}
        mode='view'
        className={classNames({
          [styles.focused]: focused
        })}
        error={error}
        hasError={hasError}
        onChange={onInputChange}
        inputRef={inputRef}
        append={
          <div className={styles.container} ref={container}>
            <CalendarIcon
              className={styles.icon}
              onClick={onIconClick}
              color={focused ? theme.yellowPrimary : theme.textBlack}
            />

            {(focused || visible) && (
              <div className={classNames(styles.calendar, className)}>
                <div
                  onMouseDown={event => {
                    event.preventDefault();
                  }}
                >
                  <Calendar
                    value={value}
                    onChange={onCalendarChange}
                    minDate={minDate}
                  />
                </div>
              </div>
            )}
          </div>
        }
        {...props}
      />
    )
  );

export { DatePicker };
