import { Auth } from '@layouts/auth';

import { WorkspaceAction } from '@widgets/workspace-action';

import { Type } from '@widgets/workspace-action/workspace-action.props';

import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

const CareSpaceDeletionConfirmed = createView().view(() => (
  <Auth.Container>
    <Auth.Header centered logoTo={routes.schedule} />

    <Auth.Body>
      <WorkspaceAction type={Type.Deleted} leftWorkspaceName='' />
    </Auth.Body>
  </Auth.Container>
));

export { CareSpaceDeletionConfirmed };
