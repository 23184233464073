import arrowBack from '@icon/arrow-back.svg';

import avatar from '@img/circle-avatar.png';

import { Link } from 'atomic-router-react';

import classNames from 'classnames';

import type { FC } from 'react';

import { Card } from '../card';

import styles from './deleted-state-info.module.scss';

import type { DeletedStateInfoProps } from './deleted-state-info.props';

const DeletedStateInfo: FC<DeletedStateInfoProps> = ({
  photo,
  title,
  classNames: _classNames,
  className,
  subTitle,
  children,
  description,
  options
}) => (
  <Card className={classNames(styles.deletedStateInfo, className)}>
    <img
      className={classNames(styles.photo, _classNames?.photo)}
      src={photo || avatar}
    />

    {title && (
      <h4 className={classNames(styles.title, _classNames?.title)}>{title}</h4>
    )}

    {subTitle && (
      <div className={classNames(styles.subTitle, _classNames?.subTitle)}>
        {subTitle}
      </div>
    )}

    {description && (
      <div className={classNames(styles.description, _classNames?.description)}>
        {description}
      </div>
    )}

    {(children || options) && (
      <div className={styles.content}>
        {options && (
          <ul className={styles.options}>
            {options.map(({ title, subTitle, route }, index) => (
              <li
                className={classNames(styles.option, _classNames?.option)}
                key={index}
              >
                <div className={styles.optionContent}>
                  <div
                    className={classNames(
                      styles.optionTitle,
                      _classNames?.optionTitle
                    )}
                  >
                    {title}
                  </div>

                  <div className={styles.optionSubTitle}>{subTitle}</div>
                </div>

                <Link className={styles.optionGo} to={route}>
                  <img src={arrowBack} />
                </Link>
              </li>
            ))}
          </ul>
        )}

        {children}
      </div>
    )}
  </Card>
);

export { DeletedStateInfo };
