import { $$emailConfirmation } from '@widgets/email-confirmation';

import { $$user } from '@entities/user';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { Modal } from '@shared/ui/atoms/modal';

import styles from './sent-email.module.scss';

const SentEmail = createView()
  .units({
    account: $$user.$account,

    onCloseClick: $$emailConfirmation.closeClicked,

    onContactUsClick: $$emailConfirmation.contactUsClicked,

    onChangeEmailClick: $$emailConfirmation.changeEmailClicked
  })

  .view(({ account, onChangeEmailClick, onContactUsClick, onCloseClick }) => (
    <Modal.Container
      size='small'
      className={styles.emailSent}
      onClose={() => onCloseClick()}
    >
      <Modal.Header>Email has been sent</Modal.Header>

      <Modal.Body className={styles.emailSentContent}>
        <p className={styles.emailSentText}>
          Instruction for confirmation your account has been sent to{' '}
          <span className={styles.highlight}>{account?.email}</span>
        </p>

        <p className={styles.emailSentText}>
          You’ll receive this email within 5 minutes. Be sure to check your spam
          folder, too. Is there a mistake in the email?{' '}
          <span className={styles.link} onClick={() => onChangeEmailClick()}>
            Click here to change.
          </span>
        </p>
      </Modal.Body>

      <Modal.Footer className={styles.footer}>
        <Button onClick={() => onContactUsClick()}>Contact us</Button>
      </Modal.Footer>
    </Modal.Container>
  ));

export { SentEmail };
