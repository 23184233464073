import type { FC } from 'react';

import { memo } from 'react';

import type { IconProps } from '../icon.props';

import styles from './exclamation-mark.module.scss';

const ExclamationMarkIcon: FC<IconProps> = memo(
  ({ borderColor, background, color }) => (
    <span
      className={styles.icon}
      style={{
        borderColor,
        background,
        color
      }}
    >
      !
    </span>
  )
);

export { ExclamationMarkIcon };
