import styles from './message-success.module.scss';

const MessageSuccess = () => (
  <p className={styles.text}>
    We sent email notifications to the invited users. You can resend the
    invitation via the menu near their accounts.
  </p>
);

export { MessageSuccess };
