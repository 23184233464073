import { redirect } from 'atomic-router';

import { createEvent } from 'effector';

import { routes } from '@shared/routes';

const becomeCarerClicked = createEvent();

const findCarerClicked = createEvent();

const $$signInPage = {
  findCarerClicked,
  becomeCarerClicked
};

redirect({
  clock: becomeCarerClicked,

  route: routes.signUp.carer.postcode
});

redirect({
  clock: findCarerClicked,

  route: routes.signUp.type
});

export { $$signInPage };
