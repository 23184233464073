import { $$billing } from '@entities/billing';

import { createView } from '@shared/lib/view';

import { Details } from './details';

import { PasswordConfirmation } from './password-confirmation';

/**
 * <BillingDetails />
 */
const BillingDetails = createView()
  .units({
    details: $$billing.$details,

    publicDetails: $$billing.$publicDetails
  })

  .view(({ details, publicDetails }) => {
    if (publicDetails && !details) {
      return <PasswordConfirmation />;
    }

    return <Details />;
  });

export { BillingDetails };
