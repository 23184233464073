import axios from 'axios';

import { createEffect, createEvent, createStore, sample } from 'effector';

import { createGate } from 'effector-react';

import { object, string } from 'yup';

import type { ChangePasswordDto, Response } from '@shared/api';

import { carersHQ } from '@shared/api';

import { createForm } from '@shared/lib/form';

import { passwordRegEx } from '@shared/lib/utils';

import { routes } from '@shared/routes';

type ChangePasswordValues = ChangePasswordDto & {
  repeatPassword: string;
};

type ChangePasswordOptions = {
  token: string;

  password: string;
};

const closeErrorBoxClicked = createEvent();

const changePasswordByResetFx = createEffect<ChangePasswordOptions, void>();

const $changePasswordError = createStore<string | null>('');

const $passwordsDoNotMatch = createStore(false);

const TokenGate = createGate<{ token: string }>();

const $schema = createStore<any>(null);

const form = createForm<ChangePasswordValues>({
  initialValues: {
    password: '',

    repeatPassword: ''
  },

  $schema
});

const $$changeUserPasswordByReset = {
  $changePasswordError,

  form,

  changePasswordByResetFx
};

sample({
  source: form.$values,

  fn: values => {
    const fields = {
      password: string()
        .nullable()

        .required()

        .trim('Required')

        .label('Password')

        .matches(passwordRegEx, 'Invalid format')

        .min(6, 'Least 6 digits long and contain numbers and upper case')

        .trim('Required'),

      repeatPassword: string().required().trim().nullable().label('Password')
    };

    if (values.repeatPassword) {
      fields.password = fields.password
        .required()
        .equals([values.repeatPassword], `Passwords do not match`) as any;
    }

    if (values.password) {
      fields.repeatPassword = fields.repeatPassword
        .required()
        .equals([values.password], `Passwords do not match`) as any;
    }

    return object(fields);
  },

  target: $schema
});

// spec({
//   on: form.fields.repeatPassword,
//
//   schema: form.fields.password.$value.map(password =>
//     password
//       ? string().required().equals([password], 'Passwords do not match')
//       : null
//   )
// });

sample({
  clock: changePasswordByResetFx.doneData,

  target: routes.auth.signIn.open.prepend(() => ({}))
});

sample({
  clock: form.submitted,

  source: TokenGate.state,

  fn: ({ token }, { password }) => ({
    token,
    password
  }),

  target: changePasswordByResetFx
});

$changePasswordError.on(changePasswordByResetFx.failData, (_, error) => {
  if (axios.isAxiosError(error)) {
    const response = error.response.data as Response;

    return response.message;
  }

  return 'Something went wrong';
});

$passwordsDoNotMatch
  .on(
    form.submitted,

    (_, { password, repeatPassword }) => password != repeatPassword
  )

  .reset(closeErrorBoxClicked);

changePasswordByResetFx.use(async payload => {
  await carersHQ.auth.changePassword(payload);
});

export {
  $$changeUserPasswordByReset,
  $passwordsDoNotMatch,
  form,
  closeErrorBoxClicked,
  TokenGate
};
