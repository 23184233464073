import type { FC } from 'react';

import styles from './rating.module.scss';

import type { RatingProps } from './rating.props';

/**
 * <Rating />
 */
const Rating: FC<RatingProps> = () => (
  <div className={styles.rating}>
    {/* content */}

    {/* content */}

    {/* content */}
  </div>
);

export { Rating };
