import classNames from 'classnames';

import type { FC, PropsWithChildren } from 'react';

import { useState } from 'react';

import { Button } from '@shared/ui/atoms/button';

import styles from './accordeon.module.scss';

type AccordeonProps = PropsWithChildren<{
  title: string;

  opened?: boolean;

  className?: string;
}>;

const Accordeon: FC<AccordeonProps> = ({
  title,
  children,
  className,
  opened
}) => {
  const [expanded, setExpanded] = useState(opened ?? false);

  const onToggleClick = () => {
    setExpanded(value => !value);
  };

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.header}>
        <span className={styles.title} title={title}>
          {title}
        </span>

        <Button variant='text' palette='text' onClick={onToggleClick}>
          {expanded ? 'Hide' : 'Show'}
        </Button>
      </div>

      {expanded && <div className={styles.content}>{children}</div>}
    </div>
  );
};

export { Accordeon };
