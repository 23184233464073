import moment from 'moment';

import { $$openAddressOnMap } from '@features/address/open-on-map';

import {
  $$profilePersonal,
  PersonalForm
} from '@features/care-recipient/profile/personal';

import { $$careRecipient } from '@entities/care-recipient';

import { months } from '@shared/lib/utils';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { EditableSection } from '@shared/ui/atoms/editable-section';

import { useMedia } from '@shared/ui/atoms/media';

import { TextPair } from '@shared/ui/atoms/text-pair';

import { $$profilePage } from '../../model';

import styles from './personal.module.scss';

/**
 * <Personal />
 */
const Personal = createView()
  .units({
    client: $$careRecipient.$careRecipientsProfile,

    editing: $$profilePage.personalDetails.$opened,

    onSave: $$profilePersonal.form.submit,

    onCancel: $$profilePage.personalDetails.close,

    onEdit: $$profilePage.personalDetails.open,

    onImageUpload: $$profilePage.uploadImageClicked
  })

  .map(({ client }) => {
    const md = useMedia('>=md');

    const onShowMapClick = () => {
      $$openAddressOnMap.openFullAddressFx(
        client as Required<typeof client> as any
      );
    };

    return {
      md,

      onShowMapClick
    };
  })
  .view(
    ({
      client,

      editing,

      onSave,

      onCancel,

      onEdit,

      onShowMapClick,

      onImageUpload
    }) => {
      const birthday = moment(client?.dateOfBirth);

      return (
        <EditableSection
          title='Personal details'
          editing={editing}
          onSave={() => onSave()}
          onCancel={() => onCancel()}
          onEdit={() => onEdit()}
        >
          {({ editing }) =>
            editing ? (
              <PersonalForm onImageUpload={onImageUpload} />
            ) : (
              <div className={styles.fields}>
                <TextPair
                  label='Full name:'
                  value={`${client?.firstName} ${client?.lastName}`}
                  wrap
                />

                <TextPair label='Gender:' value={client?.gender?.name} />

                <TextPair
                  label='Date of birth:'
                  value={
                    <>
                      <span>
                        {birthday.format(
                          `DD [${months[birthday.month()]}] YYYY`
                        )}
                      </span>

                      <span className={styles.year}>
                        {' '}
                        ({Math.abs(birthday.diff(moment(), 'years'))} years)
                      </span>
                    </>
                  }
                />

                <TextPair
                  label='Address:'
                  wrap
                  value={
                    <div>
                      <div>
                        {[
                          client?.addressLine1,
                          client?.addressLine2,
                          client?.city,
                          client?.postCode.replace(/^(.*)(.{3})$/, '$1 $2')
                        ]
                          .filter(item => item)
                          .join(', ')}
                      </div>

                      <Button
                        palette='text'
                        variant='text'
                        className={styles.showOnAMap}
                        onClick={onShowMapClick}
                      >
                        Show on a map
                      </Button>
                    </div>
                  }
                />
              </div>
            )
          }
        </EditableSection>
      );
    }
  );

export { Personal };
