import { Link } from 'atomic-router-react';

import classNames from 'classnames';

import { useMemo } from 'react';

import {
  $$contactEditRelationship,
  EditRelationship
} from '@features/care-recipient/edit-relationship';

import { $$careRecipient } from '@entities/care-recipient';

import { $$careSpace } from '@entities/care-space';

import { $$members } from '@entities/members';

import type { WorkspaceMember } from '@shared/api';

import { MembersRoleType } from '@shared/api';

import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { Button } from '@shared/ui/atoms/button';

import { EditableSection } from '@shared/ui/atoms/editable-section';

import { Tabs } from '@shared/ui/atoms/tabs';

import { TextPair } from '@shared/ui/atoms/text-pair';

import styles from './contacts.module.scss';

import './contacts.module';

import type { ContactsProps } from './contacts.props';

const Contacts = createView<ContactsProps>()
  .units({
    client: $$careRecipient.$careRecipientsProfile,

    members: $$members.$members,

    careSpace: $$careSpace.$careSpace,

    visible: $$contactEditRelationship.$visible,

    onEdit: $$contactEditRelationship.modalOpened
  })
  .map(({ members, client }) => {
    const contacts = useMemo(
      () =>
        members.reduce<WorkspaceMember[]>((contacts, member) => {
          if (
            !member.account.relations.find(
              item => item.client.id === client?.id
            )
          )
            return contacts;

          return [...contacts, member];
        }, []),
      [client, members]
    );

    return {
      contacts
    };
  })

  .view(
    ({
      client,
      careSpace: { role },
      tabChanged,
      tab,
      contacts,
      visible,
      onEdit
    }) => (
      <>
        <div className={styles.tabList}>
          <Tabs
            tabs={[
              { id: 'profile', name: 'Profile' },
              { id: 'medication', name: 'Medication' },
              { id: 'wellness', name: 'Wellness' },
              { id: 'contacts', name: 'Contacts' }
            ]}
            className={styles.tab}
            onChange={value => tabChanged(value)}
            selected={tab}
            size='lg'
            variant='inline'
          />
        </div>

        <div className={styles.contacts}>
          <div
            className={classNames(styles.members, {
              [styles.membersNotDeletable]: role === MembersRoleType.ViewOnly
            })}
          >
            {contacts.map(({ account }) => {
              const { clientRelation } = account.relations.find(
                item => item.client.id === client.id
              );

              return (
                <EditableSection
                  editing={false}
                  editable={
                    role !== MembersRoleType.ViewOnly && !!clientRelation
                  }
                  onEdit={() => onEdit({ clientRelation, account })}
                  title={`${account.firstName} ${account.lastName}`}
                  key={account.id}
                >
                  <div className={styles.fields}>
                    <TextPair
                      label='Relationship:'
                      value={clientRelation ? clientRelation.name : '-'}
                    />

                    <TextPair
                      className={styles.nowrap}
                      wrap
                      label='Email:'
                      value={account.email}
                    />

                    <TextPair label='Phone:' value={account.phone} />
                  </div>
                </EditableSection>
              );
            })}
          </div>

          {role !== MembersRoleType.ViewOnly && (
            <>
              <p className={styles.instructions}>
                If you want to manage {client?.firstName} {client?.lastName}’s
                contacts you can click button below.
              </p>

              <p className={styles.notice}>
                Please note, also you can do it on the Settings page.
              </p>

              <Link to={routes.settings.members}>
                <Button className={styles.manage} palette='secondary'>
                  Manage contacts
                </Button>
              </Link>
            </>
          )}
        </div>

        {role !== MembersRoleType.ViewOnly && visible && <EditRelationship />}
      </>
    )
  );

export { Contacts };
