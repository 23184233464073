import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { DeletedStateInfo } from '@shared/ui/atoms/deleted-state-info';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { form } from '../model';

import styles from './restore-form.module.scss';

const RestoreForm = createView().view(() => (
  <DeletedStateInfo
    className={styles.card}
    classNames={{
      subTitle: styles.subTitle
    }}
    photo=''
    title='Restore account'
    subTitle={
      <p className={styles.restoreSubTitle}>
        To restore your account, enter your email and password. <br /> If you
        are having difficulties, please{' '}
        <a
          className={styles.link}
          target='_blank'
          href='https://google.com'
          rel='noreferrer'
        >
          contact us
        </a>
        .
      </p>
    }
  >
    <Form use={form}>
      <div className={styles.email}>
        <Field.Input
          use={form.fields.email}
          name='email'
          label='Email'
          errorIn='label'
          placeholder='Enter your email'
        />
      </div>

      <div className={styles.password}>
        <Field.Input
          use={form.fields.password}
          name='password'
          label='Password'
          type='password'
          errorIn='label'
          placeholder='Enter your password'
        />
      </div>

      <Button
        className={styles.continue}
        type='submit'
        onClick={() => form.submit}
      >
        Continue
      </Button>
    </Form>
  </DeletedStateInfo>
));

export { RestoreForm };
