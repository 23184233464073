import { SetAccountRelationsForm } from '@features/account/set-relations';

import { $$careRecipient } from '@entities/care-recipient';

import { $$careSpace } from '@entities/care-space';

import { MembersRoleType } from '@shared/api';

import { connect, createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { Anchor } from '@shared/ui/atoms/anchor';

import { Button } from '@shared/ui/atoms/button';

import { EditableSection } from '@shared/ui/atoms/editable-section';

import { TextPair } from '@shared/ui/atoms/text-pair';

import { css, styled } from '@shared/ui/stitches';

import { $$accountPage } from '../../account.model';

const Section = connect(EditableSection)
  .static({
    title: 'Care recipients',

    controls: {
      save: {
        text: 'Save changes'
      }
    }
  })

  .units({
    editable: $$careSpace.$careSpace.map(
      state => state?.role == MembersRoleType.Owner
    ),

    onEdit: $$accountPage.editCareRecipietnsClicked.prepend(() => {}),

    editing: $$accountPage.careRecipients.$opened,

    onCancel: $$accountPage.careRecipients.close.prepend(() => {}),

    onSave: $$accountPage.saveCareRecipientsClicked.prepend(() => {})
  })

  .view();

const CareRecipients = createView()
  .units({
    careRecipients: $$accountPage.$$setAccountRelations.$relationShips,

    selectedCareRecipient: $$careRecipient.$selectedCareRecipient
  })

  .view(({ careRecipients, selectedCareRecipient }) => (
    <Section>
      {({ editing }) => {
        const description = (
          <Description>
            If you want to add or edit the list of the care recipients and
            relationships with them,{' '}
            <MembersLink
              to={routes.careRecipients.contacts as any}
              params={{ id: selectedCareRecipient?.id }}
            >
              <Button variant='text' palette='text'>
                click here
              </Button>
            </MembersLink>
            .
          </Description>
        );

        if (editing) {
          return (
            <>
              {description}

              <Fields>
                <SetAccountRelationsForm
                  className={fieldRow()}
                  use={$$accountPage.$$setAccountRelations}
                />
              </Fields>
            </>
          );
        }

        return (
          <>
            {description}

            <Rows>
              {careRecipients.map((item, index) => (
                <Row key={index}>
                  <TextPair
                    label='Contact for:'
                    value={`${item.client.firstName} ${item.client.lastName}`}
                  />

                  <TextPair
                    label='Relationship to care recipient:'
                    value={item.clientRelation.name}
                  />
                </Row>
              ))}
            </Rows>
          </>
        );
      }}
    </Section>
  ));

const fieldRow = css({
  display: 'grid',
  gridTemplateColumns: '1fr',
  rowGap: '12px',

  '@md': {
    rowGap: '0px',
    gridTemplateColumns: '240px 240px',
    columnGap: '20px'
  }
});

const Fields = styled('div', {
  display: 'grid',
  rowGap: '24px',

  '@md': {
    rowGap: '32px'
  }
});

const Row = styled('div', {
  display: 'grid',
  rowGap: '12px'
});

const Rows = styled('div', {
  display: 'grid',
  rowGap: '24px',

  '@md': {
    rowGap: '20px'
  }
});

const Description = styled('div', {
  maxWidth: '506px',
  fontSize: '15px',
  fontWeight: 300,
  lineHeight: '23px',
  marginBottom: '$2'
});

const MembersLink = styled(Anchor, {
  display: 'inline',
  fontSize: '15px',
  fontWeight: 500,
  lineHeight: '23px',
  cursor: 'pointer',

  '@md': {
    display: 'inline'
  }
});

export { CareRecipients };
