import classNames from 'classnames';

import { combine } from 'effector';

import moment from 'moment';

import React, { useEffect, useMemo, useRef } from 'react';

import { $$deletedAccount } from '@entities/deleted-account';

import { InvoiceStatus } from '@shared/api';

import { monthsShort } from '@shared/lib/utils';

import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { Breadcrumbs } from '@shared/ui/atoms/breadcrumbs';

import { Button } from '@shared/ui/atoms/button';

import { useMedia } from '@shared/ui/atoms/media';

import { DesktopInvoices } from './desktop-invoices';

import { $$invoices } from './invoices.model';

import styles from './invoices.module.scss';

import { MobileInvoices } from './mobile-invoices';

const Invoices = createView()
  .units({
    invoices: $$deletedAccount.$invoices,

    from: $$deletedAccount.$from,

    params: $$invoices.$params,

    tab: combine($$invoices.$params, my => {
      if (my.status === InvoiceStatus.All) return InvoiceStatus.All;

      if (my.status === InvoiceStatus.Unpaid) return InvoiceStatus.Unpaid;

      if (my.status === InvoiceStatus.Paid) return InvoiceStatus.Paid;

      if (my.status === InvoiceStatus.InProgress)
        return InvoiceStatus.InProgress;

      if (my.status === InvoiceStatus.Refunded) return InvoiceStatus.Refunded;

      return InvoiceStatus.All;
    }),

    searchText: $$invoices.$searchText,

    total: $$deletedAccount.$total,

    loading: $$deletedAccount.getInvoicesFx.pending,

    onTabChanged: $$invoices.tabChanged,

    onSearchTextChanged: $$invoices.searchTextChanged,

    onUserClicked: $$invoices.selectUserClicked,

    loadMoreInvoices: $$invoices.loadMoreInvoices
  })

  .map(
    ({
      from,
      params,
      searchText,
      invoices,
      loading,
      total,
      loadMoreInvoices
    }) => {
      const md = useMedia('>=md');

      const lg = useMedia('>=lg');

      const statuses = [
        {
          id: InvoiceStatus.Unpaid,
          name: 'Unpaid'
        },

        {
          id: InvoiceStatus.Paid,
          name: 'Paid'
        },

        {
          id: InvoiceStatus.InProgress,
          name: 'Processing'
        },

        {
          id: InvoiceStatus.Refunded,
          name: 'Refunded'
        }
      ];

      const statusOptions = [
        {
          id: 'All',
          name: 'All statuses'
        },

        ...statuses
      ];

      const statusTabs = [
        {
          id: 'All',
          name: 'All invoices'
        },

        ...statuses
      ];

      const tableContainerRef = useRef<HTMLDivElement>();

      const noInvoices = false;

      const noResultsForFilters =
        searchText || (params.status && params.status != 'All');

      const filteredInvoices = useMemo(
        () =>
          invoices.map(invoice => {
            const startDate = moment(invoice.startDate);

            const endDate = moment(invoice.endDate);

            const period = `${startDate.format(
              `DD [${monthsShort[startDate.month()]}] yyyy`
            )} - ${endDate.format(
              `DD [${monthsShort[endDate.month()]}] yyyy`
            )}`;

            return {
              invoice,

              displayValues: {
                fullName: [invoice?.carer?.firstName, invoice?.carer?.lastName]
                  .filter(item => item)

                  .join(' '),

                startDate,

                endDate,

                period
              }
            };
          }),
        [invoices]
      );

      const isReachedScrollPoint = (
        value: number,
        height: number,
        scrollHeight: number,
        threshold: number
      ) => {
        const difference = scrollHeight - (value + height);

        return difference < threshold;
      };

      const getMoreInvoices = () => {
        const next = from + 10;

        if (next >= total || loading) return;

        loadMoreInvoices(next);
      };

      const onTableContainerScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const difference =
          event.currentTarget.scrollHeight -
          (event.currentTarget.scrollTop +
            event.currentTarget.getBoundingClientRect().height);

        if (difference > 100) return;

        getMoreInvoices();
      };

      useEffect(() => {
        md
          ? tableContainerRef.current?.scrollTo(0, 0)
          : window?.scrollTo(0, 100);
      }, [params.search, params.status]);

      useEffect(() => {
        const onScroll = () => {
          if (md) return;

          if (
            !isReachedScrollPoint(
              window.scrollY,
              window.innerHeight,
              document.body.scrollHeight,
              150
            )
          )
            return;

          getMoreInvoices();
        };

        window.addEventListener('scroll', onScroll);

        return () => {
          window.removeEventListener('scroll', onScroll);
        };
      }, [md]);

      return {
        md,
        lg,
        filteredInvoices,
        statusTabs,
        statusOptions,
        tableContainerRef,
        noInvoices,
        noResultsForFilters,
        onTableContainerScroll
      };
    }
  )

  .view(
    ({
      md,
      tab,
      searchText,
      onSearchTextChanged,
      onTabChanged,
      statusTabs,
      filteredInvoices,
      onUserClicked,
      statusOptions,
      onTableContainerScroll,
      tableContainerRef,
      noInvoices,
      noResultsForFilters
    }) => (
      <div
        className={classNames(styles.invoices, {
          [styles.noInvoices]: noInvoices,
          [styles.noResultsForFilters]: noResultsForFilters
        })}
      >
        <div className={styles.header}>
          <Breadcrumbs
            className={styles.breadcrumbs}
            steps={[
              { label: 'Account', route: routes.deletedAccount.home },
              { label: 'Invoices', route: routes.deletedAccount.invoices }
            ]}
          />

          <Button
            className={classNames(styles.export, {
              [styles.exportInvisible]: noInvoices
            })}
            palette='secondary'
          >
            Export PDF file
          </Button>
        </div>

        {md ? (
          <DesktopInvoices
            filteredInvoices={filteredInvoices}
            noInvoices={noInvoices}
            noResultsForFilters={noResultsForFilters}
            tab={tab}
            searchText={searchText}
            onStatusChange={onTabChanged}
            onTableContainerScroll={onTableContainerScroll}
            onQueryChange={onSearchTextChanged}
            onRowClick={onUserClicked}
            statusTabs={statusTabs}
            tableContainerRef={tableContainerRef}
          />
        ) : (
          <MobileInvoices
            statusOptions={statusOptions}
            filteredInvoices={filteredInvoices}
            noInvoices={noInvoices}
            noResultsForFilters={noResultsForFilters}
            tab={tab}
            searchText={searchText}
            onStatusChange={onTabChanged}
            onQueryChange={onSearchTextChanged}
            onRowClick={onUserClicked}
          />
        )}
      </div>
    )
  );

export { Invoices };
