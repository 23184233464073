import { modelView } from 'effector-factorio';

import { useUnit } from 'effector-react';

import { connect } from '@shared/lib/view';

import { WizardFormStep } from '@shared/ui/molecules/wizard-form-step';

import { StepWizardModelFactory } from './step-wizard.model';

const StepWizard = modelView(
  StepWizardModelFactory,

  connect(WizardFormStep)
    .map(() => {
      const { $total, $current, backClicked, nextClicked } =
        StepWizardModelFactory.useModel();

      const props = useUnit({
        total: $total,

        current: $current,

        onGoBack: backClicked,

        onGoNext: nextClicked
      });

      return {
        ...props,

        current: props.current + 1
      };
    })

    .view()
);

export { StepWizard };
