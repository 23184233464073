import { $$emailConfirmation } from '@widgets/email-confirmation';

import { $$user } from '@entities/user';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { Modal } from '@shared/ui/atoms/modal';

import styles from './confirmation.module.scss';

const Confirmation = createView()
  .units({
    account: $$user.$account,

    onCloseClick: $$emailConfirmation.closeClicked,

    onConfirmEmailClick: $$emailConfirmation.confirmEmailClicked,

    onChangeEmailClick: $$emailConfirmation.changeEmailClicked
  })

  .view(
    ({ account, onCloseClick, onConfirmEmailClick, onChangeEmailClick }) => (
      <Modal.Container
        className={styles.emailConfirmation}
        onClose={() => onCloseClick()}
        size='small'
      >
        <Modal.Header>Email confirmation</Modal.Header>

        <Modal.Body className={styles.emailConfirmationContent}>
          <p className={styles.emailConfirmationDescription}>
            We will send a confirmation link to{' '}
            <span className={styles.highlight}>{account?.email}</span>. If the
            email didn't come, check the spam folder.
          </p>

          <p className={styles.emailConfirmationChangeEmail}>
            If you want to change your email address,{' '}
            <span
              className={styles.changeEmailLink}
              onClick={() => onChangeEmailClick()}
            >
              click here.
            </span>
          </p>
        </Modal.Body>

        <Modal.Footer className={styles.footer}>
          <Button
            palette='text'
            variant='text'
            onClick={() => onCloseClick()}
            className={styles.cancel}
          >
            Cancel
          </Button>

          <Button
            onClick={() => onConfirmEmailClick()}
            className={styles.button}
          >
            Confirm email
          </Button>
        </Modal.Footer>
      </Modal.Container>
    )
  );

export { Confirmation };
