import { createView } from '@shared/lib/view';

import { $$addCareRecipient } from '../model';

import { ModalSteps } from '../utils';

import { CancelAdding } from './cancel-adding';

import { CareNeeds } from './care-needs';

import { CareRecipientInfo } from './care-recipient-info';

import { ClientTypeSelection } from './client-type-selection';

import { ConditionExperience } from './condition-experience';

import { PostcodeCheck } from './postcode-check';

import { Preferences } from './preferences';

const AddCareRecipient = createView()
  .units({
    step: $$addCareRecipient.$step
  })

  .view(({ step }) => (
    <>
      {step === ModalSteps.PostcodeCheck && (
        <PostcodeCheck model={$$addCareRecipient.$$checkPostcode} />
      )}

      {step === ModalSteps.CareNeeds && <CareNeeds />}

      {step === ModalSteps.ClientTypeSelection && <ClientTypeSelection />}

      {step === ModalSteps.ConditionExperience && <ConditionExperience />}

      {step === ModalSteps.Preferences && <Preferences />}

      {step === ModalSteps.CareRecipientInfo && <CareRecipientInfo />}

      {step === ModalSteps.Cancel && <CancelAdding />}
    </>
  ));

export { AddCareRecipient };
