import {
  $$profileRequiredSupport,
  RequiredSupportForm
} from '@features/care-recipient/profile/required-support';

import { $$careRecipient } from '@entities/care-recipient';

import { createView } from '@shared/lib/view';

import { EditableSection } from '@shared/ui/atoms/editable-section';

import { useMedia } from '@shared/ui/atoms/media';

import { TextPair } from '@shared/ui/atoms/text-pair';

import { $$profilePage } from '../../model';

import styles from './required-support.module.scss';

/**
 * <RequiredSupport />
 */
const RequiredSupport = createView()
  .units({
    client: $$careRecipient.$careRecipientsProfile,

    editing: $$profilePage.requiredSupport.$opened,

    onSave: $$profileRequiredSupport.form.submit,

    onCancel: $$profilePage.requiredSupport.close,

    onEdit: $$profilePage.requiredSupport.open
  })

  .map(() => {
    const lg = useMedia('>=lg');

    return {
      lg,
      md: useMedia('>=md')
    };
  })
  .view(({ lg, md, client, editing, onSave, onCancel, onEdit }) => (
    <EditableSection
      title='Required support'
      editing={editing}
      onSave={() => onSave()}
      onCancel={() => onCancel()}
      onEdit={() => onEdit()}
    >
      {({ editing }) =>
        editing ? (
          <RequiredSupportForm />
        ) : (
          <div className={styles.fields}>
            <TextPair
              label={`Req${lg ? 'uired' : '.'} types of care support:`}
              spacing={md ? 'normal' : 'multiline'}
              value={
                client?.services?.map(item => item?.name).join(', ') || 'None'
              }
            />

            <TextPair
              label='Hours per week:'
              value={client?.activityPerWeek || 'None'}
              wrap
            />
          </div>
        )
      }
    </EditableSection>
  ));

export { RequiredSupport };
