import { createEffect } from 'effector';

import { carersHQ } from '@shared/api';

import { ToastID } from '@shared/config';

import { notify } from '@shared/ui/organisms/toasts';

const uploadFileFx = createEffect<File, string>();

const $$uploadFile = {
  uploadFileFx
};

notify({
  clock: uploadFileFx.fail,

  options: {
    id: ToastID.UploadFileError,

    type: 'error',

    title: 'Oooops!',

    content: 'Something went wrong while uploading file, try again later'
  }
});

uploadFileFx.use(async file => {
  const {
    data: { Location }
  } = await carersHQ.file.upload(file);

  return Location;
});

export { $$uploadFile };
