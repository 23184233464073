import { createEffect, createStore } from 'effector';

import type { Carer } from '@shared/api';

import { carersHQ } from '@shared/api';

const getCarerPublicProfileFx = createEffect<Carer['id'], Carer>();

const saveCarerFx = createEffect<Carer, Carer>();

const saveCarerMediaFx = createEffect<
  { image?: File; video?: File },
  { imageUrl?: string; videoUrl?: string }
>();

const $carerProfile = createStore<Carer | null>(null);

const $$carerProfile = {
  $carerProfile,

  getCarerPublicProfileFx,

  saveCarerFx,

  saveCarerMediaFx
};

$carerProfile.on(
  [getCarerPublicProfileFx.doneData, saveCarerFx.doneData],
  (_, payload) => payload
);

// sample({
//   clock: saveCarerMediaFx.doneData,
//
//   source: $carerProfile,
//
//   fn: (carer, { imageUrl, videoUrl }) => ({
//     ...carer,
//     imageUrl,
//     videoUrl
//   }),
//
//   target: saveCarerFx
// });

getCarerPublicProfileFx.use(async id => {
  const { data } = await carersHQ.carer.get({ id });

  return data;
});

saveCarerFx.use(async carer => {
  const response = await carersHQ.carer.update(carer);

  response.data.supportTypes
    .sort((a, b) => (a.supportType.id > b.supportType.id ? 1 : -1))

    .forEach(supportType => {
      supportType.rates.sort((a, b) => (a.rateType > b.rateType ? 1 : -1));
    });

  return response.data;
});

saveCarerMediaFx.use(async ({ image, video }) => {
  if (video && image) {
    const [
      {
        data: { Location: imageUrl }
      },
      {
        data: { Location: videoUrl }
      }
    ] = await Promise.all([image, video].map(carersHQ.file.upload));

    return {
      imageUrl,
      videoUrl
    };
  }

  if (image) {
    const {
      data: { Location: imageUrl }
    } = await carersHQ.file.upload(image);

    return {
      imageUrl
    };
  }

  if (video) {
    const {
      data: { Location: videoUrl }
    } = await carersHQ.file.upload(video);

    return {
      videoUrl
    };
  }
});

export { $$carerProfile };
