import { createStore } from 'effector';

import { modelFactory } from 'effector-factorio';

import { array, boolean, object } from 'yup';

import type { Gender, Language, Pet, TransportationHelp } from '@shared/api';

import { createForm } from '@shared/lib/form';

type PreferencesValues = {
  pets: Pet['id'][];
  smokingOk: boolean;
  languages: Language[];
  carerGender: Gender['id'];
  transportationHelp: TransportationHelp['id'];
};

const ManagePreferencesModelFactory = modelFactory(() => {
  const form = createForm<PreferencesValues>({
    initialValues: {
      languages: [
        {
          id: 1,
          name: 'English'
        }
      ],

      pets: [],

      transportationHelp: null,

      smokingOk: false,

      carerGender: null
    },

    $schema: createStore(
      object({
        languages: array(object().nullable()).nullable(),

        transportationHelp: object().nullable().label('Transportation help'),

        smokingOk: boolean().nullable().label('Smoking'),

        petsOk: boolean().nullable().label('Pets'),

        pets: array().nullable().label('Pets'),

        gender: object().nullable().label('Gender')
      })
    )
  });

  return {
    form
  };
});

export type { PreferencesValues };

export { ManagePreferencesModelFactory };
