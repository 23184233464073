import { ReactComponent as CloseIcon } from '@icon/close.svg';

import { ReactComponent as EyeCrossedIcon } from '@icon/eye-crossed.svg';

import { ReactComponent as EyeIcon } from '@icon/eye.svg';

import classNames from 'classnames';

import type { ChangeEvent, FC } from 'react';

import { useState } from 'react';

import { Label } from '@shared/ui/atoms/label';

import styles from './input.module.scss';

import type { InputIconsProps, InputProps } from './input.props';

const Input: FC<InputProps> = ({
  type,
  value,
  mode = 'input',
  tip,
  clearable = true,
  focus,
  autoFocus,
  onChange,
  labelGap,
  inputRef,
  wrapperClassName,
  onFocus,
  onBlur,
  label,
  disabled,
  error,
  hasError,
  helper,
  helperText,
  bold,
  // disableAutocomplete,
  prepend,
  append,
  placeholder,
  nowrap,
  subTitle,
  className
}) => {
  const [revealPassword, setRevealPassword] = useState(false);

  const [focused, setFocused] = useState(focus ?? false);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const onInputFocus = () => {
    setFocused(true);

    onFocus?.();
  };

  const onInputBlur = () => {
    setFocused(false);

    onBlur?.();
  };

  const onClearIconClick = () => {
    if (document.activeElement) {
      (document.activeElement as HTMLInputElement).blur();
    }

    onChange('');
  };

  return (
    <div className={classNames(styles.wrapper, wrapperClassName)}>
      {(label || helper) && (
        <Label
          label={label}
          labelGap={labelGap}
          error={error}
          isError={hasError && !helperText}
          disabled={disabled}
          tip={tip}
          nowrap={nowrap}
          bold={bold}
          helper={helper}
        />
      )}

      {subTitle && <div className={styles.subTitle}>{subTitle}</div>}

      <div
        className={classNames(styles.container, className, {
          [styles.error]: hasError,
          [styles.viewContainer]: mode === 'view',
          [styles.disabled]: disabled,
          [styles.withoutPadding]: focused,
          [styles.dots]: !revealPassword && value && type === 'password'
        })}
      >
        {prepend && <div className={styles.leftIcon}>{prepend}</div>}

        {mode === 'input' ? (
          <input
            ref={inputRef}
            placeholder={placeholder as string}
            type={revealPassword ? 'text' : type}
            value={value ?? ''}
            autoComplete='new-password'
            autoFocus={autoFocus}
            disabled={disabled}
            onChange={onInputChange}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
          />
        ) : (
          <div
            className={styles.view}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            tabIndex={-1}
            ref={inputRef}
          >
            {value ?? placeholder}
          </div>
        )}

        <InputIcon
          type={type!}
          value={value}
          mode={mode}
          focused={focused}
          clearable={clearable}
          onClearIconClick={onClearIconClick}
          setRevealPassword={setRevealPassword}
          revealPassword={revealPassword}
          append={append}
          disabled={disabled!}
        />
      </div>

      {helperText && (
        <div className={classNames(styles.helperText)}>{helperText}</div>
      )}
    </div>
  );
};

const InputIcon: FC<InputIconsProps> = ({
  type,
  value,
  focused,
  clearable,
  mode,
  onClearIconClick,
  revealPassword,
  disabled,
  append,
  setRevealPassword
}) => (
  <div
    className={classNames(styles.rightIcons, {
      [styles.withoutPadding]: clearable || append || type === 'password'
    })}
  >
    {value && focused && clearable && mode !== 'view' && (
      <div
        onMouseDown={event => {
          event.preventDefault();

          onClearIconClick();
        }}
        className={styles.clearBlock}
      >
        <CloseIcon className={styles.close} />
      </div>
    )}

    {type === 'password' && (
      <div
        className={classNames(styles.passwordIcons, {
          [styles.divider]: value && focused
        })}
      >
        <div className={classNames(styles.indicator)}>
          {revealPassword ? (
            <EyeIcon
              onClick={() => !disabled && setRevealPassword(false)}
              className={styles.eye}
            />
          ) : (
            <EyeCrossedIcon
              onClick={() => !disabled && setRevealPassword(true)}
              className={styles.eye}
            />
          )}
        </div>
      </div>
    )}

    {append && <div>{append}</div>}
  </div>
);

Input.defaultProps = {
  placeholder: 'Enter'
};

export { Input };
