import { modelView } from 'effector-factorio';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { Fieldset } from '@shared/ui/atoms/fieldset';

import { useMedia } from '@shared/ui/atoms/media';

import { Field, useField } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { CreateAccountModelFactory } from '../../model';

import styles from './create-account-form.module.scss';

const CreateAccountFormFooter = modelView(
  CreateAccountModelFactory,
  createView()
    .map(() => {
      const { form } = CreateAccountModelFactory.useModel();

      return {
        form
      };
    })

    .view(({ form }) => (
      <div className={styles.checks}>
        <h4 className={styles.checksTitle}>By signing up, you:</h4>

        <Field.Checkbox
          className={styles.checkbox}
          use={form.fields.terms}
          label={
            <span>
              Agree to our{' '}
              <a
                className={styles.link}
                target='_blank'
                href='https://google.com'
                rel='noreferrer'
              >
                <Button palette='text' variant='text'>
                  Terms of Service
                </Button>
              </a>
            </span>
          }
        />

        <Field.Checkbox
          className={styles.checkbox}
          use={form.fields.policy}
          label={
            <span>
              Consent to our use of your personal data as described in our{' '}
              <a
                className={styles.link}
                target='_blank'
                href='https://google.com'
                rel='noreferrer'
              >
                <Button palette='text' variant='text'>
                  Privacy Policy
                </Button>
              </a>
            </span>
          }
        />

        <p className={styles.checksDescription}>
          You also consent to us contacting you by email to inform you of our
          services and other information we think will be of interest to you.
          You can unsubscribe at any time.
        </p>
      </div>
    ))
);

/**
 * <CreateAccount />
 */
const CreateAccountForm = modelView(
  CreateAccountModelFactory,
  createView()
    .map(() => {
      const { form } = CreateAccountModelFactory.useModel();

      const {
        shouldShowValidation: emailValidation,
        hasError: hasEmailErrors,
        error: emailError
      } = useField(form.fields.email);

      const {
        shouldShowValidation: passwordValidation,
        hasError: hasPasswordErrors,
        error: passwordErrors
      } = useField(form.fields.password);

      const mobile = useMedia('<sm');

      const emailInValid = emailError && hasEmailErrors && emailValidation;

      const passwordInValid =
        passwordErrors && hasPasswordErrors && passwordValidation;

      return {
        form,
        emailInValid,
        passwordInValid,
        mobile,
        passwordErrors
      };
    })
    .view(({ form, emailInValid, passwordInValid, mobile }) => (
      <Form use={form}>
        <Fieldset className={styles.fields}>
          <Field.FormSection
            title='Email'
            use={form.fields.email}
            tip={!mobile && 'You will use this address to log in'}
          >
            <Field.Input
              use={form.fields.email}
              labelGap
              subTitle={
                mobile && !emailInValid && 'You will use this address to log in'
              }
              errorIn='label'
              bold
            />
          </Field.FormSection>

          <Field.FormSection
            title='Password'
            use={form.fields.password}
            tip={
              !mobile &&
              'Least 6 digits long and contain numbers and upper case'
            }
            classNames={{ header: styles.header }}
          >
            <Field.Input
              use={form.fields.password}
              labelGap
              type='password'
              errorIn='label'
              bold
              subTitle={
                mobile &&
                !passwordInValid &&
                'Least 6 digits long and contain numbers and upper case'
              }
            />
          </Field.FormSection>

          <Field.Input
            use={form.fields.confirmPassword}
            labelGap
            wrapperClassName={styles.input}
            errorIn='label'
            type='password'
            bold
            nowrap
            label='Confirm password'
          />
        </Fieldset>
      </Form>
    ))
);

export { CreateAccountForm, CreateAccountFormFooter };
