enum SignUpType {
  Carer = 'carer',
  Myself = 'myself',
  Loved = 'loved'
}

import { createForm } from '@shared/lib/form';

const form = createForm({
  initialValues: {
    clientType: SignUpType.Loved
  }
});

const $$clientTypeSelection = {
  form
};

export { $$clientTypeSelection, SignUpType };
