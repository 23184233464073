import type React from 'react';

import { useEffect, useCallback } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useClickOutside = (
  onClick: () => void,
  ref: React.MutableRefObject<HTMLElement>,
  deps: any[] = []
) => {
  const onMouseDown = useCallback((event: MouseEvent) => {
    if (!ref.current) return;

    if (ref.current?.contains(event.target as Node)) return;

    // Crutch to enable inputs's clear in modals
    if ((event.target as HTMLElement).tagName === 'svg') return;

    if ((event.target as HTMLElement).tagName === 'path') return;

    if ((event.target as HTMLElement).id === 'suggestionText') return;

    onClick();
  }, deps);

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown);

    return () => {
      document.removeEventListener('mousedown', onMouseDown);
    };
  }, deps);
};

export { useClickOutside };
