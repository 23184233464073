import type { FC, HTMLAttributes } from 'react';

const CalendarIcon: FC<
  HTMLAttributes<SVGElement> & {
    color: string;
  }
> = ({ color, ...props }) => (
  <svg viewBox='0 0 18 18' version='1.1' {...props}>
    <g
      id='Sign-Up-Client-+'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        id='Client-SignUp-3_2'
        transform='translate(-865.000000, -596.000000)'
        fill={color}
        fillRule='nonzero'
      >
        <g id='field' transform='translate(640.000000, 542.000000)'>
          <path
            d='M240.3,55.8 L238.5,55.8 L238.5,54.9 C238.5,54.4029437 238.097056,54 237.6,54 C237.102944,54 236.7,54.4029437 236.7,54.9 L236.7,55.8 L231.3,55.8 L231.3,54.9 C231.3,54.4029437 230.897056,54 230.4,54 C229.902944,54 229.5,54.4029437 229.5,54.9 L229.5,55.8 L227.7,55.8 C226.208831,55.8 225,57.0088312 225,58.5 L225,69.3 C225,70.7911688 226.208831,72 227.7,72 L240.3,72 C241.791169,72 243,70.7911688 243,69.3 L243,58.5 C243,57.0088312 241.791169,55.8 240.3,55.8 Z M241.2,69.3 C241.2,69.7970563 240.797056,70.2 240.3,70.2 L227.7,70.2 C227.202944,70.2 226.8,69.7970563 226.8,69.3 L226.8,63 L241.2,63 L241.2,69.3 Z M241.2,61.2 L226.8,61.2 L226.8,58.5 C226.8,58.0029437 227.202944,57.6 227.7,57.6 L229.5,57.6 L229.5,58.5 C229.5,58.9970563 229.902944,59.4 230.4,59.4 C230.897056,59.4 231.3,58.9970563 231.3,58.5 L231.3,57.6 L236.7,57.6 L236.7,58.5 C236.7,58.9970563 237.102944,59.4 237.6,59.4 C238.097056,59.4 238.5,58.9970563 238.5,58.5 L238.5,57.6 L240.3,57.6 C240.797056,57.6 241.2,58.0029437 241.2,58.5 L241.2,61.2 Z'
            id='icn_calendar'
          />
        </g>
      </g>
    </g>
  </svg>
);

export { CalendarIcon };
