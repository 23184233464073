import type { FC } from 'react';

import { useMemo, useRef, useState } from 'react';

import type { Entry } from '@shared/api';

import { Input } from '@shared/ui/atoms/input';

import styles from './suggestion-input.module.scss';

import type { SuggestionInputProps } from './suggestion-input.props';

const SuggestionInput: FC<SuggestionInputProps> = ({
  suggestions,
  onSuggestionSelect,
  suggestionsPlaceholder,
  value,
  onFocus,
  onBlur,
  tip,
  onChange,
  labelGap,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(value);

  const [focused, setFocused] = useState(false);

  const inputRef = useRef<HTMLInputElement>();

  const onInputFocus = () => {
    onFocus?.();

    setFocused(true);
  };

  const onInputBlur = () => {
    onBlur?.();

    setFocused(false);
  };

  const onInputChange = (value: string) => {
    setInputValue(value);

    onChange(value);
  };

  const onSuggestionSelected = (item: Entry) => (event: any) => {
    event.preventDefault();

    setInputValue(item.name);

    onSuggestionSelect?.(item);

    inputRef.current?.blur();
  };

  const filteredSuggestions = useMemo(() => {
    if (!suggestions) return;

    if (!inputValue) return suggestions;

    return suggestions.filter((item: Entry) =>
      item?.name?.toLowerCase().includes(inputValue.toLowerCase())
    );
  }, [inputValue, suggestions]);

  return (
    <div className={styles.box}>
      <Input
        {...props}
        tip={tip}
        type='text'
        labelGap={labelGap}
        value={inputValue}
        focus={focused}
        inputRef={inputRef}
        onChange={onInputChange}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        clearable
      />

      {suggestions.length > 0 && focused && (
        <div className={styles.suggestions}>
          {filteredSuggestions.length > 0 ? (
            <ul>
              {filteredSuggestions?.map((item: Entry, index) => (
                <li
                  className={styles.suggestion}
                  key={index}
                  onMouseDown={onSuggestionSelected(item)}
                >
                  <div className={styles.suggestionText} id='suggestionText'>
                    {item.name}
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className={styles.suggestionsPlaceholder}>
              {suggestionsPlaceholder}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export { SuggestionInput };
