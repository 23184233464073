import { createEffect, sample, createStore } from 'effector';

import { $$user } from '@entities/user';

import type { SettingsAccountContract, Credentials } from '@shared/api';

import type { PersonalDetailsValues, Response } from '@shared/api';

import { carersHQ } from '@shared/api';

import { ToastID } from '@shared/config';

import { notify } from '@shared/ui/organisms/toasts';

const updatePersonalDetailsFx = createEffect<
  PersonalDetailsValues,
  void,
  Response
>();

const getAccountFx = createEffect<void, SettingsAccountContract>();

const deleteAccountFx = createEffect<
  Credentials & {
    reason: string;
  },
  void
>();

const $account = createStore<SettingsAccountContract>(null!);

const $$accountInfo = {
  $account,

  getAccountFx,

  updatePersonalDetailsFx,

  deleteAccountFx
};

sample({
  clock: updatePersonalDetailsFx.done,

  target: [$$user.getAccountFx, getAccountFx]
});

$account
  .on(
    getAccountFx.doneData,

    (_, payload) => payload
  )
  .on(updatePersonalDetailsFx, (state, payload) => {
    if (payload.email) {
      return {
        ...state,
        payload: payload.email
      };
    }

    return state;
  });

getAccountFx.use(async () => {
  const { data } = await carersHQ.settings.getAccount();

  return data;
});

updatePersonalDetailsFx.use(async values => {
  const data = { ...values };

  if (!data.email) {
    delete data.email;

    delete data.password;
  }

  await carersHQ.settings.updatePersonalDetails(data);
});

deleteAccountFx.use(async data => {
  await carersHQ.settings.deleteAccount(data);
});

notify({
  clock: deleteAccountFx.failData,

  options: {
    id: ToastID.AccountDeletionError,
    type: 'error',
    title: 'Something went wrong',
    content: 'Try again later'
  }
});

export { $$accountInfo };
