import { combine, createEvent, createStore, sample } from 'effector';

import { modelFactory } from 'effector-factorio';

import { StepWizardModelFactory } from '@pages/sign-up/ui/sign-up-wizard-step';

import { CreateAccountModelFactory } from '@features/auth/sign-up';

import { ManageCareNeedsModelFactory } from '@features/care-recipient/manage-care-needs';

import { ManageConditionExperienceModelFactory } from '@features/care-recipient/manage-condition-experience';

import { ManagePreferencesModelFactory } from '@features/care-recipient/manage-preferences';

import { ManageContactDetailsModelFactory } from '@features/user/manage-contact-details';

import { ManagePersonalInformationModelFactory } from '@features/user/manage-personal-information';

import { routes } from '@shared/routes';

const SignUpMyselfModelFactory = modelFactory(() => {
  const createAccountBackClicked = createEvent();

  const $$createAccount = CreateAccountModelFactory.createModel();

  const $$createAccountWizard = StepWizardModelFactory.createModel({
    $total: createStore(0),

    $current: createStore(0),

    backClicked: createAccountBackClicked
  });

  const $total = createStore(5);

  const $current = combine(
    routes.signUp.myself.careNeeds.$isOpened,

    routes.signUp.myself.conditionExperience.$isOpened,

    routes.signUp.myself.preferences.$isOpened,

    routes.signUp.myself.personalInformation.$isOpened,

    routes.signUp.myself.contactDetails.$isOpened,

    (...opened) => opened.indexOf(true)
  );

  const steps = {
    personalInformation: ManagePersonalInformationModelFactory.createModel(),

    contactDetails: ManageContactDetailsModelFactory.createModel(),

    preferences: ManagePreferencesModelFactory.createModel(),

    conditionExperience: ManageConditionExperienceModelFactory.createModel(),

    careNeeds: ManageCareNeedsModelFactory.createModel()
  };

  const wizards = {
    personalInformation: StepWizardModelFactory.createModel({
      $total,
      $current,
      nextClicked: steps.personalInformation.form.submit
    }),

    contactDetails: StepWizardModelFactory.createModel({
      $total,
      $current,
      nextClicked: steps.contactDetails.form.submit
    }),

    preferences: StepWizardModelFactory.createModel({
      $total,
      $current,
      nextClicked: steps.preferences.form.submit
    }),

    conditionExperience: StepWizardModelFactory.createModel({
      $total,
      $current,
      nextClicked: steps.conditionExperience.form.submit
    }),

    careNeeds: StepWizardModelFactory.createModel({
      $total,
      $current,
      nextClicked: steps.careNeeds.form.submit
    })
  };

  sample({
    clock: $$createAccountWizard.nextClicked,

    target: $$createAccount.form.submit
  });

  sample({
    clock: $$createAccount.form.submitted,

    target: $$createAccount.form.reset
  });

  return {
    $$createAccount,
    $$createAccountWizard,
    $total,
    $current,
    steps,
    wizards
  };
});

const $$signUpMyself = SignUpMyselfModelFactory.createModel();

export { $$signUpMyself, SignUpMyselfModelFactory };
