import styles from './toast-text.module.scss';

const ToastText = () => (
  <p className={styles.toastText}>
    We sent a confirmation link to your new email. Please confirm your new email
    within 48 hours.
  </p>
);

export { ToastText };
