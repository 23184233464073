import { createStitches } from '@stitches/react';

const { styled, css, theme } = createStitches({
  theme: {
    colors: {
      yellowHovered: '#FFD266',
      yellowPrimary: '#FDDF98',
      yellow10: '#FFEFCA',
      yellow5: '#FFFAF0',
      peach: '#E5936B',
      peach30: '#FEE5C7',
      peach10: '#FEF0E1',
      peach5: '#FEF8F1',
      blue: '#154C95',
      blue30: '#C8D4F3',
      blue10: '#EEF2FB',
      blue5: '#F5F7FD',
      green: '#15955E',
      green30: '#B8DFCE',
      green10: '#EBF9F0',
      green5: '#F3F9F7',
      red: '#E90003',
      red30: '#FCC1C2',
      red10: '#FEE6E7',
      red5: '#FFF5F5',
      textBlack: '#0E1B44',
      textGrey: '#667992',
      textDisabled: '#BCC3CE',
      disabled: '#F6F6F7',
      divider: '#E5E6EA'
    },

    space: {
      1: '8px',
      2: '16px',
      3: '24px',
      4: '32px'
    },

    fontSizes: {
      'body-1': '15px'
    },

    lineHeights: {
      'body-1': '18px'
    }
  },

  media: {
    xs: '(min-width: 375px)',

    sm: '(min-width: 480px)',

    md: '(min-width: 768px)',

    lg: '(min-width: 1172px)',

    xl: '(min-width: 1440px)'
  },

  utils: {
    mt: (payload: string) => ({
      marginTop: payload
    }),

    mb: (payload: string) => ({
      marginBottom: payload
    }),

    ml: (payload: string) => ({
      marginLeft: payload
    }),

    mr: (payload: string) => ({
      marginRight: payload
    }),

    pt: (payload: string) => ({
      paddingTop: payload
    }),

    pb: (payload: string) => ({
      paddingBottom: payload
    }),

    pl: (payload: string) => ({
      paddingLeft: payload
    }),

    pr: (payload: string) => ({
      paddingRight: payload
    }),

    px: (value: string) => ({
      paddingLeft: value,
      paddingRight: value
    }),

    py: (value: string) => ({
      paddingTop: value,
      paddingBottom: value
    }),

    mx: (value: string) => ({
      marginLeft: value,
      marginRight: value
    }),

    my: (value: string) => ({
      marginTop: value,
      marginBottom: value
    }),

    size: (value: string) => ({
      width: value,
      height: value
    }),

    row: () => ({
      display: 'flex',
      alignItems: 'center'
    }),

    flexCol: () => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch'
    }),

    flexCenter: () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }),

    truncate: (_: boolean) => ({
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    })
  }
});

export { styled, theme, css };
