import type { RouteInstance } from 'atomic-router';

import { chainRoute, createRoute } from 'atomic-router';

import type { Store } from 'effector';

import { attach, sample } from 'effector';

import type { FormModel } from '@shared/lib/form';

import type { SignUpStepParams } from '@shared/routes/routes';

type StepFactoryOptions<V extends Record<string, any>> = {
  $values: Store<V>;

  fallbackRoute: RouteInstance<any>;
};

type FactoryOptions<
  V extends Record<string, any>,
  SV extends Record<string, any>
> = {
  form: FormModel<any>;

  route: RouteInstance<any>;

  nextRoute: RouteInstance<any>;

  key: keyof V;

  checkValues: (value: V) => boolean;

  processValues: (value: SV) => SV;

  mapValues: (values: V, params: SignUpStepParams) => SV;
};

const createSignUpStepModelFactory =
  <V>({ fallbackRoute, $values }: StepFactoryOptions<V>) =>
  <SV>({
    key,
    form,
    route,
    nextRoute,
    mapValues,
    checkValues,
    processValues
  }: FactoryOptions<V, SV>) => {
    const chainedRoute = createRoute<SignUpStepParams>();

    const { submitted } = form;

    const beforeOpenFx = attach({
      source: $values,

      effect: (values, params: SignUpStepParams) => {
        if (!checkValues(values)) throw 'Not Allowed';

        return mapValues(values, params);
      }
    });

    $values.on(submitted, (state, values) => ({
      ...state,

      [key]: processValues(values)
    }));

    chainRoute({
      route,

      beforeOpen: {
        effect: beforeOpenFx,

        mapParams: ({ params }) => params
      },

      cancelOn: beforeOpenFx.fail,

      openOn: beforeOpenFx.done,

      chainedRoute
    });

    sample({
      clock: beforeOpenFx.doneData,

      fn: values => values ?? null,

      target: form.reset
    });

    sample({
      clock: beforeOpenFx.failData,

      source: route.$params,

      target: fallbackRoute.open
    });

    sample({
      clock: submitted,

      source: route.$params,

      target: nextRoute.open
    });
  };

export { createSignUpStepModelFactory };
