import { combine, createEvent, createStore, sample } from 'effector';

import { modelFactory } from 'effector-factorio';

import { StepWizardModelFactory } from '@pages/sign-up/ui/sign-up-wizard-step';

import { CreateAccountModelFactory } from '@features/auth/sign-up';

import { ManageAdditionalInformation } from '@features/carer/manage-additional-information';

import { ManageEmploymentDetailsModelFactory } from '@features/carer/manage-employment-details';

import { ManageQualificationsModelFactory } from '@features/carer/manage-qualifications';

import { ManageServicesModelFactory } from '@features/carer/manage-services';

import { ManageContactDetailsModelFactory } from '@features/user/manage-contact-details';

import { ManagePersonalInformationModelFactory } from '@features/user/manage-personal-information';

import { $$session } from '@entities/session';

import { routes } from '@shared/routes';

const SignUpCarerModelFactory = modelFactory(() => {
  const createAccountBackClicked = createEvent();

  const $$createAccount = CreateAccountModelFactory.createModel();

  const $$createAccountWizard = StepWizardModelFactory.createModel({
    $total: createStore(0),

    $current: createStore(0),

    backClicked: createAccountBackClicked
  });

  const confirmSkillsClicked = createEvent();

  const redirectClicked = createEvent();

  const $total = createStore(6);

  const $current = combine(
    routes.signUp.carer.personalInformation.$isOpened,

    routes.signUp.carer.contactDetails.$isOpened,

    routes.signUp.carer.services.$isOpened,

    routes.signUp.carer.qualifications.$isOpened,

    routes.signUp.carer.additionalInformation.$isOpened,

    routes.signUp.carer.employmentDetails.$isOpened,

    (...opened) => opened.indexOf(true)
  );

  const steps = {
    personalInformation: ManagePersonalInformationModelFactory.createModel(),

    contactDetails: ManageContactDetailsModelFactory.createModel(),

    services: ManageServicesModelFactory.createModel(),

    qualifications: ManageQualificationsModelFactory.createModel(),

    additionalInformation: ManageAdditionalInformation.createModel(),

    employmentDetails: ManageEmploymentDetailsModelFactory.createModel()
  };

  const wizards = {
    personalInformation: StepWizardModelFactory.createModel({
      $total,

      $current,

      backClicked: redirectClicked,

      nextClicked: steps.personalInformation.form.submit
    }),

    contactDetails: StepWizardModelFactory.createModel({
      $total,

      $current,

      nextClicked: steps.contactDetails.form.submit
    }),

    services: StepWizardModelFactory.createModel({
      $total,

      $current,

      nextClicked: steps.services.form.submit
    }),

    qualifications: StepWizardModelFactory.createModel({
      $total,

      $current,

      nextClicked: steps.qualifications.form.submit
    }),

    additionalInformation: StepWizardModelFactory.createModel({
      $total,

      $current,

      nextClicked: steps.additionalInformation.form.submit
    }),

    employmentDetails: StepWizardModelFactory.createModel({
      $total,

      $current,

      nextClicked: steps.employmentDetails.form.submit
    })
  };

  sample({
    clock: confirmSkillsClicked,

    source: routes.signUp.carer.skills.$params,

    target: routes.signUp.carer.personalInformation.open
  });

  sample({
    clock: $$createAccountWizard.nextClicked,

    target: $$createAccount.form.submit
  });

  sample({
    clock: redirectClicked,

    source: $$session.$active,

    filter: session => session,

    target: routes.schedule.open
  });

  sample({
    clock: redirectClicked,

    source: $$session.$active,

    filter: session => !session,

    target: routes.signUp.carer.postcode.open
  });

  sample({
    clock: $$createAccount.form.submitted,

    target: $$createAccount.form.reset
  });

  return {
    $$createAccount,
    $$createAccountWizard,
    $total,
    $current,
    steps,
    wizards,
    confirmSkillsClicked
  };
});

const $$signUpCarer = SignUpCarerModelFactory.createModel();

export { $$signUpCarer, SignUpCarerModelFactory };
