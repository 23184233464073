import { $$members } from '@entities/members';

import { createView } from '@shared/lib/view';

import { Checkbox } from '@shared/ui/atoms/checkbox';

import styles from './notification-access.module.scss';

import type { NotificationAccessProps } from './notification-access.props';

const NotificationAccess = createView<NotificationAccessProps>()
  .units({
    isReady: $$members.$updateNotificationsReady
  })

  .view(({ title, email, sms, disabled, isReady, onClick }) => (
    <div className={styles.container}>
      <span>{title}</span>

      <div className={styles.checkboxes}>
        <Checkbox
          className={styles.checkbox}
          disabled={disabled}
          value={email}
          onChange={() => isReady && !disabled && onClick('emailNotifications')}
        />

        <Checkbox
          className={styles.checkbox}
          disabled={disabled}
          value={sms}
          onChange={() => isReady && !disabled && onClick('smsNotifications')}
        />
      </div>
    </div>
  ));

export { NotificationAccess };
