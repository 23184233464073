import { redirect } from 'atomic-router';

import { combine, createEvent, createStore, sample } from 'effector';

import { condition, splitMap } from 'patronum';

import { $$createWorkspace } from '@features/account/create-workspace/model';

import { $$selectCareRecipient } from '@features/care-recipient/select';

import { $$selectCareSpace } from '@features/care-space/select';

import { $$logoutUser } from '@features/user/logout';

import { $$careRecipient } from '@entities/care-recipient';

import { $$careSpace } from '@entities/care-space';

import { $$session, RoleType } from '@entities/session';

import { $$user } from '@entities/user';

import { atom } from '@shared/lib/factory';

import { molecule } from '@shared/lib/factory/atom';

import { pathChanged } from '@shared/lib/router';

import { routes } from '@shared/routes';

const $$dropdown = atom('Dropdown', () => {
  const $careSpacesOpened = createStore(false);

  const $opened = createStore(false);

  const outsideClicked = createEvent();

  const hamburgerClicked = createEvent();

  const avatarClicked = createEvent();

  const logoutClicked = createEvent();

  const accountSwitchClicked = createEvent();

  const addCareRecipientsClicked = createEvent();

  const createCareSpaceClicked = createEvent();

  const addRoleConfirmed = createEvent();

  const confirmAddRoleClosed = createEvent();

  const $shouldAddRole = $$user.$account.map(
    state => !state?.carer || state.accountWorkspaces?.length == 0
  );

  const $shouldConfirmAddRole = createStore(false);

  const $careRecipientsVisible = combine(
    $$session.$role,
    $$careRecipient.$hasCareRecipients,

    (role, hasCareRecipients) =>
      role == RoleType.CareRecipient && hasCareRecipients
  );

  const $careSpaceVisible = combine(
    $$session.$role,
    $$careSpace.$activeAccountCareSpaces,

    (role, careSpaces) =>
      role == RoleType.CareRecipient && careSpaces?.length > 0
  );

  const reset = sample({
    clock: [
      $$selectCareSpace.careSpaceClicked,
      $$selectCareRecipient.careRecipientClicked,

      pathChanged,

      outsideClicked,
      accountSwitchClicked
    ]
  });

  const roleAddOpened = createEvent();

  const roleSwitched = createEvent();

  condition({
    source: accountSwitchClicked,

    if: $shouldAddRole,

    then: roleAddOpened,

    else: roleSwitched
  });

  sample({
    clock: createCareSpaceClicked,

    target: $$createWorkspace.workspaceVisibilityOpened
  });

  // sample({
  //   clock: addCareRecipientsClicked,

  //   target: $$addCareRecipient.openModal
  // });

  // sample({
  //   clock: addCareRecipientsClicked,
  //
  //   source: $$careRecipient.$careRecipientID,
  //
  //   filter: careRecipientID => Boolean(careRecipientID),
  //
  //   fn: careRecipientID => ({ id: careRecipientID }),
  //
  //   target: routes.careRecipients.general.open
  // });
  //
  // sample({
  //   clock: addCareRecipientsClicked,
  //
  //   source: $$careRecipient.$careRecipientID,
  //
  //   filter: careRecipientID => !Boolean(careRecipientID),
  //
  //   fn: () => ({} as { id: string[] }),
  //
  //   target: routes.careRecipients.base.open
  // });

  const $signUpParams = $$user.$account.map(account => ({
    postcode: encodeURIComponent(
      account?.myself?.postCode ?? account?.carer?.postCode
    )
  }));

  const addRoleConfirmedFor = splitMap({
    source: sample({
      clock: addRoleConfirmed,

      source: {
        role: $$session.$role,

        account: $$user.$account
      }
    }),

    cases: {
      carer: ({ role }) => {
        if (role == RoleType.CareRecipient) return role;
      },

      type: ({ role, account }) => {
        if (role == RoleType.Carer && !account.myself) return true;
      },

      loved: ({ role, account }) => {
        if (role == RoleType.Carer && account.myself) return role;
      }
    }
  });

  redirect({
    clock: addRoleConfirmedFor.carer,

    params: $signUpParams,

    route: routes.signUp.carer.personalInformation
  });

  redirect({
    clock: addRoleConfirmedFor.loved,

    params: $signUpParams,

    route: routes.signUp.lovedOne.careNeeds
  });

  redirect({
    clock: addRoleConfirmedFor.type,

    query: $signUpParams,

    route: routes.signUp.type
  });

  $opened
    .on(avatarClicked, () => true)

    .on(hamburgerClicked, state => !state)

    .reset(reset);

  $careSpacesOpened.reset(reset);

  $shouldConfirmAddRole
    .on(roleAddOpened, () => true)

    .reset(confirmAddRoleClosed, addRoleConfirmed);

  return {
    $opened,
    $signUpParams,
    $careSpaceVisible,
    $careSpacesOpened,
    $shouldConfirmAddRole,
    $careRecipientsVisible,
    roleSwitched,
    avatarClicked,
    logoutClicked,
    outsideClicked,
    hamburgerClicked,
    addRoleConfirmed,
    accountSwitchClicked,
    confirmAddRoleClosed,
    createCareSpaceClicked,
    addCareRecipientsClicked
  };
});

molecule('Logout', () => {
  sample({
    clock: $$dropdown.logoutClicked,

    target: $$logoutUser.logout
  });
});

export { $$dropdown };
