import type { Event } from 'effector';

import { sample } from 'effector';

import { $$signUpCarer } from '@pages/sign-up/pages/carer';

import { $$addUserRole } from '@features/user/add-role';

import { $$session } from '@entities/session';

import type { SignUpCarerParams } from '@shared/api';

import { carerValuesToDto, $carerValues } from './shared';

const submitted = sample({
  clock: $$signUpCarer.steps.employmentDetails.form.submitted,

  source: {
    values: $carerValues
  },

  filter: $$session.$active,

  fn: ({ values }, employmentDetails) =>
    carerValuesToDto({
      ...values,

      employmentDetails
    })
});

sample({
  clock: submitted as any as Event<SignUpCarerParams>,

  target: $$addUserRole.addCarerRoleFx
});
