import { createEffect, createEvent, createStore, sample } from 'effector';

import { modelFactory } from 'effector-factorio';

import { object, string } from 'yup';

import type { SignUpForNewsLetterDto } from '@shared/api';

import { carersHQ } from '@shared/api';

import { createForm } from '@shared/lib/form';

import { nameRegEx } from '@shared/lib/utils';

const SignUpForNewsLetterModelFactory = modelFactory(() => {
  const reset = createEvent();

  const signUpForNewsletterFx = createEffect<SignUpForNewsLetterDto, void>();

  const $error = createStore(false);

  const $subscribed = createStore(false);

  const form = createForm({
    initialValues: {
      name: '',

      email: '',

      postCode: ''
    },

    $schema: createStore(
      object({
        name: string()
          .nullable()

          .required()

          .trim('Required')

          .label('First name')

          .matches(nameRegEx, 'Invalid'),

        email: string()
          .required()

          .nullable()

          .email()

          .label('Name')

          .trim('Required')
      })
    )
  });

  sample({
    clock: form.submitted,

    target: signUpForNewsletterFx
  });

  $error
    .on(signUpForNewsletterFx.failData, () => true)

    .reset(reset, signUpForNewsletterFx.doneData);

  $subscribed.on(signUpForNewsletterFx.doneData, () => true).reset(reset);

  signUpForNewsletterFx.use(async payload => {
    await carersHQ.auth.signUpForNewsLetter(payload);
  });

  return {
    form,

    reset,

    $error,
    $subscribed,
    signUpForNewsletterFx
  };
});

export { SignUpForNewsLetterModelFactory };
