import classNames from 'classnames';

import { $$emailConfirmation } from '@widgets/email-confirmation/model';

import { $$user } from '@entities/user';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { Modal } from '@shared/ui/atoms/modal';

import styles from './changed-email.module.scss';

const ChangedEmail = createView()
  .units({
    account: $$user.$account,

    onSubmit: $$emailConfirmation.form.submit,

    onCloseClick: $$emailConfirmation.closeClicked,

    onChangeEmailClick: $$emailConfirmation.changeEmailClicked,

    onConfirmEmailClick: $$emailConfirmation.confirmEmailClicked
  })

  .view(
    ({ account, onCloseClick, onChangeEmailClick, onConfirmEmailClick }) => (
      <Modal.Container
        className={styles.emailChanged}
        onClose={() => onCloseClick()}
        size='small'
      >
        <Modal.Header>Email confirmation</Modal.Header>

        <Modal.Body className={styles.emailChangedContent}>
          <p className={styles.emailChangedText}>
            Your address has been changed successfully.
          </p>

          <p className={styles.emailChangedText}>
            We will send a confirmation link to{' '}
            <span className={styles.highlight}>{account?.email}</span>. If the
            email didn't come, check the spam folder.
          </p>

          <p className={classNames(styles.emailChangedText, styles.highlight)}>
            If you want to change your email address,{' '}
            <span
              className={styles.changeEmailLink}
              onClick={() => onChangeEmailClick()}
            >
              click here.
            </span>
          </p>
        </Modal.Body>

        <Modal.Footer className={styles.footer}>
          <Button
            palette='text'
            variant='text'
            className={styles.cancel}
            onClick={() => onCloseClick()}
          >
            Cancel
          </Button>

          <Button onClick={() => onConfirmEmailClick()}>Confirm email</Button>
        </Modal.Footer>
      </Modal.Container>
    )
  );

export { ChangedEmail };
