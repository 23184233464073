import { $$invite } from '@features/auth/invite';

import { createView } from '@shared/lib/view';

import { Field } from '@shared/ui/organisms/field';

import styles from './invite-footer.module.scss';

const InviteFooter = createView().view(() => (
  <div className={styles.checks}>
    <h4 className={styles.checksTitle}>By signing up, you:</h4>

    <Field.Checkbox
      className={styles.checkbox}
      use={$$invite.form.fields.terms}
      label={
        <span>
          Agree to our{' '}
          <a
            className={styles.link}
            target='_blank'
            href='https://google.com'
            rel='noreferrer'
          >
            Terms of Service
          </a>
        </span>
      }
    />

    <Field.Checkbox
      className={styles.checkbox}
      use={$$invite.form.fields.policy}
      label={
        <span>
          Consent to our use of your personal data as described in our{' '}
          <a
            className={styles.link}
            target='_blank'
            href='https://google.com'
            rel='noreferrer'
          >
            Privacy Policy
          </a>
        </span>
      }
    />

    <p className={styles.checksDescription}>
      You also consent to us contacting you by email to inform you of our
      services and other information we think will be of interest to you. You
      can unsubscribe at any time.
    </p>
  </div>
));

export { InviteFooter };
