import location from '@icon/location.svg';

import avatarPlaceholder from '@img/avatar-placeholder.png';

import type { FC } from 'react';

import { useMemo, useRef, useState } from 'react';

import { $$openAddressOnMap } from '@features/address/open-on-map';

import { $$carerProfile } from '@features/carer/view-carer-profile';

import { $$carerMatches } from '@entities/carer';

import type { CarerMatch, SupportTypeId } from '@shared/api';

import { supportTypeNames } from '@shared/api';

import { supportTypeColor } from '@shared/api';

import { useClickOutside } from '@shared/lib/hooks';

import { cutMore } from '@shared/lib/utils';

import { createView } from '@shared/lib/view';

import { Button, ButtonGroup } from '@shared/ui/atoms/button';

import { Chip, ChipGroup } from '@shared/ui/atoms/chip';

import type { ChipProps } from '@shared/ui/atoms/chip/chip.props';

import { Dropdown } from '@shared/ui/atoms/dropdown';

import { useMedia } from '@shared/ui/atoms/media';

import { Text } from '@shared/ui/atoms/text';

import { TextPair } from '@shared/ui/atoms/text-pair';

import { VideoBox } from '@shared/ui/atoms/video-box';

import styles from './carer-match.module.scss';

import type {
  CarerMatchProps,
  ChipsMatchProps,
  FieldMatchProps,
  FooterMatchProps,
  HeaderMatchProps
} from './carer-match.props';

const CareMatch = createView<CarerMatchProps>()
  .units({
    likeClicked: $$carerMatches.likeClicked,

    viewProfile: $$carerProfile.viewProfileOpened,

    locationClicked: $$openAddressOnMap.openFullAddressFx
  })

  .map(({ carer, likeClicked, locationClicked, viewProfile }) => {
    const onViewProfileClick = () => {
      viewProfile(carer.id);
    };

    const onConnectClick = () => {
      alert('No messenger yet');
    };

    const onLikeClick = () => {
      likeClicked(carer);
    };

    const onLocationClick = () => {
      locationClicked(carer as Required<typeof carer>);
    };

    return {
      onLikeClick,
      onConnectClick,
      onLocationClick,
      onViewProfileClick
    };
  })
  .view(
    ({
      carer,
      onViewProfileClick,
      onConnectClick,
      onLocationClick,
      onLikeClick
    }) => (
      <div className={styles.careMatch}>
        <Header carer={carer} onViewProfileClick={onViewProfileClick} />

        <Chips carer={carer} onLocationClick={onLocationClick} />

        <VideoBox className={styles.promo}>
          <source type='video/mp4' src={`${carer?.videoUrl}#t=0.001`} />
        </VideoBox>

        <Field carer={carer} />

        <Footer
          isInShortList={carer.inShortList}
          onViewProfileClick={onViewProfileClick}
          onConnectClick={onConnectClick}
          onLikeClick={onLikeClick}
        />
      </div>
    )
  );

const Header: FC<HeaderMatchProps> = ({ carer, onViewProfileClick }) => {
  const lg = useMedia('>=lg');
  const md = useMedia('>=md');

  const moreRef = useRef<HTMLDivElement>(null!);
  const [more, setMore] = useState(false);

  const onMoreClick = () => {
    setMore(!more);
  };

  useClickOutside(() => {
    setMore(false);
  }, moreRef);

  return (
    <div className={styles.header}>
      <div className={styles.user}>
        <img
          className={styles.photo}
          src={carer?.imageUrl ?? avatarPlaceholder}
        />

        <div>
          <Text variant='h4' className={styles.userName}>
            {carer?.firstName ?? 'Winnie'} {carer?.lastName ?? 'Brewer'}
          </Text>

          <TextPair label='Carer experience:' value='3 years' />
        </div>
      </div>

      {!lg && md && (
        <Button
          palette='secondary'
          size='lg'
          onClick={() => onViewProfileClick()}
        >
          View full profile
        </Button>
      )}

      {!md && (
        <div className={styles.more} onClick={onMoreClick} ref={moreRef}>
          {more && (
            <Dropdown className={styles.moreDropdown}>
              <Dropdown.Item onClick={() => onViewProfileClick()}>
                View carer profile
              </Dropdown.Item>
            </Dropdown>
          )}
        </div>
      )}
    </div>
  );
};

const Chips: FC<ChipsMatchProps> = ({ carer, onLocationClick }) => {
  const useCareMatchChips = (carer: CarerMatch) =>
    useMemo(
      () => [
        ...[...carer.supportTypes!]
          .sort((a, b) => a.supportType!.id! - b.supportType!.id!)

          .map(type => ({
            palette: supportTypeColor[type.supportType!.id! as SupportTypeId],

            children:
              supportTypeNames[
                type.supportType.id as keyof typeof supportTypeNames
              ]
          }))
      ],
      [carer]
    );

  return (
    <div className={styles.chips}>
      <Chip
        palette='primary'
        icon={location}
        className={styles.addressChip}
        onClick={() => onLocationClick()}
      >
        <span>
          {[carer.addressLine1, carer.addressLine2, carer.city]
            .filter(Boolean)

            .join(', ')}
        </span>
      </Chip>

      <ChipGroup
        chips={useCareMatchChips(carer) as ChipProps[]}
        className={styles.chipsMobile}
      />
    </div>
  );
};

const Field: FC<FieldMatchProps> = ({ carer }) => {
  const data = useMemo(
    () => [
      {
        name: 'Qualification',
        value: cutMore(
          carer.qualifications?.map(item => item.name!),
          1
        )
      },

      {
        name: 'Condition exp-ce',
        value: cutMore(
          carer?.conditionsTypes?.map(item => item.name),
          3
        )
      }
    ],
    [carer]
  );

  return (
    <div className={styles.fields}>
      {data.map(({ name, value }, index) => (
        <TextPair key={index} label={`${name}:`} value={value} />
      ))}
    </div>
  );
};

const Footer: FC<FooterMatchProps> = ({
  onViewProfileClick,
  onConnectClick,
  onLikeClick,
  isInShortList
}) => {
  const lg = useMedia('>=lg');

  const savedButton = {
    fill: {
      normal: '#91D4B7'
    },

    text: {
      normal: '#FFFFFF'
    },

    border: {
      normal: '#91D4B7'
    }
  };

  return (
    <div className={styles.footer}>
      <ButtonGroup>
        <Button
          palette='primary'
          size='lg'
          className={styles.connect}
          onClick={() => onConnectClick()}
        >
          Connect
        </Button>

        {lg && (
          <Button
            className={styles.fullProfile}
            palette='secondary'
            size='lg'
            onClick={() => onViewProfileClick()}
          >
            View full profile
          </Button>
        )}
      </ButtonGroup>

      <Button
        className={styles.fullProfile}
        palette={isInShortList ? savedButton : 'secondary'}
        size='lg'
        onClick={() => onLikeClick()}
      >
        {isInShortList ? 'Saved to shortlist' : 'Save to shortlist'}
      </Button>
    </div>
  );
};

export { CareMatch };
