import { useUnit } from 'effector-react';

import { useMemo } from 'react';

import { $$wellness } from '@entities/wellness';

import { timeOptions } from './heading.constants';

const useHeading = () => {
  const daysCount = useUnit($$wellness.$wellnessShowDays);

  const selectedTimeOption = useMemo(
    () => timeOptions.find(option => option.id === daysCount),
    [daysCount]
  );

  const onTimeSelectChange = (id: number) => {
    $$wellness.wellnessDaysCountChanged(id);
  };

  const onEditClick = () => {
    $$wellness.editWellnessSettingsClicked();
  };

  return {
    options: timeOptions,
    selectedTimeOption,
    onTimeSelectChange,
    onEditClick
  };
};

export { useHeading };
