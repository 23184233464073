import { Attention } from '@entities/account/ui/delete-account/attention';

import { Confirm } from '@entities/account/ui/delete-account/confirm';

import { Credentials } from '@entities/account/ui/delete-account/credentials';

import { Done } from '@entities/account/ui/delete-account/done';

import { Reason } from '@entities/account/ui/delete-account/reason';

import { $$dictionary } from '@entities/dictionary';

import { $$user } from '@entities/user';

import { createView } from '@shared/lib/view';

import { $$deleteWorkspace } from '../model';

const DeleteWorkspace = createView()
  .units({
    step: $$deleteWorkspace.$step,

    account: $$user.$account,

    options: $$dictionary.$memberLeaveCauses,

    onCancel: $$deleteWorkspace.modalClosed,

    stepChanged: $$deleteWorkspace.stepChanged,

    sendEmail: $$deleteWorkspace.sendEmail,

    submit: $$deleteWorkspace.submit
  })

  .view(
    ({ step, onCancel, stepChanged, submit, options, account, sendEmail }) => (
      <>
        {step === 'confirm' && (
          <Confirm onCancel={onCancel} stepChanged={stepChanged} />
        )}

        {step === 'reason' && (
          <Reason
            step='confirm'
            onCancel={onCancel}
            stepChanged={stepChanged}
            options={options}
          />
        )}

        {step === 'attention-access' && (
          <Attention
            onCancel={onCancel}
            step={step}
            stepChanged={stepChanged}
          />
        )}

        {step === 'credentials' && (
          <Credentials onCancel={onCancel} stepChanged={stepChanged} />
        )}

        {step === 'attention-appointments' && (
          <Attention
            onCancel={onCancel}
            step={step}
            stepChanged={stepChanged}
            submit={submit}
          />
        )}

        {step === 'done' && (
          <Done
            onCancel={onCancel}
            sendEmail={sendEmail}
            email={account?.email}
          />
        )}
      </>
    )
  );

export { DeleteWorkspace };
