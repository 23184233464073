import { AdditionalInformation } from '@pages/profile/ui/general/ui/additional-information';

import { ConditionExperience } from '@pages/profile/ui/general/ui/condition-experience';

import { ContactDetails } from '@pages/profile/ui/general/ui/contact-details';

import { Qualifications } from '@pages/profile/ui/general/ui/qualifications';

import { Rates } from '@pages/profile/ui/general/ui/rates';

import { Services } from '@pages/profile/ui/general/ui/services';

import { createView } from '@shared/lib/view';

import styles from './general.module.scss';

const General = createView().view(() => (
  <div className={styles.sections}>
    <Rates />

    <Services />

    <ConditionExperience />

    <Qualifications />

    <ContactDetails />

    <AdditionalInformation />
  </div>
));

export { General };
