import { createEvent, sample } from 'effector';

import { $$careSpace } from '@entities/care-space';

import type { Workspace } from '@shared/api';

import { routes } from '@shared/routes';

const careSpaceClicked = createEvent<Workspace['id']>();

const careSpaceChanged = createEvent();

const $$selectCareSpace = {
  careSpaceChanged,
  careSpaceClicked
};

sample({
  clock: careSpaceClicked,

  target: $$careSpace.$careSpaceID
});

sample({
  clock: careSpaceClicked,

  target: careSpaceChanged
});

sample({
  clock: careSpaceClicked,

  target: routes.schedule.open
});

export { $$selectCareSpace };
