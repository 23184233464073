import { sample } from 'effector';

import { not } from 'patronum';

import { $$signUpCarer } from '@pages/sign-up/pages/carer';

import { $$signUpPage } from '@pages/sign-up/sign-up.model';

import { $$signUp } from '@features/auth/sign-up';

import { $$session } from '@entities/session';

import type { SignUpCarerParams } from '@shared/api';

import { ErrorCode } from '@shared/api';

import { routes } from '@shared/routes';

import { $carerValues, carerValuesToDto } from './shared';

const createAccountForCarerSubmitted = sample({
  clock: $$signUpCarer.$$createAccount.form.submitted,

  source: $carerValues,

  filter: not($$session.$active),

  fn: (
    values,

    { email, password }
  ) =>
    ({
      email,
      password,
      ...carerValuesToDto(values)
    } as SignUpCarerParams)
});

sample({
  clock: createAccountForCarerSubmitted,

  target: $$signUp.signUpCarerFx
});

sample({
  clock: $$signUp.signUpCarerFx.failData,

  fn: () => true,

  target: $$signUpPage.$hasAnAccount
});

sample({
  clock: $$signUp.signUpCarerFx.failData,

  source: $carerValues,

  filter: (_, code) => code == ErrorCode.EmailAlreadyInUse,

  fn: values => values.personalInformation.firstName,

  target: $$signUpPage.$firstName
});

sample({
  clock: routes.signUp.carer.createAccount.closed,

  target: $$signUpCarer.$$createAccount.form.reset.prepend(() => {})
});
