import classNames from 'classnames';

import { $$addCareRecipient, ModalSteps } from '@widgets/add-care-recipient';

import { PreferencesForm } from '@features/care-recipient/manage-preferences';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { useMedia } from '@shared/ui/atoms/media';

import { Modal } from '@shared/ui/atoms/modal';

import styles from './preferences.module.scss';

const Preferences = createView()
  .units({
    onStepClicked: $$addCareRecipient.stepChanged,

    onCancel: $$addCareRecipient.closeModal
  })

  .map(() => {
    const lg = useMedia('>=lg');

    return {
      lg
    };
  })

  .view(({ onCancel, onStepClicked, lg }) => (
    <Modal.Container
      onClose={() => onCancel()}
      fullHeight={lg}
      overlayStyles={classNames({ [styles.overlay]: !lg })}
      className={styles.modal}
      size='large'
    >
      <Modal.Header step>
        <div>Add a new person</div>

        <div>
          <span className={styles.highlight}>Step 3</span> of 4
        </div>
      </Modal.Header>

      <Modal.Body>
        <h1 className={styles.modalTitle}>Preferences</h1>

        <PreferencesForm
          classNamePicker={{
            list: styles.languages,
            language: styles.languagesItem
          }}
          model={$$addCareRecipient.$$managePreferences}
        />
      </Modal.Body>

      <Modal.Footer space fill className={styles.footer}>
        <Button
          palette='text'
          variant='text'
          className={styles.cancel}
          onClick={() => onStepClicked(ModalSteps.ConditionExperience)}
        >
          {useMedia('>=md') ? 'Previous step' : 'Previous'}
        </Button>

        <div className={styles.group}>
          <Button
            className={styles.cancel}
            onClick={() => onStepClicked(ModalSteps.Cancel)}
            palette='text'
            variant='text'
          >
            Cancel
          </Button>

          <Button
            onClick={() => $$addCareRecipient.$$managePreferences.form.submit()}
          >
            Next step
          </Button>
        </div>
      </Modal.Footer>
    </Modal.Container>
  ));

export { Preferences };
