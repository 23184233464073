import classNames from 'classnames';

import type { FC } from 'react';

import { RateType } from '@shared/api/models/care-rate';

import { roundedPrice } from '@shared/lib/utils';

import styles from './care-type-rate.module.scss';

import type { CareTypeRateProps } from './care-type-rate.props';

const CareTypeRate: FC<CareTypeRateProps> = ({ type }) => (
  <div
    className={classNames(
      styles.careType,
      styles[`careType${type?.supportType?.id}`]
    )}
  >
    <div className={styles.careTypeHeader}>
      <div className={styles.careTypeHeaderTitle}>
        {type?.supportType?.name}
      </div>

      <div className={styles.careTypeHeaderIcon} />
    </div>

    <div className={styles.careTypeRates}>
      <div className={styles.careTypeRate}>
        <span>
          £
          {roundedPrice(
            String(
              type?.rates?.find(({ rateType }) => rateType === RateType.default)
                ?.rate
            )
          )}
        </span>{' '}
        <span>/ hr</span>
      </div>

      <div className={styles.careTypeRate}>
        <span>
          £
          {roundedPrice(
            String(
              type?.rates?.find(
                ({ rateType }) => rateType === RateType.holidays
              )?.rate
            )
          )}
        </span>{' '}
        <span>/ hr in holidays</span>
      </div>
    </div>
  </div>
);

export { CareTypeRate };
