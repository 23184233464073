import type { RouteInstance } from 'atomic-router';

import { Link } from 'atomic-router-react';

import { createView } from '@shared/lib/view';

import { $links } from './navigation.model';

import styles from './navigation.module.scss';

const Navigation = createView()
  .units({
    links: $links
  })

  .view(({ links }) => (
    <ul className={styles.links}>
      {links.map(({ params, to, children }, index) => (
        <li key={index}>
          <Link
            className={styles.link}
            activeClassName={styles.active}
            params={params}
            to={to as RouteInstance<any>}
          >
            {children}
          </Link>
        </li>
      ))}
    </ul>
  ));

export { Navigation };
