import axios from 'axios';

import { createEffect, createEvent, createStore, sample } from 'effector';

import { boolean, object, string } from 'yup';

import type { LoginParamsDto, LoginResponseDto } from '@shared/api';

import { carersHQ } from '@shared/api';

import { ToastID } from '@shared/config';

import { createForm } from '@shared/lib/form';

import type { MappedErrors } from '@shared/lib/form/yup';

import type { NotifyOptions } from '@shared/ui/organisms/toasts';

import { $$toast } from '@shared/ui/organisms/toasts';

const reset = createEvent();

const loginByEmailFx = createEffect<LoginParamsDto, LoginResponseDto>();

const $error = createStore(false);

const $errors = createStore<MappedErrors<LoginParamsDto>>({});

const signInForm = createForm<LoginParamsDto>({
  initialValues: {
    email: '',

    password: '',

    rememberMe: false
  },

  $errors,

  $schema: createStore(
    object({
      email: string()
        .required()

        .trim()

        .nullable()

        .email('Invalid')

        .label('Email'),

      password: string()
        .required()

        .trim()

        .nullable()

        .label('Password'),

      rememberMe: boolean()
        .nullable()

        .label('Remember Me')
    })
  )
});

const $$signInByEmail = {
  $error,

  reset,

  signInForm,

  loginByEmailFx
};

sample({
  clock: loginByEmailFx.failData,

  filter: error => axios.isAxiosError(error),

  fn: () =>
    ({
      id: ToastID.InvalidSignInCredentials,

      type: 'error',

      title: 'Email or password is incorrect',

      content: 'Please check it and try again.',

      // TODO: unbind from styles there
      maxWidth: 414
    } as NotifyOptions),

  target: $$toast.notifyFx
});

sample({
  clock: signInForm.submitted,

  target: loginByEmailFx
});

sample({
  clock: reset,

  target: signInForm.reset
});

sample({
  source: $error,

  fn: error =>
    error
      ? {
          email: '',
          password: ''
        }
      : {},

  target: $errors
});

$error
  .on(loginByEmailFx.failData, () => true)

  .reset(reset, loginByEmailFx.done);

loginByEmailFx.use(async payload => {
  const response = await carersHQ.auth.signIn(payload);

  return response.data;
});

export { $$signInByEmail, $error };
