import { createHistoryRouter } from 'atomic-router';

import { createBrowserHistory } from 'history';

import { routes } from '@shared/routes';

const history = createBrowserHistory();

const signUp = [
  {
    path: '/sign-up/:page+',

    route: routes.signUp.any
  },

  {
    path: '/sign-up/type',

    route: routes.signUp.type
  },

  // carer
  {
    path: '/sign-up/carer/postcode',

    route: routes.signUp.carer.postcode
  },

  {
    path: '/sign-up/carer/:page+',

    route: routes.signUp.carer.any
  },

  {
    path: '/sign-up/carer/questionnaire/:postcode/:page+',

    route: routes.signUp.carer.anyWithPostcode
  },

  {
    path: '/sign-up/carer/questionnaire/:postcode/additional-information',

    route: routes.signUp.carer.additionalInformation
  },

  {
    path: '/sign-up/carer/questionnaire/:postcode/contact-details',

    route: routes.signUp.carer.contactDetails
  },

  {
    path: '/sign-up/carer/questionnaire/:postcode/create-account',

    route: routes.signUp.carer.createAccount
  },

  {
    path: '/sign-up/carer/questionnaire/:postcode/employment-details',

    route: routes.signUp.carer.employmentDetails
  },

  {
    path: '/sign-up/carer/questionnaire/:postcode/personal-information',

    route: routes.signUp.carer.personalInformation
  },

  {
    path: '/sign-up/carer/questionnaire/:postcode/qualifications',

    route: routes.signUp.carer.qualifications
  },

  {
    path: '/sign-up/carer/questionnaire/:postcode/service',

    route: routes.signUp.carer.services
  },

  {
    path: '/sign-up/carer/questionnaire/:postcode/skills',

    route: routes.signUp.carer.skills
  },

  // myself
  {
    path: '/sign-up/myself/postcode',

    route: routes.signUp.myself.postcode
  },

  {
    path: '/sign-up/myself/:page+',

    route: routes.signUp.myself.any
  },

  {
    path: '/sign-up/myself/questionnaire/:postcode/:page+',

    route: routes.signUp.myself.anyWithPostcode
  },

  {
    path: '/sign-up/myself/questionnaire/:postcode/care-needs',

    route: routes.signUp.myself.careNeeds
  },

  {
    path: '/sign-up/myself/questionnaire/:postcode/condition-experience',

    route: routes.signUp.myself.conditionExperience
  },

  {
    path: '/sign-up/myself/questionnaire/:postcode/contact-details',

    route: routes.signUp.myself.contactDetails
  },

  {
    path: '/sign-up/myself/questionnaire/:postcode/create-account',

    route: routes.signUp.myself.createAccount
  },

  {
    path: '/sign-up/myself/questionnaire/:postcode/personal-information',

    route: routes.signUp.myself.personalInformation
  },

  {
    path: '/sign-up/myself/questionnaire/:postcode/preferences',

    route: routes.signUp.myself.preferences
  },

  // loved
  {
    path: '/sign-up/loved-one/postcode',

    route: routes.signUp.lovedOne.postcode
  },

  {
    path: '/sign-up/loved-one/:page+',

    route: routes.signUp.lovedOne.any
  },

  {
    path: '/sign-up/loved-one/questionnaire/:postcode/:page+',

    route: routes.signUp.lovedOne.anyWithPostcode
  },

  {
    path: '/sign-up/loved-one/questionnaire/:postcode/care-needs',

    route: routes.signUp.lovedOne.careNeeds
  },

  {
    path: '/sign-up/loved-one/questionnaire/:postcode/condition-experience',

    route: routes.signUp.lovedOne.conditionExperience
  },

  {
    path: '/sign-up/loved-one/questionnaire/:postcode/care-recipient',

    route: routes.signUp.lovedOne.careRecipient
  },

  {
    path: '/sign-up/loved-one/questionnaire/:postcode/create-account',

    route: routes.signUp.lovedOne.createAccount
  },

  {
    path: '/sign-up/loved-one/questionnaire/:postcode/account-holder',

    route: routes.signUp.lovedOne.accountHolder
  },

  {
    path: '/sign-up/loved-one/questionnaire/:postcode/preferences',

    route: routes.signUp.lovedOne.preferences
  }
];

const router = createHistoryRouter({
  routes: [
    {
      path: '/recovery/password',

      route: routes.auth.password
    },

    {
      path: '/recovery',

      route: routes.auth.recovery
    },

    {
      path: '/sign-in',

      route: routes.auth.signIn
    },

    ...signUp,

    {
      path: '/sign-up/invite',

      route: routes.auth.signUpInvite
    },

    {
      path: '/help',

      route: routes.help
    },

    {
      path: '/team/matches',

      route: routes.matches
    },

    {
      path: '/team',

      route: routes.team.base
    },

    {
      path: '/team/:page+',

      route: routes.team.any
    },

    {
      path: '/team/my',

      route: routes.team.my
    },

    {
      path: '/team/connections',

      route: routes.team.connections
    },

    {
      path: '/team/shortlist',

      route: routes.team.shortlist
    },

    {
      path: '/profile',

      route: routes.profile
    },

    {
      path: '/invoices',

      route: routes.invoices
    },

    {
      path: '/care-recipients/:id+',

      route: routes.careRecipients.base
    },

    {
      path: '/care-recipients/:id',

      route: routes.careRecipients.general
    },

    {
      path: '/care-recipients/:id/medication',

      route: routes.careRecipients.medication
    },

    {
      path: '/care-recipients/:id/wellness',

      route: routes.careRecipients.wellness
    },

    {
      path: '/care-recipients/:id/contacts',

      route: routes.careRecipients.contacts
    },

    {
      path: '/',

      route: routes.deletedAccount.home
    },

    {
      path: '/restore-form',

      route: routes.deletedAccount.restore
    },

    {
      path: '/invoices',

      route: routes.deletedAccount.invoices
    },

    {
      path: '/invoices/:id',

      route: routes.deletedAccount.personInvoice
    },

    {
      path: '/',

      route: routes.schedule
    },

    {
      path: '/clients',

      route: routes.clients
    },

    {
      path: '/settings/account',

      route: routes.settings.account
    },

    {
      path: '/settings/billing',

      route: routes.settings.billing
    },

    {
      path: '/settings/members',

      route: routes.settings.members
    },

    {
      path: '/care-space/deletion/confirmed/:id',

      route: routes.careSpaceDeletion.confirmed
    },

    {
      path: '/auth/care-space-left/:name',

      route: routes.leaveCareSpace.confirmed
    }
  ]
});

export { router, history };
