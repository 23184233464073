import type { RouteInstance } from 'atomic-router';

import { Link } from 'atomic-router-react';

import type { PropsWithChildren, FC } from 'react';

import styles from './link-item.module.scss';

type LinkItemProps = PropsWithChildren<{
  src: string;

  params?: Record<string, any>;

  to?: RouteInstance<any>;

  onClick?: () => void;
}>;

const LinkItem: FC<LinkItemProps> = ({
  src,
  to,
  children,
  params,
  onClick
}) => {
  const content = (
    <>
      <img className={styles.icon} src={src} />

      <div className={styles.text}>{children}</div>
    </>
  );

  if (to) {
    return (
      <Link className={styles.item} to={to} params={params ?? {}}>
        {content}
      </Link>
    );
  }

  return (
    <div className={styles.item} onClick={onClick}>
      {content}
    </div>
  );
};

export { LinkItem };
