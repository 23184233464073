import { sample } from 'effector';

import { $$wellness } from '@entities/wellness';

import { ToastID } from '@shared/config';

import { routes } from '@shared/routes';

import type { NotifyOptions } from '@shared/ui/organisms/toasts';

import { $$toast } from '@shared/ui/organisms/toasts';

const $id = routes.careRecipients.wellness.$params.map(state => state.id);

sample({
  clock: [
    routes.careRecipients.wellness.opened,
    $$wellness.$wellnessShowDays.updates,
    $$wellness.$wellnessActiveCharts.updates
  ],

  source: {
    id: $id,
    wellnessActiveCharts: $$wellness.$wellnessActiveCharts,
    daysCount: $$wellness.$wellnessShowDays
  },

  fn: ({ id, wellnessActiveCharts, daysCount }) => {
    const today = new Date();
    const lastDay = new Date(today.getTime() - daysCount * 24 * 60 * 60 * 1000);

    return {
      id: id,
      startDate: today.toISOString().substring(0, 10),
      endDate: lastDay.toISOString().substring(0, 10),
      markTypes: wellnessActiveCharts
    };
  },

  target: $$wellness.getDashboardFx
});

sample({
  clock: $$wellness.getDashboardFx.fail,

  fn: ({ error }: any) =>
    ({
      id: ToastID.WellnessError,
      title: 'Wellness error',
      content: `${error.response.data.message}`,
      type: 'error'
    } as NotifyOptions),

  target: $$toast.notifyFx
});
