import classNames from 'classnames';

import type { FC } from 'react';

import styles from './label.module.scss';

import type { LabelProps } from './label.props';

const Label: FC<LabelProps> = ({
  label,
  error,
  tip,
  className,
  labelGap,
  helper,
  isError,
  nowrap,
  bold,
  plainTextLabel,
  disabled
}) => (
  <div
    className={classNames(styles.header, className, {
      [styles.labelGap]: labelGap,
      [styles.plainTextLabel]: plainTextLabel
    })}
  >
    {label && (
      <div
        className={classNames(styles.label, {
          [styles.errorBlock]: !disabled && isError,
          [styles.nowrap]: nowrap,
          [styles.bold]: bold
        })}
      >
        {label}

        {isError && error && (
          <span className={styles.errorText}>{`(${error})`}</span>
        )}

        {tip && !error && (
          <span className={classNames(styles.tip)}>{`(${tip})`}</span>
        )}
      </div>
    )}

    <span className={styles.helper}>{helper}</span>
  </div>
);

export { Label };
