import avatarPlaceholder from '@img/avatar-placeholder.png';

import { combine } from 'effector';

import { $$careRecipient } from '@entities/care-recipient';

import { $$session, RoleType } from '@entities/session';

import { $$user } from '@entities/user';

const $user = combine(
  $$careRecipient.$selectedCareRecipient,

  $$user.$account,

  $$session.$role,

  (careRecipient, account, role) =>
    (role === RoleType.Carer ? account?.carer : careRecipient) ?? null
);

const $avatarUrl = $user.map(state => state?.imageUrl ?? avatarPlaceholder);

export { $user, $avatarUrl };
