import { $$signUpCarer } from '@pages/sign-up/pages/carer';

import { routes } from '@shared/routes';

import { createCarerStep } from './shared';

createCarerStep({
  key: 'qualifications',

  form: $$signUpCarer.steps.qualifications.form,

  route: routes.signUp.carer.qualifications,

  nextRoute: routes.signUp.carer.additionalInformation,

  mapValues: values => values.qualifications,

  processValues: values => values,

  checkValues: values => Boolean(values.services)
});
