import { createEvent, sample } from 'effector';

import { createEditMemberDetails } from '@features/member/edit-details';

import { $$inviteMembers } from '@features/member/invite';

import { $$members } from '@entities/members';

import type { WorkspaceMember } from '@shared/api';

import { routes } from '@shared/routes';

const inviteMembersClicked = createEvent();

const editClicked = createEvent<WorkspaceMember>();

const $$editMemberDetails = createEditMemberDetails();

const $$membersPage = {
  $$editMemberDetails,

  editClicked,

  inviteMembersClicked
};

sample({
  clock: routes.settings.members.opened,

  target: $$members.getMembersFx
});

sample({
  clock: inviteMembersClicked,

  fn: () => true,

  target: $$inviteMembers.$visible
});

sample({
  clock: editClicked,

  fn: () => true,

  target: $$editMemberDetails.$visible
});

sample({
  clock: editClicked,

  target: $$editMemberDetails.$member
});

export { $$membersPage };
