import { createStore } from 'effector';

import { modelFactory } from 'effector-factorio';

import { number, object, string } from 'yup';

import {
  maxCareRecipientBirth,
  minCareRecipientBirth
} from '@features/care-recipient/manage-details/lib';

import type { Gender } from '@shared/api';

import { createForm } from '@shared/lib/form';

import {
  nameRegEx,
  testMaxDate,
  testMinDate,
  withoutCyrilic
} from '@shared/lib/utils';

type ManageCareRecipientDetailsValues = {
  firstName: string;

  lastName: string;

  dateOfBirth: string;

  gender: Gender['id'];

  postCode: string;

  city: string;

  addressLine1: string;

  addressLine2: string;

  phone: string;
};

const ManageCareRecipientDetailsModelFactory = modelFactory(() => {
  const form = createForm<ManageCareRecipientDetailsValues>({
    initialValues: {
      firstName: '',

      lastName: '',

      dateOfBirth: null,

      gender: 1,

      postCode: '',

      city: '',

      addressLine1: '',

      addressLine2: '',

      phone: ''
    },

    $schema: createStore(
      object({
        firstName: string()
          .nullable()

          .required()

          .trim('Required')

          .label('First name')

          .matches(nameRegEx, 'Invalid'),

        lastName: string()
          .nullable()

          .required()

          .trim('Required')

          .label('First name')

          .matches(nameRegEx, 'Invalid'),

        dateOfBirth: string()
          .nullable()

          .required()

          .trim('Required')

          .test('min-date', 'Invalid', testMinDate(minCareRecipientBirth))

          .test('max-date', 'Invalid', testMaxDate(maxCareRecipientBirth))

          .label('Date of birth'),

        gender: number()
          .nullable()

          .required()

          .label('Gender'),

        postCode: string()
          .nullable()

          .required()

          .trim('Required')

          .matches(/^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i, 'Invalid')

          .label('Postcode'),

        city: string()
          .nullable()

          .required()

          .matches(withoutCyrilic, {
            excludeEmptyString: true,
            message: 'Invalid format'
          })

          .label('City')

          .trim('Required'),

        addressLine1: string()
          .nullable()

          .required()

          .matches(withoutCyrilic, {
            excludeEmptyString: true,
            message: 'Invalid format'
          })

          .label('Address line 1')

          .trim('Required'),

        addressLine2: string()
          .nullable()

          .matches(withoutCyrilic, {
            excludeEmptyString: true,
            message: 'Invalid format'
          })

          .label('Address line 2'),

        phone: string()
          .nullable()

          .required()

          .label('Phone')

          .trim('Required')

          .min(2, 'Required')

          .matches(/^\+?\d+$/, 'Invalid')
      })
    )
  });

  return {
    form
  };
});

export type { ManageCareRecipientDetailsValues };

export { ManageCareRecipientDetailsModelFactory };
