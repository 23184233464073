import { createStore } from 'effector';

import { modelFactory } from 'effector-factorio';

import { contactDetailsSchema } from '@entities/user';

import { createForm } from '@shared/lib/form';

type ContactDetailsValues = {
  city: string;
  phone: string;
  postCode: string;
  addressLine1: string;
  addressLine2: string;
};

const ManageContactDetailsModelFactory = modelFactory(() => {
  const form = createForm<ContactDetailsValues>({
    initialValues: {
      city: '',

      phone: '',

      postCode: '',

      addressLine1: '',

      addressLine2: ''
    },

    $schema: createStore(contactDetailsSchema)
  });

  return {
    form
  };
});

export type { ContactDetailsValues };

export { ManageContactDetailsModelFactory };
