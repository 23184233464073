import { createEvent, createStore, sample } from 'effector';

import { DeleteSuccess } from '@features/member/delete/ui/delete-success';

import { $$careSpace } from '@entities/care-space';

import { $$members } from '@entities/members';

import { $$user } from '@entities/user';

import { ToastID } from '@shared/config';

import { createForm } from '@shared/lib/form';

import type { NotifyOptions } from '@shared/ui/organisms/toasts';

import { $$toast } from '@shared/ui/organisms/toasts';

const form = createForm({
  initialValues: {
    reason: ''
  }
});

const deleteMemberOpened = createEvent<string | number>();

const deleteMemberClosed = createEvent();

const setSelectedMember = createEvent<{
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}>();

const $visible = createStore(false);

const $memberId = createStore(null);

const $selectedMember = createStore<{
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}>(null);

const $$memberDelete = {
  form,

  $visible,

  $memberId,

  $selectedMember,

  setSelectedMember,

  deleteMemberOpened,

  deleteMemberClosed
};

sample({
  clock: form.submitted,

  target: $$members.kickFromWorkspace
});

sample({
  clock: $$members.kickFromWorkspace,

  source: {
    workspaceId: $$careSpace.$careSpaceID,
    selectedMember: $$memberDelete.$selectedMember.map(state => ({
      email: state?.email,
      id: state?.id
    }))
  },

  fn: ({ workspaceId, selectedMember }, payload) => ({
    ...payload,
    ...selectedMember,
    workspaceId
  }),

  target: $$members.kickFromWorkspaceFx
});

sample({
  clock: $$members.kickFromWorkspaceFx.doneData,

  target: [
    $$user.getAccountFx,
    $$members.getMembersFx,
    $$memberDelete.deleteMemberClosed,
    form.reset
  ]
});

sample({
  clock: $$members.kickFromWorkspaceFx.doneData,

  source: $$memberDelete.$selectedMember,

  fn: ({ id, email, firstName, lastName }) =>
    ({
      id: ToastID.MemberDeleted,

      type: 'success',

      title: id
        ? `<b style="word-break: break-all">${firstName} ${lastName}</b> (<b style="word-break: break-all">${email}</b>) was deleted`
        : `<b style="word-break: break-all">${email}</b> was deleted`,

      content: DeleteSuccess,

      isTextMinimized: true
    } as NotifyOptions),

  target: [$$toast.notifyFx, $$memberDelete.deleteMemberClosed]
});

$visible.on(deleteMemberOpened, () => true).reset(deleteMemberClosed);

$memberId.on(deleteMemberOpened, (_state, payload) => payload);

$selectedMember.on(setSelectedMember, (_state, payload) => payload);

export { $$memberDelete, form };
