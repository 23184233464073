import { createEffect, createEvent, createStore, sample } from 'effector';

import { condition } from 'patronum';

import { $$openAddressOnMap } from '@features/address/open-on-map';

import type { ClientProfileView } from '@shared/api';

import { carersHQ } from '@shared/api';

const viewProfileOpened = createEvent<ClientProfileView['id']>();

const viewProfileClosed = createEvent();

const showMapClicked = createEvent();

const getCareRecipientPublicProfileFx = createEffect<
  ClientProfileView['id'],
  ClientProfileView
>();

const $careRecipientProfile = createStore<ClientProfileView | null>(null);

const $visible = createStore(false);

const $$careRecipientProfile = {
  $visible,

  $careRecipientProfile,

  viewProfileClosed,

  showMapClicked,

  viewProfileOpened,

  getCareRecipientPublicProfileFx
};

sample({
  clock: viewProfileOpened,

  target: getCareRecipientPublicProfileFx
});

condition({
  source: sample({ clock: showMapClicked, source: $careRecipientProfile }),

  if: (careRecipientProfile: ClientProfileView) =>
    careRecipientProfile.isFullDataView,

  then: $$openAddressOnMap.openFullAddressFx.prepend(
    ({ addressLine1, addressLine2, city, postCode }: ClientProfileView) => ({
      addressLine1,
      addressLine2,
      city,
      postCode
    })
  ),

  else: $$openAddressOnMap.openShortAddressFx.prepend(
    ({ district, city, postCode }: ClientProfileView) => ({
      district,
      city,
      postCode
    })
  )
});

$visible.on(viewProfileOpened, () => true).reset(viewProfileClosed);

$careRecipientProfile
  .on(getCareRecipientPublicProfileFx.doneData, (_state, payload) => payload)
  .reset(viewProfileClosed);

getCareRecipientPublicProfileFx.use(async id => {
  const data = await carersHQ.carer.viewClientProfile({ id });

  return data;
});

export { $$careRecipientProfile };
