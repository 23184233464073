import cn from 'classnames';

import classnames from 'classnames';

import { useUnit } from 'effector-react';

import type { ReactNode } from 'react';

import { $$dictionary } from '@entities/dictionary/model';

import type { Language } from '@shared/api';

import { createView } from '@shared/lib/view';

import { Input } from '@shared/ui/atoms/input';

import { Label } from '@shared/ui/atoms/label';

import { useMedia } from '@shared/ui/atoms/media';

import { Select } from '@shared/ui/atoms/select';

import styles from './languages-picker.module.scss';

type LanguagesPickerProps = {
  classNames?: {
    list?: string;
    language?: string;
    wrapper?: string;
  };

  label: ReactNode;

  labelGap?: boolean;

  max: number;

  onChange: (value: Language[]) => void;

  value: Language[];

  captions?: {
    add?: string;
  };
};

type ItemProps = {
  canDelete: boolean;

  fixedWidth: boolean;

  labelGap?: boolean;

  languages: Language[];

  value: number | string;

  onChange: (value: Language['id']) => void;

  onDelete: () => void;
};

const Item = createView<ItemProps>()
  .map(({ languages }) => {
    const md = useMedia('>=md');

    const shouldShowLabelOnTop = md;

    return {
      options: languages,
      shouldShowLabelOnTop
    };
  })

  .view(
    ({
      value,
      options,
      labelGap,
      onChange,
      canDelete,
      fixedWidth,
      shouldShowLabelOnTop,
      onDelete: onDeleteClick
    }) => {
      const helper = canDelete ? (
        <span className={styles.delete} onClick={onDeleteClick}>
          Delete
        </span>
      ) : null;

      return (
        <div className={styles.language}>
          <Select
            wrapperClassName={styles.wrapper}
            labelGap={labelGap}
            onChange={onChange}
            className={cn(styles.languageSelect, {
              [styles.languageSelectFixedWidth]: fixedWidth
            })}
            searchable
            value={value}
            options={options}
            helper={shouldShowLabelOnTop ? helper : null}
          />

          {!shouldShowLabelOnTop && helper}
        </div>
      );
    }
  );

const LanguagesPicker = createView<LanguagesPickerProps>()
  .defaultProps({
    value: [],

    max: 10
  })

  .map(({ value, max, onChange }) => {
    const canDelete = value.length > 0;

    const fixedWidth = max == 2;

    const canAdd = value?.length < max;

    const languages = useUnit($$dictionary.$languages);

    const selected = value.filter(el => Boolean(el)).map(item => item.id);

    const onDelete = (position: number) => {
      onChange(value.filter(item => item?.id != position));
    };

    const onAddClick = () => {
      onChange([...value, null]);
    };

    const onLanguageChange = (id: Language['id'], index: number) => {
      const result = [...value];

      result[index + 1] = { id };

      onChange(result);
    };

    return {
      onDelete,
      onAddClick,

      canAdd,
      selected,
      canDelete,
      languages,
      fixedWidth,
      onLanguageChange,
      md: useMedia('>=md')
    };
  })

  .view(
    ({
      md,
      max,
      value,
      labelGap,
      label,
      canAdd,
      selected,
      captions,
      languages,
      canDelete,
      classNames,
      fixedWidth,
      onDelete,
      onAddClick,
      onLanguageChange
    }) => (
      <div className={classnames(styles.fieldGroup, classNames?.wrapper)}>
        {!md && <Label label={label} bold nowrap />}

        <div className={cn(styles.languages, classNames?.list)}>
          <Input
            labelGap={labelGap}
            className={cn(styles.languageSelect, classNames?.language, {
              [styles.languageSelectFixedWidth]: max === 2
            })}
            bold
            onChange={() => {}}
            value='English'
            mode='view'
            nowrap
            label={md && label}
          />

          {value.slice(1).map((item, index) => {
            const options = languages.filter(
              option => !selected.includes(option.id) || option.id == item?.id
            );

            return (
              <Item
                key={Math.random() + index}
                value={item?.id ?? null}
                languages={options}
                labelGap={labelGap}
                canDelete={canDelete}
                onChange={id => onLanguageChange(id, index)}
                fixedWidth={fixedWidth}
                onDelete={() => onDelete(item?.id ?? null)}
              />
            );
          })}

          {canAdd && (
            <span className={styles.action} onClick={onAddClick}>
              {captions?.add ?? 'Add another'}
            </span>
          )}
        </div>
      </div>
    )
  );

export { LanguagesPicker };
