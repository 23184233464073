import type { AxiosError } from 'axios';

import axios from 'axios';

import { createEffect, sample } from 'effector';

import { carersHQ, ErrorCode } from '@shared/api';

import type { Credentials, Response } from '@shared/api';

import type { EmailChangeErrors } from './types';

const changeEmailFx = createEffect<Credentials, string, AxiosError<Response>>();

const emailChanged = changeEmailFx.doneData;

const changeEmailFailed = sample({
  clock: changeEmailFx.failData,

  filter: error => axios.isAxiosError(error),

  fn: ({
    response: {
      data: { code }
    }
  }) =>
    ({
      wrongPassword: code == ErrorCode.WrongPassword,

      sameAsCurrent: code == ErrorCode.EmailIsCurrentEmail,

      alreadyInUse: code == ErrorCode.EmailIsAlreadyInUse
    } as EmailChangeErrors)
});

const $$changeUserEmail = {
  emailChanged,
  changeEmailFailed,

  changeEmailFx
};

changeEmailFx.use(async payload => {
  await carersHQ.auth.updateEmail(payload);

  return payload.email;
});

export { $$changeUserEmail };
