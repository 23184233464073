import { useStore } from 'effector-react';

import { $$boot } from '@processes/boot/model';

import { Pages } from '@pages';

import { AppLoading } from '@widgets/app-loading';

import { Toasts } from '@shared/ui/organisms/toasts';

import '@processes/authorize';

import '@processes/block';

import './model';

const Content = () => {
  const ready = useStore($$boot.$ready);

  switch (true) {
    case !ready:
      return <AppLoading />;

    default:
      return <Pages />;
  }
};

const App = () => (
  <>
    <Content />

    <Toasts />
  </>
);

export { App };

export { Provider } from './provider';
