import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { Modal } from '@shared/ui/atoms/modal/modal.component';

import { Text } from '@shared/ui/atoms/text';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { form } from '../model';

import styles from './leave-workspace.module.scss';

import { useLeaveWorkspaceProps } from './leave-workspace.props';

const LeaveWorkspace = createView()
  .map(useLeaveWorkspaceProps)

  .view(({ options, ownerName, isTextareaActive, onClose }) => (
    <Modal.Container onClose={() => onClose()} size='small'>
      <Modal.Header>{`Leaving ${ownerName} Care Space`}</Modal.Header>

      <Modal.Body>
        <div className={styles.content}>
          <Text variant='body-1' className={styles.text}>
            Once you leave the Care Space, all the information from it will not
            be available to you
          </Text>

          <Form use={form}>
            <Field.Select
              use={form.fields.reason}
              className={styles.reason}
              menuPosition='fixed'
              label={
                <div className={styles.label}>
                  What is the reason why are you leaving the Care Space?
                </div>
              }
              name='reason'
              placeholder='Select a reason'
              options={options}
              getOptionValue={value => value?.name}
            />

            {isTextareaActive && (
              <Field.Textarea
                use={form.fields.reasonOther}
                className={styles.textarea}
                textareaClassName={styles.textareaInput}
              />
            )}
          </Form>

          <Text variant='body-2' className={styles.label}>
            Changed your mind? No problem. Just press cancel.
          </Text>
        </div>
      </Modal.Body>

      <Modal.Footer className={styles.footer}>
        <Button
          onClick={() => onClose()}
          palette='text'
          variant='text'
          className={styles.cancel}
        >
          Cancel
        </Button>

        <Button onClick={() => form.submit()} palette='error'>
          Yes, I want to leave
        </Button>
      </Modal.Footer>
    </Modal.Container>
  ));

export { LeaveWorkspace };
