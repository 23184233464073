import { modelView } from 'effector-factorio';

import { $$dictionary, LanguagesPicker } from '@entities/dictionary';

import { createView } from '@shared/lib/view';

import { BoxRow } from '@shared/ui/atoms/box-row';

import { CheckboxGroup } from '@shared/ui/atoms/checkbox';

import { FormSection, FormSectionGroup } from '@shared/ui/atoms/form-section';

import { useMedia } from '@shared/ui/atoms/media';

import { RadioGroup } from '@shared/ui/atoms/radio';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { ManagePreferencesModelFactory } from '../../model';

import styles from './preferences.module.scss';

/**
 * <Preferences />
 */
const PreferencesForm = modelView(
  ManagePreferencesModelFactory,
  createView<{
    captions?: {
      languages?: {
        add?: string;
      };
    };

    classNamePicker?: {
      list?: string;
      language?: string;
      wrapper?: string;
    };
  }>()
    .units({
      pets: $$dictionary.$pets,

      genders: $$dictionary.$genders,

      transportationHelpTypes: $$dictionary.$transportationHelpTypes
    })

    .map(() => {
      const { form } = ManagePreferencesModelFactory.useModel();

      const mobile = useMedia('<sm');

      return {
        form,
        mobile
      };
    })

    .view(
      ({
        form,
        captions,
        transportationHelpTypes,
        pets,
        mobile,
        genders,
        classNamePicker
      }) => (
        <Form use={form}>
          <FormSectionGroup>
            <Field.Container use={form.fields.languages}>
              {({ onChange, value }) => (
                <LanguagesPicker
                  labelGap
                  classNames={classNamePicker}
                  captions={{
                    add: captions?.languages?.add
                  }}
                  label='Languages'
                  value={value}
                  onChange={onChange}
                />
              )}
            </Field.Container>

            <FormSection title='Gender'>
              <BoxRow layout={mobile ? 'vertical' : 'horizontalAlways'}>
                <Field.RadioList
                  use={form.fields.carerGender}
                  options={[
                    {
                      id: null,

                      name: 'No preference'
                    },

                    ...genders
                  ]}
                />
              </BoxRow>
            </FormSection>

            <FormSection title='Is help with transportation required?'>
              <RadioGroup layout='vertical'>
                <Field.RadioList
                  use={form.fields.transportationHelp}
                  options={[
                    {
                      id: null,

                      name: 'No'
                    },

                    ...transportationHelpTypes
                  ]}
                />
              </RadioGroup>
            </FormSection>

            <FormSection title='Does anyone smoke in the home?'>
              <BoxRow layout='horizontalAlways'>
                <Field.RadioList
                  use={form.fields.smokingOk}
                  options={[
                    { id: false, name: 'No' },
                    { id: true, name: 'Yes' }
                  ]}
                />
              </BoxRow>
            </FormSection>

            <FormSection title='What pets do you have at home?'>
              <CheckboxGroup className={styles.pets}>
                <Field.CheckboxList use={form.fields.pets} options={pets} />
              </CheckboxGroup>
            </FormSection>
          </FormSectionGroup>
        </Form>
      )
    )
);

export { PreferencesForm };
