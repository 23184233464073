import { createStore } from 'effector';

import { modelFactory } from 'effector-factorio';

import { array, object, string } from 'yup';

import type { ConditionsType, Service } from '@shared/api';

import type { CarerSupportType } from '@shared/api/models/carer-support-type';

import { createForm } from '@shared/lib/form';

type ServicesValues = {
  services: Service['id'][];

  referenceConfirm: boolean;

  supportTypes: CarerSupportType[];

  conditionsTypes: ConditionsType['id'][];
};

const ManageServicesModelFactory = modelFactory(() => {
  const form = createForm<ServicesValues>({
    initialValues: {
      services: [],

      supportTypes: [],

      conditionsTypes: [],

      referenceConfirm: false
    },

    $schema: createStore(
      object({
        services: array()
          .min(1, 'Required')

          .required()

          .nullable()

          .label('Care types'),

        conditionsTypes: array()
          .min(1, 'Required')

          .required()

          .nullable()

          .label('Services'),

        supportTypes: array(
          object({
            rates: array(
              object({
                rate: string()
                  .required(' ')

                  .nullable()

                  .max(6)

                  .min(1, ' ')
              })
            ).nullable()
          }).nullable()
        )
          .min(1, 'Required')

          .required()

          .nullable()

          .label('Care types')
      })
    )
  });

  return {
    form
  };
});

export type { ServicesValues };

export { ManageServicesModelFactory };
