import { createEvent, split } from 'effector';

import { routes } from '@shared/routes';

const tabChanged = createEvent<string>();

const $$settings = {
  tabChanged
};

split({
  source: tabChanged,

  match: (tab: string) => tab,

  cases: {
    account: routes.settings.account.open,
    members: routes.settings.members.open,
    billing: routes.settings.billing.open
  }
});

export { $$settings };
