import type { FC } from 'react';

import { useState } from 'react';

import { Input } from '@shared/ui/atoms/input';

import type { PhoneInputProps } from './phone-input.props';

const PhoneInput: FC<PhoneInputProps> = ({ onChange, value, ...props }) => {
  const [typedValue, setTypedValue] = useState(value ?? '');

  const phoneReplacer = (value: string) => value.replace(/[^0-9\+]+/g, '');

  const onInputChange = (value: string) => {
    setTypedValue(phoneReplacer(value));
    onChange(phoneReplacer(value));
  };

  return (
    <Input
      {...props}
      type='text'
      clearable={false}
      value={typedValue}
      onChange={onInputChange}
    />
  );
};

export { PhoneInput };
