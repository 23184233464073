import { useUnit } from 'effector-react';

import { usePostCodeAddresses } from '@features/address/search-by-postcode';

import { $$billing } from '@entities/billing';

import { $$session } from '@entities/session';

import { $$shared } from '../../model';

import { billingDetailsForm } from '../../model/details';

/**
 * <Details /> props
 */
const useDetailsProps = () => {
  const [
    roleType,
    details,
    publicDetails,
    postalCodeValue,
    postalCodeError,
    closed
  ] = useUnit([
    $$session.$role,
    $$billing.$details,
    $$billing.$publicDetails,
    billingDetailsForm.fields.postalCode.$value,
    billingDetailsForm.fields.postalCode.$errors,
    $$shared.billingDetailsClosed
  ]);

  const { cities, onSuggestionSelect, addressLines1, addressLines2 } =
    usePostCodeAddresses(postalCodeValue, '', billingDetailsForm as any);

  return {
    details,
    cities,
    onSuggestionSelect,
    addressLines1,
    addressLines2,
    publicDetails,
    roleType,
    closed
  };
};

export { useDetailsProps };
