import classNames from 'classnames';

import type { FC } from 'react';

import styles from './text-pair.module.scss';

import type { TextPairProps } from './text-pair.props';

const TextPair: FC<TextPairProps> = ({
  className,
  label,
  value,
  wrap,
  spacing,
  nowrapOverflow,
  ...props
}) => (
  <div
    className={classNames(
      styles.textPair,
      spacing === 'normal' ? '' : styles[spacing as 'spread' | 'multiline'],
      className
    )}
    {...props}
  >
    <div className={styles.label}>{label}</div>

    <div
      className={classNames(styles.value, {
        [styles.valueWrap]: wrap,
        [styles.valueNowrapOverflow]: nowrapOverflow
      })}
      title={value as string}
    >
      {value}
    </div>

    {props.children}
  </div>
);

TextPair.defaultProps = {
  spacing: 'normal'
};

export { TextPair };
