import { sample, createEffect, createStore } from 'effector';

import { object, string } from 'yup';

import { ToastText } from '@features/account/password/ui/toast';

import type { PasswordValues } from '@shared/api';

import { carersHQ } from '@shared/api';

import { ToastID } from '@shared/config';

import { createForm } from '@shared/lib/form';

import { passwordRegEx } from '@shared/lib/utils';

import type { NotifyOptions } from '@shared/ui/organisms/toasts';

import { $$toast } from '@shared/ui/organisms/toasts';

const $error = createStore(false);

const form = createForm({
  initialValues: {
    currentPassword: '',

    newPassword: ''
  },

  $errors: $error.map(incorrect =>
    incorrect ? { currentPassword: `Incorrect` } : ({} as any)
  ),

  $schema: createStore(
    object({
      currentPassword: string()
        .required('Required')

        .nullable()

        .matches(passwordRegEx, 'Incorrect')

        .trim()

        .label('Current password'),

      newPassword: string()
        .required('Required')

        .nullable()

        .matches(passwordRegEx, 'Incorrect')

        .trim()

        .label('New password')
    })
  )
});

const updatePasswordFx = createEffect<PasswordValues, void>();

const $$password = {
  form,

  updatePasswordFx
};

sample({
  clock: form.submitted,

  target: [updatePasswordFx, form.reset]
});

sample({
  clock: updatePasswordFx.doneData,

  fn: () =>
    ({
      id: ToastID.PasswordChanged,
      type: 'success',
      title: 'Password changed',
      content: ToastText,
      duration: 999999
    } as NotifyOptions),

  target: [$$toast.notifyFx]
});

$error
  .on(updatePasswordFx.failData, () => true)

  .reset(updatePasswordFx.doneData);

updatePasswordFx.use(async values => {
  await carersHQ.settings.updatePassword(values);
});

export { $$password, form };
