import { createStore } from 'effector';

import { persist } from 'effector-storage/session';

import type { CarerAdditionalInformationValues } from '@features/carer/manage-additional-information';

import type { EmploymentDetailsValues } from '@features/carer/manage-employment-details';

import type { QualificationsValues } from '@features/carer/manage-qualifications';

import type { ServicesValues } from '@features/carer/manage-services';

import type { ContactDetailsValues } from '@features/user/manage-contact-details';

import type { PersonalInformationValues } from '@features/user/manage-personal-information';

import type { ConditionsType } from '@shared/api';

import { routes } from '@shared/routes';

import { createSignUpStepModelFactory } from '../model';

type Values = {
  qualifications: QualificationsValues;
  additionalInformation: CarerAdditionalInformationValues;
  personalInformation: PersonalInformationValues;
  contactDetails: ContactDetailsValues;
  services: ServicesValues;
  employmentDetails: EmploymentDetailsValues;
};

const $carerValues = createStore<Values>({
  qualifications: null,
  additionalInformation: null,
  personalInformation: null,
  contactDetails: null,
  services: null,
  employmentDetails: null
});

const createCarerStep = createSignUpStepModelFactory({
  $values: $carerValues,

  fallbackRoute: routes.signUp.carer.personalInformation
});

persist({
  store: $carerValues,

  key: 'SIGN_UP_CARER_VALUES'
});

$carerValues.reset(
  routes.signUp.carer.anyWithPostcode.closed,
  routes.signUp.carer.postcode.opened
);

function carerValuesToDto({
  personalInformation: { firstName, lastName, dateOfBirth, gender },

  contactDetails: { postCode, phone, city, addressLine1, addressLine2 },

  employmentDetails: { carerStart, workDocument },

  qualifications: { dbs, certificates, qualifications, qualificationConfirm },

  additionalInformation: { petsDisabled, petsOk, smokingOk,  transportationHelp, languages },

  services: { conditionsTypes, supportTypes, services }
}: Values) {
  return {
    phone,
    firstName,
    lastName,
    carer: {
      careStart: '2021-07-14T15:47:30',
      careStartDate: '2021-07-14T15:47:30',
      careEndDate: '2021-07-14T15:47:30',
      qualificationConfirm,
      postCode,
      city,
      addressLine1,
      addressLine2,
      phone,
      dateOfBirth,
      firstName,
      lastName,
      gender: { id: gender },
      languages,
      conditionsTypes: conditionsTypes.map(id => ({ id } as ConditionsType)),
      supportTypes,
      dbs: dbs ? { id: dbs } : null,
      petsOk: petsOk,
      smokingOk: smokingOk,
      petsDisabled: petsDisabled.map(id => ({ id })),
      services: services.map(id => ({ id })),
      qualifications: qualifications.map(id => ({ id })),
      certificates: certificates.map(id => ({ id })),
      carerStart,
      workDocument: { id: workDocument },
      transportationHelp: transportationHelp ? { id: transportationHelp } : null
    }
  };
}

export { $carerValues, createCarerStep, carerValuesToDto };
