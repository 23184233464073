import classNames from 'classnames';

import moment from 'moment';

import { $$selectCareRecipient } from '@features/care-recipient/select';

import { $$careRecipient } from '@entities/care-recipient';

import { createView } from '@shared/lib/view';

import { UserBox } from '@shared/ui/atoms/user-box';

import styles from './care-recipients.module.scss';

const CareRecipients = createView()
  .units({
    onSelect: $$selectCareRecipient.careRecipientClicked,

    recipients: $$careRecipient.$careRecipients,

    selected: $$careRecipient.$careRecipientID
  })

  .view(({ onSelect, recipients, selected }) => (
    <div>
      <h4 className={styles.title}>Select care for</h4>

      <ul className={styles.list}>
        {recipients
          .filter(item => !item?.isDeleted)
          .map(item => (
            <li
              className={classNames(styles.item, {
                [styles.selected]: selected == item?.id
              })}
              key={item?.id}
              onClick={() => {
                if (selected == item?.id) return;

                onSelect(item?.id);
              }}
            >
              <UserBox
                src={item?.imageUrl}
                title={`${item?.firstName} ${item?.lastName}`}
                subTitle={`${
                  item?.gender?.id == 1 ? 'Male' : 'Female'
                }, ${Math.abs(
                  moment(item?.dateOfBirth).diff(moment(), 'years')
                )} years old`}
              />
            </li>
          ))}
      </ul>
    </div>
  ));

export { CareRecipients };
