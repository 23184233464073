import warning from '@icon/alarm-yellow.svg';

import error from '@icon/alarm.svg';

import success from '@icon/confirmed.svg';

import closeBlack from '@icon/delete-black.svg';

import classNames from 'classnames';

import type { CSSProperties, ReactNode } from 'react';

import styles from './toast.module.scss';

type ToastProps = {
  type: 'success' | 'error' | 'warning';

  stacked: number;

  title: ReactNode | JSX.Element;

  maxWidth: number;

  children: ReactNode | JSX.Element;

  onClose: () => void;
};

const iconsByType = {
  error,
  success,
  warning
};

const Toast = ({
  maxWidth,
  type,
  stacked,
  title,
  children,
  onClose
}: ToastProps) => (
  <div
    className={classNames(styles.container, styles[type])}
    data-stacks={stacked}
    style={
      {
        '--max-toast-width': maxWidth ? `${maxWidth}px` : '730px'
      } as CSSProperties
    }
  >
    <div className={styles.content}>
      <img className={styles.icon} src={iconsByType[type]} />

      <div className={styles.body}>
        <h4 className={styles.title}>{title}</h4>

        <div className={styles.text}>{children}</div>
      </div>

      <img
        className={styles.close}
        src={closeBlack}
        onClick={event => {
          event.stopPropagation();

          onClose();
        }}
      />
    </div>

    <div className={styles.under} />
  </div>
);

export { Toast };
