import { useEffect, useRef } from 'react';

/**
 * Use update
 */
const useUpdate = (...args: Parameters<typeof useEffect>) => {
  const [callback, ...rest] = args;
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;

      return;
    }

    return callback();
  }, ...rest);
};

export { useUpdate };
