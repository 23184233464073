import { useUnit } from 'effector-react';

import { useState } from 'react';

import { $$logoutUser } from '@features/user/logout';

import { useMedia } from '@shared/ui/atoms/media';

import { $$shared } from '../../model';

const usePasswordConfirmationProps = () => {
  const md = useMedia('>=md');

  const [forgotPassword, setForgotPassword] = useState(false);

  const [visible, closed, onLogout] = useUnit([
    $$shared.$visible,

    $$shared.billingDetailsClosed,

    $$logoutUser.logout
  ]);

  const onForgotPasswordClick = () => {
    setForgotPassword(true);
  };

  const onPreviousClick = () => {
    setForgotPassword(false);
  };

  const onRecoverClick = () => {
    closed();

    onLogout('recovery');
  };

  return {
    md,
    visible,
    closed,
    forgotPassword,
    onRecoverClick,
    onPreviousClick,
    onForgotPasswordClick
  };
};

export { usePasswordConfirmationProps };
