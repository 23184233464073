import { $$signUpCarer } from '@pages/sign-up/pages/carer';

import { routes } from '@shared/routes';

import { createCarerStep } from './shared';

createCarerStep({
  key: 'additionalInformation',

  form: $$signUpCarer.steps.additionalInformation.form,

  route: routes.signUp.carer.additionalInformation,

  nextRoute: routes.signUp.carer.employmentDetails,

  mapValues: values => values.additionalInformation,

  processValues: values => ({
    ...values,

    languages: values.languages.filter(Boolean)
  }),

  checkValues: values => Boolean(values.qualifications)
});
