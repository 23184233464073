import { $$headerDropdown } from '@layouts/main-page';

import { sample } from 'effector';

import { $$addCareRecipient } from '@widgets/add-care-recipient';

import { $$inviteMembers } from '@features/member/invite';

const addCareRecipientStarted = sample({
  clock: [
    $$headerDropdown.addCareRecipientsClicked,
    $$inviteMembers.addCareRecipientClicked
  ]
});

sample({
  clock: addCareRecipientStarted,

  target: $$addCareRecipient.openModal
});

// sample({
//   clock: addCareRecipientStarted,
//
//   source: {
//     id: $$careRecipient.$careRecipientID,
//     recipients: $$careRecipient.$careRecipients
//   },
//
//   filter: ({ id, recipients }) => Boolean(id && recipients.length),
//
//   fn: ({ id }) => ({ id }),
//
//   target: routes.careRecipients.general.open
// });
//
// sample({
//   clock: addCareRecipientStarted,
//
//   source: {
//     id: $$careRecipient.$careRecipientID,
//     recipients: $$careRecipient.$careRecipients
//   },
//
//   filter: ({ id, recipients }) => Boolean(!!id && !!recipients.length),
//
//   fn: () => ({} as { id: string[] }),
//
//   target: routes.careRecipients.base.open
// });
