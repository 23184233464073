import type { Entry } from './common';

import type { MembersRoleType } from './workspace';

type UpdateMemberNotificationsDTO = {
  workspaceId: string;

  accountId: string;

  smsNotifications: MembersNotificationType[];

  emailNotifications: MembersNotificationType[];
};

type UpdateMemberRoleDTO = {
  email: string;

  workspaceId: string;

  role: MembersRoleType;
};

type KickFromWorkspaceDTO = {
  reason: string;

  workspaceId: string;

  memberId?: string;

  email?: string;
};

type InviteMembersDTO = {
  invites: InviteMember[];
};

type InviteMembersContract = {
  email: string;

  success: boolean;

  message?: string;
}[];

type InviteMember = {
  email: string;

  workspaceId: string;

  role: MembersRoleType;

  relations: {
    clientId: string;

    relation: string;
  }[];
};

type ResendMemberInvitationDTO = {
  email: string;

  workspaceId: string;
};

type UpdateMemberRelationsDTO = {
  relations: UpdateMemberRelation[];
};

type UpdateMemberRelation = {
  email?: string;
  clientId?: string;
  relation: Entry;
};

enum MembersNotificationType {
  AnyCarerActions = 'Any carer actions',
  AnyScheduleChanges = 'Any schedule changes',
  AnyInvoicesActivities = 'Any invoices activities'
}

type InviteRequestMember = {
  email: string;

  accessLevel: {
    name: MembersRoleType;
  };
};

type MemberCareRecipients = {
  contactFor: Entry;

  relationship?: Entry;
};

type MembersRequestValues = {
  members: InviteRequestMember[];
};

type MembersRelationshipValues = {
  membersRelationships: MemberCareRecipients[][];
};

type MembersEditValues = {
  membersRelationships: MemberCareRecipients[];
};

type MembersDeleteValues = {
  reason: string;
};

export type {
  UpdateMemberNotificationsDTO,
  KickFromWorkspaceDTO,
  InviteMembersDTO,
  ResendMemberInvitationDTO,
  UpdateMemberRoleDTO,
  UpdateMemberRelationsDTO,
  UpdateMemberRelation,
  InviteMember,
  MembersRequestValues,
  MembersRelationshipValues,
  MembersEditValues,
  MembersDeleteValues,
  InviteRequestMember,
  MemberCareRecipients,
  InviteMembersContract
};

export { MembersNotificationType };
