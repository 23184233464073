import classNames from 'classnames';

import { form } from '@features/account/password/model';

import { createView } from '@shared/lib/view';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import styles from './password.module.scss';

const PasswordForm = createView().view(() => (
  <Form use={form}>
    <div className={classNames(styles.formContainer)}>
      <Field.Input
        use={form.fields.currentPassword}
        name='currentPassword'
        type='password'
        placeholder='Enter'
        label='Current password'
        errorIn='label'
      />

      <Field.Input
        use={form.fields.newPassword}
        name='newPassword'
        type='password'
        placeholder='Enter'
        label='New password'
        errorIn='label'
      />
    </div>
  </Form>
));

export { PasswordForm };
