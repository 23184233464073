import { $theme } from '@shared/ui/theme';

const $palettes = $theme.map(theme => ({
  primary: {
    fill: {
      normal: theme.disabled,
      hovered: '#FFFAF0',
      pressed: '#FFFAF0'
    },

    text: {
      normal: theme.textBlack,
      hovered: theme.yellowHovered,
      pressed: theme.yellowPrimary
    },

    border: {
      normal: theme.disabled,
      hovered: '#FFFAF0',
      pressed: '#FFFAF0'
    }
  },

  borderedRed: {
    fill: {
      normal: 'transparent',
      hovered: 'transparent',
      pressed: 'transparent'
    },

    text: {
      normal: theme.red,
      hovered: theme.red,
      pressed: theme.red
    },

    border: {
      normal: '#E4315F',
      hovered: '#E4315F',
      pressed: '#E4315F'
    }
  },

  borderedGrey: {
    fill: {
      normal: 'transparent',
      hovered: 'transparent',
      pressed: 'transparent'
    },

    text: {
      normal: theme.textGrey,
      hovered: theme.textGrey,
      pressed: theme.textGrey
    },

    border: {
      normal: theme.textGrey,
      hovered: theme.textGrey,
      pressed: theme.textGrey
    }
  },

  borderedBlue: {
    fill: {
      normal: 'transparent',
      hovered: 'transparent',
      pressed: 'transparent'
    },

    text: {
      normal: theme.blue,
      hovered: theme.blue,
      pressed: theme.blue
    },

    border: {
      normal: theme.blue,
      hovered: theme.blue,
      pressed: theme.blue
    }
  },

  borderedGreen: {
    fill: {
      normal: 'transparent',
      hovered: 'transparent',
      pressed: 'transparent'
    },

    text: {
      normal: theme.green,
      hovered: theme.green,
      pressed: theme.green
    },

    border: {
      normal: theme.green,
      hovered: theme.green,
      pressed: theme.green
    }
  },

  filledRed: {
    fill: {
      normal: theme.red10,
      hovered: theme.red10,
      pressed: theme.red10
    },

    text: {
      normal: theme.red,
      hovered: theme.red,
      pressed: theme.red
    },

    border: {
      normal: theme.red10,
      hovered: theme.red10,
      pressed: theme.red10
    }
  },

  fillerGrey: {
    fill: {
      normal: theme.disabled,
      hovered: theme.disabled,
      pressed: theme.disabled
    },

    text: {
      normal: theme.textBlack,
      hovered: theme.textBlack,
      pressed: theme.textBlack
    },

    border: {
      normal: theme.disabled,
      hovered: theme.disabled,
      pressed: theme.disabled
    }
  },

  filledPeach: {
    fill: {
      normal: theme.peach10,
      hovered: theme.peach10,
      pressed: theme.peach10
    },

    text: {
      normal: theme.textBlack,
      hovered: theme.textBlack,
      pressed: theme.textBlack
    },

    border: {
      normal: theme.peach10,
      hovered: theme.peach10,
      pressed: theme.peach10
    }
  },

  filledYellow: {
    fill: {
      normal: '#FEF7E7',
      hovered: '#FEF7E7',
      pressed: '#FEF7E7'
    },

    text: {
      normal: theme.textBlack,
      hovered: theme.textBlack,
      pressed: theme.textBlack
    },

    border: {
      normal: '#FEF7E7',
      hovered: '#FEF7E7',
      pressed: '#FEF7E7'
    }
  },

  filledBlue: {
    fill: {
      normal: theme.blue10,
      hovered: theme.blue10,
      pressed: theme.blue10
    },

    text: {
      normal: theme.textBlack,
      hovered: theme.textBlack,
      pressed: theme.textBlack
    },

    border: {
      normal: theme.blue10,
      hovered: theme.blue10,
      pressed: theme.blue10
    }
  },

  filledGreen: {
    fill: {
      normal: theme.green10,
      hovered: theme.green10,
      pressed: theme.green10
    },

    text: {
      normal: theme.textBlack,
      hovered: theme.textBlack,
      pressed: theme.textBlack
    },

    border: {
      normal: theme.green10,
      hovered: theme.green10,
      pressed: theme.green10
    }
  }
}));

export { $palettes };
