import {
  $$transferOwnerShip,
  ModalSteps
} from '@features/account/transfer-ownership/model';

import { createView } from '@shared/lib/view';

import { Attention } from './attention';

import { Credentials } from './credentials';

import { Done } from './done';

import { SelectMember } from './select-member';

import type { TransferOwnershipProps } from './transfer-ownership.props';

const TransferOwnership = createView<TransferOwnershipProps>()
  .units({ step: $$transferOwnerShip.$step })

  .view(({ step, isEmbedded, onGoPrevious }) => (
    <>
      {step === ModalSteps.SelectMember && (
        <SelectMember isEmbedded={isEmbedded} onGoPrevious={onGoPrevious} />
      )}

      {step === ModalSteps.Credentials && <Credentials />}

      {step === ModalSteps.Attention && <Attention />}

      {step === ModalSteps.Done && <Done />}
    </>
  ));

export { TransferOwnership };
