import classNames from 'classnames';

import { modelView } from 'effector-factorio';

import { ManageCareRecipientDetailsModelFactory } from '@features/care-recipient/manage-details';

import type { ContactDetailsValues } from '@features/user/manage-contact-details';

import { $$dictionary } from '@entities/dictionary';

import { ContactDetailsFields } from '@entities/user';

import type { FormModel } from '@shared/lib/form';

import { createView } from '@shared/lib/view';

import { BoxRow } from '@shared/ui/atoms/box-row';

import { FormSection, FormSectionGroup } from '@shared/ui/atoms/form-section';

import { DateSelectGroupContainer } from '@shared/ui/molecules/date-select-group';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { maxCareRecipientBirth, minCareRecipientBirth } from '../../lib';

import type { CareRecipientDetailsFormProps } from './care-recipient-details-form.props';

import styles from './care-recipient.module.scss';

const CareRecipientDetailsForm = modelView(
  ManageCareRecipientDetailsModelFactory,

  createView<CareRecipientDetailsFormProps>()
    .static({
      minBirth: minCareRecipientBirth,

      maxBirth: maxCareRecipientBirth
    })

    .units({
      genders: $$dictionary.$genders
    })

    .map(() => {
      const { form } = ManageCareRecipientDetailsModelFactory.useModel();

      return {
        form
      };
    })

    .view(({ form, genders, classNames: _classNames, maxBirth, minBirth }) => (
      <Form use={form}>
        <FormSectionGroup>
          <FormSectionGroup>
            <div
              className={classNames(
                styles.fieldGroup,
                styles.name,
                _classNames?.nameRow
              )}
            >
              <Field.Input
                labelGap
                bold
                use={form.fields.firstName}
                label='First name'
                placeholder='Enter'
                errorIn='label'
              />

              <Field.Input
                labelGap
                bold
                use={form.fields.lastName}
                label='Last name'
                placeholder='Enter'
                errorIn='label'
              />
            </div>

            <Field.FormSection
              className={styles.fieldGroup}
              title='Date of birth'
              use={form.fields.dateOfBirth}
            >
              <DateSelectGroupContainer>
                <Field.DateSelectGroup
                  use={form.fields.dateOfBirth}
                  min={minBirth}
                  max={maxBirth}
                />
              </DateSelectGroupContainer>
            </Field.FormSection>

            <FormSection className={styles.fieldGroup} title='Gender'>
              <BoxRow layout='horizontalAlways'>
                <Field.RadioList use={form.fields.gender} options={genders} />
              </BoxRow>
            </FormSection>
          </FormSectionGroup>

          <ContactDetailsFields
            form={form as any as FormModel<ContactDetailsValues>}
          />
        </FormSectionGroup>
      </Form>
    ))
);

export { CareRecipientDetailsForm };
