import { createEvent, sample } from 'effector';

import { routes } from '@shared/routes';

const backToWebsiteClicked = createEvent();

sample({
  clock: backToWebsiteClicked,

  target: routes.auth.signIn.open.prepend(() => ({}))
});

export { backToWebsiteClicked };
