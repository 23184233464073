import { createEffect, createEvent, restore } from 'effector';

import { theme } from './config';

import type { ThemeOptions } from './types';

const setTheme = createEvent<ThemeOptions>();

const setPropertiesFx = createEffect<ThemeOptions, void>();

const $theme = restore(setTheme, theme.colors);

export { $theme, setTheme, setPropertiesFx };
