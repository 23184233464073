import type { ReactNode } from 'react';

import type { StylesConfig } from 'react-select';

import type { ControlProps } from '@shared/lib/types';

import type { LabelProps } from '../label/label.props';

type DefaultOption = {
  id: string;
  name: string;
  icon?: ReactNode;
  disabled?: boolean;
};

type BaseOption = {
  [x: string]: any;
  icon?: ReactNode;
  disabled?: boolean;
};

type SelectProps<T extends BaseOption = DefaultOption> = ControlProps<any> & {
  options: T[];
  getOptionLabel?: (option: T) => any;
  getOptionValue?: (option: T) => any;
  onBlur?: () => void;
  searchable?: boolean;
  wrapperClassName?: string;
  name?: string;
  labelGap?: boolean;
  className?: string;
  bold?: boolean;
  style?: StylesConfig;
  defaultValue?: any;
  label?: ReactNode;
  helper?: ReactNode;
  menuPosition?: 'absolute' | 'fixed';
  menuPlacement?: 'bottom' | 'top' | 'auto';
  mode?: 'select' | 'view' | 'viewBordered';
  placeholder?: ReactNode;
} & Pick<LabelProps, 'plainTextLabel'>;

const customStyles: StylesConfig = {
  menuList: () => ({}),

  indicatorsContainer: () => ({}),
  valueContainer: () => ({}),
  input: () => ({}),
  placeholder: () => ({}),
  singleValue: () => ({}),
  control: () => ({})
};

export type { SelectProps, DefaultOption };

export { customStyles };
