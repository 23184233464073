import { createStore, createEffect, sample } from 'effector';

import type { BillingDetails, PublicBillingDetails } from '@shared/api';

import { carersHQ } from '@shared/api';

const getBillingDetailsFx = createEffect<void, PublicBillingDetails>();

const getFullBillingDetailsFx = createEffect<
  string,
  { password: string; details: BillingDetails }
>();

const createBillingDetailsFx = createEffect<BillingDetails, void>();

const updateBillingDetailsFx = createEffect<BillingDetails, void>();

const $details = createStore<BillingDetails>(null!);

const $publicDetails = createStore<PublicBillingDetails>(null!);

const $$billing = {
  $details,

  $publicDetails,

  getBillingDetailsFx,

  getFullBillingDetailsFx,

  createBillingDetailsFx,

  updateBillingDetailsFx
};

sample({
  clock: [createBillingDetailsFx.done, updateBillingDetailsFx.done],

  target: getBillingDetailsFx
});

$publicDetails.on(
  getBillingDetailsFx.doneData,

  (_, payload) => payload
);

$details
  .on(
    getFullBillingDetailsFx.doneData.map(payload => payload.details),

    (_, payload) => payload
  )
  .reset(updateBillingDetailsFx.doneData);

getBillingDetailsFx.use(async () => {
  const { data } = await carersHQ.billing.details();

  return data;
});

getFullBillingDetailsFx.use(async password => {
  const { data } = await carersHQ.billing.fullDetails({ password });

  return {
    password,
    details: data
  };
});

updateBillingDetailsFx.use(async ({ password, ...details }) => {
  await carersHQ.billing.update({ password, ...details });
});

createBillingDetailsFx.use(async details => {
  await carersHQ.billing.create(details);
});

export { $$billing };
