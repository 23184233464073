import classNames from 'classnames';

import { createView } from '@shared/lib/view';

import { AlertBox } from '@shared/ui/atoms/alert-box';

import { Button } from '@shared/ui/atoms/button';

import { Modal } from '@shared/ui/atoms/modal';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { passwordDetailsForm } from '../../model/password-details';

import styles from './password-confirmation.module.scss';

import { usePasswordConfirmationProps } from './password-confirmation.props';

/**
 * <PasswordConfirmation />
 */
const PasswordConfirmation = createView()
  .map(usePasswordConfirmationProps)

  .view(
    ({
      md,
      visible,
      closed,
      forgotPassword,
      onRecoverClick,
      onPreviousClick,
      onForgotPasswordClick
    }) => {
      if (!visible) return null;

      return (
        <Modal.Container
          className={classNames(styles.modal, {
            [styles.modalForgotPassword]: forgotPassword
          })}
          onClose={() => closed()}
        >
          <Form use={passwordDetailsForm} className={styles.form}>
            {forgotPassword ? (
              <>
                <Modal.Header>Forgot password?</Modal.Header>

                <Modal.Body>
                  <AlertBox
                    className={classNames(styles.alert, styles.alertRecover)}
                  >
                    <h4 className={styles.alertTitle}>Please note:</h4>

                    <p className={styles.alertText}>
                      If you click the recover password button, you will be
                      taken to the password recovery flow and logged out of your
                      account.
                    </p>
                  </AlertBox>
                </Modal.Body>
              </>
            ) : (
              <>
                <Modal.Header>Change billing details</Modal.Header>

                <Modal.Body>
                  <AlertBox className={styles.alert}>
                    <h4 className={styles.alertTitle}>
                      We are concerned about the security of your data
                    </h4>

                    <p className={styles.alertText}>
                      Please enter your account password, this is to keep your
                      payment information secure. <br /> If you have forgotten
                      your account password, you can reset it.
                    </p>
                  </AlertBox>

                  <Field.Input
                    use={passwordDetailsForm.fields.password}
                    className={styles.password}
                    name='password'
                    label='Password'
                    type='password'
                    placeholder='Enter your password'
                    helper={
                      <Button
                        variant='text'
                        palette='text'
                        onClick={onForgotPasswordClick}
                      >
                        Forgot password?
                      </Button>
                    }
                    errorIn={md ? 'label' : 'helperText'}
                  />
                </Modal.Body>
              </>
            )}

            <Modal.Footer className={styles.footer}>
              <Button
                className={styles.cancel}
                palette='text'
                variant='text'
                onClick={() => closed()}
              >
                Cancel
              </Button>

              {forgotPassword ? (
                <>
                  <Button
                    className={styles.cancel}
                    palette='text'
                    variant='text'
                    onClick={onPreviousClick}
                  >
                    {md ? 'Previous step' : 'Previous'}
                  </Button>

                  <Button className={styles.recover} onClick={onRecoverClick}>
                    {md ? 'Recover password' : 'Recover'}
                  </Button>
                </>
              ) : (
                <Button
                  className={styles.continue}
                  onClick={() => passwordDetailsForm.submit}
                >
                  Continue
                </Button>
              )}
            </Modal.Footer>
          </Form>
        </Modal.Container>
      );
    }
  );

export { PasswordConfirmation };
