import { useUnit } from 'effector-react';

import { $$dictionary } from '@entities/dictionary';

import { RelationId } from '@shared/api';

import { createView } from '@shared/lib/view';

import { Button, ButtonGroup } from '@shared/ui/atoms/button';

import { Input } from '@shared/ui/atoms/input';

import { Modal } from '@shared/ui/atoms/modal';

import { Select } from '@shared/ui/atoms/select';

import { Field } from '@shared/ui/organisms/field';

import { styled } from '@shared/ui/stitches';

import type { createEditMemberDetails } from './model';

const EditMemberDetailsModal = createView<{
  use: ReturnType<typeof createEditMemberDetails>;
}>()
  .units({
    relations: $$dictionary.$relationships
  })

  .map(({ use }) => {
    const [recipients, member, onClose, onSubmitClick] = useUnit([
      use.$recipients,
      use.$member,
      use.closed,
      use.form.submit
    ]);

    const { email, firstName, lastName } = member.account;

    const name = firstName ? `${firstName} ${lastName}` : email;

    return {
      name,
      member,
      recipients,
      form: use.form,
      onClose,
      onSubmitClick
    };
  })

  .view(({ relations, form, name, recipients, onClose, onSubmitClick }) => (
    <Modal.Container onClose={onClose}>
      <Modal.Header>Edit details for {name}</Modal.Header>

      <Modal.Body>
        <Rows>
          {recipients.map(item => (
            <Row key={item.id}>
              <Input
                value={`${item.firstName} ${item.lastName}`}
                mode='view'
                label='Contact For'
                onChange={() => {}}
              />

              <Field.Container use={form.fields.relations[item.id]}>
                {({ onChange, value }) => (
                  <Select
                    value={value}
                    onChange={relation => onChange(relation)}
                    options={relations}
                    menuPosition='fixed'
                    disabled={value == RelationId.Myself}
                    label='Relationship to care recipient'
                  />
                )}
              </Field.Container>
            </Row>
          ))}
        </Rows>
      </Modal.Body>

      <Modal.Footer>
        <ButtonGroup>
          <Button variant='text' onClick={onClose}>
            Cancel
          </Button>

          <Button onClick={onSubmitClick}>Save and continue</Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal.Container>
  ));

const Row = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  rowGap: '16px',

  '@md': {
    gridTemplateColumns: '1fr 1fr',
    rowGap: 0,

    columnGap: '20px'
  }
});

const Rows = styled('div', {
  display: 'grid',
  rowGap: '24px',

  '& > *:not(:first-child)': {
    borderTop: '1px solid #e1e3e7',
    paddingTop: '16px',

    '@md': {
      borderTop: 'none',
      paddingTop: 0
    }
  },

  '@md': {
    rowGap: '20px'
  }
});

export { EditMemberDetailsModal };
