import { $$careSpace } from '@entities/care-space';

import { createView } from '@shared/lib/view';

import { Tabs } from '@shared/ui/atoms/tabs';

import { NoCareRecipients } from '../../ui/no-care-recipients';

import styles from './medication.module.scss';

const MedicationPage = createView<any>()
  .units({
    careRecipients: $$careSpace.$careSpace
  })
  .view(({ tabChanged, tab, careRecipients }) => {
    if (!careRecipients.clients.length) {
      <NoCareRecipients />;
    }

    return (
      <div className={styles.tabList}>
        <Tabs
          tabs={[
            { id: 'profile', name: 'Profile' },
            { id: 'medication', name: 'Medication' },
            { id: 'wellness', name: 'Wellness' },
            { id: 'contacts', name: 'Contacts' }
          ]}
          className={styles.tab}
          size='lg'
          onChange={value => tabChanged(value)}
          selected={tab}
          variant='inline'
        />
      </div>
    );
  });

export { MedicationPage };
