const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';

const INVOICE_ACCESS_TOKEN_KEY = 'INVOICE_ACCESS_TOKEN';

const ROLE_KEY = 'ROLE';

const CARE_RECIPIENT_ID_KEY = 'CARE_RECIPIENT_ID';

const CARE_SPACE_ID_KEY = 'CARE_SPACE_ID';

export {
  ROLE_KEY,
  ACCESS_TOKEN_KEY,
  CARE_SPACE_ID_KEY,
  CARE_RECIPIENT_ID_KEY,
  INVOICE_ACCESS_TOKEN_KEY
};
