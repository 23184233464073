import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { DeletedStateInfo } from '@shared/ui/atoms/deleted-state-info';

import styles from './home.module.scss';

const Home = createView().view(() => (
  <div className={styles.home}>
    <DeletedStateInfo
      className={styles.card}
      classNames={{
        option: styles.option,
        title: styles.title,
        subTitle: styles.subTitle,
        photo: styles.photo
      }}
      photo=''
      title='Your account has been deleted'
      subTitle='Thank you for using Carers HQ.'
      description={
        <p className={styles.description}>
          Your account details will be stored <br className={styles.brMobile} />{' '}
          for 5 years. To learn more about
          <br className={styles.brMobile} /> information is available,{' '}
          <a
            target='_blank'
            href='https://google.com'
            rel='noreferrer'
            className={styles.link}
          >
            visit help center
          </a>
          .
        </p>
      }
      options={[
        {
          title: 'Restore your Carers HQ account',
          subTitle: 'Follow the short instruction how to restore your account.',
          route: routes.deletedAccount.restore
        },

        {
          title: 'View your invoices',
          subTitle: 'You can view and download all of your past bills.',
          route: routes.deletedAccount.invoices
        }
      ]}
    />
  </div>
));

export { Home };
