import { createRoutesView } from 'atomic-router-react';

import { modelView } from 'effector-factorio';

import { CreateAccount } from '@pages/sign-up/ui/create-account';

import { StepWizard } from '@pages/sign-up/ui/sign-up-wizard-step';

import { CareNeedsForm } from '@features/care-recipient/manage-care-needs';

import { ConditionExperienceForm } from '@features/care-recipient/manage-condition-experience';

import { PreferencesForm } from '@features/care-recipient/manage-preferences';

import { ContactDetailsForm } from '@features/user/manage-contact-details';

import { PersonalInformationForm } from '@features/user/manage-personal-information';

import { routes } from '@shared/routes';

import { CtaBanner } from '@shared/ui/atoms/cta-banner';

import { useMedia } from '@shared/ui/atoms/media';

import { styled } from '@shared/ui/stitches';

import { $$signUpMyself, SignUpMyselfModelFactory } from './myself.model';

import styles from './myself.module.scss';

const Container = styled('div', {
  flex: 1,

  background: '#fff'
});

const Wrap = styled('br', {
  display: 'none',

  '@lg': {
    display: 'block'
  }
});

const CareNeeds = () => (
  <>
    <CtaBanner letterSpacing>
      <p>
        Let’s find your perfect carer! <br />
        First, tell me about the care you need.
      </p>
    </CtaBanner>

    <Container>
      <StepWizard
        model={$$signUpMyself.wizards.careNeeds}
        key='care-needs'
        title='Care Needs'
      >
        <CareNeedsForm model={$$signUpMyself.steps.careNeeds} />
      </StepWizard>
    </Container>
  </>
);

const ConditionExperience = () => (
  <>
    <CtaBanner letterSpacing>
      <p className={styles.preferences}>
        Now, tell me about any specialist care <br /> you might need.
      </p>
    </CtaBanner>

    <Container>
      <StepWizard
        model={$$signUpMyself.wizards.conditionExperience}
        key='condition-experience'
        title='Condition Experience'
      >
        <ConditionExperienceForm
          model={$$signUpMyself.steps.conditionExperience}
        />
      </StepWizard>
    </Container>
  </>
);

const Preferences = () => {
  const mobile = useMedia('<sm');

  return (
    <>
      <CtaBanner letterSpacing>
        <p className={styles.preferences}>
          Next, please tell me {mobile && <br />} about your <br />
          individual preferences.
        </p>
      </CtaBanner>

      <Container>
        <StepWizard
          model={$$signUpMyself.wizards.preferences}
          key='preferences'
          title='Preferences'
        >
          <PreferencesForm
            classNamePicker={{ wrapper: styles.label, list: styles.languages }}
            model={$$signUpMyself.steps.preferences}
            captions={{
              languages: {
                add: 'Add another'
              }
            }}
          />
        </StepWizard>
      </Container>
    </>
  );
};

const PersonalInformation = () => {
  const mobile = useMedia('<sm');

  return (
    <>
      <CtaBanner letterSpacing>
        <p>
          And now, tell me
          {mobile && <br />} a little more
          <br /> about yourself.
        </p>
      </CtaBanner>

      <Container>
        <StepWizard
          model={$$signUpMyself.wizards.personalInformation}
          key='personal-information'
          title='Personal information'
        >
          <PersonalInformationForm
            model={$$signUpMyself.steps.personalInformation}
          />
        </StepWizard>
      </Container>
    </>
  );
};

const ContactDetails = () => (
  <>
    <CtaBanner letterSpacing>
      <p>
        Great! <br />
        And where can I reach you?
      </p>
    </CtaBanner>

    <Container>
      <StepWizard
        model={$$signUpMyself.wizards.contactDetails}
        key='contact-details'
        title='Contact Details'
      >
        <ContactDetailsForm model={$$signUpMyself.steps.contactDetails} />
      </StepWizard>
    </Container>
  </>
);

const myselfViews = [
  {
    route: routes.signUp.myself.careNeeds,

    view: CareNeeds
  },

  {
    route: routes.signUp.myself.conditionExperience,

    view: ConditionExperience
  },

  {
    route: routes.signUp.myself.preferences,

    view: Preferences
  },

  {
    route: routes.signUp.myself.personalInformation,

    view: PersonalInformation
  },

  {
    route: routes.signUp.myself.contactDetails,

    view: ContactDetails
  },

  {
    route: routes.signUp.myself.createAccount,

    view: () => (
      <CreateAccount
        stepWizardModel={$$signUpMyself.$$createAccountWizard}
        createAccountModel={$$signUpMyself.$$createAccount}
      />
    )
  }
];

const Steps = createRoutesView({
  routes: myselfViews
});

const SignUpMyselfPage = modelView(SignUpMyselfModelFactory, () => <Steps />);

export { SignUpMyselfPage, myselfViews };
