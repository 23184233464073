import { createEffect, createEvent, sample, createStore } from 'effector';

import { object, string } from 'yup';

import { $$careSpace } from '@entities/care-space';

import { $$user } from '@entities/user';

import type { LeaveWorkspaceDTO } from '@shared/api';

import { carersHQ } from '@shared/api';

import { createForm } from '@shared/lib/form';

import { routes } from '@shared/routes';

const $schema = createStore<any>(null);

const form = createForm({
  initialValues: {
    reason: '',
    reasonOther: ''
  },

  $schema
});

const leaveWorkspaceFx = createEffect<
  LeaveWorkspaceDTO & { name: string },
  void
>();

const leaveWorkspace = createEvent<Omit<LeaveWorkspaceDTO, 'workspaceId'>>();

const leaveWorkspaceOpened = createEvent();

const leaveWorkspaceClosed = createEvent();

const $visible = createStore(false);

const $$leaveWorkspace = {
  form,

  $visible,

  leaveWorkspaceOpened,

  leaveWorkspaceClosed,

  leaveWorkspace,

  leaveWorkspaceFx
};

sample({
  source: form.$values,

  fn: values => {
    const fields = {
      reason: string().required().nullable(),

      reasonOther: string().nullable()
    };

    if (values.reason == 'Other') {
      fields.reasonOther = fields.reasonOther
        .trim('Required')

        .required('Required');
    }

    return object(fields);
  },

  target: $schema
});

sample({
  clock: form.submitted,

  fn: ({ reason, reasonOther }) => ({
    reason: reason === 'Other' ? reasonOther : reason
  }),

  target: leaveWorkspace
});

sample({
  clock: leaveWorkspace,

  source: {
    workspaceId: $$careSpace.$careSpaceID,

    careSpace: $$careSpace.$careSpace
  },

  fn: ({ workspaceId, careSpace }, payload) => ({
    ...payload,

    workspaceId,

    name: `${careSpace.owner.firstName} ${careSpace.owner.lastName}`
  }),

  target: leaveWorkspaceFx
});

sample({
  clock: leaveWorkspaceFx.done,

  fn: payload => ({
    name: payload.params.name
  }),

  target: routes.leaveCareSpace.confirmed.open
});

sample({
  clock: leaveWorkspaceFx.doneData,

  source: $$careSpace.$careSpace,

  target: [$$user.getAccountFx, leaveWorkspaceClosed]
});

sample({
  clock: leaveWorkspaceClosed,

  target: form.reset
});

$visible.on(leaveWorkspaceOpened, () => true).reset(leaveWorkspaceClosed);

leaveWorkspaceFx.use(async payload => {
  await carersHQ.settings.leaveWorkspace(payload);
});

export { $$leaveWorkspace, form };
