import { createEvent, createStore, sample } from 'effector';

import { object, string } from 'yup';

import { createForm } from '@shared/lib/form';

import type { MappedErrors } from '@shared/lib/form/yup';

type CredentialInfo = { email: string; password: string };

const $error = createStore(false);

const $errors = createStore<MappedErrors<CredentialInfo>>({});

const credentialForm = createForm({
  initialValues: {
    email: '',

    password: ''
  },

  $errors,

  $schema: createStore(
    object({
      email: string()
        .required('required')

        .trim()

        .nullable()

        .email('is invalid')

        .label('Email'),

      password: string()
        .required('required')

        .trim()

        .nullable()

        .label('Password')
    })
  )
});

const credentialInfoChanged = createEvent<CredentialInfo>();

const $credentialInfo = createStore<CredentialInfo>({
  email: '',

  password: ''
});

const $$credential = {
  credentialForm,

  $credentialInfo,

  $error,

  credentialInfoChanged
};

sample({
  source: $error,

  fn: error =>
    error
      ? {
          email: 'is incorrect',
          password: 'is incorrect'
        }
      : {},

  target: $errors
});

$credentialInfo.on(credentialInfoChanged, (_state, payload) => payload);

export { $$credential, credentialForm };
