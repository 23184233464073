import classNames from 'classnames';

import type { FC, HTMLAttributes, PropsWithChildren } from 'react';

import styles from './dropdown-box.module.scss';

const DropdownBox: FC<PropsWithChildren<{}>> = ({ children }) => (
  <div className={styles.dropdownBox}>{children}</div>
);

const DropdownBoxContainer: FC<HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => <div className={classNames(styles.container)} {...props} />;

export { DropdownBox, DropdownBoxContainer };
