import type { MomentInput } from 'moment';

import moment from 'moment';

const nameRegEx = /^[a-zA-Z ]*$/;

const phoneRegEx = /^\+?\d+$/;

const passwordRegEx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/;

const postalcodeRegEx = /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i;

// const hourRegEx = /^[a-zA-Z0-9!@#\$%\^\&*\)\;\:\(+=._-]+$/;

const ibanRegEx =
  /[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}/;

const hourRegEx = /^[a-zA-Z0-9 \-\:\;\.\,]+$/;

const withoutCyrilic =
  /^[^-\s][a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/\s-]+$/;

const testMinDate = (min: MomentInput) => (value: MomentInput) => {
  if (!value) return true;

  return moment(value).isSameOrAfter(min);
};

const testMaxDate = (max: MomentInput) => (value: MomentInput) => {
  if (!value) return true;

  return moment(value).isSameOrBefore(max);
};

const testMinExperience = (min: MomentInput) => (value: MomentInput) => {
  if (!value) return true;

  const startDate = moment(value);

  const months = Math.abs(startDate.diff(moment(), 'months'));

  if (months > min) return true;

  return false;
};

const jsonEqual = (a: object, b: object) =>
  JSON.stringify(a) == JSON.stringify(b);

export {
  testMaxDate,
  testMinDate,
  nameRegEx,
  ibanRegEx,
  phoneRegEx,
  passwordRegEx,
  postalcodeRegEx,
  hourRegEx,
  withoutCyrilic,
  jsonEqual,
  testMinExperience
};
