import { sample } from 'effector';

import { not } from 'patronum';

import { $$signUpMyself } from '@pages/sign-up/pages/myself';

import { $$signUpPage } from '@pages/sign-up/sign-up.model';

import { $$signUp } from '@features/auth/sign-up';

import { $$session } from '@entities/session';

import type { SignUpClientParams } from '@shared/api';

import { ErrorCode } from '@shared/api';

import { routes } from '@shared/routes';

import { $myselfValues, myselfValuesToDto } from './shared';

const createAccountSubmitted = sample({
  clock: $$signUpMyself.$$createAccount.form.submitted,

  source: {
    values: $myselfValues
  },

  filter: not($$session.$active),

  fn: ({ values }, { email, password }) =>
    ({
      email,
      password,
      ...myselfValuesToDto(values)
    } as SignUpClientParams)
});

sample({
  clock: createAccountSubmitted,

  target: $$signUp.signUpMyselfFx
});

sample({
  clock: $$signUp.signUpMyselfFx.failData,

  fn: () => true,

  target: $$signUpPage.$hasAnAccount
});

sample({
  clock: $$signUp.signUpMyselfFx.failData,

  source: $myselfValues,

  filter: (_, code) => code == ErrorCode.EmailAlreadyInUse,

  fn: values => values.personalInformation.firstName,

  target: $$signUpPage.$firstName
});

sample({
  clock: routes.signUp.myself.createAccount.closed,

  target: $$signUpMyself.$$createAccount.form.reset.prepend(() => {})
});
