import { createEvent, createStore, sample } from 'effector';

import { lazy, object, string } from 'yup';

import { createForm } from '@shared/lib/form';

type ReasonValues = { reason: string; reasonOther: string };

const reasonForm = createForm({
  initialValues: {
    reason: '',

    reasonOther: ''
  },

  $schema: createStore(
    lazy(values => {
      const fields = {
        reason: string().required().nullable(),

        reasonOther: string().nullable()
      };

      if (values.reason == 'Other') {
        fields.reasonOther = fields.reasonOther
          .trim('Required')

          .required('Required');
      }

      return object(fields);
    })
  )
});

const reasonChanged = createEvent<ReasonValues>();

const $reason = createStore<ReasonValues>({
  reason: '',
  reasonOther: ''
});

const $$reason = {
  reasonForm,

  $reason,

  reasonChanged
};

sample({
  clock: $$reason.reasonForm.submitted,

  target: $$reason.reasonChanged
});

$reason.on(reasonChanged, (_state, payload) => payload);

export { $$reason, reasonForm };
