import { useMemo } from 'react';

import { $hasOwnDeletedCareSpace } from '@entities/_computed/care-space';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { Modal } from '@shared/ui/atoms/modal/modal.component';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { $$createWorkspace, CreateWorkspaceType, form } from '../model';

import styles from './create-workspace.module.scss';

const CreateWorkspace = createView()
  .units({
    canRestoreWorkspace: $hasOwnDeletedCareSpace,

    workspaceVisibilityClosed: $$createWorkspace.workspaceVisibilityClosed
  })

  .view(({ canRestoreWorkspace, workspaceVisibilityClosed }) => {
    const radioValues = useMemo(
      () => [
        {
          value: CreateWorkspaceType.Restore,
          title: 'Restore your previous Care Space',
          text: 'We will restore-form all your contacts, members, history, etc.',
          active: canRestoreWorkspace
        },
        {
          value: CreateWorkspaceType.New,
          title: 'Create as a new',
          text: 'We will create a completely new Care Space.',
          active: true
        }
      ],
      [canRestoreWorkspace]
    );

    return (
      <Modal.Container
        className={styles.modal}
        onClose={() => workspaceVisibilityClosed()}
      >
        <Modal.Header className={styles.header}>
          Create your own Care Space
        </Modal.Header>

        <Form className={styles.wrapper} use={form}>
          <Modal.Body className={styles.content}>
            <p className={styles.infoText}>
              Please choose how you want to create your Care Space{' '}
            </p>

            <div className={styles.group}>
              {radioValues.map(
                ({ text, title, value, active }) =>
                  active && (
                    <Field.CheckboxAlertBox
                      use={form.fields.createType}
                      key={value}
                      classNames={{
                        alertBox: styles.alertBox,
                        description: styles.description,
                        header: styles.hideMore
                      }}
                      type='radio'
                      payload={value}
                      title={title}
                      text={text}
                    />
                  )
              )}
            </div>
          </Modal.Body>

          <Modal.Footer className={styles.footer}>
            <Button
              onClick={() => workspaceVisibilityClosed()}
              palette='text'
              variant='text'
              className={styles.cancel}
            >
              Cancel
            </Button>

            <Button onClick={() => form.submit} palette='primary'>
              Create my Care Space
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Container>
    );
  });

export { CreateWorkspace };
