const updateAtCondition = <T>(
  list: T[],
  match: (item: T) => boolean,
  update: (item: T) => T
) => {
  list.map(item => {
    if (!match(item)) return item;

    return update(item);
  });
};

export { updateAtCondition };
