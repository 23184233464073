import type { FC } from 'react';

import { useMemo } from 'react';

import type { Carer, SupportType } from '@shared/api';

import { RateType } from '@shared/api/models/care-rate';

import type { FieldModel } from '@shared/lib/form';

import { useField } from '@shared/lib/form';

import { createView } from '@shared/lib/view';

import { CheckboxList } from '@shared/ui/molecules/checkbox-list';

import { Field } from '@shared/ui/organisms/field';

type SupportTypesProps = {
  supportTypes: SupportType[];

  use: FieldModel<Carer['supportTypes']>;
};

const SupportTypesList = createView<SupportTypesProps>()
  .map(({ use, supportTypes }) => {
    const {
      onBlur,
      onFocus,
      hasError,
      value: _value,
      shouldShowValidation,
      onChange: onFieldChange
    } = useField(use);

    const value = _value.map(item => item.supportType.id);

    const options = useMemo(
      () =>
        supportTypes.map(({ id, name }) => ({
          id,
          name
        })),

      [supportTypes]
    );

    const onChange = (ids: number[]) => {
      onFieldChange(
        ids.map(id => {
          const match = _value.find(one => one.id == id);

          return {
            id,

            supportType: {
              id,
              name: ''
            },

            rates: match?.rates ?? [
              {
                rate: '',

                rateType: RateType.default
              },

              {
                rate: '',

                rateType: RateType.holidays
              }
            ]
          };
        })
      );
    };

    return {
      value,
      onBlur,
      onFocus,
      options,
      onChange,
      hasError: hasError && shouldShowValidation
    };
  })

  .view(({ value, options, onChange, hasError, onFocus, onBlur }) => (
    <CheckboxList
      value={value}
      onBlur={onBlur}
      onFocus={onFocus}
      options={options}
      hasError={hasError}
      onChange={value => onChange(value as number[])}
    />
  ));

const RateFields: FC<{
  name: string;
  mobile?: boolean;
  use: [FieldModel<string>, FieldModel<string>];
}> = ({ name, mobile, use: [daily, weekend] }) => (
  <>
    <Field.CurrencyInput
      use={daily}
      label={`${name} / hr`}
      errorIn='label'
      nowrap
      placeholder={null}
    />

    <Field.CurrencyInput
      use={weekend}
      label={mobile ? 'Holidays / hr' : `${name} holidays / hr`}
      errorIn='label'
      nowrap
      placeholder={null}
    />
  </>
);

export { SupportTypesList, RateFields };
