import classNames from 'classnames';

import { useStore } from 'effector-react';

import type { FC } from 'react';

import { useEffect, useState } from 'react';

import { Bar, BarChart, Brush, CartesianGrid, XAxis, YAxis } from 'recharts';

import { $windowSize } from '@shared/lib/global-events';

import styles from './chart.module.scss';

import type { ChartProps } from './chart.props';

import { CheckMarkIcon, ExclamationMarkIcon } from './icons';

import { Placeholder } from './placeholder/placeholder.component';

import { Title } from './title/title.component';

const CustomTrick = ({
  index,
  payload,
  dataSource,
  tickFormatter,
  visibleTicksCount,
  verticalAnchor,
  ...args
}: any) => (
  <text {...args} y={args.y + 10} className={styles.chartCustomTrick}>
    <tspan x={args.x} dy='0.6em'>
      {(
        dataSource.find((data: any) => data.name === payload?.value)
          .day as string
      )?.substr(0, 2)}
    </tspan>
    <tspan x={args.x} y={args.y + 22} dy='1.2em'>
      {payload.value}
    </tspan>
  </text>
);

const Chart: FC<ChartProps> = ({
  emptyVariant,
  dataSource,
  height,
  barLabelField,
  barColor,
  barField,
  description,
  title,
  labelColor,
  active,
  type,
  maxLength,
  interval
}) => {
  let content: JSX.Element;

  const [chartWidth, setChartWidth] = useState(0);
  const screen = useStore($windowSize);

  useEffect(() => {
    const wellnessWidth = document.getElementById('wellness').clientWidth;
    const width = wellnessWidth - 2 * 16 + (dataSource.length === 7 ? 36 : 0);
    const isDesktop = screen.width > 1172;

    setChartWidth(
      width * (Math.floor(dataSource.length / (isDesktop ? 14 : 7)) || 1)
    );
  }, [screen, dataSource]);

  switch (type) {
    case 'Bar':
      content = (
        <div className={styles.barWrapper}>
          <div className={styles.barContainer}>
            <BarChart
              width={chartWidth}
              height={height}
              data={dataSource}
              className={styles.rechartsWrapper}
            >
              <CartesianGrid strokeDasharray='3 3' vertical={false} />

              <XAxis
                dataKey={barLabelField}
                axisLine={false}
                tickLine={false}
                allowDecimals={false}
                interval={0}
                allowDuplicatedCategory
                tick={<CustomTrick dataSource={dataSource} />}
                height={40}
              />

              <YAxis
                axisLine={false}
                tickLine={false}
                tickCount={maxLength + 1}
                allowDecimals={false}
                interval={interval}
                tick={{ fill: '#667992', fontSize: '12px' }}
              />

              <Brush dataKey='name' height={30} stroke={barColor} />

              <Bar
                dataKey={barField}
                fill={barColor}
                radius={20}
                barSize={24}
              />
            </BarChart>
          </div>
        </div>
      );
      break;
    case 'Boolean':
      content = (
        <div className={styles.booleanChartSection}>
          {dataSource?.map((item, index) => (
            <div
              key={index}
              className={classNames(styles.booleanChartItem, {
                [styles.booleanChartHideIcon]: item?.value === null
              })}
            >
              {item?.value !== null && (
                <div className={styles.booleanChartIcon}>
                  {item?.value ? <CheckMarkIcon /> : <ExclamationMarkIcon />}
                </div>
              )}

              <div className={styles.booleanChartDay}>{item?.day}</div>

              <div className={styles.booleanChartDate}>{item?.name}</div>
            </div>
          ))}
        </div>
      );
      break;
  }

  return (
    <div className={styles.chart}>
      {!!title && (
        <Title
          title={title}
          description={description}
          barColor={barColor}
          labelColor={labelColor}
        />
      )}

      {dataSource?.length > 0 ? (
        content
      ) : (
        <Placeholder active={active} variant={emptyVariant} />
      )}
    </div>
  );
};

Chart.defaultProps = {
  height: 330,
  interval: 1,
  maxLength: 10,
  barLabelField: 'name',
  barField: 'value'
};

export { Chart };
