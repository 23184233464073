import { createEvent, createStore, createEffect, sample } from 'effector';

import { $$checkUserCredentials } from '@features/user/credentials/check';

import { $$credential } from '@entities/account/ui/delete-account/credentials/credentials.model';

import { $$reason } from '@entities/account/ui/delete-account/reason/reason.model';

import { $$careSpace } from '@entities/care-space';

import type { DeleteWorkspaceDTO } from '@shared/api';

import { carersHQ } from '@shared/api';

import { ToastID } from '@shared/config';

import type { NotifyOptions } from '@shared/ui/organisms/toasts';

import { $$toast } from '@shared/ui/organisms/toasts';

const deleteWorkspaceFx = createEffect<DeleteWorkspaceDTO, void>();

const stepChanged = createEvent<string>();

const modalOpened = createEvent();

const modalClosed = createEvent();

const submit = createEvent();

const sendEmail = createEvent();

const $step = createStore<string>('confirm');

const $visible = createStore(false);

const $$deleteWorkspace = {
  $visible,

  $step,

  modalOpened,

  submit,

  modalClosed,

  sendEmail,

  stepChanged
};

sample({
  clock: $$reason.reasonForm.submitted,

  source: $visible,

  filter: visible => visible,

  fn: () => 'attention-access',

  target: stepChanged
});

sample({
  clock: $$credential.credentialForm.submitted,

  fn: ({ password, email }) => ({ password, email }),

  target: [
    $$checkUserCredentials.checkCredentialsFx,
    $$credential.credentialInfoChanged
  ]
});

sample({
  clock: $$checkUserCredentials.checkCredentialsFx.doneData,

  source: $visible,

  filter: visible => visible,

  fn: () => 'attention-appointments',

  target: stepChanged
});

sample({
  clock: $$checkUserCredentials.checkCredentialsFx.doneData,

  fn: () => ({
    email: '',
    password: ''
  }),

  target: $$credential.credentialForm.patch
});

$$credential.$error
  .on($$checkUserCredentials.checkCredentialsFx.failData, () => true)

  .reset(modalClosed, $$checkUserCredentials.checkCredentialsFx.doneData);

sample({
  clock: modalClosed,

  target: [
    $$credential.credentialForm.reset.prepend(() => {}),
    $$reason.reasonForm.reset.prepend(() => {})
  ]
});

sample({
  clock: submit,

  source: {
    workspaceId: $$careSpace.$careSpaceID,
    credentialInfo: $$credential.$credentialInfo,
    reason: $$reason.$reason
  },

  fn: ({ workspaceId, credentialInfo, reason }) => ({
    email: credentialInfo?.email,
    password: credentialInfo?.password,
    workspaceId,
    reason: reason?.reason || reason?.reasonOther
  }),

  target: deleteWorkspaceFx
});

sample({
  clock: deleteWorkspaceFx.doneData,

  fn: () => 'done',

  target: stepChanged
});

sample({
  clock: sendEmail,

  target: modalClosed
});

sample({
  clock: sendEmail,

  fn: () =>
    ({
      id: ToastID.DeleteAccount,

      type: 'success',

      title: 'Your own Care Space has been deleted',

      content:
        '<div style="word-break: break-word">You can always create your own Care Space on the settings page.</div>'
    } as NotifyOptions),

  target: $$toast.notifyFx
});

$step
  .on(stepChanged, (_state, payload) => payload)
  .reset([modalClosed, sendEmail]);

$visible.on(modalOpened, () => true).reset(modalClosed);

$$reason.$reason.reset(modalClosed);

$$credential.$credentialInfo.reset(modalClosed);

deleteWorkspaceFx.use(async payload => {
  await carersHQ.settings.deleteWorkspace(payload);
});

export { $$deleteWorkspace };
