import classNames from 'classnames';

import type { FC } from 'react';

import { Text } from '@shared/ui/atoms/text';

import styles from './switch.module.scss';

import type { SwitchProps } from './switch.props';

const Switch: FC<SwitchProps> = ({
  label,
  disabled,
  hasError,
  value,
  onChange,
  className
}) => (
  <div
    className={classNames(styles.switch, className, {
      [styles.disabled]: disabled,
      [styles.error]: hasError
    })}
    onClick={() => onChange(!value)}
  >
    <label className={classNames(styles.slider, { [styles.checked]: value })}>
      <div className={styles.indicator} />
    </label>

    {label && (
      <Text className={styles.label} variant='body-1'>
        {label}
      </Text>
    )}
  </div>
);

Switch.defaultProps = {
  disabled: false,
  hasError: false
};

export { Switch };
