import { $$signUpMyself } from '@pages/sign-up/pages/myself';

import { routes } from '@shared/routes';

import { createMyselfStep } from './shared';

createMyselfStep({
  key: 'personalInformation',

  form: $$signUpMyself.steps.personalInformation.form,

  route: routes.signUp.myself.personalInformation,

  nextRoute: routes.signUp.myself.contactDetails,

  mapValues: values => values.personalInformation,

  processValues: values => values,

  checkValues: values => Boolean(values.preferences)
});
