import { createEvent, createStore } from 'effector';

const createEditableSection = (defaultValue = false) => {
  const $opened = createStore(defaultValue);

  const open = createEvent();

  const close = createEvent();

  $opened.on(open, () => true).reset(close);

  return {
    $opened,

    open,

    close
  };
};

export { createEditableSection };
