import { Link } from 'atomic-router-react';

import classNames from 'classnames';

import { $$transferOwnerShip } from '@features/account/transfer-ownership';

import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { Button } from '@shared/ui/atoms/button';

import { Modal } from '@shared/ui/atoms/modal';

import styles from './done.module.scss';

const Done = createView()
  .units({
    onCancel: $$transferOwnerShip.modalClosed,
    newOwnerEmail: $$transferOwnerShip.selectMemberForm.fields.email.$value
  })

  .view(({ onCancel, newOwnerEmail }) => (
    <Modal.Container onClose={() => onCancel()} size='small'>
      <Modal.Header>Instruction was sent</Modal.Header>

      <Modal.Body>
        <div className={styles.content}>
          <p>
            We have sent a link to confirm the transfer of the Care Space
            ownership to <br className={styles.brOne} />{' '}
            <span className={classNames(styles.bold, styles.email)}>
              {newOwnerEmail}
            </span>
          </p>

          <p>
            You’ll receive this email within 5 minutes.
            <br className={styles.brTwo} /> Be sure to check your spam
            <br className={styles.brOne} /> folder, too.
          </p>

          <p className={styles.bold}>
            If you have any questions, please{' '}
            <Link
              to={routes.settings.account}
              className={styles.link}
              onClick={() => onCancel()}
            >
              get in touch with us.
            </Link>
          </p>
        </div>
      </Modal.Body>

      <Modal.Footer className={styles.footer}>
        <Button onClick={() => onCancel()}>Continue</Button>
      </Modal.Footer>
    </Modal.Container>
  ));

export { Done };
