import { createStore } from 'effector';

import { persist } from 'effector-storage/session';

import type { CareNeedsValues } from '@features/care-recipient/manage-care-needs';

import type { ConditionExperienceValues } from '@features/care-recipient/manage-condition-experience';

import type { PreferencesValues } from '@features/care-recipient/manage-preferences';

import type { ContactDetailsValues } from '@features/user/manage-contact-details';

import type { PersonalInformationValues } from '@features/user/manage-personal-information';

import { routes } from '@shared/routes';

import { createSignUpStepModelFactory } from '../model';

type Values = {
  personalInformation: PersonalInformationValues;

  contactDetails: ContactDetailsValues;

  preferences: PreferencesValues;

  conditionExperience: ConditionExperienceValues;

  careNeeds: CareNeedsValues;
};

const $myselfValues = createStore<Values>({
  personalInformation: null,

  contactDetails: null,

  preferences: null,

  conditionExperience: null,

  careNeeds: null
});

const createMyselfStep = createSignUpStepModelFactory({
  $values: $myselfValues,

  fallbackRoute: routes.signUp.myself.careNeeds
});

persist({
  key: 'SIGN_UP_MYSELF_VALUES',

  store: $myselfValues
});

$myselfValues.reset(
  routes.signUp.myself.anyWithPostcode.closed,
  routes.signUp.myself.postcode.opened
);

function myselfValuesToDto({
  careNeeds,
  preferences,
  contactDetails,
  conditionExperience,
  personalInformation
}: Values) {
  return {
    phone: contactDetails.phone,
    lastName: personalInformation.lastName,
    firstName: personalInformation.firstName,
    client: {
      postCode: contactDetails.postCode,
      city: contactDetails.city,
      addressLine1: contactDetails.addressLine1,
      addressLine2: contactDetails.addressLine2,
      phone: contactDetails.phone,
      firstName: personalInformation.firstName,
      dateOfBirth: personalInformation.dateOfBirth,
      lastName: personalInformation.lastName,
      gender: personalInformation.gender
        ? { id: personalInformation.gender }
        : null,
      conditionsTypes: conditionExperience.conditionsTypes.map(id => ({
        id
      })),
      careStart: '2021-07-14T15:47:30',
      careStartDate: '2021-07-14T15:47:30',
      careEndDate: '2021-07-14T15:47:30',
      carerGender: preferences.carerGender
        ? { id: preferences.carerGender }
        : null,
      languages: preferences.languages,
      smoking: preferences.smokingOk,
      pets: preferences.pets.map(id => ({ id })),
      transportationHelp: preferences.transportationHelp
        ? { id: preferences.transportationHelp }
        : null,

      services: careNeeds.services.map(id => ({ id })),
      visitCareType: careNeeds.visitCareType,
      careTypes: careNeeds.careTypes,
      startingFromType: careNeeds.startingFromType,
      startingFromDate: careNeeds.startingFromDate,
      nightCareTypes: careNeeds.nightCareTypes
    }
  };
}

export { $myselfValues, createMyselfStep, myselfValuesToDto };
