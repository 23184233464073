import type { Store } from 'effector';

import { attach, createEvent, sample } from 'effector';

import { $$careRecipient } from '@entities/care-recipient';

import { $$carerMatches } from '@entities/carer';

import { routes } from '@shared/routes';

const shortNoticeChanged = createEvent<boolean>();

const loadMore = createEvent();

const $params: Store<{ page: number; shortNotice: boolean }> =
  routes.matches.$query.map(query => ({
    page: Number(query.page ?? 0),
    shortNotice:
      typeof query.shortNotice == 'boolean'
        ? query.shortNotice
        : query.shortNotice == 'true'
  }));

const getCarersFx = attach({
  effect: $$carerMatches.getCarerMatchesFx,

  source: {
    id: $$careRecipient.$careRecipientID,

    params: $params
  },

  mapParams: ({ page }, { id, params: { shortNotice } }) => ({
    id,
    page,
    shortNotice
  })
});

const $$carerMatchesPage = {
  $params,

  loadMore,
  shortNoticeChanged
};

sample({
  clock: loadMore,

  source: $params,

  fn: params => ({
    params: {},
    query: { ...params, page: params.page + 1 }
  }),

  target: routes.matches.navigate
});

sample({
  clock: shortNoticeChanged,

  source: $params,

  fn: (params, shortNotice) => ({
    params: {},
    query: { ...params, shortNotice }
  }),

  target: routes.matches.navigate
});

sample({
  clock: [routes.matches.opened.map(payload => payload.query), $params.updates],

  fn: params => ({
    page: Number(params.page ?? 0),
    shortNotice: params.shortNotice ?? false
  }),

  target: getCarersFx
});

sample({
  clock: getCarersFx.doneData,

  target: $$carerMatches.loaded
});

export { $$carerMatchesPage };
