import { $$dropdown } from '@layouts/main-page/model';

import type { Event } from 'effector';

import React from 'react';

import { $$session, RoleType } from '@entities/session';

import { createView } from '@shared/lib/view';

import { Button, ButtonGroup } from '@shared/ui/atoms/button';

import { Modal } from '@shared/ui/atoms/modal';

import { styled } from '@shared/ui/stitches';

import styles from './add-role-confirm.module.scss';

/**
 * <AddRoleModal />
 */
const AddRoleConfirm = createView()
  .units({
    onSwitchClick: $$dropdown.addRoleConfirmed as Event<any>,

    roleType: $$session.$role,

    onClose: $$dropdown.confirmAddRoleClosed as Event<any>
  })

  .view(({ onClose, roleType, onSwitchClick }) => (
    <Modal.Container onClose={onClose} size='small'>
      <Modal.Header>
        Switch to {roleType == RoleType.Carer ? 'client' : 'carer'} account
      </Modal.Header>

      <Content>
        <Description>
          To use Carers HQ as a{' '}
          {roleType == RoleType.Carer ? 'client' : 'carer'}, you will need to
          complete a short <br /> registration process.
        </Description>

        <p>
          We will use your email and current password, city and address details.
        </p>

        <Note>
          Please note: If you want to change them, we will automatically update
          them for your {roleType == RoleType.Carer ? 'carer' : 'client'}{' '}
          account as well.
        </Note>
      </Content>

      <Modal.Footer className={styles.footer}>
        <ButtonGroup>
          <Button variant='text' palette='text' onClick={onClose}>
            Cancel
          </Button>

          <Button onClick={onSwitchClick}>
            Switch to {roleType == RoleType.Carer ? 'client' : 'carer'} account
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal.Container>
  ));

const Content = styled(Modal.Body, {
  fontSize: '15px',
  lineHeight: '24px',

  '& > *:not(:last-child)': {
    mb: '$1.5',

    '@md': {
      mb: '$2'
    }
  }
});

const Note = styled('p', {
  fontWeight: 500
});

const Description = styled('p', {
  '& br': {
    display: 'none',

    '@md': { display: 'inline' }
  }
});

export { AddRoleConfirm };
