import { Link } from 'atomic-router-react';

import { $$session } from '@entities/session';

import { $$user } from '@entities/user';

import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { Button } from '@shared/ui/atoms/button';

import { Modal } from '@shared/ui/atoms/modal/modal.component';

import { $$emailConfirmed } from '../model';

import styles from './email-confirmed.module.scss';

/**
 * <EmailConfirmed />
 */
const EmailConfirmed = createView()
  .units({
    onClose: $$emailConfirmed.closed,

    logged: $$session.$active,

    isInOwnCarespace: $$emailConfirmed.$inOwnCareSpace,

    account: $$user.$account,

    onAddPaymentDetailsClick: $$emailConfirmed.addPaymentDetailsClicked
  })
  .view(({ onClose, logged, onAddPaymentDetailsClick, account }) => (
    <Modal.Container onClose={onClose} size='small'>
      <Modal.Header>Email is confirmed!</Modal.Header>

      <Modal.Body className={styles.content}>
        <p className={styles.description}>
          Thank you for confirmation.{' '}
          {!account?.billingActive && (
            <>Now all that's left is to add payment details.</>
          )}
        </p>

        <p className={styles.notice}>
          Notice: We want to protect our clients and carers from fraud. It's
          safe and free.
        </p>
      </Modal.Body>

      <Modal.Footer className={styles.footer}>
        {logged ? (
          <>
            {!account?.billingActive ? (
              <>
                <Button
                  palette='text'
                  variant='text'
                  className={styles.cancel}
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>

                <Link
                  to={routes.settings.billing}
                  onClick={() => onAddPaymentDetailsClick()}
                >
                  <Button>Add payment details</Button>
                </Link>
              </>
            ) : (
              <Button onClick={() => onClose()}>Continue</Button>
            )}
          </>
        ) : (
          <Button onClick={() => onClose()}>Continue</Button>
        )}
      </Modal.Footer>
    </Modal.Container>
  ));

export { EmailConfirmed };
