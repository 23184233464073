const cutMore = (source: string[] = [], count: number) => {
  let result = source?.slice(0, count).join(', ');

  if (source.length > count) {
    result += ` & ${source.length - count} more`;
  }

  return result;
};

export { cutMore };
