import photo from '@img/banner-avatar.png';

import classNames from 'classnames';

import type { FC, HTMLAttributes } from 'react';

import styles from './cta-banner.module.scss';

type CtaBannerProps = HTMLAttributes<HTMLDivElement> & {
  letterSpacing?: boolean;
};

const CtaBanner: FC<CtaBannerProps> = ({
  children,
  className,
  letterSpacing,
  ...props
}) => (
  <div className={classNames(styles.banner, className)} {...props}>
    <div className={styles.container}>
      <img className={styles.photo} src={photo} />

      <div
        className={classNames(styles.content, {
          [styles.spacing]: letterSpacing
        })}
      >
        {children}
      </div>
    </div>
  </div>
);

export { CtaBanner };
