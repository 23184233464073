import { useUnit } from 'effector-react';

import { useMemo } from 'react';

import { $$members } from '@entities/members';

import { shortener } from '@shared/lib/utils';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { Modal } from '@shared/ui/atoms/modal';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { styled } from '@shared/ui/stitches';

import { $$memberDelete, form } from '../model';

import styles from './member-delete.module.scss';

const MemberDelete = createView()
  .units({
    memberId: $$memberDelete.$memberId,
    onCancel: $$memberDelete.deleteMemberClosed
  })

  .map(({ memberId }) => {
    const members = useUnit($$members.$members);

    const member = useMemo(() => {
      if (isNaN(memberId)) {
        return members.find(({ account: { email } }) => email === memberId);
      }

      return members.find(({ id }) => id === parseInt(memberId));
    }, [members, memberId]);

    const onSubmit = () => {
      $$memberDelete.setSelectedMember({
        id: member?.account?.id,
        email: member?.account?.email,
        firstName: member?.account?.firstName,
        lastName: member?.account?.lastName
      });

      form.submit();
    };

    return { member, onSubmit };
  })

  .view(({ member, onCancel, onSubmit }) => {
    let memberView =
      member?.account?.firstName && member?.account.lastName
        ? `${member?.account?.firstName} ${member?.account?.lastName}`
        : member?.account?.email;

    memberView = shortener(memberView, 20);

    return (
      <Modal.Container onClose={onCancel}>
        <Modal.Header>
          {`Delete ${memberView} from your Care Space?`}
        </Modal.Header>
        <Modal.Body>
          <Form className={styles.form} use={form}>
            <p className={styles.normal}>
              Are you sure you want to delete {memberView} from your Care Space?
              <br />
              We'll delete all data that connected with {memberView}.
            </p>

            <EmailNotice className={styles.normal}>
              You can add a message that {memberView} will see in email.
            </EmailNotice>

            <Field.Input
              use={form.fields.reason}
              name='reason'
              placeholder='Example: Thank you for your help and care.'
            />

            <div className={styles.cancelMessage}>
              Changed your mind? No problem.
              <br /> Just press cancel.
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className={styles.footer}>
          <Button
            onClick={() => onCancel()}
            palette='text'
            variant='text'
            className={styles.cancel}
          >
            Cancel
          </Button>

          <Button onClick={onSubmit} palette='error'>
            Yes, I want to delete
          </Button>
        </Modal.Footer>
      </Modal.Container>
    );
  });

const EmailNotice = styled('div', {
  marginBottom: '20px !important'
});

export { MemberDelete };
