import type { Effect, Event, Store } from 'effector';

import { createEvent } from 'effector';

import { modelFactory } from 'effector-factorio';

type Options = {
  $total: Store<number>;

  $current: Store<number>;

  nextClicked?: Event<void> | Effect<any, any>;

  backClicked?: Event<void> | Effect<any, any>;
};

const StepWizardModelFactory = modelFactory(
  ({
    $total,
    $current,
    nextClicked = createEvent(),
    backClicked = createEvent()
  }: Options) => ({
    $total,
    $current,

    nextClicked: nextClicked.prepend(() => {}),
    backClicked: backClicked.prepend(() => {})
  })
);

export { StepWizardModelFactory };
