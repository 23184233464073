import avatar from '@img/circle-avatar.png';

import { $$restore } from '@features/deleted-account/restore-form';

import { RestoreForm } from '@features/deleted-account/restore-form/ui';

import { $$deletedAccount } from '@entities/deleted-account';

import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { Breadcrumbs } from '@shared/ui/atoms/breadcrumbs';

import { Card } from '@shared/ui/atoms/card';

import styles from './restore.module.scss';

const Restore = createView()
  .units({
    restoreAccountSent: $$deletedAccount.$restoreAccountSent,

    email: $$restore.form.fields.email.$value
  })
  .view(({ email, restoreAccountSent }) => (
    <div className={styles.restore}>
      <Breadcrumbs
        className={styles.breadcrumbs}
        steps={[
          { label: 'Account', route: routes.deletedAccount.home },
          { label: 'Restore account', route: routes.deletedAccount.restore }
        ]}
      />

      {restoreAccountSent ? (
        <Card className={styles.success}>
          <img className={styles.successPhoto} src={avatar} />

          <h4 className={styles.successTitle}>Instructions sent</h4>

          <p className={styles.successText}>
            Instruction for reseting your account have been sent to{' '}
            <span className={styles.sentTo}> {email}</span>
          </p>

          <p className={styles.successText}>
            You’ll receive this email within 5 minutes.
            <br /> Be sure to check your spam folder.
          </p>
        </Card>
      ) : (
        <RestoreForm />
      )}
    </div>
  ));

export { Restore };
