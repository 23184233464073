import { createStore, sample } from 'effector';

import { modelFactory } from 'effector-factorio';

import moment from 'moment';

import { boolean, number, object, string } from 'yup';

import type { WorkingDocument } from '@shared/api';

import { createForm } from '@shared/lib/form';

import { testMaxDate, testMinDate, testMinExperience } from '@shared/lib/utils';

type EmploymentDetailsValues = {
  carerStart: string;
  preferences: boolean;
  workDocument: WorkingDocument['id'];
};

const ManageEmploymentDetailsModelFactory = modelFactory(() => {
  const $dateOfBirth = createStore<string>(null!);

  const $schema = createStore<any>(null);

  const form = createForm<EmploymentDetailsValues>({
    initialValues: {
      carerStart: null,
      workDocument: null,
      preferences: true
    },

    $schema
  });

  const $dateRange = $dateOfBirth.map(state => ({
    min: moment(state ?? moment().subtract(20, 'years'))
      .add(18, 'years')

      .format(),

    max: moment().format()
  }));

  sample({
    source: $dateRange.updates,

    fn: value => {
      const fields = {
        carerStart: string()
          .required()

          .nullable()

          .test(
            'min-3-months',
            'Should be at least 3 months',
            testMinExperience(3)
          )

          .label('Start date'),

        workDocument: number().required().nullable().label('Work document'),

        preferences: boolean().oneOf([true], 'Required').label('Confirmation')
      };

      if (value) {
        fields.carerStart = fields.carerStart
          .nullable()

          .test('max-date', 'Cant be in future', testMaxDate(value.max))

          .test(
            'min-date',
            'Should be greater than birth date',
            testMinDate(value.min)
          );
      }

      return object(fields);
    },

    target: $schema
  });

  return {
    form,
    $dateRange,
    $dateOfBirth
  };
});

export type { EmploymentDetailsValues };

export { ManageEmploymentDetailsModelFactory };
