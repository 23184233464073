import classNames from 'classnames';

import type { ComponentProps, FC, ReactNode } from 'react';

import React, { useMemo, useState } from 'react';

import { AlertBox } from '@shared/ui/atoms/alert-box';

import { Checkbox } from '@shared/ui/atoms/checkbox';

import { useMedia } from '@shared/ui/atoms/media';

import { Radio } from '@shared/ui/atoms/radio';

import styles from './checkbox-alert-box.module.scss';

import type { CheckboxAlertBoxProps } from './checkbox-alert-box.props';

const CheckboxAlertBox: FC<CheckboxAlertBoxProps> = ({
  title,
  text,
  onChange,
  value,
  hasError,
  type,
  payload,
  hideMore,
  classNames: _classNames
}) => {
  const [visible, setVisible] = useState(false);
  const isSelected = value === payload;

  const md = useMedia('>=md');

  const elementByType = {
    checkbox: {
      alertBoxType: 'warning',
      element: (
        <Checkbox
          className={styles.checkbox}
          onChange={onChange}
          value={value}
          hasError={hasError}
        />
      )
    },

    radio: {
      alertBoxType: isSelected ? 'warning' : 'info',
      element: (
        <Radio
          className={styles.radio}
          onChange={onChange}
          value={value}
          hasError={hasError}
          payload={payload}
        />
      )
    },

    default: {
      alertBoxType: 'warning',
      element: null as ReactNode
    }
  };

  const { element, alertBoxType } = useMemo(
    () => elementByType[type ?? 'checkbox'],

    [type, isSelected]
  );

  const onAlertBoxClick = () => {
    if (type === 'checkbox') return;

    onChange?.(payload as boolean);
  };

  return (
    <AlertBox
      className={classNames(styles.checkboxAlertBox, _classNames?.alertBox, {
        [styles.radioType]: type === 'radio',
        [styles.radioTypeSelected]: isSelected
      })}
      variant={
        hasError
          ? 'error'
          : (alertBoxType as ComponentProps<typeof AlertBox>['variant'])
      }
      onClick={onAlertBoxClick}
    >
      {(type === 'radio' || md) && element}

      <div className={styles.text}>
        <div className={styles.header}>
          {!(type === 'radio' || md) && element}

          <div>
            <div className={styles.title}>
              <span>{title}</span>
            </div>

            <div
              className={classNames(styles.more, _classNames?.header, {
                [styles.moreVisible]: visible,
                [styles.moreHidden]: hideMore
              })}
              onClick={() => setVisible(!visible)}
            >
              {hideMore && visible ? 'Hide' : 'Show'} details
            </div>
          </div>
        </div>

        <p
          className={classNames(styles.description, _classNames?.description, {
            [styles.descriptionVisible]: !hideMore || visible,
            [styles.descriptionRadioType]: type === 'radio'
          })}
        >
          {text}
        </p>
      </div>
    </AlertBox>
  );
};

CheckboxAlertBox.defaultProps = {
  type: 'checkbox',
  payload: false
};

export { CheckboxAlertBox };
