import { createEffect, createEvent, createStore, sample } from 'effector';

import { modelFactory } from 'effector-factorio';

import { object, string } from 'yup';

import { carersHQ } from '@shared/api';

import { createForm } from '@shared/lib/form';

import { postalcodeRegEx } from '@shared/lib/utils';

type CheckPostcodeParams = {
  postcode: string;
};

const PostcodeCheckFactory = modelFactory(() => {
  const reset = createEvent();

  const checkPostcodeFx = createEffect<CheckPostcodeParams, void>();

  const form = createForm({
    initialValues: {
      postcode: ''
    },

    $schema: createStore(
      object({
        postcode: string()
          .nullable()

          .trim('Required')

          .required()

          .matches(postalcodeRegEx, 'Invalid')

          .label('Postcode')
      })
    )
  });

  sample({
    clock: form.submitted,

    target: checkPostcodeFx
  });

  sample({
    clock: reset,

    target: form.reset.prepend(() => null)
  });

  checkPostcodeFx.use(async payload => {
    await carersHQ.postcode.postcodeCheck(payload);
  });

  return {
    form,
    reset,

    checkPostcodeFx
  };
});

export { PostcodeCheckFactory };
