import { createView } from '@shared/lib/view';

import { $step, $visible } from '../model';

import { Emails } from './emails';

import { Relations } from './relations';

/**
 * <MemberInvite />
 */
const MemberInvite = createView()
  .units({
    step: $step,

    visible: $visible
  })

  .view(({ step }) => (
    <>
      {step == 'emails' && <Emails />}

      {step == 'relations' && <Relations />}
    </>
  ));

export { MemberInvite };
