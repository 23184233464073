import { createStore } from 'effector';

import { array, object, string } from 'yup';

import type { CarerSupportType } from '@shared/api/models/carer-support-type';

import { createForm } from '@shared/lib/form';

type RatesValues = {
  referenceConfirm: boolean;

  supportTypes: CarerSupportType[];
};

const form = createForm<RatesValues>({
  initialValues: {
    supportTypes: [],

    referenceConfirm: false
  },

  $schema: createStore(
    object({
      supportTypes: array(
        object({
          rates: array(
            object({
              rate: string()
                  .required(' ')

                  .nullable()

                  .max(6)

                  .min(1, ' ')
            })
          ).nullable()
        }).nullable()
      )
        .min(1, 'Required')

        .required()

        .nullable()

        .label('Care types')
    })
  )
});

const $$carerRates = {
  form
};

export type { RatesValues };

export { $$carerRates, form };
