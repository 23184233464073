import classNames from 'classnames';

import type { Event } from 'effector';

import { $$password } from '@features/account/password';

import { PasswordForm } from '@features/account/password/ui';

import { $$accountInfo } from '@entities/account';

import { connect, createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { EditableSection } from '@shared/ui/atoms/editable-section';

import { $$accountPage } from '../../account.model';

import styles from './password.module.scss';

const Section = connect(EditableSection)
  .static({
    title: 'Password'
  })

  .units({
    editing: $$accountPage.password.$opened,

    onCancel: $$accountPage.password.close.prepend(() => {}),

    onSave: $$password.form.submit.prepend(() => {})
  })

  .view();

const Password = createView()
  .units({
    account: $$accountInfo.$account,

    onResetClick: $$accountPage.resetPasswordClicked as Event<any>,

    onEdit: $$accountPage.password.open.prepend(() => {})
  })

  .view(({ onEdit, onResetClick }) => (
    <Section
      editable={false}
      controls={{
        save: { text: 'Change password' }
      }}
    >
      {({ editing }) => {
        if (editing) {
          return (
            <>
              <p className={classNames(styles.description, styles.editing)}>
                Can't remember your current password?{' '}
                <Button variant='text' palette='text' onClick={onResetClick}>
                  Reset your password via email
                </Button>
              </p>

              <PasswordForm />
            </>
          );
        }

        return (
          <div className={classNames(styles.fields, styles.wrapper)}>
            <p className={styles.description}>
              If you want to change your password,{' '}
              <Button
                variant='text'
                palette='text'
                className={classNames(styles.link, styles.description)}
                onClick={() => onEdit(false)}
              >
                click here.
              </Button>
            </p>
          </div>
        );
      }}
    </Section>
  ));

export { Password };
