import classNames from 'classnames';

import type { FC, HTMLAttributes } from 'react';

import styles from './line.module.scss';

type LineProps = HTMLAttributes<HTMLDivElement>;

const Line: FC<LineProps> = ({ className, ...props }) => (
  <div className={classNames(styles.line, className)} {...props} />
);

export { Line };
