import { createEvent, createStore, sample } from 'effector';

import { $$loadClientData } from '@processes/boot';

import { $$clientTypeSelection } from '@features/care-recipient/client-type-selection';

import type { CareNeedsValues } from '@features/care-recipient/manage-care-needs';

import { ManageCareNeedsModelFactory } from '@features/care-recipient/manage-care-needs';

import type { ConditionExperienceValues } from '@features/care-recipient/manage-condition-experience';

import { ManageConditionExperienceModelFactory } from '@features/care-recipient/manage-condition-experience';

import type { ManageCareRecipientDetailsValues } from '@features/care-recipient/manage-details';

import { ManageCareRecipientDetailsModelFactory } from '@features/care-recipient/manage-details';

import type { PreferencesValues } from '@features/care-recipient/manage-preferences';

import { ManagePreferencesModelFactory } from '@features/care-recipient/manage-preferences';

import { PostcodeCheckFactory } from '@features/postcode/check';

import { $$careRecipient } from '@entities/care-recipient';

import { valuesToClient } from '@entities/care-recipient/model';

import { $$careSpace, $$member } from '@entities/care-space';

import { $$dictionary } from '@entities/dictionary';

import { $$drafts } from '@entities/drafts';

import { $$user } from '@entities/user';

import { RelationId, StartingFromType, VisitCareType } from '@shared/api';

import { routes } from '@shared/routes';

import { ModalSteps } from './utils';

const $$manageCareNeeds = ManageCareNeedsModelFactory.createModel();

const $$manageConditionExperience =
  ManageConditionExperienceModelFactory.createModel();

const $$manageCareRecipientDetails =
  ManageCareRecipientDetailsModelFactory.createModel();

const $$managePreferences = ManagePreferencesModelFactory.createModel();

const $$checkPostcode = PostcodeCheckFactory.createModel();

const openModal = createEvent();

const closeModal = createEvent();

const stepChanged = createEvent<ModalSteps>();

const $step = createStore<ModalSteps>(ModalSteps.PostcodeCheck);

const $lastStep = createStore<ModalSteps>(ModalSteps.PostcodeCheck);

const $visible = createStore(false);

const $$addCareRecipient = {
  $visible,

  $lastStep,

  $step,

  $$manageCareNeeds,

  $$manageConditionExperience,

  $$manageCareRecipientDetails,

  $$managePreferences,

  $$checkPostcode,

  openModal,

  closeModal,

  stepChanged
};

sample({
  clock: $$checkPostcode.checkPostcodeFx.doneData,

  source: $$member.$members,

  fn: members => {
    const hasMyself = members.some(one =>
      one.account.relations.some(
        relation => relation.clientRelation.id == RelationId.Myself
      )
    );

    return hasMyself ? ModalSteps.CareNeeds : ModalSteps.ClientTypeSelection;
  },

  target: stepChanged
});

sample({
  clock: $$checkPostcode.checkPostcodeFx.done,

  fn: ({ params }) => ({
    postCode: params.postcode
  }),

  target: $$manageCareRecipientDetails.form.patch
});

sample({
  clock: $$clientTypeSelection.form.submitted,

  fn: () => ModalSteps.CareNeeds,

  target: stepChanged
});

sample({
  clock: $$manageCareNeeds.form.submitted,

  fn: () => ModalSteps.ConditionExperience,

  target: stepChanged
});

sample({
  clock: $$manageConditionExperience.form.submitted,

  fn: () => ModalSteps.Preferences,

  target: stepChanged
});

sample({
  clock: $$managePreferences.form.submitted,

  fn: () => ModalSteps.CareRecipientInfo,

  target: stepChanged
});

sample({
  clock: stepChanged,

  filter: step => step !== ModalSteps.Cancel,

  fn: step => step,

  target: $lastStep
});

sample({
  clock: $$manageCareRecipientDetails.form.submitted,

  source: {
    postCode: $$checkPostcode.form.$values,

    careNeeds: $$manageCareNeeds.form.$values,

    clientTypeSelection: $$clientTypeSelection.form.$values,

    conditionExperience: $$manageConditionExperience.form.$values,

    preferences: $$managePreferences.form.$values,

    allServices: $$dictionary.$services,

    genders: $$dictionary.$genders,

    conditionsTypes: $$dictionary.$conditionTypes,

    pets: $$dictionary.$pets,

    transportation: $$dictionary.$transportationHelpTypes
  },

  fn: (
    {
      postCode,
      careNeeds,
      clientTypeSelection,
      conditionExperience,
      preferences,
      allServices,
      genders,
      conditionsTypes,
      pets,
      transportation
    },
    values
  ) => {
    const services = careNeeds.services
      .map((el: number) => allServices.find(item => item.id === el))
      .filter(el => Boolean(el));

    const carerGender = genders.find(el => el.id === preferences.carerGender);

    const gender = genders.find(el => el.id === values.gender);

    const selectedTypes = conditionExperience.conditionsTypes
      .map((el: number) => conditionsTypes.find(item => item.id === el))
      .filter(Boolean);

    const selectedPets = preferences.pets
      .map((el: number) => pets.find(item => item.id === el))
      .filter(Boolean);

    const selectedTransportation = transportation.find(
      item => item.id === preferences.transportationHelp
    );

    return {
      values: {
        ...values,
        ...careNeeds,
        ...preferences,
        ...conditionExperience,
        postCode: postCode.postcode,
        clientType: clientTypeSelection.clientType,
        careTypes: [...new Set(careNeeds.careTypes)],
        services: services,
        carerGender: carerGender,
        gender: gender,
        conditionsTypes: selectedTypes,
        pets: selectedPets,
        transportationHelp: selectedTransportation
      }
    };
  },

  target: $$careRecipient.addCareRecipientFx
});

sample({
  clock: $$careRecipient.addCareRecipientFx.doneData,

  source: $$drafts.$draft,

  filter: draft => Boolean(draft?.id),

  fn: draft => draft.id,

  target: $$drafts.deleteDraftFx
});

sample({
  clock: [
    $$careRecipient.addCareRecipientFx.doneData,
    $$drafts.saveDraftFx.doneData,
    $$drafts.deleteDraftFx.doneData
  ],

  source: $$user.$account,

  fn: ({ id }) => id,

  target: [closeModal, $$drafts.fetchDraftsFx]
});

sample({
  clock: [
    $$careRecipient.addCareRecipientFx.doneData,
    $$drafts.saveDraftFx.doneData
  ],

  source: $$careSpace.$careSpaceID,

  fn: id => id,

  target: [
    closeModal,
    $$careSpace.loadCareSpaceFx,
    $$member.getCareSpaceMembersFx
  ]
});

sample({
  clock: $$careRecipient.addCareRecipientFx.doneData,

  source: $$user.$account,

  fn: account => account,

  target: [$$loadClientData.load, closeModal]
});

sample({
  clock: [
    $$drafts.saveDraftFx.doneData.map(payload => payload.data.id),
    $$careRecipient.addCareRecipientFx.doneData
  ],

  fn: (id: string) => ({
    id
  }),

  target: routes.careRecipients.general.open
});

sample({
  clock: closeModal,

  fn: () =>
    ({
      visitCareType: VisitCareType.VisitingCare,

      startingFromDate: null,

      startingFromType: StartingFromType.SpecificDate,

      services: [],

      careTypes: [],

      nightCareTypes: []
    } as CareNeedsValues),

  target: $$manageCareNeeds.form.reset
});

sample({
  clock: closeModal,

  fn: () =>
    ({
      conditionsTypes: []
    } as ConditionExperienceValues),

  target: $$manageConditionExperience.form.reset
});

sample({
  clock: closeModal,

  fn: () =>
    ({
      pets: [],

      smokingOk: false,

      languages: [{ id: 1, name: 'English' }],

      transportationHelp: null,

      carerGender: null
    } as PreferencesValues),

  target: $$managePreferences.form.reset
});

sample({
  clock: closeModal,

  fn: () =>
    ({
      firstName: '',

      lastName: '',

      dateOfBirth: null,

      gender: 1,

      postCode: '',

      city: '',

      addressLine1: '',

      addressLine2: '',

      phone: ''
    } as ManageCareRecipientDetailsValues),

  target: $$manageCareRecipientDetails.form.reset
});

sample({
  clock: closeModal,

  target: $$checkPostcode.form.reset
});

sample({
  clock: $$drafts.saveAsDraftClicked,

  source: {
    postCode: $$checkPostcode.form.$values,

    careNeeds: $$manageCareNeeds.form.$values,

    clientTypeSelection: $$clientTypeSelection.form.$values,

    conditionExperience: $$manageConditionExperience.form.$values,

    careRecipientDetails: $$manageCareRecipientDetails.form.$values,

    preferences: $$managePreferences.form.$values,

    lastActiveStep: $lastStep,

    allServices: $$dictionary.$services,

    genders: $$dictionary.$genders,

    conditionsTypes: $$dictionary.$conditionTypes,

    pets: $$dictionary.$pets,

    transportation: $$dictionary.$transportationHelpTypes
  },

  fn: ({
    postCode,
    careNeeds,
    clientTypeSelection,
    conditionExperience,
    careRecipientDetails,
    preferences,
    lastActiveStep,
    pets,
    transportation,
    genders,
    conditionsTypes,
    allServices
  }) => {
    const services = careNeeds.services
      .map((el: number) => allServices.find(item => item.id === el))
      .filter(el => Boolean(el));

    const carerGender = genders.find(el => el.id === preferences.carerGender);

    const selectedTypes = conditionExperience.conditionsTypes.map(
      (el: number) => conditionsTypes.find(item => item.id === el)
    );

    const selectedPets = preferences.pets
      .map((el: number) => pets.find(item => item.id === el))
      .filter(Boolean);

    const gender = genders.find(el => el.id === careRecipientDetails.gender);

    const selectedTransportation = transportation.find(
      item => item.id === preferences.transportationHelp
    );

    const requestData = {
      ...careNeeds,
      ...preferences,
      ...conditionExperience,
      ...careRecipientDetails,
      postCode: postCode.postcode,
      gender: gender,
      clientType: clientTypeSelection.clientType,
      transportationHelp: selectedTransportation,
      pets: selectedPets,
      conditionsTypes: selectedTypes,
      carerGender: carerGender,
      services: services
    };

    return {
      client: valuesToClient(requestData),
      lastStep: lastActiveStep
    };
  },

  target: $$drafts.saveDraftFx
});

sample({
  clock: $$drafts.editDraftClicked,

  target: $$drafts.getDraftFx
});

sample({
  clock: $$drafts.getDraftFx.doneData,

  target: openModal
});

sample({
  clock: $$drafts.getDraftFx.doneData,

  fn: ({ postCode }) => ({ postcode: postCode }),

  target: $$checkPostcode.form.patch
});

sample({
  clock: $$drafts.getDraftFx.doneData,

  fn: ({
    startingFromDate,
    startingFromType,
    visitCareType,
    services,
    careTypes,
    nightCareTypes
  }) =>
    ({
      startingFromDate,
      startingFromType,
      visitCareType,
      services: services.map(el => el.id),
      careTypes,
      nightCareTypes
    } as CareNeedsValues),

  target: $$manageCareNeeds.form.patch
});

sample({
  clock: $$drafts.getDraftFx.doneData,

  fn: ({ pets, smoking, languages, carerGender, transportationHelp }) =>
    ({
      pets: pets.map(item => item?.id),
      languages,
      transportationHelp: transportationHelp?.id ?? null,
      smokingOk: smoking,
      carerGender: carerGender?.id ?? null
    } as PreferencesValues),

  target: $$managePreferences.form.reset
});

sample({
  clock: $$drafts.getDraftFx.doneData,

  fn: ({ conditionsTypes }) =>
    ({
      conditionsTypes: conditionsTypes.map(el => el.id)
    } as ConditionExperienceValues),

  target: $$manageConditionExperience.form.patch
});

sample({
  clock: $$drafts.getDraftFx.doneData,

  fn: ({
    firstName,
    lastName,
    dateOfBirth,
    gender,
    postCode,
    city,
    addressLine1,
    addressLine2,
    phone
  }) =>
    ({
      firstName,
      lastName,
      dateOfBirth,
      gender: gender.id,
      postCode,
      city,
      addressLine1,
      addressLine2,
      phone
    } as ManageCareRecipientDetailsValues),

  target: $$manageCareRecipientDetails.form.patch
});

sample({
  clock: $$drafts.getDraftFx.doneData,

  fn: ({ lastStep }) => lastStep ?? ModalSteps.CareNeeds,

  target: stepChanged
});

sample({
  clock: $$careRecipient.addCareRecipientFx.done,

  fn: ({ result }) => result,

  target: $$drafts.cleanupDraftFx
});

$visible.on(openModal, () => true).reset(closeModal);

$step.on(stepChanged, (_state, payload) => payload).reset(closeModal);

$lastStep.reset(closeModal);

export { $$addCareRecipient };
