import { createHttpApi } from 'effector-http-api';

import { enviroment } from '@shared/config';

import type {
  Account,
  BillingDetails,
  Credentials,
  Invoice,
  PublicBillingDetails,
  Response,
  GetInvoicesParams,
  GetInvoicesResponse,
  Carer,
  CarerMatch,
  Client,
  ClientBindings,
  ClientProfileView,
  Dashboard,
  DashboardParams,
  DeleteWorkspaceDTO,
  GetUserWorkspaceRelation,
  GetWorkspaceMembersDTO,
  InviteMembersContract,
  InviteMembersDTO,
  KickFromWorkspaceDTO,
  LeaveWorkspaceDTO,
  PaginatedData,
  PaginationOptions,
  ResendMemberInvitationDTO,
  RestoreWorkspaceDTO,
  SettingsAccountContract,
  TransferWorkspaceOwnershipDTO,
  TransferWorkspaceOwnershipInviteDTO,
  UpdateAccountContract,
  UpdateAccountDTO,
  UpdateMemberNotificationsDTO,
  UpdateMemberRelationsDTO,
  UpdateMemberRoleDTO,
  UpdatePasswordDTO,
  Workspace,
  Entry,
  PostCodeAddressMatchResult,
  CareType,
  Certificate,
  ConditionsType,
  Dbs,
  Gender,
  Language,
  Pet,
  Qualification,
  Role,
  SupportType,
  TransportationHelp,
  WorkingDocument,
  CarerGender,
  Service,
  SignUpCarerParams,
  LoginParamsDto,
  LoginResponseDto,
  SignUpForNewsLetterDto,
  ForgotPasswordDto
} from './models';

const http = createHttpApi({
  baseURL: enviroment.carersHQApiUrl,
  headers: {
    'ngrok-skip-browser-warning': true
  }
});

const accounts = http.createController('/v1/accounts');

const settings = http.createController('/v1/settings');

const billing = http.createController('/v1/billing');

const carer = http.createController('/v1/carer');

const clients = http.createController('/v1/clients');

const auth = http.createController('/v1/auth');

const dictionary = http.createController('/v1/dictionary');

const file = http.createController('/v1/file');

const postcode = http.createController('/v1/postcode');

const invoice = http.createController('/v1/invoice');

const email = http.createController('/v1/email');

const workspaces = http.createController('/v1/workspaces');

const carersHQ = {
  http,

  accounts: {
    current: accounts.createRoute<void, Response<Account>>({
      url: '/current'
    })
  },

  settings: {
    createNewWorkspace: settings.createRoute<
      void,
      Response<{ workspaceId: Workspace['id'] }>
    >({
      url: '/workspace/create',
      method: 'POST'
    }),

    restoreWorkspace: settings.createRoute<RestoreWorkspaceDTO, Response>({
      url: '/workspace/restore',

      method: 'POST'
    }),

    transferWorkspaceOwnership: settings.createRoute<
      TransferWorkspaceOwnershipDTO,
      Response<{ success: boolean }>
    >({
      url: '/workspace/transferOwnership',
      method: 'POST'
    }),

    transferWorkspaceOwnershipInvite: settings.createRoute<
      TransferWorkspaceOwnershipInviteDTO,
      Response<{ success: boolean }>
    >({
      url: '/workspace/transferOwnershipInvite',

      method: 'POST'
    }),

    passwordCheck: settings.createRoute<Credentials, Response<Account>>({
      url: '/passwordCheck',

      method: 'POST'
    }),

    deleteAccount: settings.createRoute<
      Credentials & { reason: string },
      Response<Account>
    >({
      url: '/account',

      method: 'DELETE'
    }),

    restoreAccount: settings.createRoute<Credentials, Response<Account>>({
      method: 'POST',

      url: '/account/restore'
    }),

    getAccount: settings.createRoute<void, Response<SettingsAccountContract>>({
      url: '/account'
    }),

    updatePersonalDetails: settings.createRoute<
      UpdateAccountDTO,
      Response<UpdateAccountContract>
    >({
      url: '/personal',

      method: 'PUT'
    }),

    updatePassword: settings.createRoute<
      UpdatePasswordDTO,
      Response<{ success: boolean }>
    >({
      url: '/password',

      method: 'PUT'
    }),

    updateMemberNotifications: settings.createRoute<
      UpdateMemberNotificationsDTO,
      Response
    >({
      url: '/notification',
      method: 'PUT'
    }),

    leaveWorkspace: settings.createRoute<LeaveWorkspaceDTO, Response>({
      url: '/workspace/leave',

      method: 'DELETE'
    }),

    kickFromWorkspace: settings.createRoute<KickFromWorkspaceDTO, Response>({
      url: '/workspace/kick',
      method: 'DELETE'
    }),

    deleteWorkspace: settings.createRoute<
      DeleteWorkspaceDTO,
      Response<{ success: boolean }>
    >({
      url: '/workspace/delete',
      method: 'DELETE'
    }),

    inviteMembers: settings.createRoute<
      InviteMembersDTO,
      Response<InviteMembersContract>
    >({
      url: '/workspace/invite',
      method: 'POST'
    }),

    resendMemberInvitation: settings.createRoute<
      ResendMemberInvitationDTO,
      Response
    >({
      url: '/workspace/invite/resend',
      method: 'POST'
    })
  },

  billing: {
    details: billing.createRoute<void, Response<PublicBillingDetails>>({
      url: '/'
    }),

    fullDetails: billing.createRoute<
      { password: string },
      Response<BillingDetails>
    >({
      url: '/full',

      method: 'POST'
    }),

    update: billing.createRoute<BillingDetails, Response<BillingDetails>>({
      url: '/',
      method: 'PUT'
    }),

    create: billing.createRoute<BillingDetails, Response<BillingDetails>>({
      url: '/',
      method: 'POST'
    })
  },

  carer: {
    get: carer.createRoute<{ id: string }, Response<Carer>>(({ id }) => ({
      url: `/${id}`
    })),

    matches: carer.createRoute<
      { id: Client['id']; shortNotice?: boolean } & PaginationOptions,
      Response<PaginatedData<CarerMatch>>
    >(({ id, shortNotice }) => ({
      url: `/match/${id}`,
      data: {
        shortNotice
      }
    })),

    update: carer.createRoute<Carer, Response<Carer>>({
      method: 'PUT',

      url: '/'
    }),

    viewClientProfile: carer.createRoute<
      {
        id: ClientProfileView['id'];
      },
      ClientProfileView
    >(({ id }) => ({
      url: `/client/view/${id}`
    })),

    dashboard: carer.createRoute<
      { id: string } & DashboardParams,
      Response<Dashboard[]>
    >(({ id, startDate, endDate, markTypes }) => ({
      url: `/dashboard/${id}`,
      params: {
        startDate,
        endDate,
        markTypes: [...markTypes]
      }
    }))
  },

  clients: {
    addToShort: clients.createRoute<ClientBindings, Response>({
      url: '/shortlist',
      method: 'POST'
    }),

    removeFromShort: clients.createRoute<ClientBindings>({
      url: '/shortlist',
      method: 'PUT'
    }),

    removeConnection: clients.createRoute<ClientBindings>({
      url: '/connections',
      method: 'PUT'
    }),

    addConnection: clients.createRoute<ClientBindings>({
      url: '/connections',
      method: 'POST'
    }),

    getClientCarers: clients.createRoute<
      { id: Client['id']; type: 'team' | 'connections' | 'shortlist' },
      Response<Carer[]>
    >({
      url: `/carers/view`
    }),

    get: clients.createRoute<
      {
        id: Client['id'];
      },
      Response<Client>
    >(({ id }) => ({
      url: `/${id}`
    })),

    update: clients.createRoute<Client, Response<Client>>(client => ({
      url: `/${client?.id}`,

      data: client,

      method: 'PUT'
    }))
  },

  auth: {
    deleteUser: auth.createRoute<
      {
        id: Client['id'];
        password: string;
        reason: string;
      },
      Response
    >({
      url: `/web/deleteUser`,
      method: 'POST'
    }),

    restoreUser: auth.createRoute<{ id: Client['id'] }, Response>({
      url: `/web/restoreUser`,

      method: 'POST'
    }),

    signUpCarer: auth.createRoute<
      SignUpCarerParams,
      Response<LoginResponseDto>
    >({
      method: 'POST',
      url: '/web/signup/carer'
    }),

    preSignUp: auth.createRoute<string, Response<any>>(token => ({
      url: `/web/pre-signup/${token}`
    })),

    signUpInvite: auth.createRoute<any, Response<any>>(data => ({
      method: 'POST',

      url: `/web/signup/simple/${data.token}`,

      data: {
        email: data?.email,
        firstName: data?.firstName,
        lastName: data?.lastName,
        password: data?.password,
        confirmPassword: data?.confirmPassword,
        phone: data?.phone
      }
    })),

    signUpLovedOne: auth.createRoute<any, Response<LoginResponseDto>>({
      method: 'POST',
      url: '/web/signup/lovedOne'
    }),

    signUpMyself: auth.createRoute<any, Response<LoginResponseDto>>({
      method: 'POST',
      url: '/web/signup/myself'
    }),

    addMyself: auth.createRoute<any, Response<{ id: string }>>({
      method: 'POST',
      url: '/web/add/myself'
    }),

    addCarer: auth.createRoute<any, Response<{ id: string }>>({
      method: 'POST',
      url: '/web/add/carer'
    }),

    addLovedOne: auth.createRoute<any, Response<{ id: string }>>({
      method: 'POST',
      url: '/web/add/lovedOne'
    }),

    addMyselfLoggedIn: auth.createRoute<
      { client: Client },
      Response<{ id: string }>
    >({
      method: 'POST',
      url: '/web/signup/add/myself'
    }),

    addLovedOneLoggedIn: auth.createRoute<
      { client: Client },
      Response<{ id: string }>
    >({
      method: 'POST',
      url: '/web/signup/add/lovedone'
    }),

    signUpForNewsLetter: auth.createRoute<
      SignUpForNewsLetterDto,
      Response<LoginResponseDto>
    >({
      method: 'POST',
      url: '/web/connectionRequest'
    }),

    signIn: auth.createRoute<LoginParamsDto, Response<LoginResponseDto>>({
      url: '/web/login',
      method: 'POST'
    }),

    changePassword: auth.createRoute<
      { password: string; token: string },
      Response<LoginResponseDto>
    >({
      url: '/web/restore',
      method: 'POST'
    }),

    forgotPassword: auth.createRoute<
      ForgotPasswordDto,
      Response<LoginResponseDto>
    >({
      url: '/web/forgot',
      method: 'POST'
    }),

    updateEmail: email.createRoute<
      { email: string; password: string },
      Response
    >({
      url: '/update',

      method: 'POST'
    }),

    sendConfirmation: email.createRoute({
      url: '/sendConfirmation',
      method: 'POST'
    }),

    checkCredentials: settings.createRoute<
      Omit<LoginParamsDto, 'rememberMe'>,
      Response<{ success: boolean }>
    >({
      url: '/passwordCheck',
      method: 'POST'
    }),

    loginAsDeleted: invoice.createRoute<
      Credentials,
      Response<{ invoiceAccessToken: string }>
    >({
      url: '/login',
      method: 'POST'
    }),

    getCareRecipientDrafts: auth.createRoute<
      { id: Account['id'] },
      Response<Client[]>
    >(({ id }) => ({
      url: `/web/signup/draft/client?accountId=${id}`
    })),

    getCareRecipientDraft: auth.createRoute<
      { draftId: string },
      Response<Client>
    >(({ draftId }) => ({
      url: `/web/signup/draft/client/${draftId}`
    })),

    saveCareRecipientAsDraft: auth.createRoute<
      {
        lastStep: number;
        client: Client;
      },
      Response<{ id: string; success: boolean }>
    >({
      url: '/web/signup/draft/client',
      method: 'POST'
    }),

    updateCareRecipientDraft: auth.createRoute<
      {
        lastStep: number;
        clientDraftId: string;
        client: Client;
      },
      Response
    >(({ clientDraftId }) => ({
      url: `/web/signup/draft/client/${clientDraftId}`,
      method: 'PUT'
    })),

    deleteCareRecipientDraft: auth.createRoute<{ draftId: string }>(
      ({ draftId }) => ({
        url: `/web/signup/draft/client/${draftId}`,
        method: 'DELETE'
      })
    )
  },

  dictionary: {
    careTypes: dictionary.createRoute<void, Response<CareType[]>>({
      url: '/visitingCareTypes'
    }),

    certificates: dictionary.createRoute<void, Response<Certificate[]>>({
      url: '/certificates'
    }),

    conditionTypes: dictionary.createRoute<void, Response<ConditionsType[]>>({
      url: '/conditionsTypes'
    }),

    dbs: dictionary.createRoute<void, Response<Dbs[]>>({
      url: '/dbs'
    }),

    genders: dictionary.createRoute<void, Response<Gender[]>>({
      url: '/genders'
    }),

    languages: dictionary.createRoute<void, Response<Language[]>>({
      url: '/languages'
    }),

    pets: dictionary.createRoute<void, Response<Pet[]>>({
      url: '/pets'
    }),

    qualifications: dictionary.createRoute<void, Response<Qualification[]>>({
      url: '/qualifications'
    }),

    relationships: dictionary.createRoute<void, Response<Entry[]>>({
      url: '/relations'
    }),

    roles: dictionary.createRoute<void, Response<Role[]>>({
      url: '/roles'
    }),

    supportTypes: dictionary.createRoute<void, Response<SupportType[]>>({
      url: '/supportTypes'
    }),

    transportationHelpTypes: dictionary.createRoute<
      void,
      Response<TransportationHelp[]>
    >({
      url: '/transportationHelps'
    }),

    workingDocuments: dictionary.createRoute<void, Response<WorkingDocument[]>>(
      {
        url: '/workingDocuments'
      }
    ),

    carerGenders: dictionary.createRoute<void, Response<CarerGender[]>>({
      url: '/carerGenders'
    }),

    services: dictionary.createRoute<void, Response<Service[]>>({
      url: '/services'
    }),

    memberLeaveCauses: dictionary.createRoute<void, Response<Entry[]>>({
      url: '/membersLeaveCauses'
    }),

    holderLeaveCauses: dictionary.createRoute<void, Response<Entry[]>>({
      url: '/holdersLeaveCauses'
    }),

    accountDeleteCauses: dictionary.createRoute<void, Response<Entry[]>>({
      url: '/accountDeleteCauses'
    })
  },

  file: {
    upload: file.createRoute<File, Response<{ Location: string }>>(file => {
      const data = new FormData();

      data.append('file', file);

      return {
        data,
        url: '/upload',
        method: 'POST'
      };
    })
  },

  postcode: {
    postcodeCheck: postcode.createRoute<{ postcode: string }, Response>({
      url: '/check',

      method: 'POST'
    }),

    addresses: postcode.createRoute<
      { postcode: string },
      Response<PostCodeAddressMatchResult[]>
    >({
      url: '/addresses',

      method: 'POST'
    })
  },

  invoice: {
    getAll: invoice.createRoute<
      GetInvoicesParams,
      Response<GetInvoicesResponse>
    >({
      url: '/'
    }),

    get: invoice.createRoute<{ id: string }, Response<Invoice>>(({ id }) => ({
      url: `/${id}`
    }))
  },

  email: {
    sendEmailConfirmation: email.createRoute<void, Response<{ token: string }>>(
      {
        url: '/sendConfirmation',

        method: 'POST'
      }
    )
  },

  workspaces: {
    getWorkspace: workspaces.createRoute<
      { id: Workspace['id'] },
      Response<Workspace>
    >(({ id }) => ({
      url: `/${id}`
    })),

    getMembers: workspaces.createRoute<
      { workspaceId: string },
      Response<GetWorkspaceMembersDTO>
    >(({ workspaceId }) => ({
      url: `/${workspaceId}/members`
    })),

    getUserWorkspaceRelations: workspaces.createRoute<
      void,
      Response<GetUserWorkspaceRelation[]>
    >({
      url: '/relations'
    }),

    updateMemberRole: workspaces.createRoute<UpdateMemberRoleDTO, Response>({
      url: '/role',
      method: 'PUT'
    }),

    updateMemberRelations: workspaces.createRoute<
      UpdateMemberRelationsDTO,
      Response
    >({
      url: '/relation',
      method: 'PUT'
    })
  }
};

export { carersHQ };

export * from './models';
