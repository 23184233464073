import classNames from 'classnames';

import type { FC } from 'react';

import styles from './dropdown.module.scss';

import type { DropdownItemProps, DropdownProps } from './dropdown.props';

/**
 * <Dropdown />
 */
const Dropdown: FC<DropdownProps> & {
  Item: FC<DropdownItemProps>;
} = ({ children, className, ...props }) => (
  <div className={classNames(styles.dropdown, className)} {...props}>
    {children}
  </div>
);

Dropdown.Item = ({ className, children, type, icon, ...props }) => (
  <div className={classNames(styles.item, styles[type], className)} {...props}>
    <div className={styles.itemContent}>
      {children}

      {icon && <div className={styles.icon}>{icon}</div>}
    </div>
  </div>
);

Dropdown.Item.defaultProps = {
  type: 'default'
};

export { Dropdown };
