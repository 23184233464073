import documents from '@img/carer-documents.png';

import skills from '@img/carer-skills.png';

import classNames from 'classnames';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { CtaBanner } from '@shared/ui/atoms/cta-banner';

import { $$signUpCarer } from '../carer.model';

import styles from './carer-skills.module.scss';

const cards = [
  {
    title: 'Skills',
    icon: skills,
    type: 'Skills',

    items: [
      'A can-do attitude, a caring nature and a genuine commitment to human dignity and respect.',
      'A minimum of one year of professional caring experience, with at least three months of this spent in the UK over the last two years.',
      'A smartphone or tablet. You’ll need these to access the full range of the CarersHQ online tools.',
      'Two excellent professional references.'
    ]
  },

  {
    title: 'Documents',
    icon: documents,
    type: 'Documents',

    items: [
      'A valid enhanced DBS certificate or the intention to apply for one, as part of our application process.',
      'NVQ Level 2 or above in Health & Social Care or a Care Certificate from an accredited provider.',
      'A valid passport or ID carer-info.',
      'Valid Right To Work documentation.'
    ]
  }
];

const CarerSkills = createView()
  .units({
    onConfirmClick: $$signUpCarer.confirmSkillsClicked
  })

  .view(({ onConfirmClick }) => (
    <div>
      <CtaBanner>
        <p className={styles.bannerText}>
          Great! We are excited you’d like to join us. <br />
          This is what you’ll need to become a CarersHQ carer:
        </p>
      </CtaBanner>

      <div className={styles.content}>
        <div className={styles.cards}>
          {cards.map(({ icon, title, items, type }, index) => (
            <div className={styles.card} key={index}>
              <div className={styles.cardHeader}>
                <h3 className={styles.cardHeaderTitle}>{title}</h3>

                <img
                  className={classNames(
                    styles.cardHeaderIcon,
                    styles[`cardHeaderIcon${type}`]
                  )}
                  src={icon}
                />
              </div>

              <ul className={styles.cardItems}>
                {items.map((item, index) => (
                  <li className={styles.cardItem} key={index}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className={styles.footer}>
          <Button onClick={() => onConfirmClick()}>Sounds good</Button>
        </div>
      </div>
    </div>
  ));

export { CarerSkills };
