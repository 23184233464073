import { createStore, sample } from 'effector';

import { array, object, string } from 'yup';

import { createForm } from '@shared/lib/form';

import { withoutCyrilic } from '@shared/lib/utils';

const $schema = createStore(null);

const form = createForm({
  initialValues: {
    conditionsTypes: [],

    additionalDetails: ''
  },

  $schema
});

sample({
  clock: [form.$values, form.$values.updates],

  source: form.$values,

  fn: values => {
    const fields = {
      conditionsTypes: array()
        .min(1, 'Required')

        .required()

        .nullable(),

      additionalDetails: string().nullable()
    };

    if (values.additionalDetails) {
      fields.additionalDetails = fields.additionalDetails
        .matches(withoutCyrilic, 'Invalid value')

        .max(100, 'Field cannot be longer than 100');
    }

    return object(fields);
  },

  target: $schema
});

const $$profileConditionExperience = {
  form
};

export { $$profileConditionExperience, form };
