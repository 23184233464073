import { createEvent, createStore, sample } from 'effector';

import { $$billing } from '@entities/billing';

const billingDetailsOpened = createEvent();

const billingDetailsClosed = createEvent();

const $visible = createStore(false);

const $$shared = {
  $visible,
  billingDetailsOpened,
  billingDetailsClosed
};

$visible.on(billingDetailsOpened, () => true).reset(billingDetailsClosed);

sample({
  clock: [
    $$billing.updateBillingDetailsFx.doneData,
    $$billing.createBillingDetailsFx.doneData
  ],

  target: billingDetailsClosed
});

export { $$shared };
