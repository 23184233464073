import classNames from 'classnames';

import { useUnit } from 'effector-react/effector-react.umd';

import type { FC, CSSProperties } from 'react';

import React, { useEffect } from 'react';

import { createPortal } from 'react-dom';

import { $windowSize } from '@shared/lib/global-events';

import { $$modal } from '@shared/ui/atoms/modal/modal.model';

import styles from './modal.module.scss';

import type {
  BodyProps,
  FooterProps,
  HeaderProps,
  ModalContainerProps
} from './modal.props';

let container = document.getElementById('modals');

if (!container) {
  container = document.createElement('div');

  container.id = 'modals';

  document.body.appendChild(container);
}

const Container: FC<
  React.HTMLAttributes<HTMLDivElement> & ModalContainerProps
> = ({
  size,
  onClose,
  overlayStyles,
  fullHeight,
  children,
  className,
  drawer,
  ...props
}) => {
  const [count, onOpen, onCancel, height] = useUnit([
    $$modal.$modalCount,

    $$modal.modalOpened,

    $$modal.modalClosed,

    $windowSize.map(state => state.height)
  ]);

  useEffect(() => {
    onOpen(1);

    if (count > 0) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      onCancel();

      document.body.style.overflow = 'auto';
    };
  }, [count]);

  return createPortal(
    <div
      className={classNames(styles.overlay, overlayStyles, {
        [styles[size]]: size,
        [styles.fullHeight]: fullHeight,
        [styles.overlayDrawer]: drawer
      })}
    >
      <div className={styles.backdrop} onClick={onClose} />

      <div
        className={classNames(styles.modal, className, {
          [styles[size]]: size,
          [styles.modalDrawer]: drawer
        })}
        style={
          {
            '--full-screen-height': `${height}px`
          } as CSSProperties
        }
        {...props}
      >
        <div className={classNames(styles.modal__inner)}>{children}</div>
      </div>
    </div>,

    container
  );
};

Container.defaultProps = {
  size: 'medium'
};

const Body: FC<BodyProps> = ({ className, drawer, children, ...props }) => (
  <div
    className={classNames(styles.content, className, {
      [styles.bodyDrawer]: drawer
    })}
    {...props}
  >
    {children}
  </div>
);

const Footer: FC<FooterProps> = ({
  className,
  children,
  fill,
  drawer,
  space,
  ...props
}) => (
  <footer
    className={classNames(styles.footer, className, {
      [styles.fill]: fill,
      [styles.footerDrawer]: drawer,
      [styles.space]: space
    })}
    {...props}
  >
    {children}
  </footer>
);

const Header: FC<HeaderProps> = ({ className, step, children, ...props }) => (
  <header className={classNames(styles.header, className)} {...props}>
    <div
      className={classNames({
        [styles.step]: step
      })}
    >
      {children}
    </div>
  </header>
);

const Modal = {
  Body,
  Header,
  Footer,
  Container
};

export { Modal };
