import { sample } from 'effector';

import { $$members } from '@entities/members';

import { routes } from '@shared/routes';

sample({
  clock: routes.careRecipients.contacts.opened,

  target: $$members.getMembersFx
});
