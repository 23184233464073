import { $$careRecipient } from '@entities/care-recipient';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { Modal } from '@shared/ui/atoms/modal';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { $$deleteAccount } from '../../model';

import styles from './reason.module.scss';

const Reason = createView()
  .units({
    onCancel: $$deleteAccount.closeModalClicked,

    client: $$careRecipient.$careRecipientsProfile
  })

  .view(({ onCancel, client }) => (
    <Modal.Container size='small' onClose={() => onCancel()}>
      <Modal.Header>
        Deleting {client?.firstName} {client?.lastName}’s account
      </Modal.Header>

      <Modal.Body className={styles.content}>
        <p className={styles.description}>
          Before you delete {client?.firstName} {client?.lastName}’s account,
          please help us make the CarersHQ service better. Please state the
          reason for deleting the account.
        </p>

        <Form use={$$deleteAccount.reasonForm}>
          <Field.Select
            use={$$deleteAccount.reasonForm.fields.reason}
            className={styles.reason}
            menuPlacement='bottom'
            menuPosition='fixed'
            placeholder='Select'
            label='Why you are deleting the account?'
            name='reason'
            searchable={false}
            options={[
              {
                id: 'Bip',
                name: 'Bip'
              },
              {
                id: 'Bop',
                name: 'Bop'
              }
            ]}
          />
        </Form>

        <p className={styles.changed}>
          Changed your mind? <br /> No problem. Just press cancel.
        </p>
      </Modal.Body>

      <Modal.Footer className={styles.footer}>
        <Button
          palette='text'
          variant='text'
          className={styles.cancel}
          onClick={() => onCancel()}
        >
          Cancel
        </Button>

        <Button
          palette='error'
          onClick={() => $$deleteAccount.reasonForm.submit()}
          className={styles.submit}
        >
          Continue deleting
        </Button>
      </Modal.Footer>
    </Modal.Container>
  ));

export { Reason };
