import avatar from '@img/avatar-placeholder.png';

import classNames from 'classnames';

import moment from 'moment';

import { createView } from '@shared/lib/view';

import styles from './client-box.module.scss';

import type { ClientBoxProps } from './client-box.props';

import { useClientBoxProps } from './client-box.props';

/**
 * <ClientBox />
 */
const ClientBox = createView<ClientBoxProps>()
  .defaultProps({
    onClick: () => {}
  })
  .map(useClientBoxProps)

  .view(({ className, gender, active, recipient, draft, onClick }) => (
    <div
      className={classNames(styles.clientBox, className, {
        [styles.clientBoxActive]: active
      })}
      onClick={onClick}
      key={recipient.id}
    >
      <div className={styles.avatar}>
        <img src={recipient.imageUrl ?? avatar} />
      </div>

      <div className={styles.info}>
        <div className={styles.name}>
          {recipient.isDeleted && '(Deleted) '}

          {draft && '(Draft) '}

          {recipient?.firstName || recipient?.lastName
            ? `${recipient?.firstName} ${recipient?.lastName}`.trim()
            : moment(recipient.createdAt).format('MMM DD, YYYY')}
        </div>

        {(!recipient.isDeleted ||
          recipient.gender ||
          recipient.dateOfBirth) && (
          <div className={styles.age}>
            {recipient.gender?.id && (
              <>{draft ? gender?.name : recipient.gender?.name}</>
            )}

            {recipient.gender?.id && recipient.dateOfBirth && <>, </>}

            {recipient.dateOfBirth && (
              <>
                {Math.abs(
                  moment(recipient.dateOfBirth).diff(moment(), 'years')
                )}{' '}
                years old
              </>
            )}
          </div>
        )}
      </div>
    </div>
  ));

export { ClientBox };
