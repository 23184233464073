import { createRoutesView } from 'atomic-router-react';

import { modelView } from 'effector-factorio';

import { CreateAccount } from '@pages/sign-up/ui/create-account';

import { StepWizard } from '@pages/sign-up/ui/sign-up-wizard-step';

import { CareNeedsForm } from '@features/care-recipient/manage-care-needs';

import { ConditionExperienceForm } from '@features/care-recipient/manage-condition-experience';

import { CareRecipientDetailsForm } from '@features/care-recipient/manage-details';

import { PreferencesForm } from '@features/care-recipient/manage-preferences';

import { AccountHolderDetailsForm } from '@features/user/manage-account-holder-details';

import { routes } from '@shared/routes';

import { CtaBanner } from '@shared/ui/atoms/cta-banner';

import { useMedia } from '@shared/ui/atoms/media';

import { styled } from '@shared/ui/stitches';

import {
  $$signUpLovedOne,
  SignUpLovedOneModelFactory
} from './loved-one.model';

import styles from './loved-one.module.scss';

const Container = styled('div', {
  flex: 1,

  background: '#fff'
});

const Wrap = styled('br', {
  display: 'none',

  '@sm': {
    display: 'block'
  }
});

const CareNeeds = () => (
  <>
    <CtaBanner letterSpacing>
      <p>
        Let’s find your perfect carer! <br />
        First, tell me about the care you need.
      </p>
    </CtaBanner>

    <Container>
      <StepWizard
        model={$$signUpLovedOne.wizards.careNeeds}
        key='care-needs'
        title='Care Needs'
      >
        <CareNeedsForm model={$$signUpLovedOne.steps.careNeeds} />
      </StepWizard>
    </Container>
  </>
);

const ConditionExperience = () => (
  <>
    <CtaBanner letterSpacing>
      <p>
        Now, tell me about any specialist care <br /> you might need.
      </p>
    </CtaBanner>

    <Container>
      <StepWizard
        model={$$signUpLovedOne.wizards.conditionExperience}
        key='condition-experience'
        title='Condition Experience'
      >
        <ConditionExperienceForm
          model={$$signUpLovedOne.steps.conditionExperience}
        />
      </StepWizard>
    </Container>
  </>
);

const Preferences = () => {
  const mobile = useMedia('<sm');

  return (
    <>
      <CtaBanner letterSpacing>
        <p>
          Next, please tell me
          {mobile && <br />} about your <br />
          individual preferences.
        </p>
      </CtaBanner>

      <Container>
        <StepWizard
          model={$$signUpLovedOne.wizards.preferences}
          key='preferences'
          title='Preferences'
        >
          <PreferencesForm
            classNamePicker={{ wrapper: styles.label, list: styles.languages }}
            model={$$signUpLovedOne.steps.preferences}
            captions={{
              languages: {
                add: 'Add another'
              }
            }}
          />
        </StepWizard>
      </Container>
    </>
  );
};

const CareRecipient = () => {
  const mobile = useMedia('<sm');

  return (
    <>
      <CtaBanner letterSpacing>
        <p>
          And now, tell me {mobile && <br />} a little more about <br />
          your loved one.
        </p>
      </CtaBanner>

      <Container>
        <StepWizard
          model={$$signUpLovedOne.wizards.careRecipient}
          key='care-recipient'
          title='Care Recipient'
        >
          <CareRecipientDetailsForm
            model={$$signUpLovedOne.steps.careRecipient}
          />
        </StepWizard>
      </Container>
    </>
  );
};

const AccountHolder = () => {
  const mobile = useMedia('<sm');

  return (
    <>
      <CtaBanner letterSpacing>
        <p>
          And also, tell me {mobile && <br />}a little bit <br />
          about yourself.
        </p>
      </CtaBanner>

      <Container>
        <StepWizard
          model={$$signUpLovedOne.wizards.accountHolder}
          key='account-holder'
          title='Account Holder'
        >
          <AccountHolderDetailsForm
            model={$$signUpLovedOne.steps.accountHolder}
          />
        </StepWizard>
      </Container>
    </>
  );
};

const lovedOneViews = [
  {
    route: routes.signUp.lovedOne.careNeeds,

    view: CareNeeds
  },

  {
    route: routes.signUp.lovedOne.conditionExperience,

    view: ConditionExperience
  },

  {
    route: routes.signUp.lovedOne.preferences,

    view: Preferences
  },

  {
    route: routes.signUp.lovedOne.careRecipient,

    view: CareRecipient
  },

  {
    route: routes.signUp.lovedOne.accountHolder,

    view: AccountHolder
  },

  {
    route: routes.signUp.lovedOne.createAccount,

    view: () => (
      <CreateAccount
        stepWizardModel={$$signUpLovedOne.$$createAccountWizard}
        createAccountModel={$$signUpLovedOne.$$createAccount}
      />
    )
  }
];

const Steps = createRoutesView({
  routes: lovedOneViews
});

const SignUpLovedOnePage = modelView(SignUpLovedOneModelFactory, () => (
  <Steps />
));

export { SignUpLovedOnePage, lovedOneViews };
