import classNames from 'classnames';

import type { FC, HTMLAttributes } from 'react';

import { Text } from '@shared/ui/atoms/text';

import styles from './radio.module.scss';

import type { RadioProps } from './radio.props';

const Radio: FC<RadioProps> = ({
  label,
  value,
  payload,
  disabled,
  onChange,
  hasError,
  className
}) => (
  <label
    className={classNames(styles.radio, className, {
      [styles.disabled]: disabled,
      [styles.error]: hasError
    })}
    onClick={() => onChange(payload)}
  >
    <div
      className={classNames(styles.checkmark, {
        [styles.checked]: value == payload
      })}
    />

    <input type='radio' className={styles.input} />

    {label && (
      <Text variant='body-1' className={styles.label}>
        {label}
      </Text>
    )}
  </label>
);

const RadioGroup: FC<
  HTMLAttributes<HTMLDivElement> & {
    layout: 'horizontal' | 'vertical' | 'horizontalAlways';
  }
> = ({ className, layout, ...props }) => (
  <div
    className={classNames(className, styles.group, styles[layout])}
    {...props}
  />
);

Radio.defaultProps = {
  disabled: false,

  hasError: false
};

RadioGroup.defaultProps = {
  layout: 'horizontal'
};

export { Radio, RadioGroup };
