import { $$profilePage } from '@pages/profile/profile.model';

import { $$carerRates } from '@features/carer/profile/rates';

import { RatesForm } from '@features/carer/profile/rates/ui';

import { $$carerProfile } from '@entities/carer/model';

import { createView } from '@shared/lib/view';

import { CareTypeRate } from '@shared/ui/atoms/care-type-rate';

import { EditableSection } from '@shared/ui/atoms/editable-section';

import styles from './rates.module.scss';
/**
 * <Rates />
 */
const Rates = createView()
  .units({
    carer: $$carerProfile.$carerProfile,

    editing: $$profilePage.rating.$opened,

    onSave: $$carerRates.form.submit,

    onCancel: $$profilePage.rating.close,

    onEdit: $$profilePage.rating.open
  })

  .view(({ carer, onEdit, editing, onSave, onCancel }) => (
    <EditableSection
      title='Types of care and rate'
      editing={editing}
      onEdit={onEdit}
      onCancel={onCancel}
      onSave={onSave}
    >
      {({ editing }) =>
        editing ? (
          <RatesForm />
        ) : (
          <div className={styles.rates}>
            {[...(carer?.supportTypes ?? [])]
              .sort((a, b) => (a.supportType?.id > b.supportType?.id ? 1 : -1))

              .map((type, index) => (
                <CareTypeRate type={type} key={type.supportType?.id ?? index} />
              ))}
          </div>
        )
      }
    </EditableSection>
  ));

export { Rates };
