import type { Account } from './account';

import type { Carer } from './carer';

import type { Visit } from './visit';

import type { Workspace } from './workspace';

enum InvoiceStatus {
  Paid = 'Paid',
  Unpaid = 'Unpaid',
  InProgress = 'In progress',
  All = 'All',
  Refunded = 'Refunded'
}

const invoiceStatusColor = {
  [InvoiceStatus.Paid]: 'filledGreen',
  [InvoiceStatus.Unpaid]: 'filledPeach',
  [InvoiceStatus.InProgress]: 'filledYellow',
  [InvoiceStatus.Refunded]: 'fillerGrey'
};

const invoiceStatusNames = {
  [InvoiceStatus.Paid]: 'Paid',
  [InvoiceStatus.Unpaid]: 'Unpaid',
  [InvoiceStatus.InProgress]: 'Processing',
  [InvoiceStatus.Refunded]: 'Refunded'
};

type Invoice = {
  id?: string;

  carer: Carer;

  startDate: string;

  endDate: string;

  status: InvoiceStatus;

  visits?: Visit[];

  totalHours: number;

  earnings: number;

  // Time in minutes
  dayCare?: number;
  nightCare?: number;
  subTotal?: number;
  grandTotal?: number;
  vat?: number;
  totalMoneyAmount?: number;

  account?: Account;

  workspace?: Workspace;
};

export { InvoiceStatus, invoiceStatusColor, invoiceStatusNames };

export type { Invoice };
