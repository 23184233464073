import classNames from 'classnames';

import type { FC } from 'react';

import styles from './card.module.scss';

/**
 * <Card />
 */
const Card: FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => <div className={classNames(styles.card, className)} {...props} />;

export { Card };
