enum ToastID {
  AddedToAShortlist = 1,
  RemovedFromAShortlist = 2,
  AddedToConnectedList = 3,
  RemovedFromConnectedList = 4,
  InvalidFileFormat = 5,
  LegitCarer = 6,
  RelationsWereUpdated = 7,
  PasswordChanged = 8,
  EmailChanged = 9,
  ConfirmationLinkHasBeenSent = 10,
  MemberDeleted = 11,
  InvitationsSent = 12,
  InvitationsSentError = 13,
  InvitationResent = 14,
  BillingDetailsSaved = 15,
  UploadFileError = 16,
  AccountDeletionError = 17,
  InvalidSignInCredentials = 18,
  CareRecipientRestored = 19,
  InvalidForgotPasswordCredentials = 20,
  InternalError = 21,
  WellnessError = 22,
  DeleteAccount = 23,
  UpdateRole = 24
}

export { ToastID };
