import classNames from 'classnames';

import type { FC } from 'react';

import { Button } from '@shared/ui/atoms/button';

import styles from './empty-placeholder.module.scss';

import type { EmptyPlaceholderProps } from './empty-placeholder.props';

/**
 * <EmptyPlaceholder />
 */
const EmptyPlaceholder: FC<EmptyPlaceholderProps> = ({
  text,
  title,
  image,
  active,
  action,
  onAction,
  classNames: _classNames,
  styles: externalStyles
}) => (
  <div className={styles.emptyPlaceholder}>
    {image && (
      <img
        className={classNames(styles.image, {
          [styles.active]: active
        })}
        src={image}
      />
    )}

    <div className={styles.title}>{title}</div>

    <p className={classNames(styles.text, _classNames?.text)}>{text}</p>

    {action && (
      <Button
        className={classNames(
          styles.action,
          _classNames?.action,
          externalStyles?.action
        )}
        onClick={onAction}
      >
        {action}
      </Button>
    )}
  </div>
);

EmptyPlaceholder.defaultProps = {
  active: true
};

export { EmptyPlaceholder };
