import { createStore } from 'effector';

import { modelFactory } from 'effector-factorio';

import { array, object } from 'yup';

import type { ConditionsType } from '@shared/api';

import { createForm } from '@shared/lib/form';

type ConditionExperienceValues = {
  conditionsTypes: ConditionsType['id'][];
};

const ManageConditionExperienceModelFactory = modelFactory(() => {
  const form = createForm<ConditionExperienceValues>({
    initialValues: {
      conditionsTypes: []
    },

    $schema: createStore(
      object({
        conditionsTypes: array()
          .min(1, 'Required')

          .required()

          .nullable()

          .label('Condition Experience')
      })
    )
  });

  return {
    form
  };
});

export type { ConditionExperienceValues };

export { ManageConditionExperienceModelFactory };
