import { $$signUpLovedOne } from '@pages/sign-up/pages/loved-one';

import { routes } from '@shared/routes';

import { createLovedOneStep } from './shared';

createLovedOneStep({
  key: 'conditionExperience',

  form: $$signUpLovedOne.steps.conditionExperience.form,

  route: routes.signUp.lovedOne.conditionExperience,

  nextRoute: routes.signUp.lovedOne.preferences,

  mapValues: values => values.conditionExperience,

  processValues: values => values,

  checkValues: values => Boolean(values.careNeeds)
});
