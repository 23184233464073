import { $$dropdown } from '@layouts/main-page/model';

import { createEvent, sample } from 'effector';

import { atom } from '@shared/lib/factory';

const $$selectedCareSpace = atom('Selected Care Space', () => {
  const clicked = createEvent();

  sample({
    clock: clicked,

    source: $$dropdown.$careSpacesOpened,

    fn: opened => !opened,

    target: $$dropdown.$careSpacesOpened
  });

  return {
    clicked
  };
});

export { $$selectedCareSpace };
