import { combine } from 'effector';

import { $$careSpace } from '@entities/care-space';

import { $$user } from '@entities/user';

import { WorkspaceStatus } from '@shared/api';

const $isInOwnWorkspace = combine(
  $$careSpace.$careSpace,

  $$user.$account,

  (space, account) => {
    if (!space || !account) return false;

    const current = account.accountWorkspaces.find(
      one => one.workspace.id == space.id
    );

    if (!current) return false;

    return current.workspace.owner.id == account.id;
  }
);

const $ownCareSpace = combine(
  $$user.$account,

  $$careSpace.$accountCareSpaces,

  (account, spaces) =>
    spaces?.find(one => one.workspace.owner.id == account?.id ?? null)
);

const $hasOwnCareSpace = $ownCareSpace.map(Boolean);

const $hasOwnDeletedCareSpace = $ownCareSpace.map(
  space => space?.workspace?.status == WorkspaceStatus.Deleted
);

export {
  $isInOwnWorkspace,
  $hasOwnDeletedCareSpace,
  $hasOwnCareSpace,
  $ownCareSpace
};
