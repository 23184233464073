import { createView } from '@shared/lib/view';

import { $$emailConfirmation, EmailConfirmationStep } from '../model';

import { ChangeEmail } from './change-email';

import { ChangedEmail } from './changed-email';

import { Confirmation } from './confirmation';

import { SentEmail } from './sent-email';

import { Welcome } from './welcome';

/**
 * <EmailConfirmation />
 */
const EmailConfirmation = createView()
  .units({
    step: $$emailConfirmation.$step
  })

  .view(({ step }) => {
    switch (step) {
      case EmailConfirmationStep.Welcome:
        return <Welcome />;

      case EmailConfirmationStep.Confirmation:
        return <Confirmation />;

      case EmailConfirmationStep.Sent:
        return <SentEmail />;

      case EmailConfirmationStep.ChangeEmail:
        return <ChangeEmail />;

      case EmailConfirmationStep.Changed:
        return <ChangedEmail />;
    }
  });

export { EmailConfirmation };
