import { createEvent, createStore, sample } from 'effector';

import { $$transferOwnerShip } from '@features/account/transfer-ownership';

import { $$checkUserCredentials } from '@features/user/credentials/check';

import { $$accountInfo } from '@entities/account';

import { $$credential } from '@entities/account/ui/delete-account/credentials/credentials.model';

import { $$reason } from '@entities/account/ui/delete-account/reason/reason.model';

const modalOpened = createEvent();

const modalClosed = createEvent();

const submit = createEvent();

const transferClicked = createEvent();

const stepChanged = createEvent<string>();

const transferTypeChanged = createEvent<boolean>();

const $visible = createStore(false);

const $isEmbedded = createStore(false);

const $step = createStore<string>('confirm');

const $transferType = createStore(true);

const $$settingsDeleteAccount = {
  $visible,

  $step,

  $isEmbedded,

  $transferType,

  modalOpened,

  modalClosed,

  stepChanged,

  transferClicked,

  submit,

  transferTypeChanged
};

sample({
  clock: $$reason.reasonForm.submitted,

  source: $visible,

  filter: visible => visible,

  fn: () => 'attention-access',

  target: stepChanged
});

sample({
  clock: stepChanged,

  filter: step => step === 'transfer-ownership',

  target: [modalClosed, $$transferOwnerShip.modalOpened]
});

sample({
  clock: stepChanged,

  filter: step => step === 'transfer-ownership',

  fn: () => true,

  target: $isEmbedded
});

sample({
  clock: transferClicked,

  target: [$$transferOwnerShip.modalClosed, modalOpened]
});

sample({
  clock: transferClicked,

  fn: () => 'workspace-decision',

  target: stepChanged
});

sample({
  clock: transferClicked,

  fn: () => false,

  target: $isEmbedded
});

sample({
  clock: $$credential.credentialForm.submitted,

  target: [
    $$checkUserCredentials.checkCredentialsFx,
    $$credential.credentialInfoChanged
  ]
});

$$credential.$error
  .on($$checkUserCredentials.checkCredentialsFx.failData, () => true)

  .reset(modalClosed, $$checkUserCredentials.checkCredentialsFx.done);

sample({
  clock: $$checkUserCredentials.checkCredentialsFx.doneData,

  source: $visible,

  filter: visible => visible,

  fn: () => 'attention-appointments',

  target: stepChanged
});

sample({
  clock: $$checkUserCredentials.checkCredentialsFx.doneData,

  fn: () => ({
    email: '',
    password: ''
  }),

  target: $$credential.credentialForm.patch
});

sample({
  clock: stepChanged,

  filter: step => step === 'transfer-ownership',

  target: [$$transferOwnerShip.modalOpened]
});

sample({
  clock: transferClicked,

  target: [$$transferOwnerShip.modalClosed]
});

sample({
  clock: submit,

  source: {
    credentials: $$credential.$credentialInfo,

    reason: $$reason.$reason
  },

  fn: ({
    credentials: { email, password },
    reason: { reason, reasonOther }
  }) => ({
    password,
    email,
    reason: reason || reasonOther
  }),

  target: $$accountInfo.deleteAccountFx
});

sample({
  clock: $$accountInfo.deleteAccountFx.doneData,

  fn: () => 'done',

  target: stepChanged
});

sample({
  clock: modalClosed,

  fn: () => {},

  target: [$$reason.reasonForm.reset, $$credential.credentialForm.reset]
});

$step.on(stepChanged, (_state, payload) => payload).reset(modalClosed);

$visible.on(modalOpened, () => true).reset(modalClosed);

$transferType
  .on(transferTypeChanged, (_state, payload) => payload)

  .reset(modalClosed);

$$reason.$reason.reset(modalClosed);

$$credential.$credentialInfo.reset(modalClosed);

export { $$settingsDeleteAccount };
