type Dashboard = {
  id?: string;
  date?: string;
  mood?: number;
  sleep?: number;
  pain?: number;
  meals?: number;
  bowelmovements?: number;
};

enum DashboardMarkTypes {
  Mood = 1,
  Sleep = 2,
  Pain = 3,
  Meals = 4,
  BowelMovements = 5
}

const DashboardMarkTypeNames = {
  [DashboardMarkTypes.Mood]: 'mood',
  [DashboardMarkTypes.Sleep]: 'sleep',
  [DashboardMarkTypes.Pain]: 'pain',
  [DashboardMarkTypes.Meals]: 'meals',
  [DashboardMarkTypes.BowelMovements]: 'bowelmovements'
};

type DashboardParams = {
  markTypes?: DashboardMarkTypes[];
  startDate?: string | Date;
  endDate?: string | Date;
};

export type { Dashboard, DashboardParams };

export { DashboardMarkTypes, DashboardMarkTypeNames };
