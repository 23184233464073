import { createEffect, createEvent, createStore } from 'effector';

import { persist } from 'effector-storage/local';

import { carersHQ } from '@shared/api';

import {
  ACCESS_TOKEN_KEY,
  INVOICE_ACCESS_TOKEN_KEY,
  ROLE_KEY
} from '@shared/config';

import type { RoleType } from '../config';

const started = createEvent<string>();

const ended = createEvent();

const deletedSessionStarted = createEvent<string>();

const deletedSessionEnded = createEvent();

const roleChanged = createEvent<RoleType>();

const cleanupFx = createEffect();

const $token = createStore<string | null>(null);

const $invoiceToken = createStore<string>(null!);

const $role = createStore<RoleType>(null!);

const $active = $token.map(Boolean);

const $auth = $token.map(token => {
  if (!token) return {};

  return {
    Authorization: `Bearer ${token}`
  };
});

const $$session = {
  $role,
  $active,

  ended,
  started,
  roleChanged,
  deletedSessionEnded,
  deletedSessionStarted
};

carersHQ.http.headers({
  auth: $auth
});

persist({
  store: $token,

  key: ACCESS_TOKEN_KEY
});

persist({
  store: $invoiceToken,

  key: INVOICE_ACCESS_TOKEN_KEY
});

persist({
  store: $role,

  key: ROLE_KEY
});

$token.on(started, (_, token) => token).on(ended, () => null);

$role.on(roleChanged, (_, role) => role).on(ended, () => null);

$invoiceToken
  .on(deletedSessionStarted, (_, token) => token)

  .reset(deletedSessionEnded);

cleanupFx.use(() => {
  localStorage.clear();
});

export { $$session };
