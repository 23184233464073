import { createEvent, sample } from 'effector';

import { $$careRecipient } from '@entities/care-recipient';

import { $$careSpace, $$member } from '@entities/care-space';

import { $$drafts } from '@entities/drafts';

import { $$user } from '@entities/user';

import { routes } from '@shared/routes';

const tabChanged = createEvent<string>();

const personClicked = createEvent<string>();

const $id = routes.careRecipients.base.$params.map(
  state => state.id?.[0] ?? 'temp'
);

const $$careRecipientsPage = {
  $id,

  tabChanged,

  personClicked
};

sample({
  clock: tabChanged,

  source: $id,

  filter: (_, tab) => tab == 'profile',

  fn: id => ({ id }),

  target: routes.careRecipients.general.open
});

sample({
  clock: tabChanged,

  source: $id,

  filter: (_, tab) => tab == 'medication',

  fn: id => ({ id }),

  target: routes.careRecipients.medication.open
});

sample({
  clock: tabChanged,

  source: $id,

  filter: (_, tab) => tab == 'wellness',

  fn: id => ({ id }),

  target: routes.careRecipients.wellness.open
});

sample({
  clock: tabChanged,

  source: $id,

  filter: (_, tab) => tab == 'contacts',

  fn: id => ({ id }),

  target: routes.careRecipients.contacts.open
});

sample({
  clock: personClicked,

  fn: id => ({ id }),

  target: routes.careRecipients.general.open
});

sample({
  clock: personClicked,

  fn: id => id,

  target: $$careRecipient.getCareRecipientFx
});

sample({
  clock: personClicked,

  target: $$careRecipient.resetCareRecipientProfile
});

sample({
  clock: [
    $id.updates,

    routes.careRecipients.base.opened.map(payload => payload.params.id[0])
  ],

  source: routes.careRecipients.base.$isOpened,

  filter: isOpened => Boolean(isOpened),

  fn: (_, id) => id,

  target: $$careRecipient.getCareRecipientFx
});

sample({
  clock: routes.careRecipients.base.opened,

  source: $$careSpace.$careSpaceID,

  target: $$member.getCareSpaceMembersFx
});

sample({
  clock: [routes.careRecipients.general.opened, $$user.getAccountFx.doneData],

  source: $$user.$account,

  filter: account => !!account?.id,

  fn: account => account?.id,

  target: $$drafts.fetchDraftsFx
});

export { $$careRecipientsPage };
