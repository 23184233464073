import { useStore } from 'effector-react';

import { useMemo } from 'react';

import { $$dictionary } from '@entities/dictionary';

import type { Client } from '@shared/api';

type ClientBoxProps = {
  className?: string;
  recipient: Client;
  active: boolean;
  draft?: boolean;
  onClick?: () => void;
};

const useClientBoxProps = ({ recipient }: ClientBoxProps) => {
  const genders = useStore($$dictionary.$genders);

  const gender = useMemo(
    () => genders.find(({ id }) => id === recipient.gender?.id),

    [recipient, genders]
  );

  return {
    gender
  };
};

export type { ClientBoxProps };

export { useClientBoxProps };
