import { modelView } from 'effector-factorio';

import { $$dictionary } from '@entities/dictionary';

import { createView } from '@shared/lib/view';

import { BoxRow } from '@shared/ui/atoms/box-row';

import { FormSection, FormSectionGroup } from '@shared/ui/atoms/form-section';

import { DateSelectGroupContainer } from '@shared/ui/molecules/date-select-group';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { maxBirthDate, minBirthDate } from '../lib';

import { ManagePersonalInformationModelFactory } from '../model';

import styles from './personal-information.module.scss';

/**
 * <PersonalInformation />
 */
const PersonalInformationForm = modelView(
  ManagePersonalInformationModelFactory,

  createView()
    .static({
      minBirthDate,

      maxBirthDate
    })

    .units({
      genders: $$dictionary.$genders
    })

    .map(() => ({
      form: ManagePersonalInformationModelFactory.useModel().form
    }))

    .view(({ genders, form }) => (
      <Form use={form}>
        <FormSectionGroup>
          <div className={styles.name}>
            <Field.Input
              labelGap
              use={form.fields.firstName}
              name='firstName'
              bold
              label='First name'
              errorIn='label'
            />

            <Field.Input
              labelGap
              use={form.fields.lastName}
              name='lastName'
              bold
              label='Last name'
              errorIn='label'
            />
          </div>

          <Field.FormSection
            title='Date of birth'
            use={form.fields.dateOfBirth}
          >
            <div className={styles.dateOfBirth}>
              <DateSelectGroupContainer>
                <Field.DateSelectGroup
                  searchable={false}
                  use={form.fields.dateOfBirth}
                  min={minBirthDate}
                  max={maxBirthDate}
                />
              </DateSelectGroupContainer>
            </div>
          </Field.FormSection>

          <FormSection title='Gender'>
            <BoxRow layout='horizontalAlways'>
              <Field.RadioList use={form.fields.gender} options={genders} />
            </BoxRow>
          </FormSection>
        </FormSectionGroup>
      </Form>
    ))
);

export { PersonalInformationForm };
