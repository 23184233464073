import { $$memberDelete } from '@features/member/delete';

import { EditMemberDetailsModal } from '@features/member/edit-details';

import { $$inviteMembers, MemberInvite } from '@features/member/invite';

import { $isInOwnWorkspace } from '@entities/_computed/care-space';

import { $$careSpace } from '@entities/care-space';

import { $$members } from '@entities/members';

import { MemberCard } from '@entities/members/ui/member-card';

import { $$user } from '@entities/user';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { $$membersPage } from './members.model';

import styles from './members.module.scss';

const Member = createView()
  .units({
    isUserOwner: $isInOwnWorkspace,

    memberInviteVisible: $$inviteMembers.$visible,

    onInviteMembersClick: $$membersPage.inviteMembersClicked.prepend(() => {}),

    members: $$members.$members,

    careSpace: $$careSpace.$careSpace,

    editMemberDetailsVisible: $$membersPage.$$editMemberDetails.$visible,

    onEditMemberClick: $$membersPage.editClicked,

    account: $$user.$account,

    deleteMemberOpened: $$memberDelete.deleteMemberOpened
  })

  .view(
    ({
      onEditMemberClick,
      members,
      isUserOwner,
      memberInviteVisible,
      careSpace,
      account,
      editMemberDetailsVisible,
      deleteMemberOpened,
      onInviteMembersClick
    }) => {
      if (!members.length) return null;

      return (
        <>
          <div>
            <div className={styles.header}>
              <span className={styles.numberOfMembers}>
                There are {members.length} active members in your account
              </span>

              {isUserOwner && (
                <Button onClick={onInviteMembersClick}>
                  Invite new member
                </Button>
              )}
            </div>

            <div className={styles.membersList}>
              {members.map(member => (
                <MemberCard
                  onEditClick={() => onEditMemberClick(member)}
                  deleteMemberOpened={deleteMemberOpened}
                  currentUser={account}
                  owner={careSpace.owner}
                  isUserOwner={isUserOwner}
                  key={member.id || member.account.email}
                  member={member}
                />
              ))}
            </div>
          </div>

          {memberInviteVisible && <MemberInvite />}

          {editMemberDetailsVisible && (
            <EditMemberDetailsModal use={$$membersPage.$$editMemberDetails} />
          )}
        </>
      );
    }
  );

export { Member };
