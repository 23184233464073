import bowelmovements from '@img/bowelmovements_small.png';

import meals from '@img/meals_small.png';

import mood from '@img/mood_small.png';

import pain from '@img/pain_small.png';

import sleep from '@img/sleep_small.png';

import classNames from 'classnames';

import type { FC } from 'react';

import styles from './placeholder.module.scss';

const Placeholder: FC<{ active: boolean; variant: string }> = ({
  active,
  variant
}) => {
  const photos: { [key: string]: string } = {
    mood,
    sleep,
    pain,
    meals,
    bowelmovements
  };

  return (
    <div
      className={classNames(styles.section, {
        [styles.hasBeenStopped]: !active
      })}
    >
      <img
        className={styles.icon}
        src={photos[variant]}
        alt='No data for chart'
      />

      <h2 className={styles.title}>
        {active
          ? `You don't have the information yet`
          : 'Data collection has been paused'}
      </h2>

      <p className={styles.description}>
        {active
          ? 'The information will appear here when the carer fills it out after the visit with the client'
          : 'To continue collecting information, enable this section by clicking here.'}
      </p>
    </div>
  );
};

export { Placeholder };
