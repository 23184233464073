import { createRoutesView, Route } from 'atomic-router-react';

import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { $$signUpCarer, carerViews, SignUpCarerPage } from './pages/carer';

import {
  $$signUpLovedOne,
  lovedOneViews,
  SignUpLovedOnePage
} from './pages/loved-one';

import { $$signUpMyself, myselfViews, SignUpMyselfPage } from './pages/myself';

import { $$signUpPage } from './sign-up.model';

import { CareRecipientSignUpType } from './ui/care-recipient-sign-up-type';

import { HasAnAccount } from './ui/has-an-account';

import { NotSupportedPostcode } from './ui/not-supported-postcode';

import { Postcode } from './ui/postcode';

const SignUpByRole = createRoutesView({
  routes: [
    {
      route: carerViews.map(item => item.route),

      view: () => <SignUpCarerPage model={$$signUpCarer} />
    },

    {
      route: lovedOneViews.map(item => item.route),

      view: () => <SignUpLovedOnePage model={$$signUpLovedOne} />
    },

    {
      route: myselfViews.map(item => item.route),

      view: () => <SignUpMyselfPage model={$$signUpMyself} />
    },

    {
      route: routes.signUp.type,

      view: CareRecipientSignUpType
    }
  ]
});

const SignUpPage = createView()
  .units({
    postcodeNotSupported: $$signUpPage.$postcodeNotSupported,

    hasAnAccount: $$signUpPage.$hasAnAccount,

    asCarer: routes.signUp.carer.postcode.$isOpened,

    asMyself: routes.signUp.myself.postcode.$isOpened,

    asLovedOne: routes.signUp.lovedOne.postcode.$isOpened
  })

  .view(
    ({ postcodeNotSupported, hasAnAccount, asCarer, asMyself, asLovedOne }) => {
      if (hasAnAccount) {
        return <HasAnAccount />;
      }

      return (
        <>
          {postcodeNotSupported ? (
            <NotSupportedPostcode />
          ) : (
            <Route
              route={[
                routes.signUp.myself.postcode,
                routes.signUp.lovedOne.postcode,
                routes.signUp.carer.postcode
              ]}
              view={() => (
                <Postcode asCarer={asCarer} asClient={asMyself || asLovedOne} />
              )}
            />
          )}

          <SignUpByRole />
          {/* 
        <ConfirmBack />

        <ConfirmChange /> */}
        </>
      );
    }
  );

export { SignUpPage };
