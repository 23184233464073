enum ErrorCode {
  FindToken = '0001',
  UpdateToken = '0002',
  CreateToken = '0003',
  GenerateAccessToken = '0004',
  GenerateRefreshToken = '0005',
  GetDictionary = '0006',
  LoginWeb = '0100',
  SignupMyself = '0101',
  SignupCarier = '0106',
  Signup = '0105',
  EmailAlreadyInUse = '0102',
  UpdateCurrent = '0107',
  ForgotWeb = '0104',
  CheckPostCode = '0110',
  GetLocation = '0108',
  CarerStartDate = '0109',

  InvoicesLoginAttempts = '0219',
  EmailIsCurrentEmail = '0102',
  EmailIsAlreadyInUse = '0103',
  WrongPassword = '0123',
  UpdateEmailError = '0115',
  WrongPasswordCountFallback = '0225',
  UserNotFound = '0406'
}

enum ErrorMessage {
  GetLocation = 'Get location error',
  CheckPostCode = 'Check postcode error',
  FindToken = 'Find token error',
  GetDictionary = 'Get Diactionary error',
  UpdateToken = 'Update token error',
  CreateToken = 'Create token error',
  GenerateAccessToken = 'Generate access token error',
  GenerateRefreshToken = 'Generate refresh token error',
  LoginWeb = 'Login web error',
  SignupMyself = 'Signup myself web error',
  SignupCarier = 'Signup carer web error',
  Signup = 'Signup web error',
  EmailDuplicated = 'Email already in use',
  ForgotWeb = 'Forgot password error',
  UpdateCurrent = 'Update current user error'
}

export { ErrorCode, ErrorMessage };
