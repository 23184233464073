import classNames from 'classnames';

import { $$addCareRecipient, ModalSteps } from '@widgets/add-care-recipient';

import { ConditionExperienceForm } from '@features/care-recipient/manage-condition-experience';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { useMedia } from '@shared/ui/atoms/media';

import { Modal } from '@shared/ui/atoms/modal';

import styles from './condition-experience.module.scss';

const ConditionExperience = createView()
  .units({
    onStepClicked: $$addCareRecipient.stepChanged,

    onCancel: $$addCareRecipient.closeModal
  })

  .map(() => {
    const lg = useMedia('>=lg');

    return {
      lg
    };
  })

  .view(({ onCancel, onStepClicked, lg }) => (
    <Modal.Container
      onClose={() => onCancel()}
      size='large'
      fullHeight={lg}
      overlayStyles={classNames({ [styles.overlay]: !lg })}
      className={styles.modal}
    >
      <Modal.Header step>
        <div>Add a new person</div>

        <div>
          <span className={styles.highlight}>Step 2</span> of 4
        </div>
      </Modal.Header>

      <Modal.Body>
        <h1 className={styles.modalTitle}>Condition Experience</h1>

        <ConditionExperienceForm
          model={$$addCareRecipient.$$manageConditionExperience}
        />
      </Modal.Body>

      <Modal.Footer space fill className={styles.footer}>
        <Button
          variant='text'
          palette='text'
          className={styles.cancel}
          onClick={() => onStepClicked(ModalSteps.CareNeeds)}
        >
          {useMedia('>=md') ? 'Previous step' : 'Previous'}
        </Button>

        <div className={styles.group}>
          <Button
            onClick={() => onStepClicked(ModalSteps.Cancel)}
            palette='text'
            variant='text'
            className={styles.cancel}
          >
            Cancel
          </Button>

          <Button
            onClick={() =>
              $$addCareRecipient.$$manageConditionExperience.form.submit()
            }
          >
            Next step
          </Button>
        </div>
      </Modal.Footer>
    </Modal.Container>
  ));

export { ConditionExperience };
