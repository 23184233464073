import classNames from 'classnames';

import type { FC } from 'react';

import { capitalize } from '@shared/lib/utils';

import styles from './table.module.scss';

import type {
  CellProps,
  RowProps,
  TableProps,
  TBodyProps,
  THeadProps
} from './table.props';

/**
 * <Table />
 */
const Table: FC<TableProps> = ({ className, ...props }) => (
  <div className={classNames(styles.table, className)} {...props} />
);

const Cell: FC<CellProps> = ({
  className,
  bold,
  subText,
  align,
  children,
  ...props
}) => (
  <div
    className={classNames(
      styles.cell,
      className,
      align && styles[`align${capitalize(align)}`],
      {
        [styles.bold]: bold
      }
    )}
    {...props}
  >
    <div>
      {children}

      {subText && <div className={styles.subText}>{subText}</div>}
    </div>
  </div>
);

const THead: FC<THeadProps> = ({ className, ...props }) => (
  <div className={classNames(styles.tHead, className)} {...props} />
);

const TBody: FC<TBodyProps> = ({ className, ...props }) => (
  <div className={classNames(styles.tBody, className)} {...props} />
);

const Row: FC<RowProps> = ({ className, hoverable, onClick, ...props }) => (
  <div
    className={classNames(styles.row, className, {
      [styles.hoverable]: hoverable,

      [styles.clickable]: Boolean(onClick)
    })}
    onClick={onClick}
    {...props}
  />
);

export { Table, Cell, THead, TBody, Row };
