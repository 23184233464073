import carerCardPlus from '@img/add-carer-card-plus.png';

import { Link } from 'atomic-router-react';

import type { FC } from 'react';

import { routes } from '@shared/routes';

import { Button } from '@shared/ui/atoms/button';

import styles from './add-carer.module.scss';

import type { AddCarerProps } from './add-carer.props';

/**
 * <AddCarer />
 */
const AddCarer: FC<AddCarerProps> = () => (
  <div className={styles.addCarer}>
    <Link to={routes.matches} className={styles.useless}>
      <img className={styles.plus} src={carerCardPlus} />
    </Link>

    <Link to={routes.matches}>
      <Button className={styles.add}>Find new carer</Button>
    </Link>
  </div>
);

export { AddCarer };
