import { $$logoutUser } from '@features/user/logout';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { useMedia } from '@shared/ui/atoms/media';

import { Modal } from '@shared/ui/atoms/modal';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import type { CredentialsProps } from './credential.props';

import { credentialForm } from './credentials.model';

import styles from './credentials.module.scss';

const Credentials = createView<CredentialsProps>()
  .units({
    onLogout: $$logoutUser.logout
  })

  .map(() => {
    const md = useMedia('>=md');

    return {
      md
    };
  })

  .view(({ md, onCancel, stepChanged, isAccount, onLogout }) => (
    <Modal.Container onClose={() => onCancel()} size='small'>
      <Modal.Header>
        {isAccount
          ? 'Deleting your account'
          : 'Confirm the Care Space deletion'}
      </Modal.Header>

      <Modal.Body className={styles.lowerPadding}>
        <div className={styles.content}>
          <p className={styles.info}>
            {`To delete your ${isAccount ? 'account' : 'workspace'}`} you need
            to enter your email and account password.{' '}
            <br className={styles.brTwo} />
            <a
              className={styles.link}
              onClick={() => {
                onCancel();
                onLogout('recovery');
              }}
            >
              Forgot your password? Click here.
            </a>
          </p>

          <Form className={styles.form} use={credentialForm}>
            <Field.Input
              use={credentialForm.fields.email}
              wrapperClassName={styles.email}
              type='email'
              disableAutocomplete
              placeholder='Enter'
              label='Email'
              name='email'
              errorIn='label'
            />

            <Field.Input
              use={credentialForm.fields.password}
              wrapperClassName={styles.password}
              type='password'
              disableAutocomplete
              placeholder='Enter'
              label='Password'
              name='password'
              errorIn='label'
            />
          </Form>
        </div>
      </Modal.Body>

      <Modal.Footer space className={styles.footer}>
        <Button
          palette='text'
          variant='text'
          className={styles.previous}
          onClick={() => stepChanged('attention-access')}
        >
          {!md ? 'Previous' : 'Previous step'}
        </Button>

        <div>
          <Button
            variant='text'
            palette='text'
            onClick={() => onCancel()}
            className={styles.cancel}
          >
            Cancel
          </Button>

          <Button palette='error' onClick={() => credentialForm.submit()}>
            {!md ? 'Yes, delete' : 'Yes, I want to delete'}
          </Button>
        </div>
      </Modal.Footer>
    </Modal.Container>
  ));

export { Credentials };
