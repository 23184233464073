import { useUnit } from 'effector-react';

import { useState } from 'react';

import { $$wellness } from '@entities/wellness';

import type { DashboardMarkTypes } from '@shared/api';

import { useMedia } from '@shared/ui/atoms/media';

import { initialCharts } from '../wellness.props';

const useOptionsModal = () => {
  const md = useMedia('>=md');
  const [wellnessActiveCharts] = useUnit([$$wellness.$wellnessActiveCharts]);

  const [activeCharts, setActiveCharts] = useState(wellnessActiveCharts);

  const onClose = () => {
    $$wellness.wellnessSettingsCloseClicked();
  };

  const onSaveChangesClick = () => {
    $$wellness.activeWellnessCharsChanged(activeCharts);
  };

  const onChartClick = (type: DashboardMarkTypes) => {
    setActiveCharts(
      activeCharts.includes(type)
        ? activeCharts.filter(activeType => activeType !== type)
        : [...activeCharts, type]
    );
  };

  return {
    md,
    onClose,
    allCharts: initialCharts,
    activeCharts,
    onChartClick,
    onSaveChangesClick
  };
};

export { useOptionsModal };
