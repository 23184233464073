import { createEvent, createStore, sample } from 'effector';

import { $$careRecipient } from '@entities/care-recipient';

import { $$careSpace } from '@entities/care-space';

import { $$dictionary } from '@entities/dictionary';

import { $$members } from '@entities/members';

import type { Entry, WorkspaceMemberAccount } from '@shared/api';

import { ToastID } from '@shared/config';

import type { NotifyOptions } from '@shared/ui/organisms/toasts';

import { $$toast } from '@shared/ui/organisms/toasts';

const $visible = createStore(false);

const $selectedRelation = createStore<Entry>(null);

const $selectedMember = createStore<WorkspaceMemberAccount>(null);

const modalOpened = createEvent<{
  account: WorkspaceMemberAccount;
  clientRelation: Entry;
}>();

const modalClosed = createEvent();

const submitChanges = createEvent();

const changeRelation = createEvent<number>();

const $$contactEditRelationship = {
  $selectedRelation,

  $selectedMember,

  $visible,

  modalOpened,

  submitChanges,

  changeRelation,

  modalClosed
};

sample({
  clock: changeRelation,

  source: $$dictionary.$relationships,

  fn: (value, relation) => value.find(item => item.id === relation) as any,

  target: $selectedRelation
});

sample({
  clock: submitChanges,

  source: {
    client: $$careRecipient.$careRecipientsProfile,
    account: $selectedMember,
    relation: $selectedRelation
  },

  fn: ({ client, account, relation }) => [
    {
      clientId: client.id,
      email: account.email,
      relation
    }
  ],

  target: $$careRecipient.updateMemberRelationsFx
});

sample({
  clock: $$careRecipient.updateMemberRelationsFx.doneData.map(
    ([relation]) => relation.email
  ),

  fn: email =>
    ({
      id: ToastID.RelationsWereUpdated,
      title: 'Relations updated',
      content: `${email}'s relations were updated`,
      type: 'success'
    } as NotifyOptions),

  target: $$toast.notifyFx
});

sample({
  clock: $$careRecipient.updateMemberRelationsFx.doneData,

  source: $$careSpace.$careSpaceID,

  target: [$$members.getMembersFx, modalClosed]
});

$visible.on(modalOpened, () => true).reset(modalClosed);

$selectedRelation.on(modalOpened, (_state, payload) => payload.clientRelation);

$selectedMember.on(modalOpened, (_state, payload) => payload.account);

export { $$contactEditRelationship };
