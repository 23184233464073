import type { Effect, Event, Store } from 'effector';

import { createEffect } from 'effector';

import { createEvent } from 'effector';

import { sample } from 'effector';

import { combine, createStore } from 'effector';

import { createGate } from 'effector-react';

import { interval } from 'patronum';

type RecallOptions<
  D,
  P,
  ID extends string | number,
  ErrorState = any,
  E = any
> = {
  use: Effect<P, D[], E>;

  params: Store<P>;

  recallOnWindowFocus: boolean;

  recallAfter: number;

  pauseOnError: boolean;

  removed: Event<ID>;

  unshifted: Event<D>;

  pushed: Event<D>;

  updated: Event<D>;

  id: (item: D) => ID;

  data: (payload: D[], meta: { params: P; data: D[] }) => D[];

  error: (error: E) => ErrorState;
};

type RecallModel<D, ID, P, ErrorState> = {
  $error: Store<ErrorState>;

  $pending: Store<boolean>;

  $params: Store<P>;

  $data: Store<D[]>;

  $ids: Store<ID[]>;

  $map: {
    [x: string]: D;
  };

  pause: Event<void>;

  unpause: Event<void>;

  recall: Event<void>;

  enable: Event<void>;

  disable: Event<void>;

  reset: Event<void>;
};

const createRecallFactory = (ssr = false) => {
  const VisibilityChangeGate = createGate();

  const visibilityChanged = createEvent<boolean>();

  const listenVisibilityChangeFx = createEffect(() => {
    document.addEventListener('visibilitychange', () => {
      visibilityChanged(document.visibilityState == 'visible');
    });
  });

  if (!ssr) {
    sample({
      clock: VisibilityChangeGate.open,

      target: listenVisibilityChangeFx
    });
  }

  const createRecall = <
    D,
    P,
    ID extends string | number,
    ErrorState = any,
    E = any
  >({
    pushed,
    removed,
    unshifted,
    updated,
    id: selectId,
    data: mapData,
    error: mapError,
    params: $params,
    use: getDataFx,
    ...options
  }: RecallOptions<D, P, ID, ErrorState, E>) => {
    const reset = createEvent();

    const pause = createEvent();

    const unpause = createEvent();

    const recall = createEvent();

    const enable = createEvent();

    const disable = createEvent();

    const $enabled = createStore(false);

    const $pending = getDataFx.pending;

    const $error = createStore<ErrorState>(null!);

    const $ids = createStore<ID[]>([]);

    const $map = createStore<{
      [x: string]: D;
    }>({} as {});

    const $data = combine($ids, $map, (ids, map) =>
      ids.map(id => map[id as keyof typeof map])
    );

    const loaded = sample({
      clock: getDataFx.done,

      source: $data,

      fn: (data, { params, result }) => {
        if (!mapData) return result;

        return mapData(result, { params, data });
      }
    });

    $enabled
      .on(enable, () => true)

      .reset(disable);

    $ids
      .on(loaded, (_, payload) => payload.map(selectId))

      .reset(reset, disable);

    $map
      .on(loaded, (_, payload) =>
        payload.reduce<Record<string, D>>((result, item) => {
          result[selectId(item) as any as keyof typeof result] = item;

          return result;
        }, {})
      )

      .reset(reset, disable);

    $error
      .on(
        getDataFx.failData,
        (_, payload) => (mapError ? mapError(payload) : payload) as ErrorState
      )

      .reset(getDataFx.done, reset);

    if (pushed) {
      $ids.on(pushed, (state, payload) => [...state, selectId(payload)]);
    }

    if (unshifted) {
      $ids.on(unshifted, (state, payload) => [selectId(payload), ...state]);
    }

    if (removed) {
      $ids.on(removed, (state, payload) =>
        state.filter(item => item != payload)
      );

      $map.on(removed, (state, payload) => {
        const result = { ...state };

        delete result[payload as keyof typeof result];

        return result;
      });
    }

    if (pushed || unshifted) {
      $map.on(
        [pushed, unshifted].filter(Boolean),

        (state, payload) => ({
          ...state,

          [selectId(payload)]: payload
        })
      );
    }

    if (updated) {
      $map.on(updated, (state, payload) => ({
        ...state,

        [selectId(payload)]: payload
      }));
    }

    if (!ssr && options.recallAfter) {
      const $passed = createStore(0);

      const start = sample({
        clock: [getDataFx.done],

        source: $error,

        filter: error => !options.pauseOnError || !error
      });

      const stop = sample({
        clock: [
          sample({
            clock: [$passed.updates, disable],

            filter: value => value * 1000 == options.recallAfter
          }),

          sample({
            clock: getDataFx.failData,

            filetr: () => options.pauseOnError
          })
        ]
      });

      const { tick } = interval({
        timeout: 1000,

        stop,

        start
      });

      sample({
        clock: $params.updates,

        source: $enabled,

        filter: enabled => enabled,

        fn: (_, params) => params,

        target: getDataFx
      });

      sample({
        clock: [recall, enable],

        source: $params,

        target: getDataFx
      });

      $passed
        .on(tick, state => state + 1)

        .reset(getDataFx.finally, reset);
    }

    if (!ssr && options.recallOnWindowFocus) {
      sample({
        clock: visibilityChanged,

        filter: visible => visible,

        target: recall
      });
    }

    return {
      $error,

      $pending,

      $params,

      $data,

      $ids,

      // types unmatch bc of id
      $map: $map as any,

      pause,

      unpause,

      recall,

      disable,

      enable
    } as RecallModel<D, ID, P, ErrorState>;
  };

  return { createRecall, VisibilityChangeGate };
};

const { createRecall, VisibilityChangeGate } = createRecallFactory();

export { createRecall, VisibilityChangeGate };
