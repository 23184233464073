import classNames from 'classnames';

import { DashboardMarkTypes } from '@shared/api';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { Checkbox } from '@shared/ui/atoms/checkbox';

import { Modal } from '@shared/ui/atoms/modal';

import styles from './options-modal.module.scss';

import { useOptionsModal } from './options-modal.props';

const OptionsModal = createView()
  .map(useOptionsModal)

  .view(
    ({
      onClose,
      allCharts,
      activeCharts,
      onChartClick,
      onSaveChangesClick,
      md
    }) => (
      <Modal.Container onClose={onClose} size='small'>
        <Modal.Header>
          <h1 className={styles.title}>Customize charts to display</h1>
        </Modal.Header>

        <Modal.Body className={styles.modalContent}>
          <p className={styles.info}>
            You can customize the display of your important data with single
            click
          </p>

          <p className={styles.info}>
            Selected:{' '}
            <span className={styles.selectedNum}>{activeCharts?.length}</span>{' '}
            of {allCharts.length}
          </p>

          <div className={styles.optionsContainer}>
            <div
              className={classNames(styles.option, {
                [styles.active]: activeCharts.includes(DashboardMarkTypes.Mood)
              })}
            >
              <div>
                <h2 className={styles.optionTitle}>Mood</h2>
                <p className={styles.optionDesc}>
                  How are you feeling today {!md && <br />} on a scale from 0 to
                  10
                </p>
              </div>

              <Checkbox
                value={activeCharts.includes(DashboardMarkTypes.Mood)}
                onChange={() => onChartClick(DashboardMarkTypes.Mood)}
              />
            </div>
            <div
              className={classNames(styles.option, {
                [styles.active]: activeCharts.includes(DashboardMarkTypes.Sleep)
              })}
            >
              <div>
                <h2 className={styles.optionTitle}>Sleep</h2>
                <p className={styles.optionDesc}>
                  How well did you sleep last night {!md && <br />} on a scale
                  from 0 to 10
                </p>
              </div>

              <Checkbox
                value={activeCharts.includes(DashboardMarkTypes.Sleep)}
                onChange={() => onChartClick(DashboardMarkTypes.Sleep)}
              />
            </div>
            <div
              className={classNames(styles.option, {
                [styles.active]: activeCharts.includes(DashboardMarkTypes.Pain)
              })}
            >
              <div>
                <h2 className={styles.optionTitle}>Pain</h2>
                <p className={styles.optionDesc}>
                  How is your pain today on a scale {!md && <br />} from 0 to 10
                </p>
              </div>

              <Checkbox
                value={activeCharts.includes(DashboardMarkTypes.Pain)}
                onChange={() => onChartClick(DashboardMarkTypes.Pain)}
              />
            </div>
            <div
              className={classNames(styles.option, {
                [styles.active]: activeCharts.includes(DashboardMarkTypes.Meals)
              })}
            >
              <div>
                <h2 className={styles.optionTitle}>Meals</h2>
                <p className={styles.optionDesc}>
                  How many meals did you {!md && <br />} eat yesterday?
                </p>
              </div>

              <Checkbox
                value={activeCharts.includes(DashboardMarkTypes.Meals)}
                onChange={() => onChartClick(DashboardMarkTypes.Meals)}
              />
            </div>
            <div
              className={classNames(styles.option, {
                [styles.active]: activeCharts.includes(
                  DashboardMarkTypes.BowelMovements
                )
              })}
            >
              <div>
                <h2 className={styles.optionTitle}>Bowel movements</h2>
                <p className={styles.optionDesc}>
                  Did you have a BM in the {!md && <br />} last 24 hours?
                </p>
              </div>

              <Checkbox
                value={activeCharts.includes(DashboardMarkTypes.BowelMovements)}
                onChange={() => onChartClick(DashboardMarkTypes.BowelMovements)}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className={styles.modalFooter}>
          <Button palette='text' onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onSaveChangesClick}>Save changes</Button>
        </Modal.Footer>
      </Modal.Container>
    )
  );

export { OptionsModal };
