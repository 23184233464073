import { sample } from 'effector';

import { $$signUpMyself } from '@pages/sign-up/pages/myself';

import { routes } from '@shared/routes';

import { $myselfValues } from './shared';

const { submitted } = $$signUpMyself.steps.careNeeds.form;

$myselfValues.on(submitted, (state, values) => ({
  ...state,

  careNeeds: values
}));

sample({
  clock: submitted,

  source: routes.signUp.myself.careNeeds.$params,

  target: routes.signUp.myself.conditionExperience.open
});

sample({
  clock: routes.signUp.myself.careNeeds.opened,

  source: $myselfValues,

  fn: values => values.careNeeds ?? null,

  target: $$signUpMyself.steps.careNeeds.form.reset
});
