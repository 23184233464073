import { createStore, sample } from 'effector';

import { object, string } from 'yup';

import { ToastText } from '@features/account/personal-details/ui/toast';

import { $$accountInfo } from '@entities/account';

import { ToastID } from '@shared/config';

import { createForm } from '@shared/lib/form';

import { nameRegEx, passwordRegEx, phoneRegEx } from '@shared/lib/utils';

import type { NotifyOptions } from '@shared/ui/organisms/toasts';

import { $$toast } from '@shared/ui/organisms/toasts';

const $error = createStore(false);

const $errorEmail = createStore(false);

const $errors = createStore({});

const form = createForm({
  initialValues: {
    firstName: '',

    lastName: '',

    email: '',

    password: '',

    phone: ''
  },

  $errors,

  $schema: createStore(
    object({
      firstName: string()
        .required()

        .nullable()

        .matches(nameRegEx, 'Invalid')

        .trim('Required'),

      lastName: string()
        .required()

        .nullable()

        .matches(nameRegEx, 'Invalid')

        .trim('Required'),

      phone: string()
        .nullable()

        .required()

        .label('Phone')

        .min(2, 'Required')

        .matches(phoneRegEx, 'Invalid'),

      email: string()
        .trim()

        .nullable()

        .email('Incrorrect')

        .label('Email'),

      password: string()
        .when('email', {
          is: (value: string) => value,
          then: string()
            .required('Required')

            .matches(passwordRegEx, 'Incorrect')
        })

        .trim()

        .nullable()

        .label('Password')
    })
  )
});

const $$personalDetails = {
  form
};

sample({
  source: $error,

  fn: error =>
    error
      ? {
          password: 'Is incorrect'
        }
      : {},

  target: $errors
});

sample({
  source: $errorEmail,

  fn: error =>
    error
      ? {
          email: 'Invalid'
        }
      : {},

  target: $errors
});

sample({
  clock: form.submitted,

  target: $$accountInfo.updatePersonalDetailsFx
});

sample({
  clock: $$accountInfo.updatePersonalDetailsFx.done,

  filter: ({ params }) => Boolean(params?.email),

  fn: ({ params }) =>
    ({
      id: ToastID.EmailChanged,

      type: 'success',

      title: `<div style="word-break: break-all;">Email changed to ${params?.email}</div>`,

      content: ToastText
    } as NotifyOptions),

  target: $$toast.notifyFx
});

sample({
  clock: $$accountInfo.getAccountFx.doneData,

  fn: ({ firstName, lastName, phone }) => ({
    firstName: firstName ?? '',

    lastName: lastName ?? '',

    phone: phone ?? ''
  }),

  target: form.patch
});

sample({
  clock: $$accountInfo.updatePersonalDetailsFx.failData,

  filter: (data: any) => data.response.data.code === '0102',

  fn: () => true,

  target: $errorEmail
});

sample({
  clock: $$accountInfo.updatePersonalDetailsFx.failData,

  filter: (data: any) =>
    data.response.data.code === '0123' || data.response.data.statusCode === 400,

  fn: () => true,

  target: $error
});

$errorEmail.reset([
  form.submitted,
  $$accountInfo.getAccountFx.done,
  $$accountInfo.updatePersonalDetailsFx.done
]);

$error.reset([
  form.submitted,
  $$accountInfo.getAccountFx.done,
  $$accountInfo.updatePersonalDetailsFx.done
]);

export { $$personalDetails, form };
