const TOKEN_QUERY_KEY = 'token';

const POSTCODE_QUERY_KEY = 'postcode';

const SKILLS_CONFIRMED_QUERY_KEY = 'skillsConfirmed';

const SIGNUP_TYPE_QUERY_KEY = 'signUpType';

const SIGN_UP_STEP_QUERY_KEY = 'signUpStep';

export {
  TOKEN_QUERY_KEY,
  POSTCODE_QUERY_KEY,
  SIGNUP_TYPE_QUERY_KEY,
  SIGN_UP_STEP_QUERY_KEY,
  SKILLS_CONFIRMED_QUERY_KEY
};
