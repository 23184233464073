import classNames from 'classnames';

import { useMemo } from 'react';

import { NoCareRecipients } from '@pages/care-recipients/ui/no-care-recipients';

import { $$addCareRecipient } from '@widgets/add-care-recipient';

import { $$careSpace } from '@entities/care-space';

import { $$drafts } from '@entities/drafts';

import { MembersRoleType } from '@shared/api';

import { sortByCreationDate, sortByDeletedStatus } from '@shared/lib/utils';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { Card } from '@shared/ui/atoms/card';

import { ClientBox } from '@shared/ui/atoms/client-box';

import { $$careRecipientsPage } from '../../care-recipients.model';

import styles from './persons.module.scss';

/**
 * <Persons />
 */
const Persons = createView()
  .units({
    id: $$careRecipientsPage.$id,

    careSpace: $$careSpace.$careSpace,

    drafts: $$drafts.$drafts,

    onCareRecipientClick: $$careRecipientsPage.personClicked,

    openModal: $$addCareRecipient.openModal
  })

  .map(({ drafts, careSpace }) => {
    const clients = careSpace?.clients ?? [];

    const [data, draftStartIndex] = useMemo(() => {
      const clietnsCp = [...clients];
      const draftsCp = [...drafts];

      clietnsCp.sort(sortByCreationDate);

      clietnsCp.sort(sortByDeletedStatus);

      if (!draftsCp.length) {
        return [clietnsCp, clietnsCp.length];
      }

      if (!clietnsCp.length) {
        return [draftsCp, 0];
      }

      return [[...clietnsCp, 'separator', ...draftsCp], clietnsCp.length + 1];
    }, [clients, drafts]);

    const role = careSpace?.role ?? null;

    return {
      draftStartIndex,

      role,

      data
    };
  })

  .view(
    ({ id, data, role, onCareRecipientClick, openModal, draftStartIndex }) => (
      <Card className={styles.persons}>
        <div className={styles.header}>Care is for</div>

        <div
          className={classNames(styles.content, {
            [styles.contentNotEditable]: role !== MembersRoleType.Owner,
            [styles.contentEmpty]: !data?.length
          })}
        >
          {data?.map((recipient, index) => {
            if (typeof recipient == 'string') {
              return <div className={styles.divider} key='separator' />;
            }

            return (
              <ClientBox
                className={styles.client}
                key={recipient?.id}
                draft={index >= draftStartIndex}
                active={id === recipient?.id}
                recipient={recipient}
                onClick={() => {
                  onCareRecipientClick(recipient?.id);
                }}
              />
            );
          })}

          {!data?.length && <NoCareRecipients minified />}
        </div>

        {role === MembersRoleType.Owner && (
          <div className={styles.footer}>
            <Button onClick={() => openModal()}>Add new care recipient</Button>
          </div>
        )}
      </Card>
    )
  );

export { Persons };
