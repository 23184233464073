import classNames from 'classnames';

import { useState } from 'react';

import { createView } from '@shared/lib/view';

import { Toast } from '@shared/ui/atoms/toast';

import { $toasts, closeByKey } from '../model';

import styles from './toasts.module.scss';

type StackProps = {
  count: number;

  maxWidth: number;

  children: (expanded: boolean) => any;
};

const Stack = createView<StackProps>()
  .map(() => {
    const [expanded, setExpanded] = useState(false);

    const onClick = () => {
      if (expanded) return;

      setExpanded(true);
    };

    return {
      onClick,
      expanded
    };
  })

  .view(({ onClick, maxWidth, children, count, expanded }) => (
    <div
      className={classNames(styles.stack, {
        [styles.expanded]: expanded
      })}
      style={{
        maxWidth: maxWidth ? `${maxWidth}px` : 'unset'
      }}
      onClick={onClick}
      data-count={Math.min(count - 1, 2)}
    >
      <div className={styles.stackToasts}>{children(expanded)}</div>
    </div>
  ));

const Toasts = createView()
  .units({
    toasts: $toasts,

    onClose: closeByKey
  })

  .view(({ toasts, onClose }) => (
    <div className={styles.container}>
      {toasts.map(stack => (
        <Stack
          count={stack.toasts.length}
          key={stack.key}
          maxWidth={stack.toasts[0].maxWidth}
        >
          {expanded => (
            <>
              {stack.toasts.map(item => (
                <Toast
                  maxWidth={item.maxWidth}
                  key={item.key}
                  stacked={expanded ? 0 : Math.min(stack.toasts.length - 1, 2)}
                  type={item.type ?? 'success'}
                  onClose={() => {
                    onClose(item.key);
                  }}
                  title={
                    typeof item.title == 'function' ? (
                      item.title()
                    ) : (
                      <div dangerouslySetInnerHTML={{ __html: item.title }} />
                    )
                  }
                >
                  {typeof item.content == 'function' ? (
                    item.content()
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: item.content }} />
                  )}
                </Toast>
              ))}
            </>
          )}
        </Stack>
      ))}
    </div>
  ));

export { Toasts };
