import { attach, createEffect, sample } from 'effector';

import { $$careSpace } from '@entities/care-space';

import { $$user } from '@entities/user';

import type { Workspace } from '@shared/api';

import { carersHQ } from '@shared/api';

import { routes } from '@shared/routes';

const createCareSpaceFx = createEffect<void, Workspace['id']>();

const getAccountAfterCreationFx = attach({
  effect: $$user.getAccountFx
});

const careSpaceCreated = getAccountAfterCreationFx.doneData.map(
  () => void null
);

const $$createCareSpace = {
  createCareSpaceFx,

  careSpaceCreated
};

sample({
  clock: createCareSpaceFx.doneData,

  target: [$$careSpace.$careSpaceID, getAccountAfterCreationFx]
});

sample({
  clock: getAccountAfterCreationFx.doneData,

  target: routes.schedule.open.prepend(() => ({}))
});

createCareSpaceFx.use(async () => {
  const {
    data: { workspaceId }
  } = await carersHQ.settings.createNewWorkspace();

  return workspaceId;
});

export { $$createCareSpace };
