import axios from 'axios';

import { sample } from 'effector';

import { $$user } from '@entities/user';

import type { Response } from '@shared/api';

import { carersHQ, ErrorCode } from '@shared/api';

const accountBlocked = carersHQ.http.baseRequestFx.failData.filter({
  fn: error =>
    axios.isAxiosError(error) &&
    (error.response?.data as Response)?.code ==
      ErrorCode.WrongPasswordCountFallback
});

sample({
  clock: accountBlocked,

  target: $$user.accountBlocked
});
