import { createEvent, sample } from 'effector';

import { combineEvents, condition } from 'patronum';

import { $$invite } from '@features/auth/invite';

import { $$signInByEmail } from '@features/auth/sign-in-by-email';

import { $$signUp } from '@features/auth/sign-up/model/sign-up';

import { $$carerProfile } from '@entities/carer';

import { $$session, RoleType } from '@entities/session';

import { routes } from '@shared/routes';

const startRouteRedirectTriggered = createEvent<RoleType>();

const carerRedirectTriggered = combineEvents({
  events: {
    first: startRouteRedirectTriggered,
    second: $$carerProfile.getCarerPublicProfileFx.doneData
  }
});

const logged = sample({
  clock: [
    // TODO: provide starter roles there
    $$signInByEmail.loginByEmailFx.doneData,

    sample({
      clock: [
        $$signUp.signUpCarerFx.doneData,
        $$signUp.signUpLovedFx.doneData,
        $$signUp.signUpMyselfFx.doneData
      ]
    }),

    $$invite.signUpInviteFx.doneData
  ],

  source: $$session.$role,

  fn: (role, { accessToken }) => ({
    role,

    accessToken
  })
});

const $$authorize = {
  logged,

  startRouteRedirectTriggered
};

sample({
  clock: logged,

  fn: ({ accessToken }) => accessToken,

  target: $$session.started
});

condition({
  source: startRouteRedirectTriggered,

  if: role => role == RoleType.CareRecipient,

  then: routes.matches.open.prepend(() => ({}))
});

condition({
  source: carerRedirectTriggered,

  if: ({ first }) => first == RoleType.Carer,

  then: routes.profile.open.prepend(() => ({}))
});

export { $$authorize };
