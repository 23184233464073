import { createEvent, createStore, sample } from 'effector';

import { $$deletedAccount } from '@entities/deleted-account';

import { routes } from '@shared/routes';

const $params = routes.deletedAccount.invoices.$query.map(query => {
  if (query.search) {
    return {
      status: query.status ?? 'All',
      search: query.search
    };
  }

  return {
    status: query.status ?? 'All'
  };
});

const $searchText = createStore('');

const tabChanged = createEvent<string>();

const searchTextChanged = createEvent<string>();

const selectUserClicked = createEvent<string>();

const loadMoreInvoices = createEvent<number>();

const $$invoices = {
  $params,

  $searchText,

  tabChanged,

  searchTextChanged,

  selectUserClicked,

  loadMoreInvoices
};

sample({
  clock: [routes.deletedAccount.invoices.opened, $params.updates],

  source: $params,

  fn: params => ({
    from: 0,
    search: params.search,
    status: params.status == 'All' ? null : params.status
  }),

  target: $$deletedAccount.getInvoicesFx
});

sample({
  clock: tabChanged,

  source: $params,

  fn: (params, status) =>
    ({
      params: {},
      query: { ...params, status: status }
    } as any),

  target: routes.deletedAccount.invoices.navigate
});

sample({
  clock: searchTextChanged,

  source: $params,

  fn: (params, search) =>
    ({
      params: {},
      query: { ...params, search: search }
    } as any),

  target: routes.deletedAccount.invoices.navigate
});

sample({
  clock: selectUserClicked,

  fn: id => ({ id }),

  target: routes.deletedAccount.personInvoice.open
});

sample({
  clock: loadMoreInvoices,

  fn: value => ({
    from: value
  }),

  target: $$deletedAccount.getInvoicesFx
});

$searchText.on(searchTextChanged, (_state, payload) => payload);

export { $$invoices };
