import { sample } from 'effector';

import { $$deletedAccount } from '@entities/deleted-account';

import { routes } from '@shared/routes';

const $id = routes.deletedAccount.personInvoice.$params.map(
  ({ id }) => id ?? ''
);

const $$personInvoice = {
  $id
};

sample({
  clock: routes.deletedAccount.personInvoice.opened,

  source: $id,

  fn: id => id,

  target: $$deletedAccount.getInvoiceFx
});

export { $$personInvoice };
