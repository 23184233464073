import { sample } from 'effector';

import { $$signUpLovedOne } from '@pages/sign-up/pages/loved-one';

import { $$addUserRole } from '@features/user/add-role';

import { $$session } from '@entities/session';

import { $lovedOneValues, lovedOneValuesToDto } from './shared';

const submitted = sample({
  clock: $$signUpLovedOne.steps.careRecipient.form.submitted,

  source: {
    values: $lovedOneValues
  },

  filter: $$session.$active,

  fn: ({ values }, careRecipient) =>
    lovedOneValuesToDto({
      ...values,

      careRecipient
    })
});

sample({
  clock: submitted,

  target: $$addUserRole.addLovedOneRoleFx
});
