import { useStore, useUnit } from 'effector-react';

import { useEffect, useMemo, useRef } from 'react';

import { $$address } from '@entities/address';

import type { Entry } from '@shared/api';

import type { FormModel } from '@shared/lib/form';

import { useUpdate } from '@shared/lib/hooks';

const usePostCodeAddresses = (
  postCode: string,
  error: string,

  form: FormModel<{
    city: string;
    addressLine1: string;
    addressLine2: string;
  }>
) => {
  const timer = useRef<any>();

  const patch = useUnit(form.patch);

  const results = useStore($$address.$results);

  const onSuggestionSelect = (name: string) => (item: Entry) => {
    patch({
      [name]: item.name
    });
  };

  const { cities, addressLines1, addressLines2 } = useMemo(() => {
    if (!results) {
      return {
        cities: [],
        addressLines1: [],
        addressLines2: []
      };
    }

    const _cities = new Set<string>();
    const _addressLines1 = new Set<string>();
    const _addressLines2 = new Set<string>();

    results.forEach(item => {
      _cities.add(item.city);
      _addressLines1.add(item.line1);
      _addressLines2.add(item.line2);
    });

    return Object.entries({
      cities: _cities,
      addressLines1: _addressLines1,
      addressLines2: _addressLines2
    }).reduce<Record<any, any>>((result, [key, value]) => {
      result[key] = Array.from(value)
        .filter(item => item && item?.length > 0)

        .map(name => ({ name }));

      return result;
    }, {}) as {
      cities: Entry[];
      addressLines1: Entry[];
      addressLines2: Entry[];
    };
  }, [results]);

  useEffect(() => {
    if (!postCode || error) return;

    clearTimeout(timer.current);

    timer.current = setTimeout(() => {
      $$address.findAddressesByPostcodeFx(postCode);
    }, 300);
  }, [postCode, error]);

  useUpdate(() => {
    patch({
      city: '',
      addressLine1: '',
      addressLine2: ''
    });
  }, [postCode]);

  return {
    cities,
    addressLines1,
    addressLines2,
    onSuggestionSelect
  };
};

export { usePostCodeAddresses };
