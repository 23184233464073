import { createEffect, createStore, sample } from 'effector';

import { boolean, object, string } from 'yup';

import type { InviteParamsDto, InviteResponseDto } from '@shared/api';

import { carersHQ } from '@shared/api';

import { createForm } from '@shared/lib/form';

import { nameRegEx, passwordRegEx } from '@shared/lib/utils';

import { routes } from '@shared/routes';

const getTokenDataFx = createEffect<string, any>();

const signUpInviteFx = createEffect<InviteParamsDto, InviteResponseDto>();

const $schema = createStore<any>(null);

const form = createForm({
  initialValues: {
    password: '',

    confirmPassword: '',

    firstName: '',

    lastName: '',

    phone: '',

    terms: false,

    policy: false
  },

  $schema
});

const $$invite = {
  form,

  getTokenDataFx,

  signUpInviteFx
};

sample({
  clock: [
    form.$values,
    form.$values.updates,
    routes.auth.signUpInvite.$isOpened
  ],

  source: form.$values,

  fn: values => {
    const fields = {
      password: string()
        .nullable()

        .required()

        .trim('Required')

        .label('Password')

        .matches(
          passwordRegEx,

          'Least 6 digits long and contain numbers and upper case'
        )

        .min(6, 'Least 6 digits long and contain numbers and upper case'),

      confirmPassword: string()
        .nullable()

        .required()

        .trim('Required')

        .label('Confirm password'),

      firstName: string()
        .required()

        .nullable()

        .trim('Required')

        .matches(nameRegEx, 'Invalid')

        .label('First Name'),

      lastName: string()
        .required()

        .nullable()

        .trim('Required')

        .matches(nameRegEx, 'Invalid')

        .label('Last Name'),

      phone: string()
        .required()

        .nullable()

        .trim('Required')

        .min(2, 'Required')

        .matches(/^\+?\d+$/, 'Invalid')

        .label('Phone'),

      terms: boolean()
        .oneOf([true], 'Required')

        .nullable()

        .required()

        .label('Terms'),

      policy: boolean()
        .oneOf([true], 'Required')

        .nullable()

        .required()

        .label('Privacy policy')
    };

    if (values.confirmPassword) {
      fields.password = fields.password
        .required()
        .equals(
          [values.confirmPassword],
          `Passwords don't match. Check it and try again`
        ) as any;
    }

    if (values.password) {
      fields.confirmPassword = fields.confirmPassword
        .required()
        .equals(
          [values.password],
          `Passwords don't match. Check it and try again`
        ) as any;
    }

    return object(fields);
  },

  target: $schema
});

getTokenDataFx.use(async params => {
  const response = await carersHQ.auth.preSignUp(params);

  return response.data;
});

signUpInviteFx.use(async data => {
  const response = await carersHQ.auth.signUpInvite(data);

  return response.data;
});

export { $$invite };
