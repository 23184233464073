import { createStore, sample } from 'effector';

import { $$invite } from '@features/auth/invite';

import { routes } from '@shared/routes';

const $token = routes.auth.signUpInvite.$query.map(state => state.token);

const $userInfo = createStore(null);

const $$invitePage = {
  $userInfo
};

sample({
  clock: routes.auth.signUpInvite.opened,

  source: $token,

  fn: token => token,

  target: $$invite.getTokenDataFx
});

sample({
  clock: $$invite.form.submitted,

  source: {
    userInfo: $userInfo,
    token: $token
  },

  fn: ({ userInfo, token }, data) => ({
    token,
    email: userInfo?.email,
    firstName: data?.firstName,
    lastName: data?.lastName,
    password: data?.password,
    confirmPassword: data?.confirmPassword,
    phone: data?.phone
  }),

  target: $$invite.signUpInviteFx
});

$userInfo.on($$invite.getTokenDataFx.doneData, (_state, payload) => payload);

export { $$invitePage };
