import { $$signUpMyself } from '@pages/sign-up/pages/myself';

import { routes } from '@shared/routes';

import { createMyselfStep } from './shared';

createMyselfStep({
  key: 'preferences',

  form: $$signUpMyself.steps.preferences.form,

  route: routes.signUp.myself.preferences,

  nextRoute: routes.signUp.myself.personalInformation,

  mapValues: values => values.preferences,

  processValues: values => ({
    ...values,

    languages: values.languages.filter(Boolean)
  }),

  checkValues: values => Boolean(values.conditionExperience)
});
