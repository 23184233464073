import { createEffect, createStore } from 'effector';

import { persist } from 'effector-storage/local';

import type { AccountWorkspace, Workspace } from '@shared/api';

import { WorkspaceStatus } from '@shared/api';

import { carersHQ } from '@shared/api';

import { CARE_SPACE_ID_KEY } from '@shared/config';

const loadCareSpaceFx = createEffect<Workspace['id'], Workspace>();

const $careSpaceID = createStore<Workspace['id'] | null>(null);

const $accountCareSpaces = createStore<AccountWorkspace[]>([]);

const $activeAccountCareSpaces = $accountCareSpaces.map(state =>
  state.filter(item => item.workspace.status == WorkspaceStatus.Active)
);

const $careSpace = createStore<Workspace>(null!);

const $$careSpace = {
  $careSpace,
  $careSpaceID,
  $accountCareSpaces,
  $activeAccountCareSpaces,

  loadCareSpaceFx
};

persist({
  store: $careSpaceID,

  key: CARE_SPACE_ID_KEY
});

loadCareSpaceFx.use(async id => {
  const { data } = await carersHQ.workspaces.getWorkspace({ id });

  return data;
});

$careSpace.on(loadCareSpaceFx.doneData, (_state, payload) => payload);

export { $$careSpace };
