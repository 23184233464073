import { attach, createEvent, sample } from 'effector';

import { condition } from 'patronum';

import { $$careRecipient } from '@entities/care-recipient';

import { $$careSpace, $$member } from '@entities/care-space';

import { $$user } from '@entities/user';

import type { Account } from '@shared/api';

import { WorkspaceStatus } from '@shared/api';

import { atom } from '@shared/lib/factory';

const $$loadClientData = atom('Load client general data', () => {
  const load = createEvent<Account>();

  const loaded = createEvent();

  const loadCareSpaceFx = attach({
    effect: $$careSpace.loadCareSpaceFx
  });

  const getCareSpaceMembersFx = attach({
    effect: $$member.getCareSpaceMembersFx
  });

  const careSpaceIDLoaded = sample({
    clock: load,

    source: $$careSpace.$careSpaceID,

    fn: (id, account) => {
      const careSpace =
        account.accountWorkspaces.find(one => one.workspace.id == id) ??
        account.accountWorkspaces[0];

      if (!careSpace || careSpace.workspace.status != WorkspaceStatus.Active)
        return null;

      return careSpace.workspace.id;
    }
  });

  sample({
    clock: load,

    fn: account => account.accountWorkspaces,

    target: $$careSpace.$accountCareSpaces
  });

  sample({
    clock: load,

    target: $$user.$account
  });

  sample({
    clock: careSpaceIDLoaded,

    target: $$careSpace.$careSpaceID
  });

  condition({
    source: careSpaceIDLoaded,

    if: Boolean,

    then: loadCareSpaceFx,

    else: loaded
  });

  sample({
    clock: loadCareSpaceFx.doneData,

    target: $$careSpace.$careSpace
  });

  sample({
    clock: loadCareSpaceFx.doneData.map(data => data.clients),

    target: $$careRecipient.$careRecipients
  });

  sample({
    clock: loadCareSpaceFx.doneData,

    fn: ({ id }) => id,

    target: getCareSpaceMembersFx
  });

  sample({
    clock: getCareSpaceMembersFx.doneData,

    target: $$member.membersLoaded
  });

  sample({
    clock: getCareSpaceMembersFx.finally,

    target: loaded.prepend(() => {})
  });

  sample({
    clock: loadCareSpaceFx.doneData,

    source: $$careRecipient.$careRecipientID,

    filter: (id, { clients }) => !clients.some(one => one.id == id),

    fn: (_, { clients }) => clients[0]?.id ?? null,

    target: $$careRecipient.$careRecipientID
  });

  return {
    load,
    loaded
  };
});

export { $$loadClientData };
