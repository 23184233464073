import { $$dictionary } from '@entities/dictionary';

import type { FormModel } from '@shared/lib/form';

import { useField } from '@shared/lib/form';

import { createView } from '@shared/lib/view';

import { Checkbox } from '@shared/ui/atoms/checkbox';

const NoneOfTheAbove = createView<{ form: FormModel<any> }>()
  .units({
    conditionsTypes: $$dictionary.$conditionTypes
  })

  .map(({ conditionsTypes, form }) => {
    const { value, onChange } = useField(form.fields.conditionsTypes);

    const indeterminate = value?.length == 0;

    return {
      indeterminate,

      onChange: () =>
        onChange(indeterminate ? conditionsTypes.map(item => item.id) : [])
    };
  })

  .view(props => (
    <Checkbox label='None of the above' {...props} value={false} />
  ));

export { NoneOfTheAbove };
