import { $$signUpCarer } from '@pages/sign-up/pages/carer';

import { routes } from '@shared/routes';

import { createCarerStep } from './shared';

createCarerStep({
  key: 'contactDetails',

  form: $$signUpCarer.steps.contactDetails.form,

  route: routes.signUp.carer.contactDetails,

  nextRoute: routes.signUp.carer.services,

  mapValues: (values, { postcode }) =>
    values.contactDetails ?? {
      ...$$signUpCarer.steps.contactDetails.form.initialValues,

      postCode: decodeURIComponent(postcode)
    },

  processValues: values => values,

  checkValues: values => Boolean(values.personalInformation)
});
