import { Link } from 'atomic-router-react';

import classNames from 'classnames';

import {
  $$transferOwnerShip,
  ModalSteps
} from '@features/account/transfer-ownership';

import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { AlertBox } from '@shared/ui/atoms/alert-box';

import { Button } from '@shared/ui/atoms/button';

import { useMedia } from '@shared/ui/atoms/media';

import { Modal } from '@shared/ui/atoms/modal';

import styles from './attention.module.scss';

const Attention = createView()
  .units({
    onCancel: $$transferOwnerShip.modalClosed,

    stepChanged: $$transferOwnerShip.stepChanged,

    submit: $$transferOwnerShip.submit
  })
  .map(() => {
    const md = useMedia('>=md');

    return {
      md
    };
  })
  .view(({ onCancel, stepChanged, md, submit }) => (
    <Modal.Container onClose={() => onCancel()} size='small'>
      <Modal.Header>Transfer ownership</Modal.Header>

      <Modal.Body>
        <div>
          <AlertBox className={styles.alert} variant='warning'>
            <h4 className={classNames(styles.text, styles.bold)}>
              Pay attention!
            </h4>

            <p className={styles.text}>
              Until this has been done, the Care Space transfer will not be
              completed and you will remain the owner of the Care Space.
            </p>

            <p className={styles.text}>
              As soon as the new owner accepts the invitation, we couldn’t
              delete your account.
            </p>

            <p className={classNames(styles.text, styles.bold)}>
              If you have any questions, please{' '}
              <Link
                to={routes.settings.account}
                className={styles.link}
                onClick={() => onCancel()}
              >
                get in touch with us.
              </Link>
            </p>
          </AlertBox>
        </div>
      </Modal.Body>

      <Modal.Footer space className={styles.footer}>
        <Button
          palette='text'
          variant='text'
          onClick={() => stepChanged(ModalSteps.Credentials)}
        >
          {!md ? 'Previous' : 'Previous step'}
        </Button>

        <div className={styles.group}>
          <Button
            palette='text'
            variant='text'
            onClick={() => onCancel()}
            className={styles.cancel}
          >
            Cancel
          </Button>

          <Button onClick={() => submit()}>Continue</Button>
        </div>
      </Modal.Footer>
    </Modal.Container>
  ));

export { Attention };
