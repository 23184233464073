import { $$profilePage } from '@pages/profile/profile.model';

import { $$carerServices } from '@features/carer/profile/services';

import { ServicesForm } from '@features/carer/profile/services/ui';

import { $$carerProfile } from '@entities/carer/model';

import { createView } from '@shared/lib/view';

import { EditableSection } from '@shared/ui/atoms/editable-section';

import styles from './services.module.scss';

/**
 * <Services />
 */
const Services = createView()
  .units({
    carer: $$carerProfile.$carerProfile,

    editing: $$profilePage.services.$opened,

    onSave: $$carerServices.form.submit,

    onCancel: $$profilePage.services.close,

    onEdit: $$profilePage.services.open
  })

  .view(({ carer, onSave, onCancel, onEdit, editing }) => (
    <EditableSection
      title='Services'
      onEdit={onEdit}
      editing={editing}
      onSave={onSave}
      onCancel={onCancel}
    >
      {({ editing }) =>
        editing ? (
          <ServicesForm />
        ) : (
          <ul className={styles.services}>
            {carer?.services.map(item => (
              <li key={item.id}>- {item.name}</li>
            ))}
          </ul>
        )
      }
    </EditableSection>
  ));

export { Services };
