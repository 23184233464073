import classNames from 'classnames';

import { $$addCareRecipient } from '@widgets/add-care-recipient';

import {
  $$clientTypeSelection,
  ClientTypeSelectionForm
} from '@features/care-recipient/client-type-selection';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { useMedia } from '@shared/ui/atoms/media';

import { Modal } from '@shared/ui/atoms/modal';

import styles from './client-type-selection.module.scss';

const ClientTypeSelection = createView()
  .units({
    onCancel: $$addCareRecipient.closeModal.prepend(() => {})
  })

  .map(() => {
    const lg = useMedia('>=lg');

    return {
      lg
    };
  })

  .view(({ onCancel, lg }) => (
    <Modal.Container
      onClose={onCancel}
      size='large'
      fullHeight={lg}
      overlayStyles={classNames({ [styles.overlay]: !lg })}
      className={styles.modal}
    >
      <Modal.Header>Add a new person</Modal.Header>

      <Modal.Body>
        <h1 className={styles.title}>Who are you arranging care for?</h1>

        <div className={styles.wrapper}>
          <ClientTypeSelectionForm />
        </div>
      </Modal.Body>

      <Modal.Footer className={styles.footer} fill>
        <Button
          palette='text'
          variant='text'
          className={styles.cancel}
          onClick={onCancel}
        >
          Cancel
        </Button>

        <Button onClick={() => $$clientTypeSelection.form.submit()}>
          Next step
        </Button>
      </Modal.Footer>
    </Modal.Container>
  ));

export { ClientTypeSelection };
