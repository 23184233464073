import { chainRoute, createRoute } from 'atomic-router';

import { attach, sample } from 'effector';

import { not } from 'patronum';

import { $$signUpCarer } from '@pages/sign-up/pages/carer';

import { $$session } from '@entities/session';

import type { SignUpStepParams } from '@shared/routes/routes';

import { routes } from '@shared/routes/routes';

import { $carerValues } from './shared';

const chainedRoute = createRoute<SignUpStepParams>();

const { submitted } = $$signUpCarer.steps.employmentDetails.form;

const beforeOpenFx = attach({
  source: $carerValues,

  effect: values => {
    if (!values.additionalInformation) throw 'Not Allowed';

    return values.employmentDetails;
  }
});

$carerValues.on(submitted, (state, values) => ({
  ...state,

  employmentDetails: values
}));

chainRoute({
  route: routes.signUp.carer.employmentDetails,

  beforeOpen: {
    effect: beforeOpenFx,

    mapParams: ({ params }) => params
  },

  cancelOn: beforeOpenFx.fail,

  openOn: beforeOpenFx.done,

  chainedRoute
});

sample({
  clock: routes.signUp.carer.employmentDetails.opened,

  source: $carerValues,

  fn: values => values.personalInformation.dateOfBirth,

  target: $$signUpCarer.steps.employmentDetails.$dateOfBirth
});

sample({
  clock: beforeOpenFx.doneData,

  fn: values => values ?? null,

  target: $$signUpCarer.steps.employmentDetails.form.reset
});

sample({
  clock: beforeOpenFx.failData,

  source: routes.signUp.carer.employmentDetails.$params,

  target: routes.signUp.carer.personalInformation.open
});

sample({
  clock: submitted,

  source: routes.signUp.carer.employmentDetails.$params,

  filter: not($$session.$active),

  target: routes.signUp.carer.createAccount.open
});
