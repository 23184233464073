import type { ConditionsType } from '@shared/api';

import { createForm } from '@shared/lib/form';

type ConditionExperienceValues = {
  conditionsTypes: ConditionsType['id'][];
};

const form = createForm<ConditionExperienceValues>({
  initialValues: {
    conditionsTypes: []
  }
});

const $$carerConditionExperience = {
  form
};

export type { ConditionExperienceValues };

export { $$carerConditionExperience, form };
