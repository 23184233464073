import type { Effect } from 'effector';

import { attach } from 'effector';

import { createEvent, createStore, sample } from 'effector';

import { condition } from 'patronum';

import { $$createCareSpace } from '@features/care-space/create';

import { $$restoreCareSpace } from '@features/care-space/restore';

import { $$carerProfile } from '@entities/carer';

import { $$dictionary } from '@entities/dictionary';

import { $$session, RoleType } from '@entities/session';

import { $$user } from '@entities/user';

import { $$loadCarerData } from './load-carer';

import { $$loadClientData } from './load-client';

const boot = createEvent();

const booted = createEvent();

const load = createEvent();

const $ready = createStore(false);

const getAccountFx = attach({
  effect: $$user.getAccountFx
});

const $emailConfirmationOpenedOnce = createStore(false);

const $addPaymentOpenedOnce = createStore(false);

const loaded = sample({
  clock: [$$loadCarerData.loaded, $$loadClientData.loaded]
});

const $$boot = {
  $ready,
  $emailConfirmationOpenedOnce,
  $addPaymentOpenedOnce,

  load,
  boot,
  loaded,
  booted
};

Object.entries($$dictionary)
  .filter(([key]) => key.endsWith('Fx'))

  .forEach(([_, fx]) => {
    sample({
      clock: booted,

      target: fx as Effect<any, any>
    });
  });

condition({
  source: boot,

  if: $$session.$active,

  then: load,

  else: booted
});

sample({
  clock: [
    load,
    $$createCareSpace.careSpaceCreated,
    $$restoreCareSpace.careSpaceRestored
  ],

  target: getAccountFx
});

sample({
  clock: getAccountFx.doneData,

  source: $$session.$role,

  fn: (role, account) => {
    if ((!role || role == RoleType.Carer) && account.carer) {
      return RoleType.Carer;
    }

    return RoleType.CareRecipient;
  },

  target: $$session.$role
});

sample({
  clock: getAccountFx.doneData,

  source: $$session.$role,

  filter: role => role === RoleType.Carer,

  fn: (_role, { id }) => id,

  target: $$carerProfile.getCarerPublicProfileFx
});

condition({
  source: getAccountFx.doneData,

  if: $$session.$role.map(state => state == RoleType.CareRecipient),

  then: $$loadClientData.load,

  else: $$loadCarerData.load
});

sample({
  clock: loaded,

  filter: $ready.map(state => !state),

  target: booted
});

$ready.on(booted, () => true);

export { $$boot };
