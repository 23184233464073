import moment from 'moment';

import { MembersRoleType } from '@shared/api';

const excludeEmpty = <T>(source: T) => {
  const result: T = {} as T;

  for (const key in source) {
    const value = source[key];

    if (!value || (value as any as string) == '') continue;

    result[key] = value;
  }

  return result;
};

const sortByRole = <T extends { role: MembersRoleType; id: number }>(
  one: T,
  two: T
) => {
  if (one.role === MembersRoleType.Owner) return -1;

  if (two.role === MembersRoleType.Owner) return 1;

  if (one.role === MembersRoleType.FullAccess) return -1;

  if (two.role === MembersRoleType.FullAccess) return 1;

  return one.id - two.id;
};

const sortByCreationDate = <T extends { createdAt?: Date }>(one: T, two: T) => {
  const diff = moment(one.createdAt).diff(moment(two.createdAt));

  if (diff < 0) return 1;

  return -1;
};

const sortByDeletedStatus = <T extends { isDeleted?: boolean }>(
  one: T,
  two: T
) => (one.isDeleted && !two.isDeleted ? 1 : -1);

export { excludeEmpty, sortByRole, sortByCreationDate, sortByDeletedStatus };
