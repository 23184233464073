import type { StoreValue } from 'effector';

import { createEffect, createEvent, createStore, sample } from 'effector';

import { array, mixed, object, string } from 'yup';

import { MessageSuccess } from '@features/member/invite/ui/message-success';

import { $$careSpace } from '@entities/care-space';

import { $$members } from '@entities/members';

import type {
  InviteMember,
  InviteMembersContract,
  MemberCareRecipients,
  Response
} from '@shared/api';

import { carersHQ, MembersRoleType } from '@shared/api';

import { ToastID } from '@shared/config';

import { atom } from '@shared/lib/factory';

import { createForm } from '@shared/lib/form';

import { notify } from '@shared/ui/organisms/toasts';

type EmailFormMember = {
  id: number;

  email: string;

  role: MembersRoleType;
};

type EmailsFormValues = {
  members: EmailFormMember[];
};

type RelationBinding = {
  email: string;

  relation: string;

  clientId: string;
};

type RelationsFormValues = Record<string, RelationBinding[]>;

const $schema = createStore<any>(null);

const emailsForm = createForm<EmailsFormValues>({
  initialValues: {
    members: [
      { id: 2, email: '', role: MembersRoleType.ViewOnly },

      { id: 4, email: '', role: MembersRoleType.ViewOnly },

      { id: 6, email: '', role: MembersRoleType.ViewOnly }
    ]
  },

  $schema
});

const relationsForm = createForm<RelationsFormValues>({
  initialValues: {},

  $schema: createStore(
    object({
      relation: array()
        .min(1)
        .of(
          array()
            .of(
              object({
                contactFor: mixed(),
                relation: mixed()
              })
            )
            .test(
              'Check if some filled',
              'Specify at least one relationship for each invited user.',
              (careRecipients: MemberCareRecipients[]) =>
                careRecipients.some(
                  ({ contactFor, relationship }) => contactFor && relationship
                )
            )
        )
    })
  )
});

const $emailsError = createStore(false);

sample({
  clock: emailsForm.submit,

  source: emailsForm.$errors,

  fn: value => value.members.length > 0,

  target: $emailsError
});

sample({
  source: emailsForm.$values,

  fn: () => {
    const fields = {
      members: array()
        .nullable()

        .required('Required')

        .min(1, 'Required')

        .of(
          object({
            email: string().nullable().email(),

            role: mixed()
              .required()

              .nullable()
          })
        )
    };

    // if (values.members.map(item => item.email !== '')) {
    //   fields.members = fields.members.of(
    //     object({
    //       email: string().required().email(''),
    //
    //       role: mixed()
    //         .required()
    //
    //         .nullable()
    //     })
    //   );
    // }

    return object(fields);
  },

  target: $schema
});

// spec({
//   on: emailsForm.fields.members,
//
//   schema: emailsForm.fields.members.$value.map(value =>
//     value.map(value => (value.email !== '' ? string().required() : null))
//   )
// });

const { $memberEmailRows, deleteRowClicked } = atom('Add New Email Row', () => {
  const $memberEmailRows = createStore([0, 0, 0]);

  const deleteRowClicked = createEvent<number>();

  const lastFocused = sample({
    clock: emailsForm.focused.map(([path]) =>
      path.includes('members') ? Number(path.split('.')[1]) : null
    ),

    source: $memberEmailRows,

    filter: (rows, index) => index !== null && index === rows.length - 1
  });

  sample({
    clock: deleteRowClicked,

    source: {
      members: emailsForm.fields.members.$value,
      rows: $memberEmailRows
    },

    fn: ({ members }, number) => members.filter((_, index) => index !== number),

    target: [emailsForm.fields.members.change, $memberEmailRows]
  });

  sample({
    clock: lastFocused,

    source: {
      rows: $memberEmailRows,

      count: $memberEmailRows.map(state => state.length)
    },

    filter: ({ count }) => count < 5,

    fn: ({ rows }) => [...rows, 0],

    target: $memberEmailRows
  });

  sample({
    clock: lastFocused,

    source: {
      members: emailsForm.fields.members.$value,

      count: $memberEmailRows.map(state => state.length),

      lastItem: emailsForm.fields.members.$value.map(
        state => state[state.length - 1]
      )
    },

    filter: ({ count }) => count <= 5,

    fn: ({ members, lastItem: { id } }) =>
      [
        ...members,
        { id: id + 2, email: '', role: MembersRoleType.ViewOnly }
      ] as EmailFormMember[],

    target: emailsForm.fields.members.change
  });

  return {
    $memberEmailRows,

    deleteRowClicked
  };
});

const { invitesSent, invitesError, $relationError } = atom(
  'Send Invites',
  () => {
    const sendInvitesFx = createEffect<
      InviteMember[],
      { hasSuccess: boolean; hasFailed?: boolean }
    >();

    const $relationError = createStore(false).reset(
      sendInvitesFx.doneData,
      relationsForm.submitted
    );

    const submitted = sample({
      clock: relationsForm.submitted,

      source: {
        emails: emailsForm.$values.map(state =>
          state.members.filter(item => item.email !== '')
        ),

        careSpaceID: $$careSpace.$careSpaceID
      },

      fn: ({ careSpaceID, emails }, relationsValues) => {
        const relations = Object.values(relationsValues).map(item => ({
          ...item[item.length - 1]
        }));

        return emails.map(({ email, role }) => ({
          role,
          email,
          relations: relations
            .filter(item => item.email === email)
            .map(item => ({
              clientId: item.clientId,
              relation: item.relation
            })),
          workspaceId: careSpaceID
        }));
      }
    });

    sample({
      clock: submitted,

      target: sendInvitesFx
    });

    sample({
      clock: submitted,

      filter: value =>
        value.some(value => !value.relations.length) ||
        value.some(value => value.relations.some(item => !item.relation)),

      fn: () => true,

      target: $relationError
    });

    const invitesError = sample({
      clock: sendInvitesFx.failData,

      source: $relationError,

      filter: error => !error,

      fn: (_, error) => error
    });

    sendInvitesFx.use(async invites => {
      try {
        await carersHQ.settings.inviteMembers({ invites });

        return {
          hasSuccess: true
        };
      } catch (error) {
        const statuses = (error as Response<{ success: boolean }[]>)
          .data as InviteMembersContract;

        return {
          hasSuccess: statuses.some(one => one.success),

          hasFailed: statuses.some(one => !one.success)
        };
      }
    });

    return {
      invitesSent: sendInvitesFx.doneData,

      invitesError,

      $relationError
    };
  }
);

sample({
  clock: invitesSent,

  target: $$members.getMembersFx
});

notify({
  clock: invitesSent,

  options: {
    id: ToastID.InvitationsSent,
    type: 'success',
    title: 'Invitations have been sent',
    content: MessageSuccess,
    isTextMinimized: true
  }
});

notify({
  clock: invitesError,

  options: {
    id: ToastID.InvitationsSentError,
    type: 'error',
    title: 'Some invitations have not been sent',
    content:
      '<div style="word-break: break-word">There must be an error with one of the invitations</div>',
    isTextMinimized: true
  }
});

const { $visible, cancelClicked } = atom('Visibility', () => {
  const cancelClicked = createEvent();

  const $visible = createStore(false);

  $visible.reset(cancelClicked, invitesSent);

  return {
    $visible,
    cancelClicked
  };
});

const { $step, goBackFromRelationsClicked, addCareRecipientClicked } = atom(
  'Steps',
  () => {
    const goBackFromRelationsClicked = createEvent();

    const addCareRecipientClicked = createEvent();

    const $step = createStore<'emails' | 'relations'>('emails');

    $step
      .on(emailsForm.submitted, () => 'relations')

      .on(
        [
          invitesSent,

          goBackFromRelationsClicked,

          $visible.updates.filter({ fn: Boolean })
        ],

        () => 'emails'
      );

    sample({
      clock: emailsForm.submitted,

      source: relationsForm.$values,

      fn: (values, emailsValues) =>
        Object.entries(values)
          .filter(([key]) =>
            emailsValues.members.some(one => one.email === key)
          )

          .reduce<StoreValue<typeof relationsForm.$values>>(
            (result, [key, value]) => {
              result[key as keyof typeof result] = value;

              return result;
            },

            {}
          ),

      target: relationsForm.reset
    });

    sample({
      clock: addCareRecipientClicked,

      target: cancelClicked
    });

    sample({
      clock: goBackFromRelationsClicked,

      fn: () => false,

      target: $relationError
    });

    return {
      $step,
      goBackFromRelationsClicked,
      addCareRecipientClicked
    };
  }
);

atom('cleanup', () => {
  sample({
    clock: $visible.updates,

    filter: visible => !visible,

    target: [emailsForm.reset, relationsForm.reset].map(item =>
      item.prepend(() => {})
    )
  });
});

$emailsError.reset(cancelClicked, emailsForm.changed);

$memberEmailRows.reset(cancelClicked, invitesSent);

$relationError.reset(cancelClicked);

const $$inviteMembers = {
  $visible,
  addCareRecipientClicked
};

export {
  $$inviteMembers,
  $step,
  $visible,
  $relationError,
  $memberEmailRows,
  deleteRowClicked,
  addCareRecipientClicked,
  cancelClicked,
  goBackFromRelationsClicked,
  emailsForm,
  relationsForm,
  $emailsError
};
