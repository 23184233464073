import axios from 'axios';

import { createEffect } from 'effector';

import type {
  ErrorCode,
  LoginResponseDto,
  Response,
  SignUpCarerParams,
  SignUpClientParams
} from '@shared/api';

import { carersHQ } from '@shared/api';

const signUpCarerFx = createEffect<
  SignUpCarerParams,
  LoginResponseDto,
  ErrorCode
>();

const signUpMyselfFx = createEffect<
  SignUpClientParams,
  LoginResponseDto,
  ErrorCode
>();

const signUpLovedFx = createEffect<
  SignUpClientParams,
  LoginResponseDto,
  ErrorCode
>();

const $$signUp = {
  signUpLovedFx,
  signUpCarerFx,
  signUpMyselfFx
};

signUpCarerFx.use(async params => {
  try {
    const response = await carersHQ.auth.signUpCarer(params);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw (error.response.data as Response).code;
    }
  }
});

signUpLovedFx.use(async params => {
  try {
    const response = await carersHQ.auth.signUpLovedOne(params);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw (error.response.data as Response).code;
    }
  }
});

signUpMyselfFx.use(async params => {
  try {
    const response = await carersHQ.auth.signUpMyself(params);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw (error.response.data as Response).code;
    }
  }
});

export { $$signUp };
