import pencil from '@icon/pencil-edit.svg';

import { createView } from '@shared/lib/view';

import { Button } from '@shared/ui/atoms/button';

import { Select } from '@shared/ui/atoms/select';

import styles from './heading.module.scss';

import { useHeading } from './heading.props';

const Heading = createView()
  .map(useHeading)

  .view(({ options, selectedTimeOption, onTimeSelectChange, onEditClick }) => (
    <div className={styles.heading}>
      <div className={styles.infoWrapper}>
        <h1 className={styles.title}>Reports</h1>

        <p className={styles.description}>
          You can change the time interval for displaying statistics and edit
          the data via settings.
        </p>
      </div>

      <div className={styles.action}>
        <Select
          defaultValue={selectedTimeOption}
          className={styles.select}
          value={selectedTimeOption}
          options={options}
          onChange={value => onTimeSelectChange(value)}
        />

        <Button
          className={styles.editButton}
          palette='secondary'
          icon={<img src={pencil} alt='Edit icon' />}
          onClick={onEditClick}
        />
      </div>
    </div>
  ));

export { Heading };
