import { createEffect, createStore } from 'effector';

import type { GetUserWorkspaceRelation } from '@shared/api';

import { carersHQ } from '@shared/api';

const getUserWorkspaceRelationsFx = createEffect<
  void,
  GetUserWorkspaceRelation[]
>();

const $userWorkspaceRelations = createStore<GetUserWorkspaceRelation[]>([]);

const $$careSpaceRelations = {
  $userWorkspaceRelations,
  getUserWorkspaceRelationsFx
};

$userWorkspaceRelations.on(
  getUserWorkspaceRelationsFx.doneData,

  (_, payload) => payload
);

getUserWorkspaceRelationsFx.use(async () => {
  const { data } = await carersHQ.workspaces.getUserWorkspaceRelations();

  return data;
});

export { $$careSpaceRelations };
