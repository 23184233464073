import classnames from 'classnames';

import { useStore } from 'effector-react';

import type { CSSProperties, FC } from 'react';

import { Text } from '@shared/ui/atoms/text';

import { $palettes } from './button.config';

import styles from './button.module.scss';

import type { ButtonGroupProps, ButtonProps } from './button.props';

const Button: FC<ButtonProps> = ({
  variant,
  children,
  palette,
  icon,
  disabled,
  size,
  className,
  ...props
}) => {
  const palettes = useStore($palettes);

  const { fill, text, border, shadow } =
    (typeof palette == 'string' ? palettes[palette] : palette) ??
    palettes[variant];

  return (
    <button
      className={classnames(
        styles.button,
        className,
        styles[variant],
        styles[size],
        {
          [styles.icon]: !!icon
        }
      )}
      style={
        {
          '--button-shadow': shadow,
          '--button-text-normal': text.normal,
          '--button-text-hovered': text.hovered ?? text.normal,
          '--button-text-pressed': text.pressed ?? text.normal,
          '--button-fill-normal': fill.normal,
          '--button-fill-hovered': fill.hovered ?? fill.normal,
          '--button-fill-pressed': fill.pressed ?? fill.normal,
          '--button-border-normal': border.normal ?? border.normal,
          '--button-border-hovered': border.hovered ?? border.normal,
          '--button-border-pressed': border.pressed ?? border.normal
        } as CSSProperties
      }
      disabled={disabled}
      {...props}
    >
      {icon}

      {children && (
        <Text className={styles.caption} variant='body-1'>
          {children}
        </Text>
      )}
    </button>
  );
};

const ButtonGroup: FC<ButtonGroupProps> = ({
  children,
  variant,
  className
}) => (
  <div className={classnames(styles.group, className, styles[variant])}>
    {children}
  </div>
);

ButtonGroup.defaultProps = {
  variant: 'horizontal'
};

Button.defaultProps = {
  variant: 'primary',
  palette: 'primary',
  size: 'lg'
};

export { Button, ButtonGroup };
