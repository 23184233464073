import avatarPlaceholder from '@img/avatar-placeholder.png';

import type { ChangeEventHandler } from 'react';

import React, { useRef } from 'react';

import { $$dictionary } from '@entities/dictionary';

import { ContactDetailsFields } from '@entities/user';

import { createView } from '@shared/lib/view';

import { BoxRow } from '@shared/ui/atoms/box-row';

import { FormSectionGroup } from '@shared/ui/atoms/form-section';

import { Label } from '@shared/ui/atoms/label';

import { Text } from '@shared/ui/atoms/text';

import { DateSelectGroupContainer } from '@shared/ui/molecules/date-select-group';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { $$profilePersonal, form, maxBirth, minBirth } from '../model';

import styles from './personal.module.scss';

/**
 * <Personal />
 */
const PersonalForm = createView<{ onImageUpload: Function }>()
  .units({
    genders: $$dictionary.$genders,

    image: $$profilePersonal.form.fields.imageUrl.$value
  })

  .map(({ onImageUpload }) => {
    const avatarInputRef = useRef<HTMLInputElement>();

    const onAvatarClick = () => {
      avatarInputRef.current.click();
    };

    const onAvatarChange: ChangeEventHandler<HTMLInputElement> = event => {
      const file = event.target.files.item(0);

      event.target.value = '';

      onImageUpload(file);
    };

    return {
      minBirth,

      maxBirth,

      avatarInputRef,

      onAvatarClick,

      onAvatarChange
    };
  })

  .view(
    ({
      minBirth,
      maxBirth,
      genders,
      image,
      avatarInputRef,
      onAvatarClick,
      onAvatarChange
    }) => (
      <Form className={styles.form} use={form}>
        <div className={styles.avatar}>
          <Label label='Photo' />

          <div className={styles.avatarBox} onClick={() => onAvatarClick()}>
            <img
              className={styles.avatarImage}
              src={image || avatarPlaceholder}
            />

            <div className={styles.avatarUpload} />

            <input
              className={styles.avatarInput}
              type='file'
              ref={avatarInputRef}
              onChange={onAvatarChange}
              accept='image/*'
            />
          </div>
        </div>

        <FormSectionGroup className={styles.formGroup}>
          <FormSectionGroup>
            <div className={styles.name}>
              <Field.Input
                use={form.fields.firstName}
                label='First name'
                placeholder='Enter'
              />

              <Field.Input
                use={form.fields.lastName}
                label='Last name'
                placeholder='Enter'
              />
            </div>

            <div>
              <Label label='Gender' />

              <BoxRow layout='horizontalAlways' className={styles.gender}>
                <Field.RadioList use={form.fields.gender} options={genders} />
              </BoxRow>

              <DateSelectGroupContainer className={styles.dateOfBirth}>
                <Field.DateSelectGroup
                  use={form.fields.dateOfBirth}
                  min={minBirth}
                  max={maxBirth}
                />
              </DateSelectGroupContainer>
            </div>
          </FormSectionGroup>

          <div>
            <Text variant='subtitle-2' className={styles.formTitle}>
              Address details
            </Text>

            <ContactDetailsFields
              form={form as any}
              classNames={{
                location: styles.location,
                formSection: styles.formSection,
                phoneRow: styles.phone,
                addressLine2: styles.addressLine,
                addressLine1: styles.addressLine
              }}
            />
          </div>
        </FormSectionGroup>
      </Form>
    )
  );

export { PersonalForm };
