enum RateType {
  default = 0,
  holidays = 1
}

class CareRate {
  public id?: number;

  public rate: string;

  public rateType: RateType;

  public transaction?: boolean;
}

export { RateType };

export type { CareRate };
