import { modelView } from 'effector-factorio';

import { $$dictionary } from '@entities/dictionary';

import { createView } from '@shared/lib/view';

import { FormSectionGroup } from '@shared/ui/atoms/form-section';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { ManageAccountHolderModelFactory } from '../model';

import styles from './account-holder.module.scss';

const AccountHolderDetailsForm = modelView(
  ManageAccountHolderModelFactory,

  createView()
    .units({
      relationships: $$dictionary.$relationships
    })

    .map(() => {
      const { form } = ManageAccountHolderModelFactory.useModel();

      return {
        form
      };
    })

    .view(({ form, relationships }) => (
      <Form use={form}>
        <FormSectionGroup className={styles.rows}>
          <div className={styles.name}>
            <Field.Input
              labelGap
              use={form.fields.firstName}
              label='First name'
              bold
              errorIn='label'
            />

            <Field.Input
              labelGap
              use={form.fields.lastName}
              label='Last name'
              bold
              errorIn='label'
            />
          </div>

          <div className={styles.row}>
            <Field.Select
              labelGap
              use={form.fields.relation}
              name='relation'
              bold
              options={relationships}
              errorIn='label'
              label='Relationship to care recipient'
            />
          </div>

          <div className={styles.row}>
            <Field.PhoneInput
              bold
              labelGap
              use={form.fields.phone}
              label='Phone number'
              errorIn='label'
            />
          </div>
        </FormSectionGroup>
      </Form>
    ))
);

export { AccountHolderDetailsForm };
