import { modelView } from 'effector-factorio';

import { ContactDetailsFields } from '@entities/user';

import type { FormModel } from '@shared/lib/form';

import { Form } from '@shared/ui/organisms/form';

import type { ContactDetailsValues } from '../model';

import { ManageContactDetailsModelFactory } from '../model';

/**
 * <ContactDetails />
 */
const ContactDetailsForm = modelView(ManageContactDetailsModelFactory, () => {
  const { form } = ManageContactDetailsModelFactory.useModel();

  return (
    <Form use={form}>
      <ContactDetailsFields form={form as FormModel<ContactDetailsValues>} />
    </Form>
  );
});

export { ContactDetailsForm };
