import classNames from 'classnames';

import type { FC, HTMLAttributes } from 'react';

import styles from './fieldset.module.scss';

const Fieldset: FC<HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => <div className={classNames(className, styles.container)} {...props} />;

export { Fieldset };
