import { createEvent, createStore, sample } from 'effector';

import { $$billing } from '@entities/billing';

import { routes } from '@shared/routes';

const openPage = createEvent();

const $ready = createStore(false);

const $$billingPage = {
  $ready
};

sample({
  clock: routes.settings.billing.opened,

  target: openPage
});

sample({
  clock: routes.settings.billing.opened,

  target: $$billing.getBillingDetailsFx
});

$ready.on(openPage, () => true);

export { $$billingPage };
