import { $$session, RoleType } from '@entities/session';

import { routes } from '@shared/routes';

const links = [
  {
    to: routes.schedule,

    children: 'Schedule'
  },

  {
    to: routes.team.any,

    params: {
      page: ['my'] as string[]
    },

    children: 'Care team',

    role: RoleType.CareRecipient
  },

  {
    to: routes.clients,

    children: 'My clients',

    role: RoleType.Carer
  },

  {
    to: routes.invoices,

    children: 'Invoices'
  },

  {
    to: routes.help,

    children: 'Help'
  }
];

const $links = $$session.$role.map(role =>
  links.filter(item => !item.role || item.role == role)
);

export { $links };
