import { Auth } from '@layouts/auth';

import { SignInForm } from '@features/auth/sign-in-by-email';

import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { $$signInPage } from './sign-in.model';

const SignInPage = createView()
  .units({
    onFindCarerClick: $$signInPage.findCarerClicked,

    onBecomeCarerClick: $$signInPage.becomeCarerClicked
  })

  .view(({ onFindCarerClick, onBecomeCarerClick }) => (
    <Auth.FormPage title='Welcome back'>
      <SignInForm
        recoveryRoute={routes.auth.recovery}
        onFindCarerClick={onFindCarerClick}
        onBecomeCarerClick={onBecomeCarerClick}
      />
    </Auth.FormPage>
  ));

export { SignInPage };
