import type { Model } from 'effector-factorio';

import type { FC } from 'react';

import type { CreateAccountModelFactory } from '@features/auth/sign-up';

import { CreateAccountFormFooter } from '@features/auth/sign-up';

import { CreateAccountForm } from '@features/auth/sign-up';

import { CtaBanner } from '@shared/ui/atoms/cta-banner';

import { styled } from '@shared/ui/stitches';

import type { StepWizardModelFactory } from '../sign-up-wizard-step';

import { StepWizard } from '../sign-up-wizard-step';

type CreateAccountProps = {
  asCarer?: boolean;

  stepWizardModel: Model<typeof StepWizardModelFactory>;

  createAccountModel: Model<typeof CreateAccountModelFactory>;
};

const Wrap = styled('br', {
  display: 'none',

  '@md': {
    display: 'initial'
  }
});

const WrapSmOnly = styled('br', {
  display: 'none',

  '@sm': {
    display: 'initial'
  },

  '@md': {
    display: 'none'
  }
});

const Container = styled('div', {
  flex: 1,

  background: '#fff'
});

const CreateAccount: FC<CreateAccountProps> = ({
  asCarer,
  stepWizardModel,
  createAccountModel
}) => (
  <>
    <CtaBanner letterSpacing>
      {asCarer ? (
        <p>
          All set!
          <br />
          Let’s create your account so you <WrapSmOnly /> can track your
          application.
        </p>
      ) : (
        <p>
          You’re all set! Let’s create your <WrapSmOnly /> account
          <Wrap /> & see your carer <WrapSmOnly />
          shortlist.
        </p>
      )}
    </CtaBanner>

    <Container>
      <StepWizard
        title='Create account'
        model={stepWizardModel}
        footer={<CreateAccountFormFooter model={createAccountModel} />}
        captions={
          asCarer
            ? {}
            : {
                next: 'View Carers'
              }
        }
      >
        <CreateAccountForm model={createAccountModel} />
      </StepWizard>
    </Container>
  </>
);

export { CreateAccount };
