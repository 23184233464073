import '@app/styles/index.scss';

import './localization/yup';

import classNames from 'classnames';

import { isMobile, isMacOs, isIOS, isWindows } from 'react-device-detect';

import { createRoot } from 'react-dom/client';

import { App, Provider } from '@app';

import { $$boot } from '@processes/boot';

import reportWebVitals from '@shared/lib/utils/report-web-vitals';

import { routes } from '@shared/routes';

import { init } from './localization';

routes.careRecipients.base.$params;

window.onload = () => {
  document.body.classList.add(
    ...classNames({
      'is-not-mobile-platform': !isMobile,
      'os-mac': isMacOs,
      'os-ios': isIOS,
      'os-apple': isIOS || isMacOs,
      'os-windows': isWindows
    })
      .split(' ')

      .filter(item => item)
  );
};

const setup = async () => {
  await init();

  $$boot.boot();

  const root = createRoot(document.getElementById('root')!);

  root.render(
    <Provider>
      <div className={classNames('platform-container')}>
        <App />
      </div>
    </Provider>
  );
};

setup();

reportWebVitals();
