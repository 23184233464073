import { $$dictionary, LanguagesPicker } from '@entities/dictionary';

import { createView } from '@shared/lib/view';

import { BoxRow } from '@shared/ui/atoms/box-row';

import { CheckboxGroup } from '@shared/ui/atoms/checkbox';

import { FormSection, FormSectionGroup } from '@shared/ui/atoms/form-section';

import { RadioGroup } from '@shared/ui/atoms/radio';

import { Field } from '@shared/ui/organisms/field';

import { Form } from '@shared/ui/organisms/form';

import { form } from '../model';

import styles from './additional-information-form.module.scss';

/**
 * <AdditionalInformation />
 */
const AdditionalInformationForm = createView()
  .units({
    pets: $$dictionary.$pets,

    petsVisible: form.fields.petsOk.$value.map(state => !state)
  })

  .view(({ pets, petsVisible }) => (
    <Form use={form}>
      <FormSectionGroup>
        <Field.Container use={form.fields.languages}>
          {({ value, onChange }) => (
            <LanguagesPicker
              labelGap
              classNames={{ list: styles.list }}
              label='What languages do you speak fluently?'
              value={value}
              captions={{ add: 'Add another' }}
              onChange={onChange}
            />
          )}
        </Field.Container>

        <Field.FormSection
          use={form.fields.transportationHelp}
          title='And do you have a driving license?'
        >
          <RadioGroup layout='vertical'>
            <Field.RadioList
              use={form.fields.transportationHelp}
              options={[
                {
                  id: null,

                  name: 'No'
                },

                { id: 1, name: 'Yes, I have a car and a driving license' },

                {
                  id: 2,
                  name: "Yes, I have a driving license but I don't have a car"
                }
              ]}
            />
          </RadioGroup>
        </Field.FormSection>

        <Field.FormSection
          className={styles.fieldGroup}
          use={form.fields.smokingOk}
          title='Are you ok with smokers in your client’s home?'
        >
          <BoxRow layout='horizontalAlways'>
            <Field.RadioList
              use={form.fields.smokingOk}
              options={[
                { id: false, name: 'No' },
                { id: true, name: 'Yes' }
              ]}
            />
          </BoxRow>
        </Field.FormSection>

        <FormSection
          className={!petsVisible ? styles.fieldGroup : ''}
          title='Are you ok with pets in your client’s home?'
        >
          <BoxRow layout='horizontalAlways'>
            <Field.RadioList
              use={form.fields.petsOk}
              options={[
                {
                  id: false,
                  name: 'No'
                },

                {
                  id: true,
                  name: 'Yes'
                }
              ]}
            />
          </BoxRow>
        </FormSection>

        {petsVisible && (
          <Field.FormSection
            use={form.fields.petsDisabled}
            title='What pets are you not ok with?'
          >
            <CheckboxGroup className={styles.pets}>
              <Field.CheckboxList
                use={form.fields.petsDisabled}
                options={pets}
              />
            </CheckboxGroup>
          </Field.FormSection>
        )}
      </FormSectionGroup>
    </Form>
  ));

export { AdditionalInformationForm };
