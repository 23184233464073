import { Auth } from '@layouts/auth';

import type { PropsWithChildren } from 'react';

import { createView } from '@shared/lib/view';

import { routes } from '@shared/routes';

import { Anchor } from '@shared/ui/atoms/anchor';

const AddRolePage = createView<PropsWithChildren>().view(({ children }) => (
  <Auth.Container>
    <Auth.Header centered={false} logoTo={routes.schedule}>
      <Anchor to={routes.schedule}>Back to account</Anchor>
    </Auth.Header>

    <Auth.Body>{children}</Auth.Body>
  </Auth.Container>
));

export { AddRolePage };
